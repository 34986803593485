import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Flex } from '@atom/Flex';
import { Button } from '@atom/Buttons';
import { PageTitle } from '@molecules/PageTitle';
import PageLayout from '@layout/PageLayout';
import HorizontalTab from '@molecules/HorizontalTabs';

import { employeeListTabValues, handleDownloadSheet } from './helper';
import { useSelector } from 'react-redux';

const EmployeeList = () => {
  const [currentTab, setCurrentTab] = useState('all');

  const navigate = useNavigate();
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  useEffect(() => {
    const paths = window.location.pathname?.split('/') || [];
    if (paths.length) {
      setCurrentTab(paths[paths.length - 1] || 'all');
    }
  }, [window.location.pathname]);

  const onTabChange = (tab: string) => {
    setCurrentTab(tab);
    navigate(`/employees/${tab}`);
  };

  return (
    <PageLayout>
      <Flex direction="column" style={{ width: '100%' }}>
        <PageTitle text="EMPLOYEE MANAGEMENT" isBack={false}>
          {isAdmin && (
            <>
              <Button
                text="Export Employees"
                onClick={() => {
                  handleDownloadSheet();
                }}
              />
              <Button text="Add Employee" onClick={() => navigate('/employees/create')} />
            </>
          )}
        </PageTitle>
        <HorizontalTab
          value={employeeListTabValues}
          activeKey={currentTab}
          onChange={onTabChange}
        />
      </Flex>
    </PageLayout>
  );
};

export default EmployeeList;
