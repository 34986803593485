import DataTable from '@organism/DataTable/DataTable';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';
import { formatPhoneNumber } from '@utils/format';
import moment from 'moment';
import React from 'react';
import { IStaffTableProps } from '../type';

const StaffTable: React.FC<IStaffTableProps> = ({ tableData }) => {
  const columns = () => {
    const cols = [
      {
        title: 'STAFF START TIME',
        dataIndex: 'arrival_time',
        render: (data: any) => {
          return data ? moment(data, 'hh').format('h:mm a') : '-';
        }
      },
      {
        title: 'POSITION',
        dataIndex: 'position',
        render: (data: any) => {
          return data ? data : '-';
        }
      },
      {
        title: 'EMPLOYEE',
        dataIndex: 'employee',
        render: (data: any) => {
          return data ? data : '-';
        }
      },
      {
        title: 'PHONE NUMBER',
        dataIndex: 'phone_number',
        render: (phone_number: any, data: any) => {
          const countryCode = data?.country_code?.includes('+')
            ? data?.country_code
            : data?.country_code
            ? `+${data?.country_code}`
            : '';
          const phone = data?.phone_number;
          const extension = data?.extension;
          const phoneNo = `${countryCode || ''} ${formatPhoneNumber(phone) || ''} ${
            extension ? `x${extension}` : ''
          }`;
          return data ? phoneNo : '-';
        }
      }
    ];
    return cols;
  };
  return (
    <>
      <DataTable
        className="fixTD"
        columns={columns()}
        dataSource={tableData || []}
        renderEmpty={() => <div>No additional staff added</div>}
        scrollConfig={{
          y: tableData?.length ? 'calc(100vh - 350px)' : 'auto'
        }}
      />
    </>
  );
};

export default StaffTable;
