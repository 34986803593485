import { Col, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import SelectInput from '@atom/Select';
import { IngredientDietAndVideoDetailsSecProps } from '@pages/MenuManagementPage/types';
import { showErrorToast } from '@utils/index';

import { getIngredientsSecDropdownData, handleKeyPress } from './helper';
import { SelectWrapper, SubLabel } from './style';
import { Select } from 'antd';
const { Option } = Select;

function IngredientDietAndVideoDetailsSec(props: IngredientDietAndVideoDetailsSecProps) {
  const { formik } = props;
  const { values, handleBlur, setFieldValue, handleChange, setFormikState, touched, errors } =
    formik;

  const [dropdownData, setDropdownData] = useState<any>({});
  const [isIngredientsLoading, setIsIngredientsLoading] = useState<boolean>(true);

  useEffect(() => {
    updateDropdownData();
  }, []);

  const updateDropdownData = async () => {
    setIsIngredientsLoading(true);
    try {
      const ddRes = await getIngredientsSecDropdownData();
      setDropdownData({
        ingredientsList: ddRes?.[0]?.success ? ddRes?.[0]?.data : [],
        dietaryRestrictionsList: ddRes?.[1]?.success ? ddRes?.[1]?.data : []
      });
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch dropdown data'
      });
    }
    setIsIngredientsLoading(false);
  };

  if (isIngredientsLoading) {
    return (
      <Flex justifyContent="center" style={{ minHeight: '200px' }}>
        <Spin />
      </Flex>
    );
  }

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} lg={24}>
          <Row>
            <Label
              isMandatory={false}
              text="Dietary Restrictions"
              style={{ paddingRight: '12px' }}
            />
            <SubLabel color="#A19F9F">
              (Add all applicable allergy alerts for this product)
            </SubLabel>
          </Row>
          <SelectWrapper>
            <SelectInput
              id={`dietary_restrictions`}
              name={`dietary_restrictions`}
              placeholdertitle="Add Dietary Restrictions"
              value={values?.dietary_restrictions}
              error={touched?.dietary_restrictions && errors?.dietary_restrictions}
              filterOption={(key: any, e: any) =>
                e?.children?.props?.children?.toLowerCase()?.includes(key?.toLowerCase())
              }
              onChange={(ids: any) => setFieldValue(`dietary_restrictions`, ids)}
              onBlur={handleBlur}
              mode="multiple"
              onInputKeyDown={(e) =>
                handleKeyPress(
                  e,
                  'dietaryRestrictionsList',
                  'name',
                  'dietary_restrictions',
                  setDropdownData,
                  setFieldValue,
                  dropdownData,
                  values
                )
              }
            >
              {dropdownData?.dietaryRestrictionsList?.map((item: any) => (
                <Option value={item?.id} key={item?.id}>
                  <>{item?.name}</>
                </Option>
              ))}
            </SelectInput>
          </SelectWrapper>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={24}>
          <Row>
            <Label
              isMandatory={false}
              text="Video Tutorial Link"
              style={{ paddingRight: '12px' }}
            />
            <SubLabel color="#A19F9F">(Copy and paste the video link below)</SubLabel>
          </Row>
          <Input
            name="tutorial_link"
            id="tutorial_link"
            error={touched?.tutorial_link && errors?.tutorial_link}
            placeholder="https://"
            onChange={handleChange}
            value={values?.tutorial_link}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </>
  );
}

export default IngredientDietAndVideoDetailsSec;
