import styled from 'styled-components';

export const EventListContainer = styled.div`
  .ant-table-row {
    cursor: pointer !important;
  }
  .ant-pagination-simple-pager {
    .ant-pagination-slash {
      visibility: hidden;
    }
    span::after {
      content: 'of';
      margin-left: 0;
      visibility: visible;
    }
  }
`;
