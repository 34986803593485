import MenuManagement from '@pages/GlobalSettingsPage/MenuManagement';
import EmployeeManagement from '@pages/GlobalSettingsPage/EmployeeManagement';
import EventTasks from '@pages/GlobalSettingsPage/EventTasks';
import Settings from '@pages/GlobalSettingsPage/Settings';
import LogoLibrary from '@pages/GlobalSettingsPage/LogoLibrary';
import ProductType from '@pages/GlobalSettingsPage/ProductType';

export const getGlobalSettingsTabValue = (props: any) => {
  const { currentChildTab, setCurrentChildTab } = props;
  return [
    {
      tab: 'Menu Management',
      content: (
        <MenuManagement currentChildTab={currentChildTab} setCurrentChildTab={setCurrentChildTab} />
      ),
      key: 'menu-management'
    },
    {
      tab: 'Employee Management',
      content: <EmployeeManagement />,
      key: 'employee-management'
    },
    { tab: 'Event Tasks', content: <EventTasks />, key: 'event-tasks' },
    { tab: 'Settings', content: <Settings />, key: 'settings' },
    { tab: 'Logo Library', content: <LogoLibrary />, key: 'logo-library' },
    { tab: 'Product Type', content: <ProductType />, key: 'product-type' }
  ];
};
