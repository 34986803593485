import { Col, Row } from 'antd';
import { ContactDetailsType, IAdditionalContact } from '../../types';

import { Input } from '@atom/Input';
import InputPhone from '@atom/InputPhone';
import { Label } from '@atom/FormLable';
import React from 'react';
import RemoveIcon from '@assets/removerow.png';

function AdditionalContacts({
  values,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  arrayHelper,
  index,
  showRemove
}: IAdditionalContact) {
  const phoneChangeHandler = (a: ContactDetailsType) => {
    setFieldValue(`additional_contacts[${index}].country_code`, a.code ?? ``);
    setFieldValue(`additional_contacts[${index}].extension`, a.ext ?? ``);
    setFieldValue(`additional_contacts[${index}].phone_number`, a.phone ?? ``);
    setFieldTouched(`additional_contacts[${index}].country_code`, true);
    setFieldTouched(`additional_contacts[${index}].extension`, true);
    setFieldTouched(`additional_contacts[${index}].phone_number`, true);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={6} xs={24}>
          <Label text="Name" />
          <Input
            name={`additional_contacts[${index}].name`}
            id={`additional_contacts[${index}].name`}
            error={
              touched['additional_contacts']?.[Number(index)]?.name &&
              errors['additional_contacts']?.[Number(index)]?.name
            }
            placeholder="Type Name"
            onChange={handleChange}
            value={values?.additional_contacts?.[Number(index)]?.name}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6} xs={24}>
          <Label text="Email" />
          <Input
            name={`additional_contacts[${index}].email`}
            id={`additional_contacts[${index}].email`}
            error={
              touched['additional_contacts']?.[Number(index)]?.email &&
              errors['additional_contacts']?.[Number(index)]?.email
            }
            placeholder="Type Email"
            onChange={handleChange}
            value={values?.additional_contacts?.[Number(index)]?.email}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={8} xs={20}>
          <Label text="Phone Number" />
          <InputPhone
            id="phone"
            name="phone"
            value={{
              code: values?.additional_contacts?.[Number(index)]?.country_code,
              phone: values?.additional_contacts?.[Number(index)]?.phone_number,
              ext: values?.additional_contacts?.[Number(index)]?.extension
            }}
            error={
              (touched['additional_contacts']?.[Number(index)]?.phone_number &&
                errors['additional_contacts']?.[Number(index)]?.phone_number) ||
              (touched['additional_contacts']?.[Number(index)]?.country_code &&
                errors['additional_contacts']?.[Number(index)]?.country_code) ||
              (touched['additional_contacts']?.[Number(index)]?.extension &&
                errors['additional_contacts']?.[Number(index)]?.extension) ||
              ''
            }
            onBlur={handleBlur}
            onChange={phoneChangeHandler}
          />
        </Col>
        <Col
          style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
          md={4}
          xs={4}>
          {showRemove && (
            <img
              src={RemoveIcon}
              alt="remove"
              style={{ cursor: 'pointer' }}
              onClick={() => arrayHelper.remove(index)}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default AdditionalContacts;
