import StatusLabel from '@atom/StatusLabel';
import services from '@services/index';
import { ChangeEvent } from 'react';
import { ResponseType } from '../helper';

import EmployeeTable from './EmployeeTable';
import TableHeader from './TableHeader';
import { userType } from '../constants';
import { Flex } from '@atom/Flex';
import SelectInput from '@atom/Select';
import { Select } from 'antd';
import { Button } from '@atom/Buttons';
import { downloadsheet } from '@utils/helper';

// types
export interface EmployeeListFilterType {
  email: string;
  name: string;
  phone_number: string;
  position: string;
  user_type: string;
  page?: any;
}

declare interface EmployeeRowsTypes {
  name: string;
  employee_positions: {
    name: string
  }[];
  email: string;
  user_type: number;
  phone_number: number;
  status_info: {
    status_name: string;
  }
}

// tab values
export const employeeListTabValues = [
  { tab: 'ALL', content: <EmployeeTable tab="all" />, key: 'all' },
  { tab: 'ACTIVE', content: <EmployeeTable tab="active" />, key: 'active' },
  { tab: 'INACTIVE', content: <EmployeeTable tab="in-active" />, key: 'in-active' }
];

// get table columns
export const getEmployeeManagementTableColumns = (
  filterValue: EmployeeListFilterType,
  onChange: (a: ChangeEvent) => void
) => {
  const { Option } = Select;

  const productColumns = [
    {
      title: (
        <TableHeader
          columnTitle="Employee Name"
          name="name"
          value={filterValue.name}
          placeholder="Employee"
          onChange={onChange}
        />
      ),
      dataIndex: 'name',
      width: '25%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Position(s)"
          name="position"
          value={filterValue.position}
          placeholder="Positon"
          onChange={onChange}
        />
      ),
      dataIndex: 'employee_positions',
      width: '25%',
      render: (data: any) => {
        const positionList = (data || []).map((item: { name: string }) => item.name);
        return positionList.join(', ');
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Email"
          name="email"
          value={filterValue.email}
          placeholder="Email"
          onChange={onChange}
        />
      ),
      dataIndex: 'email',
      width: '25%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '150px' }} gap={15}>
          <Flex justifyContent="space-between">
            User Type
            {filterValue?.user_type ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px' }}
                onClick={() => {
                  onChange({
                    //@ts-expect-error
                    target: { name: 'user_type', value: undefined }
                  });
                }}
              />
            ) : null}
          </Flex>
          <SelectInput
            id="user_type"
            name="user_type"
            placeholdertitle="Select One"
            value={filterValue?.user_type || null}
            onChange={(selectedOption: any) => {
              onChange({
                target: { name: 'user_type', value: selectedOption }
              } as React.ChangeEvent<HTMLInputElement>);
            }}
          >
            {userType.map((i) => (
              <Option key={i?.id} value={i?.id}>
                {i?.label}
              </Option>
            ))}
          </SelectInput>
        </Flex>
      ),
      dataIndex: 'user_type',
      width: '5%',
      render: (data: any) => {
        return data ? userType.find((item: any) => item.id === data)?.label : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Phone Number"
          name="phone_number"
          value={filterValue.phone_number}
          placeholder="Phone Number"
          onChange={onChange}
        />
      ),
      dataIndex: 'phone_number',
      width: '25%',
      render: (data: any, row: any) => {
        const countryCode = row?.country_code;
        const phone = row?.phone_number;
        const extension = row?.extension;
        const phoneNo = `${countryCode || ''} ${phone || ''} ${extension ? `x${extension}` : ''}`;
        return phoneNo ? (
          <Flex
            className="Td-fixing"
            style={{ width: '100%' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <span>{phoneNo}</span>
            <StatusLabel status={row?.status} isUppercase={true} />
          </Flex>
        ) : (
          '-'
        );
      }
    }
    // {
    //   title: <></>,
    //   dataIndex: 'status',
    //   width: '20%',
    //   render: (status: any) => {
    //     return <StatusLabel status={status} isUppercase />;
    //   }
    // }
  ];
  return productColumns;
};

// api calls
export const getEmployeeTableData = async (
  status: 'all' | 'active' | 'in-active',
  pageNo: any,
  data: EmployeeListFilterType
): Promise<ResponseType> => {
  try {
    let statusKey = null;
    if (status === 'active') statusKey = 1;
    if (status === 'in-active') statusKey = 0;

    const res = (await services.post(
      `/employees/list${statusKey !== null ? `/${statusKey}` : ''}?page=${pageNo}`,
      data
    )) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

const filters = {
  email: '',
  name: '',
  phone_number: '',
  position: '',
  user_type: '',
  page: 'all'
};

const fetchEmployeeData = async () => {
  try {
    const res = await getEmployeeTableData('all', 'all', filters);
    if (res.data.data) {
      return res.data.data;
    }
  } catch (error) {
    console.error('Error fetching employee data:', error);
  }
};

export const handleDownloadSheet = async () => {
  const data = await fetchEmployeeData();

  if (!data || !data.length) {
    console.error('No data to download');
    return;
  }

  const headerRow = [
    'Employee Name',
    'Position(s)',
    'Email',
    'User Type',
    'Phone Number',
    'Status'
  ];

  const employeeRows: string[][] = data.map((employee: EmployeeRowsTypes) => [
    employee.name,
    employee.employee_positions.map((pos: any) => pos.name).join(', '),
    employee.email,
    userType.find((type) => type.id === employee.user_type)?.label || '',
    employee.phone_number,
    employee.status_info.status_name
  ]);

  const employeeData: string[][] = [headerRow, ...employeeRows];

  downloadsheet(employeeData, 'The-FoodDudes-Employees.csv');
};
