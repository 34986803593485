import { Col, Row } from 'antd';
import { ContactDetailsType, IAdditionalContactProps } from '../types';
import { useEffect, useState } from 'react';

import { Button } from '@atom/Buttons';
import { ButtonWrapper, ContentWrapper } from './style';
import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import InputPhone from '@atom/InputPhone';
import { Label } from '@atom/FormLable';
import PlusCircle from '@assets/plus-circle.svg';
import RemoveIcon from '@assets/removerow.png';

function AdditionalContacts({
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  handleBlur,
  index,
  onAddContact,
  onRemove,
  showRemove,
  item,
  showAdd
}: IAdditionalContactProps) {
  const [contactItem, setContactItem] = useState<any>({});
  useEffect(() => {
    if (item.id || item.localId) {
      setContactItem({ ...item });
    }
  }, [item.id, item.localId]);

  const phoneChangeHandler = (a: ContactDetailsType) => {
    setTimeout(() => {
      setFieldValue(`additional_contacts[${index}].country_code`, a.code ?? ``);
      setFieldValue(`additional_contacts[${index}].extension`, a.ext ?? ``);
      setFieldValue(`additional_contacts[${index}].contact_phone_number`, a.phone ?? ``);
      setFieldTouched(`additional_contacts[${index}].country_code`, true);
      setFieldTouched(`additional_contacts[${index}].extension`, true);
      setFieldTouched(`additional_contacts[${index}].contact_phone_number`, true);
    }, 200);
    setContactItem({
      ...contactItem,
      country_code: a.code ?? `+1`,
      extension: a.ext ?? ``,
      contact_phone_number: a.phone ?? ``
    });
  };

  const handleChange = (e: any, key: string) => {
    if (e?.target?.id) {
      setTimeout(() => {
        setFieldValue(e?.target?.id, e?.target?.value);
      }, 200);
      setContactItem({ ...contactItem, [key]: e?.target?.value });
    }
  };

  return (
    <>
      <ContentWrapper>
        <Row gutter={[16, 16]}>
          <Col md={6} xs={24}>
            <Label isMandatory={true} text="Contact Name" />
            <Input
              name={`additional_contacts.${index}.contact_name`}
              id={`additional_contacts.${index}.contact_name`}
              error={
                touched['additional_contacts']?.[index]?.contact_name &&
                errors['additional_contacts']?.[index]?.contact_name
              }
              placeholder="Type Name"
              onChange={(e: any) => handleChange(e, 'contact_name')}
              value={contactItem?.contact_name}
              onBlur={handleBlur}
            />
          </Col>

          <Col md={8} xs={24}>
            <Label text="Contact Email" />
            <Input
              name={`additional_contacts.${index}.contact_email`}
              id={`additional_contacts.${index}.contact_email`}
              error={
                touched['additional_contacts']?.[index]?.contact_email &&
                errors['additional_contacts']?.[index]?.contact_email
              }
              placeholder="Type Email"
              onChange={(e: any) => handleChange(e, 'contact_email')}
              value={contactItem?.contact_email}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={6} xs={20}>
            <Label text="Contact Phone Number" />
            <InputPhone
              id="phone_number"
              name="phone_number"
              value={{
                code: contactItem?.country_code ?? '+1',
                phone: String(contactItem?.contact_phone_number ?? '') ?? '',
                ext: String(contactItem?.extension || '') ?? ''
              }}
              error={
                (touched['additional_contacts']?.[index]?.contact_phone_number &&
                  errors['additional_contacts']?.[index]?.contact_phone_number) ||
                (touched['additional_contacts']?.[index]?.country_code &&
                  errors['additional_contacts']?.[index]?.country_code) ||
                (touched['additional_contacts']?.[index]?.extension &&
                  errors['additional_contacts']?.[index]?.extension) ||
                ''
              }
              onBlur={handleBlur}
              onChange={phoneChangeHandler}
            />
          </Col>
          <Col
            style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
            md={4}
            xs={4}>
            {showRemove && index > 0 && (
              <img src={RemoveIcon} alt="remove" style={{ cursor: 'pointer' }} onClick={onRemove} />
            )}
          </Col>
        </Row>
        {showAdd && (
          <Row>
            <Col md={4} xs={24}>
              <Flex bottom={24}>
                <Button
                  text="Add More"
                  variant="secondary"
                  icon={<img width={20} src={PlusCircle} alt="add" />}
                  onClick={onAddContact}
                />
              </Flex>
            </Col>
          </Row>
        )}
      </ContentWrapper>
    </>
  );
}

export default AdditionalContacts;
