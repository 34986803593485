import { MinusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import SelectInput from '@atom/Select';
import ConfirmModal from '@organism/ConfimationModal';
import { INGREDIENT_FORM_STATUS } from '@pages/MenuManagementPage/helper';
import { updateRawData } from '@utils/index';
import { Select } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectSubCategoryWrapper } from './style';

interface SubCategoryItemProps {
  item: any;
  index: number;
  setCurrentSubCategory: Dispatch<SetStateAction<number | null>>;
  isActiveSubCategory: boolean;
  subCategoriesOption: any;
  setFieldValue: any;
  values: any;
  arrayHelper: any;
  submitIngredients?: () => void;
  formikSubCategoriesArray?: any[];
  isPrimaryBtn?: boolean;
}

const SubCategoryItem: React.FC<SubCategoryItemProps> = ({
  item,
  index,
  setCurrentSubCategory,
  isActiveSubCategory = false,
  subCategoriesOption = [],
  arrayHelper,
  setFieldValue,
  submitIngredients,
  values
}) => {
  const { ingredientsFormState } = useSelector((state: any) => state?.rawData);
  const [isChangeWarningModalActive, setIsChangeWarningModalActive] = useState(false);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);

  const onWarningModalCancel = () => {
    setCurrentSubCategory(index);
    updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
    setIsChangeWarningModalActive(false);
  };

  const savedProgress = () => {
    submitIngredients && submitIngredients();
    setIsChangeWarningModalActive(false);
  };

  const onDeleteModalCancel = () => {
    setIsDeleteModalActive(false);
  };

  const onDeleteModalConfirm = () => {
    arrayHelper.remove(index);
    setIsDeleteModalActive(false);
  };

  return (
    <>
      <SelectSubCategoryWrapper isPrimaryBtn={item.is_primary}>
        <Flex
          className="select-box"
          style={{
            width: '100%',
            marginBottom: '0px',
            position: 'relative',
            gap: '12px',
            padding: '12px',
            borderBottom: '1px solid #CDCDCD',
            backgroundColor: isActiveSubCategory ? 'rgba(253, 126, 20, 0.1)' : ''
          }}
        >
          <Button
            variant="ghost"
            icon={<MinusCircleOutlined />}
            onClick={() => setIsDeleteModalActive(true)}
          />

          {isActiveSubCategory ? (
            <>
              <SelectInput
                className="select-category-cls"
                id={'id'}
                name={'id'}
                value={item?.id || item?.id}
                placeholdertitle="Enter Sub Category"
                onChange={(value: any) => {
                  setFieldValue && setFieldValue(`sub_category_array.${index}.id`, value);
                  setCurrentSubCategory(index);
                }}
              >
                {subCategoriesOption
                  ?.filter((item: any, filterIndex: number) => {
                    return values?.sub_categories?.every((findItem: any, findIndex: number) => {
                      if (findItem === item?.id) {
                        if (index === findIndex) return true;
                        else return false;
                      } else return true;
                    });
                  })
                  ?.map((item: any) => (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
              </SelectInput>

              {!!item?.is_primary && <span className="primary-btn-edit">Primary</span>}
            </>
          ) : (
            <p
              style={{
                cursor: 'pointer',
                width: '100%',
                height: '100%',
                position: 'relative',
                marginBottom: '0'
              }}
              onClick={() => {
                if (
                  ingredientsFormState &&
                  ingredientsFormState === INGREDIENT_FORM_STATUS.MODIFIED
                ) {
                  setIsChangeWarningModalActive(true);
                } else {
                  updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
                  setCurrentSubCategory(index);
                }
              }}
            >
              {subCategoriesOption?.find((option: any) => option?.id === item?.id)?.name ?? (
                <>&nbsp;</> // given to have min-height so to click this box when no option selected from dropdown
              )}
              {!!item?.is_primary && <span className="primary-btn-view">Primary</span>}
            </p>
          )}
        </Flex>
      </SelectSubCategoryWrapper>
      {/* Confirm to save */}
      {isChangeWarningModalActive && (
        <ConfirmModal
          visible={isChangeWarningModalActive}
          onCancel={onWarningModalCancel}
          title="You are about to leave the Ingredient form"
          onOK={savedProgress}
          // isModalLoading={loading}
          cancelText={'Leave'}
          okText="Save"
        >
          <span>
            It looks like you have not saved the ingredients of the sub category. Make sure you save
            your progress before moving to another sub category.
          </span>
        </ConfirmModal>
      )}
      {/* Delete Modal */}
      {isDeleteModalActive && (
        <ConfirmModal
          visible={isDeleteModalActive}
          onCancel={onDeleteModalCancel}
          title="Are you sure you want to delete this sub category?"
          onOK={onDeleteModalConfirm}
          // isModalLoading={loading}
          okText="Delete"
        >
          <span>
            Deleting this sub category will delete its existing ingredients. This action cannot be
            undone.
          </span>
        </ConfirmModal>
      )}
    </>
  );
};

export default SubCategoryItem;
