import { CURRENCY, QUOTE_PROPOSAL_IMAGES } from '@constants/index';
import services from '@services/index';
import { showErrorToast } from '@utils/index';

export const getQuote = async (quoteId: any) => {
  try {
    const res = (await services.get(`/quotes/${quoteId}`)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getSubTotal = (data: any, sectionKey: string) => {
  let subTotal = 0;
  data?.[sectionKey]?.forEach((i: any) => (subTotal += Number(i?.total_cost)));
  return subTotal?.toFixed(2);
};

export const getLabel = (
  data: any,
  foodFeesKey: string,
  foodPercKey: string,
  alcoholFeesKey: string,
  alcoholPercKey: string,
  labourFeesKey: string,
  labourPercKey: string,
  netTotalKey?: string,
  isDiscount?: boolean
) => {
  const is_dollar = isDiscount && data?.is_discount_in_dollar;
  let result = '';
  if (netTotalKey && Number(data?.[netTotalKey])) {
    return `${is_dollar ? CURRENCY : ''}${Number(data?.[netTotalKey]) || '-'}${
      !is_dollar ? '%' : ''
    }`;
  }
  if (data?.[foodFeesKey])
    result += Number(data?.[foodPercKey])
      ? `Food: ${is_dollar ? CURRENCY : ''}${data?.[foodPercKey]}${!is_dollar ? '%' : ''}`
      : '';
  if (Number(data?.[alcoholFeesKey])) {
    if (result) result += ', ';
    result += Number(data?.[alcoholPercKey])
      ? `Alcohol: ${is_dollar ? CURRENCY : ''}${data?.[alcoholPercKey]}${!is_dollar ? '%' : ''}`
      : '';
  }
  if (Number(data?.[labourFeesKey])) {
    if (result) result += ', ';
    result += Number(data?.[labourPercKey])
      ? `Labour: ${is_dollar ? CURRENCY : ''}${data?.[labourPercKey]}${!is_dollar ? '%' : ''}`
      : '';
  }
  return result;
};

export const getIsExpandedArray = (data: any) => {
  return data?.event_dates?.meal_plans?.map((i: any, index: number) =>
    Array.from({ length: data?.event_dates?.meal_plans?.[index]?.bundles?.length }, (v, k) => false)
  );
};

export enum ACTION_TYPES {
  EDIT = 2,
  APPROVE = 3,
  REJECT = 4
}

export const actionDetails: any = {
  [ACTION_TYPES.EDIT]: {
    title: 'By editing this quote you will be creating a version history',
    body: 'By editing this quote you will be creating a version history, storing any other versions ofthis quote.',
    okText: 'Continue Editing',
    cancelText: 'No, Cancel'
  },
  [ACTION_TYPES.APPROVE]: {
    title: 'Are you sure you want to Approve this Quote?',
    body: 'This quote will be approved and will be available to create events.',
    okText: 'Approve',
    cancelText: 'Cancel'
  },
  [ACTION_TYPES.REJECT]: {
    title: 'Are you sure you want to Reject this Quote?',
    body: 'This quote will be rejected and will not be available to create events.',
    okText: 'Reject',
    cancelText: 'Cancel'
  }
};

export const enum IMAGE_TYPE {
  COVER_PAGE = 0,
  END_PAGE = 1,
  LOGO = 2
}

export const getGenerateOverviewProposalModalData = (data?: any) => {
  return {
    quote_id: data?.quote_id,
    pdf_images: [
      {
        id: 1,
        unique_key: data?.unique_key,
        title: 'Cover Page',
        name: 'Cover Page',
        slug: 'cover_page',
        image: data?.cover_page || QUOTE_PROPOSAL_IMAGES.coverImage
      },
      {
        id: 2,
        unique_key: data?.unique_key,
        title: 'End Page',
        name: 'End Page',
        slug: 'end_page',
        image: data?.end_page || QUOTE_PROPOSAL_IMAGES.endPage
      },
      {
        id: 3,
        unique_key: data?.unique_key,
        title: 'Logo',
        name: 'Logo',
        slug: 'logo',
        image: data?.logo || QUOTE_PROPOSAL_IMAGES.logo
      }
    ]
  };
};

export const generatePdf = async (data: any) => {
  try {
    const res = (await services.post(`/quotes/generate-pdf`, data)) as {
      data: any;
    };
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const updateQuoteStatus = async (
  status: any,
  quote_id: any,
  unique_invoice_string?: string
) => {
  try {
    const res = (await services?.post(`/quotes/change-status`, {
      quote_id: Number(quote_id),
      status: status,
      unique_invoice_string: unique_invoice_string
    })) as {
      data: any;
    };
    if (!res?.data?.status) {
      showErrorToast({
        message: '',
        description: res?.data?.message
      });
    }
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getBreadCrumbsRouteViewEventpath = () => {
  const paths = [
    {
      path: '/quote/tab/all',
      name: 'Quote Lists'
    },
    {
      name: 'Quote Details'
    }
  ];
  return paths;
};

export const EditEventBreadCrumbsRouteCreateEvent = (quoteId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    { name: 'Create Event' }
  ];
};

export const QuoteEditQuoteBreadCrumbsRoute = (quoteId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    { name: 'Edit Quote' }
  ];
};

export const EditQuoteBreadCrumbsRouteCreateEvent = (quoteId: any, eventId: any) => {
  return [
    { name: 'Event Lists', path: `/events/upcoming` },
    { name: 'Event Details', path: `/events/${eventId}/view` },
    { name: 'Find Quote' }
  ];
};

export const BreadCrumbsEventCreate = () => {
  return [{ name: 'Quote Lists', path: `/quote/tab/all` }, { name: 'Create Quote ' }];
};

export const BreadCrumbsEventRouteEventFindQuoteEditQuote = (quoteId: any, eventId: any) => {
  return [
    { name: 'Event Lists', path: `/events/upcoming` },
    { name: 'Event Details', path: `/events/${eventId}/view` },
    {
      name: 'Edit Event',
      path: `/events/find-quote/create/${quoteId}/${eventId}/quote`,
      state: { path: 'event_details' }
    },
    { name: 'Edit Quote' }
  ];
};

export const BreadCrumbsEventFindRouteSelectEditQuote = (quoteId: any) => {
  return [
    { name: 'Event Lists', path: `/events/upcoming` },
    { name: 'Select Quote', path: '/events/find-quote' },
    {
      name: 'Create Quote',
      path: `/events/find-quote/create/${quoteId}/quote`,
      state: { path: 'select_quote' }
    },
    { name: 'Edit Quote' }
  ];
};

export const QuoteDetailsCreateEventEditQuoteBreadCrumbs = (quoteId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    {
      name: 'Create Event',
      path: `/events/find-quote/create/${quoteId}/quote`,
      state: { path: 'quote_details' }
    },
    { name: 'Edit Quote' }
  ];
};

export const imagePreviewStyle = {
  width: '150px', 
  height: '150px', 
  // border: "1px solid black",
  // padding: "4px",
  // backgroundColor: "#f8f8f8",
}