import styled from 'styled-components';

export const TooltipWrapper = styled.span`
  line-height: 16px;
  margin-left: 4px;
  .ant-tooltip-inner {
    font-size: 14px;
    line-height: 12px;
  }

  .tooltip-style {
    font-size: 12px;
    line-height: 12px;
    margin-left: 5px;
    margin-top: -6px;
  }
`;
