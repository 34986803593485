import services from '@services/index';
import * as Yup from 'yup';

export const notesInitialValues = {
  event_date: '',
  important_notes: undefined,
  manager_notes: undefined,
  id: undefined
};

export const getNotesFormInitialValues = (data?: any, isEventsTab?: boolean) => {
  if (isEventsTab) {
    return {
      event_date: data?.event_date || '',
      important_notes: data?.important_notes || undefined,
      manager_notes: data?.manager_notes || undefined,
      id: data?.id || undefined
    };
  }
  return {
    event_date: data?.note?.event_date || '',
    important_notes: data?.note?.important_notes || undefined,
    manager_notes: data?.note?.manager_notes || undefined,
    id: data?.note?.id || undefined
  };
};

export const validationSchema = Yup.object({
  event_date: Yup.mixed(),
  important_notes: Yup.string()
    .max(3000, 'Important Notes cannot be more than 3000 characters.')
    .nullable(),
  manager_notes: Yup.string()
    .max(19000, 'Manager Notes cannot be more than 19000 characters.')
    .nullable(),
  id: Yup.number()
});

export const addEditNotes = async (data: any, id?: any) => {
  try {
    const res = (await services?.post(`/events/notes`, data)) as {
      data: any;
    };
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getNotes = async (isEvent: boolean, quote_id?: string, eventParam?: string) => {
  const url = isEvent ? `/events/notes?${eventParam}` : `/quotes/${quote_id}/notes`;
  try {
    const res = (await services.get(url)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
