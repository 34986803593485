import CustomerInformation from '@pages/QuoteManagementPage/ViewQuoteDetails/CustomerInformation';
import EventDetails from '@pages/QuoteManagementPage/ViewQuoteDetails/EventDetails';
import MealPlanAndEstimate from '@pages/QuoteManagementPage/ViewQuoteDetails/MealPlanAndEstimate';
import VenueInformation from '@pages/QuoteManagementPage/ViewQuoteDetails/VenueInformation';
import React, { useState } from 'react';
import AdditionalStaff from './AdditionalStaff';
import EventActionButtons from './EventActionButtons';
import EventTimeline from './EventTimeline';
import GuestAllergies from './GuestAllergies';
import RemainderVenueDetails from './RemainderVenueDetails';
import StaffArrivalTime from './StaffArrivalTime';
import Vendor from './Vendor';
import Notes from './Notes';

interface EventInfoProps {
  eventData: any;
  getEventDetailMethod?: (eventId: string) => void;
}

const EventInfo: React.FC<EventInfoProps> = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDowloadPdfModalVisible, setIsDowloadPdfModalVisible] = useState<boolean>(false);
  const [isContractDowloadPdfModalVisible, setIsContractDowloadPdfModalVisible] =
    useState<boolean>(false);
  const { eventData, getEventDetailMethod } = props;

  return (
    <>
      <EventDetails data={eventData} isEvent={true} getEventDetailMethod={getEventDetailMethod} />
      <CustomerInformation data={eventData} isEvent={true} />
      <VenueInformation data={eventData?.venue_detail} isEvent={true} />
      <Notes data={eventData?.manager_notes} title={'Important Notes'} />
      <EventTimeline data={eventData} />
      <MealPlanAndEstimate
        data={eventData?.meal_plan_estimate}
        isEventData={true}
        eventDate={eventData?.event_detail?.event_date}
      />
      <GuestAllergies data={eventData?.guest_allergies} />
      <StaffArrivalTime data={eventData?.event_staff_employees} />
      <AdditionalStaff data={eventData?.event_add_staff_employees} />
      <Notes data={eventData?.manager_notes} title={'Manager Notes'} />
      <RemainderVenueDetails data={eventData} />
      <Vendor data={eventData} />
      <EventActionButtons
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        isDowloadPdfModalVisible={isDowloadPdfModalVisible}
        isContractDowloadPdfModalVisible={isContractDowloadPdfModalVisible}
        setIsDowloadPdfModalVisible={setIsDowloadPdfModalVisible}
        setIsContractDowloadPdfModalVisible={setIsContractDowloadPdfModalVisible}
        getEventDetailMethod={getEventDetailMethod}
        data={eventData?.event_detail}
        locationDetail={eventData?.venue_detail}
        hasBottomButtons={true}
      />
    </>
  );
};

export default EventInfo;
