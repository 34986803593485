import styled from 'styled-components';

export const ProfilePageWrapper = styled.div`
  .description-margin {
    margin-bottom: 0px;
  }

  .registration-col {
    text-align: end;
    @media (max-width: 767px) {
      text-align: left !important;
    }
  }

  @media (max-width: 767px) {
    .data-container {
      display: flex;
      flex-direction: column-reverse;
    }
    .profile-image-wrapper {
      display: flex;
      justify-content: center;
    }

    .profile-image {
      width: 100%;
    }
  }
`;
