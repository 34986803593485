import { Input } from '@atom/Input';
import SelectInput from '@atom/Select';
import styled from 'styled-components';

export const EditableCellWithInput = styled(Input)`
  margin-bottom: 0px !important;
  width: 90% !important;
`;

export const EditableCellWithSelectInput = styled(SelectInput)`
  margin-bottom: 0px !important;
  width: 90% !important;
`;
