import { VENUE_MANAGEMENT_TABS } from '@pages/VenueManagementPage/ListViewPages/helper';
import VendorDataTable from '../VendorDataTable';

export const getTabs = (activePanel: string) => {
  const tabValues = [
    {
      tab: 'ALL',
      content: (
        <VendorDataTable activePanel={activePanel} currentPanel={VENUE_MANAGEMENT_TABS.ALL} />
      ),
      key: VENUE_MANAGEMENT_TABS.ALL,
      getUrl: VENUE_MANAGEMENT_TABS.ALL
    },
    {
      tab: 'ACTIVE',
      content: (
        <VendorDataTable activePanel={activePanel} currentPanel={VENUE_MANAGEMENT_TABS.ACTIVE} />
      ),
      key: VENUE_MANAGEMENT_TABS.ACTIVE,
      getUrl: VENUE_MANAGEMENT_TABS.ACTIVE
    },
    {
      tab: 'INACTIVE',
      content: (
        <VendorDataTable activePanel={activePanel} currentPanel={VENUE_MANAGEMENT_TABS.INACTIVE} />
      ),
      key: VENUE_MANAGEMENT_TABS.INACTIVE,
      getUrl: VENUE_MANAGEMENT_TABS.INACTIVE
    },
    {
      tab: 'DRAFTS',
      content: (
        <VendorDataTable activePanel={activePanel} currentPanel={VENUE_MANAGEMENT_TABS.DRAFTS} />
      ),
      key: VENUE_MANAGEMENT_TABS.DRAFTS,
      getUrl: VENUE_MANAGEMENT_TABS.DRAFTS
    }
  ];
  return tabValues;
};
