import styled from 'styled-components';

export const ImagePreviewerWrapper = styled.div`
  width: 178px;
  height: 118px;
  display: flex;
  .transparent-background {
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
      linear-gradient(-45deg, #ccc 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #ccc 75%),
      linear-gradient(-45deg, transparent 75%, #ccc 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
`;

export const CloseIconImg = styled.img`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

export const ImageName = styled.p`
  margin-top: 10px;
`;
