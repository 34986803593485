import { Col, Divider, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { IAddEditInfoProps } from '@pages/VenueManagementPage/types';
import { Label } from '@atom/FormLable';
import RadioButton from '@atom/RadioButton';
import { TextAreaInput } from '@atom/TextArea';
import { getIn } from 'formik';
import { ContentWrapper } from '../style';

function DockParkingSection(props: any) {
  const {
    loadingDockValue,
    id,
    handleBlur,
    setFieldValue,
    parkingValue,
    infoValue,
    loadingDockError,
    parkingError,
    infoError
  } = props;
  const [loadingDock, setLoadingDock] = useState<any>();
  const [parking, setParking] = useState<any>();
  const [infoState, setInfoState] = useState<any>();

  useEffect(() => {
    if (id && !!loadingDockValue) {
      setLoadingDock(loadingDockValue);
    }
    if (id && !!parkingValue) {
      setParking(parkingValue);
    }
    if (id && !!infoValue) {
      setInfoState(infoValue);
    }
  }, [id]);

  const handleChange = (e: any, setState: (value: any) => void) => {
    setTimeout(() => {
      setFieldValue(e?.target?.id, e?.target?.value);
    }, 200);
    setState(e?.target?.value);
  };

  const handleRadioChange = (e: any, setState: (value: any) => void) => {
    setTimeout(() => {
      setFieldValue(e?.target?.name, e?.target?.value);
    }, 200);
    setState(e?.target?.value);
  };

  return (
    <Flex style={{ width: '100%' }} direction="column">
      <H2Typography style={{ marginTop: '15px' }}>Loading Dock & Parking Info</H2Typography>
      <Divider style={{ borderColor: '#e0e0e0', margin: '15px 0px' }}></Divider>
      <ContentWrapper>
        <Row gutter={16} justify="space-between" style={{ width: '100%', marginBottom: '24px' }}>
          <Col xs={24} lg={12}>
            <Label text="Loading Dock" />
            <RadioButton
              name="venue_detail.loading_dock"
              id="venue_detail.loading_dock"
              value={loadingDock && Number(loadingDock)}
              onChange={(e) => handleRadioChange(e, setLoadingDock)}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </RadioButton>
          </Col>
          <Col xs={24} lg={12}>
            <Label text="Parking" />
            <RadioButton
              name="venue_detail.parking"
              id="venue_detail.parking"
              value={parking && Number(parking)}
              onChange={(e) => handleRadioChange(e, setParking)}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </RadioButton>
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" style={{ width: '100%' }}>
          <Col xs={24} lg={24}>
            <Label text="Additional Info" />
            <TextAreaInput
              id={`venue_detail.loading_parking_additional_information`}
              name={`venue_detail.loading_parking_additional_information`}
              placeholder="Additional Info"
              error={infoError}
              value={infoState}
              onChange={(e) => handleChange(e, setInfoState)}
              onBlur={handleBlur}
              allowClear={true}
            />
          </Col>
        </Row>
      </ContentWrapper>
    </Flex>
  );
}
export default DockParkingSection;
