import services from '@services/index';
import { DepartmentsType, PositionsType } from '../AddEditEmployee/helper';
import { ResponseType } from '../helper';
import EmployeeRecordTable from './EmployeeRecordTable';

// types
export interface EmployeeDetailsType {
  id: string;
  name: string;
  email: string;
  country_code: string;
  formated_phone_number: string;
  extension: string;
  address: string;
  user_type: number;
  employee_departments: DepartmentsType[];
  employee_positions: PositionsType[];
  reports_to: number;
  invite_sent_at: string;
  status_info: {
    status_name: string;
  };
  status: number;
  profile_pic: string | null;
  first_login_at: string | null;
}

// constant
export const getEmployeeRecordsTabValues = (tab: string) => [
  {
    tab: 'Upcoming Events',
    content: <EmployeeRecordTable tab="upcoming" currentTab={tab} />,
    key: 'upcoming'
  },
  { tab: 'Past Events', content: <EmployeeRecordTable tab="past" currentTab={tab} />, key: 'past' }
];

// get table columns
export const getEmployeeRecordsColumns = (currentTab: string) => {
  const productColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'Event Name',
      dataIndex: 'event_name',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: currentTab === 'upcoming' ? 'Start Time' : 'Total Hours',
      dataIndex: currentTab === 'upcoming' ? 'arrival_time' : 'hours',
      width: '20%',
      render: (data: any) => {
        return data ? (currentTab === 'upcoming' ? data : `${data} hours worked`) : '-';
      }
    }
  ];
  return productColumns;
};

// api calls
export const getEmployeeDetails = async (id: string): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/employees/view/${id}`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const getUpcomingRecords = async (id: string, pageNo: number): Promise<ResponseType> => {
  try {
    const res = (await services.post(
      `/employees/upcoming-records/${id}?page=${pageNo}`,
      {}
    )) as any;
    return { data: res?.data?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const getPastRecords = async (id: string, pageNo: number): Promise<ResponseType> => {
  try {
    const res = (await services.post(`/employees/past-records/${id}?page=${pageNo}`, {})) as any;
    return { data: res?.data?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const sendInvitation = async (id: string): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/employees/send-invitation/${id}`)) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const changeStatus = async (id: string): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/employees/change-status/${id}`)) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};
