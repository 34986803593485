import { Col, Row } from 'antd';
import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import { getLabourEstimateColumns } from './columns';
import { SectionTitleDiv, SubtotalAndCostDiv } from './style';
import { getSubTotal } from './helper';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';

interface LabourEstimateProps {
  data: any;
}

const getMappedTableData = (eventDatesData: any) => {
  let tableData = [];
  tableData = eventDatesData?.labours?.length
    ? eventDatesData?.labours?.map((i: any) => ({
        position: i?.position?.name,
        notes: i?.notes,
        qty: i?.quantity,
        hrs: i?.hours,
        rate: i?.rate,
        cost: i?.total_cost,
        waived_off: !!i?.waived_off
      }))
    : [];

  //to view last row for subtotal in table
  tableData.push({
    position: '',
    notes: '',
    qty: '',
    hrs: '',
    rate: 'SUBTOTAL',
    cost: getSubTotal(eventDatesData, 'labours'),
    waived_off: ''
  });
  return tableData;
};

const LabourEstimate = (props: LabourEstimateProps) => {
  const { data } = props;
  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'quote-section'}>Labour Estimate</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} style={{ marginTop: '20px' }}>
          <DataTableWrapper>
            <DataTable
              className="fixTD"
              renderEmpty={() => {
                return <>No Data Found.</>;
              }}
              dataSource={getMappedTableData(data?.event_dates)}
              columns={getLabourEstimateColumns()}
            />
          </DataTableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default LabourEstimate;
