import CenterSpiner from '@atom/CenterSpiner';
import { H2Typography } from '@constant/typography/Typography';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';
import { GetTabData, PutTabData } from '@services/quoteServices';
import { showErrorToast, showToast } from '@utils/index';
import { Divider, Row } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import AddEditQuoteFooter from '../AddEditQuoteFooter';
import EventBasicDetails from '../EventBasicDetails';
import { getTabBaseUrl, QuoteBasicDetailsObject } from '../helper';
import DepositPaymentAndHstSection from './DepositPaymentAndHstSection';
import DiscountSection from './DiscountSection';
import {
  feesFormInitialValues,
  feesFormValidations,
  getMappedData,
  getMappedPayload
} from './helper';
import ServiceFee from './ServiceFee';

interface FeesFormProps {
  tabKey: string;
  quoteBasicDetails: QuoteBasicDetailsObject;
  setQuoteBasicDetails: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  navigationUrl?: string;
  isEventsTab?: boolean;
  nextButtonTitle?: string;
  submitForm?: (tabKey: string, values: any, actions: any, method?: string) => void;
}

const FeesForm = (props: FeesFormProps) => {
  const {
    tabKey,
    navigationUrl,
    setQuoteBasicDetails,
    isEventsTab = false,
    quoteBasicDetails,
    nextButtonTitle,
    submitForm
  } = props;
  const { quoteId, eventId } = useParams();
  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);

  const navigate = useNavigate();
  const location = useLocation();
  const [formikData, setFormikData] = useState(feesFormInitialValues);
  const [draftSubmitting, setDraftSubmitting] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const formik = useFormik({
    validationSchema: feesFormValidations,
    onSubmit: (values: any, actions: any) => {
      submitHandler({ ...values, status: STATUS.ACTIVE }, actions);
    },
    initialValues: feesFormInitialValues
  });
  const { handleSubmit, setValues, values, setFieldValue } = formik;

  const updateInitialData = async () => {
    setPageLoading(true);
    try {
      const res = await GetTabData(tabKey, quoteId ?? '', isEventsTab);
      if (res?.success) {
        setFormikData((prev) => getMappedData({ ...prev }, { ...res?.data }));
        setValues(getMappedData({ ...values }, { ...res?.data }));
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id,
          quotation_no: res?.data?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string
        }));
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch quote data.'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch quote data.'
      });
    }
    setPageLoading(false);
  };

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    if (!isDraft && submitForm) {
      submitForm(
        tabKey,
        {
          ...values,
          fees: {
            ...values?.event_dates?.fees,
            discount_amount_percent:
              values?.event_dates?.fees?.discount_alcohol_fees_percent ||
              values?.event_dates?.fees?.discount_food_fees_percent ||
              values?.event_dates?.fees?.discount_labour_fees_percent
                ? 0
                : values?.event_dates?.fees?.discount_amount_percent
          }
        },
        actions,
        'post'
      );
      return;
    }
    const res: any = await PutTabData(tabKey, {
      ...quoteBasicDetails,
      ...getMappedPayload(values),
      id: quoteId,
      unique_invoice_string: random_unique_invoice_string
        ? random_unique_invoice_string
        : quoteBasicDetails.unique_invoice_string,
      event_dates: {
        ...values?.event_dates,
        fees: {
          ...values?.event_dates?.fees,
          discount_amount_percent:
            values?.event_dates?.fees?.discount_alcohol_fees_percent ||
            values?.event_dates?.fees?.discount_food_fees_percent ||
            values?.event_dates?.fees?.discount_labour_fees_percent
              ? 0
              : values?.event_dates?.fees?.discount_amount_percent
        },
        status: isDraft ? values?.status : STATUS.ACTIVE,
        event_date: values?.event_dates?.event_date?.[0] ?? ''
      }
    });
    if (res?.success) {
      actions?.setSubmitting(false);
      setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
        ...prev,
        id: res?.data?.id || prev?.id,
        quotation_no: res?.data?.quotation_no || prev?.quotation_no,
        status: res?.data?.status,
        unique_invoice_string: random_unique_invoice_string
          ? random_unique_invoice_string
          : res?.data?.unique_invoice_string || prev?.unique_invoice_string
      }));
      const tabBaseUrl = getTabBaseUrl(
        location?.pathname,
        res?.data?.id || quoteId,
        quoteBasicDetails,
        res?.data?.id
      );
      navigate(
        isDraft
          ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}`
          : !submitForm && res?.data?.id
          ? `/quote/view/${res?.data?.id}`
          : navigationUrl || '/quote',
        {
          state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
        }
      );
      if (isDraft) {
        showToast({
          message: 'Success!',
          description: 'Quotation saved as Draft'
        });
      }
    } else {
      actions?.setSubmitting(false);
      actions?.setErrors(res?.error);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
  };

  const backHandler = () => {
    if (isEventsTab) {
      const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.OTHERS}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}other`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };

  const cancelHandler = () => {
    if (isEventsTab) {
      navigate('/events');
    } else {
      navigate('/quote');
    }
  };
  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    setFieldValue('status', STATUS.DRAFT);
    await submitHandler({ ...values, status: STATUS.DRAFT }, null, true);
    setDraftSubmitting(false);
  };
  const nextHandler = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (tabKey === 'fees') {
      updateInitialData();
    }
  }, [tabKey]);

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <>
      <EventBasicDetails
        eventName="EVENT"
        eventDate="Sat, 28 Oct"
        venueName="Pune"
        customerName="Amit"
      />
      <Row style={{ marginTop: 40 }}>
        <H2Typography>Service Fee</H2Typography>
        <Divider />
      </Row>
      <ServiceFee formik={formik} />
      <DiscountSection formik={formik} />
      <DepositPaymentAndHstSection formik={formik} />
      <AddEditQuoteFooter
        showBackButton={true}
        isDraftSubmitting={draftSubmitting}
        nextButtonTitle={nextButtonTitle}
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        saveAsDraftClickHandler={!isEventsTab ? saveAsDraftHandler : undefined}
        nextClickHandler={nextHandler}
        isSubmitting={formik?.isSubmitting}
      />
    </>
  );
};

export default FeesForm;
