import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import DatePickerComponent from '@atom/DatePicker';
import { Flex } from '@atom/Flex';
import RadioButton from '@atom/RadioButton';
import TaskComponent from '@atom/TaskComponent';
import { PageTitle } from '@molecules/PageTitle';
import { showErrorToast, showToast } from '@utils/index';
import { Row, Col, Radio } from 'antd';
import { getIn, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  EventTaskType,
  getEventTaskList
} from '../EventWithExistingQuote/EventWithExistingQuoteTabPanels/TasksTab/helper';
import { TaskComponentWrapper } from '../style';
import { completeTasks, EVENT_TASK_INITIAL_VALUES, tasksSchema } from './helper';

interface EventTaskProps {
  eventData: any;
}

const EventTask: React.FC<EventTaskProps> = ({}) => {
  const [eventTasks, setEventTasks] =
    useState<{ tasks: EventTaskType[] }>(EVENT_TASK_INITIAL_VALUES);
  const { eventId } = useParams();
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!eventId) return;
      setPageLoading(true);
      const result = await getEventTaskList(eventId);
      setPageLoading(false);
      if (!result.error) {
        setEventTasks({ tasks: result?.data || [] });
      }
    })();
  }, []);

  const onSubmitHandler = async (values: any) => {};

  const formik = useFormik({
    initialValues: eventTasks,
    validationSchema: tasksSchema,
    enableReinitialize: true,
    onSubmit: onSubmitHandler
  });

  const { values, setFieldValue, errors, touched, setFieldTouched, setFieldError, setErrors } =
    formik;

  const completeTaskMethod = async (values: EventTaskType) => {
    const res = await completeTasks(values);
    if (res?.success) {
      showToast({
        message: 'Success!',
        description: `Event task updated.`
      });
    } else {
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
    }
  };

  const onChangeHandler = (index: number, data: any) => {
    !!errors &&
      Object.keys(errors)?.length === 0 &&
      completeTaskMethod({ ...values.tasks[index], ...data });
  };

  if (pageLoading) return <CenterSpiner />;

  return (
    <Flex direction="column" style={{ width: '100%' }}>
      <PageTitle className="margin-top-60" bordered={true} text="Tasks" />
      <Flex direction="column" style={{ width: '100%' }}>
        {!!eventTasks?.tasks?.length ? (
          eventTasks?.tasks?.map((task, index) => (
            <Row style={{ marginBottom: '16px' }} gutter={[16, 16]} key={`${index}-${task?.id}`}>
              <Col lg={18} md={16} xs={24}>
                <TaskComponentWrapper>
                  <TaskComponent
                    name={task?.task}
                    id={`tasks.${index}.is_completed`}
                    value={values?.tasks?.[index]?.is_completed ? 1 : 0}
                    checked={values?.tasks?.[index]?.is_completed}
                    onChange={(e: any) => {
                      setFieldValue(`tasks.${index}.is_completed`, e?.target?.checked).then(() => {
                        if (!values?.tasks?.[index]?.completed_at) {
                          setFieldValue(`tasks.${index}.is_completed`, false, true); // set to false if completed_at is not set
                          setFieldTouched(`tasks.${index}.completed_at`, true, true);
                          return;
                        }
                        onChangeHandler(index, { is_completed: e?.target?.checked });
                      });
                    }}
                  />
                </TaskComponentWrapper>
              </Col>
              <Col lg={6} md={8} xs={24}>
                <DatePickerComponent
                  allowClear={false}
                  id={`tasks.${index}.completed_at`}
                  name={`tasks.${index}.completed_at`}
                  value={values?.tasks?.[index]?.completed_at}
                  disabled={!!values?.tasks?.[index]?.is_completed}
                  onChange={(e) => {
                    setFieldValue(
                      `tasks.${index}.completed_at`,
                      moment(e).format('YYYY-MM-DD HH:mm:ss')
                    ).then(() => {
                      onChangeHandler(index, {
                        completed_at: moment(e).format('YYYY-MM-DD HH:mm:ss')
                      });
                    });
                  }}
                  error={
                    getIn(touched, `tasks.${index}.completed_at`) &&
                    getIn(errors, `tasks.${index}.completed_at`)
                  }
                />
              </Col>
            </Row>
          ))
        ) : (
          <p>No Tasks Found</p>
        )}
      </Flex>
    </Flex>
  );
};

export default EventTask;
