import * as yup from 'yup';

import { getProducts, getProductTypes } from '@pages/MenuManagementPage/helper';
import services from '@services/index';

export const newProduct = {
  product_cost: undefined,
  product_id: undefined,
  product_type: 4,
  product_subcategory_id: undefined,
  quantity_per_product: undefined,
  total_cost: undefined,
  tempId: undefined
};

export const addEditBundleValidations = yup.object().shape({
  bundle_name: yup.string().required('Bundle name is required.'),
  id: yup.mixed().nullable(),
  products: yup.array().of(
    yup.object().shape({
      product_cost: yup.mixed().required('Rate is required.'),
      product_id: yup.mixed().required('Product is required.'),
      product_subcategory_id: yup.mixed().required('Product is required.'),
      quantity_per_product: yup
        .number()
        .min(1, 'Quantity cannot be 0.')
        .required('Quantity is required.'),
      product_type: yup.mixed().required('Product type is required.'),
      total_cost: yup.number()
    })
  )
});

export const getInitialBundleData = (data: any) => {
  return {
    bundle_name: data?.bundle_name || undefined,
    id: data?.id || undefined,
    products: data?.bundle_products?.map((i: any) => ({
      ...i,
      product_type: i?.product?.product_type
    })) || [{ ...newProduct, tempId: new Date().getTime() }]
  };
};

export const getAddBundleDropdownData = async () => {
  return Promise.all([getProductTypes(), getProducts()]).then((values: any) => values);
};

export const getMappedBundleData = (data: any) => {
  return {
    bundle_name: data?.bundle_name,
    products: data?.products?.map((i: any) => ({
      product_id: i?.product_id,
      product_subcategory_id: i?.product_subcategory_id,
      quantity_per_product: i?.quantity_per_product,
      product_cost: i?.product_cost
    }))
  };
};

export const addEditBundle = async (data: any, id?: any) => {
  try {
    const res = (await services?.[id ? 'put' : 'post'](`/bundle/${id ? 'update' : 'store'}`, {
      ...getMappedBundleData(data),
      id: Number(id)
    })) as {
      data: any;
    };
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const deleteBundleProduct = async (id?: any) => {
  try {
    const res = (await services?.delete(`/bundle/product/${id}`)) as {
      data: any;
    };
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
