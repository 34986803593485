import { Flex } from '@atom/Flex';
import FormDataView, { Value } from '@atom/FormDataView';
import { H2Typography, H4Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';
import { getSubTotal } from '@pages/QuoteManagementPage/ViewQuoteDetails/helper';
import { SectionTitleDiv } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';
import { formatCurrency } from '@utils/format';
import { Col, Row } from 'antd';
import React from 'react';
import { getVendorColumns } from '../column';
import { DataTablepaddingWrapper } from './style';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';
import { useSelector } from 'react-redux';

interface VendorProps {
  data: any;
  isQuoteTab?: boolean;
}

const Vendor = (props: VendorProps) => {
  const { data, isQuoteTab } = props;
  const subtotal = Number(getSubTotal(data, 'vendors'));
  const device = useWindowSizeChangedforGrid();
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'event-section'}>VENDORS</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      {/* {!isQuoteTab && (
        <Row>
          <Col lg={8} xs={24}>
            <FormDataView
              heading="DATES"
              value={
                data?.event_detail?.event_date !== '0000-00-00'
                  ? data?.event_detail?.event_date
                  : undefined
              }
            />
          </Col>
          <Col lg={8} xs={24}>
            <FormDataView heading="TIMES" value={data?.event_detail?.start_time} />
          </Col>
        </Row>
      )} */}
      <Row>
        <Col lg={24} xs={24}>
          <DataTablepaddingWrapper>
            <DataTableWrapper>
              <DataTable
                className={`${device == 'mobile' ? 'fixTD' : ''}`}
                renderEmpty={() => {
                  return <>No Vendor Found</>;
                }}
                dataSource={data?.vendors?.map((i: any) => ({
                  ...i,
                  vendor_name: i?.vendor?.vendor_name,
                  formated_phone_number: i?.vendor?.formated_phone_number
                }))}
                columns={getVendorColumns()}
              />
            </DataTableWrapper>
          </DataTablepaddingWrapper>
        </Col>
        {data?.vendors?.length > 0 && isAdmin && (
          <>
            <Col lg={20} xs={20} style={{ textAlign: 'right' }}>
              <H4Typography>SUBTOTAL</H4Typography>
            </Col>
            <Col lg={4} xs={4} style={{ textAlign: 'right' }}>
              <H4Typography>{formatCurrency(subtotal)}</H4Typography>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default Vendor;
