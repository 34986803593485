import { IInitialFiltersType, IVenueDataTableProps, IVenueListFilterType } from '../types';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { getColumns } from './helper';
import { getTableStatus, getVenueList, initialFilters, mapTableData } from '../helper';

import DataTable from '@organism/DataTable/DataTable';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';
import { ListWrapper } from './styles';
import { TableWrapper } from '@pages/MenuManagementPage/style';

const VenueDataTable: React.FC<IVenueDataTableProps> = ({ activePanel, currentPanel }) => {
  const navigate = useNavigate();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [filterData, setFilterData] = useState<IVenueListFilterType>(initialFilters);
  const isCurrentPanelActive = currentPanel === activePanel

  useEffect(() => {
    if (isCurrentPanelActive) {
      setFilterData(initialFilters);
      setVenueTableData({ ...initialFilters, type: activePanel });
    }
  }, [activePanel]);

  const setVenueTableData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setIsTableLoading(true);
      // const newFilters = { ...filters, type: activePanel, status: getTableStatus(activePanel) };
      const res = await getVenueList(newFilters.page, getTableStatus(activePanel), filters);
      setIsTableLoading(false);
      if (!res.error) {
        setTableData(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
    }, 300),
    []
  );

  const onFilterChangeHandler = (e: ChangeEvent) => {
    const { id, value } = e.target as HTMLInputElement;
    const updatedFilters = { ...filterData, [id]: value };
    setFilterData({ ...updatedFilters, page: 1 });
    setVenueTableData(updatedFilters);
  };

  const cols = isCurrentPanelActive && getColumns(filterData, onFilterChangeHandler);

  return (
    <ListWrapper>
      <TableWrapper>
        <DataTable
          className="fixTD"
          loading={isTableLoading}
          columns={cols}
          showLoadMore={!!nextPageUrl}
          nextPageURL={nextPageUrl}
          dataSource={tableData}
          pagination={{
            pageSize: pageNo,
            total: totalCount,
            current: filterData?.page,
            onChange: (page: any) => {
              const newFilters = { ...filterData, page: page };
              setFilterData(newFilters);
              setVenueTableData(newFilters);
            }
          }}
          onFetch={() => {}}
          renderEmpty={() => <div>No Venue Found.</div>}
          onRowClick={(e: any) => {
            navigate(`/venues/${e?.record?.id}/view`, { state: activePanel });
          }}
        />
      </TableWrapper>
    </ListWrapper>
  );
};

export default VenueDataTable;
