export const getSpecialIngredientCols = () => {
  const columns: any[] = [
    {
      title: 'Ingredient Name',
      width: '30%',
      dataIndex: 'product_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'Amount',
      width: '15%',
      dataIndex: 'amount',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'Measurement',
      width: '15%',
      dataIndex: 'mesurement',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'Prep List',
      width: '20%',
      dataIndex: 'prep_list',
      render: (data: any) => {
        return data ? data : '-';
      }
    }
  ];
  return columns;
};
