export const venuSubSectionList = [
  {
    id: 'Venue',
    name: 'Venue'
  },
  {
    id: 'Private',
    name: 'Private'
  }
];

export const kitchenAvailabilityList = [
  {
    id: 1,
    name: 'Full Catering Kitchen'
  },
  {
    id: 2,
    name: 'Partial In House'
  },
  {
    id: 3,
    name: 'Residential'
  },
  {
    id: 4,
    name: 'Satellite'
  }
];
