import services from '@services/index';
import moment from 'moment';
import * as Yup from 'yup';

export enum EMPLOYEE_STATUS {
  AVAILABLE = 2,
  UNAVAILABLE = 3
}

export const validationSchema = Yup.object({
  account_manager_id: Yup.mixed().required('Select account manager').nullable(),
  event_id: Yup.mixed().nullable(),
  quote_id: Yup.mixed().nullable(),
  event_date: Yup.mixed().nullable(),
  staffing: Yup.array().of(
    Yup.object({
      start_time: Yup.string().nullable(),
      end_time: Yup.string().nullable(),
      event_id: Yup.mixed().nullable(),
      quotation_labour_id: Yup.mixed().nullable(),
      quantity: Yup.number()
        .when('position_id', {
          is: (value: string) => value && value,
          then: Yup.number()
            .required('The quantity field is required.')
            .typeError('Qty must be entered in numbers only')
            .max(255, 'Qty must be less than or equal to 255')
            .min(1, 'Qty must be greater than 0'),
          otherwise: Yup.number().typeError('Qty must be entered in numbers only')
        })
        .nullable(),
      // .nullable()
      // .typeError('Qty must be a number')
      // .max(255, 'Qty must be less than or equal to 255'),
      position_id: Yup.string().nullable(),
      employee_ids: Yup.array().of(Yup.number().nullable()).nullable(),
      is_additional: Yup.mixed().nullable(),
      sort_order: Yup.number().nullable(),
      notes: Yup.string().nullable()
    }).nullable()
  ),
  additional_staffing: Yup.array().of(
    Yup.object({
      start_time: Yup.string().nullable(),
      end_time: Yup.string().nullable(),
      event_id: Yup.mixed().nullable(),
      quotation_labour_id: Yup.mixed().nullable(),
      quantity: Yup.number()
        .when('position_id', {
          is: (value: string) => value && value,
          then: Yup.number()
            .required('The quantity field is required.')
            .typeError('Qty must be entered in numbers only')
            .max(255, 'Qty must be less than or equal to 255')
            .min(1, 'Qty must be greater than 0'),
          otherwise: Yup.number().typeError('Qty must be entered in numbers only')
        })
        .nullable(),
      // .nullable()
      // .typeError('Qty must be a number')
      // .max(255, 'Qty must be less than or equal to 255'),
      position_id: Yup.string().nullable(),
      employee_ids: Yup.array().of(Yup.number().nullable()).nullable(),
      is_additional: Yup.mixed().nullable(),
      sort_order: Yup.number().nullable(),
      notes: Yup.string().nullable()
    }).nullable()
  )
});

export const getStaffingEmptyObject = (is_additional: any, sort_order: number) => {
  return {
    start_time: '',
    end_time: '',
    event_id: undefined,
    quotation_labour_id: undefined,
    quantity: '',
    position_id: '',
    employee_ids: [],
    is_additional: is_additional,
    sort_order: sort_order || 1,
    notes: ''
  };
};
export const getStaffingFormInitialValues = (
  eventBasicDetails: any,
  eventId: any,
  quoteId: any,
  data?: any
) => {
  const isEventNotGenerated = quoteId && !eventId;
  const staffingData = isEventNotGenerated ? data?.quotation_labours : data?.staffing;
  const staffing =
    staffingData?.map((i: any) => ({
      id:
        !i?.quotation_labour_id && !isEventNotGenerated
          ? i?.id
          : i?.id && !isEventNotGenerated
          ? i?.id
          : undefined,
      start_time: i?.start_time || undefined,
      quotation_labour_id: isEventNotGenerated ? i?.id : i?.quotation_labour_id,
      end_time: i?.end_time || undefined,
      event_id: i?.event_id || undefined,
      quantity: i?.quantity || undefined,
      position_id: i?.position_id || undefined,
      employee_ids: i?.employees?.map((i: any) => i?.employee?.id) || undefined,
      is_additional: i?.is_additional ? 1 : 0,
      sort_order: i?.sort_order || 1,
      notes: i?.notes || undefined,
      toggle_notes: i?.toggle_notes
    })) || [];
  const additional_staffing =
    data?.additional_staffing?.map((i: any) => ({
      id: i?.id,
      start_time: i?.start_time || undefined,
      end_time: i?.end_time || undefined,
      event_id: i?.event_id || undefined,
      quantity: i?.quantity || undefined,
      position_id: i?.position_id || undefined,
      employee_ids: i?.employees?.map((i: any) => i?.employee?.id) || undefined,
      is_additional: 1,
      sort_order: i?.sort_order || 1,
      notes: i?.notes || undefined,
      toggle_notes: i?.toggle_notes
    })) || [];
  return {
    account_manager_id: eventBasicDetails?.account_manager_id || undefined,
    event_id: eventId || undefined,
    quote_id: quoteId || eventBasicDetails?.quote_id || undefined,
    staffing,
    additional_staffing,
    event_date: eventBasicDetails?.event_date
  };
};

export const addEditStaffing = async (data: any, id?: any) => {
  try {
    const res = (await services.post(`/events/staffing`, {
      ...data,
      event_id: data?.event_id || null,
      id
    })) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.message : 'Something went wrong!' };
  }
};

export const getAccountManagers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get(`/employees/get-account-manager`)) as { data: any };
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

export const getPositions = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/staffs/get-positions')) as { data: any };
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

export const getAvailableEmployees = async (values: any) => {
  try {
    const res = (await services.post(`/events/available-employee`, {
      event_id: values?.event_id,
      position_id: values?.position_id,
      event_date: values?.event_date || '0000-00-00',
      start_time: values?.start_time
        ? values?.start_time?.includes('AM') || values?.start_time?.includes('PM')
          ? moment(values?.start_time, 'hh:mm A').format('HH:mm:ss')
          : values?.start_time
        : '',
      end_time: values?.end_time
        ? values?.end_time?.includes('AM') || values?.end_time?.includes('PM')
          ? moment(values?.end_time, 'hh:mm A').format('HH:mm:ss')
          : values?.end_time
        : ''
    })) as { data: any };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getStaffingDropdownData = () => {
  return Promise.all([getAccountManagers(), getPositions()]).then((values: any) => values);
};

export const getStaffingInfo = async (event_id: any, quote_id: any) => {
  try {
    const res = (await services.get(
      `/events/staffing?quote_id=${quote_id}${event_id ? `&event_id=${event_id}` : ''}`
    )) as { data: any };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const addStaffToPosition = async (eventStaffingId: number, employeeId: number) => {
  try {
    const res = await services.post('/staffs/add', {
      event_staffing_id: eventStaffingId,
      employee_id: employeeId
    });
    return res;
  } catch (error) {}
};
