import services from '@services/index';
import { formatCurrency } from '@utils/format';
import { RightAlignData } from '../style';
import { Flex } from '@atom/Flex';
import StatusLabel from '@atom/StatusLabel';

export const getMappedData = (data: any) => {
  return data?.map((i: any) => ({
    status: i?.product?.status,
    products: i?.product?.product_name || '-',
    quantity: i?.quantity_per_product || '-',
    rate: i?.product_cost || '-',
    total: i?.total_cost || '-'
  }));
};

export const bundleDetailsColumns = [
  {
    title: 'PRODUCTS',
    dataIndex: 'products',
    width: '55%',
    render: (data: any, row: any) => {
      return data ? (
        <Flex className="Td-fixing" style={{ width: '100%', gap: '20px' }} alignItems="center">
          <span>{data}</span>
          {!row?.status && (
            <StatusLabel status={row?.status} isUppercase={true} isProductBundle={true} />
          )}
        </Flex>
      ) : (
        '-'
      );
    }
  },
  {
    title: <RightAlignData>QTY</RightAlignData>,
    dataIndex: 'quantity',
    width: '15%',
    render: (data: any) => {
      return data ? <RightAlignData>{data}</RightAlignData> : '-';
    }
  },
  {
    title: <RightAlignData>RATE</RightAlignData>,
    dataIndex: 'rate',
    width: '15%',
    render: (data: any) => {
      return data ? <RightAlignData>{`${formatCurrency(data)}`}</RightAlignData> : '-';
    }
  },
  {
    title: <RightAlignData>TOTAL</RightAlignData>,
    dataIndex: 'total',
    width: '15%',
    render: (data: any) => {
      return data ? <RightAlignData>{`${formatCurrency(data)}`}</RightAlignData> : '-';
    }
  }
];

export const getProduct = async (id: any) => {
  try {
    const res = (await services.get(`/product/detail/${id}`)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const updateProductStatus = async (data: any) => {
  try {
    const res = (await services.post(`/product/status`, data)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const updateBundleStatus = async (data: any) => {
  try {
    const res = (await services.put(`/bundle/update-status`, data)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
