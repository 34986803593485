import FormDataView, { Value } from '@atom/FormDataView';
import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { Select } from 'antd';
import { H2Typography } from '@constant/typography/Typography';
import { Col, Row } from 'antd';
import { EventContentWrapper, SectionTitleDiv } from './style';
import { useSelector } from 'react-redux';
import { Flex } from '@atom/Flex';
import EventActionButtons from '@pages/EventManagementPage/EventDetails/EventInfo/EventActionButtons';
import { useState } from 'react';
import moment from 'moment';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';

interface EventDetailsProps {
  data: any;
  isEvent?: boolean;
  isQuoteTab?: boolean;
  getEventDetailMethod?: (eventId: string) => void;
}

const EventDetails = (props: EventDetailsProps) => {
  // const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  // const [isDowloadPdfModalVisible, setIsDowloadPdfModalVisible] = useState<boolean>(false);
  const { data, isEvent, getEventDetailMethod, isQuoteTab } = props;
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;
  const eventDate =
    data?.event_detail?.event_date ||
    data?.event_detail?.event_dates?.event_date ||
    data?.event_dates?.event_date;

  const numberOfGuest = `Adults: ${
    data?.event_detail?.adult_count || data?.adult_count || '-'
  }, Kids: ${data?.event_detail?.kids_count || data?.kids_count || '-'}, Staff: ${
    data?.event_detail?.vendor_staff_count || data?.vendor_staff_count || '-'
  }`;

  const device = useWindowSizeChangedforGrid();

  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={isEvent ? 'event-section' : 'quote-section'}>
              Event Details
            </H2Typography>
          </SectionTitleDiv>
        </Col>
        {/* {isEvent && (
          <Col span={24}>
            <Flex
              className="fix-mobile"
              justifyContent="flex-end"
              gap={10}
              bottom={24}
              style={{ paddingBottom: 24 }}>
              <EventActionButtons
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                isDowloadPdfModalVisible={isDowloadPdfModalVisible}
                setIsDowloadPdfModalVisible={setIsDowloadPdfModalVisible}
                getEventDetailMethod={getEventDetailMethod}
                data={data?.event_detail}
              />
            </Flex>
          </Col>
        )} */}
      </Row>
      <EventContentWrapper style={isEvent ? { paddingLeft: '15px', paddingRight: '15px' } : {}}>
        <SectionTitleDiv>
          <Row
            gutter={[16, 16]}
            className={'quote-section'}
            style={{ paddingTop: `${device == 'mobile' ? '0px' : '10px'}` }}>
            {isEvent && (
              <Col md={8} xs={12}>
                <FormDataView heading="EVENT CODE" value={data?.event_detail?.event_code} />
              </Col>
            )}
            <Col md={8} xs={isEvent || isQuoteTab ? 12 : 24} className="hide-mobile-device">
              <FormDataView
                heading="EVENT NAME"
                value={data?.event_detail?.event_name || data?.event_name}
              />
            </Col>
            {isEvent && (
              <Col md={8} xs={12}>
                <FormDataView
                  heading="ACCOUNT MANAGER"
                  value={data?.event_detail?.account_manager_name}
                />
              </Col>
            )}
            <Col md={8} xs={isEvent || isQuoteTab ? 12 : 12} className="hide-mobile-device">
              <FormDataView
                heading="EVENT DATE"
                value={eventDate !== '0000-00-00' ? moment(eventDate).format('ddd, MMMM D') : '--'}
              />
            </Col>
            {isEvent && (
              <Col md={8} xs={isQuoteTab ? 12 : 12} className="hide-mobile-device">
                <FormDataView heading="NUMBER OF GUESTS" value={numberOfGuest} />
              </Col>
            )}
            {isEvent && (
              <Col md={8} xs={isEvent || isQuoteTab ? 12 : 12} className="hide-full-device">
                <FormDataView
                  heading="EVENT DATE"
                  value={
                    eventDate !== '0000-00-00' ? moment(eventDate).format('ddd, MMMM D') : '--'
                  }
                />
              </Col>
            )}

            <Col md={8} xs={isQuoteTab ? 12 : 12} className="hide-full-device">
              <FormDataView heading="NUMBER OF GUESTS" value={numberOfGuest} />
            </Col>

            {isEvent && (
              <>
                <Col md={4} xs={isQuoteTab ? 12 : 12}>
                  <FormDataView
                    heading="START TIME"
                    value={
                      data?.event_detail?.start_time &&
                      moment(data?.event_detail?.start_time, 'HH:mm:ss').format('hh:mm A')
                    }
                  />
                </Col>
                <Col md={4} xs={isQuoteTab ? 12 : 12}>
                  <FormDataView
                    heading="END TIME"
                    value={
                      data?.event_detail?.end_time &&
                      moment(data?.event_detail?.end_time, 'HH:mm:ss').format('hh:mm A')
                    }
                  />
                </Col>
              </>
            )}
            {!isEvent && (
              <>
                <Col md={8} xs={isQuoteTab ? 12 : 24}>
                  <FormDataView heading="NUMBER OF GUESTS" value={numberOfGuest} />
                </Col>
                <Col md={8} xs={isQuoteTab ? 12 : 24}>
                  <FormDataView
                    heading="ACCOUNT MANAGER"
                    value={data?.event_detail?.account_manager?.name || data?.account_manager?.name}
                  />
                </Col>

                <Col md={8} xs={isQuoteTab ? 12 : 24}>
                  <FormDataView
                    heading="START TIME"
                    value={
                      data?.event_dates?.start_string &&
                      (data?.event_dates?.start_string).toUpperCase()
                    }
                  />
                </Col>
                <Col md={8} xs={isQuoteTab ? 12 : 24}>
                  <FormDataView
                    heading="END TIME"
                    value={
                      data?.event_dates?.end_string && (data?.event_dates?.end_string).toUpperCase()
                    }
                  />
                </Col>
              </>
            )}
            <Col md={8} xs={isEvent || isQuoteTab ? 24 : 24} className="hide-full-device">
              <FormDataView
                heading="EVENT NAME"
                value={data?.event_detail?.event_name || data?.event_name}
              />
            </Col>
          </Row>
        </SectionTitleDiv>

        {data?.is_event_manager_staffs_exists && data?.event_manager_staffs?.length > 0 ? (
          data?.event_manager_staffs?.map((employee: any, index: number) => (
            <Row gutter={[16, 16]} key={index} style={{ paddingTop: '10px' }}>
              <Col md={8} xs={12}>
                <FormDataView heading="EVENT MANAGER" value={employee?.employee?.name} />
              </Col>

              <Col md={8} xs={12}>
                <FormDataView
                  heading="PHONE NUMBER"
                  value={
                    <a
                      href={`tel:${employee?.employee?.country_code ?? ''}${
                        employee?.employee?.phone_number
                      }`}
                      style={{ color: 'black' }}>
                      {`${employee?.employee?.country_code ?? ''} ${
                        employee?.employee?.phone_number
                      } ${
                        employee?.employee?.extension ? 'x' + employee?.employee?.extension : ''
                      }`}
                    </a>
                  }
                />
              </Col>
              <Col md={8} xs={12}>
                <FormDataView
                  heading="CONTACT EMAIL"
                  value={
                    <a href={`mailto:${employee?.employee?.email}`} style={{ color: 'black' }}>
                      {employee?.employee?.email}
                    </a>
                  }
                />
              </Col>
            </Row>
          ))
        ) : !data?.is_event_manager_staffs_exists ? (
          ''
        ) : (
          <Row gutter={[16, 16]} style={{ paddingTop: '10px' }}>
            <Col md={8} xs={12}>
              <FormDataView heading="EVENT MANAGER" value={''} />
            </Col>
            <Col md={8} xs={12}>
              <FormDataView heading="PHONE NUMBER" value={''} />
            </Col>
            <Col md={8} xs={12}>
              <FormDataView heading="CONTACT EMAIL" value={''} />
            </Col>
          </Row>
        )}

        {data?.is_head_chef_staffs_exists && data?.head_chef_staffs?.length > 0 ? (
          data?.head_chef_staffs?.map((employee: any, index: number) => (
            <Row gutter={[16, 16]} key={index} style={{ paddingTop: '10px' }}>
              <Col md={8} xs={12}>
                <FormDataView heading="HEAD CHEF" value={employee?.employee?.name} />
              </Col>

              <Col md={8} xs={12}>
                <FormDataView
                  heading="PHONE NUMBER"
                  value={
                    <a
                      href={`tel:${employee?.employee?.country_code}${employee?.employee?.phone_number}`}
                      style={{ color: 'black' }}>
                      {`${employee?.employee?.country_code ?? ''} ${
                        employee?.employee?.phone_number
                      } ${
                        employee?.employee?.extension ? 'x' + employee?.employee?.extension : ''
                      }`}
                    </a>
                  }
                />
              </Col>
              <Col md={8} xs={12}>
                <FormDataView
                  heading="CONTACT EMAIL"
                  value={
                    <a href={`mailto:${employee?.employee?.email}`} style={{ color: 'black' }}>
                      {employee?.employee?.email}
                    </a>
                  }
                />
              </Col>
            </Row>
          ))
        ) : !data?.is_head_chef_staffs_exists ? (
          ''
        ) : (
          <Row gutter={[16, 16]} style={{ paddingTop: '10px' }}>
            <Col md={8} xs={12}>
              <FormDataView heading="HEAD CHEF" value={''} />
            </Col>
            <Col md={8} xs={12}>
              <FormDataView heading="PHONE NUMBER" value={''} />
            </Col>
            <Col md={8} xs={12}>
              <FormDataView heading="CONTACT EMAIL" value={''} />
            </Col>
          </Row>
        )}

        {data?.is_banquet_supervisor_staffs_exists &&
        data?.banquet_supervisor_staffs?.length > 0 ? (
          data?.banquet_supervisor_staffs?.map((supervisor: any, index: number) => (
            <Row gutter={[16, 16]} key={index} style={{ paddingTop: '10px' }}>
              <Col md={8} xs={12}>
                <FormDataView heading="Banquet Supervisor" value={supervisor?.employee?.name} />
              </Col>

              <Col md={8} xs={12}>
                <FormDataView
                  heading="PHONE NUMBER"
                  value={
                    <a
                      href={`tel:${supervisor?.employee?.country_code ?? ''}${
                        supervisor?.employee?.phone_number
                      }`}
                      style={{ color: 'black' }}>
                      {`${supervisor?.employee?.country_code ?? ''} ${
                        supervisor?.employee?.phone_number
                      } ${
                        supervisor?.employee?.extension ? 'x' + supervisor?.employee?.extension : ''
                      }`}
                    </a>
                  }
                />
              </Col>
              <Col md={8} xs={12}>
                <FormDataView
                  heading="CONTACT EMAIL"
                  value={
                    <a href={`mailto:${supervisor?.employee?.email}`} style={{ color: 'black' }}>
                      {supervisor?.employee?.email}
                    </a>
                  }
                />
              </Col>
            </Row>
          ))
        ) : !data?.is_banquet_supervisor_staffs_exists ? (
          ''
        ) : (
          <Row gutter={[16, 16]} style={{ paddingTop: '10px' }}>
            <Col md={8} xs={12}>
              <FormDataView heading="Banquet Supervisor" value={''} />
            </Col>
            <Col md={8} xs={12}>
              <FormDataView heading="PHONE NUMBER" value={''} />
            </Col>
            <Col md={8} xs={12}>
              <FormDataView heading="CONTACT EMAIL" value={''} />
            </Col>
          </Row>
        )}
      </EventContentWrapper>
      {!isEvent && (
        <Row gutter={[16, 16]} style={{ marginTop: '32px' }}>
          <Col xs={24} lg={12}>
            <SelectInput
              id="date"
              name="date"
              placeholdertitle="Select from the list"
              value={data?.event_detail?.event_dates?.id}
              onChange={(value: any) => {}}>
              <Select.Option
                key={data?.event_detail?.event_dates?.id}
                value={data?.event_detail?.event_dates?.id}>
                {data?.event_detail?.event_dates?.date_string || '-'}
              </Select.Option>
            </SelectInput>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EventDetails;
