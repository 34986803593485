import { H2Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';
import { SectionTitleDiv } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';
import { Col, Row } from 'antd';
import React from 'react';
import { getStaffColumns } from '../column';
import { DataTablepaddingWrapper } from './style';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';

interface AdditionalStaffProps {
  data: any;
}

const AdditionalStaff = (props: AdditionalStaffProps) => {
  const { data } = props;
  const device = useWindowSizeChangedforGrid();

  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'event-section'}>Additional Staff</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <DataTablepaddingWrapper>
            <DataTableWrapper>
              <DataTable
                className={`${device == 'mobile' ? 'fixTD' : ''}`}
                renderEmpty={() => {
                  return <>No Additional Staff Assigned</>;
                }}
                dataSource={data}
                columns={getStaffColumns()}
              />
            </DataTableWrapper>
          </DataTablepaddingWrapper>
        </Col>
      </Row>
    </>
  );
};

export default AdditionalStaff;
