import { useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import ModalComponent from '@organism/Modal/Modal';
import { AddNewVenueModalProps } from '@pages/QuoteManagementPage/types';
import AddEditInfo from '@pages/VenueManagementPage/AddEditPages/AddEditInfo';
import { addEditValidations, getInitValues } from '@pages/VenueManagementPage/AddEditPages/helper';
import { IVenueDataType, IVenueDetail } from '@pages/VenueManagementPage/types';
import { useState } from 'react';
import { addVenue } from './helper';
import { showErrorToast, showToast } from '@utils/index';

function AddNewVenueModal(props: AddNewVenueModalProps) {
  const { isModalVisible, onCancel, updateVenueList } = props;
  const [venueData, setVenueData] = useState<IVenueDataType>({});
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setIsCreateLoading(true);
    try {
      delete formik.values?.status;
      const status = formik.values?.status;
      const additionalContacts = formik.values?.additional_contacts?.map((contact) => {
        delete contact?.localId;
        return {
          ...contact,
          venue_id: '',
          country_code:
            !!contact?.country_code && !contact?.country_code?.includes('+')
              ? `+${contact?.country_code}`
              : contact?.country_code
        };
      });
      const venueDetails: IVenueDetail = {
        ...formik.values?.venue_detail,
        food_fees:
          formik.values?.venue_detail?.food_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.food_fees,
        labour_fees:
          formik.values?.venue_detail?.labour_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.labour_fees,
        alcohol_soft_bar_fees:
          formik.values?.venue_detail?.alcohol_soft_bar_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.alcohol_soft_bar_fees
      };
      const res: any = await addVenue({
        ...formik.values,
        additional_contacts: additionalContacts,
        status: status,
        venue_detail: venueDetails
      });
      if (res?.success) {
        setVenueData(res?.data ?? {});
        updateVenueList(res?.data?.id, res?.data?.venue_name);
        showToast({
          message: 'Success!',
          description: 'Venue Saved Successfully!.'
        });
        handleCancel();
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    setIsCreateLoading(false);
  };

  const initVal: IVenueDataType = getInitValues(venueData);

  const handleCancel = () => {
    resetForm();
    setValues({ ...initVal });
    onCancel();
  };

  const formik = useFormik({
    initialValues: initVal,
    enableReinitialize: true,
    validate: (value) => {
      try {
        validateYupSchema(value, addEditValidations, true, value);
      } catch (error) {
        return yupToFormErrors(error);
      }
      return {};
    },
    validationSchema: addEditValidations,
    validateOnMount: true,
    onSubmit: onSubmit
  });

  const { resetForm, setValues } = formik;

  return (
    <>
      <ModalComponent
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        modalWidth="798px !important"
        align="left !important"
      >
        <AddEditInfo
          formik={formik}
          isCreateLoading={isCreateLoading}
          newVenueInQuoteOrEvent
          onCancelInQuoteOrEvent={() => handleCancel()}
        />
      </ModalComponent>
    </>
  );
}

export default AddNewVenueModal;
