import { QuoteBasicDetailsObject } from '@pages/QuoteManagementPage/AddEditQuote/helper';
import MealOrderForm from '@pages/QuoteManagementPage/AddEditQuote/MealOrder/MealOrderForm';
import { useState } from 'react';
import { useParams } from 'react-router';
import { CREATE_EVENT_TAB_KEYS } from '../../helper';

const MealOrder = ({
  submitForm,
  isEventsTab,
  tabKey
}: {
  submitForm: (tabKey: string, values: any, actions: any, method?: string) => void;
  isEventsTab?: boolean;
  tabKey: string;
}) => {
  const [quoteBasicDetails, setQuoteBasicDetails] = useState<QuoteBasicDetailsObject>({});
  return (
    <MealOrderForm
      quoteBasicDetails={quoteBasicDetails}
      setQuoteBasicDetails={setQuoteBasicDetails}
      tabKey={tabKey}
      isEventsTab={isEventsTab}
      submitForm={submitForm}
    />
  );
};

export default MealOrder;
