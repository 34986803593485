import styled from 'styled-components';

export const VersionHistoryWrapper = styled.div`
  .ant-select-selector {
    :before {
      content: 'TYPE: ';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      text-transform: uppercase;
      padding-right: 10px;
      color: #838383;
    }
  }
  .ant-select-selection-item {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const PageLayoutWrapper = styled.div`
  .pageouter-fixing {
    padding: 48px !important;
    @media (max-width: 768px) {
      padding: 0px !important;
    }
  }
  .btn-bg {
    background-color: #000 !important;
  }
  .footerButton .ant-btn span {
    font-weight: 600 !important;
  }
`;
