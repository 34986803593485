import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import SearchableSelect from '@atom/SearchableSelect';
import TableHeader from '@atom/TableHeader';
import { Checkbox, Tooltip } from 'antd';
import { ChangeEvent } from 'react';
import AlertCircleIcon from '@assets/alert-circle.svg';

import {
  FoodPrepListFilterTypes,
  ManageProductModalFilterTypes,
  ManageProductModalTableDataTypes,
  MappedProductListDataTypes,
  ProductListDataTypes
} from '../types';

import { getSubCategories, getEventHeadChefsList } from '../../../../services/foodPrepServices';

export const initFilterState = {
  product_name: '',
  subcategory_id: '',
  event_id: '',
  head_chef_id: '',
  quantity_sort: '',
  packed_sort: '',
  start_date: '',
  end_date: ''
};

export const intialFiltersStateForModal = {
  ingredient_name: '',
  sort_by_amount: '',
  unit: '',
  assignee: ''
};

const orderBy = [
  { id: 1, title: 'Ascending' },
  { id: 2, title: 'Descending' }
];

const handleUpdateFilter = (
  filters: any,
  setFilters: (filters: any) => void,
  key: string,
  value: any,
  fetchData: (filters: any) => void,
  tableData: Array<MappedProductListDataTypes>,
  productList?: any,
  setProductList?: (e: any) => void,
  initialProductList?: any,
  setInitialProductList?: (e: any) => void
) => {
  setProductList &&
    setProductList(() => {
      if (value) {
        return initialProductList?.filter((item: any) =>
          item?.name?.toLowerCase()?.startsWith(value?.toLowerCase())
        );
      } else {
        return initialProductList;
      }
    });
  const newFilters = { ...filters, [`${key}`]: value };
  setFilters(newFilters);
  fetchData(newFilters);
};

export const getFoodColumns = (
  filters: FoodPrepListFilterTypes,
  setFilters: (filters: any) => void,
  dropdownData: any,
  handleManageProduct: (
    product_id: number | string,
    subcategory_id: number | string,
    event_id: number | string
  ) => void,
  searchFilter: (filters: any) => void,
  updateTableData: (filters: any) => void,
  tableData: Array<MappedProductListDataTypes>,
  onClear: (filters: any) => void,
  productList?: any,
  setProductList?: (e: any) => void,
  initialProductList?: any
) => {
  const columns = [
    {
      title: (
        <TableHeader
          columnTitle="Product Name"
          isSearchable
          id="product_name"
          name="product_name"
          keyValue={'name'}
          placeholder="Product Name"
          options={productList}
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filters },
              setFilters,
              'product_name',
              e?.name,
              updateTableData,
              tableData
            )
          }
          onSearch={(e: any) => {
            handleUpdateFilter(
              { ...filters },
              setFilters,
              'product_name',
              e,
              searchFilter,
              tableData,
              productList,
              setProductList,
              initialProductList
            );
          }}
          value={
            productList?.find((e: any) => e?.name === filters?.product_name) ||
            filters?.product_name
          }
          clearButton={true}
          onClear={onClear}
          listHeight={150}
        />
      ),
      dataIndex: 'product_name',
      render: (data: any, row: any) => {
        return (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() =>
              handleManageProduct(row?.product_id, row?.product_subcategory_id, row?.event_id)
            }
          >
            {data}
          </span>
        );
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Sub Categories"
          name="subcategory_id"
          value={filters?.subcategory_id || undefined}
          placeholder="Sub Category"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filters },
              setFilters,
              'subcategory_id',
              e,
              updateTableData,
              tableData
            )
          }
          isSelect
          options={dropdownData?.subCategoriesList?.map((subCategory: any) => {
            return { id: subCategory.id, title: subCategory.name };
          })}
          clearButton={true}
          onClear={onClear}
        />
      ),
      dataIndex: 'sub_category_name'
    },
    {
      title: (
        <TableHeader
          columnTitle="Event Code"
          name="event_id"
          value={filters?.event_id || undefined}
          placeholder="Event Code"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filters },
              setFilters,
              'event_id',
              e,
              updateTableData,
              tableData
            )
          }
          isSelect
          options={dropdownData?.eventCodesList?.map((eventCode: any) => {
            return { id: eventCode.id, title: eventCode.text };
          })}
          clearButton={true}
          onClear={onClear}
        />
      ),
      dataIndex: 'event_code'
    },
    {
      title: (
        <TableHeader
          columnTitle={
            <>
              {'Event Head Chef'}
              <Tooltip title="Head chef name">
                <img
                  src={AlertCircleIcon}
                  alt="alert"
                  width="20px"
                  style={{ verticalAlign: 'bottom', marginLeft: '5px' }}
                />
              </Tooltip>
            </>
          }
          name="head_chef_id"
          value={filters?.head_chef_id || undefined}
          placeholder="Event Head Chef"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filters },
              setFilters,
              'head_chef_id',
              e,
              updateTableData,
              tableData
            )
          }
          isSelect
          options={dropdownData?.headChefsList?.map((headChef: any) => {
            return { id: headChef.id, title: headChef.name };
          })}
          clearButton={true}
          onClear={onClear}
        />
      ),
      width: '16%',
      dataIndex: 'event_head_chef'
    },
    {
      title: (
        <TableHeader
          columnTitle="Qty"
          name="quantity_sort"
          value={filters?.quantity_sort || undefined}
          placeholder="Sort"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filters },
              setFilters,
              'quantity_sort',
              e,
              updateTableData,
              tableData
            )
          }
          isSelect
          options={orderBy}
          clearButton={true}
          onClear={onClear}
        />
      ),
      dataIndex: 'qty'
    },
    {
      title: (
        <TableHeader
          columnTitle="Packed Qty"
          name="packed_sort"
          value={filters?.packed_sort || undefined}
          placeholder="Sort"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filters },
              setFilters,
              'packed_sort',
              e,
              updateTableData,
              tableData
            )
          }
          isSelect
          options={orderBy}
          clearButton={true}
          onClear={onClear}
        />
      ),
      dataIndex: 'packed_qty'
    },
    {
      title: (
        <>
          <p>{'Action'}</p>
          <Button text={'Action'} isDisable />
        </>
      ),
      render: (data: any, row: any) => {
        return !row?.is_subtotal ? (
          <Button
            variant="secondary"
            text={'Manage'}
            onClick={() =>
              handleManageProduct(row?.product_id, row?.product_subcategory_id, row?.event_id)
            }
            className="cancel-btn"
          />
        ) : null;
      }
    }
  ];

  return columns;
};

export const getManageProductColumns = (
  filterValue: ManageProductModalFilterTypes,
  onChange: (a: ChangeEvent) => void,
  assigneOptions: any,
  filteredassignesOptions: any,
  filterAssigneeListHandler: (e: string) => void,
  setTableData: any,
  tableData: any,
  onClear: (filters: any) => void,
  handleViewPrepList: (data: any) => void,
  handleSpecialIngredient: (id: number) => void
) => {
  const columns = [
    {
      title: (
        <div>
          <p>{'Done'}</p>
          <Checkbox disabled={true} />
        </div>
      ),
      width: '1%',
      className: 'doneTd',
      dataIndex: 'done',
      render: (data: any, row: ManageProductModalTableDataTypes) => {
        return (
          <Checkbox
            checked={row?.is_completed ? true : false}
            onClick={(e: any) => {
              let data = { ...tableData };
              tableData?.product_sub_category_ingredients.forEach((val: any, index: any) => {
                if (val.id === row.id) {
                  data.product_sub_category_ingredients[index].is_completed = e.target.checked
                    ? 1
                    : 0;
                }
              });
              setTableData(data);
            }}
          />
        );
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Ingredient Name"
          name="ingredient_name"
          value={filterValue.ingredient_name}
          placeholder="Ingredient"
          onChange={onChange}
          clearButton={true}
          onClear={onClear}
        />
      ),
      width: '3%',
      dataIndex: 'ingredient_name',
      render: (data: any, row: ManageProductModalTableDataTypes) => {
        const isSpecialIngredient = row?.ingredient?.is_special_ingredient;
        return (
          <span
            onClick={() => isSpecialIngredient && handleSpecialIngredient(row?.ingredient?.id)}
            style={{
              textDecoration: isSpecialIngredient ? 'underline' : '',
              cursor: isSpecialIngredient ? 'pointer' : 'default'
            }}
          >
            {row?.ingredient?.name}
          </span>
        );
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Amount"
          name="amount"
          value={filterValue?.amount || undefined}
          placeholder="Sort"
          onChange={(e: any) => {
            //@ts-ignore
            onChange({ target: { id: 'amount', value: e } });
          }}
          isSelect
          clearButton={true}
          onClear={onClear}
          options={orderBy}
        />
      ),
      dataIndex: 'amount',
      width: '2%',
      render: (data: any, row: ManageProductModalTableDataTypes) => {
        return data;
      }
    },
    {
      title: (
        <TableHeader
          clearButton={true}
          columnTitle="Unit"
          name="unit"
          value={filterValue?.unit || undefined}
          placeholder="Unit"
          onChange={onChange}
          onClear={onClear}
          isDisabled={true}
          isSelect={true}
        />
      ),
      dataIndex: 'unit',
      width: '2%',
      render: (data: any, row: ManageProductModalTableDataTypes) => {
        return data ?? row?.measurement?.name ?? '--';
      }
    },

    {
      title: (
        <TableHeader
          columnTitle={
            <>
              {'Assignee'}
              <Tooltip title="Kitchen staff name">
                <img
                  src={AlertCircleIcon}
                  alt="alert"
                  width="20px"
                  style={{ verticalAlign: 'bottom', marginLeft: '10px' }}
                />
              </Tooltip>
            </>
          }
          clearButton={true}
          name="assignee"
          listHeight={130}
          value={filterValue?.assignee || undefined}
          placeholder="Assignee"
          onClear={onClear}
          onChange={(e: any) => {
            //@ts-ignore
            onChange({ target: { id: 'assignee', value: e } });
          }}
          isSelect
          options={assigneOptions}
        />
      ),
      dataIndex: 'assignee',
      width: '2%',
      render: (data: any, row: ManageProductModalTableDataTypes, index: number) => {
        return (
          <SearchableSelect
            style={{ paddingRight: '20px' }}
            id="assigneI_data"
            name="assigneI_data"
            keyValue={'id'}
            options={filteredassignesOptions}
            placeholdertitle={'Search User'}
            onSearch={(e: any) => {
              filterAssigneeListHandler(e);
            }}
            onChange={(e: any) => {
              let data = { ...tableData };
              tableData?.product_sub_category_ingredients.forEach((val: any, index: any) => {
                if (val.id === row.id) {
                  data.product_sub_category_ingredients[index].assignee_id = e?.id;
                }
              });
              setTableData(data);
            }}
            prefix={true}
            labelValue={'name'}
            value={assigneOptions?.find(
              (e: any) =>
                e?.id === tableData?.product_sub_category_ingredients?.[index]?.assignee_id
            )}
          />
        );
      }
    },
    {
      title: (
        <>
          <p>{'Action'}</p>
          <Button text={'Action'} isDisable />
        </>
      ),
      width: '5%',
      render: (data: any, row: ManageProductModalTableDataTypes) => {
        return (
          <Flex style={{ height: '50px' }}>
            <Button
              variant="secondary"
              text={'View in Prep List'}
              onClick={() => {
                handleViewPrepList(data);
              }}
              className="cancel-btn"
              style={{ height: '30px', minWidth: '160px' }}
            />
          </Flex>
        );
      }
    }
  ];

  return columns;
};

export const getMappedTableData = (data: Array<ProductListDataTypes>) => {
  let tableData: any = [];
  data?.forEach((i: any) => {
    tableData.push({
      product_id: i?.product_id,
      product_name: i?.product_name,
      subcat_id: i?.subcategory?.[0]?.subcategory_id,
      sub_category_name: i?.subcategory?.[0]?.subcategory_name,
      event_code: i?.subcategory?.[0]?.event_code,
      event_id: i?.subcategory?.[0]?.event_id,
      event_head_chef: !i?.subcategory?.[0]?.head_chef_name
        ? 'Unassigned'
        : i?.subcategory?.[0]?.head_chef_name,
      qty: i?.subcategory?.[0]?.total_quantity,
      product_subcategory_id: i?.subcategory?.[0]?.product_subcategory_id,
      packed_qty: !i?.subcategory?.[0]?.packed_qty ? 0 : i?.subcategory?.[0]?.packed_qty
    });

    for (let k = 1; k < i?.subcategory?.length; k++) {
      let j = i?.subcategory?.[k];
      tableData.push({
        event_id: j?.event_id,
        product_id: i?.product_id,
        subcat_id: j?.subcategory_id,
        product_subcategory_id: j?.product_subcategory_id,
        product_name: '',
        sub_category_name: j?.subcategory_name,
        event_code: j?.event_code,
        event_head_chef: !j?.head_chef_name ? 'Unassigned' : j?.head_chef_name,
        qty: j?.total_quantity,
        packed_qty: !j?.packed_qty ? 0 : j?.packed_qty
      });
    }
    tableData.push({
      product_name: '',
      sub_category_name: '',
      event_code: '',
      event_head_chef: (
        <div style={{ width: '100%', textAlign: 'right', padding: '20px', fontWeight: 'bold' }}>
          SUBTOTAL
        </div>
      ),
      qty: i?.sub_total,
      packed_qty: i?.subtotal_packed_qty,
      is_subtotal: true
    });
  });
  return tableData;
};

export enum MANAGE_PRODUCT_MODAL_TYPES {
  LEAVE_FORM = 'leave_form',
  ASSIGN_HEAD_SHEF = 'assign_head_shef'
}

export const manageProductModalContent: any = {
  [MANAGE_PRODUCT_MODAL_TYPES.LEAVE_FORM]: {
    heading: 'You are about to leave this form',
    message:
      'It looks like you have not saved your progress. Are you sure you want to leave this form without saving?',
    okText: 'Save',
    cancelText: 'Leave'
  },
  [MANAGE_PRODUCT_MODAL_TYPES.ASSIGN_HEAD_SHEF]: {
    heading: 'Assigning Event Head Chef',
    message: (
      <span>
        It seems you are assigning an <span style={{ color: '#FD7E14' }}>Event Head Chef</span> for
        this product? Do you want to assign this Head Chef for all products of the same event?
      </span>
    ),
    okText: 'Assign to All Products',
    cancelText: 'Assign to This Product'
  }
};

export const getProductListDropdownData = async (filter: FoodPrepListFilterTypes) => {
  return Promise.all([getSubCategories(), getEventHeadChefsList()]).then((values: any) => values);
};
