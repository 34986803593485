import VenueDataTable from '../VenueDataTable';

export enum VENUE_MANAGEMENT_TABS {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'in-active',
  DRAFTS = 'drafts'
}

export const getTabs = (activePanel: string) => {
  const tabValues = [
    {
      tab: 'ALL',
      content: (
        <VenueDataTable currentPanel={VENUE_MANAGEMENT_TABS.ALL} activePanel={activePanel} />
      ),
      key: VENUE_MANAGEMENT_TABS.ALL,
      getUrl: VENUE_MANAGEMENT_TABS.ALL
    },
    {
      tab: 'ACTIVE',
      content: (
        <VenueDataTable currentPanel={VENUE_MANAGEMENT_TABS.ACTIVE} activePanel={activePanel} />
      ),
      key: VENUE_MANAGEMENT_TABS.ACTIVE,
      getUrl: VENUE_MANAGEMENT_TABS.ACTIVE
    },
    {
      tab: 'INACTIVE',
      content: (
        <VenueDataTable currentPanel={VENUE_MANAGEMENT_TABS.INACTIVE} activePanel={activePanel} />
      ),
      key: VENUE_MANAGEMENT_TABS.INACTIVE,
      getUrl: VENUE_MANAGEMENT_TABS.INACTIVE
    },
    {
      tab: 'DRAFTS',
      content: (
        <VenueDataTable currentPanel={VENUE_MANAGEMENT_TABS.DRAFTS} activePanel={activePanel} />
      ),
      key: VENUE_MANAGEMENT_TABS.DRAFTS,
      getUrl: VENUE_MANAGEMENT_TABS.DRAFTS
    }
  ];
  return tabValues;
};
