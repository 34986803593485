import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Tooltip } from 'antd';
import { Button } from '@atom/Buttons';
import { EditPencilIcon } from '@assets/index';
import useSWR from 'swr';
import CenterSpiner from '@atom/CenterSpiner';
import NoImage from '@assets/noimage.png';
import { getProfileDetails, profileValidations, updateProfile, uploadFileHandler } from '../helper';
import {
  ContactDetailsType,
  ProfileType,
  DepartmentsType,
  DepartmentFormType,
  PositionsType,
  ReportsToType
} from '../types';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { Flex } from '@atom/Flex';
import { H2, TypoBodyBody1 } from '@constant/typography/Typography';
import ImagePreviewer from '@atom/ImagePreviewer';
import FileDraggerComponent from '@molecules/FileDraggerComponent';
import QuestionMark from '@assets/question-mark.svg';
import { FILE_TYPES } from '@constants/userConstants';
import { showErrorToast, showToast } from '@utils/index';
import InputPhone from '@atom/InputPhone';
import SearchableSelect from '@atom/SearchableSelect';
import { SelectInputWithCheckBox } from '@atom/Select';
import RemoveIcon from '@assets/removerow.png';
import PlusCircle from '@assets/plus-circle.svg';
import { additionalDepartment, profileInitialValue } from '../constants';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { GoogleAutoComplete } from '@atom/GoogleAutoComplete';
import { getAddressComps, getLocationByPlaceId } from '@utils/googlemaphealper';
import { userActions } from '@store/userProfileReducer';
import { useDispatch } from 'react-redux';

const EditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [departmentsList, setDepartmentsList] = useState<DepartmentsType[]>([]);
  const [reportsToList, setReportsToList] = useState<ReportsToType[]>([]);

  const user = useSelector((state: { user: { user_id: string; isAdmin: boolean } }) => state.user);
  const formik = useFormik({
    initialValues: profileInitialValue as ProfileType,
    validationSchema: profileValidations,
    onSubmit: onSubmitHandler
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues
  } = formik;

  const { data: profileDetails, isValidating } = useSWR([`/profile/view`], getProfileDetails, {
    revalidateOnFocus: false
  });

  useEffect(() => {
    if (user.user_id && profileDetails) {
      const {
        profile_pic,
        imageName,
        name,
        email,
        country_code,
        phone_number,
        address,
        extension,
        departments,
        longitude,
        reports_to
      } = profileDetails.profile;

      setValues({
        ...profileInitialValue,
        profile_pic,
        imageName,
        name,
        email,
        country_code,
        phone_number,
        address: address || '',
        extension,
        departments,
        longitude,
        reports_to
      });
      setDepartmentsList(profileDetails.departments || []);
      setReportsToList(profileDetails.reportings || []);
    } else {
      setValues(profileInitialValue);
    }
  }, [user.user_id, profileDetails]);

  const imageHelperTooltip = (
    <Flex direction="column" alignItems="end">
      <p>Recommended Profile Size</p>
      <p>360 x 455 px</p>
    </Flex>
  );

  const onImageRemove = () => {
    setFieldValue('profile_pic', null);
  };

  async function onSubmitHandler(values: ProfileType) {
    setLoading(true);
    const updatedValues = { ...values, country_code: `+${values.country_code}` };
    const result = await updateProfile(updatedValues);
    setLoading(false);
    if (result.data) {
      showToast({
        message: `Profile Updated Successfully!`,
        description: ''
      });
      dispatch(userActions.setUser({ ...user, profile_pic: values?.profile_pic }));
      navigate('/profile');
    } else {
      showErrorToast({
        message: `Unable to update profile`,
        description: result.error || 'Please try again'
      });
    }
  }

  const handleFileUpload = async (file: File) => {
    setImageLoading(true);
    const result = await uploadFileHandler(file, FILE_TYPES.PROFILE_PIC);
    setImageLoading(false);
    if (result.error) {
      return showErrorToast({
        message: `Unable to upload profile picture`,
        description: result.error || 'Please try again'
      });
    }
    setFieldValue('profile_pic', result.data.url);
    setValues({ ...values, profile_pic: result.data.url });
  };

  const phoneChangeHandler = (a: ContactDetailsType) => {
    setFieldValue('country_code', a.code ?? '');
    setFieldValue('extension', a.ext ?? '');
    setFieldValue('phone_number', a.phone ?? '');
  };

  const onPlaceSelect = async (placeId: string) => {
    if (placeId) {
      const place: any = await getLocationByPlaceId(placeId);
      if (place) {
        const locObj = getAddressComps(place);
        if (locObj.formattedAddress.length > 0) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };
          setValues({
            ...values,
            address:
              `${locObj?.formattedAddress}${locObj?.neighbourhood && ', ' + locObj?.neighbourhood}${
                locObj?.city && ', ' + locObj?.city
              }${locObj?.state && ', ' + locObj?.state}${
                locObj?.country && ', ' + locObj?.country
              }` || '',
            latitude: coordinates?.lat || '',
            longitude: coordinates?.lng || ''
          });
        }
      }
    }
  };

  const deptChangeHandler = (index: number, value: DepartmentsType) => {
    setFieldValue(`departments[${index}].master_department_id`, value?.id ?? '');
    positionChangeHandler(index, undefined);
  };

  const positionChangeHandler = (index: number, value: any) => {
    setFieldValue(`departments[${index}].position_id`, value?.length ? value : '');
  };

  const onDepartmentRemove = async (index: number, arrayHelper: any) => {
    arrayHelper.remove(index);
  };

  const reportsToChangeHandler = (value: ReportsToType) => {
    setFieldValue('reports_to', value?.id);
  };

  return (
    <PageLayout>
      <PageTitle text="MY PROFILE" bordered isBack path="/profile">
        <Button
          variant="ghost"
          icon={<img src={EditPencilIcon} alt="edit" />}
          text="EDIT"
          onClick={() => {}}
        />
      </PageTitle>
      {isValidating ? (
        <CenterSpiner />
      ) : (
        <>
          <div id="map" />
          <Row>
            <Label text="Image" />
            <Row style={{ width: '100%' }}>
              <Flex
                style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <TypoBodyBody1>
                  Select a file from your computer, or drag and drop them here.
                </TypoBodyBody1>
                <Tooltip placement="bottomLeft" title={imageHelperTooltip}>
                  <img src={QuestionMark} alt="questionMark" />
                </Tooltip>
              </Flex>
              {values?.profile_pic ? (
                <ImagePreviewer
                  image={values?.profile_pic || profileDetails?.profile?.profile_pic}
                  name={'Profile Photo'}
                  closable
                  onClose={onImageRemove}
                />
              ) : (
                <FileDraggerComponent
                  loading={imageLoading}
                  onChangeHandle={handleFileUpload}
                  multiple={false}
                />
              )}
            </Row>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Label text={`Name`} isMandatory={true} />
              <Input
                id="name"
                name="name"
                value={values.name}
                placeholder={`Name`}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && errors.name}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Label text={`Email`} isMandatory={true} />
              <Input
                id="email"
                name="email"
                value={values.email}
                placeholder={`Email`}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
              />
            </Col>
            <Col md={12} xs={24}>
              <Label text={`Phone Number`} isMandatory={true} />
              <InputPhone
                id="phone_number"
                name="phone_number"
                value={{
                  code: values.country_code,
                  phone: values.phone_number,
                  ext: values.extension
                }}
                error={
                  (touched.phone_number && errors.phone_number) ||
                  (touched.extension && errors.extension) ||
                  (touched.country_code && errors.country_code) ||
                  ''
                }
                onBlur={handleBlur}
                onChange={phoneChangeHandler}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Label text={`Address`} isMandatory={true} />
              <GoogleAutoComplete
                apiKey={process.env.REACT_APP_GOOGLEAPI_KEY}
                id="address"
                name="address"
                value={values?.address}
                onBlur={handleBlur}
                placeholder="Type Address"
                onPlaceSelect={(place: any) => {
                  if (place) onPlaceSelect(place.place_id);
                }}
                error={
                  (touched?.address && errors?.address) || (touched?.address && errors?.longitude)
                }
              />
            </Col>
          </Row>
          <Flex direction="column">
            <H2 text="Work Information" />
            <Divider />
          </Flex>
          <FormikProvider value={formik}>
            <FieldArray
              name="departments"
              render={(arrayHelper) => {
                if (!values.departments || !Array.isArray(values.departments)) return null;
                return (
                  <>
                    <Row className="hide-mobile-device">
                      <Col md={10} xs={24}>
                        <Label text={`Department(s)`} isMandatory={true} />
                      </Col>
                      <Col md={10} xs={20}>
                        <Label text={`Position(s)`} isMandatory={true} />
                      </Col>
                    </Row>
                    {values.departments.map((item, index) => {
                      return (
                        <Row gutter={[16, 16]}>
                          <Col md={10} xs={24}>
                            <Label
                              className="hide-full-device"
                              text={`Department(s)`}
                              isMandatory={true}
                            />
                            <SearchableSelect
                              disabled={!user?.isAdmin}
                              disablePrefix={!user?.isAdmin}
                              id={`departments[${index}].master_department_id`}
                              name={`departments[${index}].master_department_id`}
                              options={departmentsList}
                              error={
                                touched?.departments?.[index]?.master_department_id &&
                                (errors?.departments as DepartmentFormType[])?.[index]
                                  ?.master_department_id
                              }
                              keyValue={'id'}
                              labelValue={'name'}
                              placeholdertitle="Select One"
                              onChange={(e: DepartmentsType) => deptChangeHandler(index, e)}
                              onSearch={() => {}}
                              onBlur={handleBlur}
                              value={{
                                name:
                                  departmentsList.find(
                                    (dept) => dept.id === Number(item?.master_department_id)
                                  )?.name || ''
                              }}
                              allowClear={true}
                              prefix={true}
                            />
                          </Col>
                          <Col md={10} xs={20}>
                            <Label
                              className="hide-full-device"
                              text={`Position(s)`}
                              isMandatory={true}
                            />
                            <SelectInputWithCheckBox
                              disabled={!user?.isAdmin}
                              id={`departments[${index}].position_id`}
                              name={`departments[${index}].position_id`}
                              mode="multiple"
                              placeholdertitle="Select Multiple"
                              options={departmentsList
                                .find((dept) => dept.id === Number(item.master_department_id))
                                ?.positions.map((pos: PositionsType) => {
                                  return {
                                    title: pos.name,
                                    key: pos.id,
                                    value: pos.id
                                  };
                                })}
                              value={values?.departments?.[index]?.position_id || []}
                              onChange={(e: PositionsType) => positionChangeHandler(index, e)}
                              error={
                                touched?.departments?.[index]?.position_id &&
                                (errors?.departments as DepartmentFormType[])?.[index]?.position_id
                              }
                            />
                          </Col>
                          <Col md={4} xs={4} className="centerPosition">
                            {values.departments?.length > 1 && (
                              <img
                                src={RemoveIcon}
                                alt="remove"
                                style={{ cursor: 'pointer' }}
                                onClick={() => onDepartmentRemove(index, arrayHelper)}
                              />
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                );
              }}
            />
            {user?.isAdmin && (
              <Row>
                <Col md={4} xs={24}>
                  <Flex bottom={24}>
                    <Button
                      text="Add More"
                      variant="secondary"
                      icon={<img width={20} src={PlusCircle} alt="add" />}
                      onClick={() =>
                        setFieldValue('departments', [...values.departments, additionalDepartment])
                      }
                    />
                  </Flex>
                </Col>
              </Row>
            )}
          </FormikProvider>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Label text={`Reports to`} />
              <SearchableSelect
                disablePrefix={!user?.isAdmin}
                disabled={!user?.isAdmin}
                id="reports_to"
                name="reports_to"
                options={reportsToList}
                error={touched.reports_to && errors.reports_to}
                keyValue={'id'}
                labelValue={'text'}
                placeholdertitle="Type to search name"
                onChange={reportsToChangeHandler}
                onSearch={() => {}}
                onBlur={handleBlur}
                value={{
                  text: reportsToList.find((report) => report.id === Number(values.reports_to))
                    ?.text
                }}
                allowClear={true}
                prefix={true}
              />
            </Col>
          </Row>
          <Flex className="footerButton" justifyContent={'flex-end'}>
            <Flex gap={8} className="fix-mobile">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() => navigate('/profile')}
                isDisable={loading}
              />
              <Button text="Submit" onClick={handleSubmit} isLoading={loading} />
            </Flex>
          </Flex>
        </>
      )}
    </PageLayout>
  );
};

export default EditProfile;
