import styled from 'styled-components';
interface IngredientsListContainerStyleProps {
  dataLength?: number;
}
export const IngredientsListContainer = styled.div`
  border: solid 1px #cdcdcd;
  width: 100%;
  max-height: ${(props: IngredientsListContainerStyleProps) =>
    `${
      props?.dataLength && props?.dataLength >= 4
        ? '450px'
        : props?.dataLength && props?.dataLength <= 2
        ? `${props?.dataLength * 170 + 125}px`
        : props?.dataLength && props?.dataLength > 2
        ? `450px`
        : props?.dataLength && props?.dataLength > 0
        ? `${props?.dataLength * 85 + 125}px` // 175/85
        : '120px'
    }`};
  overflow-x: hidden !important;
  overflow-y: auto !important;

  .table-header-row {
    padding: 8px 0px 0px 12px;
    border-bottom: solid 1px #cdcdcd;
  }
  .table-body-row {
    padding: 8px 0px 8px 12px;
    border-bottom: solid 1px #cdcdcd;
  }
  .table-body-row:nth-child(odd) {
    background: #f8fafb;
  }
`;

export const H4TextWrapper = styled.h4`
  textAlign: 'left',
  fontSize:'14px',
  margin: 0,
  padding: '10px 0'
`;

export const IngredientListButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin: 8px;
  @media (max-width: 768px) {
    .ant-btn {
      padding: 8px 8px;
      font-size: 12px;
      img {
        width: 20px;
      }
    }
    margin-bottom: 20px;
  }
`;

export const SidebarWrapper = styled.div`
  Label {
    margin-bottom: 0px;
  }
  .SubCategory-btn .ant-btn-default {
    width: 100% !important;
  }
`;

export const IngredientsWrapper = styled.div`
  Label {
    margin-bottom: 0px;
  }
  .ingredient-btn > button {
    border-radius: 4px;
    padding: 4px 36px;
    height: 28px;
    background-color: #cdcdcd;
    border-color: #cdcdcd;
    background: linear-gradient(
      0deg,
      #bbbbbb -8.93%,
      rgba(221, 221, 221, 0.536458) 69.53%,
      rgba(231, 227, 227, 0) 126.79%
    );
  }
`;

export const SelectSubCategoryWrapper = styled.div<{ isPrimaryBtn?: boolean }>`
  width: 100%;
  margin-bottom: 0px !important;
  .select-category-cls {
    width: ${(props: any) => (props?.isPrimaryBtn ? '180px' : '')};
    margin-bottom: 0px !important;
  }
  .primary-btn-edit {
    background: #27a03b;
    border-radius: 4px;
    padding: 1px 8px;
    height: 24px;
    color: #fff;
    position: absolute;
    font-size: 14px;
    line-height: 22px;
    top: 50%;
    right: -20px;
    transform: translate(-50%, -50%);
  }
  .primary-btn-view {
    background: #27a03b;
    border-radius: 4px;
    padding: 1px 8px;
    height: 24px;
    color: #fff;
    position: absolute;
    font-size: 14px;
    line-height: 22px;
    right: 0;
  }
`;

export const IngredientButtonWrapper = styled.div`
  .ingredients-btn {
    padding: 4px 16px;
    background: linear-gradient(
      0deg,
      #bbbbbb -8.93%,
      rgba(221, 221, 221, 0.536458) 69.53%,
      rgba(231, 227, 227, 0) 126.79%
    );
    border: 1px solid #cdcdcd;
    border-radius: 4px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: orange !important;
  }
`;
