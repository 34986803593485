import { Button } from '@atom/Buttons';
import { Label } from '@atom/FormLable';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import PlusCircle from '@assets/plus-circle.svg';
import SubCategoryItem from './SubCategoryItem';
import { FieldArray, FormikProvider } from 'formik';
import { updateRawData } from '@utils/index';
import { useSelector } from 'react-redux';
import ConfirmModal from '@organism/ConfimationModal';
import { INGREDIENT_FORM_STATUS } from '@pages/MenuManagementPage/helper';
import { Row, Col } from 'antd';
import { SidebarWrapper } from './style';

interface SidebarProps {
  subCategoriesOption: any;
  isSubCategoryDisabled: boolean;
  formik: any;
  currentSubCategory: number | null;
  setCurrentSubCategory: Dispatch<SetStateAction<number | null>>;
  setSubCategories: (values: any[]) => void;
  submitIngredients?: () => void;
  forQuotePopUp?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  subCategoriesOption = [],
  isSubCategoryDisabled,
  formik,
  currentSubCategory,
  setCurrentSubCategory,
  setSubCategories,
  submitIngredients,
  forQuotePopUp
}) => {
  const { values, setFieldValue } = formik;
  const { ingredientsFormState } = useSelector((state: any) => state?.rawData);
  const [isAddWarningModalActive, setIsAddWarningModalActive] = useState(false);

  useEffect(() => {
    setSubCategories(values?.sub_category_array?.map((item: any) => item?.id));
  }, [values?.sub_category_array]);

  const onAddSubCategory = () => {
    const subcategoryArrayLengthBeforeUpdate = values?.sub_category_array?.length ?? 0;
    setFieldValue('sub_category_array', [
      ...(values?.sub_category_array || []),
      {
        id: null
      }
    ]);
    setCurrentSubCategory(
      Number(subcategoryArrayLengthBeforeUpdate >= 0 ? subcategoryArrayLengthBeforeUpdate : 0)
    );
  };

  const onWarningModalCancel = () => {
    onAddSubCategory();
    updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
    setIsAddWarningModalActive(false);
  };

  const savedProgress = () => {
    submitIngredients && submitIngredients();
    updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.SAVED });
    setIsAddWarningModalActive(false);
  };

  return (
    <>
      <SidebarWrapper>
        <Row style={{ width: '100%' }}>
          <Col md={24} xs={24}>
            <Row>
              <Col
                md={24}
                xs={24}
                style={{
                  backgroundColor: '#F4F4F4',
                  padding: '12px 12px 12px 12px',
                  borderRadius: '5px 0px 0px 0px'
                }}
              >
                <Row>
                  <Col lg={forQuotePopUp ? 17 : 12} md={24}>
                    <Label text="Sub Category" isMandatory />
                  </Col>
                  <Col lg={forQuotePopUp ? 7 : 12} md={24}>
                    <span
                      style={{
                        color: '#A19F9F',
                        padding: '4px 0px 0px 0px',
                        fontSize: '14px',
                        lineHeight: '18px'
                      }}
                    >
                      {forQuotePopUp ? '(Min 1)' : '(Add at least 1)'}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={24} xs={24} className="SubCategory-btn">
                {subCategoriesOption?.length - 1 >= (values?.sub_categories?.length ?? 0) ? (
                  <div style={{ padding: '12px', borderBottom: '1px solid #CDCDCD' }}>
                    <Button
                      text="Add Sub Category"
                      variant="secondary"
                      icon={<img width={18} height={18} src={PlusCircle} alt="add" />}
                      isDisable={isSubCategoryDisabled}
                      style={{ minWidth: '100%', fontSize: '14px' }}
                      onClick={() => {
                        if (
                          ingredientsFormState &&
                          ingredientsFormState === INGREDIENT_FORM_STATUS.MODIFIED
                        ) {
                          setIsAddWarningModalActive(true);
                        } else {
                          updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
                          onAddSubCategory();
                        }
                      }}
                    />
                  </div>
                ) : (
                  <hr
                    style={{
                      backgroundColor: '#CDCDCD',
                      border: 'none',
                      height: '1px',
                      margin: '0'
                    }}
                  />
                )}
                <FormikProvider value={formik}>
                  <FieldArray
                    name={'sub_category_array'}
                    render={(arrayHelper: any) => {
                      return values?.sub_category_array?.map((item: any, index: number) => {
                        return (
                          <SubCategoryItem
                            arrayHelper={arrayHelper}
                            isActiveSubCategory={currentSubCategory == index}
                            item={item}
                            index={index}
                            setCurrentSubCategory={setCurrentSubCategory}
                            subCategoriesOption={subCategoriesOption}
                            key={index}
                            setFieldValue={setFieldValue}
                            values={values}
                            submitIngredients={submitIngredients}
                          />
                        );
                      });
                    }}
                  />
                </FormikProvider>
              </Col>
            </Row>
          </Col>
        </Row>
      </SidebarWrapper>
      {!!isAddWarningModalActive && (
        <ConfirmModal
          visible={isAddWarningModalActive}
          onCancel={onWarningModalCancel}
          title="You are about to leave the Ingredient form"
          onOK={savedProgress}
          // isModalLoading={loading}
          cancelText={'Leave'}
          okText="Save"
        >
          <span>
            It looks like you have not saved the ingredients of the sub category. Make sure you save
            your progress before adding another sub category.
          </span>
        </ConfirmModal>
      )}
    </>
  );
};

export default Sidebar;
