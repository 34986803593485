import PageLayout from '@layout/PageLayout';
import HorizontalTab from '@molecules/HorizontalTabs';
import { PageTitle } from '@molecules/PageTitle';
import React, { useState } from 'react';
import { getEmployeeRecordsTabValues } from './helper';

const EmployeeRecords = () => {
  const [currentTab, setCurrentTab] = useState('upcoming');

  const onTabChange = (a: string) => {
    setCurrentTab(a);
  };
  return (
    <PageLayout>
      <PageTitle text="EMPLOYEE RECORDS" bordered />
      <HorizontalTab
        value={getEmployeeRecordsTabValues(currentTab)}
        activeKey={currentTab}
        onChange={onTabChange}
      />
    </PageLayout>
  );
};

export default EmployeeRecords;
