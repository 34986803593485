import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { H2Typography } from '@constant/typography/Typography';
import { Col, Row } from 'antd';
import { FieldArray, FormikProvider } from 'formik';
import TimingCard from './TimingCard';
import { newTimelineObject } from '../helper';
import { useCallback } from 'react';
import update from 'immutability-helper';
import DragNDropWrapper from '@atom/DragNDropWrapper';
import { Divider } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';

interface EventTimeLineProps {
  formik: any;
}

const EventTimeline = (props: EventTimeLineProps) => {
  const { formik } = props;
  const { touched, errors, values, setFieldValue, handleBlur } = formik;

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const res = update(values.event_time_line, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, values.event_time_line[dragIndex]]
        ]
      });
      setFieldValue('event_time_line', res);
    },
    [values.event_time_line]
  );

  const handleDnD = async (obj: any) => {};

  const timeCardFormikProps = { touched, errors, values, setFieldValue, handleBlur };

  return (
    <>
      <H2Typography style={{ marginTop: '32px' }}>Event Timeline</H2Typography>
      <Divider style={{ margin: '20px 0px 25px 0px' }} />
      <FormikProvider value={formik}>
        <FieldArray
          name={'event_time_line'}
          render={(arrayHelper: any) => {
            if (values?.event_time_line?.length === 0) {
              return null;
            }
            return (
              <>
                {values?.event_time_line?.map((item: any, index: any) => (
                  <DragNDropWrapper
                    key={index}
                    index={index}
                    onDropCard={handleDnD}
                    moveCard={moveCard}
                    isSide={true}
                  >
                    <TimingCard
                      item={item}
                      index={index}
                      event_time_line={values?.event_time_line}
                      moveCard={moveCard}
                      onDropCard={handleDnD}
                      {...timeCardFormikProps}
                    />
                  </DragNDropWrapper>
                ))}
              </>
            );
          }}
        />
      </FormikProvider>
      <Row gutter={16}>
        <Col xs={24} lg={4}>
          <Button
            style={{ fontWeight: '700' }}
            variant="secondary"
            icon={<PlusCircleOutlined />}
            text="Add Another Timing"
            onClick={() => {
              setFieldValue('event_time_line', [
                ...values?.event_time_line,
                { ...newTimelineObject, id: undefined, tempId: Math.random() }
              ]);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default EventTimeline;
