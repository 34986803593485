import { Button } from '@atom/Buttons';
import DatePickerComponent from '@atom/DatePicker';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import PageLayout from '@layout/PageLayout';
import HorizontalTab from '@molecules/HorizontalTabs';
import { PageTitle } from '@molecules/PageTitle';
import { Col, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { getFoodPreprationListTabs } from './helper';
import { showErrorToast } from '@utils/index';
import { RangePickerProps } from 'antd/lib/date-picker';
import { getPrepMenu } from '@services/foodPrepServices';

const FoodPreprationList = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window?.location.search);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().add(7, 'days').format('YYYY-MM-DD'));
  const [callApi, setCallApi] = useState<boolean>(false);
  const [prepMenuTabs, setPrepMenuTabs] = useState([]);
  const [foodPreprationListTabs, setFoodPreprationListTabs] = useState<any>([]);
  const checkIfValidDate = () => {
    if (endDate < startDate) {
      showErrorToast({ message: 'Failed', description: 'End date cannot be in past.' });
    } else {
      setCallApi(true);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (endDate) => {
    return endDate && endDate < moment(startDate);
  };

  useEffect(() => {
    setFoodPreprationListTabs(
      getFoodPreprationListTabs(tab || '', startDate, endDate, callApi, setCallApi, prepMenuTabs)
    );
  }, [prepMenuTabs, callApi]);

  useEffect(() => {
    if (window?.location?.search) {
      setStartDate(searchParams?.get('start_date') || startDate);
      setEndDate(searchParams?.get('end_date') || endDate);
    }
  }, [searchParams?.get('start_date'), searchParams?.get('end_date'), startDate]);

  useEffect(() => {
    if (endDate < startDate) {
      setEndDate(moment(startDate).format('YYYY-MM-DD'));
    }
    disabledDate(moment(startDate));
  }, [startDate]);

  useEffect(() => {
    (async () => {
      const res = await getPrepMenu();
      if (res?.success) {
        setPrepMenuTabs(res?.data);
      }
    })();
  }, [tab]);

  return (
    <PageLayout>
      <Row gutter={18}>
        <Col lg={14} md={14} xs={24}>
          <Row>
            <Col lg={24} md={24} xs={24} style={{ alignItems: 'center', verticalAlign: 'middle' }}>
              <PageTitle text="FOOD PREPARATION"></PageTitle>
            </Col>
          </Row>
        </Col>
        <Col lg={10} md={10} xs={24}>
          <Row gutter={[16, 16]}>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              <Label text="Select Start Date" />
              <DatePickerComponent
                id={'start_date'}
                name={'start_date'}
                defaultValue={moment()}
                value={startDate}
                onChange={(e) => {
                  setStartDate(e);
                }}
                // start dates before Feb 28, 2023 are disabled
                disabledDate={(e: any) => e < moment(1677476176847)}
                allowClear={false}
              />
            </Col>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              <Label text="Select End Date" />
              <DatePickerComponent
                id={'end_date'}
                name={'end_date'}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e);
                }}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </Col>
            <Col
              xl={8}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ display: 'flex', alignItems: 'end' }}
            >
              <Flex className="fix-mobile" alignItems={'center'}>
                <Button
                  style={{ lineHeight: '22px' }}
                  text={'Apply'}
                  onClick={() => checkIfValidDate()}
                />
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: '25px', width: '100%' }}>
        <Col span={24}>
          <HorizontalTab
            value={foodPreprationListTabs}
            activeKey={tab}
            onChange={(tab: any) => {
              let newurl = `/food-preparation/${tab}`;
              navigate(newurl);
            }}
          />
        </Col>
      </Row>
    </PageLayout>
  );
};

export default FoodPreprationList;
