import { phoneRegExp } from '@constants/index';
import services from '@services/index';
import * as yup from 'yup';
import { ResponseType } from '../helper';

//type definitions
export interface DepartmentFormType {
  id?: number;
  master_department_id: string | null;
  position_id: string[];
}

export interface EmployeeType {
  profile_pic: string;
  imageName: string;
  name: string;
  email: string;
  country_code: string;
  phone_number: string;
  extension: string;
  address: string;
  latitude: string;
  longitude: string;
  departments: DepartmentFormType[];
  reports_to: string | null;
  user_type: string;
  send_invite: boolean;
  status_info?: {
    status_name?: string;
  };
  status?: number;
}

export interface ContactDetailsType {
  code: string;
  phone: string;
  ext: string;
}

export interface PositionsType {
  id: number;
  department_id: number;
  name: string;
  rate: string;
  deleted_at: string | null;
}

export interface DepartmentsType {
  id: number;
  name: string;
  deleted_at: string | null;
  positions: PositionsType[];
}

export interface ReportsToType {
  id: number;
  text: string;
  user_type: number;
  formatted_phone_number: string | null;
  registered_at: string | null;
}

//Initial Values
export const additionalDepartment = {
  master_department_id: null,
  position_id: []
};

export const employeeInitialValue = {
  profile_pic: '',
  imageName: '',
  name: '',
  email: '',
  country_code: '',
  phone_number: '',
  extension: '',
  address: '',
  latitude: '',
  longitude: '',
  departments: [additionalDepartment],
  reports_to: null,
  user_type: '',
  send_invite: false
};

//Yup Validations
export const employeeValidationSchema = yup.object({
  profile_pic: yup.string().nullable(),
  name: yup.string().required('Name field is required').nullable(),
  email: yup.string().email().required('Email field is required').nullable(),
  country_code: yup.string().required('Country Code is required').nullable(),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .nullable()
    .matches(phoneRegExp, 'Phone number is invalid'),
  extension: yup.string().nullable(),
  address: yup.string().nullable(),
  departments: yup.array().of(
    yup.object({
      master_department_id: yup.string().required('Department field is required').nullable(),
      position_id: yup
        .array()
        .min(1, 'Position field is required')
        .of(yup.string().required('Position field is required'))
        .required('Position field is required')
        .nullable()
    })
  ),
  reports_to: yup.string().nullable(),
  user_type: yup.string().required('User type field is required').nullable(),
  send_invite: yup.boolean().nullable()
});

// api calls
export const getDepartmentList = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings/departments`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const getReportsToList = async (userId: string): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/profile/reports-to/${userId}`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const addEmployee = async (data: EmployeeType, isEdit = false): Promise<ResponseType> => {
  try {
    const method = isEdit ? services.put : services.post;
    const res = (await method(
      `/employees/${isEdit ? 'update' : 'create'}`,
      data,
      undefined,
      null
    )) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error,
      message:
        error?.error?.error?.email ||
        error?.error?.message ||
        error?.message ||
        'Something went wrong!'
    };
  }
};

export const removeDepartment = async (id: number): Promise<ResponseType> => {
  try {
    const res = (await services.delete(`/employees/delete-department/${id}`)) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const uploadFileHandler = async (file: File, type: number): Promise<ResponseType> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = (await services.post(`/file-upload?type=${type}`, formData, undefined, null, {
      'Content-Type': 'multipart/form-data'
    })) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};
