import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Checkbox from '@atom/Checkbox';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { Value } from '@atom/FormDataView';
import ProductsVirtualList from './VirtualList/VirtualList';
import { FormikValues } from 'formik';
import { Button } from '@atom/Buttons';
import PlusCircleBlackOutline from '@assets/icons/plus-circle-black-outline.svg';
import { EditPencilIcon } from '@assets/index';
import { TypeForObjectOfProductTypes } from '@pages/QuoteManagementPage/types';
import { bundleSRPCalculatorHandler, costCalculator } from './helper';
import { formatCurrency } from '@utils/format';

interface BundlesPropsType {
  formik: FormikValues;
  mealIndex: number;
  bundleIndex: number;
  availableProductTypes: TypeForObjectOfProductTypes[];
  isBundleProductsEditable: boolean;
  setIsBundleProductsEditable: any;
  setMealSubtotal: (values: any, mIndex: number) => void;
  setLocalSelectedBundleIds: (a: number[]) => void;
  duplicateError: any;
  setDuplicateError: (value: any) => void;
}

const MealBundles = (props: BundlesPropsType) => {
  const {
    formik,
    mealIndex,
    bundleIndex,
    availableProductTypes,
    isBundleProductsEditable,
    setIsBundleProductsEditable,
    setMealSubtotal,
    setLocalSelectedBundleIds,
    duplicateError,
    setDuplicateError
  } = props;
  const { values, touched, errors, setFieldValue } = formik;
  const [localBundleQty, setLocalBundleQty] = useState<string>('');
  const [localBundleRate, setLocalBundleRate] = useState<string>('');
  const [localBundleSubtotal, setLocalBundleSubtotal] = useState<string>('');
  const [localBundleSRP, setLocalBundleSRP] = useState<string>('');
  const [isSpecialInstructionsEditable, setIsSpecialInstructionsEditable] = useState<boolean>(
    !!values?.event_dates?.meal_plans?.[mealIndex]?.bundles?.[bundleIndex]?.special_instruction
  );
  const [isAddProdDisabled, setIsAddProdDisabled] = useState<boolean>(false);

  const idBase: string = `event_dates.meal_plans[${mealIndex}].bundles[${bundleIndex}]`;

  const getBaseObjectForBundle = (baseObject: any) => {
    return baseObject?.event_dates?.meal_plans?.[mealIndex]?.bundles?.[bundleIndex];
  };

  useEffect(() => {
    setIsBundleProductsEditable(false);
    setLocalBundleQty(getBaseObjectForBundle(values)?.bundle_quantity);
    setLocalBundleRate(getBaseObjectForBundle(values)?.bundle_rate);
    setLocalBundleSubtotal(getBaseObjectForBundle(values)?.bundle_subtotal);
    setLocalBundleSRP(getBaseObjectForBundle(values)?.bundle_SRP);
  }, []);

  const editBundleHandler = () => {
    setIsBundleProductsEditable((prev: boolean) => !prev);
    return;
  };

  const deleteBundleHandler = () => {
    const tempMealBundlesData = values?.event_dates?.meal_plans?.[mealIndex]?.bundles
      ? JSON.parse(JSON.stringify(values?.event_dates?.meal_plans?.[mealIndex]?.bundles))
      : [];
    if (tempMealBundlesData?.length) {
      tempMealBundlesData?.splice(bundleIndex, 1);
      const tempBundleIds = tempMealBundlesData?.map((item: any) => item?.bundle_id);

      const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
      tempValues.event_dates.meal_plans[mealIndex].bundles = tempMealBundlesData;
      tempValues.event_dates.meal_plans[mealIndex].bundle_ids = tempBundleIds;
      setMealSubtotal(tempValues, mealIndex);

      setFieldValue(`event_dates.meal_plans[${mealIndex}].bundles`, tempMealBundlesData);
      setFieldValue(`event_dates.meal_plans[${mealIndex}].bundle_ids`, tempBundleIds);
      setLocalSelectedBundleIds(tempBundleIds);
    } else {
      setFieldValue(`event_dates.meal_plans[${mealIndex}].bundle_ids`, []);
    }
    return;
  };

  const setBundleSRP = (values: any, mealIndex: number, bundleIndex: number) => {
    const totalSRP = bundleSRPCalculatorHandler(values, mealIndex, bundleIndex).toString();
    setLocalBundleSRP(totalSRP);
    setFieldValue(
      `event_dates.meal_plans.[${mealIndex}].bundles.[${bundleIndex}].bundle_SRP`,
      totalSRP
    );
  };

  const setBundleSubtotal = () => {
    const totalCost = costCalculator(
      getBaseObjectForBundle(values)?.bundle_quantity,
      getBaseObjectForBundle(values)?.bundle_rate
    ).toString();
    setLocalBundleSubtotal(totalCost);
    setFieldValue(`${idBase}.bundle_subtotal`, totalCost);
  };

  useEffect(() => {
    setBundleSubtotal();
    const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
    getBaseObjectForBundle(tempValues).bundle_quantity = localBundleQty;
    getBaseObjectForBundle(tempValues).bundle_rate = localBundleRate;
    getBaseObjectForBundle(tempValues).bundle_subtotal = costCalculator(
      localBundleQty,
      localBundleRate
    );
    setMealSubtotal(tempValues, mealIndex);
  }, [localBundleQty, localBundleRate]);

  return (
    <>
      <Row gutter={16} style={{ marginTop: '24px', marginBottom: '36px' }}>
        <Col xs={24} xl={8}>
          <Label
            text={getBaseObjectForBundle(values)?.bundle_name || 'Package'}
            isMandatory={false}
          />
          <Checkbox
            id={`${idBase}.hide_price`}
            checked={getBaseObjectForBundle(values)?.hide_price}
            onChange={(e: any) => {
              setFieldValue(`${idBase}.hide_price`, e.target.checked);
            }}
            name="Hide individual prices on quotes"
          />
        </Col>
        <Col xs={12} xl={4}>
          <Label text="Qty" />
          <Input
            id={`${idBase}.bundle_quantity`}
            name={`${idBase}.bundle_quantity`}
            value={localBundleQty}
            type="number"
            error={
              getBaseObjectForBundle(touched)?.bundle_quantity &&
              getBaseObjectForBundle(errors)?.bundle_quantity
            }
            onChange={(e: any) => {
              setLocalBundleQty(e.target.value);
              setFieldValue(`${idBase}.bundle_quantity`, e.target.value);
            }}
          />
        </Col>
        <Col xs={12} xl={4}>
          <Label text="Bundle Rate" />
          <Input
            id={`${idBase}.bundle_rate`}
            name={`${idBase}.bundle_rate`}
            prefix="$"
            type="number"
            value={localBundleRate}
            error={
              getBaseObjectForBundle(touched)?.bundle_rate &&
              getBaseObjectForBundle(errors)?.bundle_rate
            }
            onChange={(e: any) => {
              setLocalBundleRate(e.target.value);
              setFieldValue(`${idBase}.bundle_rate`, e.target.value);
            }}
          />
        </Col>
        <Col xs={12} xl={4}>
          <Label text="Total Cost" />
          <Value style={{ alignItems: 'center', display: 'flex', height: '48px' }}>
            {formatCurrency(Number(localBundleSubtotal)) || '-'}
          </Value>
        </Col>
        <Col xs={12} xl={4}>
          <Label text="SRP" />
          <Input
            id={`${idBase}.bundle_SRP`}
            name={`${idBase}.bundle_SRP`}
            disabled={true}
            //@ts-ignore
            value={localBundleSRP ? formatCurrency(Number(localBundleSRP)) : ''}
            error={
              getBaseObjectForBundle(touched)?.bundle_SRP &&
              getBaseObjectForBundle(errors)?.bundle_SRP
            }
          />
        </Col>
        <Col span={24}>
          {values?.event_dates?.meal_plans?.[mealIndex]?.bundles?.length > 0 && (
            <>
              <Row>
                <Button
                  text={!isBundleProductsEditable ? 'EDIT' : 'SAVE'}
                  icon={
                    !isBundleProductsEditable ? (
                      <img src={EditPencilIcon} alt="Add product" />
                    ) : null
                  }
                  variant="ghost"
                  style={{ marginTop: '16px' }}
                  size="small"
                  onClick={editBundleHandler}
                />
                <Button
                  text="DELETE"
                  variant="ghost"
                  style={{ marginTop: '16px' }}
                  size="small"
                  onClick={deleteBundleHandler}
                />
              </Row>

              <ProductsVirtualList
                formik={formik}
                mealIndex={mealIndex}
                isBundleProductList={true}
                isProductList={false}
                bundleIndex={bundleIndex}
                duplicateError={duplicateError}
                setDuplicateError={setDuplicateError}
                isEditable={isBundleProductsEditable}
                availableProductTypes={availableProductTypes}
                setBundleSRPHandler={setBundleSRP}
              />
              {isBundleProductsEditable && (
                <Button
                  text="Add Product"
                  icon={<img src={PlusCircleBlackOutline} alt="Add product" />}
                  variant="secondary"
                  style={{ marginTop: '16px' }}
                  isDisable={
                    isAddProdDisabled || !!(duplicateError && Object.keys(duplicateError)?.length)
                  }
                  onClick={() => {
                    setIsAddProdDisabled(true);
                    setFieldValue(`${idBase}.products`, [
                      ...(getBaseObjectForBundle(values)?.products || []),
                      {
                        id: '',
                        product_id: '',
                        product_subcategory_id: '',
                        product_name: '',
                        product_type: 4,
                        special_instruction: '',
                        portions: '',
                        quantity: '',
                        rate: '',
                        total_cost: '',
                        product: {
                          id: null,
                          is_one_time_product: 0,
                          product_name: '',
                          product_type: 4,
                          status: 1
                        }
                      }
                    ]);
                    setTimeout(() => {
                      setIsAddProdDisabled(false);
                    }, 500);
                  }}
                />
              )}
              {!isSpecialInstructionsEditable && (
                <Button
                  text="+ Add Special Instructions"
                  variant="ghost"
                  style={{ marginLeft: 'auto', fontWeight: 'bold', fontSize: '16px' }}
                  onClick={() => setIsSpecialInstructionsEditable(true)}
                />
              )}
              {isSpecialInstructionsEditable && (
                <Row
                  gutter={16}
                  style={{
                    marginTop: '18px'
                  }}>
                  <Col xs={24} xl={12}>
                    <Label text="Special Instructions" />
                    <Input
                      id={`${idBase}.special_instruction`}
                      name={`${idBase}.special_instruction`}
                      placeholder="add instructions"
                      value={getBaseObjectForBundle(values)?.special_instruction}
                      error={
                        getBaseObjectForBundle(touched)?.special_instruction &&
                        getBaseObjectForBundle(errors)?.special_instruction
                      }
                      onChange={(e: any) => {
                        setFieldValue(`${idBase}.special_instruction`, e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default MealBundles;
