import React, { FC, useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4Typography, H2Typography } from '@constant/typography/Typography';

import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import {
  EditTaskListType,
  getEventTasks,
  TaskListType,
  taskValidationSchema,
  updateTaskList
} from './helper';
import { FieldArray, Formik } from 'formik';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Col, Divider, Row } from 'antd';
import { Input } from '@atom/Input';
import RemoveIcon from '@assets/removerow.png';
import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';
import { EventTaskWrapper } from './style';

const EventTasks = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskList, setTaskList] = useState<TaskListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<TaskListType[] | null>(null);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getTasksHandler();
  }, []);

  const getTasksHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getEventTasks();
    setPageLoading(false);
    if (!result.error) {
      setTaskList(result.data || []);
    }
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getTasksHandler();
    onModalCancelHandler();
  };

  const onTaskDelete = async (index: number, arrayHelper: any, id?: number | null) => {
    if (id) {
      showToast({ message: 'Task Removed Successfully', description: '' });
    }
    arrayHelper.remove(index);
  };

  const onSubmitHandler = (values: EditTaskListType) => {
    setSubmitValue(values.event_tasks);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateTasksHandler = async () => {
    if (!submitValue) return;
    setLoading(true);
    const result = await updateTaskList({ event_tasks: submitValue });
    setLoading(false);
    if (!result.error) {
      onCancelHandler();
      showToast({ message: 'Tasks Updated Successfully', description: '' });
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Tasks',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Flex bottom={26}>
          <H2Typography>Event Tasks</H2Typography>
        </Flex>
        <Formik
          initialValues={{ event_tasks: taskList }}
          validationSchema={taskValidationSchema}
          onSubmit={onSubmitHandler}>
          {({ values, handleChange, setFieldValue, handleSubmit, handleBlur, touched, errors }) => {
            return (
              <Flex direction="column">
                <FieldArray
                  name={'event_tasks'}
                  render={(arrayHelper: any) => {
                    return values?.event_tasks?.map((tasks, index) => (
                      <Row gutter={[16, 16]} align={'bottom'}>
                        <Col lg={23} md={22} xs={20}>
                          <H4Typography>Task Name</H4Typography>
                          <Input
                            id={`event_tasks[${index}].task`}
                            name={`event_tasks[${index}].task`}
                            value={tasks.task}
                            placeholder={`Event Task`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched?.event_tasks?.[index]?.task &&
                              (errors?.event_tasks as { task: string }[])?.[index]?.task
                            }
                          />
                        </Col>
                        <Col
                          lg={1}
                          md={2}
                          xs={4}
                          className="centerPosition"
                          style={{ opacity: 1, textAlign: 'center' }}>
                          <img
                            style={{ paddingTop: '10px' }}
                            src={RemoveIcon}
                            alt="remove"
                            onClick={() => onTaskDelete(index, arrayHelper, tasks?.id)}
                          />
                        </Col>
                      </Row>
                    ));
                  }}
                />
                <Flex bottom={24}>
                  <Button
                    text="Add Another Task"
                    variant="secondary"
                    icon={<img width={20} src={PlusCircle} alt="add" />}
                    onClick={() =>
                      setFieldValue('event_tasks', [...values.event_tasks, { task: '', id: null }])
                    }
                  />
                </Flex>
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8} className="fix-mobile">
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button text="Submit" onClick={handleSubmit} isLoading={loading} />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Saving will impact existing entries."
          onOK={updateTasksHandler}
          isModalLoading={loading}
          okText="Okay">
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <EventTaskWrapper>
      <Flex>
        <H2Typography className="task-title">Event Tasks</H2Typography>
      </Flex>
      <Divider style={{ margin: '10px 0px 30px 0px' }} />

      <Flex style={{ borderBottom: '1px solid', paddingBottom: 12 }}>
        <H4Typography>TASK NAME</H4Typography>
      </Flex>
      <Flex direction="column">
        {taskList.map((task, index) => (
          <p
            className="task-list"
            key={task.id}
            style={{
              background: index % 2 === 0 ? '#f8fafb' : ''
            }}>
            {task.task}
          </p>
        ))}
      </Flex>
    </EventTaskWrapper>
  );
};

export default EventTasks;
