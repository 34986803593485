import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import HorizontalTab from '@molecules/HorizontalTabs';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { useSelector } from 'react-redux';
import { VENDOR_MANAGEMENT_TABS } from '../helper';
import { getTabs } from './helper';
import { TabWrapper } from '@pages/VenueManagementPage/AddEditPages/style';

function VendorListView() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [tabType, setTabType] = useState<any>();

  const vendorManagementTabs = getTabs(tabType);
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  useEffect(() => {
    if (window.location.pathname === '/vendors' || window.location.pathname === '/vendors/') {
      navigate(`/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setTabType(tab);
  }, [tab]);

  return (
    <PageLayout title={'VENDOR MANAGEMENT'}>
      <Flex direction="column" flex={1}>
        <PageTitle text="VENDOR MANAGEMENT" isBack={false}>
          {isAdmin && <Button text="Add Vendor" onClick={() => navigate('/vendors/create')} />}
        </PageTitle>
        <Row gutter={24}>
          <Col span={24}>
            <TabWrapper>
              <HorizontalTab
                value={vendorManagementTabs}
                activeKey={tabType}
                onChange={(tab: any) => {
                  setTabType(tab);
                  let newurl = `/vendors/tab/${tab}`;
                  navigate(newurl);
                }}
              />
            </TabWrapper>
          </Col>
        </Row>
      </Flex>
    </PageLayout>
  );
}

export default VendorListView;
