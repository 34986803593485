import styled from 'styled-components';

export const EventTaskWrapper = styled.div`
  .task-list {
    margin: 0;
    padding: 16px 30px;
    fontsize: 16;
  }

  .task-title {
    font-size: 30px !important;
    font-weight: 400;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    .task-list {
      padding: 16px 0px;
    }
    .task-title {
      font-size: 18px !important;
      font-weight: 600;
    }
  }
`;
