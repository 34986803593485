import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex } from '@atom/Flex';
import { H4Typography } from '@constant/typography/Typography';
import {
  EditPrepMenuType,
  getMenuDetails,
  PrepMenuValidationSchema,
  RestrictionListType,
  updateMenuNames
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Formik, FormikProps } from 'formik';
import MenuManagementEditableVirtualList from './MenuManagementEditableVirtualList';
import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';
import ScrollToFieldError from '@utils/formikFocus';

const PrepMenu = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [prepMenu, setPrepMenu] = useState<RestrictionListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<RestrictionListType[] | null>(null);
  const [scrollToBottomOfList, setScrollToBottomOfList] = useState<boolean>(false);
  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getPrepMenuHandler();
  }, []);

  const getPrepMenuHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getMenuDetails('prep-menu');
    setPageLoading(false);
    if (!result.error) {
      setPrepMenu(result.data || []);
    }
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getPrepMenuHandler();
    onModalCancelHandler();
  };

  const onPrepMenuDelete = (
    index: number,
    ingridentsArray: any,
    setFieldValue: any,
    id?: number | null,
    is_associated?: boolean
  ) => {
    if (is_associated) {
      showErrorToast({
        message: 'Prep Menu is used with product so it will not deleted',
        description: ''
      });
      return false;
    } else {
      if (id) {
        showToast({ message: 'Prep Menu Removed Successfully', description: '' });
      }
      const tempArr = [...JSON.parse(JSON.stringify(ingridentsArray))];
      tempArr?.splice(index, 1);
      setFieldValue('prep_menus', tempArr);
      return true;
    }
  };

  const onSubmitHandler = (values: EditPrepMenuType) => {
    setSubmitValue(
      values?.prep_menus?.map((menu: any) => {
        let item = menu;
        if (!item?.id) delete item?.id;
        return item;
      })
    );
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateMenuNametHandler = async () => {
    if (!submitValue) return;
    setLoading(true);
    const result = await updateMenuNames({ prep_menus: submitValue }, 'prep-menu');
    setLoading(false);
    if (!result.error) {
      showToast({ message: 'Prep Menus Updated Successfully', description: '' });
      onCancelHandler();
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Prep Menus',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Flex bottom={26}>
          <H4Typography>Prep Menu</H4Typography>
        </Flex>
        <Formik
          initialValues={{ prep_menus: prepMenu }}
          validationSchema={PrepMenuValidationSchema}
          onSubmit={onSubmitHandler}>
          {(formikProps: FormikProps<any>) => {
            const { values, setFieldValue, handleSubmit } = formikProps;
            return (
              <Flex direction="column">
                <ScrollToFieldError formik={formikProps} />
                <MenuManagementEditableVirtualList
                  formik={formikProps}
                  onItemDelete={onPrepMenuDelete}
                  fieldArrayName="prep_menus"
                  inputFieldPlaceHolder="Prep Menu Name"
                  scrollToBottomOfList={scrollToBottomOfList}
                  setScrollToBottomOfList={setScrollToBottomOfList}
                />
                <Flex bottom={24}>
                  <Button
                    text="Add Prep Menu"
                    variant="secondary"
                    icon={<img width={20} src={PlusCircle} alt="add" />}
                    onClick={() => {
                      setFieldValue('prep_menus', [...values.prep_menus, { name: '', id: '' }]);
                      setScrollToBottomOfList(true);
                    }}
                  />
                </Flex>
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8}>
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button text="Save" onClick={handleSubmit} isLoading={loading} />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Saving will impact existing entries."
          onOK={updateMenuNametHandler}
          isModalLoading={loading}
          okText="Okay">
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <div>
      <Flex bottom={26}>
        <H4Typography>Prep Menu</H4Typography>
      </Flex>
      <Flex style={{ flexFlow: 'wrap', gap: '16px 40px' }}>
        {prepMenu.map((prepMenu) => (
          <Flex key={prepMenu.id}>
            <p
              style={{
                backgroundColor: '#F4F4F4',
                borderRadius: '5px',
                padding: '5px 10px',
                margin: 0
              }}>
              {prepMenu.name}
            </p>
          </Flex>
        ))}
      </Flex>
    </div>
  );
};
export default PrepMenu;
