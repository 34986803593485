import { Col, Divider, Row } from 'antd';
import { FieldArray, Formik } from 'formik';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { H1Typography } from '@constant/typography/Typography';
import ModalComponent from '@organism/Modal/Modal';
import { AddEditBundleModalProps } from '@pages/MenuManagementPage/types';
import { showErrorToast, showToast } from '@utils/index';

import BundleProductFields from './BundleProductFields';
import ExistingProductFields from './ExistingProductFields';
import { addBundle, addNewBundleValidations, newBundleObject } from './helper';
import { useState } from 'react';

function AddNewBundleModal(props: AddEditBundleModalProps) {
  const { isModalVisible, onOk, onCancel, dropdownData, isFromQuoteManagement = false } = props;
  const index = 0;
  const [duplicateError, setDuplicateError] = useState<any>({});
  const handleUpdate = (values: any, setFieldValue: any, index: any, data: any, key: any) => {
    const tempData = values?.products ? [...values.products] : [];
    tempData[index][key] = data;
    setFieldValue('products', tempData);
  };

  const handleCancel = (resetForm: any, setValues: any) => {
    resetForm();
    setValues({
      bundle_name: undefined,
      products: [
        {
          id: new Date().getTime(),
          product_type: 4,
          product_subcategory_id: undefined,
          product_id: undefined,
          quantity_per_product: undefined,
          product_cost: undefined
        }
      ]
    });
    onCancel();
  };

  const onSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    try {
      const res: any = await addBundle(values);
      if (res?.success) {
        showToast({
          message: 'Success!',
          description: 'Bundle Saved'
        });
        actions.resetForm();
        if (isFromQuoteManagement) {
          onOk(res?.data?.data?.id);
        } else {
          onOk();
        }
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        bundle_name: undefined,
        products: [
          {
            id: new Date().getTime(),
            product_type: 4,
            product_subcategory_id: undefined,
            product_id: undefined,
            quantity_per_product: undefined,
            product_cost: undefined
          }
        ]
      }}
      validationSchema={addNewBundleValidations}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
    >
      {(prop) => {
        const {
          touched,
          errors,
          values,
          setFieldValue,
          handleBlur,
          handleSubmit,
          handleChange,
          setValues,
          resetForm
        } = prop;

        return (
          <>
            <ModalComponent
              visible={isModalVisible}
              onCancel={() => handleCancel(resetForm, setValues)}
              modalWidth="798px !important"
              align="left !important"
            >
              <Flex justifyContent="center" bottom={38}>
                <H1Typography>Add a New Bundle</H1Typography>
              </Flex>
              <Row gutter={16}>
                <Col xs={24} lg={24}>
                  <Label isMandatory={true} text="Bundle Names" />
                  <Input
                    name="bundle_name"
                    id="bundle_name"
                    error={touched?.bundle_name && errors?.bundle_name}
                    placeholder="Bundle Name"
                    onChange={handleChange}
                    value={values.bundle_name}
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <Divider style={{ marginTop: '0px', borderColor: '#c4c4c4' }} />
              <BundleProductFields
                index={index}
                duplicateError={duplicateError}
                setDuplicateError={setDuplicateError}
                handleUpdate={handleUpdate}
                dropdownData={dropdownData}
                formik={prop}
              />
              <Divider style={{ borderColor: '#c4c4c4' }} />
              <Row gutter={16}>
                <Col xs={24} lg={24}>
                  <FieldArray
                    name={'products'}
                    render={(arrayHelper: any) => {
                      return values?.products?.map((item: any, index: any) => {
                        if (index > 0) {
                          return (
                            <ExistingProductFields
                              key={index}
                              data={item}
                              index={index}
                              dropdownData={dropdownData}
                              formik={prop}
                              duplicateError={duplicateError}
                              setDuplicateError={setDuplicateError}
                              onRemove={(curId: any) =>
                                setFieldValue(
                                  'products',
                                  values?.products?.filter((i: any) => i?.id !== curId)
                                )
                              }
                            />
                          );
                        }
                      });
                    }}
                  />
                </Col>
              </Row>
              <Button
                style={{ padding: '6px 10px', height: '38px' }}
                variant="secondary"
                icon={<PlusCircleOutlined />}
                isDisable={!!(duplicateError && Object.keys(duplicateError)?.length)}
                text="Add Existing Product"
                onClick={() =>
                  setFieldValue('products', [
                    ...values?.products,
                    { ...newBundleObject, id: new Date().getTime() }
                  ])
                }
              />
              <Flex className="footerButtonModal fix-mobile" style={{ marginBottom: '5px' }}>
                <Button
                  variant="secondary"
                  text={'Cancel'}
                  onClick={() => handleCancel(resetForm, setValues)}
                  className="cancel-btn"
                />
                <Button
                  variant="primary"
                  text={'Save'}
                  onClick={handleSubmit}
                  isLoading={prop.isSubmitting}
                />
              </Flex>
            </ModalComponent>
          </>
        );
      }}
    </Formik>
  );
}

export default AddNewBundleModal;
