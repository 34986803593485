import * as yup from 'yup';

export const myScheduleValidations = yup.object().shape({
  schedules: yup.array().of(
    yup.object().shape({
      day: yup.string().nullable(),
      start_time: yup.string().nullable(),
      end_time: yup.string().nullable(),
      user_id: yup.number().nullable()
    })
  )
});
