import BreadCrumbs from '@atom/BreadCrumb';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { Divider } from 'antd';
import { EventSelectQuoteBreadCrumbs } from '../EventDetails/helper';
import QuoteList from './QuoteList';

const FindQuote = () => {
  return (
    <PageLayout>
      <BreadCrumbs routes={EventSelectQuoteBreadCrumbs()} />
      <div style={{ marginTop: '15px' }}>
        <PageTitle text="FIND A QUOTE" isBottom={true} isBack={false} path="/events"></PageTitle>
      </div>
      <Divider />
      <QuoteList />
    </PageLayout>
  );
};

export default FindQuote;
