import services from '@services/index';
import * as Yup from 'yup';
export enum EVENT_DETAILS_TABS {
  INFO = 'info',
  DOCUMENTS = 'documents',
  TASKS = 'tasks'
}

export interface IEventCodeListType {
  id: number;
  text: string;
}

export const EVENT_TASK_INITIAL_VALUES = {
  tasks: []
};

export const sendMessageSchema = Yup.object().shape({
  subject: Yup.string().required('The subject field is required.'),
  message: Yup.string().required('The message field is required.'),
  employee_id: Yup.array()
    .min(1, 'The staff field is required.')
    .required('The staff field is required.')
});

export const tasksSchema = Yup.object().shape({
  tasks: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        completed_at: Yup.mixed()
          .nullable()
          .when('is_completed', {
            is: true,
            then: Yup.string().nullable().required('The completed at field is required.')
          }),
        is_completed: Yup.boolean().nullable(),
        master_event_task_id: Yup.string().nullable(),
        task: Yup.string().nullable()
      })
    )
});

export const changeEventStatus = async (
  event: number | string,
  payload: {
    event_id: number | string;
    status: number | string;
  }
) => {
  try {
    const res = (await services.put(`/events/${event}/update-status`, payload)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getEventDetails = async (event_id: any) => {
  try {
    const res = (await services.get(`/events/${event_id}/view`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getEventCodeList = async (event_id: any) => {
  try {
    const res = (await services.get(`/events/${event_id}/code-list`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const releaseEvent = async (event_id: any) => {
  try {
    const res = (await services.get(`/events/release-final-details/${event_id}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

// for get next and prev event
// export const getNextPrev = async (event_id: number, flag: 1 | 2) => {
//   try {
//     const res = (await services.get(`/events/${event_id}/next-prev/${flag}`)) as {
//       data: any;
//     };
//     return res?.data;
//   } catch (err: any) {
//     return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
//   }
// };

export const confirmEvent = async (event_id: any, status: any) => {
  try {
    const res = (await services.put(`/events/${event_id}/update-status`, { event_id, status })) as {
      data: any;
    };
    return res?.data?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const sendMessage = async (values: any) => {
  try {
    const res = (await services.post(`/events/send-message`, values)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const completeTasks = async (values: any) => {
  try {
    const res = (await services.post(`/events/complete-task`, values)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const EventFindQuoteBreadCrumbs = (quoteId: any, eventId: any) => {
  return [
    { name: 'Event Lists', path: `/events/upcoming` },
    { name: 'Event Details', path: `/events/${eventId}/view` },
    { name: 'Edit Event' }
  ];
};

export const QuoteDetailsFindQuoteBreadcrumb = (quoteId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    { name: ' Edit Event' }
  ];
};

export const EventEditQuoteBreadCrumbs = (quoteId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    { name: 'Create Event' }
  ];
};

export const QuoteViewEventEditEventBreadCrumbs = (quoteId: any, eventId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    {
      name: 'Event Details',
      path: `/events/${eventId}/view`,
      state: { path: 'event_details' }
    },
    { name: 'Edit Event' }
  ];
};

export const QuoteViewEventEditEventEditQuoteBreadCrumbs = (quoteId: any, eventId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    {
      name: 'Event Details',
      path: `/events/${eventId}/view`,
      state: { path: 'event_details' }
    },
    { name: 'Edit Event', path: `/events/find-quote/create/${quoteId}/${eventId}/quote/` },
    {
      name: ' Edit Quote'
    }
  ];
};

export const QuoteViewEventEditEventQuoteBreadCrumbs = (quoteId: any, eventId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    {
      name: 'Event Details',
      path: `/events/${eventId}/view`,
      state: { eventId: eventId, path: 'event_details' }
    },
    { name: 'Find Quote' }
  ];
};

export const EventEditBreadCrumbs = (quoteId: any, eventId: any) => {
  return [
    { name: 'Event Lists', path: `/events/upcoming` },
    { name: 'Event Details', path: `/events/${eventId}/view` },
    { name: 'Edit Event' }
  ];
};

export const EventBreadCrumbsViewEvent = (quoteId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    { name: 'Event Details' }
  ];
};

export const QuoteCreateEventEventDetailsBreadcrumb = (quoteId: any, eventId: any) => {
  return [
    { name: 'Quote Lists', path: `/quote/tab/all` },
    { name: 'Quote Details', path: `/quote/view/${quoteId}` },
    { name: 'Create Event', path: `/events/find-quote/create/${quoteId}/${eventId}/quote` },
    { name: 'Event Details' }
  ];
};

export const EventDetailsBreadCrumbs = () => {
  return [{ name: 'Event Lists', path: `/events/upcoming` }, { name: 'Event Details' }];
};

export const EventSelectQuoteBreadCrumbs = () => {
  return [{ name: 'Event Lists', path: `/events/upcoming` }, { name: 'Select Quote' }];
};

export const EventGeneralDocBreadCrumbs = () => {
  return [{ name: 'Event Lists', path: `/events/upcoming` }, { name: 'General Documents' }];
};

export const EventWithoutQuoteBreadCrumbs = () => {
  return [{ name: 'Event Lists', path: `/events/upcoming` }, { name: 'Create Event' }];
};

export const EventFindQuoteEditQuoteBreadCrumbs = () => {
  return [
    { name: 'Event Lists', path: `/events/upcoming` },
    { name: 'Select Quote', path: `/events/find-quote` },
    {
      name: 'Create Quote'
    }
  ];
};
