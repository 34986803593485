import services from '@services/index';
import { updateRawData } from '@utils/index';
import { Select } from 'antd';

export const duplicateProductErrorMessage =
  'Same product name use is allowed once per bundle, Please increase quantity to use same product again.';

export enum MENU_MANAGEMENT_TABS {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DRAFTS = 'draft',
  BUNDLES = 'bundle'
}
export enum PRODUCT_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  DRAFT = 2
}

export enum INGREDIENT_FORM_STATUS {
  UNCHANGED = 0,
  MODIFIED = 1,
  SAVING = 2,
  SAVED = 3
}

export const PRODUCT_STATUS_NAME: any = {
  [PRODUCT_STATUS.INACTIVE]: 'Inactive',
  [PRODUCT_STATUS.ACTIVE]: 'Active',
  [PRODUCT_STATUS.DRAFT]: 'Draft'
};

export const MENU_MANAGEMENT_TABS_KEY: any = {
  [PRODUCT_STATUS.INACTIVE]: MENU_MANAGEMENT_TABS.INACTIVE,
  [PRODUCT_STATUS.ACTIVE]: MENU_MANAGEMENT_TABS.ACTIVE,
  [PRODUCT_STATUS.DRAFT]: MENU_MANAGEMENT_TABS.DRAFTS
};

export enum PRODUCT_TYPES {
  KOSHER = 1,
  CUSTOM = 2,
  HOTEL_X = 3,
  REGULAR = 4
}

export const PRODUCT_TYPE_COLORS: any = {
  [PRODUCT_TYPES.KOSHER]: '#4e73df',
  [PRODUCT_TYPES.CUSTOM]: '#f6c23e',
  [PRODUCT_TYPES.HOTEL_X]: '#1cc88a',
  [PRODUCT_TYPES.REGULAR]: '#ed07c6'
};

export const getProductTypes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/settings/product-types')) as { data: any };
      updateRawData({
        productTypes: res.data?.data?.filter((i: any) => i.text !== 'Archived') || []
      });
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

export const getProduct = async (id: any) => {
  try {
    const res = (await services.get(`/product/edit-detail/${id}`)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getProducts = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/product/list/drop-down')) as { data: any };
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

export const getBundle = async (id: any) => {
  try {
    const res = (await services.get(`/bundle/view/${id}`)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const checkDuplicate = (values: any, setDuplicateError: (value: any) => void) => {
  let newErrors = {};
  setDuplicateError(newErrors);
  for (let i = 0; i < values?.products?.length - 1; i++) {
    let foundDuplicate = false;
    for (let j = i + 1; j < values?.products?.length; j++) {
      foundDuplicate =
        values?.products?.[i]?.product_id &&
        values?.products?.[i]?.product_subcategory_id &&
        values?.products?.[i]?.product_subcategory_id ===
          values?.products?.[j]?.product_subcategory_id &&
        values?.products?.[i]?.product_id === values?.products?.[j]?.product_id;
      //  &&
      // Number(values?.products?.[i]?.quantity_per_product) ===
      //   Number(values?.products?.[j]?.quantity_per_product);
      if (foundDuplicate) {
        newErrors = { ...newErrors, [j]: duplicateProductErrorMessage };
      }
    }
  }
  setDuplicateError(newErrors);
};
