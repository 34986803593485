import { FormikValues } from 'formik';
import ProductsVirtualList from './VirtualList/VirtualList';

import PlusCircleBlackOutline from '@assets/icons/plus-circle-black-outline.svg';
import { Button } from '@atom/Buttons';
import { TypeForObjectOfProductTypes } from '@pages/QuoteManagementPage/types';

interface MealProductsPropType {
  formik: FormikValues;
  mealIndex: number;
  availableProductTypes: TypeForObjectOfProductTypes[];
  setMealSubtotal: (values: any, mIndex: number) => void;
  duplicateError: any;
  setDuplicateError: (value: any) => void;
}

const MealProducts = (props: MealProductsPropType) => {
  const {
    formik,
    mealIndex,
    availableProductTypes,
    setMealSubtotal,
    duplicateError,
    setDuplicateError
  } = props;
  const { values, setFieldValue } = formik;
  return (
    <>
      {values?.event_dates?.meal_plans?.[mealIndex]?.products?.length > 0 && (
        <ProductsVirtualList
          isBordered={false}
          formik={formik}
          mealIndex={mealIndex}
          isBundleProductList={false}
          duplicateError={duplicateError}
          setDuplicateError={setDuplicateError}
          isProductList={true}
          isEditable={true}
          availableProductTypes={availableProductTypes}
          setMealSubtotalHandler={setMealSubtotal}
        />
      )}
      <Button
        text="Add Product"
        icon={<img src={PlusCircleBlackOutline} alt="Add product" />}
        variant="ghost"
        style={{ marginTop: '16px', fontWeight: 'bold' }}
        size="small"
        onClick={() => {
          if (!(duplicateError && Object.keys(duplicateError)?.length)) {
            setFieldValue(`event_dates.meal_plans[${mealIndex}].products`, [
              ...(values?.event_dates?.meal_plans?.[mealIndex]?.products || []),
              // {
              //   id: Math.floor(Math.random() * 10000000),
              //   product_id: Math.floor(Math.random() * 10000000),
              //   product_name: `${Math.floor(Math.random() * 10000000)}`,
              //   product_type: 4,
              //   special_instructions: `${Math.floor(Math.random() * 10000000)}`,
              //   product_portions: `${Math.floor(Math.random() * 10000000)}`,
              //   quantity: `${Math.floor(Math.random() * 10000000)}`,
              //   rate: `${Math.floor(Math.random() * 10000000)}`,
              //   total_cost: `${Math.floor(Math.random() * 10000000)}`
              // }
              {
                id: '',
                product_id: '',
                product_subcategory_id: '',
                product_name: '',
                product_type: 4,
                special_instruction: '',
                portions: '',
                quantity: '',
                rate: '',
                total_cost: '',
                product: {
                  id: null,
                  is_one_time_product: 0,
                  product_name: '',
                  product_type: 4,
                  status: 1
                }
              }
            ]);
          }
        }}
      />
    </>
  );
};

export default MealProducts;
