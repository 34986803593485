import TableHeader from '@atom/TableHeader';
import services from '@services/index';
import { downloadsheet } from '@utils/helper';
import * as Yup from 'yup';

declare interface FilterTypes {
  name: string;
  company_name: string;
  email: string;
  location: string;
  phone_number: string;
}

declare interface CustomerRowsType {
  customer_name: string;
  company_name: string;
  address: string;
  email: string;
  phone_number: string;
}

const filter = {
  name: '',
  company_name: '',
  email: '',
  location: '',
  phone_number: ''
};

export const getCustomerList = async (page: number | 'all', data: FilterTypes) => {
  try {
    const res: any = await services.post(`/customer/list?page=${page || 1}`, data);
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getColumns = (filterValue: any, onChange: (a: any) => void) => {
  const columns = [
    {
      title: (
        <TableHeader
          columnTitle="Customer Name"
          name="name"
          value={filterValue.name ?? ''}
          placeholder="Customer"
          onChange={onChange}
        />
      ),

      dataIndex: 'name',
      width: '10%',
      render: (data: any, row: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Company Name"
          name="company_name"
          value={filterValue.company_name ?? ''}
          placeholder="Company Name"
          onChange={onChange}
        />
      ),
      dataIndex: 'company_name',
      width: '10%',
      render: (data: any, row: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Location"
          name="location"
          value={filterValue.location ?? ''}
          placeholder="City, Province"
          onChange={onChange}
        />
      ),
      dataIndex: 'address',
      width: '10%',
      render: (data: any, row: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Email"
          name="email"
          value={filterValue.email ?? ''}
          placeholder="Email"
          onChange={onChange}
        />
      ),
      dataIndex: 'email',
      width: '10%',
      render: (data: any, row: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Phone Number"
          name="phone_number"
          value={filterValue.phone_number ?? ''}
          placeholder="Phone Number"
          onChange={onChange}
        />
      ),
      dataIndex: 'phone_number',
      width: '10%',
      render: (data: any, row: any) => {
        return data && row?.country_code
          ? `${
              row?.country_code?.includes('+') ? row?.country_code : `+${row?.country_code}`
            } ${data}  ${row.extension ? 'x' + row.extension : ''}`
          : data || '-';
      }
    }
  ];
  return columns;
};

export const getDummyData = () => {
  return new Array(1000).fill('').map((item: any, index) => {
    const dummyObject = {
      id: Math.random(),
      customer_name: 'customer_name' + index,
      email: 'email' + index,
      phone_number: 'phone_number' + index
    };
    return dummyObject;
  });
};

const fetchCustomerData = async () => {
  try {
    const { data } = await getCustomerList('all', filter);
    if (!data) {
      console.error('Error fetching customer data');
      return;
    }
    return data;
  } catch (error) {
    console.error('Error fetching customer data:', error);
  }
};

export const handleDownloadCustomerSheet = async () => {
  const data = await fetchCustomerData();

  if (!data || !data.length) {
    console.error('No data to download');
    return;
  }

  const headerRow = ['Customer Name', 'Company Name', 'Location', 'Email', 'Phone Number'];

  const customerRows: string[][] = data.map((customer: CustomerRowsType) => [
    customer.customer_name,
    customer.company_name,
    customer.address,
    customer.email,
    customer.phone_number
  ]);

  const customerData: string[][] = [headerRow, ...customerRows];

  downloadsheet(customerData, 'The-FoodDudes-Customers.csv');
};
