import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4, H4Typography, TypoBodyBody1 } from '@constant/typography/Typography';

import CenterSpiner from '@atom/CenterSpiner';
import { getMenuDetails } from '../MenuManagement/helper';
import FormDataView from '@atom/FormDataView';
import { getLogoList, LogoLibraryType, removeLogo, uploadLogoList } from './helper';
import { useSelector } from 'react-redux';
import FileUploadButton from '@atom/FileUploadButton';
import ImagePreviewer from '@atom/ImagePreviewer';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import ConfirmModal from '@organism/ConfimationModal';
import FileDraggerComponent from '@molecules/FileDraggerComponent';
import { Tooltip } from 'antd';
import QuestionMark from '@assets/question-mark.svg';
import { Button } from '@atom/Buttons';
import { getImageDimensions } from '@utils/helper';

const LogoLibrary = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  let [logoList, setLogoList] = useState<LogoLibraryType[] | any[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<LogoLibraryType | null>(null);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);
  const logoSize = 150;

  useEffect(() => {
    getLogoListHandler();
  }, []);

  const getLogoListHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getLogoList();
    setPageLoading(false);
    if (!result.error) {
      setLogoList(result.data || []);
    }
  }, []);

  const onUploadImages = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files || { file: e };
    if (files) {
      const logos: any[] = [];
      Object.values(files).forEach(async (file, index) => {
        const objectUrl = URL.createObjectURL(file);
        const size: any = await getImageDimensions(objectUrl);
        if (size?.height <= logoSize && size?.width <= logoSize) {
          logos.push({
            id: new Date().getTime(),
            image_url: objectUrl,
            image_detail: file,
            isNotUploaded: true
          });
          logoList = [...logos, ...logoList];
          setLogoList(logoList);
        } else {
          showErrorToast({
            message: 'Failed!',
            description: `Logo size should be ${logoSize}px`
          });
        }
      });
    }
  };

  const handleSubmit = async () => {
    const formDataStory = new FormData();
    const alreadyExistedLogos: any = [];
    logoList.forEach((file, index) => {
      if (file?.isNotUploaded) {
        formDataStory.append(`logos[${index}]`, file?.image_detail);
      } else {
        alreadyExistedLogos.push(file?.id);
      }
    });
    if (alreadyExistedLogos.length) {
      formDataStory.append(`logo_ids`, alreadyExistedLogos);
    }
    setPageLoading(true);
    const result = await uploadLogoList(formDataStory);
    setPageLoading(false);
    if (!result?.error) {
      showToast({ message: 'Logos Updated Successfully', description: '' });
      getLogoListHandler();
    } else {
      return showErrorToast({
        message: 'Error Updating Logos',
        description: result.error || 'Please try again'
      });
    }
    updateRawData({ isGlobalSettingsEdit: false });
  };

  const onLogoDelete = async () => {
    const id = submitValue?.id;
    if (!id) return;
    const index = logoList.indexOf(submitValue);
    onModalCancelHandler();
    setLoading(true);
    setPageLoading(true);
    if (index > -1) {
      logoList.splice(index, 1);
      setLogoList([...logoList]);
    }
    setLoading(false);
    setPageLoading(false);
    showToast({ message: 'Logo Removed Successfully', description: '' });
  };

  const onSubmitHandler = (logo: LogoLibraryType) => {
    setSubmitValue(logo);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const imageHelperTooltip = (
    <Flex direction="column" alignItems="end">
      <p>Logo Images</p>
      <p>150 x 150 px</p>
    </Flex>
  );

  if (pageLoading) {
    return <CenterSpiner />;
  }

  const handleCancel = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getLogoListHandler();
  };

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <FileUploadButton multiple text="Upload Images" onUpload={onUploadImages} />
        {!logoList.length && (
          <Flex direction="column">
            <Flex top={30} justifyContent={'space-between'} alignItems={'center'}>
              <TypoBodyBody1 style={{ margin: 0 }}>*Please upload JPG,PNG or SVG</TypoBodyBody1>
              <Flex alignItems={'center'} gap={5}>
                <TypoBodyBody1 style={{ margin: 0 }}>
                  Best Image Sizes for Printable Items
                </TypoBodyBody1>
                <Tooltip placement="bottomLeft" title={imageHelperTooltip}>
                  <img src={QuestionMark} alt="questionMark" style={{ height: 19, width: 16 }} />
                </Tooltip>
              </Flex>
            </Flex>
            <Flex top={10}>
              <FileDraggerComponent
                loading={pageLoading}
                onChangeHandle={onUploadImages}
                multiple={true}
                accept={'.jpg, .png, .jpeg, .svg'}
              />
            </Flex>
          </Flex>
        )}
        <Flex top={16} style={{ flexFlow: 'wrap', gap: '40px' }}>
          {logoList.map((logo) => (
            <ImagePreviewer
              className={"transparent-background"}
              key={logo.id}
              image={logo.image_url}
              closable
              onClose={() => onSubmitHandler(logo)}
              theme={{ wrapper: { width: '150px', height: '150px' } }}
            />
          ))}
        </Flex>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Are you sure you want to delete this logo?"
          onOK={onLogoDelete}
          isModalLoading={loading}
          okText="Confirm Delete">
          <div>
            <ImagePreviewer
              className={"transparent-background"}
              image={submitValue?.image_url || ''}
              theme={{ wrapper: { width: '280px', height: '200px', margin: 'auto' } }}
            />
          </div>
        </ConfirmModal>
        <Flex gap={10} top={24} justifyContent={'flex-end'} className="fix-mobile">
          <Button text={'Cancel'} variant={'secondary'} onClick={handleCancel} />
          <Button text={'Submit'} onClick={handleSubmit} />
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <Flex bottom={16}>
        <H4Typography>LOGO LIBRARY</H4Typography>
      </Flex>
      {!logoList?.length && (
        <Flex bottom={26}>
          <H4
            text={'Logos in your library can be inserted into quotations'}
            style={{ fontWeight: 'normal' }}
          />
        </Flex>
      )}
      <Flex style={{ flexFlow: 'wrap', gap: '40px' }}>
        {logoList.map((logo) => (
          <ImagePreviewer
            className={"transparent-background"}
            key={logo.id}
            image={logo.image_url}
            theme={{ wrapper: { width: '150px', height: '150px' }, image: { borderRadius: '0px' } }}
          />
        ))}
      </Flex>
    </div>
  );
};

export default LogoLibrary;
