import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Spin, Badge } from 'antd';
import MinusCircleBlack from '@assets/minus-circle-black.png';
import { Label } from '@atom/FormLable';
import { SelectInputWithCheckBox } from '@atom/Select';
import { Input } from '@atom/Input';
import TimePickerComponent from '@atom/TimePicker';
import { Button } from '@atom/Buttons';
import { FormikValues } from 'formik';
import { BundleType, TypeForObjectOfProductTypes } from '@pages/QuoteManagementPage/types';
import MealBundles from './MealBundles';
import MealProducts from './MealProducts';
import { H2Typography } from '@constant/typography/Typography';
import {
  bundlesListButton,
  checkEmptyProducts,
  fetchAllAvailableBundlesList,
  fetchAllAvailableProductTypesList,
  mealSubtotalCalculatorHandler
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { GetBundleProductsList } from '@services/quoteServices';
import moment, { Moment } from 'moment';
import { ProductBadge } from './style';
import { showErrorToast } from '@utils/index';
import AddNewBundleModal from '@pages/MenuManagementPage/AddEditPages/AddEditProduct/AddNewBundleModal';
import { getBundlesOptions } from '@pages/MenuManagementPage/AddEditPages/AddEditProduct/helper';
import { useSelector } from 'react-redux';
import { formatCurrency } from '@utils/format';

interface MealDataComponentPropsType {
  formik: FormikValues;
  mIndex: number;
  duplicateError: any;
  setDuplicateError: (value: any) => void;
  isBundleProductsEditable: boolean;
  setIsBundleProductsEditable: any;
}

const MealDataComponent = (props: MealDataComponentPropsType) => {
  const {
    formik,
    mIndex,
    duplicateError,
    setDuplicateError,
    isBundleProductsEditable,
    setIsBundleProductsEditable
  } = props;
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } =
    formik;

  const { productsList } = useSelector((state: any) => state.rawData);
  const [loading, setLoading] = useState<boolean>(true);
  const [localSelectedBundleIds, setLocalSelectedBundleIds] = useState<number[]>([]);
  const [bundlesLoading, setBundlesLoading] = useState<boolean>(false);
  const [isAddBundle, setAddBundle] = useState<boolean>(false);
  const [availableBundles, setAvailableBundles] = useState<BundleType[]>([
    bundlesListButton(setAddBundle)
  ]);
  const [allProductTypes, setAllProductTypes] = useState<TypeForObjectOfProductTypes[]>([]);
  const [availableProductTypes, setAvailableProductTypes] = useState<TypeForObjectOfProductTypes[]>(
    []
  );
  const [localMealSubtotal, setLocalMealSubtotal] = useState<string | number>('');
  const setMealSubtotal = (values: any, mIndex: number) => {
    const totalCost = mealSubtotalCalculatorHandler(values, mIndex).toString();
    setLocalMealSubtotal(totalCost);
    setFieldValue(`event_dates.meal_plans.[${mIndex}].allsubtotal`, totalCost);
  };

  const changeBundleHandler = async (bundle_ids: number[]) => {
    setBundlesLoading(true);
    let tempBundles: BundleType[] = [...values?.event_dates?.meal_plans?.[mIndex]?.bundles];
    const prevBundleIds: number[] = [...values?.event_dates?.meal_plans?.[mIndex]?.bundle_ids];
    let bundlesToSet: BundleType[] = [];

    for (let i = 0; i < bundle_ids?.length; i++) {
      const id = bundle_ids[i];
      if (!prevBundleIds?.find((el) => el === id)) {
        const res: any = await GetBundleProductsList(id);
        if (res?.success) {
          const bundleData = res?.data?.[0];
          checkEmptyProducts(bundleData);
          const newBundle = {
            bundle_id: bundleData?.id,
            bundle_name: bundleData?.bundle_name ? bundleData?.bundle_name : '',
            hide_price: true,
            bundle_quantity: '1',
            bundle_rate: bundleData?.total_cost,
            bundle_subtotal: bundleData?.total_cost,
            bundle_SRP: '',
            special_instruction: '',
            products: bundleData?.bundle_products?.map((item: any) => ({
              product_id: item?.product_id,
              product_subcategory_id: item?.product_subcategory_id,
              product_name: item?.product?.product_name,
              product_type: item?.product?.product_type,
              special_instruction: '',
              portions: '',
              quantity: item?.quantity_per_product,
              rate: item?.product_cost,
              total_cost: item?.total_cost,
              product: { ...item?.product }
            }))
          };
          tempBundles = [...tempBundles, newBundle];
        }
      }
    }
    bundlesToSet = tempBundles?.filter((item: any) =>
      bundle_ids?.find((id: number) => id === item?.bundle_id)
    );
    const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
    tempValues.event_dates.meal_plans[mIndex].bundles = bundlesToSet;
    tempValues.event_dates.meal_plans[mIndex].bundle_ids = bundle_ids;
    setMealSubtotal(tempValues, mIndex);

    setFieldValue(`event_dates.meal_plans[${mIndex}].bundles`, bundlesToSet);
    setFieldValue(`event_dates.meal_plans[${mIndex}].bundle_ids`, bundle_ids);
    setBundlesLoading(false);
  };

  useEffect(() => {
    fetchAllAvailableBundlesList(setAvailableBundles, setLoading, setAddBundle);
    fetchAllAvailableProductTypesList(setAllProductTypes, setLoading);
    setLocalSelectedBundleIds(values?.event_dates?.meal_plans?.[mIndex]?.bundle_ids);
  }, []);

  useEffect(() => {
    if (allProductTypes?.length) {
      setAvailableProductTypes(
        allProductTypes?.filter((item: TypeForObjectOfProductTypes) => item?.text !== 'Archived')
      );
    }
  }, [allProductTypes]);

  const bundleDropDownChangeHandler = (e: any) => {
    setLocalSelectedBundleIds(e);
    changeBundleHandler(e);
  };

  if (loading) {
    return <CenterSpiner />;
  }

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} lg={7}>
          <Label text="Meal Name" isMandatory={false} />
          <Input
            id={`event_dates.meal_plans[${mIndex}].meal_name`}
            name={`event_dates.meal_plans[${mIndex}].meal_name`}
            placeholder={`Meal ${values?.event_dates?.meal_plans?.length || 0}`}
            error={
              touched?.event_dates?.meal_plans?.[mIndex]?.meal_name &&
              errors?.event_dates?.meal_plans?.[mIndex]?.meal_name
            }
            value={values?.event_dates?.meal_plans?.[mIndex]?.meal_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
        <Col xs={10} lg={7} md={11}>
          <Label text="Start Time" isMandatory={false} />
          <TimePickerComponent
            id={`event_dates.meal_plans[${mIndex}].start_time`}
            name={`event_dates.meal_plans[${mIndex}].start_time`}
            use12Hours={true}
            format="h:mm A"
            placeholder={'HH:MM'}
            value={
              values?.event_dates?.meal_plans?.[mIndex]?.start_time &&
              moment(values?.event_dates?.meal_plans?.[mIndex]?.start_time, 'hh:mm A').format(
                'HH:mm'
              )
            }
            onChange={(value: string) => {
              setFieldTouched(`event_dates.meal_plans[${mIndex}].start_time`, true);
              setFieldValue(`event_dates.meal_plans[${mIndex}].start_time`, value);
            }}
            onSelect={(e: Moment) => {
              setFieldValue(`event_dates.meal_plans[${mIndex}].start_time`, e.format('hh:mm A'));
            }}
            error={
              touched?.event_dates?.meal_plans?.[mIndex]?.start_time &&
              errors?.event_dates?.meal_plans?.[mIndex]?.start_time
            }
          />
        </Col>
        <Col xs={10} lg={7} md={11}>
          <Label text="End Time" isMandatory={false} />
          <TimePickerComponent
            id={`event_dates.meal_plans[${mIndex}].end_time`}
            name={`event_dates.meal_plans[${mIndex}].end_time`}
            use12Hours={true}
            format="h:mm A"
            placeholder={'HH:MM'}
            value={
              values?.event_dates?.meal_plans?.[mIndex]?.end_time &&
              moment(values?.event_dates?.meal_plans?.[mIndex]?.end_time, 'hh:mm A').format('HH:mm')
            }
            onChange={(value: string) => {
              setFieldTouched(`event_dates.meal_plans[${mIndex}].end_time`, true);
              setFieldValue(`event_dates.meal_plans[${mIndex}].end_time`, value);
            }}
            onSelect={(e: Moment) => {
              setFieldValue(`event_dates.meal_plans[${mIndex}].end_time`, e.format('hh:mm A'));
            }}
            error={
              touched?.event_dates?.meal_plans?.[mIndex]?.end_time &&
              errors?.event_dates?.meal_plans?.[mIndex]?.end_time
            }
          />
        </Col>
        <Col xs={4} lg={3} md={2}>
          <Button
            icon={<img src={MinusCircleBlack} alt="delete" />}
            variant="ghost"
            style={{ marginTop: '48px' }}
            onClick={() => {
              const tempMealData = JSON.parse(JSON.stringify(values?.event_dates?.meal_plans));
              tempMealData.splice(mIndex, 1);
              setFieldValue('event_dates.meal_plans', tempMealData);
            }}
          />
        </Col>
        <Col xs={24} xl={24}>
          <div
            style={{
              marginTop: '18px',
              marginBottom: '18px',
              background: '#E0E0E0',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} md={12} sm={12}>
          <Label text="Bundles" isMandatory={false} />
          <SelectInputWithCheckBox
            id={`event_dates.meal_plans[${mIndex}].bundle_ids`}
            name={`event_dates.meal_plans[${mIndex}].bundle_ids`}
            placeholdertitle="Select for Bundles"
            mode="multiple"
            options={getBundlesOptions(availableBundles)}
            value={localSelectedBundleIds}
            error={
              touched?.event_dates?.meal_plans?.[mIndex]?.bundle_ids &&
              errors?.event_dates?.meal_plans?.[mIndex]?.bundle_ids
            }
            onChange={(e: any) => {
              bundleDropDownChangeHandler(e);
            }}
          />
        </Col>
        <Col xs={24} md={12} sm={12} style={{ width: '100%', alignSelf: 'center' }}>
          <Row gutter={12}>
            <Col md={6} style={{ textAlign: 'end' }}>
              <ProductBadge key={'kosher-product'} color={'geekblue'} text={'Kosher Product'} />
            </Col>
            <Col md={6} style={{ textAlign: 'end' }}>
              <ProductBadge key={'custom-product'} color={'gold'} text={'Custom Product'} />
            </Col>
            <Col md={6} style={{ textAlign: 'end' }}>
              <ProductBadge key={'hotel-x-product'} color={'green'} text={'Hotel X Product'} />
            </Col>
            <Col md={6} style={{ textAlign: 'end' }}>
              <ProductBadge key={'regular-product'} color={'pink'} text={'Regular Product'} />
            </Col>
          </Row>
        </Col>
      </Row>
      {values?.event_dates?.meal_plans?.[mIndex]?.bundles?.map(
        (bundle: BundleType, bIndex: number) => {
          return (
            <React.Fragment key={`bundle-${bIndex}-${bundle.bundle_id}`}>
              <Spin spinning={bundlesLoading}>
                <MealBundles
                  formik={formik}
                  mealIndex={mIndex}
                  bundleIndex={bIndex}
                  duplicateError={duplicateError}
                  setDuplicateError={setDuplicateError}
                  availableProductTypes={availableProductTypes}
                  isBundleProductsEditable={isBundleProductsEditable}
                  setIsBundleProductsEditable={setIsBundleProductsEditable}
                  setMealSubtotal={setMealSubtotal}
                  setLocalSelectedBundleIds={setLocalSelectedBundleIds}
                />
              </Spin>
            </React.Fragment>
          );
        }
      )}

      <Row gutter={16}>
        <Col span={24}>
          <div
            style={{
              marginTop: '18px',
              marginBottom: '18px',
              background: '#E0E0E0',
              height: '1px'
            }}
          />
        </Col>
        <Col span={24}>
          <MealProducts
            formik={formik}
            mealIndex={mIndex}
            duplicateError={duplicateError}
            setDuplicateError={setDuplicateError}
            availableProductTypes={availableProductTypes}
            setMealSubtotal={setMealSubtotal}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col
          span={24}
          style={{
            textAlign: 'right',
            paddingLeft: '24px',
            paddingRight: '24px',
            marginTop: '10px'
          }}>
          <H2Typography style={{ fontWeight: 'normal' }}>
            SubTotal :{localMealSubtotal ? formatCurrency(Number(localMealSubtotal)) || '-' : 0}
          </H2Typography>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div
            style={{
              marginTop: '18px',
              marginBottom: '18px',
              background: '#E0E0E0',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <AddNewBundleModal
        isModalVisible={isAddBundle}
        isFromQuoteManagement={true}
        onOk={(newBundleId?: string | number) => {
          setAddBundle(false);
          fetchAllAvailableBundlesList(setAvailableBundles, setLoading, setAddBundle);
          if (newBundleId) {
            bundleDropDownChangeHandler(
              localSelectedBundleIds?.length
                ? [...localSelectedBundleIds, newBundleId]
                : [newBundleId]
            );
          }
        }}
        onCancel={() => setAddBundle(false)}
        dropdownData={{ bundlesList: availableBundles, products: productsList || [] }}
      />
    </>
  );
};

export default MealDataComponent;
