import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import Switch from '@atom/Switch';
import { TextAreaInput } from '@atom/TextArea';
import { CheckboxWrapper } from '@pages/QuoteManagementPage/style';
import { Checkbox, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { handleChangeValues } from './helper';
import { serviceOptionKeys, serviceOptions, SERVICES } from './helper';
import { ServiceFeeWrapper } from './style';
const ServiceFee = (props: any) => {
  const { formik } = props;
  const { touched, errors, values, setFieldValue, setValues, handleChange, handleBlur } = formik;
  const [serviceFeeCheckboxes, setServiceFeeCheckboxes] = useState<any>([]);

  const handleServiceFeeUpdate = (e: any) => {
    const updatedValues = { ...values };
    updatedValues.event_dates.fees.service_fees_percent = e.target.value;
    handleChangeValues(
      serviceFeeCheckboxes,
      e.target.value,
      serviceOptionKeys,
      setServiceFeeCheckboxes,
      updatedValues,
      setValues
    );
    handleChange(e);
  };

  const handleToggleUpdate = (e: boolean) => {
    setServiceFeeCheckboxes([]);
    const updatedValues = { ...values };
    updatedValues.event_dates.fees.service_fees_percent = '';
    updatedValues.event_dates.fees.service_food_fees_percent = '';
    updatedValues.event_dates.fees.service_alcohol_fees_percent = '';
    updatedValues.event_dates.fees.service_labour_fees_percent = '';
    updatedValues.event_dates.fees.service_net_total_fees_percent = '';
    setValues(updatedValues);
    setFieldValue(`event_dates.fees.is_customize_service_fees`, e ? 1 : 0);
  };

  const setCheckBoxes = () => {
    let services = [];
    if (Number(values?.event_dates?.fees?.service_net_total_fees_percent)) {
      services.push(SERVICES.NET_TOTAL);
    } else {
      if (Number(values?.event_dates?.fees?.service_food_fees_percent)) {
        services.push(SERVICES.FOOD);
      }
      if (Number(values?.event_dates?.fees?.service_alcohol_fees_percent)) {
        services.push(SERVICES.ALCOHOL_AND_SOFT_BAR);
      }
      if (Number(values?.event_dates?.fees?.service_labour_fees_percent)) {
        services.push(SERVICES.LABOUR);
      }
    }
    setServiceFeeCheckboxes(services);
  };

  useEffect(() => {
    setCheckBoxes();
  }, []);

  return (
    <>
      <Row gutter={16}>
        <ServiceFeeWrapper style={{ display: 'contents' }}>
          {!values?.event_dates?.fees?.is_customize_service_fees ? (
            <>
              <Col md={4} xs={24}>
                <Label text="Service Fee" isMandatory={false} />
                <Input
                  id={`event_dates.fees.service_fees_percent`}
                  name={`event_dates.fees.service_fees_percent`}
                  placeholder="%"
                  error={
                    touched?.event_dates?.fees?.service_fees_percent &&
                    errors?.event_dates?.fees?.service_fees_percent
                  }
                  value={values?.event_dates?.fees?.service_fees_percent}
                  onChange={handleServiceFeeUpdate}
                  onBlur={handleBlur}
                />
              </Col>

              <Col className="service-fee-style" lg={8} md={10} xs={24}>
                <Row gutter={16}>
                  <Col md={18} xs={24}>
                    <CheckboxWrapper>
                      <Checkbox.Group
                        name={`event_dates.fees.service_fee_checkboxes`}
                        defaultValue={values.event_dates.fees?.service_fee_checkboxes}
                        value={serviceFeeCheckboxes}
                        options={serviceOptions}
                        onChange={(e: any) =>
                          handleChangeValues(
                            e,
                            values.event_dates.fees.service_fees_percent,
                            serviceOptionKeys,
                            setServiceFeeCheckboxes,
                            values,
                            setValues
                          )
                        }
                      />
                    </CheckboxWrapper>
                  </Col>
                  <Col lg={6} md={12} xs={24}>
                    <CheckboxWrapper>
                      <Checkbox
                        id={`event_dates.fees.service_fee_net_total`}
                        name={`event_dates.fees.service_fee_net_total`}
                        onChange={(e) =>
                          handleChangeValues(
                            e?.target?.checked ? e?.target.value : [],
                            values.event_dates.fees.service_fees_percent,
                            serviceOptionKeys,
                            setServiceFeeCheckboxes,
                            values,
                            setValues
                          )
                        }
                        value={SERVICES.NET_TOTAL}
                        checked={
                          serviceFeeCheckboxes && serviceFeeCheckboxes?.includes(SERVICES.NET_TOTAL)
                        }>
                        Net Total
                      </Checkbox>
                    </CheckboxWrapper>
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <Col md={8} xs={24} lg={4}>
                <Label text="Food" />
                <Input
                  id={`event_dates.fees.service_food_fees_percent`}
                  name={`event_dates.fees.service_food_fees_percent`}
                  placeholder="%"
                  error={
                    touched?.event_dates?.fees?.service_food_fees_percent &&
                    errors?.event_dates?.fees?.service_food_fees_percent
                  }
                  value={values?.event_dates?.fees?.service_food_fees_percent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col md={8} xs={24} lg={4}>
                <Label text="Alcohol & Soft Bar" />
                <Input
                  id={`event_dates.fees.service_alcohol_fees_percent`}
                  name={`event_dates.fees.service_alcohol_fees_percent`}
                  placeholder="%"
                  error={
                    touched?.event_dates?.fees?.service_alcohol_fees_percent &&
                    errors?.event_dates?.fees?.service_alcohol_fees_percent
                  }
                  value={values?.event_dates?.fees?.service_alcohol_fees_percent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col md={8} xs={24} lg={4}>
                <Label text="Labour" />
                <Input
                  id={`event_dates.fees.service_labour_fees_percent`}
                  name={`event_dates.fees.service_labour_fees_percent`}
                  placeholder="%"
                  error={
                    touched?.event_dates?.fees?.service_labour_fees_percent &&
                    errors?.event_dates?.fees?.service_labour_fees_percent
                  }
                  value={values?.event_dates?.fees?.service_labour_fees_percent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </>
          )}
          <Col md={8} sm={24} className="service-fee-style">
            <Flex gap={12}>
              <Switch
                id={`event_dates.fees.is_customize_service_fees`}
                checked={values?.event_dates?.fees?.is_customize_service_fees}
                defaultChecked={values?.event_dates?.fees?.is_customize_service_fees}
                onChange={handleToggleUpdate}
              />
              <span style={{ marginLeft: '6px', marginBottom: '20px' }}>
                Customize % Per Category
              </span>
            </Flex>
          </Col>
          <Col span={24}>
            <Label text="Service Fee Instructions" isMandatory={false} />
            <Input
              max={200}
              id={`event_dates.fees.service_fees_instructions`}
              name={`event_dates.fees.service_fees_instructions`}
              value={values?.event_dates?.fees?.service_fees_instructions}
              onChange={handleChange}
            />
          </Col>
        </ServiceFeeWrapper>
      </Row>
    </>
  );
};

export default ServiceFee;
