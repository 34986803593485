import * as yup from 'yup';

import { getProductTypes, getProducts } from '@pages/MenuManagementPage/helper';
import { showErrorToast, updateRawData } from '@utils/index';

import { FILE_TYPES } from '@constants/userConstants';
import { H4Typography } from '@constant/typography/Typography';
import { Input } from '@atom/Input';
import { Label } from '@atom/FormLable';
import { TotalCostLabel } from './style';
import { getMappedBundleData } from '../AddEditBundle/helper';
import services from '@services/index';
import { urlRegExp } from '@constants/index';
import { formatCurrency } from '@utils/format';

export const newIngredient = {
  name: undefined,
  amount: undefined,
  unit: undefined,
  notes: undefined
};

export const getInitValues = (values?: any, isQuote?: boolean) => {
  let initialData: any = {
    status: values?.status || undefined,
    menus: values?.menus || undefined,
    product_name: values?.product_name || undefined,
    product_type: values?.product_type || 4,
    product_type_name: values?.product_type_name || 'Regular',
    // prep_menu: values?.prep_menu || undefined,
    // prep_user: values?.prep_user || undefined,
    category: values?.category || undefined,
    sub_categories: values?.sub_categories || undefined,
    sub_category_array: values?.sub_category_array || undefined,
    ingredients: values?.ingredients || undefined,
    is_subcategory_ingredient_exist: values?.is_subcategory_ingredient_exist || undefined,
    product_ingredient_list: values?.product_ingredient_list || undefined,
    // special_ingredients: values?.special_ingredients || undefined,
    // sort_ingredients: undefined,
    dietary_restrictions: values?.dietary_restrictions || undefined,
    tutorial_link: values?.tutorial_link || undefined,
    price_descriptions: values?.price_descriptions || undefined,
    images: values?.price_descriptions || undefined
  };
  if (!isQuote) {
    initialData.bundles =
      values?.selected_bundles?.map((i: any) => ({
        bundle_id: i?.id,
        quantity_per_product: i?.quantity_per_product,
        product_cost: i?.product_cost,
        bundle_cost: i?.bundle_cost
      })) || undefined;
  } else {
    initialData.is_one_time_product = 0;
    initialData.product_id = undefined;
  }
  return initialData;
};

export const addEditProductValidations = (isQuote?: boolean) => {
  const validationObject: any = {
    menus: yup
      .array()
      .min(1, 'Please select atleast one menu.')
      .required('Please select atleast one menu.')
      .nullable(),
    product_name: yup
      .string()
      .required('Product name is required.')
      .required('Product name is required.')
      .nullable(),
    product_type: yup.mixed().required('Please select product type.').nullable(),
    category: yup.mixed().required('Please select atleast one category.').nullable(),
    // prep_menu: yup.number().required('Please select a prep menu.'),
    // prep_user: yup.number().required('Please select a prep user.'),
    sub_categories: yup
      .array()
      .of(yup.number().nullable().required('Please select a sub-category'))
      .typeError('Please select a sub-category')
      .min(1, 'Please select atleast one sub-category.')
      .required('Please select atleast one sub-category.')
      .nullable(),
    ingredients: yup.array().nullable(),
    // ingredients: yup.array().of(
    //   yup.object().shape({
    //     name: yup.string().required('Please enter ingredient name.'),
    //     amount: yup.number().required('Please enter ingredient amount.'),
    //     unit: yup.mixed().required('Please select a unit of measurement.'),
    //     assignee: yup.mixed().nullable(),
    //     notes: yup.string().nullable()
    //   })
    // ),
    // special_ingredients: yup.array().of(
    //   yup.object().shape({
    //     name: yup.string().required('Please enter special ingredient name.'),
    //     amount: yup.number().required('Please enter special ingredient amount.'),
    //     unit: yup.mixed().required('Please select a unit of measurement.'),
    //     assignee: yup.mixed().nullable(),
    //     notes: yup.string().nullable()
    //   })
    // ),
    // sort_ingredients: [],
    dietary_restrictions: yup.mixed().nullable(),
    tutorial_link: yup.string().matches(urlRegExp, 'Invalid URL format').nullable(),
    price_descriptions: yup.array().of(
      yup.object().shape({
        sub_category_id: yup.number(),
        price: yup.number().required('Please enter price.'),
        description: yup.string().nullable()
      })
    ),
    images: yup.mixed().nullable()
  };
  if (!isQuote) {
    validationObject.bundles = yup
      .array()
      .of(
        yup.object().shape({
          bundle_id: yup.number().nullable(),
          quantity_per_product: yup.number().required('Please enter quantity per product.'),
          product_cost: yup.number().required('Please enter product cost.'),
          bundle_cost: yup.number().required('Please enter bundle cost.')
        })
      )
      .nullable();
  } else {
    validationObject.is_one_time_product = yup.mixed().nullable();
    validationObject.product_id = yup.mixed().nullable();
  }
  return yup.object().shape(validationObject);
};

export const addNewBundleValidations = yup.object().shape({
  bundle_name: yup.string().required('Bundle name is required'),
  products: yup.array().of(
    yup.object().shape({
      product_cost: yup.mixed().required('Rate is required.'),
      product_id: yup.mixed().required('Product is required.'),
      product_type: yup.mixed().required('Product type is required.'),
      product_subcategory_id: yup.mixed().required('Product is required.'),
      quantity_per_product: yup.string().required('Quantity is required.'),
      total_cost: yup.number()
    })
  )
});

export const newBundleObject = {
  id: new Date().getTime(),
  product_cost: undefined,
  product_id: undefined,
  product_type: 4,
  product_subcategory_id: undefined,
  quantity_per_product: undefined
};

export const addNewBundleInitialValues = {
  bundle_name: undefined,
  sort_ingredients: [{ ...newBundleObject }]
};

export const bundleProductsColumns = [
  {
    title: <Label isMandatory={false} text="Product Type" />,
    dataIndex: 'product_type',
    width: '21%',
    render: (data: any) => {
      return data ? data : '-';
    }
  },
  {
    title: <Label isMandatory={false} text="Product" />,
    dataIndex: 'product_name',
    width: '21%',
    render: (data: any) => {
      return data ? data : '-';
    }
  },
  {
    title: <Label isMandatory={true} text="Quantity Per Product" />,
    dataIndex: 'quantity',
    width: '20%',
    render: (data: any) => {
      return data ? data : '-';
    }
  },
  {
    title: <Label isMandatory={true} text="Product Cost" />,
    dataIndex: 'cost',
    width: '20%',
    render: (data: any) => {
      return data ? data : '-';
    }
  },
  {
    title: '',
    dataIndex: 'total',
    width: '18%',
    render: (data: any) => {
      return data ? data : '';
    }
  }
];

export const getMappedTableData = (
  data: any,
  values: any,
  bundleTotalCostCalculateHandler: (
    quantity_per_product: string | number,
    product_cost: string | number
  ) => void,
  handleUpdate: any,
  handleUpdateBlur: any,
  touched: any,
  errors: any,
  item: any,
  index: number,
  totalCost: number | undefined,
  id: number | string | undefined
) => {
  const mappedData = data
    ?.filter((i: any) => (!!id ? id != i?.product?.id : true))
    ?.map((i: any) => ({
      product_type: i?.product?.product_type_details?.product_type || '-',
      product_name: i?.product?.product_name || '-',
      quantity: i?.quantity_per_product || '-',
      cost: formatCurrency(i?.product_cost) || '-'
    }));

  mappedData.push({
    product_type: (
      <H4Typography style={{ fontWeight: '400' }}>{values?.product_type_name || ''}</H4Typography>
    ),
    product_name: (
      <H4Typography style={{ fontWeight: '400' }}>{values?.product_name || ''}</H4Typography>
    ),
    quantity: (
      <Input
        style={{ marginTop: '12px' }}
        name={`bundles[${index}]?.quantity_per_product`}
        id={`bundles[${index}]?.quantity_per_product`}
        error={
          touched['bundles']?.[index]?.quantity_per_product &&
          errors['bundles']?.[index]?.quantity_per_product
        }
        placeholder="Quantity"
        onChange={(e: any) => {
          handleUpdate(e.target.value, 'quantity_per_product');
          bundleTotalCostCalculateHandler(
            e?.target?.value,
            values?.['bundles']?.[index]?.product_cost
          );
        }}
        value={item?.quantity_per_product}
        onBlur={() => handleUpdateBlur('quantity_per_product')}
      />
    ),
    cost: (
      <Input
        style={{ marginTop: '12px' }}
        name={`bundles[${index}]?.product_cost`}
        id={`bundles[${index}]?.product_cost`}
        error={
          touched['bundles']?.[index]?.product_cost && errors['bundles']?.[index]?.product_cost
        }
        placeholder="Product Cost"
        onChange={(e: any) => {
          handleUpdate(e.target.value, 'product_cost');
          bundleTotalCostCalculateHandler(
            values?.['bundles']?.[index]?.quantity_per_product,
            e?.target?.value
          );
        }}
        value={item?.product_cost}
        onBlur={() => handleUpdateBlur('product_cost')}
      />
    ),
    total: (
      <TotalCostLabel style={{ height: '48px' }}>
        {formatCurrency(totalCost!) || 'Total Bundle Cost'}
      </TotalCostLabel>
    )
  });

  return mappedData;
};

export const getMenuAndProductSecDropdownData = () => {
  return Promise.all([getMenus(), getProductTypes(), getCategories()]).then(
    (values: any) => values
  );
};

export const getProductListDropdownData = () => {
  return Promise.all([getProductTypes(), getCategories(), getSubCategories()]).then(
    (values: any) => values
  );
};

const getMenus = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/settings/menu-management/menu')) as { data: any };
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};
const getCategories = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/settings/menu-management/categories')) as { data: any };
      updateRawData({ categories: res.data?.data || [] });
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

const getSubCategories = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/settings/menu-management/categories/sub-categories')) as {
        data: any;
      };
      updateRawData({ subCategories: res?.data?.data });
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

export const getIngredientsSecDropdownData = () => {
  return Promise.all([getIngredients(), getDietaryRest()]).then((values: any) => values);
};

const getIngredients = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/settings/get-ingredients')) as { data: any };
      updateRawData({ ingredients: res.data?.data || [] });
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

const getDietaryRest = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/settings/get-dietary-restrictions')) as { data: any };
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

export const getBundlesSecDropdownData = () => {
  return Promise.all([getBundles(), getProducts()]).then((values: any) => values);
};

const getBundles = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get('/settings/get-bundles')) as { data: any };
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

const getMappedProductData = (data: any, ingredients: any, isQuote?: boolean) => {
  // ingredients: data?.ingredients?.map((i: any) => i?.id),
  // sort_ingredients: data?.special_ingredients?.map((i: any) => ({ id: i?.id, name: i?.name })),
  return {
    status: data?.status,
    menus: data?.menus,
    product_name: data?.product_name,
    product_type: data?.product_type,
    category: data?.category,
    sub_categories: data?.sub_categories,
    sub_category_array: data?.sub_category_array,
    ingredients: data?.ingredients,
    sort_ingredients: ingredients
      ?.filter((i: any) => data?.ingredients?.includes(i?.id))
      ?.map((i: any) => {
        return typeof i?.id === 'string' ? { id: null, name: i?.name } : i;
      }),
    dietary_restrictions: data?.dietary_restrictions,
    tutorial_link: data?.tutorial_link,
    price_descriptions: data?.price_descriptions?.map((i: any) => ({
      sub_category_id: i?.sub_category_id || i?.id,
      price: i?.price,
      description: i?.description || ''
    })),
    is_one_time_product: data?.is_one_time_product,
    images: data?.images
      ?.filter((i: any) => i?.image)
      ?.map((i: any) => ({
        image: i?.image,
        sub_category_id: i?.id
      })),
    bundle: isQuote ? undefined : data?.bundles?.map((i: any) => i?.bundle_id),
    bundles: isQuote ? undefined : data?.bundles
  };
};

export const addEditProduct = async (ingredients: any, data: any, id?: any, isQuote?: boolean) => {
  try {
    const res = (await services.post(`/product/${id ? 'update' : 'store'}`, {
      ...getMappedProductData(data, ingredients, isQuote),
      id
    })) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const handleUpdateSubCategory = (
  e: any,
  dropdownData: any,
  values: any,
  setValues: (val: any) => void
) => {
  const subCat = dropdownData?.catgoryList
    ?.find((i: any) => i?.id === values?.category)
    ?.sub_categories?.find((i: any) => i?.id === e?.dataId);
  const tempImg = values?.images ? [...values?.images] : [];
  const tempPD = values?.price_descriptions ? [...values?.price_descriptions] : [];
  const newImg = e?.isAdded
    ? [...tempImg, subCat]
    : tempImg?.filter((i: any) => i?.id !== e?.dataId);
  const newPD = e?.isAdded
    ? [...tempPD, { sub_category_id: subCat?.id, name: subCat?.name, price: undefined }]
    : tempPD?.filter((i: any) =>
        i?.sub_category_id ? i?.sub_category_id !== e?.dataId : i?.id !== e?.dataId
      );
  setValues({
    ...values,
    price_descriptions: newPD?.length ? newPD : undefined,
    images: newImg?.length ? newImg : undefined,
    sub_categories: e?.updatedList?.length ? e?.updatedList : undefined
  });
};

const getProductSubCategories = (product_id: string, master_category_id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = (await services.get(
        `/product/subcategories/${product_id}/${master_category_id}`
      )) as { data: any };
      resolve(res.data);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

export const handleUpdateCategory = async (
  product_id: string,
  id: any,
  values: any,
  setValues: any
) => {
  let resp: any;
  if (product_id) {
    resp = await getProductSubCategories(product_id, id);
  }

  setValues({
    ...values,
    category: id,
    sub_categories: product_id ? (!resp ? undefined : resp.data) : undefined,
    sub_category_array: product_id
      ? !resp
        ? undefined
        : resp.data.map((x: number) => ({ id: x }))
      : undefined,
    images: undefined,
    price_descriptions: undefined
  });
};

export const handleSelectBundle = (e: any, dropdownData: any, values: any, setFieldValue: any) => {
  const data: any = e?.map((i: any) => {
    const currentBundle = dropdownData?.bundlesList?.find((j: any) => i === j?.id);
    const prevAddedBundle = values?.bundles?.find((j: any) => i === j?.bundle_id);
    const newBundle = {
      bundle_id: currentBundle?.id,
      quantity_per_product: prevAddedBundle?.quantity_per_product || undefined,
      product_cost: prevAddedBundle?.product_cost || undefined,
      bundle_cost: prevAddedBundle?.bundle_cost || currentBundle?.total_cost
    };
    return newBundle;
  });
  setFieldValue('bundles', [...data]);
};

export const uploadFileHandler = async (file: File, type: number): Promise<ResponseType> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = (await services.post(`/file-upload?type=${type}`, formData, undefined, null, {
      'Content-Type': 'multipart/form-data'
    })) as any;
    return { data: res?.data?.data, error: null } as any;
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    } as any;
  }
};

export const handleUpload = async (
  data: any,
  index: number,
  values: any,
  setFieldValue: any,
  setIsUploading: any
) => {
  setIsUploading(true);
  const tempObj = [...values?.images];
  tempObj[index].image_url = URL.createObjectURL(data);
  setFieldValue('images', tempObj);
  const res = (await uploadFileHandler(data, FILE_TYPES.PRODUCTS)) as any;
  if (res?.error) {
    tempObj[index].image_url = undefined;
    setFieldValue('images', tempObj);
    setIsUploading(false);
    return showErrorToast({
      message: `Unable to upload profile picture`,
      description: res.error || 'Please try again'
    });
  } else {
    tempObj[index].image = res?.data?.url;
    setFieldValue('images', tempObj);
    setIsUploading(false);
  }
};

export const getBundlesOptions = (list: any) => {
  return list?.map((i: any) => {
    i.title = i.bundle_name;
    i.key = i.id;
    i.value = i.id;
    i.checkable = !(i.id === -1);
    i.disabled = i.status === 0;
    return i;
  });
};

export const addBundle = async (data: any) => {
  try {
    const res = (await services.post(`/bundle/store`, getMappedBundleData(data))) as {
      data: any;
    };
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const handleKeyPress = (
  e: any,
  listName: string,
  valueKeyName: string,
  formikKeyName: string,
  setDropdownData: any,
  setFieldValue: any,
  dropdownData: any,
  values: any
) => {
  if (e?.key === 'Enter' && e.target.value) {
    const tempArr = [...dropdownData?.[listName]?.map((i: any) => i?.id)];
    const alreadyExist = !!tempArr?.find((i: any) => i?.[valueKeyName]?.includes(e.target.value));

    if (!alreadyExist) {
      const newList = [
        ...dropdownData?.[listName],
        { id: e.target.value, [valueKeyName]: e.target.value }
      ];
      setDropdownData({
        ...dropdownData,
        [listName]: newList
      });
      if (formikKeyName === 'ingredients') {
        updateRawData({ [formikKeyName]: newList || [] });
      }
      setFieldValue(
        formikKeyName,
        values?.[formikKeyName] ? [...values?.[formikKeyName], e.target.value] : [e.target.value]
      );
      setTimeout(() => document.getElementById(formikKeyName)?.blur(), 50);
    }
  }
};
