import { getPrepMenu } from '@services/foodPrepServices';
import FoodPreprationListTable from './FoodPreprationListTable';
import PrepListTable from './PrepListTable';

export enum FOOD_PREPRATION_LIST_TABS {
  ALL_PRODUCTS = 'all_Products',
  APPETIZER = 'appetizer',
  CRISPY = 'crispy',
  MEAT = 'meat',
  SAUCE = 'sauce',
  VEGETABLES = 'vegetables'
}

export const getFoodPreprationListTabs = (
  activePanel: string,
  startDate: string,
  endDate: string,
  callApi: boolean,
  setCallApi: React.Dispatch<React.SetStateAction<boolean>>,
  prepMenuTabs: any[]
) => {
  const tabValues = [
    {
      tab: 'ALL PRODUCTS',
      content: (
        <FoodPreprationListTable
          currentPanel={FOOD_PREPRATION_LIST_TABS.ALL_PRODUCTS}
          activePanel={activePanel}
          startDate={startDate}
          endDate={endDate}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      ),
      key: FOOD_PREPRATION_LIST_TABS.ALL_PRODUCTS
    },
    ...prepMenuTabs?.map((prepMenu) => {
      return {
        tab: prepMenu?.name?.toUpperCase(),
        content: (
          <PrepListTable
            key={prepMenu?.id}
            currentPanel={prepMenu?.name}
            activePanel={activePanel}
            startDate={startDate}
            endDate={endDate}
            prepListId={prepMenu?.id}
            callApi={callApi}
            setCallApi={setCallApi}
          />
        ),
        key: prepMenu?.name
      };
    })
  ];
  return tabValues;
};
