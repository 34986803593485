import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { SearchBar } from '@atom/Search';
import SelectInput from '@atom/Select';
import StatusLabel from '@atom/StatusLabel';
import services from '@services/index';
import { formatCurrency } from '@utils/format';
import { getSelectOptions } from '@utils/helper';

import { MENU_MANAGEMENT_TABS, PRODUCT_STATUS } from '../helper';
import { PageWrapper } from '../style';
import MenuManagementTable from './MenuManagementTable';

export const getMenuManagementTabs = (activePanel: string) => {
  const menuManagementTabs = [
    {
      tab: 'ALL',
      content: (
        <MenuManagementTable activePanel={activePanel} currentPanel={MENU_MANAGEMENT_TABS.ALL} />
      ),
      key: MENU_MANAGEMENT_TABS.ALL
    },
    {
      tab: 'ACTIVE',
      content: (
        <MenuManagementTable activePanel={activePanel} currentPanel={MENU_MANAGEMENT_TABS.ACTIVE} />
      ),
      key: MENU_MANAGEMENT_TABS.ACTIVE
    },
    {
      tab: 'INACTIVE',
      content: (
        <MenuManagementTable
          activePanel={activePanel}
          currentPanel={MENU_MANAGEMENT_TABS.INACTIVE}
        />
      ),
      key: MENU_MANAGEMENT_TABS.INACTIVE
    },
    {
      tab: 'DRAFTS',
      content: (
        <MenuManagementTable activePanel={activePanel} currentPanel={MENU_MANAGEMENT_TABS.DRAFTS} />
      ),
      key: MENU_MANAGEMENT_TABS.DRAFTS
    },
    {
      tab: 'BUNDLES',
      content: (
        <MenuManagementTable
          activePanel={activePanel}
          currentPanel={MENU_MANAGEMENT_TABS.BUNDLES}
        />
      ),
      key: MENU_MANAGEMENT_TABS.BUNDLES
    }
  ];

  return menuManagementTabs;
};

const bundleSortOptions = {
  noOfProducts: [
    {
      id: 1,
      name: '1-5'
    },
    {
      id: 2,
      name: '5-10'
    },
    {
      id: 3,
      name: '10+'
    }
  ],
  bundlePrice: [
    {
      id: 'asc',
      name: 'Low-High'
    },
    {
      id: 'desc',
      name: 'High-Low'
    }
  ]
};

const handleUpdateFilter = (
  filters: any,
  setFilters: (filters: any) => void,
  key: string,
  value: any,
  fetchData: (filters: any) => void
) => {
  const newFilters = { ...filters, page: 1, [`${key}`]: value };
  setFilters(newFilters);
  fetchData(newFilters);
};

export const getMenuManagementTableColumns = (
  activePanel: string,
  filters: any,
  setFilters: (filters: any) => void,
  dropdownData: any,
  updateTableData: (filters: any) => void,
  searchFilter: (filters: any) => void
) => {
  const productColumns = [
    {
      title: (
        <Flex direction="column" style={{ width: '200px' }} gap={15}>
          Product Name
          <SearchBar
            id={`name`}
            placeholder="Product"
            onChange={(e: any) =>
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'name',
                e.target.value,
                searchFilter
              )
            }
            value={filters?.name}
          />
        </Flex>
      ),
      dataIndex: 'product_name',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '200px' }} gap={15}>
          Menu Name
          <SearchBar
            id={`menu_name`}
            placeholder="Menu"
            onChange={(e: any) =>
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'menu_name',
                e.target.value,
                searchFilter
              )
            }
            value={filters?.menu_name}
          />
        </Flex>
      ),
      dataIndex: 'menu_name',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '200px' }} gap={15}>
          <Flex justifyContent="space-between">
            Master Category
            {filters?.category ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px' }}
                onClick={() =>
                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'category',
                    undefined,
                    updateTableData
                  )
                }
              />
            ) : null}
          </Flex>
          <PageWrapper>
            <SelectInput
              id={`category`}
              name={`category`}
              placeholdertitle="Category"
              value={filters?.category}
              onChange={(e: any) =>
                handleUpdateFilter(
                  { ...filters, type: activePanel, sub_category: null },
                  setFilters,
                  'category',
                  e,
                  updateTableData
                )
              }>
              {getSelectOptions(dropdownData?.catgoryList, 'name')}
            </SelectInput>
          </PageWrapper>
        </Flex>
      ),
      dataIndex: 'master_category',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '200px' }} gap={15}>
          <Flex justifyContent="space-between">
            Sub category
            {filters?.sub_category ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px' }}
                onClick={() =>
                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'sub_category',
                    undefined,
                    updateTableData
                  )
                }
              />
            ) : null}
          </Flex>
          <PageWrapper>
            <SelectInput
              id={`sub_category`}
              name={`sub_category`}
              placeholdertitle="Sub Category"
              value={filters?.sub_category}
              onChange={(e: any) =>
                handleUpdateFilter(
                  { ...filters, type: activePanel },
                  setFilters,
                  'sub_category',
                  e,
                  updateTableData
                )
              }>
              {getSelectOptions(dropdownData?.subCatgoryList, 'name')}
            </SelectInput>
          </PageWrapper>
        </Flex>
      ),
      dataIndex: 'sub_category',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '240px' }} gap={15}>
          <Flex justifyContent="space-between">
            Product Type
            {filters?.product_type ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px' }}
                onClick={() =>
                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'product_type',
                    undefined,
                    updateTableData
                  )
                }
              />
            ) : null}
          </Flex>
          <PageWrapper>
            <SelectInput
              id={`product_type`}
              name={`product_type`}
              placeholdertitle="Product Type"
              value={filters?.product_type}
              onChange={(e: any) =>
                handleUpdateFilter(
                  { ...filters, type: activePanel },
                  setFilters,
                  'product_type',
                  e,
                  updateTableData
                )
              }>
              {getSelectOptions(dropdownData?.productTypeList, 'text')}
            </SelectInput>
          </PageWrapper>
        </Flex>
      ),
      dataIndex: 'product_type',
      width: '20%',
      render: (data: any, row: any) => {
        return data ? (
          <Flex
            className="Td-fixing"
            style={{ width: '100%' }}
            justifyContent="space-between"
            alignItems="center">
            <span>{data}</span>
            <StatusLabel status={row?.status} isUppercase={true} />
          </Flex>
        ) : (
          '-'
        );
      }
    }
  ];
  const bundlesColumns = [
    {
      title: (
        <Flex direction="column" style={{ width: '300px' }} gap={15}>
          Bundle Name
          <SearchBar
            id={`bundle_name`}
            placeholder="Product"
            onChange={(e: any) =>
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'bundle_name',
                e.target.value,
                searchFilter
              )
            }
            value={filters?.bundle_name}
          />
        </Flex>
      ),
      dataIndex: 'bundle_name',
      width: '50%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '200px' }} gap={15}>
          <Flex justifyContent="space-between">
            Number Of Products
            {filters?.number_of_product ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px' }}
                onClick={() =>
                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'number_of_product',
                    undefined,
                    updateTableData
                  )
                }
              />
            ) : null}
          </Flex>

          <SelectInput
            id={`number_of_product`}
            name={`number_of_product`}
            placeholdertitle="SORT: # Of Product"
            value={filters?.number_of_product}
            onChange={(e: any) =>
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'number_of_product',
                e,
                updateTableData
              )
            }>
            {getSelectOptions(bundleSortOptions?.noOfProducts, 'name')}
          </SelectInput>
        </Flex>
      ),
      dataIndex: 'no_of_products',
      width: '30%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '170px' }} gap={15}>
          <Flex justifyContent="space-between">
            Bundle Price
            {filters?.bundle_price ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px' }}
                onClick={() =>
                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'bundle_price',
                    undefined,
                    updateTableData
                  )
                }
              />
            ) : null}
          </Flex>
          <SelectInput
            id={`bundle_price`}
            name={`bundle_price`}
            placeholdertitle="SORT: Select One"
            value={filters?.bundle_price}
            onChange={(e: any) =>
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'bundle_price',
                e,
                updateTableData
              )
            }>
            {getSelectOptions(bundleSortOptions?.bundlePrice, 'name')}
          </SelectInput>
        </Flex>
      ),
      dataIndex: 'bundle_price',
      width: '20%',
      render: (data: any, row: any) => {
        return data ? (
          <Flex
            className="Td-fixing"
            style={{ width: '100%' }}
            justifyContent="space-between"
            alignItems="center">
            <span>{formatCurrency(data)}</span>
            {row?.status === PRODUCT_STATUS.INACTIVE ? (
              <StatusLabel status={row?.status} isUppercase={true} />
            ) : null}
          </Flex>
        ) : (
          '-'
        );
      }
    }
  ];
  return activePanel === MENU_MANAGEMENT_TABS.BUNDLES ? bundlesColumns : productColumns;
};

export const mapTableData = (data: Array<any>, activePanel: string) => {
  const mappedData =
    data?.map((item: any) =>
      activePanel !== MENU_MANAGEMENT_TABS.BUNDLES
        ? {
            id: item?.id || '-',
            product_name: item?.product_name || '-',
            menu_name: item?.menus?.map((i: any) => i?.master_menu?.name)?.join(' ') || '-',
            master_category: item?.category?.name || '-',
            sub_category:
              item?.sub_categories?.map((i: any) => `#${i?.category?.name}`)?.join(' ') || '-',
            product_type: item?.product_type_details?.product_type || '-',
            status: item?.status
          }
        : {
            id: item?.id || '-',
            bundle_name: item?.bundle_name || '-',
            no_of_products: item?.bundle_products_count || '-',
            bundle_price: item?.total_cost || '-',
            status: item?.status
          }
    ) || [];
  return mappedData;
};

export const initialFilters = {
  name: undefined,
  menu_name: undefined,
  category: undefined,
  sub_category: undefined,
  product_type: undefined,
  type: 'all',
  page: 1,
  bundle_name: undefined,
  number_of_product: undefined,
  bundle_price: undefined
};

export const getList = async (filters: any) => {
  const url =
    filters?.type === MENU_MANAGEMENT_TABS.BUNDLES ? '/bundle/list?page=' : '/product/list?page=';
  try {
    const res = (await services.post(`${url}${filters?.page || 1}`, {
      search_data: filters
    })) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
