import { Col, Divider, Row, Spin } from 'antd';
import { IAdditionalContacts, IVenueDataType, IVenueDetail } from '../types';
import React, { useEffect, useState } from 'react';
import { VENUE_CREATE_STATUS, addEditVenue } from './helper';
import { VENUE_DETAILS_TABS, VenueAddEditDummyData, getVenue } from '../helper';
import { addEditValidations, getInitValues, manualValidate } from './helper';
import { showErrorToast, showToast } from '@utils/index';
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router';

import AddEditDocuments from './AddEditDocuments';
import AddEditInfo from './AddEditInfo';
import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import EditIcon from '@assets/edit-2.svg';
import { Flex } from '@atom/Flex';
import HorizontalTab from '@molecules/HorizontalTabs';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { VENUE_MANAGEMENT_TABS } from '../ListViewPages/helper';
import { getUniqueDomId } from '@fullcalendar/react';

function AddEditPages() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tabType, setTabType] = useState(VENUE_DETAILS_TABS.INFO);
  const [initialValues, setInitialValues] = useState<IVenueDataType>({});
  const [isVenueLoading, setIsVenueLoading] = useState(true);
  const [venueData, setVenueData] = useState<IVenueDataType>({});
  const [isDraftLoading, setIsDraftLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const location = useLocation();
  const isEdit = location.pathname?.split('/')?.pop() === 'edit';

  useEffect(() => {
    updateInitialValues();
  }, [id]);

  const updateInitialValues = async () => {
    setIsVenueLoading(true);
    if (id) {
      try {
        const res = await getVenue(id);
        if (res?.success) {
          const documentsList = res?.data?.documents?.map((doc: any) => {
            doc.uid = getUniqueDomId();
            doc.name = doc.document_name;
            return doc;
          });
          let additionalContacts = [];
          if (res?.data?.additional_contacts?.length > 0) {
            additionalContacts = res?.data?.additional_contacts?.map(
              (contact: IAdditionalContacts) => {
                return {
                  ...contact,
                  country_code:
                    !!contact?.country_code && !contact?.country_code?.includes('+')
                      ? `+${contact?.country_code}`
                      : contact?.country_code
                };
              }
            );
          }
          setVenueData(
            { ...res?.data, documents: documentsList, additional_contacts: additionalContacts } ??
              {}
          );
        }
      } catch (error) {}
    }
    const initVal: IVenueDataType = getInitValues(id ? venueData : {});
    setInitialValues(initVal);
    setIsVenueLoading(false);
  };

  const onSubmit = async () => {
    setIsCreateLoading(true);
    try {
      if (!id) delete formik.values?.status;
      const status =
        formik.values?.status === VENUE_CREATE_STATUS.DRAFT
          ? VENUE_CREATE_STATUS.ACTIVE
          : formik.values?.status;
      const additionalContacts = formik.values?.additional_contacts?.map((contact) => {
        delete contact?.localId;
        return {
          ...contact,
          venue_id: Number(id),
          country_code:
            !!contact?.country_code && !contact?.country_code?.includes('+')
              ? `+${contact?.country_code}`
              : contact?.country_code
        };
      });
      const venueDetails: IVenueDetail = {
        ...formik.values?.venue_detail,
        food_fees:
          formik.values?.venue_detail?.food_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.food_fees,
        labour_fees:
          formik.values?.venue_detail?.labour_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.labour_fees,
        alcohol_soft_bar_fees:
          formik.values?.venue_detail?.alcohol_soft_bar_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.alcohol_soft_bar_fees
      };
      const res: any = await addEditVenue(
        {
          ...formik.values,
          additional_contacts: additionalContacts,
          status: status,
          venue_detail: venueDetails
        },
        id
      );
      if (res?.success) {
        setVenueData(res?.data ?? {});
        showToast({
          message: 'Success!',
          description: 'Venue Saved Successfully!.'
        });
        if (tabType === VENUE_DETAILS_TABS.DOCUMENTS) {
          formik.resetForm();
          navigate(`/venues/tab/${VENUE_MANAGEMENT_TABS.ALL}`);
        } else {
          if (!res?.data?.id) {
            setTabType && setTabType(VENUE_DETAILS_TABS.INFO);
            return;
          }
          navigate(`/venues/${res?.data?.id}/edit`);
          setTabType && setTabType(VENUE_DETAILS_TABS.DOCUMENTS);
        }
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    setIsCreateLoading(false);
  };

  const formik = useFormik({
    initialValues: id ? venueData : initialValues,
    enableReinitialize: true,
    validate: (value) => {
      try {
        validateYupSchema(value, addEditValidations, true, value);
      } catch (error) {
        return yupToFormErrors(error);
      }
      return {};
    },
    validationSchema: addEditValidations,
    validateOnMount: true,
    onSubmit: onSubmit
  });

  const getTabs = () => {
    const tabValues = [
      {
        tab: 'INFO',
        content: (
          <AddEditInfo
            formik={formik}
            handleSaveDraft={handleSaveDraft}
            isCreateLoading={isCreateLoading}
            isDraftLoading={isDraftLoading}
            setTabType={setTabType}
          />
        ),
        key: VENUE_DETAILS_TABS.INFO,
        getUrl: VENUE_DETAILS_TABS.INFO
      },
      {
        tab: 'DOCUMENTS',
        content: <AddEditDocuments formik={formik} setTabType={setTabType} />,
        key: VENUE_DETAILS_TABS.DOCUMENTS,
        getUrl: VENUE_DETAILS_TABS.DOCUMENTS
      }
    ];
    return tabValues;
  };

  const handleSaveDraft = async () => {
    const res = manualValidate(formik);
    if (res) return;
    setIsDraftLoading(true);
    try {
      const additionalContacts = formik.values?.additional_contacts?.map((contact) => {
        delete contact?.localId;
        return {
          ...contact,
          venue_id: Number(id),
          country_code:
            !!contact?.country_code && !contact?.country_code?.includes('+')
              ? `+${contact?.country_code}`
              : contact?.country_code
        };
      });
      const venueDetails: IVenueDetail = {
        ...formik.values?.venue_detail,
        food_fees:
          formik.values?.venue_detail?.food_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.food_fees,
        labour_fees:
          formik.values?.venue_detail?.labour_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.labour_fees,
        alcohol_soft_bar_fees:
          formik.values?.venue_detail?.alcohol_soft_bar_fees === true
            ? formik.values?.venue_detail?.landmark_fees
            : formik.values?.venue_detail?.alcohol_soft_bar_fees
      };
      const res: any = await addEditVenue(
        {
          ...formik.values,
          additional_contacts: additionalContacts,
          status: VENUE_CREATE_STATUS.DRAFT,
          venue_detail: venueDetails
        },
        id
      );
      if (res?.success) {
        formik.resetForm();
        navigate(`/venues/tab/${VENUE_MANAGEMENT_TABS.ALL}`);
        showToast({
          message: 'Success!',
          description: 'Venue Saved as Draft.'
        });
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    setIsDraftLoading(false);
  };

  if (isVenueLoading) {
    return <CenterSpiner />;
  }
  return (
    <PageLayout title={'Venue Managment'}>
      <PageTitle
        text="VENUE MANAGEMENT"
        isBack={true}
        bordered={true}
        path={`/venues/tab/${VENUE_MANAGEMENT_TABS.ALL}`}
      >
        {id && !isEdit && (
          <Button
            variant="ghost"
            text="Edit"
            icon={<img src={EditIcon} alt="Edit" />}
            onClick={() => navigate(`/venues/${id}/edit`)}
          />
        )}
      </PageTitle>

      <Row gutter={24}>
        <Col span={24}>
          <HorizontalTab
            value={getTabs()}
            activeKey={tabType}
            onChange={(tab: any) => {
              if (!formik.values.id) {
                setTabType(VENUE_DETAILS_TABS.INFO);
                return;
              }
              setTabType(tab);
              // let newurl = `/venues/${id}/edit/#${tab}`;
              // navigate(newurl);
            }}
          />
        </Col>
      </Row>
    </PageLayout>
  );
}

export default AddEditPages;
