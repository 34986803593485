import { Col, Divider, Row } from 'antd';

import FilePreview from '@molecules/FilePreview';
import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { IVenueDataType } from '../types';
import React from 'react';

function VenueDocuments({ VenueData }: { VenueData: IVenueDataType }) {
  return (
    <Flex direction="column">
      <H2Typography style={{ marginTop: '15px' }}>Documents</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      {!VenueData?.documents || VenueData?.documents?.length === 0 ? (
        <p>No Documents uploaded yet.</p>
      ) : (
        <Row gutter={[16, 16]}>
          {VenueData?.documents?.map((item: any, index: number) => {
            return (
              <Col md={12} xs={24}>
                <FilePreview key={index} document={item} />
              </Col>
            );
          })}
        </Row>
      )}
    </Flex>
  );
}

export default VenueDocuments;
