import ModalComponent from '@organism/Modal/Modal';
import { forgotPasswordSchema } from '@pages/ForgotPasswordPage/helper';
import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import NoImage from '@assets/noimage.png';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import { H3Typography, H4Typography } from '@constant/typography/Typography';
import { ForgotPasswordModalProps, ForgotPasswordType } from './types';
import { showErrorToast, showToast } from '@utils/index';
import { sendResetPasswordLink } from './helper';
import { useState } from 'react';

const ForgotPasswordModal = (props: ForgotPasswordModalProps) => {
  const [email, setEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    isModalVisible,
    onCancel,
    onOk,
    data: profileDetails,
    isRestLinkSent,
    setIsRestLinkSent
  } = props;

  const navigate = useNavigate();

  const onSend = async (values: ForgotPasswordType) => {
    if (values.email) {
      const res = (await sendResetPasswordLink(values)) as any;
      if (res?.error) {
        showErrorToast({
          message: 'Error',
          description: res?.error || 'Please try again'
        });
        setIsRestLinkSent(false);
        onCancel();
      } else {
        setIsRestLinkSent(true);
        setEmail(values?.email);
      }
    } else {
      onOk();
    }
  };

  const formik = useFormik({
    validationSchema: forgotPasswordSchema,
    onSubmit: onSend,
    initialValues: {
      email: ''
    }
  });
  const { handleChange, handleSubmit, values, errors } = formik;

  const cancelHandler = () => {
    formik.resetForm();
    navigate('/profile');
    onCancel();
  };

  const tryAgainHandler = () => {
    setIsLoading(true);
    onSend({ email });
    setIsLoading(false);
    showToast({ message: 'Email Send Successfully', description: 'A Reset Link Has Been Sent' });
  };

  return (
    <ModalComponent
      visible={isModalVisible}
      onCancel={onCancel}
      modalWidth="562px !important"
      align="left !important">
      <Row gutter={24} className="centerd">
        {!isRestLinkSent ? (
          <Col span={24}>
            <Row gutter={12}>
              <Flex justifyContent={'center'} flex={1}>
                <img
                  src={profileDetails?.profile_pic || NoImage}
                  alt="profile"
                  style={{ width: 128, height: 128, objectFit: 'cover' }}
                />
              </Flex>
            </Row>
            <Row>
              <Flex justifyContent={'center'} flex={1} top={24} bottom={16}>
                <H3Typography>Forgot Password?</H3Typography>
              </Flex>
            </Row>
            <Flex justifyContent={'center'}>
              <p>We’ll send a link to your registered email ID to recover your password</p>
            </Flex>
            <Row>
              <Flex justifyContent={'center'} flex={1}>
                <Input
                  placeholder="Email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email}
                />
              </Flex>
            </Row>

            <Row>
              <Flex
                flex={1}
                gap={8}
                justifyContent={'end'}
                top={48}
                className="loginBtn"
                style={{ paddingTop: 24 }}>
                <Button text={'Cancel'} variant="secondary" onClick={cancelHandler} />
                <Button
                  text={!formik.isSubmitting ? 'Send' : 'Sending...'}
                  onClick={handleSubmit}
                />
              </Flex>
            </Row>
          </Col>
        ) : (
          <Col span={24}>
            <Row gutter={12}>
              <Flex justifyContent={'center'} flex={1}>
                <img
                  src={profileDetails?.profile_pic || NoImage}
                  alt="profile"
                  style={{ width: 128, height: 128, objectFit: 'cover' }}
                />
              </Flex>
            </Row>
            <Row>
              <Flex justifyContent={'center'} flex={1} top={24} bottom={16}>
                <H3Typography>A Reset Link Has Been Sent</H3Typography>
              </Flex>
            </Row>
            <Flex alignItems={'center'} direction={'column'}>
              <p style={{ margin: 0 }}>A link has been sent to:</p>
              <H4Typography>{email}</H4Typography>
            </Flex>
            <Flex alignItems={'center'} direction={'column'} top={16}>
              <p style={{ margin: 0 }}>Didn’t get an email?</p>
              <Button
                text={!isLoading ? 'Try Again' : 'Sending...'}
                variant={'ghost'}
                style={{ textDecoration: 'underline' }}
                onClick={tryAgainHandler}
              />
            </Flex>
            <Flex justifyContent={'center'} top={48} style={{ paddingTop: '24px' }}>
              <Button text={'Exit'} onClick={onOk} />
            </Flex>
          </Col>
        )}
      </Row>
    </ModalComponent>
  );
};

export default ForgotPasswordModal;
