import React, { Component, ReactNode } from 'react';
import Rollbar from 'rollbar';
import _ from 'lodash';
import FallbackErrorPage from '@atom/FallbackError';

const rollbarToken = 'da5a3e4b84514194a822604d94540bdf';
const environment = process.env.REACT_APP_ROLLBAR_ENVIRONMENT;

let rollbar: Rollbar | null = null;
if (environment === 'production') {
  rollbar = new Rollbar({
    accessToken: rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true
  });
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };

    _.bindAll(this, 'onRedirect');
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (rollbar) {
      rollbar.error(error, errorInfo?.componentStack!);
    }
    this.setState({ hasError: true, error });
  }

  onRedirect(): void {
    this.setState(
      {
        hasError: false,
        error: null
      },
      () => {
        window.history.back();
      }
    );
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return <FallbackErrorPage error={error ? error.message : ''} />;
    }

    return children;
  }
}

export default ErrorBoundary;
