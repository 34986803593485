//Initial Values
export const additionalDepartment = {
  master_department_id: '',
  position_id: undefined
};

export const profileInitialValue = {
  profile_pic: '',
  imageName: '',
  name: '',
  email: '',
  country_code: '',
  phone_number: '',
  address: '',
  extension: '',
  departments: [additionalDepartment],
  reports_to: ''
};

export const USER_TYPE = {
  ADMIN: 1,
  MANAGER: 2,
  EMPLOYEE: 3
};

export const USER_TYPE_MAP = new Map([
  [USER_TYPE.ADMIN, 'ADMIN'],
  [USER_TYPE.MANAGER, 'MANAGER'],
  [USER_TYPE.EMPLOYEE, 'EMPLOYEE']
]);
