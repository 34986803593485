import React, { FC, useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4Typography } from '@constant/typography/Typography';
import {
  EditProductTypes,
  getProductTypes,
  ProductListType,
  ProductTypeProps,
  productValidationSchema,
  removeProduct,
  updateProductTypes
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { Input } from '@atom/Input';
import RemoveIcon from '@assets/removerow.png';
import PlusCircle from '@assets/plus-circle.svg';
import { FieldArray, Formik } from 'formik';
import { Button } from '@atom/Buttons';
import { Col, Row } from 'antd';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { useSelector } from 'react-redux';
import ConfirmModal from '@organism/ConfimationModal';

export interface SubmitValueType {
  index: number;
  arrayHelper: any;
  id?: number | null;
}

const ProductType = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState<ProductListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [showSaveChangeConfirmModal, setShowSaveChangeConfirmModal] = useState(false);

  const [submitValue, setSubmitValue] = useState<SubmitValueType | null>(null);
  const [formValues, setFormValues] = useState<ProductListType[]>([]);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getProductsHandler();
  }, []);

  const getProductsHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getProductTypes();
    setPageLoading(false);
    if (!result.error) {
      setProductList(result.data || []);
    }
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getProductsHandler();
    onModalCancelHandler();
  };

  const onSubmitHandler = (values: SubmitValueType) => {
    setSubmitValue(values);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const onProductDelete = async () => {
    if (submitValue?.id) {
      showToast({ message: 'Product Removed Successfully', description: '' });
    }
    onModalCancelHandler();
    submitValue?.arrayHelper && submitValue.arrayHelper.remove(submitValue.index);
  };

  const updateProductHandler = (values: EditProductTypes) => {
    setShowSaveChangeConfirmModal(true);
    setFormValues(values.product_types);
  };
  const onSubmit = async () => {
    const updatedProductTypes = (formValues || [])
      .filter((item) => item.is_static !== 1)
      .map((item) => ({
        ...item,
        product_type: item.text
      }));
    setLoading(true);
    const result = await updateProductTypes({ product_types: updatedProductTypes });
    setShowSaveChangeConfirmModal(false);
    setLoading(false);
    if (!result.error) {
      showToast({ message: 'Products Updated Successfully', description: '' });
      onCancelHandler();
    } else {
      showErrorToast({
        message: 'Error Saving Product',
        description: result.error || 'Please try again'
      });
    }
  };
  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Flex bottom={26}>
          <H4Typography>PRODUCT TYPE</H4Typography>
        </Flex>
        <Formik
          initialValues={{ product_types: productList }}
          validationSchema={productValidationSchema}
          onSubmit={updateProductHandler}>
          {({ values, handleChange, setFieldValue, handleSubmit, handleBlur, touched, errors }) => {
            return (
              <Flex direction="column">
                <FieldArray
                  name={'product_types'}
                  render={(arrayHelper: any) => {
                    return values?.product_types?.map((product, index) => (
                      <Row gutter={[16, 16]}>
                        <Col md={11} xs={20}>
                          <Input
                            id={`product_types[${index}].text`}
                            name={`product_types[${index}].text`}
                            value={product.text}
                            disabled={!!product.is_static}
                            placeholder={`Product Type Name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched?.product_types?.[index]?.text &&
                              (errors?.product_types as { text: string }[])?.[index]?.text
                            }
                          />
                        </Col>
                        {!product.is_static && (
                          <Col
                            md={1}
                            xs={4}
                            className="centerPosition"
                            style={{ opacity: 1, textAlign: 'center' }}>
                            <img
                              style={{ paddingTop: '10px' }}
                              src={RemoveIcon}
                              alt="remove"
                              onClick={() =>
                                onSubmitHandler({ index, arrayHelper, id: product?.id })
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    ));
                  }}
                />
                <Flex bottom={24}>
                  <Button
                    text="Add Product Type"
                    variant="secondary"
                    icon={<img width={20} src={PlusCircle} alt="add" />}
                    onClick={() =>
                      setFieldValue('product_types', [
                        ...values.product_types,
                        { text: null, id: '', deleted_at: null, is_static: 0 }
                      ])
                    }
                  />
                </Flex>
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8} className="fix-mobile">
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button text="Submit" onClick={handleSubmit} isLoading={loading} />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Are you sure you want to delete the product type?"
          onOK={onProductDelete}
          isModalLoading={false}
          okText="Confirm Delete">
          <span>Any products attached to the deleted product type will be Unassigned Product</span>
        </ConfirmModal>
        <ConfirmModal
          visible={showSaveChangeConfirmModal}
          onCancel={() => setShowSaveChangeConfirmModal(false)}
          title="Saving will impact existing entries."
          onOK={onSubmit}
          isModalLoading={loading}
          okText="Okay">
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <div>
      <Flex bottom={26}>
        <H4Typography>PRODUCT TYPE</H4Typography>
      </Flex>
      <Flex style={{ flexFlow: 'wrap', gap: '20px' }}>
        {productList.map((product) => (
          <p key={product.id}>{product.text}</p>
        ))}
      </Flex>
    </div>
  );
};

export default ProductType;
