import React from 'react';
import { Col, Divider, Row } from 'antd';
import FormDataView from '@atom/FormDataView';
import { H2Typography, H3Typography } from '@constant/typography/Typography';
import moment from 'moment';
import {
  EventContentWrapper,
  SectionTitleDiv
} from '@pages/QuoteManagementPage/ViewQuoteDetails/style';

function ViewEventDetails({
  event_detail,
  event_head_chef_detail,
  event_manager_detail
}: EventDetailsType) {
  const {
    account_manager_name,
    event_date,
    event_code,
    customer_on_site_name,
    total_guest,
    venue_address
  } = event_detail || {};

  const getNames = (details: any[]) =>
    details?.length > 0 ? details.map((item) => item?.employee?.name).join(', ') : 'NA';

  const rows = [
    { heading: 'Manager', value: getNames(event_manager_detail) },
    { heading: 'Food Dudes Chef(s)', value: getNames(event_head_chef_detail) },
    { heading: 'Account Manager', value: account_manager_name || 'NA' },
    { heading: 'Event Date', value: event_date ? moment(event_date).format('ddd, MMMM D') : 'NA' },
    { heading: 'Event code', value: event_code || 'NA' },
    { heading: 'Client name', value: customer_on_site_name || 'NA' },
    { heading: 'Guest count', value: total_guest || 'NA' },
    { heading: 'Venue', value: venue_address || 'NA' }
  ];

  return (
    <React.Fragment>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'event-section'}>Event Details</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>

      <EventContentWrapper style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Row style={{ width: '100%' }} gutter={[16, 10]}>
          {rows.map((row, index) => (
            <Col lg={8} key={index}>
              <FormDataView heading={row.heading} value={row.value} />
            </Col>
          ))}
        </Row>
      </EventContentWrapper>
    </React.Fragment>
  );
}

export default ViewEventDetails;
