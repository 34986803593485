import styled from 'styled-components';

import { Flex } from '@atom/Flex';

export const TableWrapper = styled(Flex)`
  .ant-table-tbody tr {
    cursor: pointer;
  }

  .ant-table-cell {
    vertical-align: baseline !important;
  }

  .ant-pagination-simple-pager {
    .ant-pagination-slash {
      visibility: hidden;
    }
    span::after {
      content: 'of';
      margin-left: 0;
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    .ant-table {
      white-space: nowrap;
    }
  }
  @media (max-width: 820px) {
    .datatable-fix {
      width: 150px !important;
    }
  }
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: contents;

  img {
    width: 16px;
    height: 16px;
  }
  span {
    text-transform: uppercase;
  }
`;

export const PageWrapper = styled.div`
  @media (max-width: 600px) {
    .text-xs-left {
      text-align: left !important;
      justify-content: right;
    }
    .mobile-fix {
    }
  }
  .ant-select-selection-item {
    font-weight: 700;
    font-size: 18px;
  }
  @media (max-width: 767px) {
    .mobile-fixing {
      display: block !important;
    }
    .btn-fixing {
      justify-content: left;
    }
  }
`;

export const RightAlignData = styled.div`
  text-align: right;
  width: 100%;
`;

export const BundleDetailsTableWrapper = styled.div`
  th {
    border: none !important;
  }
`;

export const CloseButtonWrapper = styled.div`
  @media (max-width: 650px) {
    .ghost-btn-style {
      padding-top: 10px !important;
      padding-bottom: 30px !important;
      padding-left: 0px !important;
    }
  }
`;
