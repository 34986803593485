import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import DatePickerComponent from '@atom/DatePicker';
import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { H2Typography } from '@constant/typography/Typography';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';
import { GetTabData, PutTabData } from '@services/quoteServices';
import { getEventDate, getSelectOptions } from '@utils/helper';
import { showErrorToast, showToast } from '@utils/index';
import { Col, Divider, Row } from 'antd';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import AddEditQuoteFooter from '../AddEditQuoteFooter';
import EventBasicDetails from '../EventBasicDetails';
import { getTabBaseUrl, QuoteBasicDetailsObject } from '../helper';
import {
  FORM_INITIAL_VALUES,
  getOtherSingleItem,
  othersFormSchema,
  otherSingleItem
} from './helper';
import OtherEstimateItem from './OtherEstimateItem';

interface OthersEstimateFormProps {
  tabKey: string;
  quoteBasicDetails: QuoteBasicDetailsObject;
  setQuoteBasicDetails: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  isEvent?: boolean;
  submitForm?: (tabKey: string, values: any, actions: any, method: string) => void;
}

const OthersEstimateForm = (props: OthersEstimateFormProps) => {
  const { tabKey, quoteBasicDetails, setQuoteBasicDetails, isEvent, submitForm } = props;
  const { quoteId, formType, eventId } = useParams();
  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);

  const navigate = useNavigate();
  const location = useLocation();

  const [draftSubmitting, setDraftSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [formikData, setFormikData] = useState(FORM_INITIAL_VALUES);
  const formik = useFormik({
    validationSchema: othersFormSchema,
    onSubmit: (values: any, actions: any) => submitHandler(values, actions),
    initialValues: formikData
  });
  const { values, setValues, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    if (formType === 'other')
      setValues({
        ...values,
        event_dates: {
          ...values?.event_dates,
          other_details: getOtherSingleItem([], values?.event_dates?.other_details)
        }
      });
  }, [formType]);

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    if (!isDraft && submitForm) {
      await submitForm(tabKey, values, actions, 'put');
      return;
    }
    const res: any = await PutTabData(tabKey, {
      ...quoteBasicDetails,
      ...values,
      unique_invoice_string: random_unique_invoice_string
        ? random_unique_invoice_string
        : quoteBasicDetails.unique_invoice_string,
      id: quoteId
    });
    if (res?.success) {
      setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
        ...prev,
        id: res?.data?.id || prev?.id,
        quotation_no: res?.data?.quotation_no || prev?.quotation_no,
        status: res?.data?.status,
        unique_invoice_string: random_unique_invoice_string
          ? random_unique_invoice_string
          : res?.data?.unique_invoice_string || prev?.unique_invoice_string
      }));
      const tabBaseUrl = getTabBaseUrl(
        location?.pathname,
        res?.data?.id || quoteId,
        quoteBasicDetails,
        res?.data?.id
      );
      navigate(isDraft ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}` : `${tabBaseUrl}fees`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
      if (isDraft) {
        showToast({
          message: 'Success!',
          description: 'Quotation saved as Draft'
        });
      }
    } else {
      actions.setSubmitting(false);
      actions.setErrors(res?.error);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
  };

  const getOthersTabInitialDetails = async (id: number | string) => {
    setLoading(true);
    try {
      const res: any = await GetTabData(tabKey, id, isEvent);
      if (res?.success) {
        setFormikData(res?.data);
        setValues({
          ...values,
          ...res?.data,
          event_dates: {
            ...values?.event_dates,
            ...res?.data?.event_dates,
            other_details: getOtherSingleItem(
              values?.event_dates?.other_details,
              res?.data?.event_dates?.other_details
            )
          }
        });
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id,
          quotation_no: res?.data?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string
        }));
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch quote data.'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch quote data.'
      });
    }
    setLoading(false);
  };

  const removeVendorHandler = (other: any) => {
    const positionItems = values?.event_dates?.other_details?.filter((i: any) =>
      other?.vendor_id ? i?.vendor_id !== other?.vendor_id : i?.id !== other?.id
    );
    setFieldValue('event_dates.other_details', positionItems);
  };

  const backHandler = () => {
    if (isEvent) {
      const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.VENDORS}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}vendor`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };

  const cancelHandler = () => {
    if (isEvent) {
      navigate('/events');
    } else {
      navigate(`/quote/tab/${QUOTE_MANAGEMENT_TABS.ALL}`);
    }
  };

  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    // const res: any = await PutTabData(tabKey, {
    //   ...values,
    //   status: STATUS.DRAFT,
    //   ...quoteBasicDetails
    // });
    setFieldValue('status', STATUS.DRAFT);
    await submitHandler({ ...values, status: STATUS.DRAFT }, null, true);
    setDraftSubmitting(false);
  };
  const nextHandler = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (quoteId) {
      getOthersTabInitialDetails(quoteId);
    }
  }, [quoteId]);

  if (loading) {
    return <CenterSpiner />;
  }
  return (
    <>
      <EventBasicDetails />

      <Row style={{ marginTop: 40 }}>
        <H2Typography>Others Estimate</H2Typography>
        <Divider />
        <Row style={{ width: '100%' }}>
          <Col lg={8} md={12} xs={24}>
            <Label text={'Date'} />
            <SelectInput
              id="event_dates.event_date[0]"
              name="event_dates.event_date[0]"
              placeholdertitle="Select from the list"
              value={0}
            >
              {getSelectOptions(
                [
                  {
                    id: 0,
                    name:
                      values?.event_dates?.date_string &&
                      values?.event_dates?.date_string !== '0000-00-00'
                        ? values?.event_dates?.date_string
                        : 'Unassigned Date'
                  }
                ],
                'name'
              )}
            </SelectInput>
          </Col>
        </Row>
        <FormikProvider value={formik}>
          <FieldArray
            name="other_details"
            render={(arrayHelper) => {
              if (values?.event_dates?.other_details?.length === 0) {
                return null;
              }
              return values?.event_dates?.other_details?.map((item: any, index: number) => {
                return (
                  <Row key={index} style={{ marginTop: 20, width: '100%' }}>
                    <OtherEstimateItem
                      other={item}
                      index={index}
                      formik={formik}
                      onRemove={removeVendorHandler}
                    />
                  </Row>
                );
              });
            }}
          />
        </FormikProvider>
        <Row style={{ marginTop: 20 }}>
          <Button
            variant="secondary"
            onClick={() => {
              formik.setFieldValue('event_dates.other_details', [
                ...(values?.event_dates?.other_details || []),
                {
                  ...otherSingleItem,
                  vendor_id: Math.random()
                }
              ]);
            }}
            text="+ Add Other"
            style={{ borderWidth: 2 }}
          />
        </Row>
      </Row>
      <AddEditQuoteFooter
        isEvent={isEvent}
        isDraftSubmitting={draftSubmitting}
        showBackButton={true}
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        saveAsDraftClickHandler={saveAsDraftHandler}
        nextClickHandler={nextHandler}
        isSubmitting={formik?.isSubmitting}
      />
    </>
  );
};

export default OthersEstimateForm;
