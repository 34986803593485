import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { H2Typography } from '@constant/typography/Typography';
import { Col, Row } from 'antd';
import { FieldArray, FormikProvider } from 'formik';
import { newGuestAllergiesObject } from '../helper';
import GuestFields from './GuestFields';
import { Divider } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';
import { useState } from 'react';

interface GuestAllergiesProps {
  formik: any;
}

const GuestAllergies = (props: GuestAllergiesProps) => {
  const { formik } = props;
  const {
    touched,
    errors,
    values,
    setValues,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit
  } = formik;

  const guestAllergiesFormikProps = { touched, errors, values, setFieldValue, handleBlur };

  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  return (
    <>
      <H2Typography style={{ marginTop: '32px' }}>Guest Allergies</H2Typography>
      <Divider style={{ margin: '20px 0px 25px 0px' }} />
      <FormikProvider value={formik}>
        <FieldArray
          name={'guest_allergies'}
          render={(arrayHelper: any) => {
            return values?.guest_allergies?.map((item: any, index: any) => {
              return (
                <GuestFields
                  arrayHelper={arrayHelper}
                  key={index}
                  item={item}
                  index={index}
                  isRemoved={isRemoved}
                  setIsRemoved={setIsRemoved}
                  {...guestAllergiesFormikProps}
                />
              );
            });
          }}
        />
      </FormikProvider>
      <Row gutter={16}>
        <Col xs={24} lg={4}>
          <Button
            style={{ fontWeight: '700' }}
            variant="secondary"
            icon={<PlusCircleOutlined />}
            text="Add Another Guest"
            onClick={() => {
              setFieldValue('guest_allergies', [
                ...values?.guest_allergies,
                { ...newGuestAllergiesObject, id: undefined, tempId: Math.random() }
              ]);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default GuestAllergies;
