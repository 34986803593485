import {
  ICategoryType,
  IInitialFiltersType,
  IVendorDataType,
  IVendorListFilterType,
  IVendorRecordDataType,
  ResponseType
} from './types';

import { Button } from '@atom/Buttons';
import DeactivateIcon from '@assets/alert-circle.svg';
import ReactivateIcon from '@assets/alert-circle-red.svg';
import { VENDOR_CREATE_STATUS } from './AddEditPages/helper';
import moment from 'moment';
import services from '@services/index';

export enum VENDOR_DETAILS_TABS {
  INFO = 'info',
  DOCUMENTS = 'documents'
}

export enum VENDOR_RECORD_TABS {
  UPCOMING_EVENTS = 'upcoming_events',
  PAST_EVENTS = 'past_events'
}

export enum VENDOR_MANAGEMENT_TABS {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'in-active',
  DRAFTS = 'drafts'
}

export const getButton = (
  status: string | number,
  setDeactivateModal: (value: boolean) => void,
  setReactivateModal: (value: boolean) => void
) => {
  switch (status) {
    case 0:
      return (
        <Button
          variant="ghost"
          text={'REACTIVATE'}
          icon={<img src={ReactivateIcon} alt="REACTIVATE" style={{ color: 'red' }} />}
          onClick={() => setReactivateModal(true)}
          style={{ color: 'red' }}
        />
      );

    case 1:
      return (
        <Button
          variant="ghost"
          text={'DEACTIVATE'}
          icon={<img src={DeactivateIcon} alt="DEACTIVATE" />}
          onClick={() => setDeactivateModal(true)}
        />
      );
    default:
      return null;
  }
};

export const getColumns = () => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: '	Event Name',
      dataIndex: 'event_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'Total Hours',
      dataIndex: 'total_hours',
      render: (data: any) => {
        return data ? data : '-';
      }
    }
  ];
  return columns;
};
export const VendorCategoriesDummyData: ICategoryType[] = [
  {
    id: 5,
    name: 'Audio Visual',
    comments: 'Category 1',
    status: 1
  },
  {
    id: 2,
    name: 'Dcor and Flowers',
    comments: 'Category 2',
    status: 1
  },
  {
    id: 8,
    name: 'Disposables',
    comments: 'Category 3',
    status: 1
  }
];

export const VendorAddEditDummyData: IVendorDataType = {
  vendor_name: 'Vendor name',
  status: 2,
  email: 'Vendor SUB SECTION',
  description: 'Vendor ADDRESS',
  public_tags: ['Desinger'],
  registered_at: 'October 13, 2022',
  documents: [],
  formated_phone_number: 477,
  phone_number: 554545123,
  country_iso_code: 23,
  country_code: '13',
  contact_name: 'Vendor name',
  extension: 12,
  additional_contacts: [
    {
      email: 'frank@historicalwaltonhouse.com',
      name: 'Frank Blanco',
      phone_number: 7863569435,
      country_code: '+1',
      country_iso_code: 'US',
      id: 1727,
      vendor_id: 1459
    }
  ],
  pickup_times: [
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Sunday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Monday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Tuesday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Wednesday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Thursday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Friday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Saturday'
    }
  ],
  drop_times: [
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Sunday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Monday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Tuesday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Wednesday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Thursday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Friday'
    },
    {
      start_time: '10:00 AM',
      end_time: '11:00 AM',
      day: 'Saturday'
    }
  ],
  vendor_categories: [
    {
      id: 53,
      vendor_category_id: 5,
      vendor_id: 17
    },
    {
      id: 36,
      vendor_category_id: 2,
      vendor_id: 13
    }
  ],
  categories: VendorCategoriesDummyData
};

export const VendorRecordsDummyData: IVendorRecordDataType[] = [
  {
    customer_name: 'Customer Name',
    date: 'October 13, 2022',
    event_end_time: 'October 13, 2022',
    event_name: 'Event Name',
    event_start_time: 'October 13, 2022',
    total_hours: '10'
  },
  {
    customer_name: 'Customer Name',
    date: '2022-10-19',
    event_end_time: '12:00:00',
    event_name: '"Event 17102022_0007',
    event_start_time: '01:00:00',
    total_hours: '11'
  }
];

export const initialFilters: IInitialFiltersType = {
  name: '',
  menu_name: '',
  category: '',
  sub_category: '',
  type: 'all',
  page: 1,
  status: null
};

export const getTableStatus = (status: string) => {
  switch (status) {
    case 'in-active':
      return 0;
    case 'active':
      return 1;
    case 'drafts':
      return 2;
    default:
      return null;
  }
};

export const mapTableData = (data: Array<any>) => {
  const mappedData =
    data?.map((item: any) => ({
      id: item?.id ?? '-',
      vendor_name: item?.vendor_name || '-',
      status: item?.status ?? '-',
      vendor_sub_section: item?.vendor_sub_section || '-',
      contact_email: item?.first_additional_contacts?.contact_email || '-',
      formated_contact_phone_number:
        item?.first_additional_contacts?.formated_contact_phone_number ?? '-'
    })) || [];
  return mappedData;
};

export const getVendorList = async (
  page: number,
  status: number | null,
  data: IVendorListFilterType
) => {
  try {
    const res: any = await services.post(
      `/vendors/list${status !== null ? `/${status}` : ``}?page=${page || 1}`,
      data
    );
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getVendor = async (id: any) => {
  try {
    const res = (await services.get(`/vendors/view/${id}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const changeVendorStatus = async (id: number | string, status: number) => {
  try {
    const res = (await services.put(`/vendors/${id}/change-status`, { status })) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getVendorPastRecords = async (id: string | number, page: number) => {
  try {
    const res = (await services.get(`/vendors/past-records/${id}?page=${page}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getVendorUpcomingRecords = async (id: string | number, page: number) => {
  try {
    const res = (await services.get(`/vendors/upcoming-records/${id}?page=${page}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getVendorCategories = async () => {
  try {
    const res = (await services.get(`/vendors/categories`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const addEditVendor = async (values: IVendorDataType, id?: any) => {
  try {
    const method =
      id && values?.status !== VENDOR_CREATE_STATUS.DRAFT
        ? services.put(`/vendors/update`, values)
        : services.post(`/vendors/create`, values);
    const res = (await method) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
