import { Label } from '@atom/FormLable';
import { Col, Row } from 'antd';
import React from 'react';

function RepresentativeItemHeader() {
  return (
    <Row className="hide-mobile-device" gutter={16}>
      <Col span={6}>
        <Label text={'Name'} />
      </Col>
      <Col span={6}>
        <Label text={'Email'} />
      </Col>
      <Col span={12}>
        <Label text={'Phone Number'} />
      </Col>
    </Row>
  );
}

export default RepresentativeItemHeader;
