import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import DataTable from '@organism/DataTable/DataTable';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import HorizontalTab from '@molecules/HorizontalTabs';
import { Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { getTabs } from './helper';
import { STAFF_MANAGEMENT_TABS } from '../helper';
import { Flex } from '@atom/Flex';

function StaffManagementList() {
  const navigate = useNavigate();
  const [tabType, setTabType] = useState<string>(STAFF_MANAGEMENT_TABS.UPCOMING);
  const { tab } = useParams();
  useEffect(() => {
    if (
      window.location.pathname === '/staff-management' ||
      window.location.pathname === '/staff-management/'
    ) {
      navigate(`/staff-management/tab/${STAFF_MANAGEMENT_TABS.UPCOMING}`);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    tab && setTabType(tab);
  }, [tab]);
  const staffManagementTabs = getTabs();
  return (
    <PageLayout>
      <Flex direction="column" flex={1}>
        <PageTitle text={'STAFF MANAGEMENT'} />
        <Row gutter={24}>
          <Col span={24}>
            <HorizontalTab
              value={staffManagementTabs}
              activeKey={tabType}
              onChange={(tab: any) => {
                setTabType(tab);
                let newurl = `/staff/tab/${tab}`;
                navigate(newurl);
              }}
            />
          </Col>
        </Row>
        {/* </PageTitle> */}
      </Flex>
    </PageLayout>
  );
}

export default StaffManagementList;
