import CustomerForm from './Customer/CustomerForm';
import MealPlanForm from './MealPlan/MealPlanForm';
import LaboursForm from './Labours/LaboursForm';
import VenueForm from './Venue/VenueForm';
import MealOrderForm from './MealOrder/MealOrderForm';
import VendorsForm from './Vendors/VendorsForm';
import OthersEstimateForm from './OthersTab/OthersForm';
import FeesForm from './FeesTab/FeesForm';
import UnauthorizedPage from '@pages/UnauthorizedPage/unauthorizedPage';
import services from '@services/index';
import EventBasicDetails from './EventBasicDetails';
import Notes from '@pages/EventManagementPage/AddEditEvent/SharedTabs/Notes';

interface QuoteManagementTabsPropsType {
  quoteBasicDetails: QuoteBasicDetailsObject;
  setQuoteBasicDetails: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  tabKey: string;
  quoteId?: string | number;
}

export const enum CREATE_QUOTE_TAB_KEYS {
  CUSTOMER = 'customer', 
  NOTES = 'notes',
  LABOURS = 'labour', 
  FEES = 'fees', 
  VENUE = 'venue', 
  OTHERS = 'other', 
  MEALPLAN = 'meal-plan', 
  MEALORDER = 'meal-order', 
  VENDORS = 'vendor' 
}

export const getQuoteManagementTabs = (props: QuoteManagementTabsPropsType) => {
  const { quoteId, quoteBasicDetails } = props;
  const isTabDisabled: boolean = !(quoteId || quoteBasicDetails?.id);
  const eventBasicDetailsComponent = isTabDisabled ? null : <EventBasicDetails />;

  const QUOTE_MANAGEMENT_TABS = [
    {
      tab: 'CUSTOMER',
      content: <CustomerForm {...props} />,
      disabled: false,
      key: CREATE_QUOTE_TAB_KEYS.CUSTOMER
    },
    {
      tab: 'VENUE',
      content: isTabDisabled ? <UnauthorizedPage /> : <VenueForm {...props} />,
      disabled: isTabDisabled,
      key: CREATE_QUOTE_TAB_KEYS.VENUE
    },
    {
      tab: 'NOTES',
      content: <Notes {...props} />,
      disabled: isTabDisabled,
      key: CREATE_QUOTE_TAB_KEYS.NOTES
    },
    {
      tab: 'MEAL PLAN',
      content: isTabDisabled ? <UnauthorizedPage /> : <MealPlanForm {...props} />,
      disabled: isTabDisabled,
      key: CREATE_QUOTE_TAB_KEYS.MEALPLAN
    },
    {
      tab: 'MEAL ORDER',
      content: isTabDisabled ? (
        <UnauthorizedPage />
      ) : (
        <>
          {eventBasicDetailsComponent}
          <MealOrderForm {...props} />
        </>
      ),
      disabled: isTabDisabled,
      key: CREATE_QUOTE_TAB_KEYS.MEALORDER
    },
    {
      tab: 'LABOURS',
      content: isTabDisabled ? (
        <UnauthorizedPage />
      ) : (
        <>
          {eventBasicDetailsComponent}
          <LaboursForm {...props} />
        </>
      ),
      disabled: isTabDisabled,
      key: CREATE_QUOTE_TAB_KEYS.LABOURS
    },
    {
      tab: 'VENDORS',
      content: isTabDisabled ? (
        <UnauthorizedPage />
      ) : (
        <>
          {eventBasicDetailsComponent}
          <VendorsForm {...props} />
        </>
      ),
      disabled: isTabDisabled,
      key: CREATE_QUOTE_TAB_KEYS.VENDORS
    },
    {
      tab: 'OTHERS',
      content: isTabDisabled ? <UnauthorizedPage /> : <OthersEstimateForm {...props} />,
      disabled: isTabDisabled,
      key: CREATE_QUOTE_TAB_KEYS.OTHERS
    },
    {
      tab: 'FEES',
      content: isTabDisabled ? (
        <UnauthorizedPage />
      ) : (
        <FeesForm
          {...props}
          navigationUrl={`/quote/view/${quoteId || quoteBasicDetails?.id}`}
          nextButtonTitle="Create Quote"
        />
      ),
      disabled: isTabDisabled,
      key: CREATE_QUOTE_TAB_KEYS.FEES
    }
  ];
  return QUOTE_MANAGEMENT_TABS;
};
export interface QuoteBasicDetailsObject {
  id?: number | string;
  unique_invoice_string?: string;
  quotation_no?: string | number;
  status?: number | undefined;
}

export const getEventDetails = async (quoteId: string) => {
  try {
    const res = (await services.get(`/events/detail?quote_id=${quoteId}`)) as any;
    return res?.data?.data || {};
  } catch (error) {
    return {};
  }
};

export const getTabBaseUrl = (
  pathname: string,
  quoteId: number | string | undefined,
  quoteBasicDetails: any,
  id: number | string | undefined
) => {
  return pathname?.includes('edit-quote')
    ? `/quote/edit-quote/${quoteId}/`
    : `/quote/add-quote/${
        quoteId
          ? quoteId + '/'
          : quoteBasicDetails?.id
          ? quoteBasicDetails?.id + '/'
          : id
          ? id + '/'
          : ''
      }`;
};
