import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';

import Logo from '@assets/logo.png';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import { H3Typography } from '@constant/typography/Typography';
import { showToast } from '@utils/index';

import {
  getForgetPasswordContent,
  FORGET_PASSWORD_CONTENT_TYPES,
  forgotPasswordSchema,
  sendforgotPasswordLink
} from './helper';
import { ForgotPasswordCard, ForgotPasswordContainer } from './styles';
import { useState } from 'react';

function LoginPage() {
  const navigate = useNavigate();
  const [contentType, setContentType] = useState<string>(
    FORGET_PASSWORD_CONTENT_TYPES.EMAIL_NOT_SENT
  );
  const onSend = async (values: any) => {
    try {
      const res = (await sendforgotPasswordLink(values.email)) as { message: string };
      if (res.message) {
        showToast({ message: res.message, description: '' });
        setContentType(FORGET_PASSWORD_CONTENT_TYPES.EMAIL_SENT);
      }
    } catch (error) {
      //@ts-ignore
      if (error?.error?.error?.email?.length) {
        //@ts-ignore
        formik.setFieldError('email', error?.error?.error?.email);
        return;
      }
    }
  };

  const formik = useFormik({
    validationSchema: forgotPasswordSchema,
    onSubmit: onSend,
    initialValues: {
      email: '',
      password: ''
    }
  });
  const { values } = formik;
  return (
    <ForgotPasswordContainer>
      <Row gutter={24} className="centerd">
        <Col span={24}>
          <ForgotPasswordCard>
            <Row gutter={12}>
              <Flex justifyContent={'center'} flex={1}>
                <img src={Logo} alt="logo" style={{ width: 128, height: 128 }} />
              </Flex>
            </Row>
            <Row>
              <Flex justifyContent={'center'} flex={1} top={10}>
                <H3Typography>
                  {
                    getForgetPasswordContent({
                      email: values?.email,
                      formik,
                      setContentType,
                      navigate
                    })?.[contentType]?.title
                  }
                </H3Typography>
              </Flex>
            </Row>
            {
              getForgetPasswordContent({
                email: values?.email,
                formik,
                setContentType,
                navigate
              })?.[contentType]?.content
            }
          </ForgotPasswordCard>
        </Col>
      </Row>
    </ForgotPasswordContainer>
  );
}

export default LoginPage;
