import * as yup from 'yup';

import { FormikProps } from 'formik';
import { IVenueDataType } from '../types';
import services from '@services/index';

export const newAdditionalContact = {
  localId: Math.random(),
  contact_email: '',
  contact_name: '',
  contact_phone_number: '',
  country_code: '+1',
  country_iso_code: '',
  formated_contact_phone_number: '',
  extension: ''
};

const digitsOnly = (value?: string) =>
  !!value ? /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0 || value === '' : true;

const phoneRegExp = /^[0-9]{10}$/; //  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const manualValidate = (formik: FormikProps<IVenueDataType>) => {
  formik.validateForm().then((errors) => {
    Object.entries(errors)?.map((error: any, key: any) => {
      if (Array.isArray(error[1])) {
        error[1]?.map((err: any, index: number) => {
          Object.keys(err)?.map((key: any) =>
            formik.setFieldTouched(`${error[0]}.${index}.${key}`, true)
          );
        });
      } else {
        formik.setFieldTouched(error[0], true);
      }
    });
  });
  if (!!formik.errors && Object.keys(formik.errors).length > 0) return true;
};

export const addEditValidations = yup.object().shape({
  venue_name: yup.string().required('Please enter venue name.'),
  venue_sub_section: yup.string().nullable(),
  venue_address: yup.string().required('Please enter venue address.'),
  capacity: yup.mixed().nullable(),
  fd_account_manager: yup.string().nullable(),
  start_time: yup.string().nullable(),
  end_time: yup.string().nullable(),
  additional_contacts: yup.array().of(
    yup.object().shape({
      contact_name: yup.string().required('Please enter contact name.'),
      contact_phone_number: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .nullable(),
      contact_email: yup.string().email('Please enter a valid email address.').nullable(),
      country_code: yup.string().nullable(),
      country_iso_code: yup.string().nullable(),
      extension: yup.number().typeError('Extension can only be a number.').nullable(),
      formated_contact_phone_number: yup.string().nullable()
    })
  ),
  venue_kitchen_detail: yup.object().nullable().shape({
    power_available: yup.string().nullable(),
    running_water: yup.string().nullable(),
    kitchen_availability: yup.string().nullable(),
    cooking_equipments: yup.mixed().nullable(),
    rental_equipments: yup.mixed().nullable(),
    special_equipments: yup.mixed().nullable()
  }),
  venue_detail: yup
    .object()
    .nullable()
    .shape({
      alcohol_bar_venue_in_charge: yup.string().nullable(),
      alcohol_bar_setup_requirements: yup.mixed().nullable(),
      setup_duty_requirements: yup.mixed().nullable(),
      closing_duty_requirements: yup.mixed().nullable(),
      loading_dock: yup.string().nullable(),
      parking: yup.string().nullable(),
      loading_parking_additional_information: yup.mixed().nullable(),
      coat_check: yup.string().nullable(),
      coat_check_additional_information: yup.mixed().nullable(),
      staff_additional_restriction: yup.mixed().nullable(),
      landmark_fees: yup
        .string()
        .test('inputEntry', 'Landmark fee should be number only', digitsOnly)
        .nullable(),
      additional_notes: yup.mixed().nullable()
    })
});

export const getInitValues = (values: IVenueDataType) => {
  return {
    id: values?.id,
    venue_name: values?.venue_name || '',
    status: values?.status ?? '',
    venue_sub_section: values?.venue_sub_section || null,
    venue_address: values?.venue_address || '',
    capacity: values?.capacity ?? '',
    account_manager: values?.account_manager || {},
    fd_account_manager: values?.fd_account_manager || '',
    start_time: values?.start_time || '',
    end_time: values?.end_time || '',
    additional_contacts: values?.additional_contacts || [
      {
        contact_email: '',
        contact_name: '',
        contact_phone_number: '',
        country_code: '+1',
        country_iso_code: '',
        formated_contact_phone_number: '',
        extension: ''
      }
    ],
    venue_kitchen_detail: values?.venue_kitchen_detail || {
      power_available: '',
      running_water: '',
      kitchen_availability: null,
      cooking_equipments: '',
      rental_equipments: '',
      special_equipments: ''
    },
    venue_detail: values?.venue_detail || {
      alcohol_bar_venue_in_charge: '',
      alcohol_bar_setup_requirements: '',
      setup_duty_requirements: '',
      closing_duty_requirements: '',
      loading_dock: '',
      parking: '',
      loading_parking_additional_information: '',
      coat_check: '',
      coat_check_additional_information: '',
      staff_additional_restriction: '',
      landmark_fees: '',
      additional_notes: ''
    }
  };
};

export const VenueSubSections = [
  {
    id: 'Venue',
    label: 'Venue',
    value: 'Venue'
  },
  {
    id: 'Private',
    label: 'Private',
    value: 'Private'
  }
];

export enum VENUE_CREATE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  DRAFT = 2
}

export const addEditVenue = async (values: IVenueDataType, id?: any) => {
  try {
    const method = id //&& values?.status !== VENUE_CREATE_STATUS.DRAFT
      ? services.put(`/venues/update`, values)
      : services.post(`/venues/create`, values);
    const res = (await method) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const removeAdditionalContact = async (contactId: string | number) => {
  try {
    const res = (await services.delete(`/venues/delete-additional-contact/${contactId}`)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    // return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
    return { error: 'Something went wrong!' };
  }
};

export const getAccountManager = async (key: string) => {
  try {
    const res = (await services.get(`/employees/get-account-manager/${key}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
