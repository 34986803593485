import { MinusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { TextAreaInput } from '@atom/TextArea';
import TimePickerComponent from '@atom/TimePicker';
import { Col, Row } from 'antd';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';

interface TimingCardProps {
  item: any;
  index: number;
  touched: any;
  errors: any;
  event_time_line: any;
  setFieldValue: (key: string, value: any) => {};
  handleBlur: () => void;
  onDropCard: (item: any, monitor: any) => void;
  moveCard: (dragIndex: number, hoverIndex: number, values: any) => void;
}

const TimingCard = (props: TimingCardProps) => {
  const { item, index, touched, errors, event_time_line, setFieldValue, handleBlur } = props;
  const [timelineItem, setTimelineItem] = useState<any>({});

  useEffect(() => {
    if (item.id || item.tempId) {
      setTimelineItem({ ...item });
    }
  }, [item.id, item.tempId]);

  const handleRemove = () => {
    setFieldValue(
      'event_time_line',
      event_time_line?.filter((i: any) =>
        item?.id ? i?.id != item?.id : i?.tempId != item?.tempId
      )
    );
    if (item?.id) {
      // delete from db logic
    }
  };

  const handleChange = (value: any, formikKey: string, key: string) => {
    setTimeout(() => {
      setFieldValue(formikKey, value);
    }, 200);
    setTimelineItem({ ...timelineItem, [key]: value });
  };

  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col md={13} xs={24}>
          <Label text="Name" isMandatory={false} />
          <Input
            id={`event_time_line[${index}].name`}
            name={`event_time_line[${index}].name`}
            placeholder="Name"
            error={
              touched['event_time_line']?.[index]?.name && errors['event_time_line']?.[index]?.name
            }
            value={timelineItem?.name}
            onChange={(e: any) =>
              handleChange(e.target.value, `event_time_line[${index}].name`, 'name')
            }
            onBlur={handleBlur}
          />
        </Col>
        <Col md={5} xs={11}>
          <Label text="Start Time" isMandatory={false} />
          <TimePickerComponent
            id={`event_time_line[${index}].start_time`}
            name={`event_time_line[${index}].start_time`}
            use12Hours={true}
            format="h:mm A"
            placeholder={'Type Time'}
            value={timelineItem?.start_time}
            onChange={(e: any) =>
              handleChange(e, `event_time_line[${index}].start_time`, 'start_time')
            }
            onSelect={(e: Moment) =>
              handleChange(
                e.format('hh:mm A'),
                `event_time_line[${index}].start_time`,
                'start_time'
              )
            }
            error={
              touched['event_time_line']?.[index]?.start_time &&
              errors['event_time_line']?.[index]?.start_time
            }
          />
        </Col>
        <Col md={5} xs={11}>
          <Label text="End Time" isMandatory={false} />
          <TimePickerComponent
            id={`event_time_line[${index}].end_time`}
            name={`event_time_line[${index}].end_time`}
            use12Hours={true}
            format="h:mm A"
            placeholder={'Type Time'}
            value={timelineItem?.end_time}
            onChange={(e: any) => handleChange(e, `event_time_line[${index}].end_time`, 'end_time')}
            onSelect={(e: Moment) =>
              handleChange(e.format('hh:mm A'), `event_time_line[${index}].end_time`, 'end_time')
            }
            error={
              touched['event_time_line']?.[index]?.end_time &&
              errors['event_time_line']?.[index]?.end_time
            }
          />
        </Col>
        <Col xs={2} lg={1} style={{ paddingTop: '48px' }}>
          {event_time_line?.length > 1 && (
            <Button variant="ghost" icon={<MinusCircleOutlined />} onClick={() => handleRemove()} />
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={23} xs={24}>
          <Label text="Description" isMandatory={false} />
          <TextAreaInput
            id={`event_time_line[${index}].description`}
            name={`event_time_line[${index}].description`}
            placeholder={'Additional Notes'}
            value={timelineItem?.description}
            onChange={(e: any) =>
              handleChange(e.target.value, `event_time_line[${index}].description`, 'description')
            }
            error={
              touched['event_time_line']?.[index]?.description &&
              errors['event_time_line']?.[index]?.description
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default TimingCard;
