import CenterSpiner from '@atom/CenterSpiner';
import { eventTableColumns } from './columns';
import DataTable from '@organism/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router';
import { getEvents } from './helper';
import { useSelector } from 'react-redux';

const EventsTab = () => {
  const navigate = useNavigate();
  const user = useSelector((state: { user: { isAdmin: boolean } }) => state.user);
  const { isAdmin } = user;
  const columns = eventTableColumns(navigate, isAdmin);
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);

  const { data: eventsData, isValidating: isLoadingRecords } = useSWR(
    [`/customer/events?page=${currentPage}`, id],
    getEvents,
    {
      revalidateOnFocus: false
    }
  );

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isLoadingRecords ? (
        <CenterSpiner />
      ) : (
        <>
          <DataTable
            className="fixTD"
            columns={columns}
            showLoadMore={!!eventsData?.nextPageUrl}
            dataSource={eventsData?.data}
            nextPageURL={eventsData?.nextPageUrl}
            pagination={{
              pageSize: eventsData?.per_page,
              total: eventsData?.total,
              current: eventsData?.current_page,
              onChange: handlePaginationChange
            }}
            scrollConfig={{
              y: eventsData?.data?.length ? 'calc(100vh - 350px)' : 'auto'
            }}
            renderEmpty={() => {
              return <p>No Events Found!</p>;
            }}
          />
        </>
      )}
    </>
  );
};

export default EventsTab;
