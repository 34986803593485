import CenterSpiner from '@atom/CenterSpiner';
import DataTable from '@organism/DataTable/DataTable';
import { showErrorToast, showToast } from '@utils/index';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
  FoodPrepListFilterTypes,
  FoodPreprationListTableTypes,
  MappedProductListDataTypes,
  ProductListDataTypes,
  SelectedSubcategoryType
} from '../types';
import { getFoodColumns, getMappedTableData, initFilterState } from './helper';
import ManageProductModal from './ManageProductModal';
import { getProductListDropdownData } from './helper';
import {
  getEventCodeList,
  getProductNamesData,
  getProductsList,
  submitManageProductData
} from '@services/foodPrepServices';
import { ViewTableCell } from './style';
import ConfirmModal from '@organism/ConfimationModal';
import { useParams } from 'react-router';

function FoodPreprationListTable(props: FoodPreprationListTableTypes) {
  const { tab } = useParams();
  const { currentPanel, startDate, endDate, callApi, setCallApi } = props;
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(false);
  const [productsList, setProductsList] = useState<Array<ProductListDataTypes>>([]);
  const [tableData, setTableData] = useState<Array<MappedProductListDataTypes>>([]);
  const [confirmPopUp, setconfirmPopUp] = useState<boolean>(false);
  const [dropdownData, setDropdownData] = useState<any>({});
  const [details, setDetails] = useState<any>();
  const [productList, setProductList] = useState([]);
  const [initialProductList, setInitialProductList] = useState([]);

  const [selectedSubcategoryData, setSelectedSubcategoryData] = useState<SelectedSubcategoryType>(
    {}
  );

  const [manageProduct, showManageProduct] = useState<boolean>(false);
  const [filters, setFilters] = useState<FoodPrepListFilterTypes>({
    ...initFilterState,
    start_date: startDate,
    end_date: endDate
  });
  let page = 1;

  const maxPerPage = 4;

  const getProductOptionsData = async () => {
    try {
      const res = await getProductNamesData();
      if (res?.success) {
        const formattedProducts = res?.data?.map(
          (product: { id: number; product_name: string }) => {
            return { id: product.id, name: product.product_name };
          }
        );
        setProductList(formattedProducts);
        setInitialProductList(formattedProducts);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something Went Wrong'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: error || 'Something Went Wrong'
      });
    }
  };

  const handleManageProduct = async (
    product_id: number | string,
    subcategory_id: number | string,
    event_id: number | string
  ) => {
    setSelectedSubcategoryData({ event_id, product_id, subcategory_id });
    showManageProduct(true);
  };

  const searchFilter = useCallback(
    debounce(async (filters: any) => {
      await updateTableData(filters);
    }, 500),
    []
  );

  const updateTableData = async (filters: any) => {
    setIsTableLoading(true);
    try {
      const newFilters = { ...filters, start_date: startDate, end_date: endDate };
      setFilters(newFilters);
      const res = await getProductsList(newFilters);
      if (res?.success) {
        const mappedData = getMappedTableData(res?.data);
        setTableData(mappedData);
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch list data'
      });
    }
    setIsTableLoading(false);
  };

  const getFromAndToIndex = (page: number, maxLength: number) => {
    const start = maxPerPage * (page - 1);
    const end = maxPerPage * page < maxLength ? maxPerPage * page : maxLength;
    return start > maxLength || end > maxLength ? undefined : { start, end };
  };

  const handleLoadMore = async () => {
    let tempPage = page + 1;
    const range = getFromAndToIndex(tempPage, productsList?.length);
    if (range) {
      const mappedData = getMappedTableData(productsList?.slice(0, range?.end));

      setTableData(mappedData);
      setFilters((prev: FoodPrepListFilterTypes) => ({ ...prev, page: tempPage }));
      page = tempPage;
    }
  };

  const updateDropdownData = async (filters: any) => {
    setIsDropdownLoading(true);
    try {
      const res: any = await getEventCodeList(filters);
      if (res?.data.length > 0) {
        const ddRes = await getProductListDropdownData(filters);
        setDropdownData({
          subCategoriesList: ddRes?.[0]?.success ? ddRes?.[0]?.data : [],
          headChefsList: ddRes?.[1]?.success ? ddRes?.[1]?.data : [],
          eventCodesList: res.data
        });
      } else {
        setDropdownData({
          subCategoriesList: [{ id: 0, name: 'No Sub Categories Found' }],
          headChefsList: [{ id: 0, name: 'No Head Chefs Found' }],
          eventCodesList: [{ id: 0, text: 'No Event Found' }]
        });
      }
      setIsDropdownLoading(false);
    } catch (error) {
      setIsDropdownLoading(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch dropdown data'
      });
    }
  };

  const onClear = (data: any) => {
    const { id, value } = data;

    const updatedFilters = { ...filters, [id]: value };
    setFilters({ ...updatedFilters });
    updateTableData({ ...updatedFilters });
  };

  useEffect(() => {
    if (!tab || tab === currentPanel) {
      updateDropdownData({ ...filters, start_date: startDate, end_date: endDate });
      getProductOptionsData();
      updateTableData({ ...filters, start_date: startDate, end_date: endDate });
      setFilters(initFilterState);
    }
  }, [tab, currentPanel]);

  useEffect(() => {
    if (callApi) {
      setCallApi(false);
      updateDropdownData({ ...filters, start_date: startDate, end_date: endDate });
      updateTableData({ ...filters, start_date: startDate, end_date: endDate });
    }
  }, [callApi]);

  const cols = getFoodColumns(
    filters,
    setFilters,
    dropdownData,
    handleManageProduct,
    searchFilter,
    updateTableData,
    tableData,
    onClear,
    productList,
    setProductList,
    initialProductList
  );

  if (isDropdownLoading) return <CenterSpiner />;

  const onSaveHandler = async (data: any) => {
    let tempArray: any = [];
    data?.product_sub_category_ingredients?.forEach((val: any, index: number) => {
      let a = {
        ingredient_id: val?.ingredient_id,
        is_completed: val?.is_completed,
        assignee_id: val?.assignee_id
      };
      tempArray[index] = a;
    });
    let obj = {
      event_id: data?.event_id,
      product_id: data?.product_id,
      product_subcategory_id: data?.product_subcategory_id,
      head_chef_id: data?.head_chef_id ?? null,
      is_assigning_all_event_product: data?.is_assigning_all_event_product,
      quantity: data?.quantity,
      packed_qty: data?.packed_qty,
      ingredients: tempArray
    };
    const result = await submitManageProductData(obj);
    if (result.success) {
      showToast({
        message: 'Save Successfully',
        description: result?.message
      });
      updateTableData({ ...filters, start_date: startDate, end_date: endDate });
      showManageProduct(false);
    } else {
      showErrorToast({ message: 'error', description: '' });
      showManageProduct(true);
    }
    setconfirmPopUp(false);
  };

  return (
    <>
      <ViewTableCell>
        <DataTable
          className="fixTD tableTD-fix"
          loading={isTableLoading}
          columns={cols}
          dataSource={tableData}
          infinity={true}
          onFetch={() => handleLoadMore()}
          renderEmpty={() => <div>No Data Found.</div>}
        />
      </ViewTableCell>
      {manageProduct && (
        <ManageProductModal
          setSelectedSubcategoryData={setSelectedSubcategoryData}
          selectedSubcategoryData={selectedSubcategoryData}
          manageProduct={manageProduct}
          showManageProduct={showManageProduct}
          dropdownData={dropdownData}
          setconfirmPopUp={setconfirmPopUp}
          details={details}
          setDetails={setDetails}
          onConfirmSaveHandler={onSaveHandler}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {confirmPopUp && (
        <ConfirmModal
          visible={confirmPopUp}
          onCancel={() => {
            onSaveHandler({ is_assigning_all_event_product: '1', ...details });
          }}
          title={`${details?.head_chef_id ? 'Assign' : 'Unassign'}ing Event Head Chef`}
          onOK={() => onSaveHandler({ is_assigning_all_event_product: '0', ...details })}
          okText={`${details?.head_chef_id ? 'Assign to' : 'Unassign from'} This Product`}
          cancelText={`${details?.head_chef_id ? 'Assign to' : 'Unassign from'} All Products`}
        >
          It seems you are {details?.head_chef_id ? 'Assign' : 'Unassign'}ing an Event Head Chef for
          this product. Do you want to {details?.head_chef_id ? 'assign' : 'unassign'} this Head
          Chef for all products of the same event?
        </ConfirmModal>
      )}
    </>
  );
}

export default FoodPreprationListTable;
