import TableHeader from '@atom/TableHeader';
import services from '@services/index';
import moment, { Moment } from 'moment';
import { ChangeEvent } from 'react';

export const getEventsColumns = (
  filterValue: any,
  onChange: (a: ChangeEvent) => void,
  onClear?: (data: any) => void
) => {
  const columns = [
    {
      title: (
        <TableHeader
          style={{ width: '100px' }}
          columnTitle="Code"
          name="code"
          value={filterValue.code ?? ''}
          placeholder="Code"
          onChange={onChange}
        />
      ),
      dataIndex: 'event_code',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '150px' }}
          columnTitle="Event Date"
          name="event_date"
          value={filterValue.event_date ?? ''}
          placeholder="Event Date"
          onChange={(e: any) => {
            localStorage.setItem('event_date', e);
            //@ts-ignore
            onChange({ target: { id: 'event_date', value: e } });
          }}
          isDate={true}
          onClear={onClear}
        />
      ),
      dataIndex: 'created_at',
      render: (data: any, row: any) => {
        return row.quotation?.event_dates?.event_date &&
          row.quotation?.event_dates?.event_date !== '0000-00-00'
          ? // ? moment(row.quotation?.event_dates?.event_date).format('ddd, MMMM D')
            moment(row.quotation?.event_dates?.event_date).format("ddd, MMM. DD'YY")
          : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '150px' }}
          columnTitle="Client"
          name="customer_name"
          value={filterValue.customer_name ?? ''}
          placeholder="Client"
          onChange={onChange}
          onClear={onClear}
        />
      ),
      dataIndex: 'quotation',
      render: (data: any) => {
        return data ? data?.customer?.name || '-' : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '200px' }}
          columnTitle="Event Name"
          name="event_name"
          value={filterValue.event_name ?? ''}
          placeholder="Event Name"
          onChange={onChange}
        />
      ),
      dataIndex: 'quotation',
      render: (data: any) => {
        return data ? data?.event_name || '-' : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '110px' }}
          columnTitle="Guests"
          name="guests"
          value={filterValue.guests ?? ''}
          placeholder="Guests"
          onChange={onChange}
        />
      ),
      dataIndex: 'quotation',
      render: (data: any) => {
        return data ? data?.total_guest || '-' : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '200px' }}
          columnTitle="Venue"
          name="venue_name"
          value={filterValue.venue_name ?? ''}
          placeholder="Venue"
          onChange={onChange}
        />
      ),
      dataIndex: 'quotation',
      render: (data: any) => {
        return data ? data?.venue_details?.venue_name || '-' : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '150px' }}
          columnTitle="Time"
          name="event_time"
          value={filterValue.event_time ?? ''}
          placeholder="Time"
          onChange={(e: any) => {
            localStorage.setItem('event_time', e);
            //@ts-ignore
            onChange({ target: { id: 'event_time', value: e } });
          }}
          onSelect={(e: Moment) => {
            localStorage.setItem('event_time', e.format('hh:mm A'));
            //@ts-ignore
            onChange({ target: { id: 'event_time', value: e.format('hh:mm A') } });
          }}
          isTime={true}
          onClear={onClear}
        />
      ),
      dataIndex: 'quotation',
      render: (data: any) => {
        return data
          ? `${data?.event_dates?.start_string?.toUpperCase() || ''} - ${
              data?.event_dates?.end_string?.toUpperCase() || ''
            }`
          : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '200px' }}
          columnTitle="Account Manager"
          name="account_manager"
          value={filterValue.account_manager ?? ''}
          placeholder="Account Manager"
          onChange={onChange}
        />
      ),
      dataIndex: 'account_manager',
      render: (data: any) => {
        return data ? data?.name : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '200px' }}
          columnTitle="All Kitchen Staff"
          name="kitchen_staff"
          value={filterValue.kitchen_staff ?? ''}
          placeholder="All Kitchen Staff"
          onChange={onChange}
        />
      ),
      dataIndex: 'kitchen_staffs',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '200px' }}
          columnTitle="All Operation Staff"
          name="event_staffs"
          value={filterValue.event_staffs ?? ''}
          placeholder="All Operation Staff"
          onChange={onChange}
        />
      ),
      dataIndex: 'event_staffs',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '170px' }}
          columnTitle="Event Manager"
          name="event_manager"
          value={filterValue.event_manager ?? ''}
          placeholder="Event Manager"
          onChange={onChange}
        />
      ),
      dataIndex: 'event_managers',
      render: (data: any) => {
        return data ? data : '-';
      }
    }
  ];

  return columns;
};

export const getEvents = async (page: number, data: any) => {
  try {
    const res: any = await services.post(`/events/list?page=${page || 1}`, { search_data: data });
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
