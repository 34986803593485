import * as yup from 'yup';

import { ResponseType } from '@pages/EmployeeManagementPage/helper';
import services from '@services/index';

// types
export interface DocumentsTabType {
  id: number | null;
  event_id: string;
  document_name: string;
  document_url: string;
  is_private: 0 | 1;
}

export interface EditDocumentListType {
  documents: DocumentsTabType[];
  event_id: string;
}

// api calls
export const getDocumentList = async (id: string): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/events/document?event_id=${id}`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateDocumentList = async (body: EditDocumentListType): Promise<ResponseType> => {
  try {
    const res = (await services.post(`/events/document`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    const key = Object.keys(error?.error?.error)?.[0];
    return {
      data: null,
      error: error?.error?.error
        ? error?.error?.error?.[key][0]
        : error?.error?.message || 'Something went wrong!'
    };
  }
};
