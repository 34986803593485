import {
  AccountManagerDropdownObjectType,
  CustomerDropdownObjectType,
  CustomerFormValuesTypeWithDraftStatus,
  VenueContactsDropdownObjectType,
  VenueDropdownObjectType
} from '@pages/QuoteManagementPage/types';
import { IVenueDataType } from '@pages/VenueManagementPage/types';
import services from '@services/index';
import {
  GetAccountManagers,
  GetCustomersList,
  GetVenueDetails,
  GetVenuesList
} from '@services/quoteServices';
import { showErrorToast } from '@utils/index';
import * as Yup from 'yup';

const phoneRegExp = /^[0-9]{10}$/;

export const FORM_INITIAL_VALUES: CustomerFormValuesTypeWithDraftStatus = {
  account_manager_id: null,
  customer_id: null,
  event_name: '',
  venue_id: null,
  venue_contacts: [],
  event_dates: {
    event_date: '',
    start_time: '',
    end_time: ''
  },
  parking_additional_information: '',
  adult_count: '',
  kids_count: '',
  is_customer_on_site_same_as_customer_primary_contact: true,
  customer_on_site_name: '',
  customer_on_site_email: '',
  customer_on_site_country_code: '',
  customer_on_site_phone_number: '',
  customer_on_site_extension: '',
  vendor_staff_count: '',
  total_guest: '0',
  from_event: 0,
  status: 0
};

export const NEW_CUSTOMER_FORM_INITIAL_VALUES = {
  name: '',
  email: '',
  country_code: '',
  phone_number: '',
  extension: '',
  customer_type: '1'
};

export const customerValidations = Yup.object().shape({
  name: Yup.string().required('The name field is required.'),
  email: Yup.string().email('Enter valid email field').nullable(),
  // email: Yup.string()
  //   .email('Enter valid email field')
  //   .required('The email  field is required')
  //   .nullable(),
  phone_number: Yup.string().matches(phoneRegExp, 'The phone number must be 10 digits').nullable(),
  country_code: Yup.string().nullable(),
  extension: Yup.string().nullable()
});

export const validationSchema = (isEvent?: boolean) =>
  Yup.object({
    account_manager_id: Yup.number().required('Select account manager').nullable(),
    customer_id: Yup.number().nullable().required('Please select a customer name'),
    event_name: isEvent
      ? Yup.string().nullable().required('Please select a event name')
      : Yup.string().nullable(),
    venue_id: Yup.number().nullable(),
    venue_contacts: Yup.array().nullable(),
    // venue_id: isEvent
    //   ? Yup.number().nullable()
    //   : Yup.number().nullable().required('Please select a venue'),
    // venue_contacts: isEvent
    //   ? Yup.array().nullable()
    //   : Yup.array()
    //       .of(Yup.number().required('Please select a contact'))
    //       .min(1, 'Please select at least one Venue Contact')
    //       .nullable(),
    event_dates: Yup.object({
      event_date: Yup.string().nullable(),
      start_time: Yup.string().nullable(),
      end_time: Yup.string().nullable()
    }),
    adult_count: Yup.number().typeError('You must specify a number').nullable(),
    kids_count: Yup.number().typeError('You must specify a number').nullable(),
    customer_on_site_name: Yup.string().nullable(),
    customer_on_site_email: Yup.string().nullable(),
    customer_on_site_phone_number: Yup.string()
      .matches(phoneRegExp, 'The venue contact phone number field must be 10 digits.')
      .nullable(),
    vendor_staff_count: Yup.number().typeError('You must specify a number').nullable(),
    total_guest: Yup.number().typeError('You must specify a number').nullable()
  });

export const fetchManagers = async (
  name: string = '',
  setAccountManagersList: React.Dispatch<React.SetStateAction<AccountManagerDropdownObjectType[]>>,
  setLoader?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoader && setLoader(true);
  const res: any = await GetAccountManagers(name);
  if (res?.success) {
    setAccountManagersList(res?.data);
  } else {
    showErrorToast({
      message: 'Failed!',
      description: 'Failed to fetch Managers List.'
    });
  }
  setLoader && setLoader(false);
};

export const fetchCustomersList = async (
  name: string = '',
  setCustomersList: React.Dispatch<React.SetStateAction<CustomerDropdownObjectType[]>>,
  setLoader?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoader && setLoader(true);
  const res: any = await GetCustomersList(name);
  if (res?.success) {
    setCustomersList(res?.data);
    setLoader && setLoader(false);
    return res?.data;
  } else {
    showErrorToast({
      message: 'Failed!',
      description: 'Failed to fetch Customers List.'
    });
    setLoader && setLoader(false);
  }
};

export const fetchVenuesList = async (
  name: string = '',
  setVenuesList: React.Dispatch<React.SetStateAction<VenueDropdownObjectType[]>>,
  setLoader?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoader && setLoader(true);
  const res: any = await GetVenuesList(name);
  if (res?.success) {
    setVenuesList(res?.data);
  } else {
    showErrorToast({
      message: 'Failed',
      description: 'Failed to fetch Venues List.'
    });
  }
  setLoader && setLoader(false);
};

export const fetchVenueDetails = async (
  id: string | number,
  setVenueContactList: React.Dispatch<React.SetStateAction<VenueContactsDropdownObjectType[]>>,
  setLoader?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoader && setLoader(true);
  const res: any = await GetVenueDetails(id);
  if (res?.success) {
    setVenueContactList(res?.data?.venue_contacts);
    return res?.data?.venue_details?.parking_additional_information ?? "";
  }
  setLoader && setLoader(false);
};

export const addCustomer = async (values: any) => {
  try {
    const res = await services.post('/customer/save', { ...values });
    return res;
  } catch (err: any) {
    //@ts-ignore
    return {
      error:
        err && err.error.error
          ? err?.error?.error?.email || err.error.error
          : 'Something went wrong!',
      success: false
    };
  }
};

export const addVenue = async (values: IVenueDataType) => {
  try {
    const method = services.post(`/venues/create`, values);
    const res = (await method) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

//convert 'DD-MMM-YYYY' format date to 'YYYY-MM-DD'
export const convertDateForQuoteDate = (value: string) => {
  const tempDate: Array<string | number> = value.split('-');
  const monthsArray: Array<string> = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  for (let j = 0; j < monthsArray.length; j++) {
    if (tempDate[1] == monthsArray[j]) {
      tempDate[1] = monthsArray.indexOf(monthsArray[j]) + 1;
    }
  }
  if (Number(tempDate[1]) < 10) {
    tempDate[1] = '0' + tempDate[1];
  }
  const formattedDate = tempDate[2] + '-' + tempDate[1] + '-' + tempDate[0];
  return formattedDate;
};
