import * as yup from 'yup';
import services from '@services/index';

import { ResponseType } from '@pages/EmployeeManagementPage/helper';

// types
export interface GeneralDocType {
  id: number | null;
  document_name: string;
  document_url: string;
  manager_view: 0 | 1;
  employee_view: 0 | 1;
  created_at?: string;
  updated_at?: string;
}

export interface EditGeneralDocType {
  'general-documents': GeneralDocType[];
}

// api calls
export const getGeneralDocList = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/events/general-documents`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateGeneralDocList = async (body: EditGeneralDocType): Promise<ResponseType> => {
  try {
    const res = (await services.post(`/events/general-documents`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    const key = error?.error?.error ? Object.keys(error?.error?.error)?.[0] : undefined;
    return {
      data: null,
      error: key ? error?.error?.error?.[key][0] : error?.error?.message || 'Something went wrong!'
    };
  }
};
