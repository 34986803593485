import { MinusCircleFilled, MinusCircleOutlined } from '@ant-design/icons';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import InputPhone from '@atom/InputPhone';
import { Col, Row } from 'antd';
import React from 'react';

function RepresentativeItem(props: any) {
  const { item, handleChange, errors, touched, setFieldValue, onRemove, index } = props;
  const itemString = `representatives[${index}]`;
  return (
    <Row gutter={24}>
      <Col md={6} xs={24}>
        <Label className="hide-full-device" text={'Name'} />
        <Input
          id={`${itemString}.name`}
          name={`${itemString}.name`}
          placeholder={'Type Name'}
          value={item.name}
          onChange={handleChange}
          error={errors?.name}
        />
      </Col>
      <Col md={6} xs={24}>
        <Label className="hide-full-device" text={'Email'} />
        <Input
          id={`${itemString}.email`}
          name={`${itemString}.email`}
          placeholder={'Type Email'}
          value={item.email}
          onChange={handleChange}
          error={errors?.email}
        />
      </Col>
      <Col lg={10} xs={20}>
        <Label className="hide-full-device" text={'Phone Number'} />
        <InputPhone
          id={'phone_number'}
          name={'phone_number'}
          value={{
            code: item.country_code || '+1',
            phone: item.phone_number,
            ext: item.extension
          }}
          onChange={(e: any) => {
            setFieldValue(`${itemString}.country_code`, e.code);
            setFieldValue(`${itemString}.phone_number`, e.phone);
            setFieldValue(`${itemString}.extension`, e.ext);
          }}
          error={errors?.phone_number}
        />
      </Col>
      <Col lg={2} xs={4} className="centerPosition">
        <MinusCircleOutlined
          className="xs-remove-btn"
          style={{ fontSize: 24, cursor: 'pointer' }}
          onClick={() => onRemove(item)}
        />
      </Col>
    </Row>
  );
}

export default RepresentativeItem;
