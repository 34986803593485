import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import { SearchBar } from '@atom/Search';
import React, { ChangeEvent, FC } from 'react';

interface TableHeaderProps {
  columnTitle: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (a: ChangeEvent) => void;
}

const TableHeader: FC<TableHeaderProps> = ({
  columnTitle = '',
  name = '',
  value = '',
  placeholder = '',
  onChange
}) => {
  return (
    <Flex direction="column" style={{ width: '240px' }} gap={15}>
      <p>{columnTitle}</p>
      <SearchBar id={name} value={value} placeholder={placeholder} onChange={onChange} />
    </Flex>
  );
};

export default TableHeader;
