import services from '@services/index';
import { IInitialFiltersType, IVenueListFilterType } from './types';

export enum VENUE_DETAILS_TABS {
  INFO = 'info',
  DOCUMENTS = 'documents'
}

export const VenueKitchenDetails = [
  {
    label: 'Full Catering Kitchen',
    value: 1
  },
  {
    label: 'Partial In House',
    value: 2
  },
  {
    label: 'Residential',
    value: 3
  },
  {
    label: 'Satellite',
    value: 4
  }
];

export const VenueAddEditDummyData = {
  venue_name: 'Venue name',
  status: 2,
  venue_sub_section: 'VENUE SUB SECTION',
  venue_address: 'VENUE ADDRESS',
  capacity: '2323',
  registered_at: 'October 13, 2022',
  documents: [],
  account_manager: {
    email: 'saiprasad.veturekar@torinit.ca',
    formated_phone_number: '123456789',
    id: 477,
    name: 'Lisa Capre',
    phone_number: 123456789,
    registered_at: null
  },
  fd_account_manager: 477,
  start_time: '',
  end_time: '',
  additional_contacts: [
    {
      contact_email: 'frank@historicalwaltonhouse.com',
      contact_name: 'Frank Blanco',
      contact_phone_number: 7863569435,
      country_code: '+1',
      country_iso_code: 'US',
      formated_contact_phone_number: '786-356-9435',
      id: 1727,
      venue_id: 1459
    }
  ],
  venue_kitchen_detail: {
    power_available: '1',
    running_water: '1',
    kitchen_availability: '4',
    kitchen_availability_info: {
      created_at: null,
      id: 44,
      status_id: 4,
      status_name: 'Satellite',
      status_type: 'VENUE_KITCHEN_AVAIL'
    },
    cooking_equipments: 'cooking_equipments',
    rental_equipments: 'rental_equipments',
    special_equipments: 'special_equipments'
  },
  venue_detail: {
    alcohol_bar_venue_in_charge: '1',
    alcohol_bar_setup_requirements: 'alcohol_bar_venue_in_charge',
    setup_duty_requirements: 'setup_duty_requirements',
    closing_duty_requirements: 'closing_duty_requirements',
    loading_dock: '1',
    parking: '1',
    loading_parking_additional_information: 'loading_parking_additional_information',
    coat_check: '1',
    coat_check_additional_information: 'coat_check_additional_information',
    staff_additional_restriction: 'staff_additional_restriction',
    landmark_fees: '10.00',
    on_net_total: 0,
    food_fees: '10.00',
    alcohol_soft_bar_fees: '10.00',
    labour_fees: '10.00',
    additional_notes: 'additional_notes'
  }
};

export const getVenueList = async (
  page: number,
  status: number | null,
  data: IVenueListFilterType
) => {
  try {
    const res: any = await services.post(
      `/venues/list${status !== null ? `/${status}` : ``}?page=${page || 1}`,
      data
    );
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const initialFilters: IVenueListFilterType = {
  venue_name: '',
  venue_address: '',
  contact_email: '',
  contact_phone_number: '',
  type: 'all',
  page: 1
};

export const getTableStatus = (status: string) => {
  switch (status) {
    case 'in-active':
      return 0;
    case 'active':
      return 1;
    case 'drafts':
      return 2;
    default:
      return null;
  }
};

export const mapTableData = (data: Array<any>) => {
  const mappedData =
    data?.map((item: any) => ({
      id: item?.id ?? '-',
      venue_name: item?.venue_name || '-',
      status: item?.status ?? '-',
      venue_sub_section: item?.venue_sub_section || '-',
      contact_email: item?.first_additional_contacts?.contact_email || '-',
      formated_contact_phone_number:
        item?.first_additional_contacts?.formated_contact_phone_number ?? '-'
    })) || [];
  return mappedData;
};

export const getVenue = async (id: any) => {
  try {
    const res = (await services.get(`/venues/view/${id}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const changeVenueStatus = async (id: number | string) => {
  try {
    const res = (await services.get(`/venues/change-status/${id}`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
