import ModalComponent from '@organism/Modal/Modal';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { H2Typography, H4Typography } from '@constant/typography/Typography';
import { showErrorToast, showToast } from '@utils/index';
import { confirmEvent, releaseEvent } from '../helper';
import { STATUS } from '../constants';

const EventModal = (props: any) => {
  const {
    isModalVisible,
    onCancel,
    title,
    subTitle,
    isConfirmUnconfirmEvent = false,
    setIsModalVisible,
    status = STATUS.CONFIRM,
    getEventDetailMethod,
    isRelease
  } = props;
  const { eventId } = useParams();

  const onReleaseEvent = async () => {
    try {
      setIsModalVisible(false);
      const res = await releaseEvent(eventId);
      if (res?.success) {
        return showToast({
          message: 'Success!',
          description: res?.message
        });
      } else {
        return showErrorToast({
          message: 'Failed!',
          description: res?.message || 'Failed to release event details'
        });
      }
    } catch (error) {
      setIsModalVisible(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to release event details'
      });
    }
  };

  const onConfirmEvent = async () => {
    try {
      setIsModalVisible(false);
      const res = await confirmEvent(eventId, status);
      if (res?.status) {
        getEventDetailMethod && getEventDetailMethod(eventId);
        return showToast({
          message: 'Success!',
          description: `Event ${status === STATUS.CONFIRM ? 'Confirmed!' : 'Unconfirmed!'}`
        });
      } else {
        return showErrorToast({
          message: 'Failed!',
          description: res?.message || 'Failed to release event details'
        });
      }
    } catch (error) {
      setIsModalVisible(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to release event details'
      });
    }
  };

  return (
    <ModalComponent
      visible={isModalVisible}
      onCancel={onCancel}
      modalWidth="562px !important"
      align="left !important"
    >
      <Row gutter={24} className="centerd">
        <Col lg={24} xs={24}>
          <Flex direction="column" top={40} gap={16} alignItems={'center'}>
            <H2Typography style={{ textAlign: 'center' }}>{title}</H2Typography>
            <H4Typography style={{ fontWeight: '400', textAlign: 'center' }}>
              {subTitle}
            </H4Typography>
            <Flex top={10} gap={16} className="direction-xs-column fix-mobile">
              {(!isConfirmUnconfirmEvent || isRelease) && (
                <Button text={'Yes, Continue'} onClick={onReleaseEvent} />
              )}
              <Button text={'Cancel'} variant={'secondary'} onClick={onCancel} />
              {isConfirmUnconfirmEvent && !isRelease && (
                <Button
                  text={status === STATUS.CONFIRM ? 'Confirm Event' : 'Unconfirm Event'}
                  onClick={onConfirmEvent}
                />
              )}
            </Flex>
          </Flex>
        </Col>
      </Row>
    </ModalComponent>
  );
};

export default EventModal;
