import { Button } from '@atom/Buttons';
import Checkbox from '@atom/Checkbox';
import { Flex } from '@atom/Flex';
import TableHeader from '@atom/TableHeader';
import { TagComponent } from '@atom/Tag';
import moment from 'moment';
import AlertCircleIcon from '@assets/alert-circle.svg';
import { getMeasurements } from '@pages/MenuManagementPage/AddEditPages/AddEditProduct/SubCategory/helper';
import { getEventCodeList } from '@services/foodPrepServices';
import { Tooltip } from 'antd';

export const initFilterState = {
  ingredient_name: '',
  ingredient_amount: '',
  event_code: '',
  is_done: '',
  updated_by_name: '',
  updated_at: '',
  start_date: '',
  end_date: '',
  assignee_id: '',
  assignee_name: '',
  unit: '',
  measurement_id: ''
};

export const initalEditPrepListData = {
  event_id: undefined,
  product_id: undefined,
  product_subcategory_id: undefined,
  ingredients: [],
  is_assign_to_all_event: undefined
};

export const getMappedTableData = (data: any) => {
  let tableData: any = [];
  data?.forEach((i: any) => {
    tableData.push({
      ingredient_id: i?.ingredient_id,
      ingredient_name: i?.ingredient_name,
      is_special_ingredient: i?.is_special_ingredient,
      prep_list_id: i?.event?.[0]?.prep_list_id,
      measurement_id: i?.event?.[0]?.measurement_id,
      unit: i?.event?.[0]?.measurement_name,
      ingredient_amount: i?.event?.[0]?.ingredient_amount,
      is_done: i?.event?.[0]?.is_done,
      assignee_id: i?.event?.[0]?.assignee_id,
      assignee_name: i?.event?.[0]?.assignee_name,
      quotation_id: i?.event?.[0]?.quotation_id,
      event_id: i?.event?.[0]?.event_id,
      event_code: i?.event?.[0]?.event_code,
      updated_by_name: i?.event?.[0]?.updated_by_name,
      updated_at: i?.event?.[0]?.updated_at,
      product_id: i?.event?.[0]?.product_id,
      product_subcategory_id: i?.event?.[0]?.product_subcategory_id
    });
    for (let k = 1; k < i?.event?.length; k++) {
      let j = i?.event?.[k];
      tableData.push({
        ingredient_id: i?.ingredient_id,
        ingredient_name: '',
        is_special_ingredient: 0,
        prep_list_id: j?.prep_list_id,
        measurement_id: j?.measurement_id,
        unit: j?.measurement_name,
        ingredient_amount: j?.ingredient_amount,
        is_done: j?.is_done,
        assignee_id: j?.assignee_id,
        assignee_name: j?.assignee_name,
        quotation_id: j?.quotation_id,
        event_id: j?.event_id,
        event_code: j?.event_code,
        updated_by_name: j?.updated_by_name,
        updated_at: j?.updated_at,
        product_id: i?.event?.[0]?.product_id,
        product_subcategory_id: i?.event?.[0]?.product_subcategory_id
      });
    }
    tableData.push({
      ingredient_id: '',
      ingredient_name: '',
      is_special_ingredient: 0,
      prep_list_id: '',
      measurement_id: '',
      unit: i?.event?.[0]?.measurement_name,
      ingredient_amount: i?.sub_total,
      is_done: '',
      assignee_id: '',
      assignee_name: (
        <div style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>SUBTOTAL</div>
      ),
      quotation_id: '',
      event_id: '',
      event_code: '',
      updated_by_name: '',
      updated_at: '',
      is_subtotal: true
    });
  });

  return tableData;
};

const sortingOption = [
  { id: 1, title: 'Ascending' },
  { id: 2, title: 'Descending' }
];

const handleUpdateFilter = (
  filters: any,
  setFilters: (filters: any) => void,
  key: string,
  value: any,
  fetchData: (prepListId: number, filters: any) => void,
  tableData: Array<any>,
  prepListId: number,
  assigneeList?: any,
  setAssigneeList?: (e: any) => void,
  initialAssigneeList?: any,
  ingredientList?: any,
  setIngredientList?: (e: any) => void,
  initialIngredientList?: any
) => {
  setIngredientList &&
    setIngredientList(() => {
      if (value) {
        return initialIngredientList?.filter((item: any) =>
          item?.name?.toLowerCase()?.startsWith(value?.toLowerCase())
        );
      } else {
        return initialIngredientList;
      }
    });

  setAssigneeList &&
    setAssigneeList(() => {
      if (value) {
        return initialAssigneeList?.filter((item: any) =>
          item?.name?.toLowerCase()?.startsWith(value?.toLowerCase())
        );
      } else {
        return initialAssigneeList;
      }
    });

  const newFilters = { ...filters, [`${key}`]: value };
  setFilters(newFilters);
  fetchData(prepListId, newFilters);
};

export const getFoodListColumns = (
  filter: any,
  setFilters: (filters: any) => void,
  dropdownData: any,
  getProductsTableData: (prepListId: number, filters: any) => void,
  isEditing: (a: boolean) => boolean,
  edit: (a: any, index: number) => void,
  tableData: Array<any>,
  prepListId: number,
  save: (a: any) => void,
  onClear: (filters: any) => void,
  searchFilter: (prepListId: number, filters: any) => void,
  assigneeList: any,
  setAssigneeList: (e: any) => void,
  ingredientList: any,
  setIngredientList: (e: any) => void,
  initialAssigneeList: any,
  initialIngredientList: any,
  handleSpecialIngredient: (id: number) => void,
  handleClick?: React.MouseEventHandler<HTMLDivElement>
) => {
  const columns = [
    {
      title: (
        <TableHeader
          columnTitle="Ingredient Name"
          isSearchable
          id="ingredient_name"
          name="ingredient_name"
          keyValue={'name'}
          options={ingredientList}
          placeholder={'Ingredient'}
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'ingredient_name',
              e?.name,
              searchFilter,
              tableData,
              prepListId
            )
          }
          onSearch={(e: any) => {
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'ingredient_name',
              e,
              searchFilter,
              tableData,
              prepListId,
              ingredientList,
              setIngredientList,
              initialIngredientList
            );
          }}
          labelValue={'name'}
          value={
            ingredientList?.find(
              (e: any) => !!filter?.ingredient_name && e?.name === filter?.ingredient_name
            ) || filter?.ingredient_name
          }
          clearButton={true}
          onClear={onClear}
          listHeight={150}
        />
      ),
      minWidth: '180px',
      width: '25%',
      dataIndex: 'ingredient_name',
      render: (data: any, row: any) => {
        const isSpecialIngredient = row?.is_special_ingredient;
        return (
          <span
            onClick={() => isSpecialIngredient && handleSpecialIngredient(row?.ingredient_id)}
            style={{
              textDecoration: isSpecialIngredient ? 'underline' : '',
              cursor: isSpecialIngredient ? 'pointer' : 'default'
            }}
          >
            {data}
          </span>
        );
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Event Code"
          name="event_id"
          value={filter?.event_id || undefined}
          placeholder="Event Code"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'event_id',
              e,
              getProductsTableData,
              tableData,
              prepListId
            )
          }
          isSelect
          options={dropdownData?.eventCodesList?.map((eventCode: any) => {
            return { id: eventCode.id, title: eventCode.text };
          })}
          clearButton={true}
          onClear={onClear}
          listHeight={150}
        />
      ),
      dataIndex: 'event_code',
      width: '11%',
      render: (data: any, row: any) => {
        return <TagComponent text={row?.event_code} />;
      }
    },
    {
      title: (
        <TableHeader
          columnTitle={
            <>
              {'Assignee'}
              <Tooltip title="Kitchen staff name">
                <img
                  src={AlertCircleIcon}
                  alt="alert"
                  width="20px"
                  style={{ verticalAlign: 'bottom', marginLeft: '10px' }}
                />
              </Tooltip>
            </>
          }
          isSearchable
          id="assignee_name"
          name="assignee_name"
          keyValue={'name'}
          options={assigneeList}
          placeholder={'Assignee'}
          onClick={handleClick}
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'assignee_name',
              e?.name,
              searchFilter,
              tableData,
              prepListId
            )
          }
          onSearch={(e: any) => {
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'assignee_name',
              e,
              searchFilter,
              tableData,
              prepListId,
              assigneeList,
              setAssigneeList,
              initialAssigneeList
            );
          }}
          labelValue={'name'}
          value={
            assigneeList?.find((e: any) => e?.name === filter?.assignee_name) ||
            filter?.assignee_name
          }
          clearButton={true}
          onClear={onClear}
          listHeight={150}
        />
      ),
      dataIndex: 'assignee_name',
      width: '12%',
      editable: true,
      render: (data: any, row: any) => {
        return row?.assignee_name || row?.is_subtotal ? row?.assignee_name : 'Unassigned';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Amount"
          name="sort_by_amount"
          value={filter?.sort_by_amount || undefined}
          placeholder="Sort"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'sort_by_amount',
              e,
              getProductsTableData,
              tableData,
              prepListId
            )
          }
          isSelect
          options={sortingOption}
          clearButton={true}
          onClear={onClear}
        />
      ),
      editable: true,
      dataIndex: 'ingredient_amount',
      render: (data: any, row: any) => {
        return row?.ingredient_amount;
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Unit"
          name="measurement_id"
          value={filter?.measurement_id || undefined}
          placeholder="Unit"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'measurement_id',
              e,
              getProductsTableData,
              tableData,
              prepListId
            )
          }
          isSelect
          options={dropdownData?.measurementsList?.map((measurmentCode: any) => {
            return { id: measurmentCode.id, title: measurmentCode.name };
          })}
          clearButton={true}
          onClear={onClear}
          listHeight={150}
        />
      ),
      dataIndex: 'unit',
      render: (data: any, row: any) => {
        return row?.unit;
      }
    },
    {
      title: (
        <div className="header-align">
          <p>{'Done'}</p>
          <Flex direction="column">
            <Checkbox disabled={true} />
          </Flex>
        </div>
      ),
      editable: true,
      dataIndex: 'done',
      width: '1%',
      className: 'dataTd',
      render: (data: any, row: any) => {
        return !row?.is_subtotal && <Checkbox checked={row?.is_done ? true : false} />;
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Last Edited By"
          isSearchable
          id="updated_by_name"
          name="updated_by_name"
          keyValue={'name'}
          options={assigneeList}
          placeholder={'User'}
          onClick={handleClick}
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'updated_by_name',
              e?.name,
              searchFilter,
              tableData,
              prepListId
            )
          }
          onSearch={(e: any) => {
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'updated_by_name',
              e,
              searchFilter,
              tableData,
              prepListId,
              assigneeList,
              setAssigneeList,
              initialAssigneeList
            );
          }}
          labelValue={'name'}
          value={
            assigneeList?.find((e: any) => e?.name === filter?.updated_by_name) ||
            filter?.updated_by_name
          }
          clearButton={true}
          onClear={onClear}
          listHeight={150}
        />
      ),
      dataIndex: 'updated_by_name',
      render: (data: any, row: any) => {
        return row?.updated_by_name || row?.is_subtotal ? row?.updated_by_name : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Last Edited"
          name="sort_by_date"
          value={filter?.sort_by_date || undefined}
          placeholder="Sort"
          onChange={(e: any) =>
            handleUpdateFilter(
              { ...filter },
              setFilters,
              'sort_by_date',
              e,
              getProductsTableData,
              tableData,
              prepListId
            )
          }
          isSelect
          options={sortingOption}
          clearButton={true}
          onClear={onClear}
        />
      ),
      dataIndex: 'updated_at',
      render: (data: any, row: any) => {
        return (
          <Flex direction="column" alignItems="center" style={{ minWidth: '170px' }}>
            {row?.updated_at && !row?.is_subtotal
              ? moment(row?.updated_at).format('MMM DD, YYYY, h:mm a')
              : row?.is_subtotal
              ? ''
              : '-'}
          </Flex>
        );
      }
    },
    {
      title: (
        <>
          <p>{'Action'}</p>
          <Button text={'Action'} isDisable />
        </>
      ),
      render: (data: any, row: any, index: number) => {
        const editable = isEditing(row);
        return (
          !row?.is_subtotal && (
            <Button
              size={'small'}
              variant="secondary"
              text={!editable ? 'Edit' : 'save'}
              className="cancel-btn"
              onClick={() => (editable ? save(row) : edit(row, index))}
            />
          )
        );
      }
    }
  ];

  return columns;
};

export const getConfirmationModalContent = (assignee: string, ingredient: string) => {
  const content = {
    heading: 'Assigning Ingredient Staff',
    message: (
      <span>
        Would you look like <span style={{ color: '#FD7E14' }}>{assignee}</span> to be responsible
        for all <span style={{ color: '#FD7E14' }}>{ingredient}</span> in all events?
      </span>
    ),
    okText: 'Assign to this event',
    cancelText: 'Assign to all events'
  };
  return content;
};

export const getPrepListDropdownData = async (filter: any) => {
  return Promise.allSettled([getEventCodeList(filter), getMeasurements()]);
};

export const mapDataToSave = (data: any, is_assign_to_all_event: number) => {
  const dataToSet: any = {
    is_assign_to_all_event: is_assign_to_all_event,
    event_id: data?.event_id,
    ingredient_id: data?.ingredients?.[0]?.ingredient_id,
    assignee_id: data?.ingredients?.[0]?.assignee_id,
    is_completed: data?.ingredients?.[0]?.is_completed,
    amount: Number(data?.ingredients?.[0]?.amount)
  };
  if (is_assign_to_all_event) {
    dataToSet['start_date'] = data?.start_date;
    dataToSet['end_date'] = data?.end_date;
  }
  return dataToSet;
};
