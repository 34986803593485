import { formatCurrency } from '@utils/format';
import { CostLabel, SubtotalAndCostDiv } from './style';

export const getMealPlanColumns = (isEventData: boolean, isAdmin: boolean) => {
  const columns: any[] = isEventData
    ? [
        {
          title: 'PRODUCT NAME',
          width: '17%',
          dataIndex: 'product_name',
          render: (data: any, record: any) => {
            return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
          }
        },
        {
          title: 'QTY',
          width: '11%',
          dataIndex: 'qty',
          render: (data: any, record: any) => {
            return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
          }
        }
      ]
    : [
        {
          title: 'PRODUCT NAME',
          width: isEventData ? '11%' : '17%',
          dataIndex: 'product_name',
          render: (data: any, record: any) => {
            return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
          }
        },
        {
          title: 'QTY',
          width: '11%',
          dataIndex: 'qty',
          render: (data: any, record: any) => {
            return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
          }
        },
        {
          title: 'RATE',
          width: '11%',
          dataIndex: 'rate',
          render: (data: any) => {
            return data && data === 'SUBTOTAL' ? (
              <SubtotalAndCostDiv>{data}</SubtotalAndCostDiv>
            ) : data ? (
              `${formatCurrency(data)}`
            ) : (
              '-'
            );
          }
        },
        {
          title: 'COST',
          width: '11%',
          dataIndex: 'cost',
          render: (data: any, record: any) => {
            return record && record?.rate === 'SUBTOTAL' ? (
              <SubtotalAndCostDiv>{formatCurrency(data)}</SubtotalAndCostDiv>
            ) : data ? (
              `${formatCurrency(data)}`
            ) : (
              '-'
            );
          }
        }
      ];

  if (isAdmin && isEventData) {
    columns.push(
      {
        title: 'RATE',
        width: '11%',
        dataIndex: 'rate',
        render: (data: any) => {
          return data && data === 'SUBTOTAL' ? (
            <SubtotalAndCostDiv>{data}</SubtotalAndCostDiv>
          ) : data ? (
            `${formatCurrency(data)}`
          ) : (
            '-'
          );
        }
      },
      {
        title: 'COST',
        width: '11%',
        dataIndex: 'cost',
        render: (data: any, record: any) => {
          return record && record?.rate === 'SUBTOTAL' ? (
            <SubtotalAndCostDiv>{formatCurrency(data)}</SubtotalAndCostDiv>
          ) : data ? (
            `${formatCurrency(data)}`
          ) : (
            '-'
          );
        }
      }
    );
  }

  if (isEventData) {
    columns.splice(
      1,
      0,
      {
        title: 'SPECIAL INSTRUCTIONS',
        width: '11%',
        className: 'special_instruction_column',
        dataIndex: 'special_instruction',
        render: (data: any, record: any) => {
          return (
            <span style={{ color: '#4e73df' }}>
              {data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`}
            </span>
          );
        }
      },
      {
        title: 'PORTIONS',
        width: '11%',
        dataIndex: 'portions',
        render: (data: any, record: any) => {
          return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
        }
      }
    );
  } else {
    columns.splice(1, 0, {
      title: 'SPECIAL INSTRUCTIONS',
      width: '11%',
      dataIndex: 'special_instruction',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    });
  }

  return columns;
};
export const getLabourEstimateColumns = () => {
  const columns: any[] = [
    {
      title: 'POSITION',
      width: '25%',
      dataIndex: 'position',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    },
    {
      title: 'NOTES',
      width: '35%',
      dataIndex: 'notes',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    },
    {
      title: 'QTY',
      width: '10%',
      dataIndex: 'qty',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    },
    {
      title: 'HRS',
      width: '10%',
      dataIndex: 'hrs',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    },
    {
      title: 'RATE',
      width: '10%',
      dataIndex: 'rate',
      render: (data: any) => {
        return data && data === 'SUBTOTAL' ? (
          <SubtotalAndCostDiv>{data}</SubtotalAndCostDiv>
        ) : data ? (
          `${formatCurrency(data)}`
        ) : (
          '-'
        );
      }
    },
    {
      title: 'COST',
      width: '10%',
      dataIndex: 'cost',
      render: (data: any, allData: any) => {
        return allData && allData?.rate === 'SUBTOTAL' ? (
          <SubtotalAndCostDiv>{formatCurrency(data)}</SubtotalAndCostDiv>
        ) : data ? (
          <CostLabel waived_off={allData?.waived_off}>{formatCurrency(data)}</CostLabel>
        ) : (
          '-'
        );
      }
    }
  ];
  return columns;
};
export const getOthersEstimateColumns = () => {
  const columns: any[] = [
    {
      title: 'ITEM NAME',
      width: '67%',
      dataIndex: 'item_name',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    },
    {
      title: 'QTY',
      width: '11%',
      dataIndex: 'qty',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    },
    {
      title: 'RATE',
      width: '11%',
      dataIndex: 'rate',
      render: (data: any) => {
        return data && data === 'SUBTOTAL' ? (
          <SubtotalAndCostDiv>{data}</SubtotalAndCostDiv>
        ) : data ? (
          `${formatCurrency(data)}`
        ) : (
          '-'
        );
      }
    },
    {
      title: 'COST',
      width: '11%',
      dataIndex: 'cost',
      render: (data: any, allData: any) => {
        return allData && allData?.rate === 'SUBTOTAL' ? (
          <SubtotalAndCostDiv>{formatCurrency(data)}</SubtotalAndCostDiv>
        ) : data ? (
          <CostLabel waived_off={allData?.waived_off}>{formatCurrency(data)}</CostLabel>
        ) : (
          '-'
        );
      }
    }
  ];
  return columns;
};

export const getVendorEstimatesColumns = () => {
  const columns: any[] = [
    {
      title: 'VENDOR',
      width: '67%',
      dataIndex: 'item_name',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    },
    {
      title: 'QTY',
      width: '11%',
      dataIndex: 'qty',
      render: (data: any, record: any) => {
        return data ? data : `${record?.rate === 'SUBTOTAL' ? '' : '-'}`;
      }
    },
    {
      title: 'RATE',
      width: '11%',
      dataIndex: 'rate',
      render: (data: any) => {
        return data && data === 'SUBTOTAL' ? (
          <SubtotalAndCostDiv>{data}</SubtotalAndCostDiv>
        ) : data ? (
          `${formatCurrency(data)}`
        ) : (
          '-'
        );
      }
    },
    {
      title: 'COST',
      width: '11%',
      dataIndex: 'cost',
      render: (data: any, allData: any) => {
        return allData && allData?.rate === 'SUBTOTAL' ? (
          <SubtotalAndCostDiv>{formatCurrency(data)}</SubtotalAndCostDiv>
        ) : data ? (
          <CostLabel waived_off={allData?.waived_off}>{formatCurrency(data)}</CostLabel>
        ) : (
          '-'
        );
      }
    }
  ];
  return columns;
};
