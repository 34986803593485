import React from 'react';
import { Col, Row } from 'antd';
import FormDataView from '@atom/FormDataView';
import { H2Typography } from '@constant/typography/Typography';
import {
  ManagerNotesDiv,
  SectionTitleDiv
} from '@pages/QuoteManagementPage/ViewQuoteDetails/style';

interface ManagerNotesProps {
  data: any;
  title: string;
}

const Notes: React.FC<ManagerNotesProps> = ({ data, title }) => {
  const defaultValue =
    title === 'Manager Notes'
      ? 'Example: Set Up Chairs on Main Lounge'
      : 'Example: Bride’s Mom - Celiac. No shoes on the carpet';

  const notes = title === 'Manager Notes' ? data?.manager_notes : data?.important_notes;

  const value = (
    <span
      style={{
        color: notes ? 'inherit' : 'rgba(0, 0, 0, 0.5)',
        fontSize: notes ? '' : '16px'
      }}>
      {notes || defaultValue}
    </span>
  );

  const wordCount =
    title === 'Manager Notes'
      ? data?.manager_notes?.length || 0
      : data?.important_notes?.length || 0;
  const maxWordCount = title === 'Manager Notes' ? '19000' : '3000';

  // return (
  //   <>
  //     <Row gutter={16} style={{ marginTop: '32px' }}>
  //       <Col span={24}>
  //         <SectionTitleDiv>
  //           <H2Typography>Manager Notes</H2Typography>
  //           <H2Typography>{title}</H2Typography>
  //         </SectionTitleDiv>
  //       </Col>
  //     </Row>
  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'event-section'}>{title}</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      <Col
        lg={24}
        xs={24}
        style={{
          minHeight: '150px',
          paddingLeft: '15px',
          paddingRight: '15px'
        }}>
        <FormDataView value={value} />
      </Col>
    </>
  );
};

export default Notes;
