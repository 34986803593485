import services from '@services/index';
import moment from 'moment';
import * as Yup from 'yup';

export const newTimelineObject = {
  name: undefined,
  start_time: undefined,
  end_time: undefined,
  description: undefined,
  event_date: undefined,
  event_id: undefined
};

export const newGuestAllergiesObject = {
  event_date: undefined,
  seated: undefined,
  guest_name: undefined,
  allergy: undefined,
  event_id: undefined
};

export const getServiceFormInitialValues = (data?: any) => {
  return {
    event_time_line: data?.event_time_line?.length
      ? data?.event_time_line
      : [{ ...newTimelineObject, tempId: Math.random() }],
    guest_allergies: data?.guest_allergies?.length
      ? data?.guest_allergies
      : [{ ...newGuestAllergiesObject, tempId: Math.random() }]
  };
};

export const validationSchema = Yup.object({
  event_time_line: Yup.array().of(
    Yup.object({
      name: Yup.string().nullable(),
      start_time: Yup.string().nullable(),
      end_time: Yup.string().nullable(),
      description: Yup.string().nullable()
    })
  ),
  guest_allergies: Yup.array().of(
    Yup.object({
      event_date: Yup.string().nullable(),
      seated: Yup.string().nullable(),
      guest_name: Yup.string().nullable(),
      allergy: Yup.string().nullable()
    })
  )
});

const getMappedEventData = (data: any, eventId: number, eventDate: string) => {
  data.event_time_line.map((t: any) => {
    t.event_date = t?.event_date || eventDate;
    t.event_id = t?.event_id || eventId;
    t.id = t?.id || undefined;
  });
  data.guest_allergies.map((t: any) => {
    t.event_date = t?.event_date || eventDate;
    t.event_id = t?.event_id || eventId;
    t.id = t?.id || undefined;
  });
  return data;
};

export const addEditService = async (data: any, eventId: number, eventDate: string) => {
  try {
    const res = (await services?.post(`/events/service`, {
      ...getMappedEventData(data, eventId, eventDate)
    })) as {
      data: any;
    };
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getService = async (event_id: any, quote_id: any, event_date: any) => {
  try {
    const res = (await services.get(
      `/events/service?event_id=${event_id}&quote_id=${quote_id}&event_date=${event_date}`
    )) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
