import services from '@services/index';
import { ResponseType } from '../helper';

// api calls
export const getSettings = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const saveSettings = async (value: string): Promise<ResponseType> => {
  try {
    const body = {
      settings: [
        {
          key: 'hst_fee',
          value: value
        }
      ]
    };
    const res = (await services.post(`/settings`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};
