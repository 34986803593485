import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Row } from 'antd';
import { FieldArray } from 'formik';
import React from 'react';
import { REPRESENTATIVE_ITEM } from './helper';
import RepresentativeItem from './RepresentativeItem';
import RepresentativeItemHeader from './RepresentativeItemHeader';

function RepresentativeContainer(props: any) {
  const { representatives, handleChange, errors, touched, setFieldValue } = props;

  return (
    <>
      <RepresentativeItemHeader />
      <FieldArray
        name="representatives"
        render={(arrrayHelper: any) => {
          return (
            <>
              {representatives.map((i: any, index: number) => (
                <RepresentativeItem
                  key={i.id || i.localId}
                  handleChange={handleChange}
                  index={index}
                  setFieldValue={setFieldValue}
                  errors={errors.representatives && errors.representatives[index]}
                  item={representatives[index]}
                  onRemove={(item: any) => {
                    const newRerentatives = representatives.filter(
                      (i: any) => i.id !== item.id || i.localId !== item.localId
                    );
                    setFieldValue('representatives', newRerentatives);
                  }}
                />
              ))}
            </>
          );
        }}
      />

      <Row>
        <Flex className="fix-mobile">
          <Button
            text={'Add Representative'}
            variant={'secondary'}
            onClick={() => {
              setFieldValue('representatives', [
                ...representatives,
                { ...REPRESENTATIVE_ITEM, localId: Math.random() }
              ]);
            }}
            icon={<PlusCircleOutlined />}
          />
        </Flex>
      </Row>
    </>
  );
}

export default RepresentativeContainer;
