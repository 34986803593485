import BreadCrumbs from '@atom/BreadCrumb';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { Col, Divider, Select } from 'antd';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  EventFindQuoteEditQuoteBreadCrumbs,
  EventFindQuoteBreadCrumbs,
  EventEditQuoteBreadCrumbs,
  QuoteDetailsFindQuoteBreadcrumb,
  QuoteViewEventEditEventBreadCrumbs,
  getEventCodeList
} from '../EventDetails/helper';
import EventWithExistingQuoteTabs from './EventWithExistingQuoteTabs';
import { localStorageUtil } from '@utils/localstorageUtil';
import SelectInput from '@atom/Select';
import { useEffect, useState } from 'react';
import CenterSpiner from '@atom/CenterSpiner';

function EventWithExistingQuote() {
  const params = useParams();
  const { quoteId, eventId, formType } = params;
  const route = useLocation();
  const navigate = useNavigate();

  const location = localStorageUtil.getValue('location');
  const [eventCodeList, setEventCodeList] = useState<any[]>([]);
  const [currentEventCode, setCurrentEventCode] = useState<any>(null);
  const [isEventCodeLoading, setIsEventCodeLoading] = useState<boolean>(false);

  const getEventCodeListMethod = async (event_id?: string) => {
    if (!event_id) return;
    setIsEventCodeLoading(true);
    try {
      const res = await getEventCodeList(event_id);
      if (res?.success) {
        setEventCodeList(res?.data);
        setIsEventCodeLoading(false);
        if (!res?.data?.length) {
          setCurrentEventCode(null);
        }
      }
    } catch (err) {
      setIsEventCodeLoading(false);
    }
  };

  useEffect(() => {
    if (eventId) {
      getEventCodeListMethod(eventId);
      setCurrentEventCode(parseInt(eventId));
    }
  }, [eventId]);

  const eventCodeChange = (codeData: any) => {
    setCurrentEventCode(codeData?.id);
    navigate(`/events/${codeData?.id}/view`);
  };

  if (isEventCodeLoading) return <CenterSpiner />;

  return (
    <PageLayout>
      {(eventId && !location?.state?.path) ||
      location?.state?.path === 'event_details' ||
      location?.state?.path === 'edit_event' ? (
        <BreadCrumbs routes={EventFindQuoteBreadCrumbs(quoteId, eventId)} />
      ) : route?.state?.path === 'view_event' ||
        location?.state?.path === 'quote_details' ||
        location?.state?.path === 'quote_event' ? (
        <BreadCrumbs routes={QuoteViewEventEditEventBreadCrumbs(quoteId, eventId)} />
      ) : location?.state?.path === 'select' || location?.state?.path === 'select_quote' ? (
        <BreadCrumbs routes={EventFindQuoteEditQuoteBreadCrumbs()} />
      ) : location?.state?.prevPath === `/quote/view/${quoteId}` ||
        location?.state?.path === 'create_event' ? (
        <BreadCrumbs routes={EventEditQuoteBreadCrumbs(quoteId)} />
      ) : (
        <BreadCrumbs routes={QuoteDetailsFindQuoteBreadcrumb(quoteId)} />
      )}

      <div style={{ marginTop: '15px' }}>
        <PageTitle
          text={formType === 'quote' ? 'FIND A QUOTE' : 'EVENT MANAGEMENT'}
          isBack={false}
          path="/events/find-quote/"></PageTitle>
      </div>
      <Col lg={4} xs={24} style={{ paddingTop: '20px' }}>
        <SelectInput
          id="event_id"
          name="event_id"
          allowClear={false}
          placeholdertitle="Select from the list"
          style={{ width: 'auto' }}
          value={currentEventCode}
          onChange={(event_id: any) => {
            eventCodeChange(eventCodeList.find((event) => event.id === event_id));
          }}>
          {eventCodeList?.map((item) => (
            <Select.Option key={item?.id} value={item.id} id={item?.id}>
              {item?.text}
            </Select.Option>
          ))}
        </SelectInput>
      </Col>
      <Divider />
      <EventWithExistingQuoteTabs />
    </PageLayout>
  );
}

export default EventWithExistingQuote;
