import { Row } from 'antd';
import { useFormik } from 'formik';
import { FORM_INITIAL_VALUES, getMappedData, laboursFormSchema, showOverrideCheckBox } from './helper';
import StaffingEstimateSection from './StaffingEstimateSection';
import EventBasicDetails from '../EventBasicDetails';
import AddEditQuoteFooter from '../AddEditQuoteFooter';
import { GetTabData, PutTabData } from '@services/quoteServices';
import { getTabBaseUrl, QuoteBasicDetailsObject } from '../helper';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { showErrorToast, showToast } from '@utils/index';
import CenterSpiner from '@atom/CenterSpiner';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';
import ConfirmModal from '@organism/ConfimationModal';
import { Label } from '@atom/FormLable';
import { SelectInputWithCheckBox } from '@atom/Select';
import {
  getAccountManagers,
  getAvailableEmployees
} from '@pages/EventManagementPage/AddEditEvent/SharedTabs/Staffing/helper';
import { Flex } from '@atom/Flex';
import { SelectLabelText } from './style';

interface LaboursFormProps {
  tabKey: string;
  quoteBasicDetails: QuoteBasicDetailsObject;
  setQuoteBasicDetails: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  isEventsTab?: boolean;
  submitForm?: (tabKey: string, values: any, actions: any, method?: string) => void;
}

function LaboursForm(props: LaboursFormProps) {
  const {
    tabKey,
    quoteBasicDetails,
    isEventsTab = false,
    setQuoteBasicDetails,
    submitForm
  } = props;

  const params = useParams();
  const { quoteId, eventId } = params;
  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);

  const location = useLocation();
  const navigate = useNavigate();

  const [draftSubmitting, setDraftSubmitting] = useState(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isloading, setLoading] = useState<boolean>(true);
  const [employees, setEmployees] = useState<Array<any>>([]);
  const [staffingItem, setStaffingItem] = useState<any>({});
  const [status, setStatus] = useState<number>(STATUS.DRAFT);

  const formik = useFormik({
    validationSchema: laboursFormSchema(isEventsTab),
    onSubmit: (values: any, actions: any) => {
      submitHandler(values, actions);
    },
    initialValues: FORM_INITIAL_VALUES
  });
  const { handleSubmit, setValues, values, setFieldValue, touched, errors } = formik;

  const updateInitialData = async () => {
    setPageLoading(true);
    try {
      const res = await GetTabData(tabKey, quoteId ?? '', isEventsTab);
      if (res?.success) {
        setValues(getMappedData({ ...values }, { ...res?.data }));
        setStatus(res?.data?.status);
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id,
          quotation_no: res?.data?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string
        }));
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch quote data.'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch quote data.'
      });
    }
    setPageLoading(false);
  };

  const handleChangeValue = (value: any, formikKey: string, key: string) => {
    setStaffingItem((prev: any) => ({ ...prev, [key]: value }));
    setTimeout(() => {
      setFieldValue(formikKey, value);
    }, 200);
  };

  const handleSelectEmployee = (e: any) => {
    handleChangeValue(e, 'event_dates.notify_staff', 'event_dates.notify_staff');
  };

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    if (!isDraft && submitForm) {
      submitForm(
        tabKey,
        {
          ...values,
          event_dates: {
            ...values?.event_dates,
            event_date: values?.event_dates?.event_date?.[0] ?? ''
          }
        },
        actions,
        'post'
      );
      return;
    }
    const res: any = await PutTabData(tabKey, {
      ...quoteBasicDetails,
      ...values,
      unique_invoice_string: random_unique_invoice_string
        ? random_unique_invoice_string
        : quoteBasicDetails.unique_invoice_string,
      id: quoteId,
      event_dates: {
        ...values?.event_dates,
        event_date: values?.event_dates?.event_date?.[0] ?? ''
      }
    });
    if (res?.success) {
      actions?.setSubmitting(false);
      setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
        ...prev,
        id: res?.data?.id || prev?.id,
        quotation_no: res?.data?.quotation_no || prev?.quotation_no,
        status: res?.data?.status,
        unique_invoice_string: random_unique_invoice_string
          ? random_unique_invoice_string
          : res?.data?.unique_invoice_string || prev?.unique_invoice_string
      }));
      const tabBaseUrl = getTabBaseUrl(
        location?.pathname,
        res?.data?.id || quoteId,
        quoteBasicDetails,
        res?.data?.id
      );
      navigate(isDraft ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}` : `${tabBaseUrl}vendor`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
      if (isDraft) {
        showToast({
          message: 'Success!',
          description: 'Quotation saved as Draft'
        });
      }
    } else {
      actions?.setSubmitting(false);
      actions?.setErrors(res?.error);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
  };

  const backHandler = () => {
    if (isEventsTab) {
      const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.MEALORDER}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}meal-order`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };

  const fetchAvailableEmployeesList = async () => {
    setLoading(true);
    try {
      const { data } = (await getAccountManagers()) as { data: Array<any> };
      setEmployees(data || []);
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch Managers List.'
      });
    }
    setLoading(false);
  };

  const cancelHandler = () => {
    if (isEventsTab) {
      navigate('/events');
    } else {
      navigate('/quote');
    }
  };
  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    setFieldValue('status', STATUS.DRAFT);
    await submitHandler({ ...values, status: STATUS.DRAFT }, null, true);
    setDraftSubmitting(false);
  };
  const nextHandler = () => {
    showOverrideCheckBox.includes(status) ? setIsModalVisible(true) : handleSubmit();
  };

  useEffect(() => {
    if (tabKey === 'labour') {
      updateInitialData();
      fetchAvailableEmployeesList();
    }
  }, [tabKey]);

  if (pageLoading) {
    return <CenterSpiner />;
  }
  return (
    <>
      {/* <EventBasicDetails
        eventName="EVENT"
        eventDate="Sat, 28 Oct"
        venueName="Pune"
        customerName="Amit"
      /> */}

      <Row style={{ marginTop: 40 }}>
        <StaffingEstimateSection isEventsTab={isEventsTab} formik={formik} />
      </Row>
      <AddEditQuoteFooter
        showBackButton={true}
        isDraftSubmitting={draftSubmitting}
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        saveAsDraftClickHandler={!isEventsTab ? saveAsDraftHandler : undefined}
        nextClickHandler={nextHandler}
        isSubmitting={formik?.isSubmitting}
      />
      <ConfirmModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        title={
          'This change is going to affect staffing assignments, do you want to override the Staffing assignments?'
        }
        onOK={() => {
          setIsModalVisible(false);
          handleSubmit();
        }}
        modalOverFlowX="visible"
        okFirst={true}
        okText="Yes / Send"
        cancelText="No / Cancel"
        theme={{ title: { textAlign: 'left' } }}>
        <Flex flex={1} direction="column" gap={15}>
          <SelectLabelText>If yes, who would you like to inform?</SelectLabelText>
          <SelectInputWithCheckBox
            name={'event_dates.notify_staff'}
            id={'event_dates.notify_staff'}
            placeholdertitle="Search for Employee"
            onChange={(e: any) => handleSelectEmployee(e)}
            options={employees?.map((i: any) => {
              i.title = <Flex>{i.name}</Flex>;
              i.key = i.id;
              i.value = i.id;
              return i;
            })}
            filterTreeNode={(inputValue: string, treeNode: any) =>
              treeNode?.name?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) > -1
            }
            value={!isloading ? staffingItem?.event_dates?.notify_staff : []}
            error={touched?.event_dates?.notify_staff && errors?.event_dates?.notify_staff}
          />
        </Flex>
      </ConfirmModal>
    </>
  );
}

export default LaboursForm;
