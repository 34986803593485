import { Col, Divider, Row, Select } from 'antd';
import { Formik } from 'formik';

import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { H1Typography } from '@constant/typography/Typography';
import ModalComponent from '@organism/Modal/Modal';
import { showErrorToast, showToast } from '@utils/index';
import TimePickerComponent from '@atom/TimePicker';
import SelectInput from '@atom/Select';
import { addStaff, addNewStaffValidations, newStaffObject } from './helper';
import { AddStaffModalProps } from '../types';
import { TimePickerWrapper } from './style';
import { Moment } from 'moment';
const { Option } = Select;

function AddNewStaffModal(props: AddStaffModalProps) {
  const { isModalVisible, onOk, onCancel, openPosition, colData } = props;
  const handleCancel = (resetForm: any, setValues: any) => {
    resetForm();
    setValues(newStaffObject);
    onCancel();
  };

  const onSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    values.event_id = colData.id;
    values.event_date = colData.event_date;
    try {
      const res: any = await addStaff(values);
      if (res?.success) {
        showToast({
          message: 'Success!',
          description: 'Staff Saved'
        });
        actions.resetForm();
        onOk();
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={newStaffObject}
      validationSchema={addNewStaffValidations}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
    >
      {(prop) => {
        const {
          touched,
          errors,
          values,
          setFieldValue,
          handleBlur,
          handleSubmit,
          handleChange,
          setValues,
          resetForm,
          setFieldTouched
        } = prop;
        return (
          <>
            <ModalComponent
              visible={isModalVisible}
              onCancel={() => handleCancel(resetForm, setValues)}
              modalWidth="798px !important"
              align="left !important"
            >
              <Flex justifyContent="center" bottom={38}>
                <H1Typography>Staffing</H1Typography>
              </Flex>
              <TimePickerWrapper>
                <Row gutter={16}>
                  <Col lg={6} xs={24}>
                    <Label text="START TIME" isMandatory />
                    <TimePickerComponent
                      id="start_time"
                      name="start_time"
                      use12Hours={true}
                      format="hh:mm:ss A"
                      placeholder={'HH:MM'}
                      value={values?.start_time}
                      onChange={(value: string) => {
                        setFieldTouched('start_time', true);
                        setFieldValue('start_time', value);
                      }}
                      onSelect={(e: Moment) => {
                        setFieldValue('start_time', e.format('hh:mm A'));
                      }}
                      error={touched?.start_time && errors?.start_time}
                    />
                  </Col>
                  <Col lg={6} xs={24}>
                    <Label text="END TIME" isMandatory />
                    <TimePickerComponent
                      id="end_time"
                      name="end_time"
                      use12Hours={true}
                      format="hh:mm:ss A"
                      placeholder={'HH:MM'}
                      value={values?.end_time}
                      onChange={(value: string) => {
                        setFieldTouched('end_time', true);
                        setFieldValue('end_time', value);
                      }}
                      onSelect={(e: Moment) => {
                        setFieldValue('end_time', e.format('hh:mm A'));
                      }}
                      error={touched?.end_time && errors?.end_time}
                    />
                  </Col>
                  <Col lg={4} xs={24}>
                    <Label text="QTY" isMandatory />
                    <Input
                      id={'quantity'}
                      name={'quantity'}
                      onChange={handleChange}
                      error={touched.quantity && errors.quantity}
                      value={values?.quantity}
                    />
                  </Col>
                  <Col lg={8} xs={24}>
                    <Label text="POSITION" isMandatory />
                    <SelectInput
                      onChange={(e: any) => {
                        setFieldValue('position_id', e);
                      }}
                      value={values.position_id}
                      id={'position_id'}
                      name={'position_id'}
                      placeholdertitle={'Position'}
                      error={touched.position_id && errors.position_id}
                    >
                      {openPosition?.data?.data?.map((i: any) => {
                        return (
                          <Option key={i.id} value={i.id}>
                            {i.name}
                          </Option>
                        );
                      })}
                    </SelectInput>
                  </Col>
                </Row>
              </TimePickerWrapper>
              <Divider style={{ borderColor: '#c4c4c4' }} />
              <Flex className="footerButtonModal fix-mobile" style={{ marginBottom: '10px' }}>
                <Button
                  variant="secondary"
                  text={'Cancel'}
                  onClick={() => handleCancel(resetForm, setValues)}
                  className="cancel-btn"
                />
                <Button
                  variant="primary"
                  text={'Save'}
                  onClick={handleSubmit}
                  isLoading={prop.isSubmitting}
                />
              </Flex>
            </ModalComponent>
          </>
        );
      }}
    </Formik>
  );
}

export default AddNewStaffModal;
