import { Col, Divider, Row } from 'antd';
import FormDataView, { Title } from '@atom/FormDataView';

import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { IVendorDataType } from '../types';
import StatusLabel from '@atom/StatusLabel';
import { TagComponent } from '@atom/Tag';
import { formatPhoneNumber } from '@utils/format';
import moment from 'moment';
import { useState } from 'react';
import { VendorContentWrapper } from './style';

function VendorInfo({ VendorData }: { VendorData: IVendorDataType }) {
  const getVendorTypes = () => {
    const types = VendorData?.categories?.map((type) => type.name).join(', ');
    return types;
  };

  const getPublictags = () => {
    if (!VendorData?.public_tags || VendorData?.public_tags?.length == 0) return 'Not Specified';
    return (
      <Flex flex={'wrap'}>
        {VendorData?.public_tags?.map((tag, index) => (
          <TagComponent key={`${tag}=${index}`} text={tag} rounded={false} />
        ))}
      </Flex>
    );
  };

  return (
    <Flex direction="column" style={{ marginBottom: '10px', marginTop: '30px' }}>
      <VendorContentWrapper>
        <Row gutter={16} style={{ width: '100%' }}>
          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView heading={'VENDOR NAME'} value={VendorData?.vendor_name} />
          </Col>
          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView heading={'VENDOR TYPE'} value={getVendorTypes()} />
          </Col>
          <Col span={6} md={8} sm={8} xs={12}>
            {/* <FormDataView heading={'STATUS'} value={VendorData?.status} /> */}
            <Title>STATUS</Title>
            {(!!VendorData?.status || VendorData?.status === 0) && (
              <StatusLabel status={VendorData?.status} isUppercase={true} />
            )}
          </Col>

          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView heading={'VENDOR CONTACT'} value={VendorData?.contact_name} />
          </Col>
          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView heading={'CONTACT EMAIL'} value={VendorData?.email} />
          </Col>
          <Col span={6} md={6} sm={6} xs={12}>
            <FormDataView
              heading={'REGISTRATION DATE'}
              value={moment(VendorData?.registered_at).format('ddd, MMMM DD')}
            />
          </Col>

          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView
              heading={'CONTACT PHONE NUMBER'}
              value={
                !!VendorData?.formated_phone_number
                  ? `${VendorData?.country_code ?? ''} ${
                      VendorData?.formated_phone_number ?? 'Not Specified'
                    } ${VendorData?.extension ? `x ${VendorData?.extension}` : ''}`
                  : null
              }
            />
          </Col>
          <Col span={6} md={8} sm={8} xs={12}>
            <FormDataView heading={'BILLING ADDRESS'} value={VendorData?.address} />
          </Col>
          <Col span={6} md={6} sm={8} xs={12}>
            <FormDataView heading={'PUBLIC TAG'} value={getPublictags()} />
          </Col>
        </Row>

        <Row gutter={8} style={{ width: '100%' }}>
          <Col span={6} md={20} sm={24} xs={24}>
            <FormDataView heading={'VENDOR DESCRIPTION'} value={VendorData?.description} />
          </Col>
        </Row>

        <H2Typography style={{ marginTop: '15px' }}>Additional Contacts</H2Typography>
        <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />

        {VendorData?.additional_contacts?.map((contact, index) => (
          <Row
            gutter={8}
            style={{ width: '100%' }}
            key={`${contact?.name}-${contact?.email}-${index}`}
          >
            <Col span={6} md={8} sm={8} xs={24}>
              <FormDataView heading={'NAME'} value={contact?.name} />
            </Col>
            <Col span={6} md={8} sm={8} xs={24}>
              <FormDataView heading={'EMAIL'} value={contact?.email} />
            </Col>
            <Col span={6} md={8} sm={8} xs={24}>
              <FormDataView
                heading={'PHONE NUMBER'}
                value={
                  !!contact?.phone_number
                    ? `${contact?.country_code ?? ''} ${formatPhoneNumber(
                        contact?.phone_number ?? ''
                      )} ${contact?.extension ? `x ${contact?.extension}` : ''}`
                    : null
                }
              />
            </Col>
          </Row>
        ))}
        <H2Typography style={{ marginTop: '15px' }}>Pick Up Times</H2Typography>
        <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />

        <Row gutter={8} style={{ width: '100%' }}>
          {VendorData?.pickup_times?.map((pickup, index) => {
            if (!pickup?.day) return null;
            return (
              <Col
                span={6}
                md={6}
                sm={8}
                xs={24}
                key={`${pickup?.day}-${pickup?.time_type}-${index}`}
              >
                <FormDataView
                  heading={pickup?.day}
                  value={
                    !pickup?.start_time || !pickup?.end_time
                      ? null
                      : `${pickup?.start_time} - ${pickup?.end_time}`
                  }
                />
              </Col>
            );
          })}
        </Row>

        <H2Typography style={{ marginTop: '15px' }}>Delivery</H2Typography>
        <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />

        <Row gutter={8} style={{ width: '100%' }}>
          {VendorData?.drop_times?.map((drops, index) => {
            if (!drops?.day) return null;
            return (
              <Col
                span={6}
                md={6}
                sm={8}
                xs={24}
                key={`${drops?.day}-${drops?.time_type}-${index}`}
              >
                <FormDataView
                  heading={drops?.day}
                  value={
                    !drops?.start_time || !drops?.end_time
                      ? null
                      : `${drops?.start_time} - ${drops?.end_time}`
                  }
                />
              </Col>
            );
          })}
        </Row>
      </VendorContentWrapper>
    </Flex>
  );
}

export default VendorInfo;
