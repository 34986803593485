import { FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import MenuManagementEditableItem from './MenuManagementEditableItem';
import { GlobalSettingEditableVirtualListContainer } from './style';

interface IngredientsVirtualListProps {
  formik: FormikProps<any>;
  onItemDelete: (
    index: number,
    ingridentsArray: any,
    setFieldValue: any,
    id?: number | null,
    is_associated?: boolean
  ) => boolean;
  fieldArrayName: string;
  inputFieldPlaceHolder: string;
  specialIngredientClickHandler?: (id: number) => void;
  scrollToBottomOfList?: boolean;
  setScrollToBottomOfList?: React.Dispatch<React.SetStateAction<boolean>>;
  isInputFieldDisabled?: boolean;
  showDeleteIcon?: boolean;
}

const RowItem = (obj: any) => {
  const { index, style } = obj;
  const { values, fieldArrayName } = obj.data;
  return (
    <MenuManagementEditableItem
      style={{ ...style, cursor: 'pointer' }}
      keyValue={`${fieldArrayName}-EditableItem-${index}-${
        values?.[`${fieldArrayName}`]?.[index]?.id
      }`}
      {...{
        ...obj?.data,
        isInputFieldDisabled: obj?.data?.isInputFieldDisabled
          ? obj?.data?.isInputFieldDisabled
          : values?.[`${fieldArrayName}`]?.[index]?.is_static,
        showDeleteIcon: obj?.data?.showDeleteIcon
          ? obj?.data?.showDeleteIcon
          : !values?.[`${fieldArrayName}`]?.[index]?.is_static,
        index
      }}
    />
  );
};

const IngredientsVirtualList = (props: IngredientsVirtualListProps) => {
  const {
    formik,
    onItemDelete,
    fieldArrayName,
    inputFieldPlaceHolder,
    specialIngredientClickHandler,
    scrollToBottomOfList,
    setScrollToBottomOfList,
    isInputFieldDisabled,
    showDeleteIcon
  } = props;
  const { setFieldValue, handleBlur, touched, errors, values } = formik;

  const [data, setData] = useState<any[]>([]);
  const listRef = useRef<List>(null);

  const scrollListToItemIndex = (itemNumber: number) => {
    if (!listRef?.current) return;
    listRef?.current?.scrollToItem(itemNumber);
  };

  useEffect(() => {
    if (scrollToBottomOfList) {
      scrollListToItemIndex(data?.length);
      setScrollToBottomOfList && setScrollToBottomOfList(false);
    }
  }, [data?.length]);

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(values?.[`${fieldArrayName}`] || [])) || [];
    setData(data);
  }, [values?.[`${fieldArrayName}`]]);

  return (
    <GlobalSettingEditableVirtualListContainer dataLength={data?.length}>
      <AutoSizer>
        {({ height, width }: { height: any; width: any }) => {
          return (
            <List
              ref={listRef}
              className="List"
              height={height}
              itemCount={data?.length}
              itemSize={85}
              width={width}
              key={data?.length}
              itemData={{
                setFieldValue,
                handleBlur,
                touched,
                errors,
                values,
                onItemDelete,
                fieldArrayName,
                inputFieldPlaceHolder,
                specialIngredientClickHandler,
                isInputFieldDisabled,
                showDeleteIcon,
                scrollListToItemIndex
              }}
            >
              {RowItem}
            </List>
          );
        }}
      </AutoSizer>
    </GlobalSettingEditableVirtualListContainer>
  );
};
export default IngredientsVirtualList;
