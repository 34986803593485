import { Col, Row } from 'antd';
import { FieldArray, FormikProvider } from 'formik';
import { useCallback } from 'react';
import StaffingFields from './StaffingFields';
import update from 'immutability-helper';
import { H2Typography } from '@constant/typography/Typography';
import { Divider } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';
import { Button } from '@atom/Buttons';
import { getStaffingEmptyObject } from './helper';
import { PlusCircleOutlined } from '@ant-design/icons';
import DragNDropWrapper from '@atom/DragNDropWrapper';
import Error from '@atom/Error';

interface StaffingSectionProps {
  formik: any;
  dropdownData: any;
  eventDetails: any;
  deletedData: any;
  allPositions: any[];
}

const StaffingSection = (props: StaffingSectionProps) => {
  const { formik, dropdownData, eventDetails, deletedData, allPositions } = props;
  const { values, setFieldValue, errors } = formik;

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const res = update(values.staffing, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, values.staffing[dragIndex]]
        ]
      });
      setFieldValue('staffing', res);
    },
    [values.staffing]
  );

  const handleDnD = async (obj: any) => {};

  return (
    <>
      <H2Typography>Staffing</H2Typography>
      <Divider />
      <FormikProvider value={formik}>
        <FieldArray
          name="staffing"
          render={(arrayHelper) => {
            if (values?.staffing?.length === 0) {
              return null;
            }
            return values?.staffing?.map((item: any, index: number) => {
              return (
                <DragNDropWrapper
                  key={index}
                  index={index}
                  onDropCard={handleDnD}
                  moveCard={moveCard}
                >
                  <Row style={{ marginTop: 20, width: '100%' }}>
                    <StaffingFields
                      index={index}
                      formik={formik}
                      item={item}
                      allPositions={allPositions}
                      deletedData={deletedData}
                      eventDetails={eventDetails}
                      dropdownData={dropdownData}
                      fieldType="staffing"
                      onRemove={(position: any) => {
                        const positionItems = values?.staffing.filter((i: any) =>
                          i?.id
                            ? i?.id !== position?.id
                            : i?.quotation_labour_id
                            ? i?.quotation_labour_id !== position?.quotation_labour_id
                            : i?.tempId !== position?.tempId
                        );
                        setFieldValue('staffing', positionItems);
                      }}
                    />
                  </Row>
                </DragNDropWrapper>
              );
            });
          }}
        />
      </FormikProvider>
      <Row gutter={16} style={{ marginTop: '10' }}>
        <Col lg={20} xs={20}>
          {!!errors.staffing && typeof errors.staffing === 'string' && (
            //@ts-ignore
            <Error error={errors.staffing || ''} />
          )}
        </Col>
        <Col lg={4} xs={4} style={{ justifyContent: 'end', display: 'flex' }}>
          <Button
            variant="secondary"
            onClick={() => {
              formik.setFieldValue('staffing', [
                ...(values?.staffing || []),
                {
                  tempId: new Date().getTime(),
                  ...getStaffingEmptyObject(0, values?.staffing?.length + 1)
                }
              ]);
            }}
            icon={<PlusCircleOutlined />}
            text={'Add'}
            style={{ borderWidth: 2 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default StaffingSection;
