export enum DOCUSIGN_FORM_TYPE_ENUM {
  ONBOARD = 1,
  OFFBOARD = 2
}

export enum GLOBAL_SETTING_FORM_SECTION {
  BOARD_FORM = 1,
  RAHR_FORM = 2,
  PREC_FORM = 3
}

export enum GLOBAL_SETTING_FORM_TYPE {
  DOCUSING_ID = 1,
  LINK = 2,
  FILE = 3,
  CHECKBOX = 4
}
export const GLOBAL_SETTING_FORM_TYPE_LABELS: { [key: string]: any } = {
  2: 'Link',
  3: 'File'
};

export enum DOCUSIGN_FORM_SIGNEDBY_ENUM {
  AGENT = 1,
  BRANCH_MANAGER = 2,
  BRANCH_ADMIN = 3
}

export const DOCUSIGN_STATE = {
  NEW: 0,
  LOADING: 1,
  INITIALIZED: 2,
  DOCUMENT_SIGNED: 3,
  RECEIVED_S3_URL: 4
};

export const DOCUSIGN_MESSAGE_ACTIONS = {
  DOCUSIGN_FAILED_SIGN: 'DOCUSIGN_FAILED_SIGN',
  DOCUSIGN_SIGN_COMPLETE: 'DOCUSIGN_COMPLETE_SIGN'
};
