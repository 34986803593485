import ModalComponent from '@organism/Modal/Modal';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { H3Typography, H5Typography } from '@constant/typography/Typography';
import { showErrorToast } from '@utils/index';
import { useState } from 'react';
import { dowloadEventContractPdf } from './helper';
import moment from 'moment';

const DownloadContractPDFModal = (props: DownloadContractPDFModalPropTypes) => {
  const {
    isContractDowloadPdfModalVisible,
    onCancel,
    setIsContractDowloadPdfModalVisible,
    data,
    locationDetail
  } = props;

  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);
  const { eventId } = useParams() as { eventId: string };

  const cancelDownloadAsPdf = () => {
    setIsContractDowloadPdfModalVisible(false);
  };

  const extractStringFromError = (error: ErrorObject) => {
    const value = Object?.values(error)?.[0];
    const errorMessage = value[0];
    if (typeof errorMessage === 'string') {
      return errorMessage;
    }
  };

  const onSave = async () => {
    setIsGeneratingPdf(true);
    const payload = {
      quotation_id: data?.quote_id,
      event_id: eventId
    };
    const res = (await dowloadEventContractPdf(payload)) as ContractPDFDownloadResponseTypes;
    setIsGeneratingPdf(false);
    if (res?.error) {
      if (res?.error?.error) {
        const errorMessage = extractStringFromError(res?.error?.error);
        if (errorMessage) {
          return showErrorToast({
            message: `Failed`,
            description: errorMessage
          });
        }
      }
      return showErrorToast({
        message: `Failed`,
        description: 'Please try again'
      });
    } else if (res?.data?.url) {
      setIsContractDowloadPdfModalVisible(false);
      window.open(res?.data?.url, '_blank');
    }
  };

  return (
    <>
      <ModalComponent
        visible={isContractDowloadPdfModalVisible}
        onCancel={onCancel}
        modalWidth="calc(100vw - 150px)"
        align="left !important">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <H3Typography style={{ fontWeight: '400', textAlign: 'left' }}>Event:</H3Typography>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <H5Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              {data?.event_name || 'NA'}
            </H5Typography>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <H3Typography style={{ fontWeight: '400', textAlign: 'left' }}>Location:</H3Typography>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <H5Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              {locationDetail?.venue_address || 'NA'}
            </H5Typography>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <H3Typography style={{ fontWeight: '400', textAlign: 'left' }}>Date:</H3Typography>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <H5Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              {data?.event_date || 'NA'}
            </H5Typography>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <H3Typography style={{ fontWeight: '400', textAlign: 'left' }}>Time:</H3Typography>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <H5Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              {data?.start_time && data?.end_time
                ? `${moment(data?.start_time, 'HH:mm:ss').format('hh:mm A')} - ${moment(
                    data?.end_time,
                    'HH:mm:ss'
                  ).format('hh:mm A')}`
                : 'NA'}
            </H5Typography>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <H3Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              Estimated Guest Count:
            </H3Typography>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <H5Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              {data?.total_guest || 'NA'}
            </H5Typography>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <H3Typography style={{ fontWeight: '400', textAlign: 'left' }}>Contact:</H3Typography>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <H5Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              {data?.account_manager_email || 'NA'}
            </H5Typography>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <H3Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              Food Dudes Rep:
            </H3Typography>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <H5Typography style={{ fontWeight: '400', textAlign: 'left' }}>
              {data?.account_manager_name || 'NA'}
            </H5Typography>
          </Col>
        </Row>
        <Row>
          <Col lg={24} xs={24}>
            <Flex className="fix-mobile" justifyContent={'flex-end'} gap={16} top={30}>
              <Button text={'Cancel'} variant={'secondary'} onClick={cancelDownloadAsPdf} />
              <Button text={isGeneratingPdf ? 'Generating PDF...' : 'Save'} onClick={onSave} />
            </Flex>
          </Col>
        </Row>
      </ModalComponent>
    </>
  );
};

export default DownloadContractPDFModal;
