import React, { FC, useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4Typography } from '@constant/typography/Typography';
import {
  DepartmentsType,
  deptValidationSchema,
  duplicateDepartmentErrorMessage,
  duplicatePositionErrorMessage,
  EditDepartmentType,
  getDepartmentList,
  updateDepartmentList
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { FieldArray, Formik } from 'formik';
import { Col, Row } from 'antd';
import RemoveIcon from '@assets/removerow.png';
import PlusCircle from '@assets/plus-circle.svg';
import { Input } from '@atom/Input';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';
import { checkDuplicateError } from '../helper';
import { DeleteIconImageTag, DepartmentPositionListWrapper } from './style';

const EmployeeManagement = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [departmentsList, setDepartmentsList] = useState<DepartmentsType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<DepartmentsType[] | null>(null);
  const [duplicateDepartmentError, setDuplicateDepartmentError] = useState<any>({});
  const [duplicatePositionError, setDuplicatePositionError] = useState<any>({});

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getEmployeesHandler();
  }, []);

  const getEmployeesHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getDepartmentList();
    setPageLoading(false);
    if (!result.error) {
      setDepartmentsList(result.data || []);
    }
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getEmployeesHandler();
    onModalCancelHandler();
  };

  const onDepartmentDelete = async (
    index: number,
    arrayHelper: any,
    id?: number | null,
    isPosition = false
  ) => {
    if (id) {
      showToast({
        message: `${isPosition ? 'Position' : 'Department'} Removed Successfully`,
        description: ''
      });
    }
    arrayHelper.remove(index);
  };

  const onSubmitHandler = (values: EditDepartmentType) => {
    setSubmitValue(values.departments);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateCategoriesHandler = async () => {
    if (!submitValue) return;
    setLoading(true);
    const result = await updateDepartmentList({ departments: submitValue });
    setLoading(false);
    if (!result.error) {
      showToast({
        message: 'Departments Updated Successfully',
        description: result?.message || ''
      });
      onCancelHandler();
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Departments',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Formik
          initialValues={{ departments: departmentsList }}
          validationSchema={deptValidationSchema}
          onSubmit={onSubmitHandler}>
          {({ values, handleChange, setFieldValue, handleSubmit, handleBlur, touched, errors }) => {
            useEffect(() => {
              setDuplicateDepartmentError({});
              setDuplicatePositionError({});
              const { parentFieldErrors, childFieldErrors } = checkDuplicateError(
                values,
                'departments',
                'positions',
                duplicateDepartmentErrorMessage,
                duplicatePositionErrorMessage
              );
              setDuplicateDepartmentError(parentFieldErrors);
              setDuplicatePositionError(childFieldErrors);
            }, [values]);

            const onPositionAdd = (index: number) => {
              const updatedCategories = [...values.departments];
              updatedCategories[index].positions = [
                ...updatedCategories[index].positions,
                { name: '', id: null, rate: '', department_id: updatedCategories[index].id }
              ];
              setFieldValue('departments', updatedCategories);
            };

            return (
              <>
                <Row className="hide-mobile-device">
                  <Col md={10}>
                    <H4Typography>Department</H4Typography>
                  </Col>
                  <Col md={14}>
                    <Row gutter={8}>
                      <Col md={14}>
                        <H4Typography>Positions</H4Typography>
                      </Col>
                      <Col md={8}>
                        <H4Typography>Rate</H4Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Flex direction="column">
                  <FieldArray
                    name={'departments'}
                    render={(arrayHelper: any) => {
                      return values?.departments?.map((menu, index) => (
                        <Row>
                          <Col md={10} xs={24}>
                            <Row>
                              <Col md={20} xs={20}>
                                <H4Typography className="hide-full-device">Department</H4Typography>
                                <Input
                                  id={`departments[${index}].name`}
                                  name={`departments[${index}].name`}
                                  value={menu.name}
                                  placeholder={`Department`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    (touched?.departments?.[index]?.name &&
                                      (errors?.departments as { name: string }[])?.[index]?.name) ||
                                    duplicateDepartmentError?.[index]
                                  }
                                />
                              </Col>
                              <Col md={4} xs={4} style={{ opacity: 1 }} className="centerPosition">
                                <DeleteIconImageTag
                                  src={RemoveIcon}
                                  alt=""
                                  onClick={() => onDepartmentDelete(index, arrayHelper, menu?.id)}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={14} xs={24}>
                            <FieldArray
                              name={`departments[${index}].positions`}
                              render={(posArrayHelper: any) => {
                                return menu.positions.map((position, posIndex) => (
                                  <Row gutter={8}>
                                    <Col md={14} xs={24}>
                                      <H4Typography className="hide-full-device">
                                        Positions
                                      </H4Typography>
                                      <Input
                                        id={`departments[${index}].positions[${posIndex}].name`}
                                        name={`departments[${index}].positions[${posIndex}].name`}
                                        value={position.name}
                                        placeholder={`Position`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          (touched?.departments?.[index]?.positions?.[posIndex]
                                            ?.name &&
                                            (
                                              errors?.departments as {
                                                positions: { name: string }[];
                                              }[]
                                            )?.[index]?.positions?.[posIndex]?.name) ||
                                          duplicatePositionError?.[index]?.[posIndex]
                                        }
                                      />
                                    </Col>
                                    <Col md={8} xs={20}>
                                      <H4Typography className="hide-full-device">Rate</H4Typography>
                                      <Input
                                        id={`departments[${index}].positions[${posIndex}].rate`}
                                        name={`departments[${index}].positions[${posIndex}].rate`}
                                        value={position.rate}
                                        placeholder={`Rate`}
                                        isCurrency
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                          touched?.departments?.[index]?.positions?.[posIndex]
                                            ?.rate &&
                                          (
                                            errors?.departments as {
                                              positions: { rate: string }[];
                                            }[]
                                          )?.[index]?.positions?.[posIndex]?.rate
                                        }
                                      />
                                    </Col>
                                    <Col
                                      md={2}
                                      xs={4}
                                      style={{ opacity: 1 }}
                                      className="centerPosition">
                                      <DeleteIconImageTag
                                        src={RemoveIcon}
                                        alt=""
                                        onClick={() =>
                                          onDepartmentDelete(
                                            posIndex,
                                            posArrayHelper,
                                            position?.id,
                                            true
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                ));
                              }}
                            />
                            <Flex bottom={24}>
                              <Button
                                text="Add Position"
                                variant="secondary"
                                style={{ fontWeight: '700' }}
                                icon={<img width={20} src={PlusCircle} alt="add" />}
                                onClick={() => onPositionAdd(index)}
                              />
                            </Flex>
                          </Col>
                        </Row>
                      ));
                    }}
                  />
                  <Flex bottom={24}>
                    <Button
                      text="Add Department"
                      variant="secondary"
                      icon={<img width={20} src={PlusCircle} alt="add" />}
                      style={{ fontWeight: '700' }}
                      onClick={() =>
                        setFieldValue('departments', [
                          ...values.departments,
                          { name: '', id: '', positions: [{ name: '', id: null, rate: '' }] }
                        ])
                      }
                    />
                  </Flex>
                  <Flex className="footerButton" justifyContent={'space-between'}>
                    <Flex alignItems={'center'}></Flex>
                    <Flex gap={8} className="fix-mobile">
                      <Button
                        text="Cancel"
                        variant="secondary"
                        onClick={onCancelHandler}
                        isDisable={loading}
                      />
                      <Button
                        text="Submit"
                        onClick={() => {
                          !Object.keys(errors)?.length &&
                          !Object.keys(duplicateDepartmentError)?.length &&
                          !Object.keys(duplicatePositionError)?.length
                            ? handleSubmit()
                            : showErrorToast({
                                message: 'Failed!',
                                description: 'Please solve error.'
                              });
                        }}
                        isLoading={loading}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Saving will impact existing entries."
          onOK={updateCategoriesHandler}
          isModalLoading={loading}
          okText="Okay">
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <DepartmentPositionListWrapper>
      <Flex bottom={10}>
        <H4Typography className="hide-mobile-device">DEPARTMENTS</H4Typography>
      </Flex>
      <Row>
        {departmentsList.map((category) => (
          <Col key={category.id} md={3} xs={24} style={{ marginBottom: '3rem' }}>
            <p className="hide-full-device title">DEPARTMENTS</p>

            <p>{category.name}</p>
            <p className="title">POSITIONS</p>
            {category.positions.map((subCat) => (
              <p key={subCat.id} style={{ margin: '2px' }}>
                {subCat.name} - {subCat.rate}
              </p>
            ))}
          </Col>
        ))}
      </Row>
    </DepartmentPositionListWrapper>
  );
};

export default EmployeeManagement;
