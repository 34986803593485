import { Col, Divider, Row, Select, Tooltip } from 'antd';
import {
  ContactDetailsType,
  DepartmentFormType,
  DepartmentsType,
  EmployeeType,
  PositionsType,
  ReportsToType,
  addEmployee,
  additionalDepartment,
  employeeInitialValue,
  employeeValidationSchema,
  getDepartmentList,
  getReportsToList,
  uploadFileHandler
} from './helper';
import { FILE_TYPES, STATUS_ENUM } from '@constants/userConstants';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { H1Typography, H2, TypoBodyBody1 } from '@constant/typography/Typography';
import React, { FC, useEffect, useState } from 'react';
import SelectInput, { SelectInputWithCheckBox } from '@atom/Select';
import { getAddressComps, getLocationByPlaceId } from '@utils/googlemaphealper';
import { showErrorToast, showToast } from '@utils/index';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import Checkbox from '@atom/Checkbox';
import FileDraggerComponent from '@molecules/FileDraggerComponent';
import { Flex } from '@atom/Flex';
import FormDataView from '@atom/FormDataView';
import { GoogleAutoComplete } from '@atom/GoogleAutoComplete';
import ImagePreviewer from '@atom/ImagePreviewer';
import { Input } from '@atom/Input';
import InputPhone from '@atom/InputPhone';
import { Label } from '@atom/FormLable';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import PlusCircle from '@assets/plus-circle.svg';
import QuestionMark from '@assets/question-mark.svg';
import RemoveIcon from '@assets/removerow.png';
import StatusLabel from '@atom/StatusLabel';
import { getEmployeeDetails } from '../ViewEmployee/helper';
import { useSelector } from 'react-redux';
import { userType } from '../constants';
import { getSelectOptions } from '@utils/helper';

const { Option } = Select;

interface AddEditEmployeeProp {
  isEdit?: boolean;
}
const AddEditEmployee: FC<AddEditEmployeeProp> = ({ isEdit = false }) => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [departmentsList, setDepartmentsList] = useState<DepartmentsType[]>([]);
  const [reportsToList, setReportsToList] = useState<ReportsToType[]>([]);

  const user = useSelector((state: { user: { user_id: string } }) => state.user);
  const params = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: employeeInitialValue as EmployeeType,
    validationSchema: employeeValidationSchema,
    onSubmit: onSubmitHandler
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    setFieldError,
    setFieldTouched
  } = formik;

  useEffect(() => {
    if (user.user_id) {
      getDepartmentList().then((result) => {
        if (!result.error) {
          setDepartmentsList(result.data || []);
        }
      });
      getReportsToList(user.user_id).then((result) => {
        if (!result.error) {
          setReportsToList(result.data || []);
        }
      });
      (async () => {
        if (isEdit) {
          setPageLoading(true);
          const result = await getEmployeeDetails(params?.id ?? '');
          setPageLoading(false);
          if (!result.error) {
            setValues({
              ...employeeInitialValue,
              ...result.data,
              country_code: result?.data?.country_code?.replace('+', '')
            });
          }
        }
      })();
    }
  }, [user.user_id]);

  async function onSubmitHandler(values: EmployeeType) {
    setLoading(true);
    const updatedValues = { ...values, country_code: `+${values?.country_code || '1'}` };
    const result: any = await addEmployee(updatedValues, isEdit);
    setLoading(false);
    if (result.data) {
      showToast({
        message: `Employee ${isEdit ? 'Updated' : 'Added'} Successfully!`,
        description: ''
      });
      navigate('/employees/all');
    } else {
      if (result?.error?.error?.error?.email[0]) {
        setFieldError('email', result?.error?.error?.error?.email[0]);
      }
      showErrorToast({
        message: `Unable to ${isEdit ? 'update' : 'add'} employee`,
        description: result?.message || 'Please try again'
      });
    }
  }

  const handleFileUpload = async (file: File) => {
    setImageLoading(true);
    const result = await uploadFileHandler(file, FILE_TYPES.PROFILE_PIC);
    setImageLoading(false);
    if (result.error) {
      return showErrorToast({
        message: `Unable to upload profile picture`,
        description: result.error || 'Please try again'
      });
    }
    setFieldValue('profile_pic', result.data.url);
    setFieldValue('imageName', file?.name);
  };

  const onImageRemove = () => {
    setFieldValue('profile_pic', '');
    setFieldValue('imageName', '');
  };

  const deptChangeHandler = (index: number, value: DepartmentsType) => {
    setFieldValue(`departments[${index}].master_department_id`, value ?? '');
    positionChangeHandler(index, undefined!);
  };

  const deptSearchHandler = () => {};

  const onDepartmentRemove = async (index: number, arrayHelper: any, id?: number) => {
    if (id) {
      showToast({ message: 'Department Removed Successfully', description: '' });
    }
    arrayHelper.remove(index);
  };

  const positionChangeHandler = (index: number, value: PositionsType) => {
    setFieldValue(`departments[${index}].position_id`, value);
  };

  const reportsToChangeHandler = (value: ReportsToType) => {
    setFieldValue('reports_to', value);
  };

  const reportsToSearchHandler = () => {};

  const phoneChangeHandler = (a: ContactDetailsType) => {
    setFieldValue('country_code', a?.code ?? '');
    setFieldValue('extension', a?.ext ?? '');
    setFieldValue('phone_number', a?.phone ?? '');
  };

  const onPlaceSelect = async (placeId: string) => {
    if (placeId) {
      const place: any = await getLocationByPlaceId(placeId);
      if (place) {
        const locObj = getAddressComps(place);
        if (locObj.formattedAddress.length > 0) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };
          setFieldValue(
            'address',
            `${locObj?.formattedAddress}${locObj?.neighbourhood && ', ' + locObj?.neighbourhood}${
              locObj?.city && ', ' + locObj?.city
            }${locObj?.state && ', ' + locObj?.state}${
              locObj?.country && ', ' + locObj?.country
            }` || ''
          );
          setFieldValue('latitude', coordinates?.lat || '');
          setFieldValue('longitude', coordinates?.lng || '');
        }
      }
    }
  };

  const imageHelperTooltip = (
    <Flex direction="column" alignItems="end">
      <p>Recommended Profile Size</p>
      <p>360 x 455 px</p>
    </Flex>
  );

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <PageLayout>
      <PageTitle text="EMPLOYEE MANAGEMENT" isBack bordered={true} path="/employees/all" />
      <Flex bottom={26} justifyContent="space-between">
        <H1Typography>{isEdit ? 'EDIT' : 'ADD NEW'} EMPLOYEE</H1Typography>
        {isEdit && (
          <FormDataView
            heading="STATUS"
            value={
              <StatusLabel
                status={
                  values.status === STATUS_ENUM.INACTIVE
                    ? STATUS_ENUM.INACTIVE
                    : values.status || ''
                }
                isUppercase={true}
              />
            }
          />
        )}
      </Flex>
      <div id="map" />
      <Row>
        <Label text="Image" />
        <Row style={{ width: '100%' }}>
          <Flex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <TypoBodyBody1>
              Select a file from your computer, or drag and drop them here.
            </TypoBodyBody1>
            <Tooltip placement="bottomLeft" title={imageHelperTooltip}>
              <img src={QuestionMark} alt="questionMark" />
            </Tooltip>
          </Flex>
          {values.profile_pic ? (
            <ImagePreviewer
              image={values.profile_pic || ''}
              name={values?.imageName}
              closable
              onClose={onImageRemove}
            />
          ) : (
            <FileDraggerComponent
              loading={imageLoading}
              onChangeHandle={handleFileUpload}
              format="pdf"
              multiple={true}
            />
          )}
        </Row>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label text={`Name`} isMandatory={true} />
          <Input
            id="name"
            name="name"
            value={values.name}
            placeholder={`Type Name`}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && errors.name}
          />
        </Col>
        <Col md={12} xs={24}>
          <Label text={`Email`} isMandatory={true} />
          <Input
            id="email"
            name="email"
            value={values.email}
            placeholder={`Type Email`}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label text={`Phone Number`} isMandatory={true} />
          <InputPhone
            id="phone_number"
            name="phone_number"
            value={{
              code: values.country_code,
              phone: values.phone_number,
              ext: values.extension
            }}
            error={
              (touched.phone_number && errors.phone_number) ||
              (touched.extension && errors.extension) ||
              (touched.country_code && errors.country_code) ||
              ''
            }
            onBlur={handleBlur}
            onChange={phoneChangeHandler}
          />
        </Col>
        <Col md={12} xs={24}>
          <Label text={`Address`} />
          <GoogleAutoComplete
            apiKey={process.env.REACT_APP_GOOGLEAPI_KEY}
            id="address"
            name="address"
            value={values?.address}
            placeholder="Type Address"
            onPlaceSelect={(place: any) => {
              if (place) onPlaceSelect(place.place_id);
            }}
            error={touched?.address && errors?.address}
          />
        </Col>
      </Row>

      <Flex direction="column">
        <H2 text="Work Information" />
        <Divider />
      </Flex>
      <FormikProvider value={formik}>
        <FieldArray
          name="departments"
          render={(arrayHelper) => {
            if (!values.departments || !Array.isArray(values.departments)) return null;
            return (
              <>
                <Row className="hide-mobile-device">
                  <Col md={10} xs={24}>
                    <Label text={`Department(s)`} isMandatory={true} />
                  </Col>
                  <Col md={10} xs={20}>
                    <Label text={`Position(s)`} isMandatory={true} />
                  </Col>
                </Row>
                {values.departments.map((item, index) => {
                  return (
                    <Row gutter={[16, 16]}>
                      <Col md={10} xs={24}>
                        <Label
                          className="hide-full-device"
                          text={`Department`}
                          isMandatory={true}
                        />
                        <SelectInput
                          id={`departments[${index}].master_department_id`}
                          name={`departments[${index}].master_department_id`}
                          placeholdertitle="Select One"
                          value={values?.departments?.[index]?.master_department_id}
                          error={
                            touched?.departments?.[index]?.master_department_id &&
                            (errors?.departments as DepartmentFormType[])?.[index]
                              ?.master_department_id
                          }
                          onChange={(e: DepartmentsType) => deptChangeHandler(index, e)}
                          onBlur={handleBlur}
                        >
                          <Option
                            style={{
                              color: '#999',
                              fontSize: '16px',
                              padding: '6px 6px 6px 12px',
                              fontWeight: '400'
                            }}
                            value="disabled"
                            disabled
                          >
                            <>Select One</>
                          </Option>
                          {getSelectOptions(departmentsList, 'name')}
                        </SelectInput>
                      </Col>
                      <Col md={10} xs={20}>
                        <Label className="hide-full-device" text={`Position`} isMandatory={true} />
                        <SelectInputWithCheckBox
                          id={`departments[${index}].position_id`}
                          name={`departments[${index}].position_id`}
                          mode="multiple"
                          placeholdertitle="Select Multiple"
                          options={departmentsList
                            .find((dept) => dept.id === Number(item.master_department_id))
                            ?.positions.map((pos: PositionsType) => {
                              return {
                                title: pos.name,
                                key: pos.id,
                                value: pos.id
                              };
                            })}
                          value={values?.departments?.[index]?.position_id || []}
                          onChange={(e: PositionsType) => positionChangeHandler(index, e)}
                          error={
                            touched?.departments?.[index]?.position_id &&
                            (errors?.departments as DepartmentFormType[])?.[index]?.position_id
                          }
                        />
                      </Col>
                      <Col md={4} xs={4} style={{ alignItems: 'center', display: 'flex' }}>
                        <img
                          src={RemoveIcon}
                          alt="removeIcon"
                          onClick={() => onDepartmentRemove(index, arrayHelper, item?.id)}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </>
            );
          }}
        />
        <Row>
          <Col md={4} xs={24}>
            <Flex bottom={24}>
              <Button
                fullWidth
                text="Add More"
                variant="secondary"
                icon={<img width={20} src={PlusCircle} alt="add" />}
                onClick={() =>
                  setFieldValue('departments', [...values.departments, additionalDepartment])
                }
              />
            </Flex>
          </Col>
        </Row>
      </FormikProvider>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label text={`Reports to`} />
          <SelectInput
            id={`reports_to`}
            name={`reports_to`}
            placeholdertitle="Type to search name"
            value={values?.reports_to}
            error={touched?.reports_to && errors?.reports_to}
            onChange={reportsToChangeHandler}
            onBlur={handleBlur}
          >
            <Option
              style={{
                color: '#999',
                fontSize: '16px',
                padding: '6px 6px 6px 12px',
                fontWeight: '400'
              }}
              value="disabled"
              disabled
            >
              <>Select One</>
            </Option>
            {getSelectOptions(reportsToList, 'text')}
          </SelectInput>
        </Col>
        <Col md={12} xs={24}>
          <Label text={`User Type`} isMandatory={true} />
          <SelectInput
            id="user_type"
            name="user_type"
            placeholdertitle="Select One"
            value={values?.user_type || null}
            onChange={(e: any) => setFieldValue('user_type', e)}
            error={touched?.user_type && errors?.user_type}
          >
            <Option
              style={{
                color: '#999',
                fontSize: '16px',
                padding: '6px 6px 6px 12px',
                fontWeight: '400'
              }}
              value="disabled"
              disabled
            >
              <>Select One</>
            </Option>
            {userType.map((i) => (
              <Option key={i?.id} value={i?.id}>
                {i?.label}
              </Option>
            ))}
          </SelectInput>
        </Col>
      </Row>

      <Flex className="footerButton" justifyContent={'space-between'}>
        <Flex alignItems={'center'} className="fix-mobile">
          {!isEdit && (
            <Checkbox
              name="Send Email Invitation"
              id="send_invite"
              onChange={handleChange}
              checked={values.send_invite}
              error={touched.send_invite && errors.send_invite}
            />
          )}
        </Flex>
        <Flex gap={8} className="fix-mobile">
          <Row gutter={[12, 12]}>
            <Col lg={12} xs={24}>
              <Button
                fullWidth
                text="Cancel"
                variant="secondary"
                onClick={() => navigate('/employees/all')}
                isDisable={loading}
              />
            </Col>
            <Col lg={12} xs={24}>
              <Button fullWidth text="Submit" onClick={handleSubmit} isLoading={loading} />
            </Col>
          </Row>
        </Flex>
      </Flex>
    </PageLayout>
  );
};

export default AddEditEmployee;
