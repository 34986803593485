import { Flex } from '@atom/Flex';
import { Row } from 'antd';
import styled from 'styled-components';

export const TableWrapper = styled(Flex)`
  .ant-table-tbody tr {
    cursor: pointer;
  }
  .ant-pagination-simple-pager {
    .ant-pagination-slash {
      visibility: hidden;
    }
    span::after {
      content: 'of';
      margin-left: 0;
      visibility: visible;
    }
  }
`;

export const QuoteButtonWrapper = styled.div`
  @media (max-width: 768px) {
    .responsive-css {
      display: block !important;
    }
  }
`;
export const CheckboxWrapper = styled.div`
  .ant-checkbox-group-item + .ant-checkbox-group-item {
    align-items: center !important;
  }
  .ant-checkbox-group-item {
    align-items: center !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    color: #fff;
    border-color: #b7b9cc;
    background-color: #2d9cdb !important;
  }

  .ant-checkbox-checked::after {
    border-color: #b7b9cc;
  }

  .ant-checkbox-inner {
    display: block;
    width: 20px;
    height: 20px;
    &:hover {
      border: 1px solid #b7b9cc !important;
      outline: none;
      box-shadow: none;
    }
    &:focus {
      border: 1px solid #b7b9cc !important;
      outline: none;
      box-shadow: none;
    }
    &:active {
      border: 1px solid #b7b9cc !important;
      outline: none;
      box-shadow: none;
    }
  }

  @media (max-width: 768px) {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const EventManagementVenueWrapper = styled.div`
  label {
    margin-bottom: 5px !important;
  }
`;

export const VendorEstimateItemWrapper = styled(Row)`
  .vendorColumn {
    flex: auto;
  }
  @media (max-width: 768px) {
    .vendorColumn {
      flex: 100%;
      padding-right: 0px !important;
    }
  }
`;
