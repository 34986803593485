import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import { DEVICE } from '@constants/index';
import { useWindowSizeChanged } from '@hooks/useWindowSizeChanged';
import AddEditEventFooter from '@pages/EventManagementPage/AddEditEvent/AddEditEventFooter';
import Vendor from '@pages/EventManagementPage/EventDetails/EventInfo/Vendor';
import CustomerInformation from '@pages/QuoteManagementPage/ViewQuoteDetails/CustomerInformation';
import EventDetails from '@pages/QuoteManagementPage/ViewQuoteDetails/EventDetails';
import { getQuote } from '@pages/QuoteManagementPage/ViewQuoteDetails/helper';
import LabourEstimate from '@pages/QuoteManagementPage/ViewQuoteDetails/LabourEstimate';
import MealPlanAndEstimate from '@pages/QuoteManagementPage/ViewQuoteDetails/MealPlanAndEstimate';
import OthersEstimate from '@pages/QuoteManagementPage/ViewQuoteDetails/OthersEstimate';
import Total from '@pages/QuoteManagementPage/ViewQuoteDetails/Total';
import VenueInformation from '@pages/QuoteManagementPage/ViewQuoteDetails/VenueInformation';
import { showErrorToast } from '@utils/index';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { localStorageUtil } from '@utils/localstorageUtil';

function QuoteTab() {
  const { quoteId, eventId } = useParams();
  const navigate = useNavigate();
  const size = useWindowSizeChanged();
  const [quoteData, setQuoteData] = useState<any>({});
  const [isLoadingQuote, setIsLoadingQuote] = useState<boolean>(true);
  const device = useWindowSizeChanged();
  const location = localStorageUtil.getValue('location');
  useEffect(() => {
    updateQuoteData(quoteId);
  }, [quoteId]);

  const updateQuoteData = async (quoteId: any) => {
    setIsLoadingQuote(true);
    try {
      const res = await getQuote(quoteId);
      if (res?.success) {
        setQuoteData(res?.data);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch quote data'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch quote data'
      });
    }
    setIsLoadingQuote(false);
  };
  const onNext = () => {
    const url = eventId
      ? `/events/find-quote/create/${quoteId}/${eventId}/staffing`
      : `/events/find-quote/create/${quoteId}/staffing`;
    localStorageUtil.setValue('location', {
      state: {
        prevPath: location?.pathname,
        eventId: eventId,
        path: location?.state?.path
      }
    });
    navigate(url, {
      state: {
        prevPath: location?.pathname,
        eventId: eventId,
        path: location?.state?.path
      }
    });
  };

  const onEditQuote = () => {
    localStorageUtil.setValue('location', {
      state: {
        prevPath: location?.pathname,
        eventId: eventId,
        path: location?.state?.path
      }
    });
    navigate(`/quote/edit-quote/${quoteId}/customer`, {
      state: {
        prevPath: location?.pathname,
        eventId: eventId,
        path: location?.state?.path
      }
    });
  };
  if (isLoadingQuote) {
    return <CenterSpiner />;
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24} md={12}>
          <Button
            text={'Edit Quote'}
            variant={'secondary'}
            onClick={onEditQuote}
            fullWidth={size === DEVICE.MOBILE}
          />
        </Col>
        <Col lg={12} md={12} xs={24}>
          <Row gutter={24}>
            <Col lg={12} xs={24}></Col>
            <Col lg={12} xs={24}>
              <Row gutter={[16, 16]} className="hide-btn">
                <Col lg={12} md={12} xs={24}>
                  <Button
                    text={'Cancel'}
                    variant={'secondary'}
                    fullWidth
                    onClick={() => navigate('/events')}
                  />
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Button text={'Next'} fullWidth onClick={onNext} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <CustomerInformation data={quoteData} isQuoteTab={true} />
      <VenueInformation data={quoteData} isQuoteTab={true} />
      <EventDetails data={quoteData} isQuoteTab={true} />
      <MealPlanAndEstimate data={quoteData} isEventData={false} eventDate={''} />
      <LabourEstimate data={quoteData} />
      <Vendor data={quoteData.event_dates} isQuoteTab={true} />
      <OthersEstimate data={quoteData} />
      <Total data={quoteData} hideButtons={true} />

      <AddEditEventFooter
        showBackButton={true}
        backClickHandler={() => navigate('/events')}
        cancelClickHandler={() => navigate('/events')}
        nextClickHandler={onNext}
      />
    </>
  );
}

export default QuoteTab;
