import services from '@services/index';
import { ResponseType } from '../helper';

// types
export interface LogoLibraryType {
  id: number;
  image_url: string;
}

// api calls
export const getLogoList = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings/logo-gallery`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const uploadLogoList = async (data: any): Promise<ResponseType> => {
  try {
    const res = (await services.post(`/settings/logo-gallery`, data, undefined, null, {
      'Content-Type': 'multipart/form-data'
    })) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    const key = error?.error?.error ? Object.keys(error?.error?.error)?.[0] : undefined;
    return {
      data: null,
      error: key ? error?.error?.error?.[key][0] : error?.error?.message || 'Something went wrong!'
    };
  }
};

export const removeLogo = async (id: number): Promise<ResponseType> => {
  try {
    const res = (await services.delete(`/settings/logo-gallery/${id}`)) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};
