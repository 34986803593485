import React, { useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4Typography } from '@constant/typography/Typography';
import {
  EditRestrictionType,
  getMenuDetails,
  RestrictionListType,
  restrictionValidationSchema,
  updateMenuNames
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Formik, FormikProps } from 'formik';
import MenuManagementEditableVirtualList from './MenuManagementEditableVirtualList';

import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';

const DietaryRestrictions = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [restrictions, setRestrictions] = useState<RestrictionListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<RestrictionListType[] | null>(null);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);
  const [scrollToBottomOfList, setScrollToBottomOfList] = useState<boolean>(false);

  useEffect(() => {
    getRestrictionsHandler();
  }, []);

  const getRestrictionsHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getMenuDetails('dietary-restrictions');
    setPageLoading(false);
    if (!result.error) {
      setRestrictions(result.data || []);
    }
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getRestrictionsHandler();
    onModalCancelHandler();
  };

  const onDietaryRestrictionDelete = (
    index: number,
    ingridentsArray: any,
    setFieldValue: any,
    id?: number | null
  ) => {
    if (id) {
      showToast({ message: 'Dietary Restriction Removed Successfully', description: '' });
    }
    const tempArr = [...JSON.parse(JSON.stringify(ingridentsArray))];
    tempArr?.splice(index, 1);
    setFieldValue('dietary_restrictions', tempArr);
    return true;
  };

  const onSubmitHandler = (values: EditRestrictionType) => {
    setSubmitValue(values.dietary_restrictions);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateMenuNametHandler = async () => {
    if (!submitValue) return;
    setLoading(true);
    const result = await updateMenuNames(
      { dietary_restrictions: submitValue },
      'dietary-restrictions'
    );
    setLoading(false);
    if (!result.error) {
      showToast({ message: 'Dietary Restriction Updated Successfully', description: '' });
      onCancelHandler();
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Dietary Restrictions',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Flex bottom={26}>
          <H4Typography>MANAGE DIETARY RESTRICTIONS / ALLERGENS</H4Typography>
        </Flex>
        <Formik
          initialValues={{ dietary_restrictions: restrictions }}
          validationSchema={restrictionValidationSchema}
          onSubmit={onSubmitHandler}>
          {(formikProps: FormikProps<any>) => {
            const { values, setFieldValue, handleSubmit } = formikProps;
            return (
              <Flex direction="column">
                <MenuManagementEditableVirtualList
                  formik={formikProps}
                  onItemDelete={onDietaryRestrictionDelete}
                  fieldArrayName="dietary_restrictions"
                  inputFieldPlaceHolder="Dietary Restriction Name"
                  scrollToBottomOfList={scrollToBottomOfList}
                  setScrollToBottomOfList={setScrollToBottomOfList}
                />
                <Flex bottom={24}>
                  <Button
                    text="Add Dietary Restriction"
                    variant="secondary"
                    icon={<img width={20} src={PlusCircle} alt="add" />}
                    onClick={() => {
                      setFieldValue('dietary_restrictions', [
                        ...values.dietary_restrictions,
                        { name: '', id: '' }
                      ]);
                      setScrollToBottomOfList(true);
                    }}
                  />
                </Flex>
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8} className="fix-mobile">
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button text="Submit" onClick={handleSubmit} isLoading={loading} />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Saving will impact existing entries."
          onOK={updateMenuNametHandler}
          isModalLoading={loading}
          okText="Okay">
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <div>
      <Flex bottom={26}>
        <H4Typography>MANAGE DIETARY RESTRICTIONS / ALLERGENS</H4Typography>
      </Flex>
      <Flex style={{ flexFlow: 'wrap', gap: '16px 40px' }}>
        {restrictions.map((restr) => (
          <Flex key={restr.id}>
            <p
              style={{
                backgroundColor: '#F4F4F4',
                borderRadius: '5px',
                padding: '5px 10px',
                margin: 0
              }}>
              {restr.name}
            </p>
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

export default DietaryRestrictions;
