import FormDataView from '@atom/FormDataView';
import { H2Typography } from '@constant/typography/Typography';
import { Divider, SectionTitleDiv } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';
import { Col, Row } from 'antd';
import React from 'react';
import { kitchenAvailabilityList } from '@pages/QuoteManagementPage/AddEditQuote/Venue/constants';
import { useSelector } from 'react-redux';
import { DataBoxDiv } from './style';

interface RemainderVenueDetailsProps {
  data: any;
}

const RemainderVenueDetails = (props: RemainderVenueDetailsProps) => {
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;
  const returnYesOrNo = (flag: string | number) => {
    if (flag == 0) {
      return 'No';
    } else if (flag == 1) {
      return 'Yes';
    }
    return null;
  };

  const kitchenAvailabilityName = (id: number) => {
    let name;
    kitchenAvailabilityList.map((obj) => {
      if (obj.id == id) {
        name = obj.name;
      }
    });
    return name;
  };
  const { data } = props;

  const renderDataBox = (heading: string, value: any) => (
    <FormDataView
      heading={heading}
      value={
        value
        // <DataBoxDiv>
        //   {value || (
        //     <span style={{ color: 'rgba(0, 0, 0, 0.5)', paddingTop: '5px' }}>Not Specified</span>
        //   )}
        // </DataBoxDiv>
      }
    />
  );

  const renderCoatCheckInformation = (coatCheck: string, additionalInfo: string) => {
    let displayInfo = returnYesOrNo(coatCheck);

    if (additionalInfo) {
      displayInfo += `, ${additionalInfo}`;
    }
    return displayInfo;
  };

  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'event-section'}>Venue Details</H2Typography>
          </SectionTitleDiv>
        </Col>
        <Col span={24}>
          <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
            {data?.pointOfContactDetails?.length ? (
              data.pointOfContactDetails.map((details: any, index: number) => (
                <React.Fragment key={index}>
                  <Col md={8} xs={12}>
                    <FormDataView heading="VENUE CONTACT " value={details?.contact_name} />
                  </Col>
                  <Col md={8} xs={12}>
                    <FormDataView
                      heading="VENUE PHONE NUMBER"
                      value={
                        details?.contact_phone_number ? (
                          <a
                            href={`tel:${details?.country_code ?? ''}${
                              details?.contact_phone_number
                            }`}
                            style={{ color: 'black' }}>
                            {`${details?.country_code ?? ''} ${details?.contact_phone_number} ${
                              details?.extension ? 'x' + details?.extension : ''
                            }`}
                          </a>
                        ) : (
                          ''
                        )
                      }
                    />
                  </Col>
                  <Col md={8} xs={24}>
                    <FormDataView
                      heading="VENUE CONTACT EMAIL "
                      value={
                        <a href={`mailto:${details?.contact_email}`} style={{ color: 'black' }}>
                          {details?.contact_email}
                        </a>
                      }
                    />
                  </Col>
                </React.Fragment>
              ))
            ) : (
              <>
                <Col md={8} xs={12}>
                  <FormDataView heading="VENUE CONTACT" value={''} />
                </Col>
                <Col md={8} xs={12}>
                  <FormDataView heading="VENUE PHONE NUMBER" value={''} />
                </Col>
                <Col md={8} xs={24}>
                  <FormDataView heading="VENUE CONTACT EMAIL" value={''} />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Row gutter={16} style={{ marginTop: '32px' }}>
          <Col span={24}>
            <H2Typography style={{ fontWeight: 400 }}>Kitchen Details</H2Typography>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col md={8} xs={12}>
            <FormDataView
              heading="POWER"
              value={returnYesOrNo(data?.venue_detail?.power_available)}
            />
          </Col>
          <Col md={8} xs={12}>
            <FormDataView
              heading="RUNNING WATER"
              value={returnYesOrNo(data?.venue_detail?.running_water)}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormDataView
              heading="KITCHEN AVAILABILITY"
              value={kitchenAvailabilityName(data?.venue_detail?.kitchen_availability)}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12} xs={24}>
            {renderDataBox('IN HOUSE COOKING EQUIPMENT', data?.venue_detail?.cooking_equipments)}
          </Col>
          <Col md={12} xs={24}>
            {renderDataBox('SPECIAL RENTAL EQUIPMENT', data?.venue_detail?.special_equipments)}
          </Col>
          <Col md={24} xs={24}>
            {renderDataBox('CATERING RENTALS REQUIRED', data?.venue_detail?.rental_equipments)}
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: '32px' }}>
          <Col span={24}>
            <H2Typography style={{ fontWeight: 400 }}>Alcohol & Bar Set Up</H2Typography>
            <Divider />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '10px' }}>
          <Col md={24} xs={24}>
            {renderDataBox(
              'BAR SERVICE AND REQUIREMENTS',
              data?.venue_detail?.alcohol_bar_setup_requirements
            )}
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '32px' }}>
          <Col span={24}>
            <H2Typography style={{ fontWeight: 400 }}>Set Up & Closing Duties</H2Typography>
            <Divider />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '10px' }}>
          <Col md={12} xs={24}>
            {renderDataBox('SET UP REQUIREMENTS', data?.venue_detail?.setup_duty_requirements)}
          </Col>
          <Col md={12} xs={24}>
            {renderDataBox('CLOSING REQUIREMENTS', data?.venue_detail?.closing_duty_requirements)}
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: '32px' }}>
          <Col span={24}>
            <H2Typography style={{ fontWeight: 400 }}>Service Information</H2Typography>
            <Divider />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '10px' }}>
          <Col md={12} xs={24}>
            {renderDataBox('staff details', data?.venue_detail?.staff_additional_restriction)}
          </Col>
          <Col md={12} xs={24}>
            {renderDataBox(
              'Coat Check & Additional information',
              renderCoatCheckInformation(
                data?.venue_detail?.coat_check,
                data?.venue_detail?.coat_check_additional_information
              )
            )}
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: '32px' }}>
          <Col span={24}>
            <H2Typography style={{ fontWeight: 400 }}>Additional Information</H2Typography>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={24}>
            {renderDataBox('', data?.venue_detail?.additional_notes)}
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: '32px' }}>
          <Col span={24}>
            <H2Typography style={{ fontWeight: 400 }}>Loading Dock</H2Typography>
            <Divider />
          </Col>
          <Col md={24} xs={24}>
            {renderDataBox(
              '',
              Number(data?.venue_detail?.loading_dock)
                ? data?.venue_detail?.loading_parking_additional_information
                : ''
            )}
          </Col>
        </Row>
      </div>
      {/* <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography style={{ fontWeight: 400 }}>Alcohol Bar & Set Up</H2Typography>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col md={24} xs={24}>
          <FormDataView
            heading="VENUE IN CHARGE"
            value={returnYesOrNo(data?.alcohol_bar_venue_in_charge)}
          />
        </Col>
        <Col md={24} xs={24}>
          <FormDataView
            heading="BAR SERVICE AND REQUIREMENTS"
            value={data?.alcohol_bar_setup_requirements}
          />
        </Col>
      </Row> */}

      {/* <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography style={{ fontWeight: 400 }}>
            Loading Dock & Parking Information
          </H2Typography>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col md={8} xs={24}>
          <FormDataView heading="LOADING DOCK" value={returnYesOrNo(data?.loading_dock)} />
        </Col>
        <Col md={8} xs={24}>
          <FormDataView heading="PARKING" value={returnYesOrNo(data?.parking)} />
        </Col>
        <Col md={24} xs={24}>
          <FormDataView
            heading="ADDITIONAL INFORMATION"
            value={data?.loading_parking_additional_information}
          />
        </Col>
      </Row> */}
      {/* <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography style={{ fontWeight: 400 }}>Service Information</H2Typography>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col md={24} xs={24}>
          <FormDataView heading="COAT CHECK" value={returnYesOrNo(data?.coat_check)} />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography style={{ fontWeight: 400 }}>Staff Details</H2Typography>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col md={24} xs={24}>
          <FormDataView
            heading="ADDITIONAL RESTRICTION"
            value={data?.staff_additional_restriction}
          />
        </Col>
      </Row> */}
      {/* <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography style={{ fontWeight: 400 }}>Landmark Fees</H2Typography>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={8}>
          <FormDataView heading="FOOD" value={data?.food_fees} />
        </Col>
        <Col md={4} xs={8}>
          <FormDataView heading="ALCOHOL & SOFT BAR" value={data?.alcohol_soft_bar_fees} />
        </Col>
        <Col md={4} xs={8}>
          <FormDataView heading="LABOUR" value={data?.labour_fees} />
        </Col>
      </Row>  */}
      {/* {isAdmin && (
        <>
          <Row gutter={16} style={{ marginTop: '32px' }}>
            <Col span={24}>
              <H2Typography style={{ fontWeight: 400 }}>Landmark Fees</H2Typography>
              <Divider />
            </Col>
          </Row>
          <Row>
            {[
              { heading: 'FOOD', field: 'food_fees' },
              { heading: 'ALCOHOL & SOFT BAR', field: 'alcohol_soft_bar_fees' },
              { heading: 'LABOUR', field: 'labour_fees' }
            ].map((item, index) => (
              <Col key={index} md={4} xs={8}>
                <FormDataView
                  heading={item.heading}
                  value={
                    data && data[item.field]
                      ? Number(data[item.field]) % 1 === 0
                        ? `${Number(data[item.field])}%`
                        : `${Number(data[item.field]).toFixed(2)}%`
                      : ''
                  }
                />
              </Col>
            ))}
          </Row>
        </>
      )} */}
      {/* <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <H2Typography style={{ fontWeight: 400 }}>Additional Notes</H2Typography>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col md={24} xs={24}>
          <FormDataView heading="ADDITIONAL NOTES" value={data?.additional_notes} />
        </Col>
      </Row> */}
    </>
  );
};

export default RemainderVenueDetails;
