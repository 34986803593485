import styled from 'styled-components';

export const DeleteIconImageTag = styled.img`
  @media (max-width: 767px) {
    position: absolute;
    top: 40px;
  }
`;

export const DepartmentPositionListWrapper = styled.div`
  .title {
    margin: 0px;
    margin-bottom: 5px;
    font-weight: bold;
  }
`;
