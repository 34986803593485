import services from '@services/index';
import { ITimeType, IWorkOrderListFilterType, MyScheduleType } from './type';

export enum WORK_ORDER_MANAGEMENT_TABS {
  UPCOMING = 'upcoming',
  PAST = 'past',
  MY_SCHEDULE = 'my-schedule'
}

export const MyScheduleDummyData: { schedules: ITimeType[] } = {
  schedules: [
    {
      start_time: null,
      end_time: null,
      day: 'Sunday'
    },
    {
      start_time: null,
      end_time: null,
      day: 'Monday'
    },
    {
      start_time: null,
      end_time: null,
      day: 'Tuesday'
    },
    {
      start_time: null,
      end_time: null,
      day: 'Wednesday'
    },
    {
      start_time: null,
      end_time: null,
      day: 'Thursday'
    },
    {
      start_time: null,
      end_time: null,
      day: 'Friday'
    },
    {
      start_time: null,
      end_time: null,
      day: 'Saturday'
    }
  ]
};

export const PastRecordDummyData = {
  add_staffs: [
    {
      arrival_time: '14:00:00',
      country_code: '23',
      employee: 'Anna Matreninskaya',
      extension: '+1',
      is_additional: 1,
      phone_number: 1234567891,
      position: 'Event Manager'
    }
  ],
  event_details: {
    contact_email: 'asdasd',
    end_time: '2020-06-30T18:00:00.000Z',
    event_dates: '2020-06-30T18:00:00.000Z',
    event_name: 'asdasd',
    no_of_guest: 90,
    start_time: '2020-06-30T18:00:00.000Z'
  },
  important_info: {
    event_address: '27 Berkley Rd, Parry Sound, ON, Canada',
    event_date: '2021-09-17',
    time_scheduled: '02:00 PM - 12:00 AM',
    venue: 'K & J Family Cottage'
  },
  staffs: [
    {
      arrival_time: '14:00:00',
      country_code: '23',
      employee: 'Adrian Niman',
      extension: '+1',
      is_additional: 0,
      phone_number: 1234567891,
      position: 'Head Chef'
    },
    {
      arrival_time: '14:00:00',
      country_code: '23',
      employee: 'Adrian Niman',
      extension: '+1',
      is_additional: 0,
      phone_number: 1234567891,
      position: 'Head Chef'
    },
    {
      arrival_time: '14:00:00',
      country_code: '23',
      employee: 'Adrian Niman',
      extension: '+1',
      is_additional: 0,
      phone_number: 1234567891,
      position: 'Head Chef'
    },
    {
      arrival_time: '14:00:00',
      country_code: '23',
      employee: 'Adrian Niman',
      extension: '+1',
      is_additional: 0,
      phone_number: 1234567891,
      position: 'Head Chef'
    },
    {
      arrival_time: '14:00:00',
      country_code: '23',
      employee: 'Adrian Niman',
      extension: 0,
      is_additional: 0,
      phone_number: 1234567891,
      position: 'Head Chef'
    },
    {
      arrival_time: '14:00:00',
      country_code: '23',
      employee: 'Adrian Niman',
      extension: 0,
      is_additional: 0,
      phone_number: 1234567891,
      position: 'Head Chef'
    }
  ],
  supervisor: {
    country_code: '+1',
    email: 'saiprasad.veturekar@torinit.ca',
    employee: 'Dave Schwingenschloegl',
    extension: '21',
    phone_number: 123456789
  }
};

export const getUpcomingRecordsList = async (page: number, data: IWorkOrderListFilterType) => {
  try {
    const res: any = await services.post(`/work-orders/upcoming-records?page=${page || 1}`, data);
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getPastRecordsList = async (page: number, data: IWorkOrderListFilterType) => {
  try {
    const res: any = await services.post(`/work-orders/past-records?page=${page || 1}`, data);
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getMyScheduleData = async () => {
  try {
    const res: any = await services.get(`/work-orders/my-schedule`);
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const submitMyScheduleData = async (schedules: MyScheduleType) => {
  try {
    const res: any = await services.put(`/work-orders/my-schedule`, schedules);
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getWorkOrderDetails = async (
  event_id: number | string,
  event_staffing_id: number | string,
  employee_id: number | string
) => {
  try {
    const res = (await services.get(
      `/work-orders/details/${event_id}/${event_staffing_id}/${employee_id}`
    )) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
