import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  .add-another-btn {
    border: none;
    padding: 0px;
    font-weight: bolder;
    font-size: 14px;
    line-height: 1;
    color: #000;
    border-bottom: 1px solid;
    border-radius: 0;
    margin-left: 10px;
  }
`;

export const LabelWrapper = styled.div`
  .time-label-style Label {
    line-height: 21px;
    align-items: center;
    text-align: center;
    color: #000;
    mix-blend-mode: normal;
    background: #f4f4f4;
    border-radius: 3px;
    padding: 11px 0;
    display: block;
    width: 100%;
  }
`;
