import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { formatCurrency } from '@utils/format';
import { Col, Row } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import GeneratePDFModal from './GeneratePDFModal';
import { getGenerateOverviewProposalModalData, getLabel } from './helper';
import { ButtonStyleWrapper, Divider, SectionTitleDiv } from './style';

interface TotalProps {
  data: any;
  hideButtons?: boolean;
  updateQuoteData?: (quoteId: any) => void;
}

const Total = (props: TotalProps) => {
  const user = useSelector((state: { user: any }) => state.user);
  const { data, hideButtons, updateQuoteData } = props;
  const { isAdmin } = user;
  const { quoteId } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string | undefined>('');
  const [payload, setPayload] = useState(
    getGenerateOverviewProposalModalData({
      quote_id: quoteId,
      unique_key: data?.unique_invoice_string,
      cover_page: data?.pdf_images?.[0]?.image,
      end_page: data?.pdf_images?.[1]?.image,
      logo: data?.pdf_images?.[2]?.image
    })
  );
  const backupPayload = getGenerateOverviewProposalModalData({
    quote_id: quoteId,
    unique_key: data?.unique_invoice_string,
    cover_page: data?.pdf_images?.[0]?.image,
    end_page: data?.pdf_images?.[1]?.image,
    logo: data?.pdf_images?.[2]?.image
  });

  const serviceFees =
    Number(data?.service_net_total_fees || 0) ||
    Math.round(
      (Number(data?.service_food_fees || 0) +
        Number(data?.service_alcohol_fees || 0) +
        Number(data?.service_labour_fees || 0)) *
        100
    ) / 100;
  const landmarkFees =
    Number(data?.landmark_net_total_fees || 0) ||
    Math.round(
      (Number(data?.landmark_food_fees || 0) +
        Number(data?.landmark_alcohol_fees || 0) +
        Number(data?.landmark_labour_fees || 0)) *
        100
    ) / 100;
  const subTotal =
    Math.round(
      (Number(data?.quote_subtotal || 0) + serviceFees + landmarkFees + Number.EPSILON) * 100
    ) /
      100 -
    data?.waived_fees;

  const discount =
    data?.adjustments ||
    Math.round(
      (Number(data?.discount_amount || 0) +
        Number(data?.discount_alcohol_fees || 0) +
        Number(data?.discount_food_fees || 0) +
        Number(data?.discount_labour_fees || 0)) *
        100
    ) / 100;
  const ratePerPerson =
    data?.grand_total &&
    data?.total_guest &&
    Math.round(
      (Number(data?.grand_total || 0) / Number(data?.total_guest) + Number.EPSILON) * 100
    ) / 100;

  // const netBalance =
  //   data?.grand_total &&
  //   data?.payment &&
  //   Number(data?.grand_total || 0) - Number(data?.payment || 0);

  const enum ACTION_TYPES {
    GENERATE_OVERVIEW = 'generate_overview',
    GENERATE_PROPOSAL = 'generate_proposal'
  }

  const serviceFeesLabel = getLabel(
    data,
    'service_food_fees',
    'service_food_fees_percent',
    'service_alcohol_fees',
    'service_alcohol_fees_percent',
    'service_labour_fees',
    'service_labour_fees_percent',
    'service_net_total_fees_percent'
  );
  const landmarkFeesLabel = getLabel(
    data,
    'landmark_food_fees',
    'landmark_food_fees_percent',
    'landmark_alcohol_fees',
    'landmark_alcohol_fees_percent',
    'landmark_labour_fees',
    'landmark_labour_fees_percent',
    'landmark_net_total_fees_percent'
  );
  const discountLabel = getLabel(
    data,
    'discount_food_fees',
    'discount_food_fees_percent',
    'discount_alcohol_fees',
    'discount_alcohol_fees_percent',
    'discount_labour_fees',
    'discount_labour_fees_percent',
    '',
    true
  );

  const handleGenerateProposal = () => {
    if (data?.proposal_pdf) {
      const newWindow = window.open(data?.proposal_pdf, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      setActionType(ACTION_TYPES.GENERATE_PROPOSAL);
      setShowModal(true);
    }
  };

  const handleGenerateQuoteOverview = () => {
    if (data?.overview_pdf) {
      const newWindow = window.open(data?.overview_pdf, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      setActionType(ACTION_TYPES.GENERATE_PROPOSAL);
      setShowModal(true);
    }
  };

  const handleSendEmailToClient = () => {};

  const openPDFsHandler = (overview_pdf_link: string, proposal_pdf_link: string) => {
    window.open(overview_pdf_link, '_blank', 'noopener,noreferrer');
    window.open(proposal_pdf_link, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col xs={24} md={24}>
          <SectionTitleDiv>
            <H2Typography className={'quote-section'}>Total</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      <Flex justifyContent="space-between" style={{ marginTop: '10px', fontSize: '1rem' }}>
        <span>Subtotal Cost</span>
        <span>{formatCurrency(data?.quote_subtotal) || '0.00'}</span>
      </Flex>
      {data?.waived_fees && Number(data?.waived_fees) ? (
        <Flex justifyContent="space-between" style={{ color: '#FF0000', fontSize: '1rem' }}>
          <span>Waived Fees</span>
          <span>-{formatCurrency(data?.waived_fees) || '-'}</span>
        </Flex>
      ) : null}
      {serviceFees ? (
        <Flex justifyContent="space-between" style={{ fontSize: '1rem' }}>
          <span>{`Service Fee ${serviceFeesLabel ? `(${serviceFeesLabel})` : ''} ${
            data?.service_fees_instructions ? `(${data?.service_fees_instructions})` : ''
          }`}</span>
          <span>{formatCurrency(serviceFees) || '-'}</span>
        </Flex>
      ) : null}
      {landmarkFees ? (
        <Flex justifyContent="space-between" style={{ fontSize: '1rem' }}>
          <span>{`Landmark Fee ${landmarkFeesLabel ? `(${landmarkFeesLabel})` : ''}`}</span>
          <span>{formatCurrency(landmarkFees) || '-'}</span>
        </Flex>
      ) : null}
      <Divider style={{ margin: '20px 0px', fontSize: '1rem' }} />
      <Flex justifyContent="space-between">
        <span style={{ fontSize: '16px' }}>Subtotal</span>
        <span>{formatCurrency(subTotal) || '0.00'}</span>
      </Flex>

      {discount ? (
        <Flex justifyContent="space-between" style={{ color: '#FF0000', fontSize: '1rem' }}>
          <span>{`Discount ${discountLabel ? `(${discountLabel})` : ''} ${
            data?.adjustment_instructions ? `(${data?.adjustment_instructions})` : ''
          }`}</span>
          <span>-{formatCurrency(discount) || '-'}</span>
        </Flex>
      ) : null}
      <Flex justifyContent="space-between" style={{ fontSize: '1rem' }}>
        <span>
          {`HST (${data?.hst_fees_percent || '-'}%)`}
          <span style={{ color: '#FF0000' }}>{`${
            data?.hst_fees_instructions ? `(${data?.hst_fees_instructions})` : ''
          }`}</span>
        </span>
        <span>{formatCurrency(data?.hst_fees) || '0.00'}</span>
      </Flex>
      <Divider style={{ margin: '20px 0px' }} />
      <Flex justifyContent="space-between" style={{ fontSize: '1rem' }}>
        <span>Grand Total</span>
        <span>{formatCurrency(data?.grand_total) || '0.00'}</span>
      </Flex>
      {data?.deposit && Number(data?.deposit) ? (
        <Flex justifyContent="space-between" style={{ color: '#FF0000', fontSize: '1rem' }}>
          {data?.deposit_percent && Number(data?.deposit_percent) ? (
            <span>{`Deposit (${data?.deposit_percent || '-'}%)`}</span>
          ) : (
            <span>Deposit</span>
          )}
          <span>-{formatCurrency(data?.deposit) || '-'}</span>
        </Flex>
      ) : null}
      {data?.payment && Number(data?.payment) ? (
        <Flex justifyContent="space-between" style={{ color: '#FF0000', fontSize: '1rem' }}>
          <span>Payment</span>
          <span>-{formatCurrency(data?.payment) || '-'}</span>
        </Flex>
      ) : null}
      <Divider style={{ margin: '20px 0px' }} />
      {data?.net_balance && Number(data?.net_balance) ? (
        <Flex justifyContent="space-between" style={{ fontSize: '1rem' }}>
          <span>Net Balance</span>
          <span>{formatCurrency(data?.net_balance) || '-'}</span>
        </Flex>
      ) : null}
      {ratePerPerson ? (
        <Flex justifyContent="space-between" style={{ fontSize: '1rem' }}>
          <span>Rate Per Person</span>
          <span>{formatCurrency(ratePerPerson) || '-'}</span>
        </Flex>
      ) : null}
      {isAdmin && !hideButtons && (
        <ButtonStyleWrapper>
          <Flex className="fix-mobile" justifyContent="flex-end" gap={10}>
            <Button
              text="Generate Proposal"
              variant="secondary"
              style={{ minWidth: '237px', fontSize: '16px' }}
              onClick={handleGenerateProposal}
            />
            <Button
              text="Generate Quote Overview"
              variant="secondary"
              style={{ minWidth: '237px', fontSize: '16px' }}
              onClick={handleGenerateQuoteOverview}
            />
          </Flex>
          <Divider />
          <Flex className="fix-mobile" justifyContent="flex-end" gap={10}>
            <Button
              text="Email To Client"
              variant="primary"
              isDisable={true}
              style={{ width: '237px', fontSize: '16px' }}
              onClick={handleSendEmailToClient}
            />
          </Flex>
        </ButtonStyleWrapper>
      )}
      <GeneratePDFModal
        isModalVisible={showModal}
        setShowModal={setShowModal}
        data={payload}
        backupData={backupPayload}
        setData={setPayload}
        updateQuoteData={updateQuoteData}
        openPDFsHandler={openPDFsHandler}
      />
    </>
  );
};

export default Total;
