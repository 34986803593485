import styled from 'styled-components';

export const ViewTableCell = styled.div`
  .fixTD .ant-table-tbody > tr > td {
    padding: 10px 2px;
  }
  .fixTD .ant-table-thead > tr > th {
    min-width: 200px;
    width: 200px;
  }
  .fixTD .ant-table-tbody > tr > td {
    min-width: 200px;
    width: 200px;
  }
  .dataTd {
    min-width: 80px !important;
    width: 80px !important;
    .ant-checkbox-wrapper {
      position: relative;
      left: 25px;
      top: 10px;
    }
  }

  .doneTd {
    min-width: 40px !important;
    width: 40px !important;
    .ant-checkbox-wrapper {
      position: relative;
      right: 0px;
      top: 0px;
    }
  }

  .fixTD .ant-table-tbody > tr > td:nth-child(6) {
    min-width: 80px !important;
    width: 80px !important;
    .ant-checkbox-wrapper {
      position: relative;
      left: 25px;
    }
  }
  .header-align {
    text-align: center;
    .ant-checkbox-inner .ant-checkbox-wrapper {
      position: absolute;
      left: 25px;
      top: 0px;
    }
  }
  .ant-table-header {
    overflow: visible !important;
  }
  .clear-button {
    height: 30px;
  }
  .cancel-btn {
    padding: 10px 6px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    color: #fff;
    border-color: #b7b9cc;
    background-color: #ff8a00 !important;
  }
`;

export const ProductDetailWrapper = styled.div`
  .content-div {
    margin-bottom: 15px !important;
    .content-title {
      margin-bottom: 7px !important;
    }
  }
`;
