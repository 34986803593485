import styled from 'styled-components';

export const GlobalSettingEditableVirtualListContainer = styled.div`
  border: none;
  width: 100%;
  padding: 10px;
  min-height: ${(props: { dataLength?: number }) =>
    `${
      props?.dataLength && props?.dataLength >= 7
        ? '80vh'
        : props?.dataLength && props?.dataLength > 0
        ? `${props?.dataLength * 85}px`
        : '50px'
    }`};
`;
