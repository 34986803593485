import {
  CREATE_EVENT_TAB_KEYS,
  NEXT_TAB,
  getEventManagementTabs,
  getTabBaseUrl,
  submitForm
} from './helper';
import { showErrorToast, updateRawData } from '@utils/index';
import { useNavigate, useParams } from 'react-router';

import HorizontalTab from '@molecules/HorizontalTabs';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { getEventDetails } from '@pages/QuoteManagementPage/AddEditQuote/helper';
import { useSelector } from 'react-redux';
import BreadCrumbs from '@atom/BreadCrumb';
import {
  EventEditBreadCrumbs,
  EventWithoutQuoteBreadCrumbs,
  QuoteViewEventEditEventBreadCrumbs
} from '../EventDetails/helper';
import { localStorageUtil } from '@utils/localstorageUtil';

function AddEditEvent() {
  const navigate = useNavigate();
  const location = localStorageUtil.getValue('location');
  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const params = useParams();
  const { eventId, quoteId, formType } = params;

  const submitHandler = async (tabKey: string, values: any, actions: any, method: string) => {
    try {
      const res: any = await submitForm(tabKey, values, method);
      if (res?.success) {
        actions && actions.setSubmitting(false);
        if (!!res?.data?.unique_invoice_string) {
          updateRawData({ unique_invoice_string: res?.data?.unique_invoice_string });
        }
        if (tabKey === CREATE_EVENT_TAB_KEYS.CUSTOMER) {
          onQuoteCreated(res?.data?.id);
        } else if (tabKey !== CREATE_EVENT_TAB_KEYS.DOCUMENTS) {
          const tabBaseUrl = getTabBaseUrl(quoteId, eventId, NEXT_TAB?.[tabKey]);

          navigate(tabBaseUrl, {
            state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
          });
        } else {
          navigate('/events', {
            state: { prevPath: location?.pathname }
          });
        }
      } else {
        actions && actions.setSubmitting(false);
        showErrorToast({
          message: 'Failed!',
          description: res?.error ? res?.error : 'Failed to submit form'
        });
      }
    } catch (error: any) {
      actions && actions.setSubmitting(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to submit form'
      });
    }
  };

  const onQuoteCreated = async (quoteId: string) => {
    try {
      const res = await getEventDetails(quoteId);
      const tabBaseUrl = getTabBaseUrl(quoteId, res?.event_id, CREATE_EVENT_TAB_KEYS.VENUE);
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, path: location?.state?.path }
      });
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch events data'
      });
    }
  };

  return (
    <PageLayout>
      {quoteId && eventId && location?.state?.path === 'quote_details' ? (
        <BreadCrumbs routes={QuoteViewEventEditEventBreadCrumbs(quoteId, eventId)} />
      ) : location?.state?.prevPath === '/events/upcoming' ? (
        <BreadCrumbs routes={EventWithoutQuoteBreadCrumbs()} />
      ) : location?.pathname === 'quote_event' || location?.state?.path === 'events' ? (
        <BreadCrumbs routes={EventEditBreadCrumbs(quoteId, eventId)} />
      ) : (
        <BreadCrumbs routes={EventEditBreadCrumbs(quoteId, eventId)} />
      )}

      <div style={{ marginTop: '15px' }}>
        <PageTitle text="EVENT MANAGEMENT" isBack={false} path="/events" />
      </div>
      <div
        style={{
          marginTop: '18px',
          background: '#E0E0E0',
          marginBottom: '43px',
          height: '1px'
        }}
      />
      <HorizontalTab
        value={getEventManagementTabs({
          tabKey: formType,
          eventId: eventId,
          quoteId: quoteId,
          eventBasicDetails: details,
          submitHandler: submitHandler
        })}
        activeKey={formType}
        onChange={(value: any) => {
          const tabBaseUrl = getTabBaseUrl(quoteId, eventId, value);
          navigate(tabBaseUrl, {
            state: { prevPath: location?.pathname, path: location?.state?.path }
          });
        }}
      />
    </PageLayout>
  );
}

export default AddEditEvent;
