import React from 'react';
import { FieldArray, FormikProvider } from 'formik';
import { H1Typography, H2Typography } from '@constant/typography/Typography';
import { VENDOR_DETAILS_TABS, VENDOR_MANAGEMENT_TABS } from '../helper';
import { newAdditionalContact } from './helper';
import { useNavigate, useParams } from 'react-router';
import { Col, Divider, Row } from 'antd';

import AdditionalContacts from './Sections/AdditionalContacts';
import { Button } from '@atom/Buttons';
import DeliveryTimesSection from './Sections/DeliveryTimes';
import { Flex } from '@atom/Flex';
import { IAddEditInfoProps } from '../types';
import PickUpTimesSection from './Sections/PickUpTimes';
import ScrollToFieldError from '@utils/formikFocus';
import VendorDetailsSection from './Sections/VendorDetailsSection';
import PlusCircleBlackOutline from '@assets/icons/plus-circle-black-outline.svg';

const AddEditInfo: React.FC<IAddEditInfoProps> = ({
  formik,
  vendorCatergoriesData,
  isCreateLoading,
  isDraftLoading,
  setTabType,
  handleSaveDraft
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      {!id && <H1Typography style={{ margin: '35px 0px' }}>ADD NEW VENDOR</H1Typography>}
      <ScrollToFieldError formik={formik} />
      <VendorDetailsSection formik={formik} vendorCatergoriesData={vendorCatergoriesData} />
      <H2Typography style={{ marginTop: '15px' }}>Additional Contacts</H2Typography>
      <Divider style={{ borderColor: '#e0e0e0', margin: '15px 0px' }}></Divider>
      <FormikProvider value={formik}>
        <FieldArray
          name={'additional_contacts'}
          render={(arrayHelper: any) => {
            if (
              !formik.values?.additional_contacts ||
              formik.values?.additional_contacts?.length === 0
            )
              return null;
            return formik.values?.additional_contacts?.map((item: any, index: any) => {
              return (
                <AdditionalContacts
                  key={index}
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                  handleChange={formik.handleChange}
                  arrayHelper={arrayHelper}
                  index={index}
                  item={item}
                  showRemove={(formik?.values?.additional_contacts?.length || 0) > 0}
                  showAdd={
                    !!formik?.values?.additional_contacts &&
                    index === formik?.values?.additional_contacts?.length - 1
                  }
                />
              );
            });
          }}
        />
        <Row>
          <Col md={4} xs={24}>
            <Flex bottom={24}>
              <Button
                style={{ borderColor: '#000', color: '#000' }}
                text="Add More"
                variant="secondary"
                icon={<img width={20} src={PlusCircleBlackOutline} alt="Add product" />}
                onClick={() =>
                  formik.setFieldValue(
                    'additional_contacts',
                    formik?.values?.additional_contacts
                      ? [...formik?.values?.additional_contacts, newAdditionalContact]
                      : [newAdditionalContact]
                  )
                }
              />
            </Flex>
          </Col>
        </Row>
      </FormikProvider>
      <PickUpTimesSection formik={formik} />
      <DeliveryTimesSection formik={formik} />

      <Flex className="footerButton fix-mobile">
        <Button
          variant="secondary"
          text="Cancel"
          onClick={() => navigate(`/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`)}
        />

        <Button
          variant="secondary"
          text="Save as Draft"
          isLoading={isDraftLoading}
          onClick={handleSaveDraft}
        />

        <Button
          variant="primary"
          size="large"
          text="Next"
          onClick={formik?.handleSubmit}
          isLoading={isCreateLoading}
        />
      </Flex>
    </>
  );
};

export default AddEditInfo;
