import HorizontalTab from '@molecules/HorizontalTabs';
import React, { FC, useState } from 'react';
import { menuGlobalTabValue } from './helper';

const MenuManagement = (props: any) => {
  const { currentChildTab, setCurrentChildTab } = props;

  const onTabChange = (tab: string) => {
    setCurrentChildTab(tab);
  };
  return (
    <HorizontalTab value={menuGlobalTabValue} activeKey={currentChildTab} onChange={onTabChange} />
  );
};

export default MenuManagement;
