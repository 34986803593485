import { Col, Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { IAddEditInfoProps } from '@pages/VenueManagementPage/types';
import { Label } from '@atom/FormLable';
import { TextAreaInput } from '@atom/TextArea';
import { getIn } from 'formik';
import { ContentWrapper } from '../style';

function SetupDutiesSection(props: any) {
  const { value, id, handleBlur, errors, setFieldValue } = props;
  const [venueDetails, setVenueDetails] = useState<any>();

  useEffect(() => {
    if (id && !!value) {
      setVenueDetails(value);
    }
  }, [id]);

  const handleChange = (e: any) => {
    setTimeout(() => {
      setFieldValue(e?.target?.id, e?.target?.value);
    }, 200);
    setVenueDetails(e?.target?.value);
  };

  return (
    <Flex style={{ width: '100%' }} direction="column">
      <H2Typography style={{ marginTop: '15px' }}>Set Up Duties</H2Typography>
      <Divider style={{ borderColor: '#e0e0e0', margin: '15px 0px' }}></Divider>
      <ContentWrapper>
        <Row gutter={16} justify="space-between" style={{ width: '100%' }}>
          <Col xs={24} lg={24}>
            <Label text="Set Up Requirements" />
            <TextAreaInput
              id={`venue_detail.setup_duty_requirements`}
              name={`venue_detail.setup_duty_requirements`}
              placeholder="Requirements"
              value={venueDetails}
              error={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              allowClear={true}
            />
          </Col>
        </Row>
      </ContentWrapper>
    </Flex>
  );
}

export default SetupDutiesSection;
