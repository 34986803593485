import services from '@services/index';
import * as yup from 'yup';
import { ResponseType } from './types';

export const MAX_EVENTS = 3;

export const getProfileDetails = async (url: string) => {
  try {
    const res: any = await services.get(url);
    return res?.status ? res?.data?.data : {};
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const changePasswordValidations = yup.object().shape({
  current_password: yup.mixed().required('The current password field is required.')
});

export const profileValidations = yup.object().shape({
  name: yup.string().required('The name field is required.'),
  email: yup.string().required('The email field is required.'),
  phone_number: yup.string().required('The phone number field is required.'),
  address: yup.string().required('The address field is required.'),
  country_code: yup.string().nullable(),
  extension: yup.string().nullable(),
  reports_to: yup.string().nullable(),
  departments: yup
    .array()
    .of(
      yup.object().shape({
        master_department_id: yup.number().required('The master department field is required.'),
        position_id: yup.mixed().required('The position field is required.')
      })
    )
    .min(1)
    .required('The department field is required'),
  longitude: yup.mixed().required('Please select address from suggested list')
});

export const uploadFileHandler = async (file: File, type: number): Promise<ResponseType> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = (await services.post(`/file-upload?type=${type}`, formData, undefined, null, {
      'Content-Type': 'multipart/form-data'
    })) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const getDepartmentList = async (url: string) => {
  try {
    const res: any = await services.get(url);
    return res?.status ? res?.data?.data : {};
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const getReportsToList = async (url: string) => {
  try {
    const res: any = await services.get(url);
    return res?.status ? res?.data?.data : {};
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateProfile = async (data: any): Promise<ResponseType> => {
  try {
    const method = services.post;
    const res = (await method(`/profile/update`, data, undefined, null)) as any;
    return { data: res?.status, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};
