import { Input } from '@atom/Input';
import SearchableSelect from '@atom/SearchableSelect';
import SelectInput from '@atom/Select';
import RemoveIcon from '@assets/removerow.png';
import { Flex } from '@atom/Flex';
import { DropdownListItemType, IngredientsListItemType } from './types';
import { ViewTableCell } from './style';
import { Select } from 'antd';

const deleteIngredientHandler = (index: number, setFieldValue: any, values: any) => {
  const tempIngredients = JSON.parse(JSON.stringify([...(values?.ingredients || [])]));
  tempIngredients?.splice(index, 1);
  setFieldValue('ingredients', tempIngredients);
};

export const getIngedientcolumns = (
  isEdit: boolean,
  formik?: any,
  ingredientsList?: IngredientsListItemType[],
  specialIngredientsList?: IngredientsListItemType[],
  measurementsList?: DropdownListItemType[],
  prepList?: DropdownListItemType[],
  onSearchIngredientHandler?: (e: string, index: number, isSpecial: boolean) => void,
  handleClick?: React.MouseEventHandler<HTMLDivElement>
) => {
  const { values, setFieldValue, errors, touched, handleBlur } = formik;

  const onChnageIngredientHandler = (e: any, index: any) => {
    if (e?.id !== values?.ingredients?.[index]?.ingredient_id) {
      setFieldValue(`ingredients[${index}].amount`, '');
      setFieldValue(`ingredients[${index}].measurement_id`, null);
      setFieldValue(`ingredients[${index}.prep_list_id`, null);
    }
    if (e?.id > -1) {
      setFieldValue(`ingredients[${index}].ingredient`, e);
      setFieldValue(`ingredients[${index}].ingredient_name`, '');
      setFieldValue(`ingredients[${index}].ingredient_id`, e?.id);
    }
    if (values?.ingredients?.[index]?.is_special_ingredient) {
      setFieldValue(
        `ingredients[${index}].measurement_id`,
        specialIngredientsList?.find((specialIngredient) => specialIngredient.id === e?.id)
          ?.measurement_id
      );
    }
  };

  const columns: any = [
    {
      title: 'INGREDIENT NAME',
      dataIndex: 'ingredient_name',
      width: '30%',
      render: (cellData: any, rowData: any, index: any) => {
        return isEdit ? (
          <SearchableSelect
            id={`ingredients[${index}].ingredient_id`}
            name={`ingredients[${index}].ingredient_id`}
            placeholdertitle={'Search Ingredient'}
            value={values?.ingredients?.[index]?.ingredient}
            labelValue={'name'}
            onClick={handleClick}
            onChange={(e: any) => onChnageIngredientHandler(e, index)}
            onSearch={(e: any) =>
              onSearchIngredientHandler &&
              onSearchIngredientHandler(
                e,
                index,
                values?.ingredients?.[index]?.is_special_ingredient ? true : false
              )
            }
            error={
              touched?.ingredients?.[index]?.ingredient_id &&
              (errors?.ingredients?.[index]?.ingredient_id ||
                errors?.ingredients?.[index]?.ingredient_name)
            }
            onBlur={handleBlur}
            options={
              values?.ingredients?.[index]?.is_special_ingredient
                ? specialIngredientsList || []
                : ingredientsList || []
            }
            keyValue={'id'}
            listHeight={130}
            style={{ marginBottom: '8px' }}
          />
        ) : (
          <ViewTableCell>{cellData ?? '-'}</ViewTableCell>
        );
      }
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '18%',
      render: (cellData: any, rowData: any, index: any) => {
        return isEdit ? (
          <Input
            id={`ingredients[${index}].amount`}
            name={`ingredients[${index}].amount`}
            value={values?.ingredients?.[index]?.amount}
            error={touched?.ingredients?.[index]?.amount && errors?.ingredients?.[index]?.amount}
            onBlur={handleBlur}
            placeholder="0.00"
            onChange={(e: any) => {
              const valueToUpdate: string = e?.target?.value?.replace(/[^0-9\\.]+/g, '');
              setFieldValue(`ingredients[${index}].amount`, valueToUpdate);
            }}
            styleForWrapper={{ marginBottom: '8px' }}
          />
        ) : (
          <ViewTableCell>{cellData ?? '-'}</ViewTableCell>
        );
      }
    },
    {
      title: 'MEASUREMENT',
      dataIndex: 'measurement',
      width: '20%',
      render: (cellData: any, rowData: any, index: any) => {
        return isEdit ? (
          <SelectInput
            id={`ingredients[${index}.measurement_id`}
            name={`ingredients[${index}.measurement_id`}
            value={values?.ingredients?.[index]?.measurement_id}
            error={
              touched?.ingredients?.[index]?.measurement_id &&
              errors?.ingredients?.[index]?.measurement_id
            }
            disabled={values?.ingredients?.[index]?.is_special_ingredient}
            onBlur={handleBlur}
            placeholdertitle={'Select Measurement'}
            onChange={(e: number) => {
              setFieldValue(`ingredients[${index}.measurement_id`, e);
            }}
            listHeight={130}
            style={{ marginBottom: '8px' }}>
            {measurementsList?.map((measurement: DropdownListItemType) => (
              <Select.Option key={measurement?.id} value={measurement?.id}>
                {measurement?.name}
              </Select.Option>
            ))}
          </SelectInput>
        ) : (
          <ViewTableCell>{cellData ?? '-'}</ViewTableCell>
        );
      }
    },
    {
      title: 'PREP LIST',
      dataIndex: 'prep_list',
      width: '25%',
      render: (cellData: any, rowData: any, index: any) => {
        return isEdit ? (
          <SelectInput
            id={`ingredients[${index}.prep_list_id`}
            name={`ingredients[${index}.prep_list_id`}
            value={values?.ingredients?.[index]?.prep_list_id}
            onBlur={handleBlur}
            error={
              touched?.ingredients?.[index]?.prep_list_id &&
              errors?.ingredients?.[index]?.prep_list_id
            }
            placeholdertitle={'Select Prep List'}
            onChange={(e: number) => {
              setFieldValue(`ingredients[${index}.prep_list_id`, e);
            }}
            listHeight={130}
            style={{ marginBottom: '8px' }}>
            {prepList?.map((prep: DropdownListItemType) => (
              <Select.Option key={prep?.id} value={prep?.id}>
                {prep?.name}
              </Select.Option>
            ))}
          </SelectInput>
        ) : (
          <ViewTableCell>{cellData ?? '-'}</ViewTableCell>
        );
      }
    }
  ];
  if (isEdit) {
    columns?.push({
      title: '',
      dataIndex: '',
      width: '5%',
      render: (cellData: any, rowData: any, index: number) => {
        return (
          <Flex justifyContent="center">
            <img
              src={RemoveIcon}
              alt="remove"
              style={{ paddingTop: '10px', cursor: 'pointer' }}
              onClick={() => {
                deleteIngredientHandler(index, setFieldValue, values);
              }}
            />
          </Flex>
        );
      }
    });
  }
  return columns;
};

export const expandedIngredientsTableCols = [
  {
    title: 'BREAKDOWN INGREDIENT',
    dataIndex: 'ingredient_name',
    width: '30%',
    render: (cellData: any) => {
      return cellData ? cellData : '-';
    }
  },
  {
    title: 'AMOUNT',
    dataIndex: 'amount',
    width: '18%',
    render: (cellData: any) => {
      return cellData ? cellData : '-';
    }
  },
  {
    title: 'MEASUREMENT',
    dataIndex: 'measurement',
    width: '20%',
    render: (cellData: any) => {
      return cellData ? cellData : '-';
    }
  },
  {
    title: 'PREP LIST',
    dataIndex: 'prep_list',
    width: '25%',
    render: (cellData: any) => {
      return cellData ? cellData : '-';
    }
  }
];
