import { Col, Radio, Row, Select } from 'antd';
import { Formik } from 'formik';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { H1Typography } from '@constant/typography/Typography';
import ModalComponent from '@organism/Modal/Modal';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import MenuAndProductDetailsSec from '@pages/MenuManagementPage/AddEditPages/AddEditProduct/MenuAndProductDetailsSec';
import IngredientDietAndVideoDetailsSec from '@pages/MenuManagementPage/AddEditPages/AddEditProduct/IngredientDietAndVideoDetailsSec';
import PriceImagesAndBundleDetailsSec from '@pages/MenuManagementPage/AddEditPages/AddEditProduct/PriceImagesAndBundleDetailsSec';
import SelectInput from '@atom/Select';
import {
  getProduct,
  getProducts,
  INGREDIENT_FORM_STATUS,
  PRODUCT_STATUS,
  PRODUCT_TYPE_COLORS
} from '@pages/MenuManagementPage/helper';
import RadioButton from '@atom/RadioButton';
import {
  addEditProduct,
  addEditProductValidations,
  getInitValues
} from '@pages/MenuManagementPage/AddEditPages/AddEditProduct/helper';
import { ArrowComponentWrapper } from '@pages/MenuManagementPage/AddEditPages/AddEditProduct/style';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

function AddNewProductModal(props: any) {
  const { isModalVisible, onOk, onCancel, addNewProductHandler } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productList, setProductList] = useState<Array<any>>([]);
  const [isProductSubmit, setProductSubmit] = useState<boolean>(false);
  const { ingredients } = useSelector((state: any) => state.rawData);
  const ingredientRef = useRef<any>(null);
  const submitIngredients = () => {
    !!ingredientRef && ingredientRef?.current?.handleSubmit();
  };
  const handleCancel = (resetForm: any, setValues: any) => {
    resetForm();
    setProductSubmit(false);
    onCancel();
  };

  const onSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    try {
      const res: any = await addEditProduct(
        ingredients,
        {
          ...values,
          status: PRODUCT_STATUS.ACTIVE
        },
        values?.id ?? null,
        true
      );
      if (res?.success) {
        showToast({
          message: 'Success!',
          description: 'Product Saved'
        });
        await addNewProductHandler(res?.data?.sub_categories?.[0]?.id, res?.data);
        setProductSubmit(false);
        actions.resetForm();
        updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
        onOk();
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    actions.setSubmitting(false);
  };

  const handleSelectProduct = async (id: any, values: any, setValues: (values: any) => void) => {
    const res = await getProduct(id);
    if (res?.success) {
      setValues({
        ...(res?.data || {}),
        is_one_time_product: values?.is_one_time_product,
        product_id: id,
        images: res?.data?.price_descriptions || undefined,
        product_name: `C-${res?.data?.product_name}`
      });
      setProductSubmit(false);
    } else {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (isModalVisible) {
      (async () => {
        await getProducts()
          .then((res: any) => setProductList(res?.data))
          .catch((err) => {
            setIsLoading(false);
          });
        setIsLoading(false);
      })();
    } else {
      setIsLoading(false);
    }
  }, [isModalVisible]);

  return (
    <Formik
      initialValues={{
        ...getInitValues(null, true)
      }}
      validationSchema={addEditProductValidations(true)}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}>
      {(prop) => {
        const {
          touched,
          errors,
          values,
          setFieldValue,
          handleBlur,
          handleSubmit,
          setValues,
          resetForm
        } = prop;

        return (
          <>
            <ModalComponent
              visible={isModalVisible}
              modalWidth="1100px"
              onCancel={() => handleCancel(resetForm, setValues)}
              align="left !important">
              <Flex justifyContent="space-between" alignItems={'center'} bottom={38}>
                <H1Typography>Add Product</H1Typography>
                <Flex>
                  <Button
                    variant="secondary"
                    text={'Cancel'}
                    onClick={() => handleCancel(resetForm, setValues)}
                    className="cancel-btn"
                    style={{ marginRight: '16px' }}
                  />
                  <Button
                    isDisable={!isProductSubmit}
                    variant="primary"
                    text={'Submit'}
                    onClick={handleSubmit}
                    isLoading={prop.isSubmitting}
                  />
                </Flex>
              </Flex>
              <Row>
                <Col md={12} xs={24}>
                  <Label isMandatory={true} text="Is this a one-time product?" />
                  <RadioButton
                    name="is_one_time_product"
                    id="is_one_time_product"
                    value={values?.is_one_time_product}
                    onChange={(e) => {
                      setFieldValue('is_one_time_product', e?.target?.value);
                    }}>
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Yes</Radio>
                  </RadioButton>
                </Col>
                <Col md={12} xs={24}>
                  <Label isMandatory={false} text="Copy from Existing Product" />
                  <ArrowComponentWrapper>
                    <SelectInput
                      id={`product_id`}
                      name={`product_id`}
                      filterOption={(key: any, e: any) =>
                        e?.name?.toLowerCase()?.includes(key?.toLowerCase())
                      }
                      placeholdertitle="Select Existing Product"
                      value={values?.product_id}
                      error={touched?.product_id && errors?.product_id}
                      onChange={(id: any) => handleSelectProduct(id, values, setValues)}
                      onBlur={handleBlur}
                      // disabled={!values?.is_one_time_product}
                    >
                      {productList
                        ?.filter((i: any) => i?.product_type === values?.product_type)
                        ?.map((item: any) => {
                          return (
                            <Select.Option
                              value={item?.product_id}
                              key={item?.product_subcategory_id}
                              name={item?.text}>
                              <>
                                <span
                                  className="text-style"
                                  style={{
                                    color: PRODUCT_TYPE_COLORS?.[item?.product_type] || 'black'
                                  }}>
                                  {item?.text}
                                </span>{' '}
                                <span className="text-style" style={{ color: 'red' }}>
                                  {' '}
                                  - {item?.master_category}/{item?.subcategory}
                                </span>
                              </>
                            </Select.Option>
                          );
                        })}
                    </SelectInput>
                  </ArrowComponentWrapper>
                </Col>
              </Row>
              <MenuAndProductDetailsSec
                formik={prop}
                forQuotePopUp={true}
                productListFromQuote={productList}
                setProductSubmit={setProductSubmit}
                ingredientRef={ingredientRef}
                submitIngredients={submitIngredients}
              />
              <IngredientDietAndVideoDetailsSec formik={prop} />
              <PriceImagesAndBundleDetailsSec isQuote={true} formik={prop} />
            </ModalComponent>
          </>
        );
      }}
    </Formik>
  );
}

export default AddNewProductModal;
