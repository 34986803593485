import moment from 'moment';
import services from '@services/index';

export const MAX_EVENTS = 3;

export enum CALENDAR_VIEWS {
  MONTH = 'dayGridMonth',
  WEEK = 'timeGridWeek',
  DAY = 'listDay',
  SCHEDULE = 'listMonth'
}

export const getInitialFilters = (dateValue: any, currentView: string) => {
  return {
    startDate: moment(dateValue)
      .startOf(
        currentView === CALENDAR_VIEWS.MONTH || currentView === CALENDAR_VIEWS.SCHEDULE
          ? 'month'
          : currentView === CALENDAR_VIEWS.WEEK
          ? 'week'
          : 'day'
      )
      .format('YYYY-MM-DD'),
    endDate: moment(dateValue)
      .endOf(
        currentView === CALENDAR_VIEWS.MONTH || currentView === CALENDAR_VIEWS.SCHEDULE
          ? 'month'
          : currentView === CALENDAR_VIEWS.WEEK
          ? 'week'
          : 'day'
      )
      .format('YYYY-MM-DD'),
    currentView: currentView
  };
};

const getTitle = (i: any, currentView: string) => {
  switch (currentView) {
    case CALENDAR_VIEWS.SCHEDULE:
      return `${i?.event_code ? i?.event_code + ' - ' : ''} ${i?.event_name ?? 'Unnamed Event'} - ${
        i?.total_guest
      } Guests\n${
        i?.start_time && i?.end_time
          ? `${moment(i?.start_time, 'HH:mm:ss').format('h:mm A')} - ${moment(
              i?.end_time,
              'HH:mm:ss'
            ).format('h:mm A')}`
          : ''
      } - ${i?.venue_name}`;
    case CALENDAR_VIEWS.DAY:
      return `${i?.total_guest ? i?.total_guest + ' - ' : ''} ${
        i?.event_code ? i?.event_code + ' - ' : ''
      } ${i?.event_name ?? 'Unnamed Event'}${
        i?.start_time && i?.end_time
          ? `,${'  ' + moment(i?.start_time, 'HH:mm:ss').format('h:mm A')} - ${moment(
              i?.end_time,
              'HH:mm:ss'
            ).format('h:mm A')}`
          : ''
      }`;
    case CALENDAR_VIEWS.WEEK:
      return `${i?.total_guest ? i?.total_guest + ' - ' : ''} ${
        i?.event_code ? i?.event_code + ' - ' : ''
      } ${i?.event_name ?? 'Unnamed Event'}${
        i?.start_time && i?.end_time
          ? `,${'  ' + moment(i?.start_time, 'HH:mm:ss').format('h:mm A')} - ${moment(
              i?.end_time,
              'HH:mm:ss'
            ).format('h:mm A')}`
          : ''
      }`;
    default:
      return `${i?.total_guest ? i?.total_guest + ' - ' : ''} ${
        i?.event_code ? i?.event_code + ' - ' : ''
      } ${i?.event_name ?? 'Unnamed Event'}`;
  }
};

export const getMappedEvents = (data: any, currentView: string) => {
  const mappedData = data?.map((i: any) => ({
    id: i?.event_id,
    quote_id: i?.quote_id,
    allDay: !(i?.start_time && i?.end_time),
    start: `${i?.date}${i?.start_time ? `T${i?.start_time}` : ''}`,
    end: `${i?.date}${i?.end_time ? `T${i?.end_time}` : ''}`,
    title: getTitle(i, currentView),
    borderColor: 'transparent',
    backgroundColor: i?.color,
    textColor: 'black !important',
    account_manager_name: i?.account_manager?.name,
    event_manager_name: i?.event_manager_name?.map((manager: any) => manager?.employee?.name),
    head_chef_staffs_name: i?.head_chef_staffs?.map((chef: any) => chef?.employee?.name)
  }));
  return currentView === CALENDAR_VIEWS.SCHEDULE
    ? mappedData.filter((i: any) => {
        const startDate = new Date(i?.start);
        const currentDate = new Date();
        const startMonth = startDate.getMonth();
        const currentMonth = currentDate.getMonth();
        const startYear = startDate.getFullYear();
        const currentYear = currentDate.getFullYear();

        return (
          startYear !== currentYear ||
          startMonth > currentMonth ||
          (startMonth === currentMonth && startDate >= currentDate) ||
          (startMonth < currentMonth && startDate <= currentDate)
        );
      })
    : mappedData;
};

export const getEvents = async (filters: any) => {
  try {
    const res = (await services.get(
      `/monthly-dashboard-event/${filters?.startDate}/${filters?.endDate}`
    )) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export enum WEEK_START_DAY {
  SUNDAY = 0,
  MONDAY = 1
}
