import CenterSpiner from '@atom/CenterSpiner';
import Checkbox from '@atom/Checkbox';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { FILE_TYPES } from '@constants/userConstants';
import FileDraggerComponent, { CustomFileDragger } from '@molecules/FileDraggerComponent';
import { uploadFileHandler } from '@services/fileService';
import { showErrorToast, showToast } from '@utils/index';
import { Col, Row, UploadFile } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DocumentsTabType,
  EditDocumentListType,
  getDocumentList,
  updateDocumentList
} from './helper';
import DeleteIcon from '@assets/Delete.svg';
import FilesImage from '@assets/filesImage.png';
import { H4Typography } from '@constant/typography/Typography';
import AddEditQuoteFooter from '@pages/QuoteManagementPage/AddEditQuote/AddEditQuoteFooter';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '../../tabHelpers';

function DocuementsTab(props: any) {
  const { onDocumentSubmit, withQuote } = props;
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [documentsList, setDocumentsList] = useState<DocumentsTabType[]>([]);

  const { eventId, quoteId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getEventListHandler();
  }, []);

  const getEventListHandler = useCallback(async () => {
    if (!eventId)
      return showErrorToast({ message: 'Unable to fetch Documents List', description: '' });
    setPageLoading(true);
    const result = await getDocumentList(eventId);
    setPageLoading(false);
    if (!result.error) {
      setDocumentsList(result.data || []);
    }
  }, []);

  const onFileUpload = async (file: File, length: number, uid: string = '') => {
    if (!eventId) return;
    setFileUploading(true);
    const result = (await uploadFileHandler(file, FILE_TYPES.DOCUMENTS)) as any;
    setFileUploading(false);
    if (result.error) {
      return showErrorToast({
        message: `Unable to upload document`,
        description: result.error || 'Please try again'
      });
    }
    if (result?.data?.url) {
      setDocumentsList((prev) => [
        ...prev,
        {
          id: null,
          uid: uid,
          event_id: eventId,
          document_name: file.name,
          document_url: result?.data?.url,
          is_private: 0
        }
      ]);
    }
  };

  const isPrivateHandler = (index: number) => {
    const updatedDocumentsList = [...documentsList];
    updatedDocumentsList[index] = {
      ...updatedDocumentsList[index],
      is_private: updatedDocumentsList[index].is_private === 0 ? 1 : 0
    };
    setDocumentsList(updatedDocumentsList);
  };

  const deleteDocumentHandler = (index: number) => {
    if (fileUploading) return;
    const updatedDocumentsList = [...documentsList];
    updatedDocumentsList.splice(index, 1);
    setDocumentsList(updatedDocumentsList);
  };

  const backHandler = () => {
    // const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.TASKS}/${quoteId}/${eventId}`;
    const tabBaseUrl = withQuote
      ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.TASKS}`
      : `/events/create/${CREATE_EVENT_TAB_KEYS.TASKS}/${quoteId}/${eventId}`;
    navigate(tabBaseUrl, {
      state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
    });
  };

  const cancelHandler = () => {
    navigate('/events');
  };
  const nextHandler = async () => {
    setLoading(true);
    if (eventId) {
      const result = await updateDocumentList({ documents: documentsList, event_id: eventId });
      setLoading(false);
      if (!result.error) {
        showToast({ message: 'Documents Updated Successfully', description: '' });
        navigate(`/events/${eventId}/view`, {
          state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
        });
      } else {
        setLoading(false);
        showErrorToast({
          message: 'Error Saving Documents',
          description: result.error || 'Please try again'
        });
      }
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <div>
      <Flex top={10} direction="column">
        {/* <Label text="File" isMandatory /> */}
        <CustomFileDragger
          onChangeHandle={onFileUpload}
          loading={fileUploading}
          maxLimit={20000000} // 20 MB
          multiple={true}
          showUploadList={false}
        />
      </Flex>
      <Flex direction="column">
        {documentsList.map((document, index) => {
          return (
            <Row key={index} gutter={[24, 16]}>
              <Col>
                <Checkbox
                  name="Is private?"
                  id={`is-private-${index}`}
                  onChange={() => isPrivateHandler(index)}
                  checked={document.is_private}
                />
              </Col>
              <Col>
                <Flex style={{ alignItems: 'baseline', gap: '0 20px ' }}>
                  <img src={FilesImage} alt="file" style={{ height: '16px' }} />
                  <H4Typography>{document.document_name}</H4Typography>
                  <img
                    src={DeleteIcon}
                    alt="delete"
                    style={{ height: '16px', cursor: fileUploading ? 'not-allowed' : 'pointer' }}
                    onClick={() => deleteDocumentHandler(index)}
                  />
                </Flex>
              </Col>
            </Row>
          );
        })}
      </Flex>
      <AddEditQuoteFooter
        showBackButton
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        // saveAsDraftClickHandler={saveAsDraftHandler}
        nextButtonTitle="Update EDS"
        nextClickHandler={nextHandler}
        isSubmitting={loading}
        isEvent={true}
        // isNextButtonDisabled={!isValid}
      />
    </div>
  );
}

export default DocuementsTab;
