import * as yup from 'yup';
import Categories from './Categories';
import DietaryRestrictions from './DietaryRestrictions';
import Ingredients from './Ingredients';
import MenuNames from './MenuNames';
import Measurements from './Measurements';
import services from '@services/index';
import { ResponseType } from '../helper';
import PrepMenu from './PrepMenu';
import SpecialIngredients from './SpecialIngredients/SpecialIngredients';
import { SpecialIngredientType } from './SpecialIngredients/types';

// types
export interface MenuNameListType {
  id: number;
  name: string;
  status: number;
}

export interface EditMenuNameTypes {
  menus: MenuNameListType[];
}

export interface SubCategoryType {
  id: number | null;
  name: string;
  deleted_at?: string | null;
  parent_id?: number;
}

export interface CategoryListType {
  id: number;
  name: string;
  deleted_at: string | null;
  sub_categories: SubCategoryType[];
}

export interface IngredientListType {
  id: number;
  name: string;
}

export interface RestrictionListType {
  id: number;
  name: string;
  text: string;
}

export interface EditCategoriesType {
  categories: CategoryListType[];
}

export interface EditIngredientType {
  ingredients?: IngredientListType[];
  save_ingredients?: IngredientListType[];
  delete_ingredients?: IngredientListType[];
}

export interface EditRestrictionType {
  dietary_restrictions: RestrictionListType[];
}

export interface EditMeasurementType {
  measurements: RestrictionListType[];
}

export interface EditPrepMenuType {
  prep_menus: RestrictionListType[];
}

export interface EditSpecialIngredientType {
  ingredients: RestrictionListType[];
}

//Type for 'unique' property being added to yup to check duplicate fields in an array
declare module 'yup' {
  interface ArraySchema<T> {
    unique(propertyName: string, message: string): ArraySchema<T>;
  }
}

// tab values
export const menuGlobalTabValue = [
  { tab: 'Menu Names', content: <MenuNames />, key: 'menu-names' },
  { tab: 'Categories', content: <Categories />, key: 'categories' },
  { tab: 'Prep List', content: <PrepMenu />, key: 'prep-menu' },
  { tab: 'Ingredients', content: <Ingredients />, key: 'ingredients' },
  { tab: 'Special Ingredients', content: <SpecialIngredients />, key: 'special-ingredients' },
  {
    tab: 'Dietary Restrictions',
    content: <DietaryRestrictions />,
    key: 'dietary-restrictions'
  },
  { tab: 'Measurements', content: <Measurements />, key: 'measurements' }
];

// api calls
export const getMenuDetails = async (key: string): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings/menu-management/${key}`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateMenuNames = async (
  body:
    | EditMenuNameTypes
    | EditCategoriesType
    | EditIngredientType
    | EditRestrictionType
    | EditPrepMenuType
    | EditMeasurementType
    | SpecialIngredientType,
  key: string
): Promise<ResponseType> => {
  try {
    const res = (await services.post(`/settings/menu-management/${key}`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.error?.message || error?.error?.message || 'Something went wrong!'
    };
  }
};

export const editSpecialIngredient = async (
  body: SpecialIngredientType,
  key: string
): Promise<ResponseType> => {
  try {
    const res = (await services.put(`/settings/menu-management/${key}`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.error?.message || error?.error?.message || 'Something went wrong!'
    };
  }
};

export const saveSpecialIngredient = async (
  body: { special_ingredient_ids: number[] },
  key: string
): Promise<ResponseType> => {
  try {
    const res = (await services.put(`/settings/menu-management/${key}`, body)) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const removeMenuName = async (id: number, key: string): Promise<ResponseType> => {
  try {
    const res = (await services.delete(`/settings/menu-management/${key}/${id}`)) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

//Added a method in yup to check unique field in array of objects
yup.addMethod(yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array: any) {
    const uniqueData = Array.from(
      new Set(array.map((row: any) => (row[field] ? row[field].trim().toLowerCase() : null)))
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row: any, i: number) => row[field]?.toLowerCase() !== uniqueData[i]
    );
    if (array[index][field] === '') {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message
    });
  });
});

// yup validations
export const menuValidationSchema = yup.object({
  menus: yup.array().of(
    yup.object({
      name: yup.string().required('Menu Name is required.').nullable()
    })
  )
});

export const ingredientsValidationSchema = yup.object({
  ingredients: yup
    .array()
    .of(
      yup.object({
        name: yup
          .string()
          .required('Ingredient name is required.')
          .max(255, 'Ingredient name cannot exceed 255 characters.')
          .nullable()
      })
    )
    .unique('name', 'All the ingredients in list should have distinct names.')
});

const decimalRegEx = /^\d+(\.\d{1,2})?$/;

export const specialIngredientsValidationSchema = yup.object({
  name: yup
    .string()
    .required('Special Ingredient name is required.')
    .max(128, 'Special Ingredient name cannot be more than 128 characters.')
    .nullable(),
  yields: yup
    .string()
    .matches(decimalRegEx, 'Decimal upto 2 values allowed.')
    .required('Yield is required.')
    .nullable(),
  measurement: yup.string().required('Please select a measurement from list.').nullable(),
  ingredients: yup.array().of(
    yup.object().shape(
      {
        ingredient_id: yup.number().when(['ingredient_name', 'is_special_ingredient'], {
          is: (ingredient_name: string, is_special_ingredient: number) =>
            !!ingredient_name && !is_special_ingredient,
          then: yup.number().nullable(),
          otherwise: yup
            .number()
            .required('Please select a ingredient from list.')
            .test(
              'Is Selected from list?',
              'Please select a ingredient from list.',
              (value: any) => value && value !== -1
            )
            .nullable()
        }),
        ingredient_name: yup.string().when(['ingredient_id', 'is_special_ingredient'], {
          is: (ingredient_id: number, is_special_ingredient: number) =>
            (!!ingredient_id && ingredient_id !== -1) || is_special_ingredient,
          then: yup.string().nullable(),
          otherwise: yup
            .string()
            .required('Please add ingredient.')
            .max(128, 'Ingredient name cannot be more than 128 characters.')
            .nullable()
        }),
        amount: yup
          .string()
          .matches(decimalRegEx, 'Decimal upto 2 values allowed.')
          .required('Amount is required.')
          .nullable(),
        measurement_id: yup.number().required('Please select a measurement.').nullable(),
        prep_list_id: yup.number().required('Please select a prep value.').nullable()
      },
      [['ingredient_id', 'ingredient_name']]
    )
  )
});

export const restrictionValidationSchema = yup.object({
  dietary_restrictions: yup.array().of(
    yup.object({
      name: yup.string().required('Name is required.').nullable()
    })
  )
});

export const measurementValidationSchema = yup.object({
  measurements: yup
    .array()
    .of(
      yup.object({
        name: yup
          .string()
          .required('Name is required.')
          .max(128, 'Measurement cannot be more than 128 characters.')
          .nullable()
      })
    )
    .unique('name', 'All the fields in list should have distinct text.')
});

export const PrepMenuValidationSchema = yup.object({
  prep_menus: yup
    .array()
    .of(
      yup.object({
        name: yup
          .string()
          .required('Prep Menu Name is required.')
          .min(0, 'Prep Menu Name is required.')
          .max(128, 'Text cannot be more than 128 characters.')
          .nullable()
      })
    )
    .unique('name', 'All the Prep Menus in list should have distinct names.')
});

export const categoryValidationSchema = yup.object({
  categories: yup.array().of(
    yup.object({
      name: yup.string().required('Category Name is required.').nullable(),
      sub_categories: yup.array().of(
        yup.object({
          name: yup.string().required('Sub Category Name is required.').nullable()
        })
      )
    })
  )
});

export const duplicateCategoryErrorMessage = 'Master category name must be unique';
export const duplicateSubCategoryErrorMessage = 'Sub category name must be unique';
