import { Flex } from '@atom/Flex';
import styled from 'styled-components';

export const AssigneeItem = styled(Flex)`
  margin-left: 20px;
  .unassigned {
    color: red !important;
    font-weight: 700;
  }
`;

export const ScrollFlex = styled(Flex)`
  overflow: auto;
  padding: 20px;
  width: 500px;
  max-width: 500px;
  @media (max-width: 767px) {
    width: auto;
    max-width: auto;
  }
`;
export const TimePickerWrapper = styled.div`
  .ant-picker {
    margin-bottom: 0px !important;
  }
  .ant-picker-dropdown {
    top: 6rem !important;
  }
  .ant-picker-content {
    max-height: 100px;
  }

  .ant-select input {
    padding: 12px 0px !important;
    height: auto !important;

    &:focus {
      z-index: 1 !important;
    }
  }
  .ant-select-dropdown {
    z-index: 2 !important;
    background-color: #ffffff !important;
  }
`;

export const TableWrapper = styled(Flex)`
  .ant-table-cell {
    vertical-align: baseline !important;
  }

  .ant-pagination-simple-pager {
    .ant-pagination-slash {
      visibility: hidden;
    }
    span::after {
      content: 'of';
      margin-left: 0;
      visibility: visible;
    }
  }

  @media (max-width: 820px) {
    .datatable-fix {
      width: 150px !important;
    }
  }
`;
