import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '@pages/EventManagementPage/EventWithExistingQuote/tabHelpers';
import { localStorageUtil } from '@utils/localstorageUtil';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { QUOTE_STATUS, STATUS_NAME_COLORS } from '../constants';
import { ACTION_TYPES } from './helper';
import { QuoteStatusWrapper } from './style';

interface QuoteStatusProps {
  data: any;
  setIsModalVisible: (val: boolean) => void;
  setActionType: (val: number) => void;
}

const QuoteStatus = (props: QuoteStatusProps) => {
  const { data, setIsModalVisible, setActionType } = props;
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;
  const location = useLocation();

  const showActionButtons =
    data?.status !== QUOTE_STATUS.DRAFT &&
    data?.status !== QUOTE_STATUS.CREATED_EVENTS &&
    data?.status !== QUOTE_STATUS.CANCELED_EVENTS &&
    data?.status !== QUOTE_STATUS.CONFIRMED_EVENTS &&
    isAdmin;

  const handleRejectQuote = () => {
    setActionType(ACTION_TYPES.REJECT);
    setIsModalVisible(true);
  };

  const handleApproveQuote = () => {
    setActionType(ACTION_TYPES.APPROVE);
    setIsModalVisible(true);
  };

  return (
    <QuoteStatusWrapper>
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col xs={24} md={12}>
          <H2Typography className="status-title">QUOTE STATUS:</H2Typography>
          <span
            className="status"
            style={{
              color: STATUS_NAME_COLORS[data?.status]
            }}>
            {data?.status_info?.status_name?.toUpperCase() || '-'}
          </span>
        </Col>
        {showActionButtons && (
          <Col xs={24} md={12}>
            <Flex className="flex-fixing" gap={10}>
              {data?.status !== QUOTE_STATUS.REJECTED && (
                <Button
                  className="btn-fixing-reject"
                  style={{ textDecoration: 'underline', fontWeight: '700', fontSize: '16px' }}
                  text="Reject Quote"
                  variant="ghost"
                  onClick={handleRejectQuote}
                />
              )}
              {data?.status !== QUOTE_STATUS.APPROVED && (
                <Button
                  className="btn-fixing"
                  style={{ fontWeight: '700', fontSize: '16px' }}
                  text="Approve Quote"
                  variant="secondary"
                  onClick={handleApproveQuote}
                />
              )}

              {data?.status !== QUOTE_STATUS.REJECTED && (
                <Button
                  style={{ fontSize: '16px' }}
                  className="btn-fixing"
                  text="Create Event"
                  isDisable={
                    data?.status === QUOTE_STATUS.SAVED ||
                    data?.status === QUOTE_STATUS.PENDING_APPROVAL
                  }
                  variant="primary"
                  onClick={() => {
                    localStorageUtil.setValue('location', {
                      state: {
                        prevPath: location?.pathname,
                        eventId: data?.event_id,
                        path: 'create_event'
                      }
                    });
                    navigate(
                      `/events/find-quote/create/${quoteId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.QUOTE}`,
                      {
                        state: {
                          prevPath: location?.pathname,
                          eventId: data?.event_id,
                          path: 'create_event'
                        }
                      }
                    );
                  }}
                />
              )}
            </Flex>
          </Col>
        )}
        {!!data?.event_id &&
          data?.status !== QUOTE_STATUS.APPROVED &&
          data?.status !== QUOTE_STATUS.REJECTED && (
            <Col xs={24} md={12}>
              <Flex className="flex-fixing" gap={10}>
                <Button
                  style={{ fontSize: '16px' }}
                  className="btn-fixing-reject"
                  text="View Event"
                  onClick={() => {
                    localStorageUtil.setValue('location', {
                      state: {
                        prevPath: location?.pathname,
                        eventId: data?.event_id,
                        path: 'view_event'
                      }
                    });
                    navigate(`/events/${data?.event_id}/view`, {
                      state: {
                        prevPath: location?.pathname,
                        eventId: data?.event_id,
                        path: 'view_event'
                      }
                    });
                  }}
                />
              </Flex>
            </Col>
          )}
      </Row>
    </QuoteStatusWrapper>
  );
};

export default QuoteStatus;
