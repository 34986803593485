import React, { useState } from 'react';

import { PageTitle } from '@molecules/PageTitle';
import HorizontalTab from '@molecules/HorizontalTabs';
import PageLayout from '@layout/PageLayout';

import { getGlobalSettingsTabValue } from './helper';
import { Button } from '@atom/Buttons';
import EditIcon from '@assets/edit-2.svg';
import { updateRawData } from '@utils/index';
import { menuGlobalTabValue } from '../MenuManagement/helper';

const GlobalSettingsView = () => {
  const [currentTab, setCurrentTab] = useState('menu-management');
  const [currentChildTab, setCurrentChildTab] = useState('menu-names');

  const onTabChange = (tab: string) => {
    setCurrentTab(tab);
    setCurrentChildTab(menuGlobalTabValue[0].key);
  };

  const onEdit = () => {
    updateRawData({ isGlobalSettingsEdit: true });
  };

  return (
    <PageLayout>
      <PageTitle text="GLOBAL SETTINGS" isBack={false}>
        <Button
          style={{ borderBottom: '2px solid #000' }}
          icon={<img src={EditIcon} alt="edit" />}
          text="EDIT"
          variant="ghost"
          onClick={onEdit}
        />
      </PageTitle>
      <HorizontalTab
        value={getGlobalSettingsTabValue({ currentChildTab, setCurrentChildTab })}
        activeKey={currentTab}
        onChange={onTabChange}
      />
    </PageLayout>
  );
};

export default GlobalSettingsView;
