import styled from 'styled-components';

import { H6Typography, TypoBodyBody1 } from '@constant/typography/Typography';

export const SubLabel = styled.p`
  color: #818080;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
`;

export const TagWrapper = styled.div`
.ant-tag-checkable-checked {
  background-color: rgba(228,168,83,.5) !important;
}
  .ant-tag-checkable {
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    padding: 9px 15px;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom:16px;
    &:hover {
      color: #000; !important;
      outline: none;
      box-shadow: none;
    }
    &:focus {
      color: #000; !important;
      outline: none;
      box-shadow: none;
    }
}
`;

export const SelectWrapper = styled.div`
  .ant-select-selection-item {
    align-items: center;
    padding: 5px 10px;
    background-color: #f4f4f4 !important;
    border: none !important;
    display: inline-flex;
  }
  .ant-select-multiple .ant-select-selection-item {
    background-color: #f4f4f4 !important;
  }
  .ant-select-selection-overflow {
    position: absolute;
    top: 5px;
  }

  .ant-select {
    height: 130px;
    vertical-align: super;
    display: -webkit-box;
  }
  .ant-select-selection-placeholder {
    // height: 110px;
    top: 20%;
    padding: 10px 0;
  }

  .ant-select-selector {
    overflow-x: clip;
  }
`;
export const PriceDescWrapper = styled.div`
  .headerContent {
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #5a5c69;
    background: #f4f4f4;
    border-radius: 3px;
    padding: 12px 0 !important;
    display: block;
    width: 100%;
  }
  .ant-collapse-header {
    padding: 0px !important;

    font-weight: none !important;
    border-bottom: none !important;
  }
  .borderedPanel {
    border: none !important;
    margin-bottom: 0px !important;
  }
  .ant-collapse-content .ant-collapse-content-box {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
  }
`;

export const ContentWrapper = styled.div`
  .ant-select-multiple .ant-select-selection-item {
    background-color: #f4f4f4 !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
    background-color: #ddd !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(228, 168, 83, 0.5) !important;
  }
`;

export const SubCategoryPlaceHolder = styled(TypoBodyBody1)`
  display: inline;
  color: #858796;
  background: #f4f4f4;
  font-size: 16px;
  padding: 12px 35px;
  border-radius: 3px;
  width: 100%;
  line-height: 21px;
  text-align: center;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ErrorLabel = styled(H6Typography)`
  margin-top: 10px;
  font-weight: 100;
`;

export const TotalCostLabel = styled(TypoBodyBody1)`
  display: flex;
  justify-content: center;
  font-weight: 100;
  background: #e9ecef;
  padding: 10px 20px;

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
    background-color: #ddd !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(228, 168, 83, 0.5) !important;
  }
`;
export const ArrowComponentWrapper = styled.div`
  .ant-select-arrow {
    display: none !important;
  }
  .ant-select-selection-item .text-style {
    color: #000 !important;
  }
`;

export const TextWrapper = styled.div`
  .ant-select-selection-item .text-style {
    color: #000 !important;
  }
`;

export const ButtonWrapper = styled.div`
  .footerButton {
    gap: 16px !important;
    justify-content: end;
    margin-top: 0px !important;
  }
`;

export const SingleImageWrapper = styled.div`
  .ant-btn {
    width: 100% !important;
  }
`;

export const SpecialIngredientWraper = styled.div`
  .czaSpg .ant-table-thead > tr > th {
    border: none !important;
    padding-left: 30px !important;
  }
  .czaSpg .ant-table-tbody > tr {
    background: none !important;
    border: 1px solid #cdcdcd !important;
  }
  .ant-table table {
    border-collapse: none !important;
  }
  .czaSpg .ant-table-tbody > tr > td {
    border-bottom: 1px solid #cdcdcd;
  }
  .ant-table-container table > thead > tr:first-child th {
    border-bottom: 1px solid #cdcdcd !important;
  }

  .specialingredientstableth: first-child {
    padding-left: 30px !important;
  }

  .specialIngredientsTable th {
    padding-left: 10px !important;
  }
`;
export const TextBox = styled.div`
  padding: 5px 20px;
  border: 1px solid #cdcdcd;
  background-color: #f4f4f4;
`;
export const MenuCategoryWrapper = styled.div`
  .ant-select-selection-search-input {
    padding-left: 88px !important;
  }
  .ant-select-selector {
    :before {
      content: 'Category: ';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      text-transform: uppercase;
      padding-right: 10px;
      color: #838383;
    }
  }
`;

export const BundleWrapper = styled.div`
  .ant-select-selection-overflow .ant-select-selection-search {
    margin: 0px !important;
  }
`;

export const ExistingProductFieldsWrapper = styled.div`
  .remove-btn {
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      justify-content: start;
      margin-bottom: 10px;
    }
  }
`;

export const MenuMenusWrapper = styled.div`
  .selectRowWithCheckbox {
    display: flex;
  }
  .ant-select-dropdown .ant-select-item-option-state {
    display: none;
  }
  .ant-select-dropdown .ant-select-item-option-selected {
    background-color: white !important;
  }
  .ant-select-dropdown {
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: orange !important;
    border: orange !important;
  }
  .ant-select-selection-item-content .ant-checkbox-checked {
    display: none !important;
  }
`;
