import Checkbox from '@atom/Checkbox';
import { Flex } from '@atom/Flex';
import { Value } from '@atom/FormDataView';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import Switch from '@atom/Switch';
import TagComponent from '@atom/TagComponent';
import { H4Typography } from '@constant/typography/Typography';
import { CURRENCY } from '@constants/index';
import { useParams } from 'react-router';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { RemoveIcon } from '../Labours/style';
import { formatCurrency } from '@utils/format';

interface OtherEstimateItemProps {
  other: any;
  index: number;
  formik: any;
  onRemove: (other: any) => void;
}

function OtherEstimateItem(props: OtherEstimateItemProps) {
  const { other, index, onRemove, formik } = props;
  const { handleChange, handleBlur, setFieldValue, touched, errors, values } = formik;

  const { formType } = useParams();

  const baseKey = `event_dates.other_details[${index}]`;
  const baseValueObject = values?.event_dates?.other_details?.[index];
  const baseTouchedObject = touched?.event_dates?.other_details?.[index];
  const baseErrorObject = errors?.event_dates?.other_details?.[index];
  const [toggle, setToggle] = useState<boolean>(
    baseValueObject?.public_tags?.length > 0 || baseValueObject?.toggle_tags
  );

  useEffect(() => {
    if (formType === 'others')
      setToggle(baseValueObject?.public_tags?.length > 0 || baseValueObject?.toggle_tags);
  }, [formType]);

  useEffect(() => {
    const quantity = baseValueObject?.quantity;
    const rate = baseValueObject?.rate;
    if (quantity && rate) {
      setFieldValue(`${baseKey}.total_cost`, Number(quantity) * Number(rate));
    } else {
      setFieldValue(`${baseKey}.total_cost`, 0);
    }
  }, [baseValueObject?.quantity, baseValueObject?.rate]);

  return (
    <>
      <Row style={{ width: '100%' }} justify="space-between" gutter={12}>
        <Col lg={8} xs={24}>
          <Label text={'Item Description'} />
          <Input
            name={`${baseKey}.item_description`}
            id={`${baseKey}.item_description`}
            error={baseTouchedObject?.item_description && baseErrorObject?.item_description}
            value={baseValueObject?.item_description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Flex gap={12}>
            <Switch
              id={`${baseKey}.toggle_tags`}
              checked={toggle}
              defaultChecked={toggle}
              onChange={(e: boolean) => {
                setFieldValue(`${baseKey}.toggle_tags`, e);
                setToggle(e);
              }}
            />
            <span style={{ marginLeft: '6px', marginBottom: '20px' }}>Add Public Tag</span>
          </Flex>
        </Col>

        <Col lg={3} xs={12}>
          <Label text={'Qty'} />
          <Input
            name={`${baseKey}.quantity`}
            id={`${baseKey}.quantity`}
            placeholder={'0'}
            value={baseValueObject?.quantity}
            error={baseTouchedObject?.quantity && baseErrorObject?.quantity}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>

        <Col lg={3} xs={12}>
          <Label text={'Rate'} />
          <Input
            id={`${baseKey}.rate`}
            name={`${baseKey}.rate`}
            value={baseValueObject?.rate}
            placeholder={'0'}
            isCurrency={true}
            onChange={handleChange}
            error={baseTouchedObject?.rate && baseErrorObject?.rate}
          />
        </Col>

        <Col lg={3}>
          <Flex direction="column">
            <Label text={'Total Cost'} />
            <Flex top={4}>
              <Value style={{ color: other.waived_off ? 'red' : 'black' }}>
                {formatCurrency(baseValueObject?.total_cost) || 0.0}
              </Value>
            </Flex>
          </Flex>
        </Col>
        <Col lg={3}>
          <Flex style={{ height: 41 }}>
            <Label text={'   '} />
          </Flex>

          <Flex alignItems={'center'}>
            <Flex>
              <Checkbox
                id={`${baseKey}.waived_off`}
                checked={other.waived_off}
                onChange={handleChange}
              />
            </Flex>
            <Flex left={4}>
              <Value style={{ marginBottom: '0px' }}>Fee’s Waived</Value>
            </Flex>
            <RemoveIcon
              style={{ fontSize: '22px', fontWeight: '700' }}
              onClick={() => onRemove(other)}
            />
          </Flex>
        </Col>
      </Row>
      {toggle && (
        <Row style={{ width: '100%', marginTop: '16px' }}>
          <Col span={24}>
            <H4Typography>Public Tag</H4Typography>
          </Col>
          <Col span={24}>
            <span style={{ color: '#858796 ' }}>
              Please add a tag if necessary to describe this field. This is what will show in the
              quote
            </span>
          </Col>
          <Col xs={24} md={12} lg={9}>
            <TagComponent
              id={`${baseKey}.public_tags`}
              setFieldValue={setFieldValue}
              values={baseValueObject?.public_tags || []}
              placeholder="Type & Enter to add tag"
              key="public_tags"
              error={baseTouchedObject?.public_tags && baseErrorObject?.public_tags}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default OtherEstimateItem;
