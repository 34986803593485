import CenterSpiner from '@atom/CenterSpiner';
import Error from '@atom/Error';
import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import ConfirmModal from '@organism/ConfimationModal';
import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '@pages/EventManagementPage/EventWithExistingQuote/tabHelpers';
import EventBasicDetails from '@pages/QuoteManagementPage/AddEditQuote/EventBasicDetails';
import { getEventDetails } from '@pages/QuoteManagementPage/AddEditQuote/helper';
import { getValidatedData } from '@pages/QuoteManagementPage/AddEditQuote/Labours/helper';
import { EventManagementVenueWrapper } from '@pages/QuoteManagementPage/style';
import { getSelectOptions } from '@utils/helper';
import { showErrorToast, updateRawData } from '@utils/index';
import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import AddEditEventFooter from '../../AddEditEventFooter';
import { CREATE_EVENT_TAB_KEYS, getTabBaseUrl } from '../../helper';
import AdditionalStaffingSection from './AdditionalStaffingSection';
import {
  addEditStaffing,
  getStaffingDropdownData,
  getStaffingFormInitialValues,
  getStaffingInfo,
  validationSchema
} from './helper';
import StaffingSection from './StaffingSection';
import MessageModal from '@organism/MessageModal';
import { H3Typography } from '@constant/typography/Typography';

interface StaffingInformationProps {
  withQuote?: boolean;
  eventBasicDetails?: any;
  onStaffCreated?: (quoteId: string, staffValues: any) => void;
}

const StaffingInformation = (props: StaffingInformationProps) => {
  const { eventBasicDetails, onStaffCreated, withQuote } = props;
  const eventDetailsFromStore = useSelector((state: any) => state.rawData?.eventDetails);
  const location = useLocation();

  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const isEdit = location?.pathname.includes('edit');
  const params = useParams();
  const navigate = useNavigate();
  const { quoteId, eventId, formType } = params;

  const dateOption = [
    {
      name: eventBasicDetails?.event_date
        ? moment(eventBasicDetails?.event_date).format('ddd, MMMM yy')
        : 'Unassigned Date',
      id: eventBasicDetails?.event_id || null
    }
  ];

  const [formikData, setFormikData] = useState<any>({});
  const [dropdownData, setDropdownData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [isMessageModalVisible, setIsMessageModalVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessageModal] = useState<string>('');

  const [deletedData, setDeletedData] = useState<any[]>([]);
  const [allPostions, setAllPostions] = useState<any[]>([]);
  const formik = useFormik({
    initialValues: formikData,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      submitHandler(values);
    },
    enableReinitialize: true
  });

  const { values, errors, setValues, handleSubmit, setFieldValue } = formik;

  const submitHandler = async (values: any) => {
    try {
      setSubmitting(true);
      const res = (await addEditStaffing(getValidatedData(values))) as any;
      if (res?.error) {
        setErrorMessageModal(res?.error);
      }
      if (res?.success) {
        if (onStaffCreated && quoteId) {
          onStaffCreated(quoteId, values);
          setSubmitting(false);
          return;
        }

        const tabBaseUrl = getTabBaseUrl(quoteId, eventId, CREATE_EVENT_TAB_KEYS.SERVICE);
        setSubmitting(false);

        navigate(tabBaseUrl, {
          state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
        });
      } else {
        setSubmitting(false);
        setIsMessageModalVisible(true);
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      setSubmitting(false);

      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
  };

  const backHandler = () => {
    let tabBaseUrl;
    if (withQuote) {
      tabBaseUrl = `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.QUOTE}`;
    } else {
      tabBaseUrl = getTabBaseUrl(quoteId, eventId, CREATE_EVENT_TAB_KEYS.FEES);
    }
    navigate(tabBaseUrl, {
      state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
    });
  };
  const cancelHandler = () => {
    navigate('/events');
  };

  const isIncompleteData = () => {
    const staffingEmptyFields: any = values?.staffing?.filter(
      (i: any) =>
        !i?.start_time ||
        !i?.end_time ||
        !i?.quantity ||
        !i?.position_id ||
        !i?.employee_ids?.length
    );
    const additionalStaffingEmptyFields: any = values?.additional_staffing?.filter(
      (i: any) =>
        !i?.start_time ||
        !i?.end_time ||
        !i?.quantity ||
        !i?.position_id ||
        !i?.employee_ids?.length
    );
    return (
      !values?.staffing?.length ||
      !values?.additional_staffing?.length ||
      staffingEmptyFields?.length ||
      additionalStaffingEmptyFields?.length
    );
  };

  const nextHandler = () => {
    if (!isIncompleteData()) {
      handleSubmit();
    } else {
      setIsModalVisible(true);
    }
  };

  const setupFormikInitialValues = async () => {
    setLoading(true);
    if (quoteId) {
      try {
        const res = (await getStaffingInfo(eventId, quoteId)) as any;
        if (res?.success) {
          const staffingData = getStaffingFormInitialValues(
            eventDetailsFromStore,
            eventId,
            quoteId,
            res?.data
          );
          setFormikData(staffingData);
          setValues(staffingData);
        } else {
          showErrorToast({
            message: 'Failed!',
            description: 'Failed to fetch events data.'
          });
        }
      } catch (error: any) {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch events data.'
        });
      }
    }

    setLoading(false);
  };

  const setDropDown = async () => {
    try {
      const deleteDropdownData = await getStaffingInfo(eventId, quoteId);
      const staffingDropdownData = await getStaffingDropdownData();

      const filteredPositions =
        deleteDropdownData?.data?.staffing
          ?.filter((item: any) => item?.position?.deleted_at !== null)
          ?.map((item: any) => item?.position) || [];

      setDeletedData(filteredPositions);

      setDropdownData({
        accountMangers: staffingDropdownData?.[0]?.success ? staffingDropdownData?.[0]?.data : [],
        positions: staffingDropdownData?.[1]?.success ? staffingDropdownData?.[1]?.data : []
      });

      setAllPostions([...staffingDropdownData?.[1]?.data, ...filteredPositions]);
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch dropdown data.'
      });
    }
  };

  useEffect(() => {
    (async () => {
      await setDropDown();
    })();
  }, []);

  useEffect(() => {
    if (
      details &&
      formType === CREATE_EVENT_TAB_KEYS.STAFFING &&
      (!details?.event_date || details?.event_date === '0000-00-00')
    ) {
      showErrorToast({
        message: 'Warning!',
        description:
          'Please set event date in customer tab. It is required to fetch employees list.'
      });
    }
  }, [details?.event_date, formType]);

  useEffect(() => {
    if (formType === CREATE_EVENT_TAB_KEYS.STAFFING && quoteId) {
      setupFormikInitialValues();
    } else {
      const formikValues = getStaffingFormInitialValues(
        eventDetailsFromStore,
        eventId,
        quoteId,
        null
      );
      setFormikData(formikValues);
      setValues(formikValues);
      setLoading(false);
    }
  }, [formType, quoteId, eventDetailsFromStore?.account_manager_id]);

  return (
    <>
      <EventManagementVenueWrapper>
        {loading ? (
          <CenterSpiner />
        ) : (
          <>
            <EventBasicDetails />

            <Row gutter={16} style={{ marginTop: '24px' }}>
              <Col xs={24} md={10}>
                <Label text="ACCOUNT MANAGER" isMandatory={true} />
                <SelectInput
                  id={'account_manager_id'}
                  name={'account_manager_id'}
                  value={values?.account_manager_id || ''}
                  placeholdertitle="Select Account Manager"
                  error={errors.account_manager_id}
                  onChange={(value: any) => setFieldValue('account_manager_id', value)}
                >
                  {getSelectOptions(dropdownData?.accountMangers, 'name')}
                </SelectInput>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Label text="Date" isMandatory={false} />
                <SelectInput
                  id={`event_date`}
                  name={`event_date`}
                  placeholdertitle="Date"
                  value={0}
                  error={''}
                  onChange={() => {}}
                  onBlur={() => {}}
                  allowClear={false}
                >
                  {getSelectOptions(
                    [
                      {
                        id: 0,
                        name:
                          details?.event_date && details?.event_date !== '0000-00-00'
                            ? moment(details?.event_date).format('ddd, MMMM DD')
                            : 'Unassigned Date'
                      }
                    ],
                    'name'
                  )}
                </SelectInput>
              </Col>
            </Row>
            <StaffingSection
              formik={formik}
              dropdownData={dropdownData}
              eventDetails={details}
              deletedData={deletedData}
              allPositions={allPostions}
            />

            <AdditionalStaffingSection
              formik={formik}
              dropdownData={dropdownData}
              eventDetails={details}
            />

            <AddEditEventFooter
              showBackButton={true}
              backClickHandler={backHandler}
              cancelClickHandler={cancelHandler}
              nextClickHandler={nextHandler}
              isNextButtonDisabled={false}
              isSubmitting={submitting}
            />

            <ConfirmModal
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              title={`You are missing information on ${
                details?.event_date
                  ? moment(details?.event_date).format('ddd, MMMM DD')
                  : 'Unassigned Date'
              } are you sure you want to continue?`}
              onOK={() => {
                setIsModalVisible(false);
                handleSubmit();
              }}
              okFirst={true}
              okText="Yes, Continue"
            >
              <span>You can always come back and edit later</span>
            </ConfirmModal>

            <MessageModal
              visible={isMessageModalVisible}
              title={'Failed!'}
              onCancel={() => setIsMessageModalVisible(false)}
              onOK={() => {
                setIsMessageModalVisible(false);
              }}
              okText="OK"
            >
              <H3Typography>{errorMessage}</H3Typography>
            </MessageModal>
          </>
        )}
      </EventManagementVenueWrapper>
    </>
  );
};

export default StaffingInformation;
