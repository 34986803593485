import { Col, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { Divider } from 'antd';
import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { TextAreaInput } from '@atom/TextArea';
import { VenueKitchenDetails } from '@pages/VenueManagementPage/helper';
import { getIn } from 'formik';
import RadioButton from '@atom/RadioButton';
import { ContentWrapper } from '../style';

function KitchenDetailsSection(props: any) {
  const {
    powerValue,
    powerError,
    id,
    handleBlur,
    setFieldValue,
    runningWaterValue,
    runningWaterError,
    availabilityValue,
    availabilityError,
    specialValue,
    specialError,
    rentalValue,
    rentalError,
    cookingValue,
    cookingError
  } = props;
  const [power, setPower] = useState<any>();
  const [runningWater, setRunningWater] = useState<any>();
  const [availability, setAvailability] = useState<any>();
  const [special, setSpecial] = useState<any>();
  const [rental, setRental] = useState<any>();
  const [cooking, setCooking] = useState<any>();

  useEffect(() => {
    if (id && !!powerValue) {
      setPower(powerValue);
    }
    if (id && !!runningWaterValue) {
      setRunningWater(runningWaterValue);
    }
    if (id && !!availabilityValue) {
      setAvailability(availabilityValue);
    }
    if (id && !!cookingValue) {
      setCooking(cookingValue);
    }
    if (id && !!rentalValue) {
      setRental(rentalValue);
    }
    if (id && !!specialValue) {
      setSpecial(specialValue);
    }
  }, [id]);

  const handleChange = (e: any, setState: (value: any) => void) => {
    setTimeout(() => {
      setFieldValue(e?.target?.id, e?.target?.value);
    }, 200);
    setState(e?.target?.value);
  };

  const handleRadioChange = (e: any, setState: (value: any) => void) => {
    setTimeout(() => {
      setFieldValue(e?.target?.name, e?.target?.value);
    }, 200);
    setState(e?.target?.value);
  };

  return (
    <Flex style={{ width: '100%' }} direction="column">
      <H2Typography style={{ marginTop: '15px' }}>Kitchen Details</H2Typography>
      <Divider style={{ borderColor: '#e0e0e0', margin: '15px 0px' }}></Divider>
      <ContentWrapper>
        <Row gutter={16} justify="space-between" style={{ width: '100%', marginBottom: '24px' }}>
          <Col xs={24} lg={12}>
            <Label text="Power" />
            <RadioButton
              name="venue_kitchen_detail.power_available"
              id="venue_kitchen_detail.power_available"
              value={power && Number(power)}
              onChange={(e) => handleRadioChange(e, setPower)}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </RadioButton>
          </Col>
          <Col xs={24} lg={12}>
            <Label text="Running Water" />
            <RadioButton
              name="venue_kitchen_detail.running_water"
              id="venue_kitchen_detail.running_water"
              value={runningWater && Number(runningWater)}
              onChange={(e) => handleRadioChange(e, setRunningWater)}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </RadioButton>
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" style={{ width: '100%' }}>
          <Col xs={24} lg={12}>
            <Label text="Kitchen Availability" />

            <SelectInput
              id={`venue_kitchen_detail.kitchen_availability`}
              name={`venue_kitchen_detail.kitchen_availability`}
              placeholdertitle="Select One"
              value={
                VenueKitchenDetails?.find((item: any) => item?.value === availabilityValue)?.label
              }
              error={availabilityError}
              onChange={(id: string) => {
                setFieldValue(`venue_kitchen_detail.kitchen_availability`, id);
              }}
              onBlur={handleBlur}
              allowClear={true}
              options={VenueKitchenDetails}
            />
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" style={{ width: '100%' }}>
          <Col xs={24} lg={24}>
            <Label text="In House Cooking Equipment" />
            <TextAreaInput
              id={`venue_kitchen_detail.cooking_equipments`}
              name={`venue_kitchen_detail.cooking_equipments`}
              placeholder="Type"
              value={special}
              error={specialError}
              onChange={(e: any) => handleChange(e, setSpecial)}
              onBlur={handleBlur}
              allowClear={true}
            />
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" style={{ width: '100%' }}>
          <Col xs={24} lg={24}>
            <Label text="Special Rental Equipment" />
            <TextAreaInput
              id={`venue_kitchen_detail.rental_equipments`}
              name={`venue_kitchen_detail.rental_equipments`}
              placeholder="Type"
              value={rental}
              error={rentalError}
              onChange={(e: any) => handleChange(e, setRental)}
              onBlur={handleBlur}
              allowClear={true}
            />
          </Col>
        </Row>

        <Row gutter={16} justify="space-between" style={{ width: '100%' }}>
          <Col xs={24} lg={24}>
            <Label text="Catering Rentals Required" />
            <TextAreaInput
              id={`venue_kitchen_detail.special_equipments`}
              name={`venue_kitchen_detail.special_equipments`}
              placeholder="Type"
              value={cooking}
              error={cookingError}
              onChange={(e: any) => handleChange(e, setCooking)}
              onBlur={handleBlur}
              allowClear={true}
            />
          </Col>
        </Row>
      </ContentWrapper>
    </Flex>
  );
}

export default KitchenDetailsSection;
