import React, { useEffect, useState } from 'react';
import PageLayout from '@layout/PageLayout';
import { Flex } from '@atom/Flex';
import { PageTitle } from '@molecules/PageTitle';
import { Button } from '@atom/Buttons';
import { useLocation, useNavigate, useParams } from 'react-router';
import QuoteManagementTabs from './QuoteManagementTabs';
import { QUOTE_MANAGEMENT_TABS } from './helper';
import { useSelector } from 'react-redux';
import { DateFilter } from '@molecules/DateFilter';
import { Col, Row } from 'antd';
import { localStorageUtil } from '@utils/localstorageUtil';

const QuotesList = (props: any) => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [activePanel, setActivePanel] = useState<any>();
  const startingDate = localStorage.getItem('quote_start_date') ?? '';
  const [startDate, setStartDate] = useState(startingDate);
  const endingDate = localStorage.getItem('quote_end_date') ?? '';
  const [endDate, setEndDate] = useState(endingDate);
  const [callApi, setCallApi] = useState<boolean>(false);

  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  const getReport = async () => {
    const BASE_URL_GENERATE_REPORT = `${process.env.REACT_APP_BASE_API_GENERATE_REPORT}/quotes/generate-report`;
    window.open(BASE_URL_GENERATE_REPORT + `/${startDate}/${endDate}`, '_blank');
  };

  useEffect(() => {
    const paths = window.location.pathname?.split('/') || [];
    if (paths?.length && (paths.pop() === 'quote' || paths.pop() === 'quote')) {
      navigate(`/quote/tab/${QUOTE_MANAGEMENT_TABS.ALL}`);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setActivePanel(tab);
  }, [tab]);

  return (
    <PageLayout>
      <Flex direction="column" style={{ width: '100%' }}>
        <PageTitle text="QUOTE MANAGEMENT" isBack={false}>
          {isAdmin && (
            <Button
              text="Add Quote"
              onClick={() => {
                localStorageUtil.setValue('location', {
                  state: { prevPath: location?.pathname }
                });
                navigate('/quote/add-quote/customer', {
                  state: { prevPath: location?.pathname }
                });
              }}
            />
          )}
        </PageTitle>
        <Row>
          <Col lg={24} style={{ display: 'flex', justifyContent: 'end' }}>
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              setCallApi={setCallApi}
              generateReport={() => getReport()}
            />
          </Col>
        </Row>
      </Flex>
      <QuoteManagementTabs
        activePanel={activePanel}
        startDate={startDate}
        endDate={endDate}
        setCallApi={setCallApi}
        callApi={callApi}
      />
    </PageLayout>
  );
};

export default QuotesList;
