import { Label } from '@atom/FormLable';
import { TextAreaInput } from '@atom/TextArea';
import { H2Typography } from '@constant/typography/Typography';
import { Col, Row } from 'antd';

interface ManagerNotesProps {
  formik: any;
}

const ManagerNotes = (props: ManagerNotesProps) => {
  const { formik } = props;
  const { touched, errors, values, handleBlur, handleChange } = formik;

  return (
    <>
      <H2Typography style={{ marginTop: '32px' }}>Manager Notes</H2Typography>
      <hr />
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col md={24} xs={24}>
          <Label text="Manager Notes" isMandatory={false} />
          <TextAreaInput
            id={'manager_notes'}
            name={'manager_notes'}
            placeholder={'Add Manager Notes'}
            value={values?.manager_notes}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.manager_notes && errors?.manager_notes}
            showCount
            maxLength={19000}
          />
        </Col>
      </Row>
    </>
  );
};

export default ManagerNotes;
