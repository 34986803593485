import { ChangeEvent } from 'react';
import { Flex } from '@atom/Flex';
import { IVenueListFilterType } from '../types';
import StatusLabel from '@atom/StatusLabel';
import TableHeader from './TableHeader';

export const getColumns = (
  filterValue: IVenueListFilterType,
  onChange: (a: ChangeEvent) => void
) => {
  const columns = [
    {
      title: (
        <TableHeader
          columnTitle="Venue Name"
          name="venue_name"
          value={filterValue.venue_name ?? ''}
          placeholder="Venue Name"
          onChange={onChange}
        />
      ),
      dataIndex: 'venue_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Location"
          name="venue_address"
          value={filterValue.venue_address ?? ''}
          placeholder="City, Province"
          onChange={onChange}
        />
      ),
      dataIndex: 'venue_address',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Email"
          name="contact_email"
          value={filterValue.contact_email ?? ''}
          placeholder="Email"
          onChange={onChange}
        />
      ),
      dataIndex: 'contact_email',
      render: (contact_email: string, data: any) => {
        return data?.first_additional_contacts?.contact_email ?? '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Phone Number"
          name="contact_phone_number"
          value={filterValue.contact_phone_number ?? ''}
          placeholder="Phone Number"
          onChange={onChange}
        />
      ),
      dataIndex: 'formated_contact_phone_number',
      render: (formated_contact_phone_number: string, data: any) => {
        const countryCode = data?.first_additional_contacts?.country_code?.includes('+')
          ? data?.first_additional_contacts?.country_code
          : data?.first_additional_contacts?.country_code &&
            `+${data?.first_additional_contacts?.country_code}`;
        const phone = data?.first_additional_contacts?.formated_contact_phone_number;
        const extension = data?.first_additional_contacts?.extension;
        const phoneNo = `${countryCode || ''} ${phone || ''} ${extension ? `x${extension}` : ''}`;

        return data?.first_additional_contacts?.formated_contact_phone_number ? (
          <Flex style={{ width: '100%' }} justifyContent="space-between" alignItems="center">
            <span>{phoneNo}</span>
          </Flex>
        ) : (
          '-'
        );
      }
    },
    {
      title: '',
      dataIndex: 'status',
      render: (status: any) => {
        return <StatusLabel status={status} isUppercase />;
      }
    }
  ];
  return columns;
};
