import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import React from 'react';
import { Col, Divider, Row } from 'antd';
import FormDataView from '@atom/FormDataView';
import { Flex } from '@atom/Flex';
import { Button } from '@atom/Buttons';
import DropdownButton from '@atom/DropdownButton';
import { H2Typography, H4, H4Typography } from '@constant/typography/Typography';
import { EditPencilIcon } from '@assets/index';
import { useNavigate, useParams } from 'react-router';
import { getCustomerDetails, getFormattedPhoneNumber } from './helper';
import useSWR from 'swr';
import CenterSpiner from '@atom/CenterSpiner';
import { CUSTOMER_TYPE } from './constants';
import moment from 'moment';
import { CustomerDetailWrapper } from './style';

interface CustomerDetailsProps {
  comingFromPage?: number;
}

const CustomerDetails = (props: CustomerDetailsProps) => {
  const { comingFromPage } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isValidating } = useSWR([`/customer/detail`, id], getCustomerDetails, {
    revalidateOnFocus: false
  });
  const customerDetails = data || {};

  return (
    <PageLayout>
      <PageTitle
        text="CUSTOMER MANAGEMENT"
        bordered
        isBack
        path="/customers"
        isBottom
        goToPage={comingFromPage}
      >
        <Button
          variant="ghost"
          icon={<img src={EditPencilIcon} alt="editPencilIcon" />}
          text="Edit"
          onClick={() => navigate(`/customers/${id}/edit`)}
        />
      </PageTitle>
      {isValidating ? (
        <CenterSpiner />
      ) : (
        <>
          {customerDetails?.customer_type === CUSTOMER_TYPE.INDIVIDUAL ? (
            <>
              <CustomerDetailWrapper gutter={24}>
                <Col className="customer-col" md={8} xs={8}>
                  <FormDataView heading="NAME" value={customerDetails?.name} />
                </Col>
                <Col className="customer-col" md={8} xs={8}>
                  <FormDataView heading="EMAIL" value={customerDetails?.email} />
                </Col>
                <Col
                  md={8}
                  xs={8}
                  className="text-xs-left customer-col"
                  style={{ textAlign: 'right' }}
                >
                  <FormDataView
                    heading="REGISTRATION DATE"
                    value={
                      customerDetails?.registered_at
                        ? moment(customerDetails?.registered_at).format('ddd, MMMM DD')
                        : undefined
                    }
                  />
                </Col>
              </CustomerDetailWrapper>
              <CustomerDetailWrapper gutter={24}>
                <Col className="customer-col" lg={8} xs={8}>
                  <FormDataView
                    heading="PHONE NUMBER"
                    value={getFormattedPhoneNumber(
                      customerDetails?.country_code ?? '+1',
                      customerDetails?.formated_phone_number,
                      customerDetails?.extension
                    )}
                  />
                </Col>
                <Col className="customer-col" lg={8} xs={8}>
                  <FormDataView heading="BILLING ADDRESS" value={customerDetails?.address} />
                </Col>
                <Col className="customer-col" lg={8} xs={8}>
                  <FormDataView
                    heading="ADDITIONAL NOTES"
                    value={customerDetails?.additional_notes}
                  />
                </Col>
              </CustomerDetailWrapper>
            </>
          ) : (
            <>
              <Row gutter={16}>
                <Col lg={16} sm={12} xs={24}>
                  <FormDataView heading="COMPANY NAME" value={customerDetails?.company_name} />
                </Col>
                <Col lg={8} sm={12} xs={24} className="text-xs-left" style={{ textAlign: 'right' }}>
                  <FormDataView
                    heading="REGISTRATION DATE"
                    value={
                      customerDetails?.registered_at
                        ? moment(customerDetails?.registered_at).format('ddd, MMMM DD')
                        : undefined
                    }
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col lg={8} sm={12} xs={24}>
                  <FormDataView heading="CONTACT NAME" value={customerDetails?.name} />
                </Col>
                <Col lg={8} sm={12} xs={24}>
                  <FormDataView heading="CONTACT EMAIL" value={customerDetails?.email} />
                </Col>
                <Col lg={8} sm={12} xs={24}>
                  <FormDataView
                    heading="CONTACT PHONE NUMBER"
                    value={getFormattedPhoneNumber(
                      customerDetails?.country_code,
                      customerDetails?.formated_phone_number,
                      customerDetails?.extension
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={8} sm={12} xs={24}>
                  <FormDataView heading="BILLING ADDRESS" value={customerDetails?.address} />
                </Col>
                <Col lg={16} sm={12} xs={24}>
                  <FormDataView
                    heading="ADDITIONAL NOTES"
                    value={customerDetails?.additional_notes}
                  />
                </Col>
              </Row>
            </>
          )}
          <Flex direction="column" className="fix-mobile" gap={20}>
            <Flex justifyContent={'flex-end'}>
              <Button
                text="Create Quote"
                onClick={() => navigate(`/quote/add-quote/customer?customer_id=${id}`)}
              />
            </Flex>
            <Flex justifyContent={'space-between'} className="custumerdetails-mobile-fix">
              <H2Typography className="text-mobile-fix">Representatives</H2Typography>
              <DropdownButton
                text={'Create Event'}
                options={[
                  { key: 'withQuote', label: 'With Quote' },
                  { key: 'withoutQuote', label: 'Without Quote' }
                ]}
                onSelect={(item: any) => {
                  if (item.key === 'withQuote') {
                    navigate('/events/find-quote');
                  } else {
                    navigate(`/events/create/customer?customer_id=${id}`);
                  }
                }}
              />
            </Flex>
          </Flex>
          <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
          {customerDetails?.representatives?.length ? (
            <>
              <Flex direction="column" className="customer-table">
                <Flex style={{ paddingTop: '15px' }}>
                  <Row gutter={16} style={{ width: '100%' }}>
                    <Col span={6} md={8} sm={8} xs={12}>
                      <H4Typography>NAME</H4Typography>
                    </Col>
                    <Col className="min-width-fixing" span={6} md={8} sm={8} xs={12}>
                      <H4Typography>EMAIL</H4Typography>
                    </Col>
                    <Col span={6} md={8} sm={8} xs={12}>
                      <H4Typography>PHONE NUMBER</H4Typography>
                    </Col>
                  </Row>
                </Flex>
                {customerDetails?.representatives.map((item: any, index: number) => (
                  <Flex
                    className="inside-customer-table"
                    direction="column"
                    style={{ paddingTop: '15px' }}
                    key={index}
                  >
                    <Row gutter={16} style={{ width: '100%' }}>
                      <Col span={6} md={8} sm={8} xs={12}>
                        <H4 text={item.name} style={{ fontWeight: 'normal' }} />
                      </Col>
                      <Col className="min-width-fixing" span={6} md={8} sm={8} xs={12}>
                        <H4 text={item.email} style={{ fontWeight: 'normal' }} />
                      </Col>
                      <Col span={6} md={8} sm={8} xs={12}>
                        <H4
                          text={getFormattedPhoneNumber(
                            item?.country_code ?? '+1',
                            item?.phone_number,
                            item?.extension
                          )}
                          style={{ fontWeight: 'normal' }}
                        />
                      </Col>
                    </Row>
                  </Flex>
                ))}
              </Flex>
            </>
          ) : (
            <Flex direction="column">
              <H4Typography>NO REPRESENTATIVE</H4Typography>
              <H4
                style={{ color: '#b5b5b5', fontWeight: 'normal' }}
                text="No Representative Available"
              />
            </Flex>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default CustomerDetails;
