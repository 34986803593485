import { Col, Row } from 'antd';
import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import { getVendorEstimatesColumns } from './columns';
import { SectionTitleDiv, SubtotalAndCostDiv } from './style';
import { getSubTotal } from './helper';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';

interface VendorEstimatesProps {
  data: any;
}

const getMappedTableData = (eventDatesData: any) => {
  let tableData = [];
  tableData = eventDatesData?.vendors?.length
    ? eventDatesData?.vendors?.map((i: any) => ({
        item_name: i?.vendor?.vendor_name,
        qty: i?.quantity,
        rate: i?.rate,
        cost: i?.total_cost,
        waived_off: !!i?.waived_off
      }))
    : [];

  //to view last row for subtotal in table
  tableData.push({
    item_name: '',
    qty: '',
    rate: 'SUBTOTAL',
    cost: getSubTotal(eventDatesData, 'vendors'),
    waived_off: ''
  });
  return tableData;
};

const VendorEstimates = (props: VendorEstimatesProps) => {
  const { data } = props;
  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'quote-section'}>Vendors</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} style={{ marginTop: '20px' }}>
          <DataTableWrapper>
            <DataTable
              className="fixTD"
              renderEmpty={() => {
                return <>No Data Found.</>;
              }}
              dataSource={getMappedTableData(data?.event_dates)}
              columns={getVendorEstimatesColumns()}
            />
          </DataTableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default VendorEstimates;
