import { CloseCircleOutlined } from '@ant-design/icons';
import { Flex } from '@atom/Flex';
import SearchableSelect from '@atom/SearchableSelect';
import SelectInput from '@atom/Select';
import { H5Typography } from '@constant/typography/Typography';
import { EMPLOYEE_STATUS_COLOR } from '@constants/userConstants';
import { AssigneeItem } from '@pages/StaffManagementPage/StaffManagementDataTable/style';
import { updateRawData } from '@utils/index';
import { Popover, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAvailableEmployees } from './helper';
const { Option } = Select;
function UnassignedEmployeePopOver(props: any) {
  const { onUpdateQuantity, staff, onStaffAdded, index } = props;
  const selectedEmployee = staff?.employees?.[index];
  const selectedEmployeeIds = staff?.employees?.map((i: any) => i?.employee_id);
  const [visiblePopOver, setVisiblePopOver] = useState(false);

  return (
    <AssigneeItem>
      {!selectedEmployee ? (
        <>
          <Popover
            placement="bottom"
            title={'    '}
            trigger="click"
            open={visiblePopOver}
            onOpenChange={(visible: boolean) => setVisiblePopOver(visible)}
            content={
              <EmployeesDropDown
                selectedEmployeeIds={selectedEmployeeIds}
                selectedEmployee={selectedEmployee}
                staff={staff}
                onStaffAdded={(e: any) => {
                  setVisiblePopOver(false);
                  onStaffAdded(e);
                }}
              />
            }
          >
            <Flex
              style={{ cursor: 'pointer' }}
              onClick={() => !selectedEmployee?.employee && setVisiblePopOver(true)}
            >
              <span className="unassigned">Unassigned</span>
            </Flex>
          </Popover>
        </>
      ) : (
        <span>{selectedEmployee?.employee?.name}</span>
      )}
      <CloseCircleOutlined
        onClick={() => onUpdateQuantity(-1, selectedEmployee)}
        style={{ marginLeft: 5, cursor: 'pointer' }}
      />
    </AssigneeItem>
  );
}

const EmployeesDropDown = (props: any) => {
  const { staff, onStaffAdded, selectedEmployee, selectedEmployeeIds } = props;
  const employees = useSelector(
    (state: any) => state.rawData?.[`staffEmployees_${staff.position_id}`]
  );
  useEffect(() => {
    if (!employees) {
      (async () => {
        const res = await getAvailableEmployees({
          end_time: staff?.end_time,
          event_date: staff?.event_date,
          event_id: staff?.event_id,
          position_id: staff?.position_id,
          start_time: staff?.start_time
        });
        if (!res.error) {
          updateRawData({ [`staffEmployees_${staff.position_id}`]: res.data });
        }
      })();
    }
  }, []);
  return (
    <SelectInput
      id={'assignee'}
      name={'assignee'}
      placeholdertitle={'Search Employee'}
      style={{ width: 300 }}
      value={employees?.length ? selectedEmployee?.employee_id : ''}
      onChange={onStaffAdded}
      filterOption={(key: any, e: any) => e?.name?.toLowerCase()?.includes(key?.toLowerCase())}
    >
      {employees
        ?.filter((i: any) => !selectedEmployeeIds?.includes(i?.id))
        ?.map((emp: any) => {
          const { id, name, status, status_id } = emp;
          return (
            <Option key={id} value={id} name={name}>
              <Flex>
                <H5Typography style={{ flex: 1 }}>{name}</H5Typography>
                <H5Typography style={{ color: EMPLOYEE_STATUS_COLOR[status_id] }}>
                  {status}
                </H5Typography>
              </Flex>
            </Option>
          );
        })}
    </SelectInput>
  );
};

export default UnassignedEmployeePopOver;
