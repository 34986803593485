import { Col, Row, Tooltip } from 'antd';
import FormDataView from '@atom/FormDataView';
import { H2Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import { getMealPlanColumns } from './columns';
import { MealPlanRowWrapper, SectionTitleDiv } from './style';
import moment from 'moment';
import { getIsExpandedArray, getSubTotal } from './helper';
import { useEffect, useState } from 'react';
import { DownOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';
import ModalComponent from '@organism/Modal/Modal';
import ProductDetails from '@pages/MenuManagementPage/DetailsViewPages/ProductDetails';
import ThreeDotCircle from '@assets/icons/ThreeDotCircle.svg';
import { useSelector } from 'react-redux';
import { DataTablepaddingWrapper } from '@pages/EventManagementPage/EventDetails/EventInfo/style';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';

interface MealPlanAndEstimateProps {
  data: any;
  isEventData: boolean;
  eventDate: any;
}

const MealPlanAndEstimate = (props: MealPlanAndEstimateProps) => {
  let { data: dataFromProps, isEventData, eventDate } = props;
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;
  const [data, setData] = useState<any>();
  const [showProductDetail, setShowProductDetail] = useState<boolean>(false);
  const [productId, setProductId] = useState<number>();
  const [bundleExpanded, setBundleExpanded] = useState<Array<Array<boolean>>>(
    data && getIsExpandedArray(data)
  );
  const device = useWindowSizeChangedforGrid();

  useEffect(() => {
    if (isEventData) {
      setData({
        event_dates: {
          meal_plans: dataFromProps
        }
      });
      setBundleExpanded(
        getIsExpandedArray({
          event_dates: {
            meal_plans: dataFromProps
          }
        })
      );
    } else {
      setBundleExpanded(getIsExpandedArray(dataFromProps));
      setData(dataFromProps);
    }
  }, [isEventData, dataFromProps]);

  const handleShowHide = (arrayIndex: number, itemIndex: number, setShowBundle: any) => {
    setShowBundle((prevState: any) => {
      const tempState = [...prevState];
      tempState[arrayIndex][itemIndex] = !tempState?.[arrayIndex]?.[itemIndex];
      return tempState;
    });
  };

  const modalOpenCloseHandler = (openModal: boolean, id?: number) => {
    if (openModal && id) {
      setShowProductDetail(openModal);
      setProductId(id);
    } else {
      setShowProductDetail(false);
      setProductId(undefined);
    }
  };

  const getProductName = (
    tooltipTitle: string,
    product_id: number,
    productName: string,
    specialInstruction: string
  ) => {
    return (
      <>
        <span
          style={{
            marginRight: '10px',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={() => {
            modalOpenCloseHandler(true, product_id);
          }}>
          {/* {product_id === productId && showProductDetail ? (
            <EyeOutlined />
          ) : (
            <EyeInvisibleOutlined />
          )} */}
          <img width={15} src={ThreeDotCircle} alt="view" />
        </span>
        <Tooltip
          title={tooltipTitle}
          trigger="hover"
          placement="right"
          color="#fafafa"
          overlayInnerStyle={{ color: 'grey', fontSize: '16px' }}>
          <span>{`${productName}    ${specialInstruction || ''}`}</span>
        </Tooltip>
      </>
    );
  };

  const getTableData = (
    curData: any,
    arrayIndex: number,
    showBundles: Array<Array<boolean>>,
    setShowBundle: (val: Array<Array<boolean>>) => void
  ) => {
    let tableData: any = [];
    curData?.bundles?.forEach((i: any, index: number) => {
      tableData.push({
        isBundle: true,
        product_name: (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => handleShowHide(arrayIndex, index, setShowBundle)}>
            {i?.bundle?.bundle_name}
            <DownOutlined style={{ marginLeft: '10px' }} />
          </span>
        ),
        special_instruction: i?.special_instruction,
        portions: i.portions,
        qty: i?.bundle_quantity,
        rate: i?.bundle_rate,
        cost: i?.bundle_subtotal
      });
      if (showBundles?.[arrayIndex]?.[index]) {
        i?.products?.forEach((j: any) =>
          tableData.push({
            product_name: getProductName(
              j?.ingredients?.map((ingredient: any) => ingredient?.ingredient?.name)?.join(', ') ||
                'Not Specified',
              j?.product_id,
              j?.product?.product_name,
              j?.product?.special_instruction
            ),
            special_instruction: j?.special_instruction,
            portions: j.portions,
            qty: j?.quantity,
            rate: isEventData || !i?.hide_price ? j?.rate : '',
            cost: isEventData || !i?.hide_price ? j?.total_cost : ''
          })
        );
      }
    });
    curData?.products?.forEach((j: any) =>
      tableData.push({
        product_name: getProductName(
          j.ingredients?.map((ingredient: any) => ingredient?.ingredient?.name)?.join(', ') ||
            'Not Specified',
          j.product_id,
          j?.product?.product_name,
          j?.product?.special_instruction
        ),
        special_instruction: j?.special_instruction,
        portions: j.portions,
        qty: j?.quantity,
        rate: j?.rate,
        cost: j?.total_cost
      })
    );

    //to view last row for subtotal in table
    tableData.push({
      product_name: '',
      special_instruction: '',
      portions: '',
      qty: '',
      rate: 'SUBTOTAL',
      cost: `
      ${(
        Number(getSubTotal(curData, 'products')) +
        Number(
          getSubTotal(
            {
              bundle: curData?.bundles?.map((j: any) => ({
                total_cost: j?.bundle_subtotal
              }))
            },
            'bundle'
          )
        )
      )?.toFixed(2)}`
    });

    return tableData;
  };

  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={isEventData ? 'event-section' : 'quote-section'}>
              Meal Plan & Estimate
            </H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      {data?.event_dates?.meal_plans?.map((i: any, index: number) => (
        <div key={index}>
          <MealPlanRowWrapper gutter={16}>
            {/* {!isEventData && ( */}
            <>
              <Col
                xs={!isEventData ? 12 : 24}
                lg={6}
                style={{ paddingLeft: `${isEventData ? '20px' : '10px'}` }}>
                <FormDataView
                  heading={`${i?.meal_name} Date`}
                  value={
                    isEventData
                      ? eventDate && eventDate !== '0000-00-00'
                        ? moment(eventDate).format('ddd, MMMM D')
                        : '--'
                      : data?.event_dates?.date_string || '-'
                  }
                />
              </Col>
              <Col
                xs={!isEventData ? 12 : 24}
                lg={6}
                style={{ paddingLeft: `${isEventData ? '20px' : '10px'}` }}>
                <FormDataView
                  heading={`${i?.meal_name} Time`}
                  value={`${
                    i?.start_time && i?.end_time
                      ? `${moment(i?.start_time, 'hh:mm:ss').format('hh:mm A')} - ${moment(
                          i?.end_time,
                          'hh:mm:ss'
                        ).format('hh:mm A')}`
                      : '-'
                  }`}
                />
              </Col>
            </>
            {/* )} */}
            <Col
              span={24}
              style={
                isEventData ? { marginBottom: '20px' } : { marginTop: '20px', marginBottom: '20px' }
              }>
              <DataTablepaddingWrapper>
                <DataTableWrapper>
                  <DataTable
                    className={`${device == 'mobile' ? 'fixMealPlanTable' : ''}`}
                    renderEmpty={() => {
                      return <>No Data Found.</>;
                    }}
                    style={{ borderBottom: '1px solid #000' }}
                    dataSource={getTableData(i, index, bundleExpanded, setBundleExpanded)}
                    columns={getMealPlanColumns(isEventData, isAdmin)}
                  />
                </DataTableWrapper>
              </DataTablepaddingWrapper>
            </Col>
          </MealPlanRowWrapper>
        </div>
      ))}
      {!data?.event_dates?.meal_plans?.length && <span>No meal plans found.</span>}
      {!data && <></>}
      {showProductDetail && (
        <ModalComponent
          visible={showProductDetail}
          onCancel={() => {
            modalOpenCloseHandler(false);
          }}
          modalWidth="700px !important"
          closable={true}
          align="left"
          padding={'5px !important'}
          wrapperPadding={'20px !important'}>
          <ProductDetails
            productId={productId}
            isEditable={false}
            showProductDetail={showProductDetail}
          />
        </ModalComponent>
      )}
    </>
  );
};

export default MealPlanAndEstimate;
