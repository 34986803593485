import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  .add-another-btn {
    border: none;
    padding: 0px;
    font-weight: bolder;
    font-size: 14px;
    line-height: 1;
    color: #000;
    border-bottom: 1px solid;
    border-radius: 0;
    margin-left: 10px;
  }
`;

export const ContentWrapper = styled.div`
  label {
    font-size: 18px;
    line-height: 21px;
    @media (max-width: 768px) {
      font-size: 13px;
      line-height: 17px;
    }
  }
`;
export const TabWrapper = styled.div`
  @media (max-width: 768px) {
    .ant-tabs-nav-wrap {
      overflow: auto !important;
    }
  }
`;
