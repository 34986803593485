import { Col, Divider, Radio, Row } from 'antd';
import React, { ReactHTMLElement, useEffect, useState } from 'react';

import Checkbox from '@atom/Checkbox';
import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { Input } from '@atom/Input';
import { Label } from '@atom/FormLable';
import Switch from '@atom/Switch';
import { ContentWrapper } from '../style';

function LandmarkFeeSection(props: any) {
  const {
    id,
    handleBlur,
    setFieldValue,

    foodFeesValue,
    foodFeesError,

    alcoholFeesError,
    alcoholFeesValue,

    labourFeesError,
    labourFeesValue,

    landmarkFeesError,
    landmarkFeesValue,

    netTotalError,
    netTotalValue,

    customizeFeesValue,
    newVenueInQuoteOrEvent
  } = props;

  const [foodFees, setFoodFees] = useState<any>();
  const [alcoholFees, setAlcoholFees] = useState<any>();
  const [labourFees, setLabourFees] = useState<any>();
  const [landmarkFees, setLandmarkFees] = useState<any>();
  const [netTotal, setNetTotal] = useState<any>();
  const [toggle, setToggle] = useState(customizeFeesValue ? true : false);

  useEffect(() => {
    if (id && !!foodFeesValue) {
      setFoodFees(!netTotalValue ? foodFeesValue : false);
    }
    if (id && !!alcoholFeesValue) {
      setAlcoholFees(!netTotalValue ? alcoholFeesValue : false);
    }
    if (id && !!labourFeesValue) {
      setLabourFees(!netTotalValue ? labourFeesValue : false);
    }
    if (id && !!landmarkFeesValue) {
      setLandmarkFees(landmarkFeesValue);
    }
    if (id && !!netTotalValue) {
      setNetTotal(netTotalValue);
    }
    if (id && !!customizeFeesValue) {
      setToggle(customizeFeesValue ? true : false);
    }
  }, [id]);

  const handleChange = (e: any, setState: (value: any) => void) => {
    setTimeout(() => {
      setFieldValue(e.target.name, e.target.value);
    }, 200);
    setState(e.target.value);
  };

  const handleToggleChange = (e: any, setState: (value: any) => void) => {
    setTimeout(() => {
      setFieldValue(e?.target?.name, e?.target?.checked);
    }, 200);
    setState(e?.target?.checked);
  };

  return (
    <Flex style={{ width: '100%' }} direction="column">
      <H2Typography style={{ marginTop: '15px' }}>Landmark Fee</H2Typography>
      <Divider style={{ borderColor: '#e0e0e0', margin: '15px 0px' }}></Divider>
      <ContentWrapper>
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <Label text="Landmark Fee" />
            <Row gutter={16}>
              {toggle ? (
                <>
                  <Col xs={24} lg={newVenueInQuoteOrEvent ? 4 : 3}>
                    <Label text="Food" />
                    <Input
                      name="venue_detail.food_fees"
                      id="venue_detail.food_fees"
                      error={foodFeesError}
                      placeholder="%"
                      value={foodFees}
                      onChange={(e) => handleChange(e, setFoodFees)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={24} lg={newVenueInQuoteOrEvent ? 6 : 3}>
                    <Label text="Alcohol & Soft Bar" />
                    <Input
                      name="venue_detail.alcohol_soft_bar_fees"
                      id="venue_detail.alcohol_soft_bar_fees"
                      error={alcoholFeesError}
                      placeholder="%"
                      value={alcoholFees}
                      onChange={(e) => handleChange(e, setAlcoholFees)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={24} lg={3}>
                    <Label text="Labour" />
                    <Input
                      name="venue_detail.labour_fees"
                      id="venue_detail.labour_fees"
                      placeholder="%"
                      error={labourFeesError}
                      value={labourFees}
                      onChange={(e) => handleChange(e, setLabourFees)}
                      onBlur={handleBlur}
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={24} lg={newVenueInQuoteOrEvent ? 5 : 3}>
                    <Input
                      name="venue_detail.landmark_fees"
                      id="venue_detail.landmark_fees"
                      placeholder="%"
                      error={landmarkFeesError}
                      value={landmarkFees}
                      onChange={(e) => handleChange(e, setLandmarkFees)}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col lg={newVenueInQuoteOrEvent ? 3 : 2} xs={newVenueInQuoteOrEvent ? 24 : 12}>
                    <Checkbox
                      name="Food"
                      id="venue_detail.food_fees"
                      error={foodFeesError}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleToggleChange(e, setFoodFees);
                        if (e.target.checked) {
                          setFieldValue('venue_detail.on_net_total', false);
                          setNetTotal('');
                        }
                      }}
                      checked={foodFees == 0.0 ? false : foodFees}
                    />
                  </Col>
                  <Col lg={newVenueInQuoteOrEvent ? 6 : 3} xs={newVenueInQuoteOrEvent ? 24 : 12}>
                    <Checkbox
                      name="Alcohol & Soft Bar"
                      id="venue_detail.alcohol_soft_bar_fees"
                      error={alcoholFeesError}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleToggleChange(e, setAlcoholFees);
                        if (e.target.checked) {
                          setFieldValue('venue_detail.on_net_total', false);
                          setNetTotal('');
                        }
                      }}
                      checked={alcoholFees == 0.0 ? false : alcoholFees}
                    />
                  </Col>
                  <Col lg={newVenueInQuoteOrEvent ? 4 : 2} xs={newVenueInQuoteOrEvent ? 24 : 12}>
                    <Checkbox
                      name="Labour"
                      id="venue_detail.labour_fees"
                      error={labourFeesError}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleToggleChange(e, setLabourFees);
                        if (e.target.checked) {
                          setFieldValue('venue_detail.on_net_total', false);
                          setNetTotal('');
                        }
                      }}
                      checked={labourFees == 0.0 ? false : labourFees}
                    />
                  </Col>
                  <Col lg={newVenueInQuoteOrEvent ? 4 : 2} xs={12}>
                    <Checkbox
                      name="Net Total"
                      id="venue_detail.on_net_total"
                      error={netTotalError}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleToggleChange(e, setNetTotal);
                        if (e.target.checked) {
                          setFieldValue('venue_detail.labour_fees', false);
                          setFieldValue('venue_detail.alcohol_soft_bar_fees', false);
                          setFieldValue('venue_detail.food_fees', false);
                          setAlcoholFees('');
                          setFoodFees('');
                          setLabourFees('');
                        }
                      }}
                      checked={netTotal}
                    />
                  </Col>
                </>
              )}
              <Col md={10} xs={24}>
                <Flex gap={16} className="margin-top">
                  <Switch
                    onChange={(e: boolean) => {
                      setToggle(!toggle);
                      setFieldValue('venue_detail.on_net_total', '');
                      setFieldValue('venue_detail.labour_fees', '');
                      setFieldValue('venue_detail.alcohol_soft_bar_fees', '');
                      setFieldValue('venue_detail.food_fees', '');
                      setFieldValue('venue_detail.landmark_fees', '');
                      setAlcoholFees('');
                      setFoodFees('');
                      setLabourFees('');
                      setLandmarkFees('');
                      setNetTotal('');
                      if (e) {
                        setFieldValue('venue_detail.is_customize_landmark_fees', 1);
                      } else {
                        setFieldValue('venue_detail.is_customize_landmark_fees', 0);
                      }
                    }}
                    checked={toggle}
                  />

                  <Label
                    style={{ fontSize: '14px' }}
                    text={toggle ? 'return to same % per category' : 'Customize % Per Category'}
                  />
                </Flex>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentWrapper>
    </Flex>
  );
}

export default LandmarkFeeSection;
