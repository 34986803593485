import { formatCurrency } from '@utils/format';
import moment from 'moment';

export const getEventTimelineColumns = () => {
  const columns: any[] = [
    {
      title: 'NAME',
      width: '25%',
      dataIndex: 'name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'START TIME',
      width: '25%',
      dataIndex: 'start_time',
      render: (data: any) => {
        return data ? moment(data, 'HH:mm:ss').format('h:mm A') : '-';
      }
    },
    {
      title: 'END TIME',
      width: '25%',
      dataIndex: 'end_time',
      render: (data: any) => {
        return data ? moment(data, 'HH:mm:ss').format('h:mm A') : '-';
      }
    },
    {
      title: 'DESCRIPTION',
      width: '25%',
      dataIndex: 'description',
      render: (data: any) => {
        return data ? data : '-';
      }
    }
  ];
  return columns;
};

export const getStaffColumns = () => {
  const columns: any[] = [
    {
      title: 'START TIME',
      width: '12.5%',
      dataIndex: 'start_time',
      render: (data: any) => {
        return data ? moment(data, 'HH:mm:ss').format('h:mm A') : '-';
      }
    },
    {
      title: 'END TIME',
      width: '12.5%',
      dataIndex: 'end_time',
      render: (data: any) => {
        return data ? moment(data, 'HH:mm:ss').format('h:mm A') : '-';
      }
    },
    {
      title: 'POSITION',
      width: '15%',
      dataIndex: 'position_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'QTY',
      width: '1%',
      dataIndex: 'quantity',
      render: (data: any, row: any) => {
        //display quantity as 1 when there is employee id and if not display the actual quantity
        return row?.emp_id && row?.employee_name ? 1 : data ? data : '-';
      }
    },
    {
      title: 'EMPLOYEE',
      width: '17.5%',
      dataIndex: 'employee_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'NOTES',
      width: '26.5%',
      dataIndex: 'notes',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'PHONE NUMBER',
      width: '15%',
      dataIndex: 'phone_number',
      render: (data: any, row: any) => {
        if (!data) {
          return '-';
        }

        const phoneNumber = row?.country_code
          ? `${
              row?.country_code?.includes('+') ? row?.country_code : `+${row?.country_code}`
            } ${data}`
          : data;

        return (
          <a href={`tel:${phoneNumber}`} style={{ color: 'black' }}>
            {phoneNumber} {row.extension ? 'x' + row.extension : ''}
          </a>
        );
      }
    }
  ];
  return columns;
};

export const getAllergiesColumns = () => {
  const columns: any[] = [
    {
      title: 'TITLE',
      width: '25%',
      dataIndex: 'seated',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'GUEST',
      width: '25%',
      dataIndex: 'guest_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'ALLERGY NOTES',
      width: '25%',
      dataIndex: 'allergy',
      render: (data: any) => {
        return data ? data : '-';
      }
    }
  ];
  return columns;
};

export const getVendorColumns = () => {
  // const columns: any[] = [
  //   {
  //     title: 'VENDOR',
  //     width: '50%',
  //     dataIndex: 'vendor_name',
  //     render: (data: any) => {
  //       return data ? data : '-';
  //     }
  //   },
  //   {
  //     title: 'QTY',
  //     width: '16%',
  //     dataIndex: 'quantity',
  //     render: (data: any) => {
  //       return data ? data : '-';
  //     }
  //   },
  //   {
  //     title: 'RATE',
  //     width: '16%',
  //     dataIndex: 'rate',
  //     render: (data: any) => {
  //       return data ? formatCurrency(data) : '-';
  //     }
  //   },
  //   {
  //     title: 'COST',
  //     width: '16%',
  //     dataIndex: 'total_cost',
  //     render: (data: any) => {
  //       return data ? formatCurrency(data) : '-';
  //     }
  //   }
  // ];
  const columns: any[] = [
    {
      title: 'NAME',
      width: '20%',
      dataIndex: 'vendor_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'START TIME',
      width: '10%',
      dataIndex: 'event_start_time',
      render: (data: any) => {
        return data ? moment(data, 'HH:mm:ss').format('h:mm A') : '-';
      }
    },
    {
      title: 'END TIME',
      width: '10%',
      dataIndex: 'event_end_time',
      render: (data: any) => {
        return data ? moment(data, 'HH:mm:ss').format('h:mm A') : '-';
      }
    },
    {
      title: 'QTY',
      width: '5%',
      dataIndex: 'quantity',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'NOTES',
      width: '40%',
      dataIndex: 'notes',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: 'PHONE NUMBER',
      width: '15%',
      dataIndex: 'formated_phone_number',
      render: (data: any) => {
        return data ? (
          <a href={`tel:${data}`} style={{ color: 'black' }}>
            {data}
          </a>
        ) : (
          '-'
        );
      }
    }
  ];
  return columns;
};
