import { Button } from '@atom/Buttons';
import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { H2Typography } from '@constant/typography/Typography';
import { CURRENCY } from '@constants/index';
import { Col, Divider, Row, Select } from 'antd';
import { FieldArray, FormikProvider } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { labourPositionItem, labourTotalCostCalculationHandler } from './helper';
import StaffingEstimateItem from './StaffingEstimateItem';
import { CustomFormValue } from './style';
import update from 'immutability-helper';
import { GetPositionsListForDropdown } from '@services/quoteServices';
import { showErrorToast } from '@utils/index';
import DragNDropWrapper from '@atom/DragNDropWrapper';
import { getEventDate, getSelectOptions } from '@utils/helper';
import { formatCurrency } from '@utils/format';

function StaffingEstimateSection(props: any) {
  const { formik, isEventsTab } = props;
  const { values, touched, errors, setFieldValue } = formik;
  const [grandTotalCost, setGrandTotalCost] = useState<string>('0');
  const [positionsList, setPositionsList] = useState<Array<{ id?: number; name?: string }>>([]);

  const fetchPositionsList = async () => {
    const res: any = await GetPositionsListForDropdown();
    if (res?.success) {
      setPositionsList(res?.data);
    } else {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch positions list.'
      });
    }
  };

  useEffect(() => {
    fetchPositionsList();
  }, []);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      let res = update(values?.event_dates?.labours, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, values?.event_dates?.labours[dragIndex]]
        ]
      });
      res = res?.map((i: any, index: number) => ({ ...i, sort_order: index + 1 }));
      setFieldValue('event_dates.labours', res);
    },
    [values?.event_dates?.labours]
  );

  const handleDnD = async (obj: any) => {};

  const calculateLabourSubtotal = (laboursArray: any) => {
    const total = labourTotalCostCalculationHandler(laboursArray);
    setGrandTotalCost(total?.toFixed(2).toString());
  };

  useEffect(() => {
    if (!values?.event_dates?.labours.length) {
      {
        formik.setFieldValue('event_dates.labours', [
          ...values?.event_dates?.labours,
          {
            id: new Date().getTime(),
            ...labourPositionItem,
            sort_order: values?.event_dates?.labours?.length + 1
          }
        ]);
      }
    }
    calculateLabourSubtotal(values?.event_dates?.labours);
  }, []);

  return (
    <>
      <H2Typography>Staffing Estimate</H2Typography>
      <Divider />
      <Row style={{ width: '100%' }}>
        <Col lg={4} md={12} xs={24}>
          <Label text={'Date'} />
          {/* <DatePickerComponent id={'date'} name={'date'} /> */}
          <SelectInput
            id="event_dates.event_date[0]"
            name="event_dates.event_date[0]"
            placeholdertitle="Select from the list"
            error={touched?.event_dates?.event_date?.[0] && errors?.event_dates?.event_date?.[0]}
            value={0}
          >
            {getSelectOptions(
              [
                {
                  id: 0,
                  name:
                    values?.event_dates?.date_string &&
                    values?.event_dates?.date_string !== '0000-00-00'
                      ? values?.event_dates?.date_string
                      : 'Unassigned Date'
                }
              ],
              'name'
            )}
          </SelectInput>
        </Col>
      </Row>
      <FormikProvider value={formik}>
        <FieldArray
          name="event_dates.labours"
          render={(arrayHelper) => {
            if (values?.event_dates?.labours?.length === 0) {
              return null;
            }
            return values?.event_dates?.labours?.map((item: any, index: number) => {
              return (
                <DragNDropWrapper
                  key={item.id}
                  index={index}
                  onDropCard={handleDnD}
                  moveCard={moveCard}
                >
                  <Row style={{ marginTop: 20, width: '100%' }}>
                    <StaffingEstimateItem
                      index={index}
                      formik={formik}
                      isEventsTab={isEventsTab}
                      moveCard={moveCard}
                      onDropCard={handleDnD}
                      positionsList={positionsList}
                      onRemove={(position: any) => {
                        let positionItems = values?.event_dates?.labours.filter(
                          (i: any) => i.id !== position.id
                        );
                        positionItems = positionItems?.map((i: any, subIndex: number) => ({
                          ...i,
                          sort_order: subIndex + 1
                        }));
                        setFieldValue('event_dates.labours', positionItems);
                        calculateLabourSubtotal(positionItems);
                      }}
                      calculateLabourSubtotal={calculateLabourSubtotal}
                    />
                  </Row>
                </DragNDropWrapper>
              );
            });
          }}
        />
      </FormikProvider>
      <Row gutter={24} style={{ width: '100%', marginTop: 20 }}>
        {isEventsTab ? <Col span={1} /> : null}
        <Col md={1} xs={0}></Col>
        <Col md={3} xs={11}>
          <Button
            variant="secondary"
            onClick={() => {
              formik.setFieldValue('event_dates.labours', [
                ...values?.event_dates?.labours,
                {
                  id: new Date().getTime(),
                  ...labourPositionItem,
                  sort_order: values?.event_dates?.labours?.length + 1
                }
              ]);
            }}
            text={'+ Add Position'}
            style={{ borderWidth: 2 }}
          />
        </Col>
        <Col md={14} xs={0} />
        <Col md={5} xs={11} style={{ marginLeft: '10px' }}>
          <Label text={'Subtotal'} />
          <CustomFormValue>{formatCurrency(Number(grandTotalCost)) || '0.00'}</CustomFormValue>
        </Col>
      </Row>
    </>
  );
}

export default StaffingEstimateSection;
