import { resetPassword } from '@services/userServices';
import * as Yup from 'yup';

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New password is required')
    .matches(/[0-9]/, 'The new password may only contain at least one numbers')
    .matches(/[a-z]+/, 'The new password may only contain at least one alphabet.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  repeatPassword: Yup.string()
    .required('Please re-enter password')
    .when('newPassword', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'The repeat new password and new password must match')
        .min(8, 'Password is too short - should be 8 chars minimum.')
    })
});

export const updateResetPassword = async (
  newPassword: string,
  passwordVerifyString: string,
  repeatPassword: string
) => {
  try {
    const res = await resetPassword(newPassword, passwordVerifyString, repeatPassword);
    return res;
  } catch (error) {
    throw error;
  }
};
