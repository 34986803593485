import React, { ChangeEvent, FC } from 'react';

import DatePickerComponent from '@atom/DatePicker';
import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import { SearchBar } from '@atom/Search';
import TimePickerComponent from '@atom/TimePicker';

interface TableHeaderProps {
  columnTitle: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (a: ChangeEvent) => void;
  type?: 'time' | 'date' | 'text';
  timeFormat?: string;
  dateformat?: string;
  use12Hours?: boolean;
}

const TableHeader: FC<TableHeaderProps> = ({
  columnTitle = '',
  name = '',
  value = '',
  placeholder = '',
  onChange,
  type = 'text',
  timeFormat = 'Time',
  dateformat = 'DD/MM',
  use12Hours = true
}) => {
  switch (type) {
    case 'time':
      return (
        <Flex direction="column" style={{ width: '240px' }} gap={15}>
          <p>{columnTitle}</p>
          <TimePickerComponent
            id={name}
            name={name}
            use12Hours={use12Hours}
            format={timeFormat}
            placeholder={'Time'}
            value={value}
            onChange={onChange}
          />
        </Flex>
      );
    case 'date':
      return (
        <Flex direction="column" style={{ width: '240px' }} gap={15}>
          <p>{columnTitle}</p>
          <DatePickerComponent
            id={name}
            name={name}
            format={dateformat}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </Flex>
      );
    case 'text':
      return (
        <Flex direction="column" style={{ width: '240px' }} gap={15}>
          <p>{columnTitle}</p>
          <SearchBar id={name} value={value} placeholder={placeholder} onChange={onChange} />
        </Flex>
      );
    default:
      return (
        <Flex direction="column" style={{ width: '240px' }} gap={15}>
          <p>{columnTitle}</p>
          <SearchBar id={name} value={value} placeholder={placeholder} onChange={onChange} />
        </Flex>
      );
  }
};

export default TableHeader;
