import DatePickerComponent from '@atom/DatePicker';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { showErrorToast } from '@utils/index';
import { Button } from '@atom/Buttons';
import { Col, Row } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React from 'react';
import { ButtonWrapper } from './style';
import DropdownButton from '@atom/DropdownButton';

interface DateFilterProps {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setCallApi: React.Dispatch<React.SetStateAction<boolean>>;
  generateReport?: () => void;
  isEventManagementPage?: boolean;
}

export const DateFilter = (props: DateFilterProps) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setCallApi,
    generateReport,
    isEventManagementPage = false
  } = props;

  const checkIfValidDate = () => {
    if (endDate < startDate) {
      showErrorToast({ message: 'Failed', description: 'End date cannot be in past.' });
    } else {
      setCallApi(true);
    }
  };

  const clearDate = () => {
    if (isEventManagementPage) {
      localStorage.removeItem('start_date');
      localStorage.removeItem('end_date');
    } else {
      localStorage.removeItem('quote_start_date');
      localStorage.removeItem('quote_end_date');
    }
    setStartDate('');
    setEndDate('');
    setCallApi(true);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (endDate) => {
    return endDate && endDate < moment(startDate);
  };
  return (
    <ButtonWrapper>
      <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'end', gap: 5 }}>
        <Col>
          <Label text="Select Start Date" style={{ margin: 0 }} />
          <DatePickerComponent
            id={'start_date'}
            name={'start_date'}
            value={startDate}
            onChange={(e) => {
              localStorage.setItem(isEventManagementPage ? 'start_date' : 'quote_start_date', e);
              setStartDate(e);
            }}
            allowClear={false}
          />
        </Col>
        <Col>
          <Label text="Select End Date" style={{ margin: 0 }} />
          <DatePickerComponent
            id={'end_date'}
            name={'end_date'}
            value={endDate}
            onChange={(e) => {
              localStorage.setItem(isEventManagementPage ? 'end_date' : 'quote_end_date', e);
              setEndDate(e);
            }}
            allowClear={false}
            disabledDate={disabledDate}
          />
        </Col>
        <Col>
          <Flex gap={10} style={{ flexWrap: 'wrap' }}>
            <Button
              variant="secondary"
              size={'small'}
              style={{ lineHeight: '22px', minWidth: '165px' }}
              text={'Clear'}
              onClick={() => clearDate()}
              isDisable={!(startDate || endDate)}
            />

            <DropdownButton
              text={isEventManagementPage ? 'Search Events' : 'Search Quotes'}
              disabled={!(startDate && endDate)}
              options={[
                { key: 'view_list', label: 'View List' },
                { key: 'generate_report', label: 'Generate Report' }
              ]}
              onSelect={(item) => {
                if (item.key === 'view_list') {
                  checkIfValidDate();
                } else if (item.key === 'generate_report') {
                  generateReport && generateReport();
                }
              }}
            />
          </Flex>
        </Col>
      </Row>
    </ButtonWrapper>
  );
};
