import PageLayout from '@layout/PageLayout';
import HorizontalTab from '@molecules/HorizontalTabs';
import { PageTitle } from '@molecules/PageTitle';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import EventsTab from './EventTab';
import { CUSTOMER_MANAGEMENT_TABS } from './helper';
import QuotesTab from './QuotesTab';
import { QuoteRecordWrapper } from './style';

const QuoteRecords = () => {
  const [currentTab, setCurrentTab] = useState(CUSTOMER_MANAGEMENT_TABS.QUOTES);

  const getQuoteRecordTabs = () => {
    const tabValues = [
      {
        tab: 'Quotes',
        content: <QuotesTab />,
        key: CUSTOMER_MANAGEMENT_TABS.QUOTES,
        getUrl: CUSTOMER_MANAGEMENT_TABS.QUOTES
      },
      {
        tab: 'Events',
        content: <EventsTab />,
        key: CUSTOMER_MANAGEMENT_TABS.EVENTS,
        getUrl: CUSTOMER_MANAGEMENT_TABS.EVENTS
      }
    ];
    return tabValues;
  };
  return (
    <PageLayout>
      <PageTitle text="QUOTE RECORDS" bordered />
      <Row gutter={24}>
        <Col span={24}>
          <QuoteRecordWrapper>
            <HorizontalTab
              value={getQuoteRecordTabs()}
              activeKey={currentTab}
              onChange={(tab: any) => {
                setCurrentTab(tab);
              }}
            />
          </QuoteRecordWrapper>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default QuoteRecords;
