import { useEffect, useState } from 'react';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label, LabelText } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { H3Typography, H4Typography, H5Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import ModalComponent from '@organism/Modal/Modal';
import { Col, Row, Select } from 'antd';
import { getIngedientcolumns } from './columns';
import {
  editSpecialIngredient,
  getMenuDetails,
  RestrictionListType,
  specialIngredientsValidationSchema,
  updateMenuNames
} from '../helper';
import CenterSpiner from '@atom/CenterSpiner';
import { Input } from '@atom/Input';
import { useFormik } from 'formik';
import { SpecialIngredientModalInitialValues, getSpecialIngredientMappedValues } from './helper';
import { DropdownListItemType, IngredientsListItemType } from './types';
import { useSelector } from 'react-redux';
import { showErrorToast, updateRawData } from '@utils/index';
import ExpandedIngredients from './ExpandedIngredients';
import { DownOutlined } from '@ant-design/icons';
import { DataTableWrapper } from './style';

interface ViewSpecialIngredientsProps {
  isModalVisible: boolean;
  modalCloseHandler: () => void;
  specialIngredientId: any;
  getSpecialIngredientsListHandler?: () => void;
  isModalEditable?: boolean;
}

const SpecialIngredientDetailsModal = (props: ViewSpecialIngredientsProps) => {
  const { isEditForSpecialIngredientDetailsModal: isEdit } = useSelector(
    (state: any) => state.rawData
  );
  const {
    isModalVisible,
    modalCloseHandler,
    specialIngredientId,
    getSpecialIngredientsListHandler,
    isModalEditable = true
  } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [measurementList, setMeasurementList] = useState<RestrictionListType[]>([]);
  const [ingredientsList, setIngredientsList] = useState<IngredientsListItemType[]>([]);
  const [filteredIngredientsList, setFilteredIngredientsList] = useState<IngredientsListItemType[]>(
    []
  );
  const [specialIngredientsList, setSpecialIngredientsList] = useState<IngredientsListItemType[]>(
    []
  );
  const [filteredSpecialIngredientsList, setFilteredSpecialIngredientsList] = useState<
    IngredientsListItemType[]
  >([]);
  const [prepList, setPrepList] = useState<RestrictionListType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  const formik = useFormik({
    onSubmit: (values: any) => submitHandler(values),
    initialValues: SpecialIngredientModalInitialValues,
    validationSchema: specialIngredientsValidationSchema,
    enableReinitialize: true
  });

  const {
    values,
    setFieldValue,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit
  } = formik;
  const getIngredientsHandler = async () => {
    if (!ingredientsList?.length) {
      setIsLoading(true);
      const result = await getMenuDetails('ingredients');
      if (!result.error) {
        setIngredientsList(result.data || []);
        setFilteredIngredientsList(result.data || []);
      }
      setIsLoading(false);
    }
  };

  const getSpecialIngredientsHandler = async () => {
    setIsLoading(true);
    const result = await getMenuDetails('special-ingredients');
    if (!result.error) {
      setSpecialIngredientsList(result.data || []);
      setFilteredSpecialIngredientsList(result.data || []);
    }
    setIsLoading(false);
  };

  const getMeasurementsHandler = async () => {
    if (!measurementList?.length) {
      setIsLoading(true);
      const result = await getMenuDetails('measurements');
      if (!result.error) {
        setMeasurementList(result.data || []);
      }
      setIsLoading(false);
    }
  };
  const getPrepListHandler = async () => {
    if (!prepList?.length) {
      setIsLoading(true);
      const result = await getMenuDetails('prep-menu');
      if (!result.error) {
        setPrepList(result.data || []);
      }
      setIsLoading(false);
    }
  };

  const fetchSpecialIngredientDetailsHandler = async (id: number) => {
    setIsLoading(true);
    setIsTableLoading(true);
    const { data } = await getMenuDetails(`special-ingredients/${id}`);
    setValues({ ...values, ...getSpecialIngredientMappedValues(data) });
    setIsLoading(false);
    setIsTableLoading(false);
  };

  useEffect(() => {
    if (specialIngredientId) {
      fetchSpecialIngredientDetailsHandler(specialIngredientId);
    } else {
      updateRawData({ isEditForSpecialIngredientDetailsModal: true });
    }
    getIngredientsHandler();
    getSpecialIngredientsHandler();
    getMeasurementsHandler();
    getPrepListHandler();
  }, []);

  const handleCancel = () => {
    modalCloseHandler();
    updateRawData({ isEditForSpecialIngredientDetailsModal: false });
  };

  const submitHandler = async (values: any) => {
    if (!Object.keys(errors).length) {
      setIsLoading(true);
      const postData = {
        ...values,
        ingredients: values?.ingredients?.map((ingredient: any) => ({
          ingredient_id:
            ingredient?.ingredient_id && ingredient?.ingredient_id !== -1
              ? ingredient?.ingredient_id
              : undefined,
          ingredient_name:
            !ingredient?.ingredient_id &&
            ingredient?.ingredient_id !== -1 &&
            ingredient.ingredient_name
              ? ingredient.ingredient_name
              : undefined,
          amount: ingredient?.amount,
          measurement_id: ingredient?.measurement_id,
          prep_list_id: ingredient?.prep_list_id,
          is_new: !ingredient.ingredient_id && !!ingredient?.ingredient_name ? 1 : 0
        }))
      };
      try {
        let res: any;
        if (!specialIngredientId) {
          res = await updateMenuNames(postData, 'special-ingredients');
        } else {
          res = await editSpecialIngredient(postData, 'special-ingredients');
        }
        if (!res?.error) {
          modalCloseHandler();
          getSpecialIngredientsListHandler && getSpecialIngredientsListHandler();
          updateRawData({ isEditForSpecialIngredientDetailsModal: false });
        } else {
          showErrorToast({ message: 'Something went wrong', description: res?.error });
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const addIngredientHandler = (
    setFieldValue: (field: string, value: any) => void,
    values: any,
    isSpecialIngredient: 0 | 1
  ) => {
    setFieldValue('ingredients', [
      ...values.ingredients,
      {
        ingredient_id: null,
        amount: '',
        measurement_id: null,
        prep_list_id: null,
        is_special_ingredient: isSpecialIngredient
      }
    ]);
  };

  const onSearchIngredientHandler = (e: string, index: number, isSpecial: boolean) => {
    const defaultList = isSpecial ? specialIngredientsList : ingredientsList;
    const listToSet =
      defaultList?.filter((item: any) => item?.name?.toLowerCase()?.includes(e?.toLowerCase())) ||
      [];
    if (isSpecial) {
      setFilteredSpecialIngredientsList(listToSet);
    } else {
      setFilteredIngredientsList(listToSet);
    }
    setFieldValue(`ingredients[${index}].ingredient`, null);
    setFieldValue(`ingredients[${index}].ingredient_id`, null);
    setFieldValue(`ingredients[${index}].ingredient_name`, e);
  };

  const handleClick = () => {
    setFilteredIngredientsList(ingredientsList);
    setFilteredSpecialIngredientsList(specialIngredientsList);
  };

  const ingredientColumns = getIngedientcolumns(
    isEdit,
    formik,
    filteredIngredientsList,
    filteredSpecialIngredientsList,
    measurementList,
    prepList,
    onSearchIngredientHandler,
    handleClick
  );

  return (
    <ModalComponent
      visible={isModalVisible}
      modalWidth="861px !important"
      align="left !important"
      padding="17px"
    >
      <Flex alignItems="center" direction="column" bottom={38}>
        <H3Typography>
          {!specialIngredientId
            ? 'Add Special Ingredient'
            : isEdit
            ? 'Edit Special Ingredient'
            : 'View Special Ingredient'}
        </H3Typography>
      </Flex>
      {isLoading ? (
        <CenterSpiner />
      ) : (
        <>
          <Row style={{ width: '100%' }} gutter={16} justify="center">
            <Col xs={24} md={8}>
              <Label
                isMandatory={isEdit}
                text="Special Ingredient Name"
                textColor={isEdit ? '#191919' : '#828282'}
              />
              {!isEdit ? (
                <LabelText>{values?.name}</LabelText>
              ) : (
                <Input
                  id="name"
                  name="name"
                  placeholder="Enter Special Ingredient Name"
                  value={values?.name}
                  onBlur={handleBlur}
                  error={touched?.name && errors?.name}
                  onChange={handleChange}
                />
              )}
            </Col>
            <Col xs={24} md={9}>
              <Label
                isMandatory={isEdit}
                text="Yields"
                textColor={isEdit ? '#191919' : '#828282'}
              />
              {!isEdit ? (
                <LabelText>{values?.yields}</LabelText>
              ) : (
                <Input
                  name="yields"
                  id="yields"
                  placeholder="0.00"
                  value={values?.yields}
                  onBlur={handleBlur}
                  error={touched?.yields && errors?.yields}
                  onChange={(e: any) => {
                    const valueToUpdate: string = e?.target?.value?.replace(/[^0-9\\.]+/g, '');
                    setFieldValue('yields', valueToUpdate);
                  }}
                />
              )}
            </Col>
            <Col xs={24} md={7}>
              <Label
                isMandatory={isEdit}
                text="Measurement"
                textColor={isEdit ? '#191919' : '#828282'}
              />
              {!isEdit ? (
                <LabelText>{values?.measurement_name}</LabelText>
              ) : (
                <SelectInput
                  id={'measurement'}
                  name={'measurement'}
                  value={values?.measurement}
                  onBlur={handleBlur}
                  error={touched?.measurement && errors?.measurement}
                  onChange={(e: number) => setFieldValue('measurement', e)}
                  listHeight={150}
                  placeholdertitle={'Select Measurement'}
                  style={{ marginBottom: '8px' }}
                >
                  {measurementList?.map((measurement: DropdownListItemType) => (
                    <Select.Option key={measurement?.id} value={measurement?.id}>
                      {measurement?.name}
                    </Select.Option>
                  ))}
                </SelectInput>
              )}
            </Col>
          </Row>

          {isEdit ? (
            <Row style={{ width: '100%' }} gutter={16} justify="center">
              <Col xs={24}>
                <Flex>
                  <H4Typography>{'Define Ingredients'}</H4Typography>
                </Flex>
              </Col>
              <Col xs={24}>
                <Flex>
                  <H5Typography style={{ color: '#949595', fontWeight: '500' }}>
                    {
                      'Please add all ingredients and their unit of measurements used in this product'
                    }
                  </H5Typography>
                </Flex>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <DataTableWrapper style={{ paddingTop: '25px' }}>
            <DataTable
              className="expandableTableRow"
              loading={isTableLoading}
              columns={ingredientColumns}
              dataSource={values?.ingredients}
              expandable={{
                expandedRowRender: (record: any) => (
                  <ExpandedIngredients specialIngredientId={record?.ingredient_id} />
                ),
                rowExpandable: (record: any) => {
                  return !!record?.is_special_ingredient && !!record?.ingredient_id;
                }, //@ts-ignore
                expandIcon: ({ expanded, onExpand, record }) => {
                  return !record?.is_special_ingredient || !record?.ingredient_id ? null : (
                    <DownOutlined
                      style={{
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        marginRight: '5px',
                        color: '#FF8A00',
                        transform: expanded ? 'rotate(180deg)' : 'rotate(360deg)'
                      }}
                      onClick={(e) => onExpand(record, e)}
                    />
                  );
                }
              }}
              renderEmpty={() => (
                <Flex justifyContent="center" alignItems="center">
                  {isEdit
                    ? 'Please Add Ingredients by clicking buttons below'
                    : 'No Ingredients Found.'}
                </Flex>
              )}
            />
          </DataTableWrapper>

          {isEdit && (
            <Row style={{ width: '100%' }} gutter={16} justify="start">
              <Flex gap={10}>
                <Button
                  style={{ paddingLeft: '5px' }}
                  text="+   Add Ingredient"
                  variant="ghost"
                  onClick={() => addIngredientHandler(setFieldValue, values, 0)}
                />
                <Button
                  text="+   Add Special Ingredient"
                  variant="ghost"
                  style={{ color: '#F2994A' }}
                  onClick={() => addIngredientHandler(setFieldValue, values, 1)}
                />
              </Flex>
            </Row>
          )}

          <Flex justifyContent="flex-end" top={30} style={{ gap: '10px', paddingBottom: '5px' }}>
            <Button
              variant="secondary"
              text={isModalEditable ? 'Cancel' : 'Close'}
              isDisable={isSubmitting}
              onClick={() => {
                handleCancel();
              }}
              className="cancel-btn"
            />
            {isModalEditable && (
              <Button
                variant="primary"
                text={isEdit ? 'Submit' : 'Edit Special Ingredient'}
                isLoading={isSubmitting}
                onClick={
                  isEdit
                    ? () => handleSubmit()
                    : () => updateRawData({ isEditForSpecialIngredientDetailsModal: true })
                }
              />
            )}
          </Flex>
        </>
      )}
    </ModalComponent>
  );
};

export default SpecialIngredientDetailsModal;
