import * as yup from 'yup';

import { ITimeType, IVendorDataType } from '../types';

import { FormikProps } from 'formik';
import { IVenueDataType } from '@pages/VenueManagementPage/types';
import { min } from 'moment';
import services from '@services/index';

const phoneRegExp = /^[0-9]{10}$/;

export const manualValidate = (formik: FormikProps<IVendorDataType>) => {
  formik.validateForm().then((errors) => {
    Object.entries(errors)?.map((error: any, key: any) => {
      if (Array.isArray(error[1])) {
        error[1]?.map((err: any, index: number) => {
          Object.keys(err)?.map((key: any) =>
            formik.setFieldTouched(`${error[0]}.${index}.${key}`, true)
          );
        });
      } else {
        formik.setFieldTouched(error[0], true);
      }
    });
  });
  if (!!formik.errors && Object.keys(formik.errors).length > 0) return true;
};

export const addEditValidations = yup.object().shape({
  vendor_name: yup.string().required('The vendor name field is required.'),
  contact_name: yup.string().required('The contact name field is required.'),
  email: yup
    .string()
    .email('Please enter valid email address')
    .required('The contact email field is required.'),
  address: yup.string().required('The billing address field is required.'),
  phone_number: yup
    .string()
    .required('The phone number field is required.')
    .matches(phoneRegExp, 'Phone number is not valid'),
  country_code: yup.string().nullable(),
  extension: yup.number().typeError('Extension can only be a number.').nullable(),
  description: yup.string().required('The vendor description field is required.'),
  start_time: yup.string().nullable(),
  end_time: yup.string().nullable(),
  vendor_categories: yup.mixed().required('The vendor type field is required.'),
  public_tags: yup.mixed().required('The public tags field is required.'),
  pickup_times: yup
    .array()
    .nullable()
    .of(
      yup.object().shape({
        day: yup.string().nullable(),
        start_time: yup.string().nullable(),
        end_time: yup
          .string()
          .nullable()
          .test('end_time', 'Start time and end time should be different.', function (value) {
            const { start_time } = this.parent;
            if (start_time && value) return value !== start_time;
            // if (start_time && value) {
            //   return new Date(`01/01/2020 ${value}`) > new Date(`01/01/2020 ${start_time}`);
            // }
            return true;
          })
      })
    ),
  drop_times: yup
    .array()
    .nullable()
    .of(
      yup.object().shape({
        day: yup.string().nullable(),
        start_time: yup.string().nullable(),
        end_time: yup
          .string()
          .nullable()
          .test('end_time', 'Start time and end time should be different.', function (value) {
            const { start_time } = this.parent;
            if (start_time && value) return value !== start_time;
            // if (start_time && value) {
            //   return new Date(`01/01/2020 ${value}`) > new Date(`01/01/2020 ${start_time}`);
            // }
            return true;
          })
      })
    ),
  additional_contacts: yup
    .array()
    .nullable()
    .of(
      yup.object().shape({
        email: yup.string().nullable().email('Please enter valid email address'),
        name: yup.string().nullable(),
        phone_number: yup.string().nullable().matches(phoneRegExp, 'Phone number is not valid'),
        country_code: yup.string().nullable(),
        country_iso_code: yup.string().nullable(),
        extension: yup.number().typeError('Extension can only be a number.').nullable()
      })
    )
});

export const getInitValues = (values: IVendorDataType) => {
  return {
    vendor_name: values?.vendor_name || '',
    status: values?.status ?? '',
    address: values?.address || '',
    email: values?.email || '',
    extension: values?.extension ?? '',
    formated_phone_number: values?.formated_phone_number ?? '',
    phone_number: values?.phone_number ?? '',
    country_iso_code: values?.country_iso_code ?? '',
    country_code: values?.country_code ?? '+1',
    additional_contacts: values?.additional_contacts || [
      {
        email: '',
        name: '',
        phone_number: '',
        country_code: '+1',
        country_iso_code: '',
        extension: ''
      }
    ],
    contact_name: values?.contact_name || '',
    categories: values?.categories || [],
    public_tags: values?.public_tags,
    registered_at: values?.registered_at ?? '',
    documents: values?.documents || [],
    vendor_categories: values?.vendor_categories,
    pickup_times: values?.pickup_times || deliveryTimesInitialValues(1),
    drop_times: values?.drop_times || deliveryTimesInitialValues(2),
    description: values?.description ?? '',
    latitude: values?.latitude ?? '',
    longitude: values?.longitude ?? ''
  };
};

const deliveryTimesInitialValues = (time_type: number) => {
  const times: ITimeType[] = [
    {
      day: 'Sunday',
      end_time: '',
      start_time: '',
      time_type,
      vendor_id: ''
    },
    {
      day: 'Monday',
      end_time: '',
      start_time: '',
      time_type,
      vendor_id: ''
    },
    {
      day: 'Tuesday',
      end_time: '',
      start_time: '',
      time_type,
      vendor_id: ''
    },
    {
      day: 'Wednesday',
      end_time: '',
      start_time: '',
      time_type,
      vendor_id: ''
    },
    {
      day: 'Thursday',
      end_time: '',
      start_time: '',
      time_type,
      vendor_id: ''
    },
    {
      day: 'Friday',
      end_time: '',
      start_time: '',
      time_type,
      vendor_id: ''
    },
    {
      day: 'Saturday',
      end_time: '',
      start_time: '',
      time_type,
      vendor_id: ''
    }
  ];
  return times;
};

export const newAdditionalContact = {
  email: '',
  name: '',
  phone_number: '',
  country_code: '+1',
  extension: '',
  country_iso_code: ''
};

export enum VENDOR_CREATE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  DRAFT = 2
}

export const addEditVendor = async (values: IVendorDataType, id?: any) => {
  try {
    const method =
      id && values?.status !== VENDOR_CREATE_STATUS.DRAFT
        ? services.put(`/vendors/update`, values)
        : services.post(`/vendors/create`, values);
    const res = (await method) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.message : 'Something went wrong!' };
  }
};

export const removeAdditionalContact = async (contactId: string | number) => {
  try {
    const res = (await services.delete(`/vendors/additional-contact/${contactId}`)) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
