import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EditIcon from '@assets/edit-2.svg';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { H2Typography } from '@constant/typography/Typography';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import ConfirmModal from '@organism/ConfimationModal';
import DataTable from '@organism/DataTable/DataTable';
import { showErrorToast, showToast } from '@utils/index';
import { getBundle, MENU_MANAGEMENT_TABS, PRODUCT_STATUS } from '../helper';
import { bundleDetailsColumns, getMappedData, updateBundleStatus } from './helper';
import { BundleDetailsTableWrapper, RightAlignData } from '../style';
import { useSelector } from 'react-redux';
import { formatCurrency } from '@utils/format';

function BundleDetails() {
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;
  const navigate = useNavigate();
  const { tab, id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isProductModalVisible, setIsProductModalVisible] = useState<boolean>(false);
  const [bundleData, setBundleData] = useState<any>({});
  const [tableData, setTableData] = useState<Array<any>>([]);

  useEffect(() => {
    getBundleData();
  }, []);

  const getBundleData = async () => {
    setIsLoading(true);
    try {
      const res = await getBundle(id);
      if (res?.success) {
        setBundleData(res?.data);
        const mappedData = getMappedData(res?.data?.bundle_products);
        setTableData(mappedData);
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch product data'
      });
    }
    setIsLoading(false);
  };

  const handleActivateDeactivate = async () => {
    const reqObj: any = {
      bundle_id: id
    };
    if (bundleData?.status === PRODUCT_STATUS.ACTIVE) {
      reqObj.status = PRODUCT_STATUS.INACTIVE;
    } else if (bundleData?.status === PRODUCT_STATUS.INACTIVE) {
      reqObj.status = PRODUCT_STATUS.ACTIVE;
    }
    try {
      const res = await updateBundleStatus(reqObj);
      if (res?.success) {
        setIsModalVisible(false);
        navigate(`/menu/tab/${tab}`);
        showToast({
          message: 'Success!',
          description: 'Bundle status updated'
        });
        getBundleData();
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to update bundle status'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to update bundle status'
      });
    }
  };

  return (
    <PageLayout>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {isAdmin && (
            <PageTitle text="PRODUCT LIST" isBack={true} bordered={true} path={`/menu/tab/${tab}`}>
              <Button
                variant="ghost"
                text={bundleData?.status === PRODUCT_STATUS.INACTIVE ? 'REACTIVATE' : 'DEACTIVATE'}
                style={bundleData?.status === PRODUCT_STATUS.ACTIVE ? { color: 'red' } : {}}
                icon={<ExclamationCircleOutlined />}
                onClick={() => {
                  if (bundleData?.isDeactivated) {
                    setIsProductModalVisible(true);
                  } else {
                    setIsModalVisible(true);
                  }
                }}
              />
              <Button
                variant="ghost"
                text="EDIT"
                icon={<img src={EditIcon} alt="Edit Button" />}
                onClick={() => navigate(`/menu/${tab}/edit-bundle/${id}`)}
              />
            </PageTitle>
          )}
          <H2Typography>{bundleData?.bundle_name || '-'}</H2Typography>
          <BundleDetailsTableWrapper>
            <DataTable
              loading={isLoading}
              rowClassName={(record) => (!record?.status ? 'table-row-dark' : '')}
              columns={bundleDetailsColumns}
              showLoadMore={false}
              nextPageURL={''}
              dataSource={tableData}
              pagination={false}
              onFetch={() => {}}
              scrollConfig={{
                y: tableData?.length ? 'calc(100vh - 350px)' : 'auto'
              }}
              renderEmpty={() => {
                return <>No Data Found</>;
              }}
            />
          </BundleDetailsTableWrapper>
          <Flex justifyContent="flex-end">
            <Flex direction="column">
              <Label isMandatory={false} text="SUBTOTAL" />
              <RightAlignData>{formatCurrency(bundleData?.total_cost)}</RightAlignData>
            </Flex>
          </Flex>
          <ConfirmModal
            title={`Are you sure you want to ${
              bundleData?.status === PRODUCT_STATUS.ACTIVE ? 'Deactivate' : 'Reactivate'
            } this Item?`}
            okText={bundleData?.status === PRODUCT_STATUS.ACTIVE ? 'Deactivate' : 'Reactivate'}
            cancelText="Cancel"
            visible={isModalVisible}
            onOK={() => handleActivateDeactivate()}
            onCancel={() => setIsModalVisible(false)}>
            {bundleData?.status === PRODUCT_STATUS.ACTIVE
              ? 'This item will not be forever deleted the account It will just be deactivated, but will remain the database'
              : `This item will be reactivated, it will go back into the "Active" product list.`}
          </ConfirmModal>
          <ConfirmModal
            title={`You can not reactivate this bundle at this time.`}
            okText={`Got It`}
            visible={isProductModalVisible}
            showCancel={true}
            onOK={() => setIsProductModalVisible(false)}>
            {`This bundle contains an inactive product, please reactivate the product to reactivate the bundle.`}
          </ConfirmModal>
        </>
      )}
    </PageLayout>
  );
}

export default BundleDetails;
