import React, { FC } from 'react';
import ModalComponent from '@organism/Modal/Modal';
import { Flex } from '@atom/Flex';
import { ModalWrapper, Wrapper } from './style';
import { Button } from '@atom/Buttons';
import { CSSProperties } from 'styled-components';
import { Spin } from 'antd';

interface ThemeType {
  title?: CSSProperties;
  desc?: CSSProperties;
  divider?: CSSProperties;
  btn?: CSSProperties;
}
interface Values {
  visible?: boolean;
  onOK?: () => void;
  onCancel?: () => void;
  okFirst?: boolean;
  okText?: string;
  cancelText?: string;
  title: string;
  children?: string | React.ReactNode;
  icon?: React.ReactNode;
  theme?: ThemeType;
  flexValueForContent?: number | string;
  modalSaveButtonLoader?: boolean;
  isModalLoading?: boolean;
  closable?: boolean;
  showCancel?: boolean;
  modalOverFlowX?: string;
}
const MessageModal: FC<Values> = ({
  icon,
  visible,
  onOK,
  onCancel,
  okFirst,
  title,
  children,
  okText,
  cancelText,
  theme,
  flexValueForContent,
  modalSaveButtonLoader = false,
  isModalLoading,
  closable,
  showCancel,
  modalOverFlowX
}) => {
  return (
    <Wrapper>
      <ModalComponent
        visible={visible}
        onOK={onOK}
        onCancel={onCancel}
        closable={closable}
        modalOverFlowX={modalOverFlowX}
      >
        {!!isModalLoading ? (
          <Spin />
        ) : (
          <ModalWrapper direction="column">
            <Flex direction="row" alignItems={'center'}>
              <Flex
                style={theme?.title}
                flex={1}
                className="title"
                color="red"
                justifyContent={'center'}
              >
                {title}
              </Flex>
            </Flex>
            <Flex direction="row" alignItems={'center'}>
              {/* <Flex flex={flexValueForContent || 0.2} /> */}
              {flexValueForContent}
              <Flex
                style={theme?.desc}
                top={20}
                flex={1}
                className="description"
                direction="column"
                justifyContent={'center'}
              >
                {children}
                {!showCancel ? (
                  <Flex
                    top={40}
                    gap={8}
                    style={theme?.btn}
                    justifyContent={'center'}
                    className="fix-mobile"
                  >
                    <Button
                      variant="primary"
                      text={okText || 'Proceed anyway'}
                      onClick={onOK}
                      isLoading={modalSaveButtonLoader}
                    />
                  </Flex>
                ) : (
                  <Flex
                    top={40}
                    gap={8}
                    style={theme?.btn}
                    justifyContent={'center'}
                    className="fix-mobile"
                  >
                    <Button
                      variant="primary"
                      text={okText || 'Proceed anyway'}
                      onClick={onOK}
                      isLoading={modalSaveButtonLoader}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </ModalWrapper>
        )}
      </ModalComponent>
    </Wrapper>
  );
};
export default MessageModal;
