import { ChangeEvent } from 'react';
import { IWorkOrderListFilterType } from '../type';
import TableHeader from './TableHeader';
import moment from 'moment';

export const getColumns = (
  filterValue: IWorkOrderListFilterType,
  onChange: (a: ChangeEvent, key?: string) => void
) => {
  const columns = [
    {
      title: (
        <TableHeader
          columnTitle="Date"
          name="date"
          value={filterValue.date ?? ''}
          placeholder="DD/MM"
          dateformat={'ddd, MMM DD, YYYY'}
          type="date"
          onChange={(e) => onChange(e, 'date')}
        />
      ),
      dataIndex: 'date',
      render: (data: any) => {
        return data ? moment(data).format('ddd, MMMM DD') : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Scheduled"
          name="event_time"
          value={filterValue.event_time ?? ''}
          placeholder="Time"
          timeFormat={'h:mm A'}
          onChange={(e) => onChange(e, 'event_time')}
          type="time"
        />
      ),
      dataIndex: 'start_time',
      render: (data: any, row: any) => {
        const startTime = !!row?.start_time ? moment(row?.start_time, 'hh').format('h:mm a') : null;
        const endTime = !!row?.end_time ? moment(row?.end_time, 'hh').format('h:mm a') : null;
        return !!startTime && !!endTime ? `${startTime} -${endTime}` : '--';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Venue Name"
          name="venue_name"
          value={filterValue.venue_name ?? ''}
          placeholder="Venue Name"
          onChange={(e) => {
            !(!filterValue.venue_name && !(e?.target as HTMLInputElement)?.value) && onChange(e);
          }}
        />
      ),
      dataIndex: 'venue_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Event Name"
          name="event_name"
          value={filterValue.event_name ?? ''}
          placeholder="Event Name"
          onChange={(e) => {
            !(!filterValue.event_name && !(e?.target as HTMLInputElement)?.value) && onChange(e);
          }}
        />
      ),
      dataIndex: 'event_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Event Supervisor"
          name="event_supervisor"
          value={filterValue.event_supervisor ?? ''}
          placeholder="Supervisor"
          onChange={(e) => {
            !(!filterValue.event_supervisor && !(e?.target as HTMLInputElement)?.value) &&
              onChange(e);
          }}
        />
      ),
      dataIndex: 'supervisor',
      render: (data: any) => {
        return data ? data : '-';
      }
    }
  ];
  return columns;
};

export const getRows = () => {
  const rows: Array<any> = [];
  // const rows: IWorkOrderDataType[] = [
  //   {
  //     venue_name: 'Bery',
  //     event_staffing_id: 1,
  //     supervisor: 'Nikhil',
  //     employee_id: 123,
  //     start_time: '10:00 AM',
  //     end_time: '12:00 PM',
  //     event_name: 'Audio Visual, Dcor and Flowers',
  //     event_id: 1699,
  //     date: 'October 13, 2022'
  //   },
  //   {
  //     venue_name: 'Bery',
  //     event_staffing_id: 1,
  //     supervisor: 'Nikhil',
  //     employee_id: 123,
  //     start_time: '10:00 AM',
  //     end_time: '12:00 PM',
  //     event_name: 'Audio Visual, Dcor and Flowers',
  //     event_id: 1699,
  //     date: 'October 13, 2022'
  //   },
  //   {
  //     venue_name: 'Bery',
  //     event_staffing_id: 1,
  //     supervisor: 'Nikhil',
  //     employee_id: 123,
  //     start_time: '10:00 AM',
  //     end_time: '12:00 PM',
  //     event_name: 'Audio Visual, Dcor and Flowers',
  //     event_id: 1699,
  //     date: 'October 13, 2022'
  //   },
  //   {
  //     venue_name: 'Bery',
  //     event_staffing_id: 1,
  //     supervisor: 'Nikhil',
  //     employee_id: 123,
  //     start_time: '10:00 AM',
  //     end_time: '12:00 PM',
  //     event_name: 'Audio Visual, Dcor and Flowers',
  //     event_id: 1699,
  //     date: 'October 13, 2022'
  //   },
  //   {
  //     venue_name: 'Bery',
  //     event_staffing_id: 1,
  //     supervisor: 'Nikhil',
  //     employee_id: 123,
  //     start_time: '10:00 AM',
  //     end_time: '12:00 PM',
  //     event_name: 'Audio Visual, Dcor and Flowers',
  //     event_id: 1699,
  //     date: 'October 13, 2022'
  //   },
  //   {
  //     venue_name: 'Bery',
  //     event_staffing_id: 1,
  //     supervisor: 'Nikhil',
  //     employee_id: 123,
  //     start_time: '10:00 AM',
  //     end_time: '12:00 PM',
  //     event_name: 'Audio Visual, Dcor and Flowers',
  //     event_id: 1699,
  //     date: 'October 13, 2022'
  //   },
  //   {
  //     venue_name: 'Bery',
  //     event_staffing_id: 1,
  //     supervisor: 'Nikhil',
  //     employee_id: 123,
  //     start_time: '10:00 AM',
  //     end_time: '12:00 PM',
  //     event_name: 'Audio Visual, Dcor and Flowers',
  //     event_id: 1699,
  //     date: 'October 13, 2022'
  //   }
  // ];
  return rows;
};
