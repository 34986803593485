import * as Yup from 'yup';
import { ResponseType } from '../types';
import services from '@services/index';
import { ForgotPasswordType } from './types';

export const checkPasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    // .min(8, 'The current password field must contain alphabet, numbers & 8 characters long.')
    // .matches(
    //   /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
    //   'The current password field must contain alphabet, numbers & 8 characters long.'
    // )
    .required('The current password field is required.')
});

export const updatePasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required('New password is required')
    .matches(/[0-9]/, 'The new password must contain at least one numbers')
    .matches(/[a-z]+/, 'The new password must contain at least one alphabet.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirm_password: Yup.string()
    .required('Please confirm password')
    .when('new_password', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref('new_password')], 'The repeat new password and new password must match')
        .min(8, 'Password is too short - should be 8 chars minimum.')
    })
});

export const updatePassword = async (data: any): Promise<ResponseType> => {
  try {
    const method = services.post;
    const res = (await method(`/profile/update-password`, data, undefined, null)) as any;
    return { data: res?.status, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const checkPassword = async (data: any): Promise<ResponseType> => {
  try {
    const method = services.post;
    const res = (await method(`/profile/check-password`, data, undefined, null)) as any;
    return { data: res?.status, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const sendResetPasswordLink = async (data: ForgotPasswordType): Promise<ResponseType> => {
  try {
    const method = services.post;
    const res = (await method(`/forgot-password/send`, data, undefined, null)) as any;
    return { data: res?.status, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};
