import React from 'react';

import { Flex } from '@atom/Flex';
import { H1Typography, H3Typography } from '@constant/typography/Typography';
import PageLayout from '@layout/PageLayout';
import { Button } from 'antd';
import { useNavigate } from 'react-router';

function FallbackErrorPage({ error }: any) {
  const navigate = useNavigate();

  const triggerBackToHome = () => {
    if (navigate) {
      navigate('/');
      window.location.reload();
    }
  };
  return (
    <PageLayout>
      <Flex direction="column" justifyContent={'center'} style={{ height: 400 }}>
        <Flex justifyContent={'center'}>
          <Flex direction="column" alignItems={'center'}>
            <H3Typography>{error}</H3Typography>
            <Button onClick={triggerBackToHome}>Back To Home</Button>
          </Flex>
        </Flex>
      </Flex>
    </PageLayout>
  );
}

export default FallbackErrorPage;
