import { IStaffManagementDataTableProps, IStaffManagementListFilterType } from '../types';
import { STAFF_MANAGEMENT_TABS, getStaffList } from '../helper';
import { getPosition } from './helper';
import { getColumns } from './helper';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import DataTable from '@organism/DataTable/DataTable';
import AddNewStaffModal from '../../StaffManagementPage/StaffManagementDataTable/AddNewStaff';
import { useSelector } from 'react-redux';
import { TableWrapper } from './style';
import { useNavigate } from 'react-router-dom';

const StaffManagementDataTable: React.FC<IStaffManagementDataTableProps> = ({ activePanel }) => {
  const navigate = useNavigate();

  const { recallAPI = 0, showStaffingLoader } = useSelector((state: any) => state.rawData);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [filterData, setFilterData] = useState<IStaffManagementListFilterType>({
    code: '',
    date: '',
    venue: '',
    employee: '',
    tab: STAFF_MANAGEMENT_TABS.UPCOMING,
    page: 1
  });

  const callTableData = (filters?: IStaffManagementListFilterType) => {
    if (activePanel === STAFF_MANAGEMENT_TABS.UPCOMING) {
      const newFilters = { ...filters, tab: STAFF_MANAGEMENT_TABS.UPCOMING };
      setFilterData(newFilters);
      updateTableData(newFilters);
    } else if (activePanel === STAFF_MANAGEMENT_TABS.PAST) {
      const newFilters = { ...filters, tab: STAFF_MANAGEMENT_TABS.PAST };
      setFilterData(newFilters);
      updateTableData(newFilters);
    }
  };

  useEffect(() => {
    if (recallAPI) {
      callTableData(filterData);
    }
  }, [recallAPI]);

  useEffect(() => {
    if (showStaffingLoader) {
      setIsTableLoading(showStaffingLoader);
    }
  }, [showStaffingLoader]);

  useEffect(() => {
    callTableData(filterData);
  }, [activePanel]);

  useEffect(() => {
    return () => {
      setTableData([]);
    };
  }, []);

  const updateTableData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setIsTableLoading(true);
      const res = await getStaffList(newFilters.page, filters);
      if (!res.error) {
        setTableData(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
      setIsTableLoading(false);
    }, 500),
    []
  );
  const onFilterChangeHandler = (e: any, key?: string) => {
    let updatedFilters;
    if (key === 'date') {
      updatedFilters = { ...filterData, date: e };
    } else if (key === 'code') {
      updatedFilters = { ...filterData, code: e };
    } else if (key === 'venue') {
      updatedFilters = { ...filterData, venue: e };
    } else if (key === 'employee') {
      updatedFilters = { ...filterData, employee: e };
    } else {
      const { id, value } = e.target;
      updatedFilters = { ...filterData, [id]: value };
    }
    setFilterData({ ...updatedFilters, page: 1 });
    callTableData({ ...updatedFilters, page: 1 });
  };

  const [openPosition, setOpenPosition] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [colData, setColData] = useState({});

  useEffect(() => {
    getInitData();
    return () => {
      setTableData([]);
    };
  }, []);

  const getInitData = async () => {
    const res: any = await getPosition();
    if (!res.error) {
      setOpenPosition(res);
    }
  };
  const onClick = (data: any) => {
    setIsModalVisible(true);
    setColData(data);
  };
  const cols = getColumns(filterData, onFilterChangeHandler, onClick, navigate);
  return (
    <>
      <TableWrapper>
        <DataTable
          loading={isTableLoading}
          columns={cols}
          showLoadMore={!!nextPageUrl}
          nextPageURL={nextPageUrl}
          dataSource={tableData}
          pagination={{
            pageSize: pageNo,
            total: totalCount,
            current: filterData?.page,
            onChange: (page: any) => {
              const newFilters = { ...filterData, page: page };
              setFilterData(newFilters);
              callTableData(newFilters);
            }
          }}
          onFetch={() => {}}
          renderEmpty={() => <div>No Records Found.</div>}
        />
      </TableWrapper>
      {isModalVisible && (
        <AddNewStaffModal
          isModalVisible={isModalVisible}
          tableData={tableData}
          openPosition={openPosition}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => {
            setIsModalVisible(false);
            updateTableData(filterData);
          }}
          colData={colData}
        />
      )}
    </>
  );
};
export default StaffManagementDataTable;
