import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { PageTitle } from '@molecules/PageTitle';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DocumentsTabType,
  getDocumentList
} from '../EventWithExistingQuote/EventWithExistingQuoteTabPanels/DocuementsTab/helper';
import DownloadIcon from '@assets/download.svg';
import { Col, Row } from 'antd';
import FilePreview from '@molecules/FilePreview';
import { useParams } from 'react-router';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import { DownloadContentWrapper } from '../style';

interface EventDocumentProps {}

const EventDocument: React.FC<EventDocumentProps> = ({}) => {
  const { quoteId, eventId } = useParams();
  const [documentsList, setDocumentsList] = useState<DocumentsTabType[]>([]);
  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  useEffect(() => {
    getEventListHandler();
  }, [eventId]);

  const getEventListHandler = useCallback(async () => {
    if (!eventId) return;
    setIsDocumentLoading(true);
    const result = await getDocumentList(eventId);
    if (!result.error) {
      setDocumentsList(result?.data || []);
    }
    setIsDocumentLoading(false);
  }, [eventId]);

  if (isDocumentLoading) return <CenterSpiner />;

  return (
    <Flex direction="column" style={{ width: '100%' }}>
      <DownloadContentWrapper>
        <PageTitle className="margin-top-60" bordered={true} text="Documents">
          {documentsList?.length ? (
            <a
              href={`${process.env.REACT_APP_BASE_API_GENERATE_REPORT}/events/download-documents/${eventId}`}
              target="_blank">
              <Button
                className="download-btn"
                variant="secondary"
                text="Download all documents"
                icon={<img src={DownloadIcon} alt="download all" />}
              />
            </a>
          ) : null}
        </PageTitle>
      </DownloadContentWrapper>
      <Row gutter={[16, 16]}>
        {!!documentsList?.length ? (
          documentsList?.map((item: any, index: number) => {
            if (!isAdmin && item?.is_private) return null;
            return (
              <Col md={12} xs={24}>
                <FilePreview
                  key={index}
                  document={{
                    ...item,
                    name: item?.document_name ?? '',
                    url: item?.document_url ?? ''
                  }}
                />
              </Col>
            );
          })
        ) : (
          <Col md={12} xs={24}>
            <p>No Documents uploaded yet.</p>
          </Col>
        )}
      </Row>
    </Flex>
  );
};

export default EventDocument;
