import { Col, Row, Spin } from 'antd';
import { FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { SelectInputWithCheckBox } from '@atom/Select';

import { PriceImagesAndBundleDetailsSecProps } from '@pages/MenuManagementPage/types';
import { showErrorToast } from '@utils/index';

import AddNewBundleModal from './AddNewBundleModal';
import BundleDetailsTable from './BundleDetailsTable';
import { getBundlesOptions, getBundlesSecDropdownData, handleSelectBundle } from './helper';
import { BundleWrapper, SubLabel } from './style';

function PriceImagesAndBundleDetailsSec(props: PriceImagesAndBundleDetailsSecProps) {
  const { formik, isQuote } = props;
  const {
    values,
    setTouched,
    setFieldValue,
    handleChange,
    setFormikState,
    touched,
    errors,
    setErrors
  } = formik;
  const { id } = useParams();
  const bundlesListButton = {
    id: -1,
    bundle_name: (
      <Button
        text={'+ Create New Bundle'}
        variant="ghost"
        onClick={() => setIsModalVisible(true)}
      />
    )
  };
  const [dropdownData, setDropdownData] = useState<any>({ bundlesList: [bundlesListButton] });
  const [isBundlesLoading, setIsBundlesLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    updateDropdownData();
  }, []);

  const handleUpdateError = (key: string, message: string, index: number) => {
    const tempError = errors ? { ...errors } : {};
    tempError.products = tempError?.products || Array.from({ length: index + 1 }, () => ({}));
    tempError.products =
      index < tempError?.products?.length
        ? tempError?.products
        : [...tempError?.products, ...Array.from({ length: index - (length - 1) }, () => ({}))];

    tempError.products[index][key] = message;
    setErrors({ ...tempError });
  };

  const updateDropdownData = async () => {
    setIsBundlesLoading(true);
    try {
      const ddRes = await getBundlesSecDropdownData();
      setDropdownData({
        bundlesList: ddRes?.[0]?.success
          ? [bundlesListButton, ...ddRes?.[0]?.data]
          : [bundlesListButton],
        products: ddRes?.[1]?.success ? ddRes?.[1]?.data : []
      });
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch dropdown data'
      });
    }
    setIsBundlesLoading(false);
  };

  if (isBundlesLoading) {
    return (
      <Flex justifyContent="center" style={{ minHeight: '200px' }}>
        <Spin />
      </Flex>
    );
  }

  return (
    <>
      {isQuote ? null : (
        <Row gutter={16}>
          <BundleWrapper style={{ width: '100%' }}>
            <Col xs={24} lg={12} md={12}>
              <Row>
                <Label isMandatory={false} text="Bundles" style={{ paddingRight: '12px' }} />
                <SubLabel color="#A19F9F">
                  (Create a New bundle or Add this product to Existing Bundles)
                </SubLabel>
              </Row>
              <SelectInputWithCheckBox
                id="bundles"
                name="bundles"
                placeholdertitle="Add Bundle"
                mode="multiple"
                options={getBundlesOptions(dropdownData?.bundlesList)}
                value={values?.bundles?.map((i: any) => i?.bundle_id)}
                error={''}
                onChange={(e: any) => handleSelectBundle(e, dropdownData, values, setFieldValue)}
              />
            </Col>
          </BundleWrapper>
          <Col xs={24} lg={24}>
            {values?.bundles?.length ? (
              <>
                <FieldArray
                  name={'bundles'}
                  render={(arrayHelper: any) => {
                    return values?.bundles?.map((item: any, index: any) => {
                      return (
                        <BundleDetailsTable
                          key={index}
                          formik={formik}
                          item={item}
                          index={index}
                          dropdownData={dropdownData}
                        />
                      );
                    });
                  }}
                />
              </>
            ) : null}
          </Col>
        </Row>
      )}
      <AddNewBundleModal
        dropdownData={dropdownData}
        isModalVisible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
          updateDropdownData();
        }}
        onCancel={() => setIsModalVisible(false)}
      />
    </>
  );
}

export default PriceImagesAndBundleDetailsSec;
