import styled from 'styled-components';

export const LabelWrapper = styled.div`
  .time-label-style Label {
    line-height: 21px;
    align-items: center;
    text-align: center;
    color: #000;
    mix-blend-mode: normal;
    background: #f4f4f4;
    border-radius: 3px;
    padding: 11px 0;
    display: block;
    width: 100%;
  }
  .ant-picker-input .ant-picker-suffix {
    display: none;
  }
`;
export const DataTableWrapper = styled.div`
  .ant-table-container {
    border-top: 1px solid #000 !important;
  }
`;
