import Loader from '@atom/Loader';
import { DOCUSIGN_MESSAGE_ACTIONS } from '@constants/docusignConstants';
import { message } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

function DocusignHandler() {
  const location = useLocation();
  const handleDocusingEvents = () => {
    if (location && location.search && location.search.length) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has('envelope_id') && searchParams.has('event')) {
        const envelopId = searchParams.get('envelope_id');
        const eventId = searchParams.get('event_id');
        const event = searchParams.get('event');
        // if (event !== 'signing_complete' && event !== 'viewing_complete') {
        if (event !== 'signing_complete') {
          message.error('Failed to sign');
          postMessageToParentWindow({
            action: DOCUSIGN_MESSAGE_ACTIONS.DOCUSIGN_FAILED_SIGN,
            payload: 'Unable to sign document'
          });
        } else {
          postMessageToParentWindow({
            action: DOCUSIGN_MESSAGE_ACTIONS.DOCUSIGN_SIGN_COMPLETE,
            payload: {
              envelopId,
              eventId,
              event: 'signing_complete'
            }
          });
        }
      }
      window.close();
    }
  };

  const postMessageToParentWindow = (payload: any) => {
    if (window.opener) {
      window.opener.postMessage(payload);
    }
  };

  useEffect(() => {
    if (window.opener) {
      handleDocusingEvents();
    } else {
      message.error('Not opened via docusing! Please close this window!');
    }
  }, []);

  return (
    <>
      <Loader loading={true} />
    </>
  );
}

export default DocusignHandler;
