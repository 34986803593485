import ModalComponent from '@organism/Modal/Modal';
import { Col, Row, Select, Spin, Switch } from 'antd';
import { H2Typography, H4Typography } from '@constant/typography/Typography';
import { Label } from '@atom/FormLable';
import { Button } from '@atom/Buttons';
import { Input } from '@atom/Input';
import { contractModalValidationSchema, generateContract, sendContractCallback } from './helper';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { showErrorToast, showToast } from '@utils/index';
import { DOCUSIGN_MESSAGE_ACTIONS } from '@constants/docusignConstants';
import { Flex } from '@atom/Flex';
import { CURRENCY } from '@constants/index';
import { Value } from '@atom/FormDataView';
import moment from 'moment';
import SelectInput from '@atom/Select';

const { Option } = Select;
const ContractButtonModal = (props: ContractButtonModalPropTypes) => {
  const {
    isContractButtonsModalVisible,
    isViewContractDataLoading,
    onCancel,
    setIsContractButtonsModalVisible,
    data,
    contractData,
    contractAction,
    locationDetail
  } = props;

  const [isFormEnabled, setIsFormEnabled] = useState(true);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contract_type_id: contractData?.contract_type_id || 3,
      name: contractData?.client_name || '',
      email: contractData?.client_email || '',
      fdd_rep: data?.contract_status
        ? contractData?.fdd_rep || data?.account_manager_name
        : data?.account_manager_name || '',
      deposit_amount: contractData?.deposit_amount || '',
      deposit_percent: data?.contract_status
        ? contractData?.deposit_percent
        : data?.deposit_percent || '',
      is_deposit_percent: data?.contract_status
        ? contractData?.is_deposit_percent
        : data?.is_deposit_percent
        ? 1
        : 0 || 0,
      grand_total: data?.grand_total || ''
    },
    validationSchema: contractModalValidationSchema,
    onSubmit: (values) => {
      handleSave(values);
    }
  });
  const {
    touched,
    errors,
    values,
    setValues,
    handleBlur,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue
  } = formik;

  const handleCancel = () => {
    setIsContractButtonsModalVisible(false);
    resetForm();
    setIsFormEnabled(true);
  };

  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);
  const isApiCalled = useRef<boolean>(false);

  const handleDocusignWindowMessages = async (e: MessageEvent) => {
    if (contractAction && e.origin === window.location.origin) {
      const { action, payload } = e.data;
      if (payload?.event)
        switch (action) {
          case DOCUSIGN_MESSAGE_ACTIONS.DOCUSIGN_FAILED_SIGN:
            showErrorToast({
              message: 'Error Saving Documents',
              description: 'Please try again'
            });
            break;
          case DOCUSIGN_MESSAGE_ACTIONS.DOCUSIGN_SIGN_COMPLETE:
            if (!isApiCalled.current) {
              const res = await sendContractCallback({
                envelope_id: payload.envelopId,
                event_id: payload.eventId,
                event: payload.event
              });
              if (!res?.error) {
                showToast({ message: 'Contract Sent Successfully.', description: '' });
                setIsContractButtonsModalVisible(false);
                window.location.reload();
              } else {
                showErrorToast({
                  message: 'Error Saving Documents',
                  description: res.error || 'Please try again'
                });
              }
            }
            isApiCalled.current = true;
            break;
        }
    }
  };

  const setupEventListeners = () => {
    window.addEventListener('message', handleDocusignWindowMessages, true);
  };

  useEffect(() => {
    if (contractAction) {
      setupEventListeners();
    } else {
      window.removeEventListener('message', handleDocusignWindowMessages, true);
    }

    return () => {
      window.removeEventListener('message', handleDocusignWindowMessages, true);
    };
  }, [isContractButtonsModalVisible, isViewContractDataLoading]);

  const handleSave = async (values: ContractFormValues) => {
    setIsGeneratingPdf(true);
    const res = await generateContract({
      contract_type_id: values?.contract_type_id,
      name: values?.name,
      email: values?.email,
      fdd_rep: values?.fdd_rep,
      event_id: contractData?.event_id || data?.event_id,
      envelope_id: contractData?.envelope_id || '',
      deposit_amount: values?.deposit_amount,
      deposit_percent: values?.deposit_percent,
      is_deposit_percent: values?.is_deposit_percent,
      grand_total: values?.grand_total,
      action: contractAction !== null ? contractAction : '1'
    });
    setIsGeneratingPdf(false);

    if (!res?.error) {
      if (res?.data?.data?.pdf_url) {
        window.open(res?.data?.data?.pdf_url, '_blank');
      }
    } else {
      showErrorToast({
        message: 'Error Saving Documents',
        description: res.error || 'Please try again'
      });
    }
    setIsFormEnabled(false);
  };

  const depositAmount = Math.round(parseFloat(values.deposit_amount));

  const Contract_amount = values?.is_deposit_percent
    ? Math.round((values.grand_total * values.deposit_percent) / 100).toFixed(2)
    : isNaN(depositAmount)
    ? 0
    : depositAmount;

  const handleGeneratePDF = () => {
    if (!(Object.keys(errors).length === 0)) {
      console.error('Form has errors. Cannot generate PDF.'); 
    }
    setFieldValue('deposit_amount', Contract_amount);
    handleSubmit();
  };

  const handleToggleUpdate = (e: boolean) => {
    const updatedValues = { ...values };
    updatedValues.deposit_amount = '';
    updatedValues.deposit_percent = '';
    updatedValues.is_deposit_percent = e ? 1 : 0;
    setValues(updatedValues);
  };

  // Helper function to truncate string
  const truncateString = (str: string, maxLength: number) => {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };

  return (
    <>
      <ModalComponent
        visible={isContractButtonsModalVisible}
        onCancel={onCancel}
        modalWidth={'calc(100vw - 500px) !important'}
        align="center !important"
      >
        <Row gutter={24} className="centered">
          <Col lg={24} md={24}>
            <H2Typography style={{ textAlign: 'center', marginBottom: '15px' }}>
              Contract Details
            </H2Typography>
          </Col>
        </Row>

        <Row gutter={24}>
          {/* Left Column - Event Details */}

          <Col lg={12} md={12}>
            <Col lg={24} md={24}>
              <Label text={`CONTRACT TYPE`} isMandatory={true} />
              <SelectInput
                id="contract_type_id"
                name="contract_type_id"
                placeholdertitle="Select One"
                value={values?.contract_type_id}
                style={{
                  textAlign: 'left'
                }}
                onChange={(value: any) => setFieldValue('contract_type_id', value)}
                error={touched?.contract_type_id && errors?.contract_type_id}
              >
                {data?.contract_types?.map((i: any) => (
                  <Option
                    style={{
                      fontSize: '16px',
                      padding: '6px 12px',
                      fontWeight: '400',
                      textAlign: 'left'
                    }}
                    key={i?.id}
                    value={i?.id}
                  >
                    {i?.name}
                  </Option>
                ))}
              </SelectInput>
            </Col>
            <Col lg={24} md={24}>
              <Label text="CLIENT NAME" isMandatory={true} />
              <Input
                id={'name'}
                name={'name'}
                placeholder="Enter Name"
                value={values?.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.name && errors?.name}
                disabled={!isFormEnabled}
              />
            </Col>
            <Col lg={24} md={24}>
              <Label text="CLIENT EMAIL" isMandatory={true} />
              <Input
                id={'email'}
                name={'email'}
                placeholder="Enter email"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.email && errors?.email}
                disabled={!isFormEnabled}
              />
            </Col>
            <Col lg={24} md={24}>
              <Label text="FOOD DUDES REP" isMandatory={true} />
              <Input
                id={'fdd_rep'}
                name={'fdd_rep'}
                placeholder="Enter Name"
                value={values?.fdd_rep}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.fdd_rep && errors?.fdd_rep}
                disabled={!isFormEnabled}
              />
            </Col>
            <Col lg={24} md={24}>
              <Flex gap={12} justifyContent="space-between" alignItems={'center'}>
                <Label text="DEPOSIT" isMandatory={true} />
                <div>
                  <Switch
                    id={`is_deposit_percent`}
                    checked={values?.is_deposit_percent}
                    defaultChecked={values?.is_deposit_percent}
                    onChange={handleToggleUpdate}
                  />
                  <span style={{ marginLeft: '6px' }}>Customize as a %</span>
                </div>
              </Flex>
              <Input
                id={values?.is_deposit_percent ? `deposit_percent` : `deposit_amount`}
                name={values?.is_deposit_percent ? `deposit_percent` : `deposit_amount`}
                prefix={values?.is_deposit_percent ? '%' : CURRENCY}
                value={
                  values?.is_deposit_percent ? values?.deposit_percent : values?.deposit_amount
                }
                onBlur={handleBlur}
                error={
                  (touched?.deposit_amount || touched?.deposit_percent) &&
                  (errors?.deposit_amount || errors?.deposit_percent)
                }
                onChange={handleChange}
              />
            </Col>
            <Col lg={24} md={24} style={{ textAlign: 'left', paddingTop: '10px' }}>
              <Label
                text={`CONTRACT AMOUNT : ${CURRENCY}
              ${Contract_amount}`}
              />
            </Col>
          </Col>
          {/* Right Column - Client Details */}
          <Col lg={12} md={12}>
            <Col lg={24} md={24} style={{ textAlign: 'left', marginBottom: '15px' }}>
              <Label text="EVENT NAME" />
              <Value>{truncateString(data?.event_name, 70) || '-'}</Value>
            </Col>
            <Col lg={24} md={24} style={{ textAlign: 'left', marginBottom: '15px' }}>
              <Label text="LOCATION" />
              <Value>{truncateString(locationDetail?.venue_address, 70) || '-'}</Value>
            </Col>
            <Col lg={24} md={24} style={{ textAlign: 'left', marginBottom: '15px' }}>
              <Label text="EVENT DATE" />
              <Value>{data?.event_date || '-'}</Value>
            </Col>
            <Col lg={24} md={24} style={{ textAlign: 'left', marginBottom: '15px' }}>
              <Label text="TIME ESTIMATED" />
              <Value>
                {data?.start_time && data?.end_time
                  ? `${moment(data?.start_time, 'HH:mm:ss').format('hh:mm A')} to ${moment(
                      data?.end_time,
                      'HH:mm:ss'
                    ).format('hh:mm A')}`
                  : '-'}
              </Value>
            </Col>
            <Col lg={24} md={24} style={{ textAlign: 'left', marginBottom: '15px' }}>
              <Label text="GUEST COUNT" />
              <Value>{data?.total_guest}</Value>
            </Col>
          </Col>
          <Col lg={12} md={12} style={{ paddingTop: '10px' }}>
            <Col lg={24} md={24}>
              <Row style={{ paddingBottom: '20px', gap: '20px' }}>
                <Button text={'Cancel'} variant={'secondary'} onClick={handleCancel} />

                <Button
                  text={'Generate Contract'}
                  onClick={handleGeneratePDF}
                  isDisable={!isFormEnabled}
                  isLoading={isGeneratingPdf}
                />
              </Row>
            </Col>
          </Col>
        </Row>
      </ModalComponent>
    </>
  );
};

export default ContractButtonModal;
