import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { H1Typography } from '@constant/typography/Typography';
import ModalComponent from '@organism/Modal/Modal';
import InputPhone from '@atom/InputPhone';
import { customerValidations, NEW_CUSTOMER_FORM_INITIAL_VALUES } from './helper';
import { AddNewCustomerModalProps, ContactDetailsType } from '@pages/QuoteManagementPage/types';

function AddNewCustomerModal(props: AddNewCustomerModalProps) {
  const { isModalVisible, onCancel, onSubmit } = props;

  const handleCancel = (resetForm: any, setValues: any) => {
    resetForm();
    setValues({ ...NEW_CUSTOMER_FORM_INITIAL_VALUES });
    onCancel();
  };

  const formik = useFormik({
    initialValues: NEW_CUSTOMER_FORM_INITIAL_VALUES,
    validationSchema: customerValidations,
    onSubmit: onSubmit
  });

  const {
    resetForm,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    isSubmitting
  } = formik;

  const phoneChangeHandler = (a: ContactDetailsType) => {
    setFieldValue('country_code', a.code ?? '');
    setFieldValue('extension', a.ext ?? '');
    setFieldValue('phone_number', a.phone ?? '');
  };

  return (
    <>
      <ModalComponent
        visible={isModalVisible}
        onCancel={() => handleCancel(resetForm, setValues)}
        modalWidth="798px !important"
        align="left !important"
      >
        <Flex justifyContent="center" bottom={38}>
          <H1Typography>Add a New Customer</H1Typography>
        </Flex>
        <Row gutter={16}>
          <Col xs={24} lg={24}>
            <Label isMandatory={true} text="Customer Name" />
            <Input
              name="name"
              id="name"
              error={touched?.name && errors?.name}
              placeholder="Customer Name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
            />
          </Col>

          <Col xs={24} lg={24}>
            {/* <Label isMandatory={true} text="Customer Email" /> */}
            <Label isMandatory={false} text="Customer Email" />
            <Input
              name="email"
              id="email"
              error={touched?.email && errors?.email}
              placeholder="Customer Email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={24} xs={24}>
            <Label text={`Phone Number`} />
            <InputPhone
              id="phone_number"
              name="phone_number"
              value={{
                code: values.country_code,
                phone: values.phone_number,
                ext: values.extension
              }}
              error={
                (touched.phone_number && errors.phone_number) ||
                (touched.extension && errors.extension) ||
                (touched.country_code && errors.country_code) ||
                ''
              }
              onBlur={handleBlur}
              onChange={phoneChangeHandler}
            />
          </Col>
        </Row>

        <Flex className="footerButtonModal fix-mobile">
          <Button
            variant="secondary"
            text={'Cancel'}
            onClick={() => handleCancel(resetForm, setValues)}
            className="cancel-btn"
          />
          <Button variant="primary" text={'Save'} onClick={handleSubmit} isLoading={isSubmitting} />
        </Flex>
      </ModalComponent>
    </>
  );
}

export default AddNewCustomerModal;
