import * as yup from 'yup';
import services from '@services/index';

import { ResponseType } from '@pages/EmployeeManagementPage/helper';
import { FormikErrors, FormikTouched } from 'formik';

// initial values
export const initialValue = {
  task: ''
};

// types
export interface EventTaskType {
  id: number | string;
  event_id: number | string;
  master_event_task_id: number | string;
  task: string;
  sort_order: number | string;
  completed_at: string | null;
  is_completed: boolean;
}

export interface EditEventTaskType {
  event_tasks: EventTaskType[];
}

export interface TaskInputProps {
  index: number;
  formikValue: string;
  touched: FormikTouched<EditEventTaskType>;
  errors: FormikErrors<EditEventTaskType>;
  onRemoveHandler?: (a: number) => void;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showRemove: boolean;
}

// api calls
export const getEventTaskList = async (id: string): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/events/task?event_id=${id}`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateEventTaskList = async (body: EditEventTaskType): Promise<ResponseType> => {
  try {
    const res = (await services.post(`/events/task`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    const key = error?.error?.error ? Object.keys(error?.error?.error)?.[0] : undefined;
    return {
      data: null,
      error: key ? error?.error?.error?.[key][0] : error?.error?.message || 'Something went wrong!'
    };
  }
};

// yup validations
export const eventTaskValidationSchema = yup.object({
  event_tasks: yup.array().of(
    yup.object({
      task: yup.string().required('Event Task is required.').nullable()
    })
  )
});
