import { STAFF_MANAGEMENT_TABS } from '../helper';
import StaffManagementDataTable from '../StaffManagementDataTable';
export const getTabs = () => {
  const tabValues = [
    {
      tab: 'UPCOMING',
      content: <StaffManagementDataTable activePanel={STAFF_MANAGEMENT_TABS.UPCOMING} />,
      key: STAFF_MANAGEMENT_TABS.UPCOMING,
      getUrl: STAFF_MANAGEMENT_TABS.UPCOMING
    },
    {
      tab: 'PAST',
      content: <StaffManagementDataTable activePanel={STAFF_MANAGEMENT_TABS.PAST} />,
      key: STAFF_MANAGEMENT_TABS.PAST,
      getUrl: STAFF_MANAGEMENT_TABS.PAST
    }
  ];
  return tabValues;
};
