import styled from 'styled-components';

export const RadioButtonWrapper = styled.div`
  // margin-bottom: 24px;
  font-weight: 800;
  font-size: 16px !important;
  text-transform: uppercase;
  .ant-radio-wrapper {
    font-size: 16px !important;
  }
`;

export const SurveyPageWrapper = styled.div`
  .errormsg {
    min-height: 24px;
    display: block;
  }

  .pageTitle {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const TextAreaInputWrapper = styled.div`
  .textAreaInput {
    margin-bottom: 0px;
  }
`;
