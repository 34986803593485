import { WORK_ORDER_MANAGEMENT_TABS } from '../helper';
import WorkOrderDataTable from '../WorkOrderDataTable';
import MySchedulePage from './MyScheduleSection';

export const getTabs = (activePanel: string) => {
  const tabValues = [
    {
      tab: 'UPCOMING',
      content: (
        <WorkOrderDataTable
          currentPanel={WORK_ORDER_MANAGEMENT_TABS.UPCOMING}
          activePanel={activePanel}
        />
      ),
      key: WORK_ORDER_MANAGEMENT_TABS.UPCOMING,
      getUrl: WORK_ORDER_MANAGEMENT_TABS.UPCOMING
    },
    {
      tab: 'PAST',
      content: (
        <WorkOrderDataTable
          currentPanel={WORK_ORDER_MANAGEMENT_TABS.PAST}
          activePanel={activePanel}
        />
      ),
      key: WORK_ORDER_MANAGEMENT_TABS.PAST,
      getUrl: WORK_ORDER_MANAGEMENT_TABS.PAST
    },
    {
      tab: 'MY SCHEDULE',
      content: <MySchedulePage activePanel={activePanel} />,
      key: WORK_ORDER_MANAGEMENT_TABS.MY_SCHEDULE,
      getUrl: WORK_ORDER_MANAGEMENT_TABS.MY_SCHEDULE
    }
  ];
  return tabValues;
};
