import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Button } from '@atom/Buttons';
import { CustomFileDragger } from '@molecules/FileDraggerComponent';
import { FILE_TYPES } from '@constants/userConstants';
import { Flex } from '@atom/Flex';
import { IAddEditInfoProps } from '../types';
import { Label } from '@atom/FormLable';
import { QuoteButtonWrapper } from '@pages/QuoteManagementPage/style';
import { VENDOR_MANAGEMENT_TABS } from '../helper';
import { showErrorToast } from '@utils/index';
import { uploadFileHandler } from '@services/fileService';
import { Col, Row } from 'antd';
import { H4Typography } from '@constant/typography/Typography';
import DeleteIcon from '@assets/Delete.svg';
import FilesImage from '@assets/filesImage.png';

const AddEditDocuments: React.FC<IAddEditInfoProps> = ({ formik }) => {
  const { handleSubmit, isSubmitting, values, setFieldValue } = formik;
  const navigate = useNavigate();
  const { id } = useParams();
  const [docLoading, setDocLoading] = useState(false);

  const handleFileUpload = async (file: File, length: number, uid: string) => {
    setDocLoading(true);
    const result: any = await uploadFileHandler(file, FILE_TYPES.DOCUMENTS);
    setDocLoading(false);
    if (result.error) {
      return showErrorToast({
        message: `Unable to upload document`,
        description: result.error || 'Please try again'
      });
    }
    const documentsList = values.documents || [];

    const doc = {
      uid: uid,
      venue_id: id,
      document_name: file.name,
      document_url: result.data.url
    };
    documentsList.push(doc);
    setFieldValue('documents', documentsList);
  };

  const deleteDocumentHandler = (index: number) => {
    if (docLoading) return;
    const documentsList = values.documents || [];
    const updatedDocumentsList = [...documentsList];
    updatedDocumentsList.splice(index, 1);
    setFieldValue('documents', updatedDocumentsList);
  };

  return (
    <>
      <Flex direction="column">
        {!!values?.documents && values?.documents?.length > 0 && <Label text="File" isMandatory />}
        <CustomFileDragger
          onChangeHandle={handleFileUpload}
          loading={docLoading}
          maxLimit={20000000} // 20 MB
          multiple={true}
          showUploadList={false}
        />
      </Flex>
      <Flex direction="column">
        {values?.documents?.map((document, index) => {
          return (
            <Row key={index} gutter={[24, 16]}>
              <Col>
                <Flex style={{ alignItems: 'baseline', gap: '0 20px ' }}>
                  <img src={FilesImage} alt="file" style={{ height: '16px' }} />
                  <H4Typography>{document.document_name}</H4Typography>
                  <img
                    src={DeleteIcon}
                    alt="delete"
                    style={{ height: '16px', cursor: docLoading ? 'not-allowed' : 'pointer' }}
                    onClick={() => deleteDocumentHandler(index)}
                  />
                </Flex>
              </Col>
            </Row>
          );
        })}
      </Flex>
      {!!values?.documents && values?.documents?.length > 0 && (
        <Flex>{/* <pre>{JSON.stringify(values?.documents, null, 2)}</pre> */}</Flex>
      )}
      <QuoteButtonWrapper>
        <Flex
          className="footerButton responsive-css fix-mobile"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Flex className="footerButton">
            <Button
              variant="primary"
              text="Back"
              onClick={() => navigate(`/vendors${id ? `/${id}/edit` : `/edit`}`)}
            />
          </Flex>
          <Flex className="footerButton">
            <Button
              variant="secondary"
              text="Cancel"
              onClick={() => navigate(`/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`)}
            />
            <Button
              variant="primary"
              size="large"
              text="Submit"
              onClick={handleSubmit}
              isLoading={isSubmitting}
            />
          </Flex>
        </Flex>
      </QuoteButtonWrapper>
    </>
  );
};

export default AddEditDocuments;
