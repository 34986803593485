import { Col, Row } from 'antd';
import { FieldArray, FormikProvider } from 'formik';
import { useCallback } from 'react';
import StaffingFields from './StaffingFields';
import update from 'immutability-helper';
import { H2Typography } from '@constant/typography/Typography';
import { Divider } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';
import { Button } from '@atom/Buttons';
import { getStaffingEmptyObject } from './helper';
import { PlusCircleOutlined } from '@ant-design/icons';
import DragNDropWrapper from '@atom/DragNDropWrapper';
import Error from '@atom/Error';

interface AdditionalStaffingSectionProps {
  formik: any;
  dropdownData: any;
  eventDetails: any;
}

const AdditionalStaffingSection = (props: AdditionalStaffingSectionProps) => {
  const { formik, dropdownData, eventDetails } = props;
  const { values, setFieldValue, errors } = formik;

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const res = update(values.additional_staffing, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, values.additional_staffing[dragIndex]]
        ]
      });
      setFieldValue('additional_staffing', res);
    },
    [values.additional_staffing]
  );

  const handleDnD = async (obj: any) => {};

  return (
    <>
      <H2Typography>Additional Staff</H2Typography>
      <Divider />
      <FormikProvider value={formik}>
        <FieldArray
          name="additional_staffing"
          render={(arrayHelper) => {
            if (values?.additional_staffing?.length === 0) {
              return null;
            }
            return values?.additional_staffing?.map((item: any, index: number) => {
              return (
                <DragNDropWrapper
                  key={index}
                  index={index}
                  onDropCard={handleDnD}
                  moveCard={moveCard}
                >
                  <Row style={{ marginTop: 20, width: '100%' }}>
                    <StaffingFields
                      index={index}
                      item={item}
                      formik={formik}
                      eventDetails={eventDetails}
                      dropdownData={dropdownData}
                      fieldType="additional_staffing"
                      onRemove={(position: any) => {
                        const positionItems = values?.additional_staffing?.filter((i: any) =>
                          i?.id ? i?.id !== position?.id : i?.tempId !== position?.tempId
                        );
                        setFieldValue('additional_staffing', positionItems);
                      }}
                    />
                  </Row>
                </DragNDropWrapper>
              );
            });
          }}
        />
      </FormikProvider>
      <Row style={{ width: '100%', marginTop: '10' }}>
        <Col lg={20} xs={20}>
          {!!errors.additional_staffing && typeof errors.additional_staffing === 'string' && (
            //@ts-ignore
            <Error error={errors.additional_staffing || ''} />
          )}
        </Col>
        <Col lg={4} xs={4} style={{ justifyContent: 'end', display: 'flex' }}>
          <Button
            variant="secondary"
            onClick={() => {
              formik.setFieldValue('additional_staffing', [
                ...(values?.additional_staffing || []),
                {
                  tempId: new Date().getTime(),
                  ...getStaffingEmptyObject(1, values?.additional_staffing?.length + 1)
                }
              ]);
            }}
            icon={<PlusCircleOutlined />}
            text={'Add'}
            style={{ borderWidth: 2 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdditionalStaffingSection;
