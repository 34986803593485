import services from '@services/index';
import * as yup from 'yup';

export enum SERVICES {
  FOOD = '1',
  ALCOHOL_AND_SOFT_BAR = '2',
  LABOUR = '3',
  NET_TOTAL = '4'
}

export const serviceOptionKeys: any = {
  service_food_fees_percent: SERVICES.FOOD,
  service_alcohol_fees_percent: SERVICES.ALCOHOL_AND_SOFT_BAR,
  service_labour_fees_percent: SERVICES.LABOUR,
  service_net_total_fees_percent: SERVICES.NET_TOTAL
};

export const serviceOptions = [
  { label: 'Food', value: SERVICES.FOOD },
  { label: 'Alcohol & Soft Bar', value: SERVICES.ALCOHOL_AND_SOFT_BAR },
  { label: 'Labour', value: SERVICES.LABOUR }
];

export enum DISCOUNTS {
  FOOD = '1',
  ALCOHOL_AND_SOFT_BAR = '2',
  LABOUR = '3'
}

export const discountOptionKeys: any = {
  discount_food_fees_percent: DISCOUNTS.FOOD,
  discount_alcohol_fees_percent: DISCOUNTS.ALCOHOL_AND_SOFT_BAR,
  discount_labour_fees_percent: DISCOUNTS.LABOUR
};

export const discountOptions = [
  { label: 'Food', value: DISCOUNTS.FOOD },
  { label: 'Alcohol & Soft Bar', value: DISCOUNTS.ALCOHOL_AND_SOFT_BAR },
  { label: 'Labour', value: DISCOUNTS.LABOUR }
];

export const feesFormValidations = yup.object().shape({
  service_fees: yup.string().nullable(),
  service_fees_percent: yup.string().nullable(),
  service_food_fees: yup.string().nullable(),
  service_food_fees_percent: yup.string().nullable(),
  service_alcohol_fees: yup.string().nullable(),
  service_alcohol_fees_percent: yup.string().nullable(),
  service_labour_fees: yup.string().nullable(),
  service_labour_fees_percent: yup.string().nullable(),
  service_fees_instructions: yup.string().nullable(),
  service_net_total_fees: yup.string().nullable(),
  service_net_total_fees_percent: yup.string().nullable(),
  discount_amount_percent: yup.string().nullable(),
  discount_alcohol_fees: yup.string().nullable(),
  discount_alcohol_fees_percent: yup.string().nullable(),
  discount_food_fees: yup.string().nullable(),
  discount_food_fees_percent: yup.string().nullable(),
  discount_labour_fees: yup.string().nullable(),
  discount_labour_fees_percent: yup.string().nullable(),
  adjustment_instructions: yup.string().nullable(),
  deposit: yup.number().nullable(),
  deposit_percent: yup.number().nullable(),
  payment: yup.number().nullable(),
  hst_fees_percent: yup.number().nullable(),
  hst_fees_instructions: yup.string().nullable()
});

export const feesFormInitialValues = {
  id: '',
  unique_invoice_string: '',
  quotation_no: '',
  event_dates: {
    id: '',
    quotation_id: '',
    event_date: [new Date().toString()],
    fees: {
      is_customize_service_fees: false,
      service_fees_percent: '',
      service_food_fees_percent: '',
      service_alcohol_fees_percent: '',
      service_labour_fees_percent: '',
      service_fees_instructions: '',
      is_customize_discount_fees: false,
      is_discount_in_dollar: false,
      discount_amount_percent: '',
      discount_food_fees_percent: '',
      discount_alcohol_fees_percent: '',
      discount_labour_fees_percent: '',
      adjustment_instructions: '',
      deposit: '',
      deposite_percent: '',
      is_deposit_percent: false,
      hst_fees_percent: '',
      hst_fees_instructions: ''
    }
  },
  status: 0
};

export const getMappedData = (oldValuesObj: any, newValuesObj: any) => {
  const netTotal = Number(
    newValuesObj?.service_net_total_fees_percent || oldValuesObj?.service_net_total_fees_percent
  );
  const is_customize_service_fees =
    newValuesObj?.is_customize_service_fees || oldValuesObj?.is_customize_service_fees;
  const foodFeesService = Number(
    newValuesObj?.service_food_fees_percent || oldValuesObj?.service_food_fees_percent
  );
  const alcoholFeesService = Number(
    newValuesObj?.service_alcohol_fees_percent || oldValuesObj?.service_alcohol_fees_percent
  );
  const labourFeesService = Number(
    newValuesObj?.service_labour_fees_percent || oldValuesObj?.service_labour_fees_percent
  );
  const is_customize_discount_fees =
    newValuesObj?.is_customize_discount_fees || oldValuesObj?.is_customize_discount_fees;
  const foodFeesDiscount = Number(
    newValuesObj?.discount_food_fees_percent || oldValuesObj?.discount_food_fees_percent
  );
  const alcoholFeesDiscount = Number(
    newValuesObj?.discount_alcohol_fees_percent || oldValuesObj?.discount_alcohol_fees_percent
  );
  const labourFeesDiscount = Number(
    newValuesObj?.discount_labour_fees_percent || oldValuesObj?.discount_labour_fees_percent
  );

  const is_deposit_percent = newValuesObj?.is_deposit_percent || 0;

  return {
    ...oldValuesObj,
    id: newValuesObj?.id || oldValuesObj?.id,
    quotation_no: newValuesObj?.quotation_no || oldValuesObj?.quotation_no,
    status: newValuesObj?.status ?? oldValuesObj?.status,
    unique_invoice_string:
      newValuesObj?.unique_invoice_string || oldValuesObj?.unique_invoice_string,
    event_dates: {
      id: newValuesObj?.event_dates?.id,
      quotation_id: newValuesObj?.event_dates?.quotation_id,
      event_date: [newValuesObj?.event_dates?.event_date],
      start_time: newValuesObj?.event_dates?.start_time,
      end_time: newValuesObj?.event_dates?.end_time,
      quotation_event_date_id: newValuesObj?.event_dates?.id || oldValuesObj?.event_dates?.id,
      fees: {
        is_customize_service_fees: is_customize_service_fees,
        service_fees_percent:
          netTotal ||
          (is_customize_service_fees
            ? 0
            : foodFeesService || alcoholFeesService || labourFeesService),
        service_food_fees_percent: foodFeesService,
        service_alcohol_fees_percent: alcoholFeesService,
        service_labour_fees_percent: labourFeesService,
        service_fees_instructions:
          newValuesObj?.service_fees_instructions || oldValuesObj?.service_fees_instructions,
        service_net_total_fees_percent: netTotal,
        is_customize_discount_fees: is_customize_discount_fees,
        is_discount_in_dollar: newValuesObj?.is_discount_in_dollar || 0,
        discount_amount_percent: is_customize_discount_fees
          ? 0
          : foodFeesDiscount ||
            alcoholFeesDiscount ||
            labourFeesDiscount ||
            newValuesObj?.discount_amount_percent,
        discount_food_fees_percent: foodFeesDiscount,
        discount_alcohol_fees_percent: alcoholFeesDiscount,
        discount_labour_fees_percent: labourFeesDiscount,
        adjustment_instructions:
          newValuesObj?.adjustment_instructions || oldValuesObj?.adjustment_instructions,
        is_deposit_percent: is_deposit_percent,
        deposit: is_deposit_percent ? 0 : newValuesObj?.deposit || oldValuesObj?.deposit,
        deposit_percent: newValuesObj?.deposit_percent || oldValuesObj?.deposit_percent,
        payment: newValuesObj?.payment || oldValuesObj?.payment,
        hst_fees_percent: newValuesObj?.hst_fees_percent || oldValuesObj?.hst_fees_percent,
        hst_fees_instructions:
          newValuesObj?.hst_fees_instructions || oldValuesObj?.hst_fees_instructions
      }
    }
  };
};

export const handleChangeValues = (
  e: any,
  value: string,
  keyOptions: any,
  setCheckboxState: (value: any) => void,
  values: any,
  setValues: (value: any) => void
) => {
  const val = e?.length ? e : [e];
  setCheckboxState(val);
  const tempObj: any = { ...values };
  for (const key in keyOptions) {
    tempObj.event_dates.fees[key] = val?.includes(keyOptions[key]) ? value : 0;
  }
  setValues(tempObj);
};

export const getMappedPayload = (data: any) => {
  return {
    ...data,
    is_customize_service_fees: undefined,
    is_customize_discount_fees: undefined
  };
};
