import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import Switch from '@atom/Switch';
import { CheckboxWrapper } from '@pages/QuoteManagementPage/style';
import { Checkbox, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { DISCOUNTS, handleChangeValues } from './helper';
import { discountOptionKeys, discountOptions } from './helper';
import { ServiceFeeWrapper } from './style';
import { CURRENCY } from '@constants/index';
const DiscountSection = (props: any) => {
  const { formik } = props;
  const { touched, errors, values, setFieldValue, setValues, handleChange, handleBlur } = formik;
  const [discountCheckboxes, setDiscountCheckboxes] = useState<any>([]);

  const handleDiscountUpdate = (e: any) => {
    const updatedValues = { ...values };
    updatedValues.event_dates.fees.discount_amount_percent = e.target.value;
    handleChangeValues(
      discountCheckboxes,
      e?.target.value,
      discountOptionKeys,
      setDiscountCheckboxes,
      updatedValues,
      setValues
    );
    handleChange(e);
  };

  const handleToggleUpdate = (e: boolean) => {
    setDiscountCheckboxes([]);
    const updatedValues = { ...values };
    updatedValues.event_dates.fees.discount_amount_percent = '';
    updatedValues.event_dates.fees.discount_alcohol_fees_percent = '';
    updatedValues.event_dates.fees.discount_food_fees_percent = '';
    updatedValues.event_dates.fees.discount_labour_fees_percent = '';
    setValues(updatedValues);
    setFieldValue(`event_dates.fees.is_customize_discount_fees`, e ? 1 : 0);
  };

  const handleToggleDiscount = (e: any) => {
    const updatedValues = { ...values };
    updatedValues.event_dates.fees.discount_amount_percent = '';
    updatedValues.event_dates.fees.discount_alcohol_fees_percent = '';
    updatedValues.event_dates.fees.discount_food_fees_percent = '';
    updatedValues.event_dates.fees.discount_labour_fees_percent = '';
    updatedValues.event_dates.fees.is_discount_in_dollar = e ? 1 : 0;
    setValues(updatedValues);
  };

  const setCheckBoxes = () => {
    let discounts = [];
    if (Number(values?.event_dates?.fees?.discount_food_fees_percent)) {
      discounts.push(DISCOUNTS.FOOD);
    }
    if (Number(values?.event_dates?.fees?.discount_alcohol_fees_percent)) {
      discounts.push(DISCOUNTS.ALCOHOL_AND_SOFT_BAR);
    }
    if (Number(values?.event_dates?.fees?.discount_labour_fees_percent)) {
      discounts.push(DISCOUNTS.LABOUR);
    }
    setDiscountCheckboxes(discounts);
  };

  useEffect(() => {
    setCheckBoxes();
  }, []);

  return (
    <>
      {values?.event_dates?.fees?.is_customize_discount_fees ? <Label text="Discount" /> : null}
      <Row gutter={16}>
        <ServiceFeeWrapper style={{ display: 'contents' }}>
          {!values?.event_dates?.fees?.is_customize_discount_fees ? (
            <>
              <Col md={4} xs={24}>
                <Label text="Discount" isMandatory={false} />
                <Input
                  id={`event_dates.fees.discount_amount_percent`}
                  name={`event_dates.fees.discount_amount_percent`}
                  placeholder={values?.event_dates?.fees?.is_discount_in_dollar ? CURRENCY : '%'}
                  error={
                    touched?.event_dates?.fees?.discount_amount_percent &&
                    errors?.event_dates?.fees?.discount_amount_percent
                  }
                  value={values?.event_dates?.fees?.discount_amount_percent}
                  onChange={handleDiscountUpdate}
                  onBlur={handleBlur}
                />
              </Col>

              <Col md={8} xs={12} className="service-fee-style">
                <Row gutter={16}>
                  <Col md={18} xs={24}>
                    <CheckboxWrapper>
                      <Checkbox.Group
                        name={`event_dates.fees.discount_checkboxes`}
                        defaultValue={discountCheckboxes}
                        value={discountCheckboxes}
                        options={discountOptions}
                        onChange={(e: any) =>
                          handleChangeValues(
                            e,
                            values.event_dates.fees.discount_amount_percent,
                            discountOptionKeys,
                            setDiscountCheckboxes,
                            values,
                            setValues
                          )
                        }
                      />
                    </CheckboxWrapper>
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <Col md={8} xs={24} lg={4}>
                <Label text="Food" />
                <Input
                  id={`event_dates.fees.discount_food_fees_percent`}
                  name={`event_dates.fees.discount_food_fees_percent`}
                  placeholder={values?.event_dates?.fees?.is_discount_in_dollar ? CURRENCY : '%'}
                  error={
                    touched?.event_dates?.fees?.discount_food_fees_percent &&
                    errors?.event_dates?.fees?.discount_food_fees_percent
                  }
                  value={values?.event_dates?.fees?.discount_food_fees_percent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col md={8} xs={24} lg={4}>
                <Label text="Alcohol & Soft Bar" />
                <Input
                  id={`event_dates.fees.discount_alcohol_fees_percent`}
                  name={`event_dates.fees.discount_alcohol_fees_percent`}
                  placeholder={values?.event_dates?.fees?.is_discount_in_dollar ? CURRENCY : '%'}
                  error={
                    touched?.event_dates?.fees?.discount_alcohol_fees_percent &&
                    errors?.event_dates?.fees?.discount_alcohol_fees_percent
                  }
                  value={values?.event_dates?.fees?.discount_alcohol_fees_percent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col md={8} xs={24} lg={4}>
                <Label text="Labour" />
                <Input
                  id={`event_dates.fees.discount_labour_fees_percent`}
                  name={`event_dates.fees.discount_labour_fees_percent`}
                  placeholder={values?.event_dates?.fees?.is_discount_in_dollar ? CURRENCY : '%'}
                  error={
                    touched?.event_dates?.fees?.discount_labour_fees_percent &&
                    errors?.event_dates?.fees?.discount_labour_fees_percent
                  }
                  value={values?.event_dates?.fees?.discount_labour_fees_percent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </>
          )}
          <Col md={8} xs={24} className="service-fee-style">
            <Flex gap={12}>
              <Switch
                id={`event_dates.fees.is_customize_discount_fees`}
                checked={values?.event_dates?.fees?.is_customize_discount_fees}
                defaultChecked={values?.event_dates?.fees?.is_customize_discount_fees}
                onChange={handleToggleUpdate}
              />
              <span style={{ marginLeft: '6px', marginBottom: '20px' }}>
                Customize {values?.event_dates?.fees?.is_discount_in_dollar ? CURRENCY : '%'} Per
                Category
              </span>
            </Flex>
            <Flex>
              <Switch
                id={`event_dates.fees.is_discount_in_dollar`}
                checked={values?.event_dates?.fees?.is_discount_in_dollar}
                defaultChecked={values?.event_dates?.fees?.is_discount_in_dollar}
                onChange={handleToggleDiscount}
              />
              <span style={{ marginLeft: '6px' }}>Customize discount as amount($)</span>
            </Flex>
          </Col>
          <Col span={24}>
            <Label text="Discount Instructions" isMandatory={false} />
            <Input
              max={200}
              id={`event_dates.fees.adjustment_instructions`}
              name={`event_dates.fees.adjustment_instructions`}
              value={values?.event_dates?.fees?.adjustment_instructions}
              onChange={handleChange}
            />
          </Col>
        </ServiceFeeWrapper>
      </Row>
    </>
  );
};

export default DiscountSection;
