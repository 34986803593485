import styled from 'styled-components';
interface ProductsListContainerStyleProps {
  isBordered?: boolean;
  dataLength?: number;
  isProductList?: boolean;
  isSelectBoxFocused?: boolean;
}
export const ProductsListContainer = styled.div`
  border: ${(props: ProductsListContainerStyleProps) =>
    `${props?.isBordered ? 'solid 1px' : 'none'}`};
  width: 100%;
  padding: 10px;
  min-height: ${(props: ProductsListContainerStyleProps) =>
    `${
      !props?.isSelectBoxFocused
        ? props?.dataLength && props?.dataLength >= 4
          ? '400px'
          : props?.isProductList && props?.dataLength && props?.dataLength <= 2
          ? `${props?.dataLength * 170 + 75}px`
          : props?.isProductList && props?.dataLength && props?.dataLength > 2
          ? `400px`
          : props?.dataLength && props?.dataLength > 0
          ? `${props?.dataLength * (props?.isProductList ? 170 : 85) + 75}px`
          : '50px'
        : props?.isProductList
        ? '460px'
        : '400px'
    }`};

  .ant-select-item-option-content {
    white-space: initial !important;
  }

  @media (max-width: 1200px) {
    overflow: auto;
    min-height: ${(props: ProductsListContainerStyleProps) =>
      `${
        !props?.isSelectBoxFocused
          ? props?.dataLength && props?.dataLength >= 4
            ? '400px'
            : props?.isProductList && props?.dataLength && props?.dataLength <= 2
            ? `${props?.dataLength * 480 + 75}px`
            : props?.isProductList && props?.dataLength && props?.dataLength > 2
            ? `400px`
            : props?.dataLength && props?.dataLength > 0
            ? `${props?.dataLength * (props?.isProductList ? 480 : 85) + 75}px`
            : '50px'
          : props?.isProductList
          ? '460px'
          : '400px'
      }`};

    .List {
      width: ${(props: ProductsListContainerStyleProps) =>
        `${!props.isProductList ? `1200px` : ``}!important`};
      overflow-x: ${(props: ProductsListContainerStyleProps) =>
        `${!props.isProductList ? `auto` : `hidden`}!important`};
    }
    .product-list-header {
      gap: ${(props: ProductsListContainerStyleProps) => `${props.isProductList ? `0px` : `3px`}`};
      width: ${(props: ProductsListContainerStyleProps) =>
        `${!props.isProductList ? `1200px` : ''}`};
    }
    .product-list-header h4 {
      font-size: 16px;
    }
  }
`;

export const SpecialInstructionWrapper = styled.div`
  // padding: 0px 10px;
  // overflow-y: auto;
  // text-align: center;
  // height: 70px;

  // :hover {
  //   overflow-y: auto;
  // }
`;
