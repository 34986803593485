import React, { useEffect, useState } from 'react';
import { Flex } from '@atom/Flex';
import { H4Typography } from '@constant/typography/Typography';
import {
  getMenuDetails,
  IngredientListType,
  saveSpecialIngredient,
  specialIngredientsValidationSchema
} from '../helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';
import MenuManagementEditableVirtualList from '../MenuManagementEditableVirtualList';
import SpecialIngredientDetailsModal from './SpecialIngredientDetailsModal';
import { EditIngredientType } from './types';

const SpecialIngredients = () => {
  const [isSpecialIngredientDetailsModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [ingredientsList, setIngredientsList] = useState<IngredientListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState<boolean>(false);
  const [submitValue, setSubmitValue] = useState<IngredientListType[] | null>(null);
  const [specialIngredientIdForModal, setSpecialIngredientIdForModal] = useState<number | null>(
    null
  );
  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);
  const specialIngredientClickHandler = (id: number) => {
    setSpecialIngredientIdForModal(id);
    setIsModalVisible(true);
  };

  const addSpecialIngredientHandler = () => {
    setSpecialIngredientIdForModal(null);
    setIsModalVisible(true);
  };

  useEffect(() => {
    updateRawData({ isEditForSpecialIngredientDetailsModal: false });
    getSpecialIngredientsHandler();
  }, []);

  const getSpecialIngredientsHandler = async () => {
    setPageLoading(true);
    const result = await getMenuDetails('special-ingredients');
    if (!result.error) {
      setIngredientsList(result.data || []);
    }
    setPageLoading(false);
  };

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getSpecialIngredientsHandler();
  };

  const onIngredientDelete = (
    index: number,
    ingridentsArray: any,
    setFieldValue: any,
    id?: number | null,
    is_associated?: boolean
  ) => {
    if (is_associated) {
      showErrorToast({
        message: 'Special Ingredient is used with product so it will not deleted',
        description: ''
      });
      return false;
    } else {
      if (id) {
        showToast({ message: 'Special Ingredient Removed Successfully', description: '' });
      }
      const tempArr = [...JSON.parse(JSON.stringify(ingridentsArray))];
      tempArr?.splice(index, 1);
      setFieldValue('special_ingredients', tempArr);
      return true;
    }
  };

  const onSubmitHandler = async (values: EditIngredientType) => {
    setSubmitValue(values.special_ingredients);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateMIngredientsHandler = async () => {
    if (!submitValue) return;
    setButtonLoading(true);
    const deleteData = {
      special_ingredient_ids: submitValue.map((ingredient) => ingredient.id)
    };
    const result = await saveSpecialIngredient(
      deleteData,
      'special-ingredients/save-special-ingredient'
    );
    setButtonLoading(false);
    if (!result.error) {
      showToast({ message: 'Special Ingredient Updated Successfully', description: '' });
      onCancelHandler();
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Ingredients',
        description: result.error || 'Please try again'
      });
    }
    setShowSaveConfirmModal(false);
  };

  const modalCloseHandler = () => {
    setIsModalVisible(false);
    setSpecialIngredientIdForModal(null);
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <>
      {isGlobalSettingsEdit ? (
        <>
          <Flex bottom={26}>
            <H4Typography>SPECIAL INGREDIENTS</H4Typography>
          </Flex>
          <Formik
            initialValues={{ special_ingredients: ingredientsList }}
            validationSchema={specialIngredientsValidationSchema}
            onSubmit={onSubmitHandler}>
            {(formikProps: FormikProps<any>) => {
              const { values } = formikProps;
              return (
                <Flex direction="column">
                  <MenuManagementEditableVirtualList
                    formik={formikProps}
                    onItemDelete={onIngredientDelete}
                    fieldArrayName="special_ingredients"
                    inputFieldPlaceHolder="Special Ingredient Name"
                    specialIngredientClickHandler={specialIngredientClickHandler}
                    isInputFieldDisabled={true}
                  />
                  <Flex bottom={24}>
                    <Button
                      text="Add Special Ingredient"
                      variant="secondary"
                      icon={<img width={20} src={PlusCircle} alt="add" />}
                      onClick={addSpecialIngredientHandler}
                    />
                  </Flex>
                  <Flex className="footerButton" justifyContent={'space-between'}>
                    <Flex alignItems={'center'}></Flex>
                    <Flex gap={8}>
                      <Button
                        text="Cancel"
                        variant="secondary"
                        onClick={onCancelHandler}
                        isDisable={buttonLoading}
                      />
                      <Button
                        text="Save"
                        onClick={() => onSubmitHandler(values)}
                        isLoading={buttonLoading}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              );
            }}
          </Formik>
          {showSaveConfirmModal && (
            <ConfirmModal
              visible={showSaveConfirmModal}
              onCancel={onModalCancelHandler}
              title="Saving will impact existing entries."
              onOK={updateMIngredientsHandler}
              isModalLoading={buttonLoading}
              okText="Okay">
              <span>
                Saving this new global setting could impact existing entries. Do you wish to
                continiue?
              </span>
            </ConfirmModal>
          )}
        </>
      ) : (
        <>
          <Flex bottom={26}>
            <H4Typography>SPECIAL INGREDIENTS</H4Typography>
          </Flex>
          <Flex style={{ flexFlow: 'wrap', gap: '16px 40px' }}>
            {ingredientsList?.map((ing) => (
              <Flex onClick={() => specialIngredientClickHandler(ing?.id)} key={ing?.id}>
                <p
                  style={{
                    backgroundColor: '#F4F4F4',
                    color: '#F2994A',
                    borderRadius: '5px',
                    padding: '5px 10px',
                    cursor: 'pointer',
                    margin: 0
                  }}>
                  {ing?.name}
                </p>
              </Flex>
            ))}
          </Flex>
        </>
      )}
      {isSpecialIngredientDetailsModalVisible && (
        <SpecialIngredientDetailsModal
          isModalVisible={isSpecialIngredientDetailsModalVisible}
          modalCloseHandler={modalCloseHandler}
          specialIngredientId={specialIngredientIdForModal}
          getSpecialIngredientsListHandler={getSpecialIngredientsHandler}
        />
      )}
    </>
  );
};

export default SpecialIngredients;
