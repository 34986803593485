import { Flex } from '@atom/Flex';
import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomerDetails from './CustomerDetails';
import QuoteRecords from './QuoteRecords';
import SurveyFormPdfViewer from '@pages/EventManagementPage/SurveyForm/SurveyFormPdfViewer';

const ViewCustomer = () => {
  const { state } = useLocation();
  const comingFromPage = state?.comingFromPage;

  return (
    <Flex direction="column" gap={20}>
      <SurveyFormPdfViewer />
      <CustomerDetails comingFromPage={comingFromPage} />
      <QuoteRecords />
    </Flex>
  );
};

export default ViewCustomer;
