import React, { useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4Typography } from '@constant/typography/Typography';
import {
  EditMeasurementType,
  getMenuDetails,
  RestrictionListType,
  measurementValidationSchema,
  updateMenuNames
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Formik, FormikProps } from 'formik';
import MenuManagementEditableVirtualList from './MenuManagementEditableVirtualList';
import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';
import ScrollToFieldError from '@utils/formikFocus';

const Measurements = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [measurements, setMeasurements] = useState<RestrictionListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<RestrictionListType[] | null>(null);
  const [scrollToBottomOfList, setScrollToBottomOfList] = useState<boolean>(false);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getMeasurementsHandler();
  }, []);

  const getMeasurementsHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getMenuDetails('measurements');
    setPageLoading(false);
    if (!result.error) {
      setMeasurements(result.data || []);
    }
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getMeasurementsHandler();
    onModalCancelHandler();
  };

  const onMeasurementDelete = (
    index: number,
    ingridentsArray: any,
    setFieldValue: any,
    id?: number | null,
    is_associated?: boolean
  ) => {
    if (is_associated) {
      showErrorToast({
        message: 'Measurement is used with ingredient so it will not deleted',
        description: ''
      });
      return false;
    } else {
      if (id) {
        showToast({ message: 'Measurement Removed Successfully', description: '' });
      }
      const tempArr = [...JSON.parse(JSON.stringify(ingridentsArray))];
      tempArr?.splice(index, 1);
      setFieldValue('measurements', tempArr);
      return true;
    }
  };

  const onSubmitHandler = (values: EditMeasurementType) => {
    setSubmitValue(values.measurements);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateMenuNametHandler = async () => {
    if (!submitValue) return;
    setLoading(true);
    const result = await updateMenuNames({ measurements: submitValue }, 'measurements');
    setLoading(false);
    if (!result.error) {
      showToast({ message: 'Measurement Updated Successfully', description: '' });
      onCancelHandler();
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Measurement',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Flex bottom={26}>
          <H4Typography>MEASURMENTS</H4Typography>
        </Flex>
        <Formik
          initialValues={{ measurements: measurements }}
          validationSchema={measurementValidationSchema}
          onSubmit={onSubmitHandler}>
          {(formikProps: FormikProps<any>) => {
            const { values, setFieldValue, handleSubmit } = formikProps;
            return (
              <Flex direction="column">
                <ScrollToFieldError formik={formikProps} />
                <MenuManagementEditableVirtualList
                  formik={formikProps}
                  onItemDelete={onMeasurementDelete}
                  fieldArrayName="measurements"
                  inputFieldPlaceHolder="Measurement Name"
                  scrollToBottomOfList={scrollToBottomOfList}
                  setScrollToBottomOfList={setScrollToBottomOfList}
                />
                <Flex bottom={24}>
                  <Button
                    text="Add Measurement"
                    variant="secondary"
                    icon={<img width={20} src={PlusCircle} alt="add" />}
                    onClick={() => {
                      setFieldValue('measurements', [...values.measurements, { name: '', id: '' }]);
                      setScrollToBottomOfList(true);
                    }}
                  />
                </Flex>
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8}>
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button text="Submit" onClick={handleSubmit} isLoading={loading} />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Saving will impact existing entries."
          onOK={updateMenuNametHandler}
          isModalLoading={loading}
          okText="Okay">
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <div>
      <Flex bottom={26}>
        <H4Typography>MEASURMENTS</H4Typography>
      </Flex>
      <Flex style={{ flexFlow: 'wrap', gap: '16px 40px' }}>
        {measurements.map((measurement) => (
          <Flex key={measurement.id}>
            <p
              style={{
                backgroundColor: '#F4F4F4',
                borderRadius: '5px',
                padding: '5px 10px',
                margin: 0
              }}>
              {measurement.name}
            </p>
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

export default Measurements;
