import { Col, Divider, Row, Select } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { Button } from '@atom/Buttons';
import { Label } from '@atom/FormLable';
import { GoogleAutoComplete } from '@atom/GoogleAutoComplete';
import { Input } from '@atom/Input';
import InputPhone from '@atom/InputPhone';
import SelectInput from '@atom/Select';
import { TextAreaInput } from '@atom/TextArea';
import { H1Typography } from '@constant/typography/Typography';
import { CUSTOMER_TYPE_ENUM, CUSTOMER_TYPES } from '@constants/userConstants';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { getLocationByPlaceId } from '@utils/googlemaphealper';
import { showErrorToast, showToast } from '@utils/index';

import { customerInitialValues, customerSchema, getCustomerDetails, saveCustomer } from './helper';
import RepresentativeContainer from './RepresentativeContainer';
import CenterSpiner from '@atom/CenterSpiner';
import { Flex } from '@atom/Flex';
import CustomerBulkImport from './CustomerBulkImport';
import ScrollToFieldError from '@utils/formikFocus';
import { VersionHistoryWrapper } from './style';

const { Option } = Select;

function AddEditCustomerPage(props: any) {
  const { isBulk } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isValidating } = useSWR(['/customer/detail', id], getCustomerDetails, {
    revalidateOnFocus: false
  });

  const onCustomerSubmit = async (values: any) => {
    try {
      const res = (await saveCustomer({
        ...values
      })) as any;
      if (res?.data?.success) {
        showToast({
          message: `Customer ${
            location?.pathname.includes('edit') ? 'Details Edited' : 'Created'
          } Successfully`,
          description: ''
        });
        navigate('/customers');
      } else {
        showErrorToast({
          message: 'Something went wrong!',
          description: ''
        });
      }
    } catch (error) {
      //@ts-ignore
      const validationErrors = error.errors;
      if (validationErrors) {
        formik.setErrors({ ...validationErrors });
        return;
      }

      //@ts-ignore
      const errorMessage = error?.error?.message;

      showErrorToast({
        message: errorMessage ? errorMessage : 'Something went wrong!',
        description: ''
      });
    }
  };

  const formik = useFormik({
    validationSchema: customerSchema,
    onSubmit: onCustomerSubmit,
    initialValues: id && data ? data : customerInitialValues,
    enableReinitialize: true
  });

  const onPlaceSelect = async (placeId: string) => {
    if (placeId) {
      const place: any = await getLocationByPlaceId(placeId);
      if (place) {
        formik.setFieldValue('latitude', place?.geometry?.location.lat()?.toString());
        formik.setFieldValue('longitude', place?.geometry?.location.lng()?.toString());
      }
    }
  };
  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } = formik;
  const isCompany = values.customer_type?.toString() === CUSTOMER_TYPE_ENUM.COMPANY;

  return (
    <PageLayout>
      {isBulk ? (
        <CustomerBulkImport />
      ) : (
        <>
          <div id="map" />
          <PageTitle text="CUSTOMER MANAGEMENT" isBack bordered={true} path="/customers/" />
          <H1Typography>{id ? 'EDIT CUSTOMER' : 'ADD NEW CUSTOMER'}</H1Typography>
          {isValidating ? (
            <Flex direction={'column'} justifyContent={'center'} flex={1}>
              <CenterSpiner />
            </Flex>
          ) : (
            <>
              <ScrollToFieldError formik={formik} />
              <Row style={{ marginTop: 20 }}>
                <Col lg={12} xs={24}>
                  <Label text="Customer Type" isMandatory />
                  <VersionHistoryWrapper>
                    <SelectInput
                      onChange={(e: any) => {
                        setFieldValue('customer_type', e);
                      }}
                      value={values.customer_type?.toString()}
                      id={'customer_type'}
                      name={'customer_type'}
                      placeholdertitle="Select One"
                      error={touched.customer_type && errors.customer_type}
                    >
                      <Option
                        style={{
                          color: '#999',
                          fontSize: '16px',
                          padding: '6px 6px 6px 12px',
                          fontWeight: '400'
                        }}
                        value="disabled"
                        disabled
                      >
                        <>Select One</>
                      </Option>
                      {Object.keys(CUSTOMER_TYPES)
                        .reverse()
                        .map((i: string) => {
                          return (
                            <Option key={i} value={CUSTOMER_TYPE_ENUM[i]}>
                              {CUSTOMER_TYPES[i]}
                            </Option>
                          );
                        })}
                    </SelectInput>
                  </VersionHistoryWrapper>
                </Col>
              </Row>
              <Row gutter={16}>
                {isCompany && (
                  <Col md={16} xs={24}>
                    <Label text={`${isCompany ? 'Company ' : ''}Name`} isMandatory />
                    <Input
                      id={`company_name`}
                      name={`company_name`}
                      placeholder={'Type Name'}
                      onChange={handleChange}
                      error={touched.company_name && errors.company_name}
                      value={values?.company_name}
                    />
                  </Col>
                )}

                <Col md={12} xs={24}>
                  <Label text={`${isCompany ? 'Company Contact Name' : 'Name'}`} isMandatory />
                  <Input
                    id={'name'}
                    name={'name'}
                    placeholder={'Type name'}
                    onChange={handleChange}
                    error={touched.name && errors.name}
                    value={values?.name}
                  />
                </Col>

                <Col md={12} xs={24}>
                  <Label text={`${isCompany ? 'Contact Email' : 'Email'}`} isMandatory={false} />
                  <Input
                    id={'email'}
                    name={'email'}
                    placeholder={'Type email'}
                    onChange={handleChange}
                    error={touched.email && errors.email}
                    value={values?.email}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Label text={`${isCompany ? 'Contact Phone Number' : 'Phone Number'}`} />
                  <InputPhone
                    min={'0'}
                    max={'100'}
                    id={'phone_number'}
                    name={'phone_number'}
                    value={{
                      code: values.country_code || '+1',
                      phone: values.phone_number,
                      ext: values.extension ?? ''
                    }}
                    //@ts-ignore
                    error={touched.phone_number && errors.phone_number ? errors.phone_number : ''}
                    onChange={(e: any) => {
                      setFieldValue('country_code', e.code);
                      setFieldValue('phone_number', e.phone);
                      setFieldValue('extension', e.ext);
                    }}
                  />
                </Col>

                <Col md={12} xs={24}>
                  <Label text="Billing Address" />
                  <GoogleAutoComplete
                    id="address"
                    name="address"
                    placeholder="Type Address"
                    value={values?.address}
                    onPlaceSelect={(place: any) => {
                      if (place) {
                        onPlaceSelect(place.place_id);
                      }
                    }}
                    onChange={(address: string) => {
                      setFieldValue('address', address);
                    }}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col md={24} xs={24}>
                  <Label text={'Additional Notes'} />
                  <TextAreaInput
                    id={'additional_notes'}
                    name={'additional_notes'}
                    placeholder={'Additional Notes'}
                    value={values.additional_notes}
                    onChange={handleChange}
                    error={touched.additional_notes && errors.additional_notes}
                    maxLength={300}
                    showCount
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <H1Typography>Representatives</H1Typography>
                <Divider />
              </Row>
              <FormikProvider value={formik}>
                <RepresentativeContainer
                  representatives={
                    values.representatives.length
                      ? values.representatives
                      : customerInitialValues.representatives
                  }
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </FormikProvider>

              <Flex className="footerButton fix-mobile">
                <Button
                  text={'Cancel'}
                  variant={'secondary'}
                  onClick={() => navigate('/customers')}
                />
                <Button
                  text={'Submit'}
                  onClick={handleSubmit}
                  isLoading={formik.isValidating || formik.isSubmitting}
                />
              </Flex>
            </>
          )}
        </>
      )}
    </PageLayout>
  );
}

export default AddEditCustomerPage;
