import { Col, Divider, Row } from 'antd';
import { useEffect, useState } from 'react';

import { Input } from '@atom/Input';
import { H3Typography, H4Typography } from '@constant/typography/Typography';
import { TableWrapper } from '@pages/MenuManagementPage/style';
import { BundleDetailsTableProps } from '@pages/MenuManagementPage/types';

import DataTable from '../../../../shared/organisms/DataTable/DataTable';
import { bundleProductsColumns, getMappedTableData } from './helper';
import { TotalCostLabel } from './style';
import { useParams } from 'react-router';

function BundleDetailsTable(props: BundleDetailsTableProps) {
  const { id } = useParams();
  const { formik, item, index, dropdownData } = props;
  const { values, setFieldValue, setTouched, touched, errors } = formik;

  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const currentBundle = dropdownData?.bundlesList?.find((i: any) => i?.id === item?.bundle_id);
  const [totalCost, setTotalCost] = useState<number | undefined>(undefined);

  useEffect(() => {
    const lastBundleProduct = {
      ...currentBundle?.bundle_products?.[currentBundle?.bundle_products?.length - 1]
    };
    if (id && lastBundleProduct?.product_id.toString() !== id.toString())
      bundleTotalCostCalculateHandler('0', '0');
    else if (id && lastBundleProduct?.product_id.toString() === id.toString())
      bundleTotalCostCalculateHandler(
        lastBundleProduct?.product_cost,
        lastBundleProduct?.quantity_per_product
      );
  }, []);

  const bundleTotalCostCalculateHandler = (
    quantity_per_product: string | number,
    product_cost: string | number
  ) => {
    let currentCost = 0;
    currentBundle?.bundle_products?.forEach((i: any) => {
      if (id?.toString() !== i?.product_id?.toString()) currentCost += Number(i?.total_cost);
    });

    if (quantity_per_product && product_cost) {
      currentCost = currentCost + Number(quantity_per_product) * Number(product_cost);
      setTotalCost(currentCost);
      handleUpdate(currentCost, 'bundle_cost');
    } else {
      setTotalCost(undefined);
      handleUpdate(currentCost, 'bundle_cost');
    }
  };

  const handleUpdate = (data: any, key: any) => {
    const tempBundle = values?.bundles ? [...values.bundles] : [];
    tempBundle[index][key] = data;
    setFieldValue('bundles', tempBundle);
  };

  const handleUpdateBlur = (key: any) => {
    const temptouched: any = touched ? { ...touched } : {};
    temptouched.bundles = temptouched?.bundles || Array.from({ length: index + 1 }, () => ({}));
    temptouched.bundles =
      index < temptouched?.bundles?.length
        ? temptouched?.bundles
        : [...temptouched?.bundles, ...Array.from({ length: index - (length - 1) }, () => ({}))];
    temptouched.bundles[index][key] = true;
    setTouched(temptouched);
  };

  const tableData = getMappedTableData(
    currentBundle?.bundle_products,
    values,
    bundleTotalCostCalculateHandler,
    handleUpdate,
    handleUpdateBlur,
    touched,
    errors,
    item,
    index,
    totalCost,
    id
  );

  return (
    <>
      <H3Typography>{currentBundle?.bundle_name || ''}</H3Typography>
      <Divider />
      <TableWrapper>
        <DataTable
          loading={isTableLoading}
          columns={bundleProductsColumns}
          showLoadMore={false}
          nextPageURL={''}
          dataSource={tableData}
          pagination={false}
          onFetch={() => {}}
          scrollConfig={{
            y: tableData?.length ? 'calc(100vh - 350px)' : 'auto'
          }}
          renderEmpty={() => {
            return <>No Data Found</>;
          }}
        />
      </TableWrapper>
    </>
  );
}

export default BundleDetailsTable;
