import * as Yup from 'yup';

export const othersFormSchema = Yup.object().shape({
  id: Yup.mixed().nullable(),
  unique_invoice_string: Yup.mixed().nullable(),
  quotation_no: Yup.mixed().nullable(),
  from_event: Yup.mixed().nullable(),
  status: Yup.mixed().nullable(),
  event_dates: Yup.object().shape({
    id: Yup.mixed().nullable(),
    quotation_id: Yup.mixed().nullable(),
    event_date: Yup.mixed().nullable(),
    start_time: Yup.mixed().nullable(),
    date_string: Yup.mixed().nullable(),
    end_time: Yup.mixed().nullable(),
    other_details: Yup.array().of(
      Yup.object().shape({
        item_description: Yup.string().nullable(),
        rate: Yup.number()
          .when('item_description', {
            is: (value: string) => value && value,
            then: Yup.number()
              .required('The rate field is required.')
              .typeError('Rate must be a number')
          })
          .nullable()
          .typeError('Qty must be a number'),
        quantity: Yup.number()
          .when('item_description', {
            is: (value: string) => value && value,
            then: Yup.number()
              .required('The quantity field is required.')
              .typeError('Qty must be a number')
          })
          .nullable()
          .typeError('Qty must be a number'),
        total_cost: Yup.number().nullable(),
        waived_off: Yup.boolean().nullable(),
        toggle_tags: Yup.boolean().nullable(),
        public_tags: Yup.array().of(Yup.string()).nullable()
      })
    )
  })
});

export const otherSingleItem = {
  item_description: '',
  quantity: 0,
  rate: 0,
  total_cost: '',
  waived_off: false,
  toggle_tags: false,
  public_tags: ''
};

export const getOtherSingleItem = (prevData: any, newData: any) => {
  if (!!newData?.length) return newData;
  else if (!!prevData?.length) return prevData;
  else return [otherSingleItem];
};

export const FORM_INITIAL_VALUES = {
  id: undefined,
  unique_invoice_string: undefined,
  quotation_no: undefined,
  from_event: 0,
  status: 1,
  event_dates: {
    id: undefined,
    quotation_id: undefined,
    event_date: undefined,
    start_time: undefined,
    end_time: undefined,
    date_string: undefined,
    other_details: [
      {
        item_description: undefined,
        quantity: undefined,
        total_cost: undefined,
        rate: undefined,
        toggle_tags: false,
        public_tags: undefined
      }
    ]
  }
};
