import { MinusCircleOutlined } from '@ant-design/icons';
import { Value } from '@atom/FormDataView';
import { H5Typography } from '@constant/typography/Typography';
import styled from 'styled-components';

export const CustomFormValue = styled(Value)`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000e3;
  text-transform: none;
`;

export const RemoveIcon = styled(MinusCircleOutlined)`
  margin-left: 15px;
  font-size: 20px;
  cursor: pointer;
`;

export const AddNotesText = styled(H5Typography)`
  font-size: 1rem;
  font-weight: 900;
  cursor: pointer;
`;

export const AddExtraText = styled(H5Typography)`
  font-size: 1rem;
  font-weight: 900;
  // cursor: pointer;
  color: red;
`;

export const SelectLabelText = styled.div`
  text-align: left
`
