import services from '@services/index';
import { IStaffManagementListFilterType } from './types';
export enum STAFF_MANAGEMENT_TABS {
  UPCOMING = 'upcoming',
  PAST = 'past',
  MY_SCHEDULE = 'my-schedule'
}

export const getStaffList = async (page: number, data: IStaffManagementListFilterType) => {
  try {
    const res: any = await services.post(`/staffs/view?page=${page || 1}`, data);
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
