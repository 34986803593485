import { Button } from '@atom/Buttons';
import DropdownButton from '@atom/DropdownButton';
import PageLayout from '@layout/PageLayout';
import { DateFilter } from '@molecules/DateFilter';
import HorizontalTab from '@molecules/HorizontalTabs';
import { PageTitle } from '@molecules/PageTitle';
import { localStorageUtil } from '@utils/localstorageUtil';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { CREATE_EVENT_TAB_KEYS } from '../AddEditEvent/helper';
import { HorizontalTabWrapper } from '../style';
import { BASE_URL_GENERATE_REPORT, GENERATE_REPORT_URL, getEventListTabs } from './helper';

function EventList() {
  const startingDate = localStorage.getItem('start_date') ?? '';
  const [startDate, setStartDate] = useState(startingDate);
  const endingDate = localStorage.getItem('end_date') ?? '';
  const [endDate, setEndDate] = useState(endingDate);
  const [callApi, setCallApi] = useState<boolean>(false);

  const { tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin, user_id } = user;

  useEffect(() => {
    if (!tab) {
      navigate('/events/upcoming');
    }
  }, [tab]);
  const eventListTabs = getEventListTabs(tab || '', startDate, endDate, callApi, setCallApi);

  const getReport = async () => {
    const userId = btoa(user_id?.toString());
    window.open(BASE_URL_GENERATE_REPORT + `/${startDate}/${endDate}?user_id=${userId}`, '_blank');
    // if (key === 'this_week') {
    //   window.open(GENERATE_REPORT_URL.THIS_WEEK, '_blank');
    // } else {
    //   window.open(GENERATE_REPORT_URL.NEXT_WEEK, '_blank');
    // }
  };

  const pathKey = location?.pathname?.split('/')[1];

  return (
    <PageLayout>
      <Row gutter={24}>
        <Col
          lg={isAdmin ? 10 : 20}
          md={isAdmin ? 12 : 20}
          xs={24}
          style={{ alignItems: 'center', verticalAlign: 'middle' }}>
          <Row>
            <Col lg={24} md={24} xs={24}>
              <PageTitle text="EVENT MANAGEMENT"></PageTitle>
            </Col>
          </Row>
        </Col>
        <Col lg={isAdmin ? 14 : 4} md={isAdmin ? 12 : 4} xs={24}>
          <Row gutter={[16, 16]} style={{ marginBottom: '1.5rem', justifyContent: 'end' }}>
            <Col lg={isAdmin ? 6 : 24} xs={24}>
              <Button
                style={{ fontSize: '16px' }}
                fullWidth
                text={'General Documents'}
                onClick={() => navigate('/events/documents')}
              />
            </Col>
            {isAdmin && (
              <>
                {/* generate report dropdown */}
                {/* <Col lg={6} xs={24}>
                  <DropdownButton
                    text={'Generate Report'}
                    options={[
                      { key: 'this_week', label: 'This Week' },
                      { key: 'next_week', label: 'Next Week' }
                    ]}
                    onSelect={(item) => {
                      generateReport(item.key);
                    }}
                  />
                </Col> */}
                <Col lg={6} xs={24}>
                  <DropdownButton
                    text={'Create Event'}
                    options={[
                      { key: 'existing_quote', label: 'Use Existing Quote' },
                      { key: 'without_quote', label: 'Without a Quote' }
                    ]}
                    onSelect={(item) => {
                      if (item.key === 'existing_quote') {
                        localStorageUtil.setValue('location', {
                          state: { prevPath: location?.pathname, path: pathKey }
                        });
                        navigate('/events/find-quote', {
                          state: { prevPath: location?.pathname, path: pathKey }
                        });
                      } else {
                        localStorageUtil.setValue('location', {
                          state: { prevPath: location?.pathname, path: pathKey }
                        });
                        navigate(`/events/create/${CREATE_EVENT_TAB_KEYS.CUSTOMER}`, {
                          state: { prevPath: location?.pathname, path: pathKey }
                        });
                      }
                    }}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            setCallApi={setCallApi}
            generateReport={getReport}
            isEventManagementPage={true}
          />
        </Col>
      </Row>
      <div
        style={{
          marginTop: '15px',
          background: '#E0E0E0',
          marginBottom: '15px',
          height: '1px'
        }}
      />
      <Row gutter={24}>
        <Col span={24}>
          <HorizontalTabWrapper>
            <HorizontalTab
              value={eventListTabs}
              activeKey={tab}
              onChange={(tab: any) => {
                let newurl = `/events/${tab}`;
                navigate(newurl);
              }}
            />
          </HorizontalTabWrapper>
        </Col>
      </Row>
    </PageLayout>
  );
}

export default EventList;
