import styled from 'styled-components';

export const ServiceFeeWrapper = styled.div`
  .service-fee-style {
    margin-top: 40px;
  }
  @media (max-width: 576px) {
    .service-fee-style {
      margin-top: 10px;
    }
  }
`;
