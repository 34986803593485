import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import CenterSpiner from '@atom/CenterSpiner';
import { Label } from '@atom/FormLable';
import Checkbox from '@atom/Checkbox';
import { PageTitle } from '@molecules/PageTitle';
import { FilePreviewList } from '@molecules/FilePreview/style';
import { CustomFileDragger } from '@molecules/FileDraggerComponent';
import AddEditQuoteFooter from '@pages/QuoteManagementPage/AddEditQuote/AddEditQuoteFooter';
import PageLayout from '@layout/PageLayout';

import DeleteIcon from '@assets/Delete.svg';
import FilesImage from '@assets/filesImage.png';
import DownloadImage from '@assets/downloadImage.png';
import { EditPencilIcon, Verified, WrongIcon } from '@assets/index';
import { H4Typography } from '@constant/typography/Typography';
import { FILE_TYPES } from '@constants/userConstants';
import { uploadFileHandler } from '@services/fileService';
import { showErrorToast, showToast } from '@utils/index';

import { GeneralDocType, getGeneralDocList, updateGeneralDocList } from './helper';
import { GenralDocumentWrapper } from '../style';
import { useSelector } from 'react-redux';
import BreadCrumbs from '@atom/BreadCrumb';
import { EventGeneralDocBreadCrumbs } from '../EventDetails/helper';

const GeneralDocuments = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [documentsList, setDocumentsList] = useState<GeneralDocType[]>([]);
  const user = useSelector(
    (state: { user: { isAdmin: boolean; isManager: boolean; isEmployee: boolean } }) => state.user
  );
  const { isAdmin, isManager, isEmployee } = user;

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setPageLoading(true);
      const result = await getGeneralDocList();
      setPageLoading(false);
      if (!result.error) {
        setDocumentsList(result.data || []);
      }
    })();
  }, []);

  const onFileUpload = async (file: File) => {
    setFileUploading(true);
    const result = (await uploadFileHandler(file, FILE_TYPES.DOCUMENTS)) as any;
    setFileUploading(false);
    if (result.error) {
      return showErrorToast({
        message: `Unable to upload document`,
        description: result.error || 'Please try again'
      });
    }
    if (result?.data?.url) {
      setDocumentsList((prev) => [
        ...prev,
        {
          id: null,
          document_name: file.name,
          document_url: result?.data?.url,
          manager_view: 0,
          employee_view: 0
        }
      ]);
    }
  };

  const viewChangeHandler = (index: number, view: 'manager' | 'employee') => {
    const updatedDocumentsList = [...documentsList];
    const key = view === 'manager' ? 'manager_view' : 'employee_view';
    updatedDocumentsList[index] = {
      ...updatedDocumentsList[index],
      [key]: updatedDocumentsList[index][key] === 0 ? 1 : 0
    };
    setDocumentsList(updatedDocumentsList);
  };

  const cancelHandler = () => {
    setIsEdit(false);
  };

  const deleteDocumentHandler = (index: number) => {
    if (fileUploading) return;
    const updatedDocumentsList = [...documentsList];
    updatedDocumentsList.splice(index, 1);
    setDocumentsList(updatedDocumentsList);
  };

  const onSaveHandler = async () => {
    setLoading(true);
    const result = await updateGeneralDocList({ 'general-documents': documentsList });
    setLoading(false);
    if (!result.error) {
      showToast({ message: 'Documents Updated Successfully', description: '' });
      setIsEdit(false);
      navigate('/events/documents');
    } else {
      showErrorToast({
        message: 'Error Saving Documents',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <PageLayout>
      <Row gutter={24}>
        <BreadCrumbs routes={EventGeneralDocBreadCrumbs()} />
        <Col span={24} style={{ marginTop: '15px' }}>
          <PageTitle
            isBottom={true}
            text="EVENT MANAGEMENT"
            isBack={false}
            path="/events"
            bordered={true}>
            {!isEdit && isAdmin && (
              <Button
                variant="ghost"
                icon={<img src={EditPencilIcon} alt="edit" />}
                text="Edit"
                onClick={() => setIsEdit(true)}
              />
            )}
          </PageTitle>
        </Col>
        <Col md={24}>
          {isEdit && (
            <Flex top={10} direction="column">
              <Label text="File" isMandatory />
              <CustomFileDragger
                onChangeHandle={onFileUpload}
                loading={fileUploading}
                maxLimit={20000000} // 20 MB
                multiple={true}
                showUploadList={false}
              />
            </Flex>
          )}
          {documentsList.map((doc, index) => {
            return (
              (isAdmin || (isManager && doc?.manager_view) || (isEmployee && doc?.employee_view)) ?
                <GenralDocumentWrapper>
                  <FilePreviewList>
                    <Row gutter={16} style={{ width: '100%' }}>
                      <Col lg={12} sm={12} md={12} xs={24}>
                        <Row
                          gutter={16}
                          className="file-image-fixing"
                          style={{ alignItems: 'center' }}>
                          <Col lg={2} md={2} xs={2}>
                            <img width={16} height={16} src={FilesImage} alt="file" />
                          </Col>

                          <Col lg={22} md={22} xs={22}>
                            {doc?.document_name}
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        lg={10}
                        sm={10}
                        md={10}
                        xs={22}
                        style={{ justifyContent: 'center', display: 'flex', width: '100%' }}
                        className="mobile-style">
                        {isEdit ? (
                          <Row style={{ alignItems: 'center', width: '100%' }}>
                            <Col md={24} xs={12}>
                              <Checkbox
                                name="Manager View"
                                id={`manager-view-${index}`}
                                onChange={() => viewChangeHandler(index, 'manager')}
                                checked={doc.manager_view}
                              />
                            </Col>
                            <Col md={24} xs={12}>
                              <Checkbox
                                name="Employee View"
                                id={`employee-view-${index}`}
                                onChange={() => viewChangeHandler(index, 'employee')}
                                checked={doc.employee_view}
                              />
                            </Col>
                          </Row>
                        ) : (
                          isAdmin && (
                            <Row style={{ alignItems: 'center' }} gutter={16}>
                              <Col md={12} lg={12} xs={12}>
                                <Row gutter={24}>
                                  <Col md={4} xs={4}>
                                    {doc.manager_view === 0 && (
                                      <img
                                        src={WrongIcon}
                                        alt="wrongIcon"
                                        style={{ height: '16px' }}
                                      />
                                    )}
                                    {doc.manager_view === 1 && (
                                      <img src={Verified} alt="verified" style={{ height: '16px' }} />
                                    )}
                                  </Col>
                                  <Col md={20} xs={20}>
                                    <H4Typography style={{ fontWeight: 'normal' }}>
                                      Manager View
                                    </H4Typography>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={12} lg={12} xs={12}>
                                <Row gutter={24}>
                                  <Col md={4} xs={4}>
                                    {doc.employee_view === 0 && (
                                      <img
                                        src={WrongIcon}
                                        alt="wrongIcon"
                                        style={{ height: '16px' }}
                                      />
                                    )}
                                    {doc.employee_view === 1 && (
                                      <img src={Verified} alt="verified" style={{ height: '16px' }} />
                                    )}
                                  </Col>
                                  <Col md={20} xs={20}>
                                    <H4Typography style={{ fontWeight: 'normal' }}>
                                      Employee View
                                    </H4Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )
                        )}
                      </Col>
                      <Col
                        lg={2}
                        sm={2}
                        md={2}
                        xs={24}
                        style={{ justifyContent: 'end', display: 'flex' }}
                        className="mobile-style">
                        <Row style={{ alignItems: 'center' }}>
                          {isEdit ? (
                            <Col md={24}>
                              <img
                                src={DeleteIcon}
                                alt="delete"
                                style={{ cursor: fileUploading ? 'not-allowed' : 'pointer' }}
                                onClick={() => deleteDocumentHandler(index)}
                              />
                            </Col>
                          ) : (
                            <Col md={24}>
                              <img
                                src={DownloadImage}
                                alt="download"
                                style={{ cursor: 'pointer' }}
                                onClick={() => window.open(doc.document_url, '_blank')}
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </FilePreviewList>
                </GenralDocumentWrapper> : ""
            );
          })}
        </Col>
      </Row>
      {isEdit && (
        <AddEditQuoteFooter
          cancelClickHandler={cancelHandler}
          nextButtonTitle="Save"
          nextClickHandler={onSaveHandler}
          isSubmitting={loading}
        // isNextButtonDisabled={!isValid}
        />
      )}
    </PageLayout>
  );
};

export default GeneralDocuments;
