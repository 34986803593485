import * as yup from 'yup';
import services from '@services/index';
import { ResponseType } from '../helper';
import { digitsRegExp, floatingPointRegExp } from '@constants/index';

// types
export interface PositionsType {
  id: number | null;
  name: string;
  rate: string;
  department_id?: number;
  deleted_at?: string | null;
}

export interface DepartmentsType {
  id: number;
  name: string;
  deleted_at: string | null;
  positions: PositionsType[];
}

export interface EditDepartmentType {
  departments: DepartmentsType[];
}

// api calls
export const getDepartmentList = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings/departments`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateDepartmentList = async (body: EditDepartmentType): Promise<ResponseType> => {
  try {
    const res = (await services.post(`/settings/departments`, body)) as any;
    return { data: res?.data?.data, error: null, message: res?.data?.message };
  } catch (error: any) {
    const key = error?.error?.error ? Object.keys(error?.error?.error)?.[0] : undefined;
    return {
      data: null,
      error: key ? error?.error?.error?.[key][0] : error?.error?.message || 'Something went wrong!'
    };
  }
};

export const removeDepartment = async (id: number, isPosition = false): Promise<ResponseType> => {
  try {
    const res = (await services.delete(
      `/settings/departments/${isPosition ? 'position/' : ''}${id}`
    )) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

// yup validations
export const deptValidationSchema = yup.object({
  departments: yup.array().of(
    yup.object({
      name: yup.string().required('Department Name is required.').nullable(),
      positions: yup.array().of(
        yup.object({
          name: yup.string().required('Position Name is required.').nullable(),
          rate: yup
            .string()
            .matches(floatingPointRegExp, 'Only Positive numbers are allowed')
            .required('Rate is required.')
            .nullable()
        })
      )
    })
  )
});

export const duplicateDepartmentErrorMessage = 'Department name must be unique';
export const duplicatePositionErrorMessage = 'Position name must be unique';
