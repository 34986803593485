import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import FileUploadButton from '@atom/FileUploadButton';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import ImagePreviewer from '@atom/ImagePreviewer';
import { H4Typography, H5Typography } from '@constant/typography/Typography';
import { FILE_TYPES } from '@constants/userConstants';
import ModalComponent from '@organism/Modal/Modal';
import { getLogoList } from '@pages/GlobalSettingsPage/LogoLibrary/helper';
import { AddExtraText } from '@pages/QuoteManagementPage/AddEditQuote/Labours/style';
import { uploadFileHandler } from '@services/fileService';
import { getImageDimensions } from '@utils/helper';
import { showErrorToast } from '@utils/index';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { generatePdf, IMAGE_TYPE, imagePreviewStyle } from './helper';

interface GeneratePDFModalProps {
  isModalVisible: boolean;
  setShowModal: (value: boolean) => void;
  data: any;
  backupData: any;
  setData: (data: any) => void;
  updateQuoteData?: (quoteId: any) => void;
  openPDFsHandler?: (overview_pdf_link: string, proposal_pdf_link: string) => void;
}

const GeneratePDFModal = (props: GeneratePDFModalProps) => {
  const {
    isModalVisible,
    setShowModal,
    data,
    backupData,
    setData,
    updateQuoteData,
    openPDFsHandler
  } = props;
  const { quoteId } = useParams();
  const [isLogoLibrary, setisLogoLibrary] = useState<boolean>(false);
  const [logoList, setLogoList] = useState<Array<any>>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tempData, setTempData] = useState<any>({});
  const logoSize = 150;
  const handleCancel = () => {
    setData(backupData);
    setisLogoLibrary(false);
    setShowModal(false);
  };

  const getLogoListHandler = async () => {
    const result = await getLogoList();
    if (!result.error) {
      setLogoList(result.data || []);
    }
  };

  const onSelect = (logo: any) => {
    let data = { ...tempData };
    data.pdf_images[2].image = logo?.image_url;
    setTempData(data);
    setisLogoLibrary(false);
  };

  const onUploadImages = async (e: any, index: number) => {
    setLoading(true);
    const file = e?.target?.files?.[0];

    if (file) {
      const size: any = await getImageDimensions(window.URL.createObjectURL(file));
      if (index === IMAGE_TYPE.LOGO && (size?.width > logoSize || size?.height > logoSize)) {
        showErrorToast({
          message: 'Failed!',
          description: `Logo size should be ${logoSize}px`
        });
      } else {
        const res = (await uploadFileHandler(file, FILE_TYPES.PROFILE_PIC)) as any;
        const data = { ...tempData };
        data.pdf_images[index].image = res?.data?.url;
        setTempData(data);
      }
    } else {
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong'
      });
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setData(tempData);
      const res = (await generatePdf(tempData)) as any;
      if (res?.success) {
        updateQuoteData && updateQuoteData(quoteId);
        openPDFsHandler && openPDFsHandler(res?.data?.overview_url, res?.data?.proposal_url);
        setShowModal(false);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something Went Wrong'
        });
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong'
      });
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      getLogoListHandler();
      setTempData({ ...data });
    }
  }, [isModalVisible]);

  return (
    <ModalComponent
      visible={isModalVisible}
      onCancel={() => handleCancel()}
      modalWidth="calc(100vw - 150px) !important"
      align="left !important"
    >
      {loading ? (
        <CenterSpiner />
      ) : (
        <>
          <Flex alignItems="center" direction="column" bottom={38}>
            <H4Typography>
              {isLogoLibrary ? 'Select from Logo Library' : 'Add an Image to the PDF'}
            </H4Typography>
            <H5Typography>
              {isLogoLibrary
                ? logoList?.length > 0
                  ? 'Select a logo to add to the PDF'
                  : 'No logos are available!'
                : 'Upload a Cover Page, End Page and Logo to add to the PDF'}
            </H5Typography>
          </Flex>

          {!isLogoLibrary ? (
            <>
              <Row style={{ width: '100%' }} gutter={16} justify="center">
                <Col xs={24} md={8}>
                  <Flex justifyContent={'center'}>
                    <Label text="Cover Page" />
                  </Flex>
                  <Flex justifyContent={'center'}>
                    <ImagePreviewer
                      image={tempData?.pdf_images?.[0]?.image}
                      closable={false}
                      onClose={() => {}}
                      theme={{ wrapper: imagePreviewStyle }}
                    />
                  </Flex>
                  <Flex
                    top={10}
                    justifyContent="center"
                    gap={10}
                    direction="column"
                    style={{ textAlign: 'center', height: '45px' }}
                  >
                    <FileUploadButton
                      multiple
                      isDisable={isSubmitting}
                      text="Upload Image"
                      onUpload={(e: any) => onUploadImages(e, IMAGE_TYPE.COVER_PAGE)}
                    />
                  </Flex>
                </Col>
                <Col xs={24} md={8}>
                  <Flex justifyContent={'center'}>
                    <Label text="End Page" />
                  </Flex>
                  <Flex justifyContent={'center'}>
                    <ImagePreviewer
                      image={tempData?.pdf_images?.[1]?.image}
                      onClose={() => {}}
                      closable={false}
                      theme={{ wrapper: imagePreviewStyle }}
                    />
                  </Flex>
                  <Flex
                    justifyContent="center"
                    gap={10}
                    top={10}
                    direction="column"
                    style={{ textAlign: 'center', height: '45px' }}
                  >
                    <FileUploadButton
                      multiple
                      isDisable={isSubmitting}
                      text="Upload Image"
                      onUpload={(e: any) => onUploadImages(e, IMAGE_TYPE.END_PAGE)}
                    />
                  </Flex>
                </Col>
                <Col xs={24} md={8}>
                  <Flex justifyContent={'center'}>
                    <Label text="Logo" />
                  </Flex>
                  <Flex justifyContent={'center'}>
                    <ImagePreviewer
                      className={"transparent-background"}
                      image={tempData?.pdf_images?.[2]?.image}
                      onClose={() => {}}
                      closable={false}
                      theme={{ wrapper: imagePreviewStyle }}
                    />
                  </Flex>
                  <Flex
                    justifyContent="center"
                    gap={10}
                    top={10}
                    direction="column"
                    style={{ textAlign: 'center', height: '45px' }}
                  >
                    <FileUploadButton
                      multiple
                      text="Upload Image"
                      isDisable={isSubmitting}
                      onUpload={(e: any) => onUploadImages(e, IMAGE_TYPE.LOGO)}
                    />
                  </Flex>
                  <Flex
                    gap={10}
                    top={5}
                    direction="column"
                    style={{ textAlign: 'center', height: '45px' }}
                  >
                    <Button
                      variant="secondary"
                      isDisable={isSubmitting}
                      text="Select From Logo Library"
                      onClick={() => setisLogoLibrary(true)}
                    />
                  </Flex>
                </Col>
              </Row>
            </>
          ) : (
            <Flex top={16} style={{ flexFlow: 'wrap', gap: '40px' }}>
              {logoList?.map((logo) => (
                <ImagePreviewer
                  key={logo.id}
                  className={"transparent-background"}
                  image={logo.image_url}
                  closable={false}
                  onClick={() => onSelect(logo)}
                  theme={{ wrapper: { width: '150px', height: '150px' } }}
                />
              ))}
            </Flex>
          )}
          <Flex className="fix-mobile" justifyContent={'flex-end'} top={30}>
            <AddExtraText>Double check your logo, cover page and end page before saving</AddExtraText>
          </Flex>
          <Flex
            className="fix-mobile"
            justifyContent="flex-end"
            top={10}
            bottom={30}
            style={{ gap: '10px' }}
          >
            <Button
              variant="secondary"
              text={isLogoLibrary ? 'Back' : 'Cancel'}
              isDisable={isSubmitting}
              onClick={() => {
                isLogoLibrary ? setisLogoLibrary(false) : handleCancel();
              }}
              className="cancel-btn"
            />
            <Button
              variant="primary"
              text={'Submit'}
              isDisable={isLogoLibrary}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            />
          </Flex>
        </>
      )}
    </ModalComponent>
  );
};

export default GeneratePDFModal;
