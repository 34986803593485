import { localStorageUtil } from '@utils/localstorageUtil';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
function QuoteManagementPage() {
  useEffect(() => {
    return () => {
      if (!window?.location?.pathname?.includes('event')) {
        localStorageUtil.removeValue('location');
      }
    };
  }, []);
  return <Outlet />;
}

export default QuoteManagementPage;
