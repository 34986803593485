export enum STATUS {
  DRAFT = 0,
  ACTIVE = 1,
  INACTIVE = 2
}

export enum QUOTE_STATUS {
  DRAFT = 0,
  SAVED = 1,
  PENDING_APPROVAL = 2,
  APPROVED = 3,
  REJECTED = 4,
  CREATED_EVENTS = 5,
  EVENT_COMPLETED = 6,
  CONFIRMED_EVENTS = 7,
  CANCELED_EVENTS = 8
}

export const STATUS_NAME_COLORS: any = {
  [QUOTE_STATUS.DRAFT]: '#ff8a00',
  [QUOTE_STATUS.SAVED]: '#c4c4c4',
  [QUOTE_STATUS.PENDING_APPROVAL]: '#2f80ed',
  [QUOTE_STATUS.APPROVED]: '#27a03b',
  [QUOTE_STATUS.REJECTED]: '#FF0000',
  [QUOTE_STATUS.CREATED_EVENTS]: '#2f80ed',
  [QUOTE_STATUS.CONFIRMED_EVENTS]: '#27a03b',
  [QUOTE_STATUS.CANCELED_EVENTS]: '#FF0000'
};
