import ModalComponent from '@organism/Modal/Modal';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { H2Typography, H4Typography } from '@constant/typography/Typography';
import { showErrorToast, showToast } from '@utils/index';
import { getLogoList } from '@pages/GlobalSettingsPage/LogoLibrary/helper';
import { useEffect, useState } from 'react';
import ImagePreviewer from '@atom/ImagePreviewer';
import FileUploadButton from '@atom/FileUploadButton';
import { uploadFileHandler } from '@services/fileService';
// import CoverImg from '@assets/images/coverImg.jpg';
// import NoImage from '@assets/noimage.png';
import { Upload_Type } from '../constants';
import { dowloadEventPdf, getDownloadPdfData, getUniqueKey, imagePreviewStyle, uploadLogo } from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { QUOTE_PROPOSAL_IMAGES } from '@constants/index';
import { getImageDimensions } from '@utils/helper';
import { AddExtraText } from '@pages/QuoteManagementPage/AddEditQuote/Labours/style';

const DownloadPDFModal = (props: any) => {
  const { isDowloadPdfModalVisible, onCancel, setIsDowloadPdfModalVisible, data } = props;
  const [logoList, setLogoList] = useState<any[]>([]);
  const [isLogoImageUpload, setIsLogoImageUpload] = useState<boolean>(false);
  const [isCoverImageUpload, setIsCoverImageUpload] = useState<boolean>(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);
  const [isSelectFromGallery, setIsSelectFromGallery] = useState<boolean>(false);
  const [selectFromGallery, setSelectFromGallery] = useState<any>(undefined);
  const [logoImage, setLogoImage] = useState<any>(QUOTE_PROPOSAL_IMAGES.logo);
  const [coverImage, setCoverImage] = useState<any>(QUOTE_PROPOSAL_IMAGES.coverImage);
  const logoSize = 150;
  const { eventId } = useParams();

  useEffect(() => {
    getLogoListHandler();
    if (data?.event_pdf_images?.length) {
      data?.event_pdf_images.forEach((i: any) => {
        if (i.title === 'Cover Page') {
          setCoverImage(i?.image);
        } else if (i.title === 'Logo') {
          setLogoImage(i?.image);
        }
      });
    }
  }, []);

  const getLogoListHandler = async () => {
    const result = await getLogoList();
    if (!result.error) {
      setLogoList(result.data || []);
    }
  };

  const onUploadCoverImages = async (e: any, upload_type: number) => {
    const file = e?.target?.files[0];
    if (upload_type === Upload_Type.COVER) {
      setIsCoverImageUpload(true);
      const res = (await uploadFileHandler(file, 3)) as any;
      setIsCoverImageUpload(false);
      if (res?.error) {
        return showErrorToast({
          message: `Unable to upload cover image`,
          description: res.error || 'Please try again'
        });
      } else if (res?.data?.url) {
        setCoverImage(res?.data?.url);
      }
    } else {
      const objectUrl = URL.createObjectURL(file);
      const size: any = await getImageDimensions(objectUrl);
      if (size?.height <= logoSize && size?.width <= logoSize) {
        setIsLogoImageUpload(true);
        const res = (await uploadLogo(file)) as any;
        setIsLogoImageUpload(false);
        if (res?.error) {
          return showErrorToast({
            message: `Unable to upload cover image`,
            description: res.error || 'Please try again'
          });
        } else if (res?.data?.url) {
          setLogoImage(res?.data?.url);
        }
      } else {
        showErrorToast({
          message: 'Failed!',
          description: `Logo size should be ${logoSize}px`
        });
      }
    }
  };

  const cancelDownloadAsPdf = () => {
    setLogoImage(undefined);
    setIsSelectFromGallery(false);
    setIsDowloadPdfModalVisible(false);
  };

  const onBack = () => {
    setSelectFromGallery(undefined);
    setIsSelectFromGallery(false);
  };

  const onSelectFromGallery = () => {
    setLogoImage(selectFromGallery);
    setIsSelectFromGallery(false);
  };

  const onSave = async () => {
    setIsGeneratingPdf(true);
    const data = getDownloadPdfData(eventId, coverImage, logoImage);
    const res = await dowloadEventPdf(data, eventId);
    setIsGeneratingPdf(false);
    setSelectFromGallery(undefined);
    if (res?.error) {
      return showErrorToast({
        message: `Failed`,
        description: 'Please try again'
      });
    } else if (res?.data?.url) {
      setIsDowloadPdfModalVisible(false);
      window.open(res?.data?.url, '_blank');
    }
  };

  return (
    <>
      <ModalComponent
        visible={isDowloadPdfModalVisible}
        onCancel={onCancel}
        modalWidth="calc(100vw - 150px)"
        align="left !important"
      >
        <Row gutter={24} className="centerd">
          <Col lg={24} xs={24}>
            <Flex direction="column" gap={10} alignItems={'center'}>
              <H2Typography style={{ fontWeight: '400', textAlign: 'center' }}>
                {!isSelectFromGallery ? 'Add an Image to The Menu' : 'Select from Logo Library'}
              </H2Typography>
              <H4Typography style={{ fontWeight: '400', textAlign: 'center' }}>
                {!isSelectFromGallery
                  ? 'Select Cover Page or Logo, then Upload an image to add to the PDF'
                  : logoList?.length > 0
                  ? 'Select a logo to add to the PDF'
                  : 'No logos are available!'}
              </H4Typography>
            </Flex>
          </Col>
        </Row>
        {!isSelectFromGallery ? (
          <>
            <Row gutter={16}>
              <Col lg={8} xs={24}>
                <H4Typography>Cover Page</H4Typography>
                <Flex justifyContent={'center'}>
                  {isCoverImageUpload ? (
                    <CenterSpiner />
                  ) : (
                    <ImagePreviewer
                      image={coverImage}
                      closable={false}
                      onClose={() => {}}
                      theme={{ wrapper: imagePreviewStyle }}
                    />
                  )}
                </Flex>
                <Flex
                  gap={10}
                  top={10}
                  direction="column"
                  style={{ textAlign: 'center', height: '45px' }}
                >
                  <FileUploadButton
                    text="Upload Image"
                    onUpload={(e) => {
                      onUploadCoverImages(e, Upload_Type.COVER);
                    }}
                  />
                </Flex>
              </Col>
              <Col lg={8} xs={24}>
                <H4Typography>Logo</H4Typography>
                <Flex alignItems={'center'} direction="column">
                  {isLogoImageUpload ? (
                    <CenterSpiner />
                  ) : (
                    <ImagePreviewer
                      className={"transparent-background"}
                      image={logoImage}
                      closable={false}
                      onClose={() => {}}
                      theme={{ wrapper: imagePreviewStyle }}
                    />
                  )}
                </Flex>
                <Flex top={10} direction="column" style={{ textAlign: 'center', height: '45px' }}>
                  <FileUploadButton
                    text="Upload Image"
                    onUpload={(e) => {
                      onUploadCoverImages(e, Upload_Type.LOGO);
                    }}
                  />
                </Flex>
                <Flex direction="column">
                  <Button
                    text={'Select From logo library'}
                    variant={'secondary'}
                    style={{ width: '100%' }}
                    onClick={() => {
                      setIsSelectFromGallery(true);
                    }}
                  />
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col lg={24} xs={24}>
                <Flex className="fix-mobile" justifyContent={'flex-end'} top={30}>
                  <AddExtraText>Double check your logo and cover page before saving</AddExtraText>
                </Flex>
                <Flex className="fix-mobile" justifyContent={'flex-end'} gap={16} top={10}>
                  <Button text={'Cancel'} variant={'secondary'} onClick={cancelDownloadAsPdf} />
                  <Button text={isGeneratingPdf ? 'Generating PDF...' : 'Save'} onClick={onSave} />
                </Flex>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col lg={24} xs={24}>
                <Flex top={16} style={{ flexFlow: 'wrap', gap: '40px' }}>
                  {logoList.map((logo, index) => (
                    <ImagePreviewer
                      key={logo.id}
                      className={"transparent-background"}
                      image={logo.image_url}
                      onClick={() => {
                        logoList.map((l) => {
                          l.border = undefined;
                        });
                        logoList[index].border = '3px solid #27a03b';
                        setSelectFromGallery(logo?.image_url);
                      }}
                      theme={{
                        wrapper: {
                          width: '150px',
                          height: '150px',
                          border: `${logo?.border || 'none'}`
                        }
                      }}
                    />
                  ))}
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col lg={24} xs={24}>
                <Flex justifyContent={'flex-end'} gap={16} top={30}>
                  <Button text={'Back'} variant={'secondary'} onClick={onBack} />
                  <Button
                    text={'Save'}
                    isDisable={!logoList?.length}
                    onClick={onSelectFromGallery}
                  />
                </Flex>
              </Col>
            </Row>
          </>
        )}
      </ModalComponent>
    </>
  );
};

export default DownloadPDFModal;
