import DataTable from '@organism/DataTable/DataTable';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getEmployeeRecordsColumns, getPastRecords, getUpcomingRecords } from './helper';
import { DataTableWrapper } from './style';

interface EmployeeRecordTableProp {
  tab: string;
  currentTab: string;
}

interface RecordsType {
  date: string;
  event_name: string;
  customer_name: string;
  arrival_time: string;
}

const EmployeeRecordTable: FC<EmployeeRecordTableProp> = ({ currentTab, tab }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState<RecordsType[]>([]);

  const user = useSelector((state: { user: { user_id: string } }) => state.user);

  useEffect(() => {
    if (id) {
      (async () => {
        setLoading(true);
        if (currentTab === tab && currentTab === 'upcoming') {
          const result = await getUpcomingRecords(id, pageNo);
          setLoading(false);
          if (!result.error) {
            setTableData(result.data || []);
          }
        } else if (currentTab === tab && currentTab === 'past') {
          const result = await getPastRecords(id, pageNo);
          setLoading(false);
          if (!result.error) {
            setTableData(result.data || []);
          }
        }
      })();
    }
  }, [currentTab, id]);

  const columns = getEmployeeRecordsColumns(currentTab);

  return (
    <DataTableWrapper>
      <DataTable
        className="fixTD"
        loading={loading}
        columns={columns}
        showLoadMore={false}
        nextPageURL={''}
        dataSource={tableData}
        pagination={false}
        onFetch={() => {}}
        renderEmpty={() => <div>No Records found.</div>}
        scrollConfig={{
          y: tableData?.length ? 'calc(100vh - 350px)' : 'auto'
        }}
        onRowClick={() => {}}
      />
    </DataTableWrapper>
  );
};

export default EmployeeRecordTable;
