import CenterSpiner from '@atom/CenterSpiner';
import DataTable from '@organism/DataTable/DataTable';
import { quoteTableColumns } from './columns';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { getQuotations } from './helper';
import { useState } from 'react';

const QuotesTab = () => {
  const navigate = useNavigate();
  const columns = quoteTableColumns(navigate);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: quotationsData, isValidating: isValidatingQuotation } = useSWR(
    [`/customer/quotations?page=${currentPage}`, id],
    getQuotations,
    {
      revalidateOnFocus: false
    }
  );

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isValidatingQuotation ? (
        <CenterSpiner />
      ) : (
        <>
          <DataTable
            className="fixTD"
            columns={columns}
            showLoadMore={!!quotationsData?.nextPageUrl}
            dataSource={quotationsData?.data}
            nextPageURL={quotationsData?.nextPageUrl}
            pagination={{
              pageSize: quotationsData?.per_page,
              total: quotationsData?.total,
              current: quotationsData?.current_page,
              onChange: handlePaginationChange
            }}
            scrollConfig={{
              y: quotationsData?.data?.length ? 'calc(100vh - 350px)' : 'auto'
            }}
            renderEmpty={() => {
              return <p>No Quotations Found!</p>;
            }}
          />
        </>
      )}
    </>
  );
};

export default QuotesTab;
