import { CUSTOMER_TYPE_ENUM } from '@constants/userConstants';
import { phoneRegExp } from '@constants/index';
import services from '@services/index';
import * as yup from 'yup';

export const REPRESENTATIVE_ITEM = {
  country_code: '+1',
  country_iso_code: 'CA',
  email: '',
  extension: '',
  name: '',
  phone_number: '',
  localId: Math.random()
};
export const customerInitialValues = {
  name: '',
  email: '',
  country_code: '+1',
  country_iso_code: 'CA',
  company_name: '',
  additional_notes: '',
  address: '',
  customer_type: '',
  extension: '',
  latitude: '',
  longitude: '',
  phone_number: '',
  representatives: [{ ...REPRESENTATIVE_ITEM }]
};

export const customerSchema = yup.object().shape({
  company_name: yup.string().when(['customer_type'], {
    is: (customer_type: string) => customer_type?.toString() === CUSTOMER_TYPE_ENUM.COMPANY,
    then: yup.string().required('Company name is required').nullable(),
    otherwise: yup.string().nullable()
  }),

  name: yup.string().when(['customer_type'], {
    is: (customer_type: string) => customer_type?.toString() === CUSTOMER_TYPE_ENUM.COMPANY,
    then: yup.string().required('Company Contact Name is required').nullable(),
    otherwise: yup.string().required('Customer name is required').nullable()
  }),

  // email: yup.string().email('Enter valid email').required('Email is required').nullable(),
  email: yup.string().email('Enter valid email').nullable(),

  customer_type: yup.string().required('Please select customer type').nullable(),
  phone_number: yup.string().nullable().matches(phoneRegExp, 'Phone number is invalid'),
  latitude: yup.string().nullable(),
  longitude: yup.string().nullable(),
  address: yup.string().nullable(),
  additional_notes: yup.string().nullable(),
  representatives: yup.array().of(
    yup.object().shape({
      phone_number: yup
        .string()
        .nullable()
        .matches(phoneRegExp, 'Phone number is invalid')
        .nullable(),
      email: yup.string().email('Enter valid email').nullable(),
      name: yup.string().nullable()
    })
  )
});

export const saveCustomer = async (values: any) => {
  try {
    const res = await services.post(
      values.id ? `/customer/update/${values.id}` : '/customer/save',
      { ...values }
    );
    return res;
  } catch (error) {
    //@ts-ignore
    const errorMessageObject = error?.error?.errors;
    if (errorMessageObject) {
      throw { errors: errorMessageObject };
    }
    throw error;
  }
};

export const getCustomerDetails = async (url: string, customerId: string) => {
  try {
    if (!customerId) {
      return { ...customerInitialValues };
    }
    const res = (await services.post(url, { customer_id: customerId })) as any;
    return res?.success
      ? {
          ...res.data.data,
          country_code: res?.data?.data?.country_code ?? '+1',
          country_iso_code: res?.data?.data?.country_iso_code ?? 'CA'
        }
      : {};
  } catch (error) {
    throw error;
  }
};

export const submitBulkUpload = async (url: string) => {
  try {
    const res = (await services.post('/customer/bulk-import', { file_url: url })) as any;
    if (!res?.data?.status) {
      if (res?.data?.data?.errorMsgs) {
        return {
          error: res?.data?.data?.errorMsgs
            .replaceAll('<ol>', '')
            .replaceAll('</ol>', '')
            .replaceAll('<li>', '')
            .replaceAll('</li>', '')
        };
      }
    }
    return { message: res?.data?.message };
  } catch (error) {
    throw error;
  }
};
