import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '@pages/EventManagementPage/EventWithExistingQuote/tabHelpers';
import EventBasicDetails from '@pages/QuoteManagementPage/AddEditQuote/EventBasicDetails';
import { getSelectOptions } from '@utils/helper';
import { showErrorToast } from '@utils/index';
import { Col, Row, Spin } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import AddEditEventFooter from '../../AddEditEventFooter';
import { CREATE_EVENT_TAB_KEYS } from '../../helper';
import GuestAllergies from './GuestAllergies';
import {
  addEditService,
  getService,
  getServiceFormInitialValues,
  validationSchema
} from './helper';
import EventTimeline from './Timeline/EventTimeline';

const Service = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { quoteId, eventId, formType, form } = params;
  const { withQuote, onServiceSubmit = (values: any) => {} } = props;
  const [formikData, setFormikData] = useState({});
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState(false);

  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const formik = useFormik({
    initialValues: formikData,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      submitHandler(values);
    }
  });

  const {
    touched,
    errors,
    values,
    setValues,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit
  } = formik;

  const submitHandler = async (values: any) => {
    const updatedValues = {
      ...values,
      event_time_line: values?.event_time_line?.map((value: any, index: number) => ({
        ...value,
        sort_order: index + 1
      }))
    };
    try {
      if (eventId) {
        setSubmitting(true);
        const res = (await addEditService(
          updatedValues,
          parseInt(eventId),
          details?.event_date
        )) as any;
        if (res?.success) {
          if (onServiceSubmit) {
            onServiceSubmit({ ...updatedValues, ...res });
            setSubmitting(false);
            // return;
          }
          const tabBaseUrl = withQuote
            ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.VENDORS}`
            : `/events/create/${CREATE_EVENT_TAB_KEYS.NOTES}/${quoteId}/${eventId}`;
          navigate(tabBaseUrl, {
            state: { prevPath: location?.pathname, path: location?.state?.path }
          });
        } else {
          showErrorToast({
            message: 'Failed!',
            description: 'Something went wrong'
          });
        }
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
  };

  const backHandler = () => {
    const tabBaseUrl = withQuote
      ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.STAFFING}`
      : `/events/create/${CREATE_EVENT_TAB_KEYS.STAFFING}/${quoteId}/${eventId}`;
    navigate(tabBaseUrl, {
      state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
    });
  };
  const cancelHandler = () => {
    navigate('/events');
  };

  const nextHandler = () => {
    handleSubmit();
  };

  const setupFormikInitialValues = async () => {
    if (details?.event_date) {
      try {
        const res = (await getService(eventId, quoteId, details?.event_date)) as any;
        if (res?.success) {
          const eventData = getServiceFormInitialValues(res?.data);
          setValues(eventData);
          setFormikData(eventData);
        } else {
          showErrorToast({
            message: 'Failed!',
            description: 'Failed to fetch events data.'
          });
        }
      } catch (error: any) {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch events data.'
        });
      }
    } else {
      setValues(getServiceFormInitialValues());
    }
    setLoading(false);
  };

  useEffect(() => {
    setupFormikInitialValues();
  }, [details?.event_id]);

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          <EventBasicDetails eventDate="-" eventName="-" venueName="-" customerName="-" />
          <Row gutter={16} style={{ marginTop: '32px' }}>
            <Col md={12} xs={24}>
              <Label text="Date" isMandatory={false} />
              <SelectInput
                id={`event_date`}
                name={`event_date`}
                placeholdertitle="Date"
                value={0}
                error={''}
                onChange={() => {}}
                onBlur={handleBlur}
                allowClear={false}>
                {getSelectOptions(
                  [
                    {
                      id: 0,
                      name: details?.event_date
                        ? moment(details?.event_date).format('ddd, MMMM DD')
                        : 'Unassigned Date'
                    }
                  ],
                  'name'
                )}
              </SelectInput>
            </Col>
          </Row>
          <EventTimeline formik={formik} />
          <div style={{ marginTop: '18px' }} />
          <GuestAllergies formik={formik} />
          <AddEditEventFooter
            showBackButton={true}
            backClickHandler={backHandler}
            cancelClickHandler={cancelHandler}
            nextClickHandler={nextHandler}
            isSubmitting={submitting}
          />
        </>
      )}
    </>
  );
};

export default Service;
