import React, { ChangeEvent, FC } from 'react';

import { Flex } from '@atom/Flex';
import { ICategoryType } from '../types';
import { Input } from '@atom/Input';
import SelectInput from '@atom/Select';
import { getSelectOptions } from '@utils/helper';

interface VendorTypeHeaderProps {
  columnTitle?: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (a: ChangeEvent) => void;
  vendorCategories: ICategoryType[];
}

const VendorTypeHeader: FC<VendorTypeHeaderProps> = ({
  columnTitle = '',
  name = '',
  value = null,
  placeholder = '',
  onChange,
  vendorCategories
}) => {
  return (
    <Flex direction="column" style={{ width: '240px', margin: 0 }} gap={15}>
      <SelectInput
        id={name}
        name={name}
        placeholdertitle={placeholder}
        value={value}
        onChange={onChange}
      >
        {getSelectOptions(vendorCategories, 'name')}
      </SelectInput>
    </Flex>
  );
};

export default VendorTypeHeader;
