import React, { useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4Typography } from '@constant/typography/Typography';
import {
  EditMenuNameTypes,
  getMenuDetails,
  MenuNameListType,
  menuValidationSchema,
  updateMenuNames
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import MenuManagementEditableVirtualList from './MenuManagementEditableVirtualList';

import { Formik, FormikProps } from 'formik';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';

const MenuNames = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [menuNameList, setMenuNameList] = useState<MenuNameListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<MenuNameListType[] | null>(null);
  const [scrollToBottomOfList, setScrollToBottomOfList] = useState<boolean>(false);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getMenuDetailsHandler();
  }, []);

  const getMenuDetailsHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getMenuDetails('menu');
    if (!result.error) {
      setMenuNameList(result.data || []);
    }
    setPageLoading(false);
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getMenuDetailsHandler();
    onModalCancelHandler();
  };

  const onMenuNameDelete = (
    index: number,
    ingridentsArray: any,
    setFieldValue: any,
    id?: number | null
  ) => {
    if (id) {
      showToast({ message: 'Menu Name Removed Successfully', description: '' });
    }
    const tempArr = [...JSON.parse(JSON.stringify(ingridentsArray))];
    tempArr?.splice(index, 1);
    setFieldValue('menus', tempArr);
    return true;
  };

  const onSubmitHandler = (values: EditMenuNameTypes) => {
    setSubmitValue(values.menus);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateMenuNametHandler = async () => {
    if (!submitValue) return;
    setLoading(true);
    const result = await updateMenuNames({ menus: submitValue }, 'menu');
    setLoading(false);
    if (!result.error) {
      showToast({
        message: 'Menu Names Updated Successfully',
        description: result?.data?.message.length > 2 ? result?.data?.message : ''
      });
      onCancelHandler();
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Menu Names',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Flex bottom={26}>
          <H4Typography>MENU NAMES</H4Typography>
        </Flex>
        <Formik
          initialValues={{ menus: menuNameList }}
          validationSchema={menuValidationSchema}
          onSubmit={onSubmitHandler}>
          {(formikProps: FormikProps<any>) => {
            const { values, setFieldValue, handleSubmit } = formikProps;

            return (
              <Flex direction="column">
                <MenuManagementEditableVirtualList
                  formik={formikProps}
                  onItemDelete={onMenuNameDelete}
                  fieldArrayName="menus"
                  inputFieldPlaceHolder="Menu Name"
                  scrollToBottomOfList={scrollToBottomOfList}
                  setScrollToBottomOfList={setScrollToBottomOfList}
                />
                <Flex bottom={24}>
                  <Button
                    text="Add Menu"
                    variant="secondary"
                    icon={<img width={20} src={PlusCircle} alt="add" />}
                    onClick={() => {
                      setFieldValue('menus', [...values.menus, { name: '', id: '' }]);
                      setScrollToBottomOfList(true);
                    }}
                  />
                </Flex>
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8} className="fix-mobile">
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button text="Submit" onClick={handleSubmit} isLoading={loading} />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Saving will impact existing entries."
          onOK={updateMenuNametHandler}
          isModalLoading={loading}
          okText="Okay">
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <div>
      <Flex bottom={26}>
        <H4Typography>MENU NAMES</H4Typography>
      </Flex>
      <Flex style={{ flexFlow: 'wrap', gap: '20px' }}>
        {menuNameList.map((menu) => (
          <p key={menu.id} style={{ wordBreak: 'break-all' }}>
            {menu.name}
          </p>
        ))}
      </Flex>
    </div>
  );
};

export default MenuNames;
