import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import ModalComponent from '@organism/Modal/Modal';
import { ChangePasswordModalProps } from './types';
import NoImage from '@assets/noimage.png';
import { useFormik } from 'formik';
import { Input } from '@atom/Input';
import { Button } from '@atom/Buttons';
import { checkPasswordSchema, checkPassword, updatePasswordSchema, updatePassword } from './helper';
import { ResponseType } from '../types';
import { showErrorToast, showToast } from '@utils/index';

const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const {
    isModalVisible,
    onOk,
    onCancel,
    onForgotPassword,
    data: profileDetails,
    setIsNewPassword,
    isNewPassword
  } = props;

  const onSubmit = async (values: any) => {
    const res = (await checkPassword(values)) as ResponseType;
    if (res.error) {
      showErrorToast({
        message: 'Error',
        description: res?.error || 'Please try again'
      });
    } else {
      setIsNewPassword(true);
    }
  };

  const onSubmitUpdatePassword = async (values: any) => {
    const res = (await updatePassword(values)) as ResponseType;
    if (res.error) {
      showErrorToast({
        message: 'Error',
        description: res?.error || 'Please try again'
      });
    } else {
      showToast({ message: 'Password Updated', description: '' });
      onOk();
    }
  };

  const formik = useFormik({
    validationSchema: checkPasswordSchema,
    onSubmit: onSubmit,
    initialValues: {
      current_password: ''
    }
  });
  const { handleChange, handleSubmit, values, errors } = formik;

  const updatePasswordFormik = useFormik({
    validationSchema: updatePasswordSchema,
    onSubmit: onSubmitUpdatePassword,
    initialValues: {
      new_password: '',
      confirm_password: ''
    }
  });
  const {
    handleChange: onUpdatePasswordChange,
    handleSubmit: onUpdatePasswordSubmit,
    values: updatePasswordValues,
    errors: updatePasswordError
  } = updatePasswordFormik;

  return (
    <ModalComponent
      visible={isModalVisible}
      onCancel={onCancel}
      modalWidth="562px !important"
      align="left !important"
    >
      <Flex alignItems={'center'} direction={'column'} gap={24} style={{ marginBottom: 24 }}>
        <img
          src={profileDetails?.profile_pic || NoImage}
          style={{ height: '128px', width: '128px', objectFit: 'cover' }}
          alt="Profile Image"
        />
        <H2Typography>Change Password</H2Typography>
      </Flex>
      {!isNewPassword ? (
        <>
          <Input
            type="password"
            name="current_password"
            id="current_password"
            placeholder="Current Password"
            onChange={handleChange}
            value={values.current_password}
            error={errors.current_password}
          />
          {/* <Button
            variant="ghost"
            text="Forgot Password?"
            onClick={onForgotPassword}
            style={{ paddingLeft: 0, textDecoration: 'underline' }}
          /> */}
        </>
      ) : (
        <>
          <Input
            type="password"
            name="new_password"
            id="new_password"
            placeholder="New Password"
            onChange={onUpdatePasswordChange}
            value={updatePasswordValues.new_password}
            error={updatePasswordError.new_password}
          />
          <Input
            type="password"
            name="confirm_password"
            id="confirm_password"
            placeholder="Repeat Password"
            onChange={onUpdatePasswordChange}
            value={updatePasswordValues.confirm_password}
            error={updatePasswordError.confirm_password}
          />
        </>
      )}

      <Flex justifyContent={'flex-end'} style={{ marginTop: '48px', paddingTop: '24px' }}>
        {!isNewPassword ? (
          <Button
            text={!formik.isSubmitting ? 'Change Password?' : 'Checking...'}
            onClick={handleSubmit}
          />
        ) : (
          <Button
            text={'Change Password?'}
            onClick={onUpdatePasswordSubmit}
            isLoading={updatePasswordFormik.isSubmitting}
          />
        )}
      </Flex>
    </ModalComponent>
  );
};

export default ChangePasswordModal;
