import { Col, Row } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import PageLayout from '@layout/PageLayout';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { TooltipText } from '@atom/Tooltip';
import { H4Typography, H5Typography } from '@constant/typography/Typography';
import { CSV_TEMPLATE, FILE_TYPES } from '@constants/userConstants';
import FileDraggerComponent from '@molecules/FileDraggerComponent';
import { PageTitle } from '@molecules/PageTitle';
import { uploadFileHandler } from '@services/fileService';
import { showErrorToast, showToast } from '@utils/index';
import { submitBulkUpload } from './helper';
import { PageLayoutWrapper } from './style';

function CustomerBulkImport() {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [bulkUploading, setBulkUploading] = useState(false);
  const [documentURL, setDocumentURL] = useState(null);
  const navigate = useNavigate();
  const onFileUpload = async (file: File) => {
    setFileUploading(true);
    const result = (await uploadFileHandler(file, FILE_TYPES.DOCUMENTS)) as any;
    setFileUploading(false);
    setUploadedFile(file);
    if (result.error) {
      return showErrorToast({
        message: `Unable to document`,
        description: result.error || 'Please try again'
      });
    }
    if (result?.data?.url) {
      setDocumentURL(result?.data?.url);
    }
  };
  const onBulkUploadSubmit = async () => {
    if (documentURL) {
      try {
        setBulkUploading(true);
        const res = (await submitBulkUpload(documentURL)) as any;
        if (res.error) {
          showErrorToast({ message: res.error, description: '' });
          return;
        }
        if (res.message) {
          showToast({ message: res.message, description: '' });
          navigate('/customers');
        }
      } catch (error) {
        //@ts-ignore
        if (error.message) {
          //@ts-ignore
          showErrorToast({ message: error.message, description: '' });
          return;
        }
        showErrorToast({ message: 'Something went wrong', description: '' });
      } finally {
        setBulkUploading(false);
      }
    }
  };
  return (
    <>
      <PageLayoutWrapper>
        <PageLayout className="page_outer-fixing">
          <PageTitle text="CUSTOMER MANAGEMENT" isBack bordered={true} path="/customers/" />
          <H4Typography style={{ paddingTop: '30px' }}>{'Upload File'}</H4Typography>
          <Row gutter={24}>
            <Col md={12} xs={24}>
              <H5Typography style={{ fontWeight: 'normal' }}>
                Please upload file to import existing data
              </H5Typography>
            </Col>
            <Col md={12} xs={24} style={{ justifyContent: 'end', display: 'flex' }}>
              <Flex>
                <H5Typography style={{ fontWeight: 'normal' }}>
                  What files can I upload?
                </H5Typography>
                <TooltipText placement="bottomLeft" text={'Please only upload CSV/XLS documents'} />
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FileDraggerComponent
                format="csv"
                onChangeHandle={onFileUpload}
                loading={fileUploading}
                maxLimit={1}
                uploadedFileName={uploadedFile?.name}
                showUploadList={false}
              />
            </Col>
          </Row>
          <Flex className="footerButton" justifyContent={'space-between'}>
            <Flex className="fix-mobile">
              <Button
                text={'Download CSV Template'}
                onClick={() => {
                  window.open(CSV_TEMPLATE, '_blank');
                }}
                variant={'secondary'}
              />
            </Flex>
            <Flex gap={16} className="fix-mobile">
              <Button
                text={'Cancel'}
                onClick={() => {
                  navigate('/customers');
                }}
                variant={'secondary'}
              />
              <Button
                className="btn-bg"
                style={{ backgroundColor: '#000000 !important' }}
                text={'Submit'}
                isDisable={!documentURL}
                isLoading={bulkUploading}
                onClick={onBulkUploadSubmit}
                variant={'primary'}
              />
            </Flex>
          </Flex>
        </PageLayout>
      </PageLayoutWrapper>
    </>
  );
}

export default CustomerBulkImport;
