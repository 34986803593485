import { Col, Row } from 'antd';
import { IVendorListItemType, getVendorList } from './helper';
import { useEffect, useState } from 'react';

import { CURRENCY } from '@constants/index';
import Checkbox from '@atom/Checkbox';
import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import { Label } from '@atom/FormLable';
import { AddNotesText, RemoveIcon } from '../Labours/style';
import SearchableSelect from '@atom/SearchableSelect';
import TimePickerComponent from '@atom/TimePicker';
import { Value } from '@atom/FormDataView';
import _ from 'lodash';
import { costCalculator } from '../MealPlan/helper';
import moment, { Moment } from 'moment';
import { VendorEstimateItemWrapper } from '@pages/QuoteManagementPage/style';
import { formatCurrency } from '@utils/format';

interface VendorEstimateItemProps {
  vendor: any;
  index: number;
  formik: any;
  vendorsList: Array<any>;
  onRemove: (vendor: any) => void;
  isEventsTab?: boolean;
}

function VendorEstimateItem(props: VendorEstimateItemProps) {
  const { vendor, index, onRemove, formik, isEventsTab = false, vendorsList } = props;
  const { handleChange, touched, errors, setFieldValue, values, handleBlur, setFieldTouched } =
    formik;

  const [localItemRate, setLocalItemRate] = useState<string>('');
  const [localItemQuantity, setLocalItemQuantity] = useState<string>('');
  const [localItemTotalCost, setLocalItemTotalCost] = useState<string>('');
  const [localItemVendorID, setLocalItemVendorID] = useState<string>('');
  const [vendorNameList, setVendorNameList] = useState<IVendorListItemType[]>([]);
  const [filteredVendorNameList, setFilteredVendorNameList] = useState<IVendorListItemType[]>([]);
  const [localVendorsNotesValue, setLocalVendorsNotesValue] = useState<string>('');

  const getBaseObjectForEntity = (baseObject: any) => {
    if (isEventsTab) {
      return baseObject?.vendors?.[index];
    }
    return baseObject?.event_dates?.vendors?.[index];
  };
  const valVarBase: any = getBaseObjectForEntity(values);
  const touchedVarBase: any = getBaseObjectForEntity(touched);
  const errorsVarBase: any = getBaseObjectForEntity(errors);
  const idBase: string = isEventsTab ? `vendors[${index}]` : `event_dates.vendors[${index}]`;
  const [showNotesArea, setShowNotesArea] = useState<boolean>(valVarBase?.notes ? true : false);

  const startTimeString = isEventsTab ? `${idBase}.event_start_time` : `${idBase}.start_time`;
  const endTimeSting = isEventsTab ? `${idBase}.event_end_time` : `${idBase}.end_time`;
  const startTimeValue = isEventsTab
    ? values?.vendors?.[index]?.event_start_time
    : values?.vendors?.[index]?.start_time;
  const endTimeValue = isEventsTab
    ? values?.vendors?.[index]?.event_end_time
    : values?.vendors?.[index]?.end_time;
  const startTimeError = isEventsTab
    ? touchedVarBase?.event_start_time && errorsVarBase?.event_start_time
    : touchedVarBase?.start_time && touchedVarBase?.start_time;

  const endTimeError = isEventsTab
    ? touchedVarBase?.event_end_time && errorsVarBase?.event_end_time
    : touchedVarBase?.end_time && touchedVarBase?.end_time;

  useEffect(() => {
    setLocalItemQuantity(valVarBase?.quantity);
    setLocalItemRate(valVarBase?.rate);
    setLocalItemTotalCost(valVarBase?.total_cost);
    setLocalItemVendorID(valVarBase?.vendor_id);
  }, []);

  useEffect(() => {
    const totalCost: string = costCalculator(localItemQuantity, localItemRate).toString();
    setLocalItemTotalCost(totalCost);
    setFieldValue(`${idBase}.total_cost`, totalCost);
  }, [localItemQuantity, localItemRate]);

  useEffect(() => {
    (async () => {
      const { data }: any = await getVendorList();
      const vendorsList = data?.map((item: IVendorListItemType) => ({
        ...item,
        name: item.vendor_name,
        value: item.id
      }));
      setVendorNameList(vendorsList);
      setFilteredVendorNameList(vendorsList);
      setLocalVendorsNotesValue(valVarBase?.notes ?? '');
    })();
  }, []);

  const vendorListSearchHandler = (value: string) => {
    setFilteredVendorNameList(
      vendorNameList?.filter(
        (vendor: any) => vendor?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
      )
    );
  };

  return (
    <>
      <VendorEstimateItemWrapper
        style={{ width: '100%' }}
        justify="space-between"
        gutter={[16, 16]}>
        {isEventsTab && (
          <Col className="vendorColumn" xs={24}>
            <Label text={'Start Time'} />
            <TimePickerComponent
              id={startTimeString}
              name={startTimeString}
              use12Hours={true}
              format="h:mm A"
              placeholder={'HH:MM'}
              onChange={(value: string) => {
                setFieldTouched(startTimeString, true);
                setFieldValue(startTimeString, value);
              }}
              onSelect={(e: Moment) => {
                setFieldValue(startTimeString, e.format('hh:mm A'));
              }}
              value={startTimeValue && moment(startTimeValue, 'hh:mm A').format('HH:mm')}
              error={startTimeError}
              allowClear={false}
            />
          </Col>
        )}
        {isEventsTab && (
          <Col className="vendorColumn" xs={24}>
            <Label text={'End Time'} />
            <TimePickerComponent
              id={endTimeSting}
              name={endTimeSting}
              use12Hours={true}
              format="h:mm A"
              placeholder={'HH:MM'}
              onChange={(value: string) => {
                setFieldTouched(endTimeSting, true);
                setFieldValue(endTimeSting, value);
              }}
              onSelect={(e: Moment) => {
                setFieldValue(endTimeSting, e.format('hh:mm A'));
              }}
              value={endTimeValue && moment(endTimeValue, 'hh:mm A').format('HH:mm')}
              error={endTimeError}
              allowClear={false}
            />
          </Col>
        )}
        <Col className="vendorColumn" xs={24}>
          <Label text={'Vendors'} />

          <SearchableSelect
            id={`${idBase}.vendor_id`}
            name={`${idBase}.vendor_id`}
            options={filteredVendorNameList}
            error={touchedVarBase?.vendor_id && errorsVarBase?.vendor_id}
            keyValue={'value'}
            labelValue={'name'}
            placeholdertitle={'Search For Vendors'}
            onChange={(e: IVendorListItemType) => {
              setFieldValue(`${idBase}.vendor_id`, e?.id);
            }}
            onBlur={handleBlur}
            value={{
              name: vendorNameList?.find((item) => String(item.id) == localItemVendorID)
                ?.vendor_name
            }}
            onSearch={vendorListSearchHandler}
            allowClear={true}
            prefix={true}
            disablePrefix={!!localItemVendorID}
          />
        </Col>

        <Col className="vendorColumn" xs={24}>
          <Label text={'Qty'} />
          <Input
            name={`${idBase}.quantity`}
            id={`${idBase}.quantity`}
            placeholder={'0'}
            value={localItemQuantity}
            onChange={(e: any) => {
              setLocalItemQuantity(e.target.value);
              setTimeout(() => {
                setFieldValue(`${idBase}.quantity`, e?.target?.value);
              }, 200);
            }}
            onBlur={handleBlur}
            error={touchedVarBase?.quantity && errorsVarBase?.quantity}
          />
        </Col>

        <Col className="vendorColumn" xs={24}>
          <Label text={'Rate'} />
          <Input
            id={`${idBase}.rate`}
            name={`${idBase}.rate`}
            placeholder={'0'}
            isCurrency={true}
            value={localItemRate}
            onChange={(e: any) => {
              setLocalItemRate(e.target.value);
              setTimeout(() => {
                setFieldValue(`${idBase}.rate`, e?.target?.value);
              }, 200);
            }}
            onBlur={handleBlur}
            error={touchedVarBase?.rate && errorsVarBase?.rate}
          />
        </Col>

        <Col className="vendorColumn" xs={24}>
          <Flex direction="column">
            <Label text={'Total Cost'} />

            <Flex alignItems={'center'} style={{ height: '48px' }}>
              <Value style={{ color: valVarBase?.waived_off ? 'red' : 'black' }}>
                {formatCurrency(Number(localItemTotalCost))}
              </Value>
            </Flex>
          </Flex>
        </Col>

        <Col className="vendorColumn" xs={24} style={{ alignItems: 'center', display: 'flex' }}>
          <Flex>
            <Checkbox
              id={`${idBase}.waived_off`}
              checked={valVarBase?.waived_off}
              onChange={(e: any) => {
                setFieldValue(`${idBase}.waived_off`, e.target.checked ? 1 : 0);
              }}
            />
            <Value style={{ marginBottom: '0px' }}>Fee's Waived</Value>
          </Flex>
        </Col>
        <Col className="vendorColumn" xs={24} style={{ alignItems: 'center', display: 'flex' }}>
          <RemoveIcon onClick={onRemove} style={{ marginLeft: '0px' }} />
        </Col>
      </VendorEstimateItemWrapper>
      {!showNotesArea && !valVarBase?.notes && (
        <AddNotesText onClick={() => setShowNotesArea(true)}>+ Add Notes</AddNotesText>
      )}
      {(showNotesArea || valVarBase?.notes) && (
        <Row style={{ width: '100%' }}>
          <Col lg={12} xs={24}>
            <Label text={'Notes'} />
            <Input
              name={`${idBase}.notes`}
              id={`${idBase}.notes`}
              placeholder="Add Notes"
              onChange={(e: any) => {
                setLocalVendorsNotesValue(e?.target?.value);
                setTimeout(() => {
                  setFieldValue(`${idBase}.notes`, e?.target?.value);
                }, 200);
              }}
              value={localVendorsNotesValue}
              error={touchedVarBase?.notes && errorsVarBase?.notes}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default VendorEstimateItem;
