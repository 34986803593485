import Checkbox from '@atom/Checkbox';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import SearchableSelect from '@atom/SearchableSelect';
import TimePickerComponent from '@atom/TimePicker';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';
import { formatCurrency } from '@utils/format';
import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { costCalculator } from '../MealPlan/helper';
import { AddExtraText, AddNotesText, CustomFormValue, RemoveIcon } from './style';
import { showOverrideCheckBox } from './helper';

function StaffingEstimateItem(props: any) {
  const { index, onRemove, isEventsTab, formik, positionsList, calculateLabourSubtotal } = props;
  const { handleBlur, values, setFieldTouched, setFieldValue, touched, errors } = formik;
  const [staffingEstimateItem, setStaffingEstimateItem] = useState<any>({});
  const { employees } = staffingEstimateItem?.employee_details || {};
  const device = useWindowSizeChangedforGrid();

  const [filteredPositionsList, setFilteredPositionsList] = useState<
    Array<{ id?: number; name?: string }>
  >([]);

  useEffect(() => {
    setFilteredPositionsList(positionsList);
  }, [positionsList]);
  const [localItemTotalCost, setLocalItemTotalCost] = useState<string>('');

  const [showNotesArea, setShowNotesArea] = useState<boolean>(false);

  const getBaseObjectForEntity = (baseObject: any) => {
    return baseObject?.event_dates?.labours?.[index];
  };
  const valVarBase: any = getBaseObjectForEntity(values);
  const idBase: string = `event_dates.labours[${index}]`;

  const [initialMasterPositionId, setInitialMasterPositionId] = useState<number | null>(null);

  useEffect(() => {
    if (valVarBase?.master_position_id && initialMasterPositionId === null) {
      setInitialMasterPositionId(valVarBase.master_position_id);
    }
  }, [valVarBase.master_position_id]);

  const isInitialMount = useRef(true);
  const [isPositionChanged, setIsPositionChanged] = useState<boolean>(false);
  const isChecked = useRef(valVarBase?.do_not_override_data);

  useEffect(() => {
    if (!isInitialMount.current) {
      setIsPositionChanged(initialMasterPositionId !== staffingEstimateItem?.master_position_id);
    } else {
      isInitialMount.current = false;
    }
  }, [initialMasterPositionId, staffingEstimateItem?.master_position_id]);

  const handleChangeValue = (value: any, formikKey: string, key: string) => {
    setStaffingEstimateItem((prev: any) => ({ ...prev, [key]: value }));
    setTimeout(() => {
      setFieldValue(formikKey, value);
    }, 200);
  };

  const calculateHoursDiff = (start_Time: string, end_Time: string) => {
    const startTime = moment(start_Time, 'hh:mm A');
    const endTime = moment(end_Time, 'hh:mm A');
    let minsDiff = endTime.diff(startTime, 'minutes');

    if (minsDiff <= 0) {
      minsDiff += 1440;
    }
    if (!minsDiff) {
      minsDiff = 0;
    }

    return (minsDiff / 60).toFixed(2);
  };

  const calculateHoursHandler = (start_Time: string, end_Time: string) => {
    const hoursDiff = calculateHoursDiff(start_Time, end_Time);
    setStaffingEstimateItem((prev: any) => ({ ...prev, hours: hoursDiff }));
    const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
    tempValues.event_dates.labours[index].hours = hoursDiff;
    calculateLabourSubtotal(tempValues?.event_dates?.labours);
    setTimeout(() => {
      setFieldValue(`${idBase}.hours`, hoursDiff);
    }, 200);
  };

  useEffect(() => {
    if (valVarBase.id) {
      // const hoursDiff = calculateHoursDiff(valVarBase?.start_time, valVarBase?.end_time);
      // setStaffingEstimateItem({ ...valVarBase, hours: hoursDiff });
      setStaffingEstimateItem(valVarBase);
      setShowNotesArea(!!valVarBase?.notes);
    }
  }, [valVarBase.id]);

  useEffect(() => {
    const totalCost: string = costCalculator(
      staffingEstimateItem?.quantity,
      staffingEstimateItem?.rate,
      staffingEstimateItem?.hours
    ).toString();
    setLocalItemTotalCost(totalCost);
    setFieldValue(`${idBase}.total_cost`, totalCost);
  }, [staffingEstimateItem?.quantity, staffingEstimateItem?.rate, staffingEstimateItem?.hours]);

  const labourPositionListSearchHandler = (value: string) => {
    setFilteredPositionsList(
      positionsList?.filter(
        (postion: any) => postion?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
      )
    );
  };

  return (
    <>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col md={3} xs={12}>
          <Label text={'Start Time'} />
          <TimePickerComponent
            id={`${idBase}.start_time`}
            name={`${idBase}.start_time`}
            use12Hours={true}
            format="h:mm A"
            placeholder={'HH:MM'}
            value={staffingEstimateItem?.start_time}
            onChange={(value: any) => {
              setFieldTouched(`${idBase}.start_time`, true);
              handleChangeValue(value, `${idBase}.start_time`, 'start_time');
              calculateHoursHandler(value, staffingEstimateItem?.end_time);
            }}
            onSelect={(e: Moment) => {
              handleChangeValue(e.format('hh:mm A'), `${idBase}.start_time`, 'start_time');
              calculateHoursHandler(e.format('hh:mm A'), staffingEstimateItem?.end_time);
            }}
          />
        </Col>

        <Col md={3} xs={12}>
          <Label text={'End Time'} />
          <TimePickerComponent
            id={`${idBase}.end_time`}
            name={`${idBase}.end_time`}
            use12Hours={true}
            format="h:mm A"
            placeholder={'HH:MM'}
            value={staffingEstimateItem?.end_time}
            onChange={(value: any) => {
              setFieldTouched(`${idBase}.end_time`, true);
              handleChangeValue(value, `${idBase}.end_time`, 'end_time');
              calculateHoursHandler(staffingEstimateItem?.start_time, value);
            }}
            onSelect={(e: Moment) => {
              handleChangeValue(e.format('hh:mm A'), `${idBase}.end_time`, 'end_time');
              calculateHoursHandler(staffingEstimateItem?.start_time, e.format('hh:mm A'));
            }}
          />
        </Col>

        <Col md={3} xs={24}>
          <Label text={'Position'} />
          <SearchableSelect
            id={`${idBase}.master_position_id`}
            name={`${idBase}.master_position_id`}
            options={filteredPositionsList}
            keyValue={'id'}
            labelValue={'name'}
            value={positionsList?.find(
              (i: any) => i?.id === staffingEstimateItem?.master_position_id
            )}
            prefix={true}
            onChange={(value: any) => {
              handleChangeValue(value?.value, `${idBase}.master_position_id`, 'master_position_id');
              handleChangeValue(value?.value, `${idBase}.position`, 'position');
              const rateOfPosition = positionsList?.find((i: any) => i?.id === value?.value)?.rate;
              const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
              tempValues.event_dates.labours[index].rate = rateOfPosition;
              calculateLabourSubtotal(tempValues?.event_dates?.labours);
              handleChangeValue(rateOfPosition, `${idBase}.rate`, 'rate');
              handleChangeValue(
                isPositionChanged ? isChecked.current : 0,
                `${idBase}.do_not_override_data`,
                'do_not_override_data'
              );
            }}
            onSearch={labourPositionListSearchHandler}
            onBlur={() => setFieldTouched(`${idBase}.position`, true)}
            error={
              touched?.event_dates?.labours[index]?.position &&
              errors?.event_dates?.labours[index]?.position
            }
          />
        </Col>
        <Col md={3} xs={12}>
          <Label text={'Qty'} />
          <Input
            name={`${idBase}.quantity`}
            id={`${idBase}.quantity`}
            placeholder={'0'}
            value={staffingEstimateItem?.quantity}
            onChange={(e: any) => {
              const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
              tempValues.event_dates.labours[index].quantity = e?.target?.value;
              calculateLabourSubtotal(tempValues?.event_dates?.labours);
              handleChangeValue(e?.target.value, `${idBase}.quantity`, 'quantity');
            }}
            onBlur={handleBlur}
            error={
              touched?.event_dates?.labours[index]?.quantity &&
              errors?.event_dates?.labours[index]?.quantity
            }
          />
        </Col>

        <Col md={3} xs={12}>
          <Label text={'Rate'} />
          <Input
            id={`${idBase}.rate`}
            name={`${idBase}.rate`}
            placeholder={'0'}
            isCurrency={true}
            value={staffingEstimateItem?.rate}
            onChange={(e: any) => {
              const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
              tempValues.event_dates.labours[index].rate = e?.target?.value;
              calculateLabourSubtotal(tempValues?.event_dates?.labours);
              handleChangeValue(e?.target.value, `${idBase}.rate`, 'rate');
            }}
            onBlur={handleBlur}
            error={
              touched?.event_dates?.labours[index]?.rate &&
              errors?.event_dates?.labours[index]?.rate
            }
          />
        </Col>
        <Col md={3} xs={12}>
          <Label text={'Hours'} />
          <Input
            id="hours"
            name="hours"
            placeholder={'0'}
            value={staffingEstimateItem?.hours}
            onChange={(e: any) => {
              const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
              tempValues.event_dates.labours[index].hours = e?.target?.value;
              calculateLabourSubtotal(tempValues?.event_dates?.labours);
              handleChangeValue(e?.target.value, `${idBase}.hours`, 'hours');
            }}
            error={
              touched?.event_dates?.labours[index]?.hours &&
              errors?.event_dates?.labours[index]?.hours
            }
          />
        </Col>
        <Col md={isEventsTab ? 2 : 3} xs={12}>
          <Flex direction="column">
            <Label text={'Total Cost'} />
            <Flex top={4}>
              <CustomFormValue
                style={{ color: staffingEstimateItem?.waived_off ? 'red' : 'black' }}
              >
                {formatCurrency(Number(localItemTotalCost))}
              </CustomFormValue>
            </Flex>
          </Flex>
        </Col>
        <Col md={3} xs={24}>
          <Row gutter={16} style={{ position: 'relative', top: '30%' }}>
            <Col md={20} xs={12}>
              <Flex>
                <Flex>
                  <Checkbox
                    id={`${idBase}.waived_off`}
                    checked={staffingEstimateItem?.waived_off}
                    onChange={(e: any) =>
                      handleChangeValue(e?.target.checked, `${idBase}.waived_off`, 'waived_off')
                    }
                  />
                </Flex>
                <Flex left={4}>
                  <CustomFormValue style={{ marginBottom: '0px' }}>Fee’s Waived</CustomFormValue>
                </Flex>
              </Flex>
            </Col>
            <Col md={4} xs={12}>
              <RemoveIcon
                style={{ fontSize: '22px', fontWeight: '700' }}
                onClick={() => onRemove(valVarBase)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {!showNotesArea && (
        <Row style={{ width: '100%', gap: '28px' }}>
          <AddNotesText onClick={() => setShowNotesArea(true)}>+ Add Notes</AddNotesText>
          {showOverrideCheckBox.includes(values?.status) && (
            <Flex>
              <Flex>
                <Checkbox
                  id={`${idBase}.do_not_override_data`}
                  checked={staffingEstimateItem?.do_not_override_data}
                  disabled={isPositionChanged}
                  onChange={(e: any) =>
                    handleChangeValue(
                      e?.target?.checked ? 1 : 0,
                      `${idBase}.do_not_override_data`,
                      'do_not_override_data'
                    )
                  }
                />
              </Flex>
              <Flex left={4}>
                <AddExtraText
                  style={{
                    marginBottom: '0px',
                    color: isPositionChanged ? 'gray' : 'red'
                  }}
                >
                  Do not override above data to staff
                </AddExtraText>
              </Flex>
            </Flex>
          )}
          {employees?.length ? (
            <AddExtraText>
              Position has been staffed. Revising labour will affect the assignments.
            </AddExtraText>
          ) : null}
        </Row>
      )}

      {showNotesArea && (
        <Row style={{ width: '100%' }}>
          <Row style={{ width: '100%', gap: '28px' }}>
            <Label text={'Notes'} style={{ minWidth: 86 }} />
            {device !== 'mobile' ? (
              <>
                {showOverrideCheckBox.includes(values?.status) && (
                  <Flex>
                    <Flex>
                      <Checkbox
                        id={`${idBase}.do_not_override_data`}
                        checked={staffingEstimateItem?.do_not_override_data}
                        disabled={isPositionChanged}
                        onChange={(e: any) =>
                          handleChangeValue(
                            e?.target?.checked ? 1 : 0,
                            `${idBase}.do_not_override_data`,
                            'do_not_override_data'
                          )
                        }
                      />
                    </Flex>
                    <Flex left={4}>
                      <AddExtraText
                        style={{
                          marginBottom: '0px',
                          color: isPositionChanged ? 'gray' : 'red'
                        }}
                      >
                        Do not override above data to staff
                      </AddExtraText>
                    </Flex>
                  </Flex>
                )}
                {employees?.length ? (
                  <AddExtraText>
                    Position has been staffed. Revising labour will affect the assignments.
                  </AddExtraText>
                ) : null}
              </>
            ) : null}
          </Row>
          <Col md={12} xs={24}>
            <Input
              name={`${idBase}.notes`}
              id={`${idBase}.notes`}
              placeholder="Add Notes"
              onChange={(e: any) => handleChangeValue(e?.target.value, `${idBase}.notes`, 'notes')}
              value={staffingEstimateItem?.notes}
            />
          </Col>
          {device === 'mobile' ? (
            <>
              {showOverrideCheckBox.includes(values?.status) && (
                <Flex>
                  <Flex>
                    <Checkbox
                      id={`${idBase}.do_not_override_data`}
                      checked={staffingEstimateItem?.do_not_override_data}
                      disabled={isPositionChanged}
                      onChange={(e: any) =>
                        handleChangeValue(
                          e?.target?.checked ? 1 : 0,
                          `${idBase}.do_not_override_data`,
                          'do_not_override_data'
                        )
                      }
                    />
                  </Flex>
                  <Flex left={4}>
                    <AddExtraText
                      style={{
                        marginBottom: '0px',
                        color: isPositionChanged ? 'gray' : 'red'
                      }}
                    >
                      Do not override above data to staff
                    </AddExtraText>
                  </Flex>
                </Flex>
              )}
              {employees?.length ? (
                <AddExtraText>
                  Position has been staffed. Revising labour will affect the assignments.
                </AddExtraText>
              ) : null}
            </>
          ) : null}
        </Row>
      )}
    </>
  );
}

export default StaffingEstimateItem;
