import { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { Input } from '@atom/Input';
import RemoveIcon from '@assets/removerow.png';
import { updateRawData } from '@utils/index';
interface MenuManagementEditableItemProps {
  style: any;
  index: number;
  keyValue: string;
  onItemDelete: (
    index: number,
    ingridentsArray: any,
    setFieldValue: any,
    id?: number | null,
    is_associated?: boolean
  ) => boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: (e: any) => void;
  touched: { [field: string]: any };
  values: { [field: string]: any };
  errors: { [field: string]: any };
  fieldArrayName: string;
  inputFieldPlaceHolder: string;
  specialIngredientClickHandler?: (id: number) => void;
  isInputFieldDisabled?: boolean;
  showDeleteIcon?: boolean;
  scrollListToItemIndex?: (itemIndex: number) => void;
}

const MenuManagementEditableItem = (props: MenuManagementEditableItemProps) => {
  const {
    style,
    index,
    keyValue,
    onItemDelete,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    values,
    fieldArrayName,
    inputFieldPlaceHolder = '',
    specialIngredientClickHandler,
    isInputFieldDisabled = false,
    showDeleteIcon = true,
    scrollListToItemIndex
  } = props;
  const [localItemName, setLocalItemName] = useState<string>('');

  useEffect(() => {
    setLocalItemName(values?.[`${fieldArrayName}`]?.[index]?.name);
  }, []);

  const specialIngredientHandleClick = () => {
    if (fieldArrayName == 'special_ingredients') {
      specialIngredientClickHandler &&
        specialIngredientClickHandler(values?.[`${fieldArrayName}`]?.[index]?.id);
      updateRawData({ isEditForSpecialIngredientDetailsModal: true });
    }
  };

  const itemDeleteHandler = () => {
    const deleteItem: boolean = onItemDelete(
      index,
      values?.[`${fieldArrayName}`],
      setFieldValue,
      values?.[`${fieldArrayName}`]?.[index]?.id,
      values?.[`${fieldArrayName}`]?.[index]?.is_associated
        ? values?.[`${fieldArrayName}`]?.[index]?.is_associated
        : undefined
    );

    if (deleteItem) {
      setTimeout(() => {
        scrollListToItemIndex && scrollListToItemIndex(index ? index : 1);
      }, 300);
    }
  };

  return (
    <Row gutter={[16, 16]} key={keyValue} style={style}>
      <Col md={11} xs={20} onClick={specialIngredientHandleClick}>
        <Input
          disabled={isInputFieldDisabled}
          id={`${fieldArrayName}[${index}].name`}
          name={`${fieldArrayName}[${index}].name`}
          value={localItemName}
          placeholder={inputFieldPlaceHolder}
          onChange={(e: any) => {
            setLocalItemName(e?.target?.value);
            setTimeout(() => {
              setFieldValue(`${fieldArrayName}[${index}].name`, e?.target?.value);
            }, 200);
          }}
          onBlur={handleBlur}
          error={
            touched?.[`${fieldArrayName}`]?.[index]?.name &&
            (errors?.[`${fieldArrayName}`] as { name: string }[])?.[index]?.name
          }
        />
      </Col>
      <Col md={1} xs={4} className="centerPosition" style={{ opacity: 1, textAlign: 'center' }}>
        {showDeleteIcon && (
          <img
            style={{ paddingTop: '10px', cursor: 'pointer' }}
            src={RemoveIcon}
            alt="Delete button"
            onClick={itemDeleteHandler}
          />
        )}
      </Col>
    </Row>
  );
};

export default MenuManagementEditableItem;
