import { Col, Divider, Row, Spin } from 'antd';
import {
  IAdditionalContact,
  IAdditionalContacts,
  ICategoryType,
  IVendorCatergoryType,
  IVendorDataType
} from '../types';
import React, { useEffect, useState } from 'react';
import {
  VENDOR_CREATE_STATUS,
  addEditValidations,
  addEditVendor,
  getInitValues,
  manualValidate
} from './helper';
import {
  VENDOR_DETAILS_TABS,
  VENDOR_MANAGEMENT_TABS,
  VendorCategoriesDummyData,
  changeVendorStatus,
  getButton,
  getVendor,
  getVendorCategories
} from '../helper';
import { showErrorToast, showToast } from '@utils/index';
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router';

import AddEditDocuments from './AddEditDocuments';
import AddEditInfo from './AddEditInfo';
import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import ConfirmModal from '@organism/ConfimationModal';
import EditIcon from '@assets/edit-2.svg';
import { Flex } from '@atom/Flex';
import HorizontalTab from '@molecules/HorizontalTabs';
import Item from 'antd/lib/list/Item';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { getUniqueDomId } from '@fullcalendar/react';

function VendorAddEditPages() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tabType, setTabType] = useState(VENDOR_DETAILS_TABS.INFO);
  const [initialValues, setInitialValues] = useState<IVendorDataType>({});
  const [isVendorLoading, setIsVendorLoading] = useState<boolean>(true);
  const [vendorData, setVendorData] = useState<IVendorDataType>({});
  const [vendorCatergoriesData, setVendorCatergoriesData] = useState<ICategoryType[] | []>([]);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState<boolean>(false);
  const [isReactivateModalVisible, setIsReactivateModalVisible] = useState<boolean>(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const location = useLocation();
  const isEdit = location.pathname?.split('/')?.pop() === 'edit';

  useEffect(() => {
    updateInitialValues();
    getVendorCategoriesMethod();
  }, [id]);

  const updateInitialValues = async () => {
    setIsVendorLoading(true);
    if (id) {
      try {
        const res = await getVendor(id);
        if (res?.success) {
          const documentsList = res?.data?.documents?.map((doc: any) => {
            doc.uid = getUniqueDomId();
            doc.name = doc.document_name;
            return doc;
          });
          let additionalContacts = [];
          if (res?.data?.additional_contacts?.length > 0) {
            additionalContacts = res?.data?.additional_contacts?.map(
              (contact: IAdditionalContacts) => {
                return {
                  ...contact,
                  country_code:
                    !!contact?.country_code && !contact?.country_code?.includes('+')
                      ? `+${contact?.country_code}`
                      : contact?.country_code
                };
              }
            );
          }
          const countryCode =
            !!res?.data?.country_code && !res?.data?.country_code?.includes('+')
              ? `+${res?.data?.country_code}`
              : res?.data?.country_code;
          setVendorData(
            res?.data
              ? {
                  ...res?.data,
                  documents: documentsList,
                  additional_contacts: additionalContacts,
                  country_code: countryCode
                }
              : {}
          );
        }
      } catch (error) {}
    }
    const initVal: IVendorDataType = getInitValues(id ? vendorData : {});
    setInitialValues(initVal);
    setIsVendorLoading(false);
  };

  const getVendorCategoriesMethod = async () => {
    setIsVendorLoading(true);
    try {
      const res = await getVendorCategories();
      if (res?.success) {
        setVendorCatergoriesData(
          res?.data?.map((item: any) => ({
            ...item,
            vendor_category_id: item?.id,
            label: item?.name,
            title: item?.name,
            value: item?.id,
            checkable: !(item?.id === -1)
          })) ?? {}
        );
      }
    } catch (error) {}
    setIsVendorLoading(false);
  };

  const onSubmit = async () => {
    setIsCreateLoading(true);
    try {
      const vendor_categories = formik.values?.vendor_categories?.map(
        (item: any) => item?.vendor_category_id
      );
      if (!id) delete formik.values?.status;
      if (!id) delete formik.values?.registered_at;
      if (!id) delete formik.values?.categories;
      const status =
        formik.values?.status === VENDOR_CREATE_STATUS.DRAFT
          ? VENDOR_CREATE_STATUS.ACTIVE
          : formik.values?.status;
      const countryCode =
        !!formik.values?.country_code && !formik.values?.country_code?.includes('+')
          ? `+${formik.values?.country_code}`
          : '+1';
      const additionalContacts = formik.values?.additional_contacts?.map((contact) => ({
        ...contact,
        country_code:
          !!contact?.country_code && !contact?.country_code?.includes('+')
            ? `+${contact?.country_code}`
            : contact?.country_code
      }));
      const res: any = await addEditVendor(
        {
          ...formik.values,
          status,
          country_code: countryCode,
          additional_contacts: additionalContacts,
          vendor_categories
        },
        id
      );
      if (res?.success) {
        showToast({
          message: 'Success!',
          description: 'Vendor Saved Successfully!.'
        });
        if (tabType === VENDOR_DETAILS_TABS.DOCUMENTS) {
          formik.resetForm();
          navigate(`/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`);
        } else {
          if (!res?.data?.id) {
            setTabType && setTabType(VENDOR_DETAILS_TABS.INFO);
            return;
          }
          navigate(`/vendors/${res?.data?.id}/edit`);
          setTabType && setTabType(VENDOR_DETAILS_TABS.DOCUMENTS);
        }
      } else {
        showErrorToast({
          message: 'Failed!',
          description: res?.error ?? 'Something went wrong'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: error?.error ?? 'Something went wrong'
      });
    }
    setIsCreateLoading(false);
  };

  const formik = useFormik({
    initialValues: id ? vendorData : initialValues,
    enableReinitialize: true,
    validate: (value) => {
      try {
        validateYupSchema(value, addEditValidations, true, value);
      } catch (error) {
        return yupToFormErrors(error);
      }
      return {};
    },
    validationSchema: addEditValidations,
    validateOnMount: true,
    onSubmit: onSubmit
  });

  const changeStatusMethod = async (text: string, status: number) => {
    if (!id) return;
    isVendorLoading && setIsVendorLoading(true);
    const res = await changeVendorStatus(id, status);
    if (res?.success) {
      setIsReactivateModalVisible(false);
      setIsDeactivateModalVisible(false);
      showToast({ message: 'Success!', description: `Vendor ${text}` });
      navigate(`/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`);
    } else {
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
    }
  };

  const getTabs = () => {
    const tabValues = [
      {
        tab: 'INFO',
        content: (
          <AddEditInfo
            formik={formik}
            vendorCatergoriesData={vendorCatergoriesData || []}
            isDraftLoading={isDraftLoading}
            isCreateLoading={isCreateLoading}
            setTabType={setTabType}
            handleSaveDraft={handleSaveDraft}
          />
        ),
        key: VENDOR_DETAILS_TABS.INFO,
        getUrl: VENDOR_DETAILS_TABS.INFO
      },
      {
        tab: 'DOCUMENTS',
        content: <AddEditDocuments formik={formik} />,
        key: VENDOR_DETAILS_TABS.DOCUMENTS,
        getUrl: VENDOR_DETAILS_TABS.DOCUMENTS
      }
    ];
    return tabValues;
  };

  const handleSaveDraft = async () => {
    const res = manualValidate(formik);
    if (res) return;
    setIsDraftLoading(true);
    try {
      const vendorCategories = formik.values?.vendor_categories?.map(
        (item: any) => item?.vendor_category_id
      );
      const additionalContacts = formik.values?.additional_contacts?.map((contact) => ({
        ...contact,
        country_code:
          !!contact?.country_code && !contact?.country_code?.includes('+')
            ? `+${contact?.country_code}`
            : contact?.country_code
      }));
      const countryCode =
        !!formik.values?.country_code && !formik.values?.country_code?.includes('+')
          ? `+${formik.values?.country_code}`
          : '+1';
      const res: any = await addEditVendor(
        {
          ...formik.values,
          vendor_categories: vendorCategories,
          additional_contacts: additionalContacts,
          country_code: countryCode,
          status: VENDOR_CREATE_STATUS.DRAFT
        },
        id
      );
      if (res?.success) {
        formik.resetForm();
        navigate(`/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`);
        showToast({
          message: 'Success!',
          description: 'Vendor Saved as Draft.'
        });
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    setIsDraftLoading(false);
  };

  if (isVendorLoading) {
    return <CenterSpiner />;
  }

  return (
    <PageLayout title={'Vendor Managment'}>
      <PageTitle
        text="VENDOR MANAGEMENT"
        isBack={true}
        isBottom={true}
        bordered={true}
        path={`/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`}
      >
        {id && !isEdit && (
          <Button
            variant="ghost"
            text="Edit"
            icon={<img src={EditIcon} alt="Edit" />}
            onClick={() => navigate(`/vendors/${id}/edit`)}
          />
        )}
      </PageTitle>

      <Row gutter={24}>
        <Col span={24}>
          <HorizontalTab
            value={getTabs()}
            activeKey={tabType}
            onChange={(tab: any) => {
              setTabType(tab);
              // let newurl = `/vendors/${id}/edit/#${tab}`;
              // navigate(newurl);
            }}
          />
        </Col>
      </Row>
      <ConfirmModal
        title="Are you sure you want to Deactivate this Vendor?"
        okText="Deactivate"
        cancelText="Cancel"
        visible={isDeactivateModalVisible}
        onOK={() => changeStatusMethod('Deactivated', 0)}
        onCancel={() => setIsDeactivateModalVisible(false)}
      >
        This Vendor will be tagged as "INACTIVE"
      </ConfirmModal>
      <ConfirmModal
        title="Are you sure you want to Reactivate this Vendor?"
        okText="Reactivate"
        cancelText="Cancel"
        visible={isReactivateModalVisible}
        onOK={() => changeStatusMethod('Reactivated', 1)}
        onCancel={() => setIsReactivateModalVisible(false)}
      >
        This Vendor will be tagged as "ACTIVE"
      </ConfirmModal>
    </PageLayout>
  );
}

export default VendorAddEditPages;
