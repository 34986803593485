export const EventModalContent = {
  Release_Title: 'Are you sure you want to Release Final Details?',
  Release_SubTitle: 'This will send email to all assigned staffs.',
  Confirm_Title: 'Are you sure you want to confirm this event?',
  Unconfirm_Title: 'Are you sure you want to unconfirm this event?',
  Confirm_SubTitle:
    'If you confirm this event, all assigned staff members will be marked as staffed.',
  Unconfirm_SubTitle:
    'If you unconfirm this event all assigned staff members will be marked as available.'
};

export const STATUS = {
  UNCONFIRM: 0,
  CONFIRM: 1
};

export const COVER_IMG =
  'https://foodude.s3.us-east-2.amazonaws.com/image-gallery/r5O8TG1HahbVovxuAey2NvNztU5xmT5ABkHfwEJe.jpg';

export const Upload_Type = {
  COVER: 1,
  LOGO: 2
};
