import DataTable from '@organism/DataTable/DataTable';
import { useEffect, useState } from 'react';
import { getMenuDetails } from '../helper';
import { expandedIngredientsTableCols } from './columns';
import { getSpecialIngredientMappedValues } from './helper';

interface ExpandedIngredientsProps {
  specialIngredientId: number;
}

const ExpandedIngredients = (props: ExpandedIngredientsProps) => {
  const { specialIngredientId } = props;
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [specialIngredientDetails, setSpecialIngredientDetails] = useState<any>(null);

  const fetchSpecialIngredientDetailsHandler = async (id: number) => {
    setIsTableLoading(true);
    const res: any = await getMenuDetails(`special-ingredients/${id}`);
    if (!res?.error) {
      setSpecialIngredientDetails(getSpecialIngredientMappedValues(res?.data));
    }
    setIsTableLoading(false);
  };

  useEffect(() => {
    fetchSpecialIngredientDetailsHandler(specialIngredientId);
  }, []);
  return (
    <DataTable
      className="expandedTableRow"
      loading={isTableLoading}
      columns={expandedIngredientsTableCols}
      dataSource={specialIngredientDetails?.ingredients || []}
      renderEmpty={() => <>No Data Found!</>}
      expandable={{
        expandedRowRender: (record: any) => <></>,
        rowExpandable: (record: any) => false
      }}
    />
  );
};

export default ExpandedIngredients;
