import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import FormDataView from '@atom/FormDataView';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { Tooltip } from 'antd';
import { TagComponent } from '@atom/Tag';
import { PageTitle } from '@molecules/PageTitle';
import ConfirmModal from '@organism/ConfimationModal';
import DataTable from '@organism/DataTable/DataTable';
import ModalComponent from '@organism/Modal/Modal';
import {
  getManageAssigneeData,
  getManageProductData,
  getPrepMenu
} from '@services/foodPrepServices';
import { showErrorToast } from '@utils/index';
import { Col, Divider, Row } from 'antd';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  ManageProductModalFilterTypes,
  PrepListMenuTypes,
  SelectedSubcategoryType
} from '../../types';
import {
  getManageProductColumns,
  intialFiltersStateForModal,
  manageProductModalContent,
  MANAGE_PRODUCT_MODAL_TYPES
} from '../helper';
import { ProductDetailWrapper, ViewTableCell } from '../style';
import AlertCircleIcon from '@assets/alert-circle.svg';
import SearchableSelect from '@atom/SearchableSelect';
import SpecialIngredientDetailsModal from '@pages/GlobalSettingsPage/MenuManagement/SpecialIngredients/SpecialIngredientDetailsModal';

interface ManageProductModalProps {
  manageProduct: boolean;
  showManageProduct: (value: boolean) => void;
  selectedSubcategoryData: SelectedSubcategoryType;
  setSelectedSubcategoryData: (value: SelectedSubcategoryType) => void;
  dropdownData: any;
  setconfirmPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  details: any;
  setDetails: any;
  onConfirmSaveHandler: (data: any) => void;
  startDate: string;
  endDate: string;
}

const ManageProductModal = (props: ManageProductModalProps) => {
  const {
    manageProduct,
    showManageProduct,
    selectedSubcategoryData,
    setSelectedSubcategoryData,
    dropdownData,
    setconfirmPopUp,
    details,
    setDetails,
    onConfirmSaveHandler,
    startDate,
    endDate
  } = props;

  const [tableData, setTableData] = useState<any>();
  const [tempTableData, setTempTableData] = useState<any>(null);
  const [filters, setFilters] = useState<ManageProductModalFilterTypes>({
    ...intialFiltersStateForModal
  });
  const [isConfirmationModal, setIsConfirmationModal] = useState<boolean>(false);
  const [confirmationModalType, setConfirmationModalType] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [assigneOptions, setAssigneOptions] = useState<any>();
  const [filteredassignesOptions, setFilteredassignesOptions] = useState<any>();
  const [prepList, setPrepList] = useState<Array<PrepListMenuTypes>>();
  const [headChefList, setHeadChefList] = useState<any>([]);
  const [packedQtyerror, setPackedQtyError] = useState<boolean>(false);
  const [initialHeadCheff, setInitialHeadCheff] = useState<number | null>(null);
  const [isIngredientModal, setIngredientModal] = useState<boolean>(false);
  const [specialIngredientId, setSpecialIngredientId] = useState<number>();

  const getSelectedSubCatData = async (selectedSubcategoryData: SelectedSubcategoryType) => {
    setLoading(true);
    try {
      const res = await getManageProductData(selectedSubcategoryData);
      if (res?.success) {
        setTableData(res?.data);
        setTempTableData(res?.data);
        setInitialHeadCheff(res?.data?.head_chef_id);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something Went Wrong'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: error || 'Something Went Wrong'
      });
    }
    setLoading(false);
  };

  const getAssigneOptionsData = async () => {
    setLoading(true);
    try {
      const res = await getManageAssigneeData();
      if (res?.success) {
        setAssigneOptions(res?.data);
        setFilteredassignesOptions(res?.data);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something Went Wrong'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: error || 'Something Went Wrong'
      });
    }
    setLoading(false);
  };

  const getPrepLisMenuData = async () => {
    setLoading(true);
    try {
      const res = await getPrepMenu();
      if (res?.success) {
        setPrepList(res?.data);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something Went Wrong'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: error || 'Something Went Wrong'
      });
    }
    setLoading(false);
  };

  const filterAssigneeListHandler = (e: string) => {
    const listToSet =
      assigneOptions?.filter((item: any) =>
        item?.name?.toLowerCase()?.includes(e?.toLowerCase())
      ) || [];
    setFilteredassignesOptions(listToSet);
  };

  useEffect(() => {
    getAssigneOptionsData();
    getPrepLisMenuData();
    getSelectedSubCatData(selectedSubcategoryData);
    setFilters({ ...filters, ...selectedSubcategoryData });
    getSelectedSubCatDataforSubCategory({ ...filters, ...selectedSubcategoryData });
    setHeadChefList(dropdownData?.headChefsList);
  }, []);

  const getSelectedSubCatDataforSubCategory = async (
    selectedSubcategoryData: SelectedSubcategoryType
  ) => {
    setLoading(true);
    try {
      const res = await getManageProductData(selectedSubcategoryData);
      if (res?.success) {
        setTableData(res?.data);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something Went Wrong'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: error || 'Something Went Wrong'
      });
    }
    setLoading(false);
  };

  const handleCancel = () => {
    if (confirmationModalType === MANAGE_PRODUCT_MODAL_TYPES.LEAVE_FORM) {
      setIsConfirmationModal(false);
      showManageProduct(false);
    }
  };

  const handleSave = () => {
    if (confirmationModalType === MANAGE_PRODUCT_MODAL_TYPES.LEAVE_FORM) {
      manageProductSaveHandler();
    }
  };

  const onFilterChangeHandler = (e: ChangeEvent) => {
    const { id, value } = e.target as HTMLInputElement;
    const updatedFilters = { ...filters, [id]: value };
    setFilters({ ...updatedFilters, ...selectedSubcategoryData });
    getSelectedSubCatDataforSubCategory({ ...updatedFilters, ...selectedSubcategoryData });
  };

  const onClear = (data: any) => {
    const { id, value } = data;
    const updatedFilters = { ...filters, [id]: value };
    setFilters({ ...updatedFilters, ...selectedSubcategoryData });
    getSelectedSubCatDataforSubCategory({ ...updatedFilters, ...selectedSubcategoryData });
  };

  const handleViewPrepList = async (data: any) => {
    if (prepList?.length) {
      if (data?.prep_list_id) {
        const filterPrepList = prepList?.find(
          (val: PrepListMenuTypes) => val?.id === data?.prep_list_id
        );
        window.open(
          `${
            process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : '/'
          }food-preparation/${filterPrepList?.name}/?ingredient_name=${encodeURIComponent(
            data?.ingredient?.name
          )}&start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(
            endDate
          )}&event_code=${encodeURIComponent(tableData?.event_id)}`,
          '_blank'
        );
      } else {
        showErrorToast({
          message: 'Sorry!',
          description: `${data?.ingredient?.name} is not added in prep list`
        });
      }
    }
  };

  const handleHeadChefSearch = (e: any) => {
    setHeadChefList(() => {
      if (e) {
        return dropdownData?.headChefsList?.filter(
          (item: any) => item?.name?.toLowerCase()?.indexOf(e?.toLowerCase()) > -1
        );
      } else {
        return dropdownData?.headChefsList;
      }
    });
  };

  const handleSpecialIngredient = (id: number) => {
    setSpecialIngredientId(id);
    setIngredientModal(true);
  };

  const cols = getManageProductColumns(
    filters,
    onFilterChangeHandler,
    assigneOptions,
    filteredassignesOptions,
    filterAssigneeListHandler,
    setTableData,
    tableData,
    onClear,
    handleViewPrepList,
    handleSpecialIngredient
  );

  useEffect(() => {
    tableData?.product_sub_category_ingredients?.forEach((e: any) => {
      tempTableData?.product_sub_category_ingredients?.forEach((f: any, index: number) => {
        if (e.id === f.id) {
          tempTableData.product_sub_category_ingredients[index] = e;
          return;
        }
      });
    });
    let temp = { ...tableData };
    temp.product_sub_category_ingredients = tempTableData?.product_sub_category_ingredients;
    setTempTableData(temp);
    setDetails({ ...temp });
  }, [tableData]);

  const manageProductSaveHandler = () => {
    if (
      tableData?.head_chef_id === initialHeadCheff ||
      (!tableData?.head_chef_id && !initialHeadCheff)
    ) {
      setLoading(true);
      onConfirmSaveHandler({ is_assigning_all_event_product: '0', ...details });
      setLoading(false);
    } else {
      showManageProduct(false);
      setconfirmPopUp(true);
    }
  };

  return (
    <ModalComponent
      visible={manageProduct}
      onCancel={() => {
        setSelectedSubcategoryData({});
        showManageProduct(false);
      }}
      modalWidth="calc(100vw - 200px) !important"
      closable={false}
    >
      <Row>
        <Col lg={12}>
          <PageTitle text="Manage Product" />
        </Col>
        <Col lg={12}>
          <Flex justifyContent={'end'} gap={24}>
            <Button
              text="Cancel"
              variant={'secondary'}
              onClick={() => {
                setConfirmationModalType(MANAGE_PRODUCT_MODAL_TYPES.LEAVE_FORM);
                setIsConfirmationModal(true);
              }}
            />
            <Button
              text="Save"
              isDisable={packedQtyerror}
              onClick={() => manageProductSaveHandler()}
            />
          </Flex>
        </Col>
      </Row>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px 22px 0px' }} />

      <ProductDetailWrapper>
        <Row style={{ textAlign: 'left' }}>
          <Col lg={4} xs={8}>
            <FormDataView
              heading="PRODUCT NAME"
              titleColor="grey"
              value={tableData?.product?.product_name || '-'}
            />
          </Col>
          <Col lg={4} xs={8}>
            <FormDataView
              heading="SUB CATEGORY"
              titleColor="grey"
              value={tableData?.subcategory?.name || '-'}
            />
          </Col>
          <Col lg={4} xs={8}>
            <FormDataView
              heading="EVENT CODE"
              titleColor="grey"
              value={<TagComponent text={tableData?.event_code || '-'} />}
            />
          </Col>
          <Col lg={4} xs={8}>
            <FormDataView heading="QTY" titleColor="grey" value={tableData?.quantity || '-'} />
          </Col>
          <Col lg={4} xs={8}>
            <FormDataView
              titleColor="grey"
              heading="LAST UPDATED BY"
              value={tableData?.updated_by_users?.name || '-'}
            />
          </Col>
          <Col lg={4} xs={8}>
            <FormDataView
              titleColor="grey"
              heading="LAST UPDATED"
              value={moment(tableData?.updated_at).format('MMM DD, YYYY, h:mm a') || '-'}
            />
          </Col>
        </Row>
      </ProductDetailWrapper>

      <Divider style={{ borderColor: '#E0E0E0', margin: '0px 0px 22px 0px' }} />

      <Row gutter={16}>
        <Col lg={6}>
          <Flex alignItems={'center'}>
            <Label text="Event Head Chef" />
            <Tooltip title="Kitchen staff name">
              <img
                src={AlertCircleIcon}
                alt="alert"
                width="20px"
                style={{ margin: '0px 0px 16px 10px' }}
              />
            </Tooltip>
          </Flex>

          <SearchableSelect
            style={{ textAlign: 'initial' }}
            id="name"
            name="name"
            keyValue={'id'}
            options={headChefList}
            placeholdertitle={'Select Head Chef'}
            onChange={(e: any) => {
              let data = { ...tableData };
              data.head_chef_id = e?.id;
              setTableData(data);
            }}
            onSearch={(e: any) => handleHeadChefSearch(e)}
            prefix={true}
            labelValue={'name'}
            value={headChefList?.find((e: any) => e?.id === tableData?.head_chef_id)}
          />
        </Col>
        <Col lg={4}>
          <Label text="Packed Qty"></Label>
          <Flex alignItems={'baseline'} gap={5}>
            <Input
              name="packed_qty"
              placeholder={'0'}
              value={tableData?.packed_qty}
              onChange={(e) => {
                let data = { ...tableData };
                const valueToUpdate = e?.target?.value?.replace(/\D/g, '');
                if (valueToUpdate > tableData.quantity) {
                  setTableData({ ...data, packed_qty: valueToUpdate });
                  setPackedQtyError(true);
                } else {
                  setTableData({ ...data, packed_qty: valueToUpdate });
                  setPackedQtyError(false);
                }
              }}
              error={packedQtyerror && 'Packed quantity should not be greater than total Quantity'}
            />
            <span>/{tableData?.quantity}</span>
          </Flex>
        </Col>
      </Row>
      <ViewTableCell>
        <DataTable
          className="fixTD"
          loading={isLoading}
          columns={cols}
          dataSource={tableData?.product_sub_category_ingredients}
          onFetch={() => {}}
          renderEmpty={() => <div>No Data Found.</div>}
        />
      </ViewTableCell>
      {isConfirmationModal && (
        <ConfirmModal
          visible={isConfirmationModal}
          onCancel={handleCancel}
          title={manageProductModalContent?.[confirmationModalType]?.heading}
          onOK={handleSave}
          okText={manageProductModalContent?.[confirmationModalType]?.okText}
          cancelText={manageProductModalContent?.[confirmationModalType]?.cancelText}
        >
          {manageProductModalContent?.[confirmationModalType]?.message}
        </ConfirmModal>
      )}

      {isIngredientModal && (
        <SpecialIngredientDetailsModal
          isModalVisible={isIngredientModal}
          modalCloseHandler={() => {
            setIngredientModal(false);
            setSpecialIngredientId(undefined);
          }}
          specialIngredientId={specialIngredientId}
          isModalEditable={false}
        />
      )}
    </ModalComponent>
  );
};

export default ManageProductModal;
