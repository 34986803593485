import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import Switch from '@atom/Switch';
import { CURRENCY } from '@constants/index';
import { Col, Row } from 'antd';
const DepositPaymentAndHstSection = (props: any) => {
  const { formik } = props;
  const { touched, errors, handleChange, values, setFieldValue, setValues } = formik;

  const handleToggleUpdate = (e: boolean) => {
    const updatedValues = { ...values };
    updatedValues.event_dates.fees.deposit = '';
    updatedValues.event_dates.fees.deposit_percent = '';
    updatedValues.event_dates.fees.is_deposit_percent = e ? 1 : 0;
    setValues(updatedValues);
  };

  return (
    <>
      <Row gutter={16} align="middle">
        <Col lg={4} xs={24}>
          <Label text="Deposit" isMandatory={false} />
          <Input
            id={`event_dates.fees.deposit`}
            name={
              values?.event_dates?.fees?.is_deposit_percent
                ? `event_dates.fees.deposit_percent`
                : `event_dates.fees.deposit`
            }
            prefix={values?.event_dates?.fees?.is_deposit_percent ? '%' : CURRENCY}
            value={
              values?.event_dates?.fees?.is_deposit_percent
                ? values?.event_dates?.fees?.deposit_percent
                : values?.event_dates?.fees?.deposit
            }
            error={
              (touched?.event_dates?.fees?.deposit ||
                touched?.event_dates?.fees?.deposit_percent) &&
              (errors?.event_dates?.fees?.deposit || errors?.event_dates?.fees?.deposit_percent)
            }
            onChange={handleChange}
          />
        </Col>
        <Col lg={8} xs={24}>
          <Flex gap={12} justifyContent="end" alignItems={'center'}>
            <Switch
              id={`event_dates.fees.is_deposit_percent`}
              checked={values?.event_dates?.fees?.is_deposit_percent}
              defaultChecked={values?.event_dates?.fees?.is_deposit_percent}
              onChange={handleToggleUpdate}
            />
            <span style={{ marginLeft: '6px' }}>Customize as a %</span>
          </Flex>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={4} xs={24}>
          <Label text="Payment" isMandatory={false} />
          <Input
            id={`event_dates.fees.payment`}
            name={`event_dates.fees.payment`}
            prefix={CURRENCY}
            value={values?.event_dates?.fees?.payment}
            error={touched?.event_dates?.fees?.payment && errors?.event_dates?.fees?.payment}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4} lg={4} xs={24}>
          <Label text="HST Fees" isMandatory={false} />
          <Input
            id={`event_dates.fees.hst_fees_percent`}
            name={`event_dates.fees.hst_fees_percent`}
            prefix="%"
            value={values?.event_dates?.fees?.hst_fees_percent}
            error={
              touched?.event_dates?.fees?.hst_fees_percent &&
              errors?.event_dates?.fees?.hst_fees_percent
            }
            onChange={handleChange}
          />
        </Col>
        <Col md={20} lg={20} xs={24}>
          <Label text="HST Fees Instructions" isMandatory={false} />
          <Input
            max={200}
            id={`event_dates.fees.hst_fees_instructions`}
            name={`event_dates.fees.hst_fees_instructions`}
            value={values?.event_dates?.fees?.hst_fees_instructions}
            error={
              touched?.event_dates?.fees?.hst_fees_instructions &&
              errors?.event_dates?.fees?.hst_fees_instructions
            }
            onChange={handleChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default DepositPaymentAndHstSection;
