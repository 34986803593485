import services from '@services/index';
import { IStaffManagementListFilterType } from '../types';
import TableHeader from './TableHeader';
import { ChangeEvent } from 'react';
import { Flex } from '@atom/Flex';
import { H4Typography } from '@constant/typography/Typography';
import * as yup from 'yup';
import { Button } from '@atom/Buttons';
import StaffTableItem from './StaffTableItem';
import { digitsRegExp } from '@constants/index';
import { Col, Row } from 'antd';
import moment from 'moment';
import { NavigateFunction } from 'react-router-dom';

export const getColumns = (
  filterValue: IStaffManagementListFilterType,
  onChange: (a: ChangeEvent | null, key?: string) => void,
  onPress: any,
  navigate: NavigateFunction
) => {
  const columns = [
    {
      title: (
        <TableHeader
          columnTitle=""
          name="code"
          value={filterValue.code ?? ''}
          placeholder="Code"
          onChange={(e) => {
            !(!filterValue.code && !(e?.target as HTMLInputElement)?.value) && onChange(e);
          }}
          style={{ width: '180px' }}
        />
      ),
      dataIndex: 'event_code',
      render: (data: any, row: any) => {
        return (
          <Flex direction="column" alignItems={'center'}>
            <H4Typography>Code</H4Typography>
            {data ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/events/${row?.id}/view`);
                }}>
                {data}
              </span>
            ) : (
              '-'
            )}

            <H4Typography style={{ marginTop: 10 }}>Date</H4Typography>
            {row.event_date ? moment(row.event_date).format('ddd, MMM DD, YYYY') : '-'}
          </Flex>
        );
      }
    },
    {
      title: (
        <Row style={{ width: '240px' }}>
          <Row style={{ width: '100%' }} justify="end">
            <Col md={24}>
              {!!filterValue?.date && (
                <Button
                  text="Clear"
                  variant="ghost"
                  style={{ padding: '0px', marginLeft: 'auto', lineHeight: '0px' }}
                  onClick={() => onChange(null, 'date')}
                />
              )}
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <TableHeader
              columnTitle=""
              name="date"
              value={filterValue.date ?? ''}
              placeholder="DD/MM"
              type="date"
              dateformat={'YYYY-MM-DD'}
              onChange={(e) => onChange(e, 'date')}
            />
          </Row>
        </Row>
      ),
      dataIndex: 'event_date',
      render: (data: any, row: any) => {
        const formatTime = (time: string) => {
          return time ? moment(time, 'HH:mm:ss').format('hh:mm A') : '';
        };

        const startTime = formatTime(row?.start_time);
        const endTime = formatTime(row?.end_time);

        const time = startTime ? `${startTime}\n${endTime}` : endTime || '--';

        return (
          <Flex direction="column" alignItems={'center'}>
            <H4Typography>Client</H4Typography>
            {row?.customer_name || '-'}
            <H4Typography style={{ marginTop: 10 }}>Time</H4Typography>
            <span style={{ whiteSpace: 'pre-line', lineHeight: '1.5rem' }}>{time}</span>
          </Flex>
        );
      }
    },
    {
      title: (
        <TableHeader
          columnTitle=""
          name="venue"
          value={filterValue.venue ?? ''}
          placeholder="Venue"
          onChange={(e) => {
            !(!filterValue.venue && !(e?.target as HTMLInputElement)?.value) && onChange(e);
          }}
          style={{ width: '400px' }}
        />
      ),
      dataIndex: 'venue_name',
      render: (data: any, row: any) => {
        return (
          <Flex justifyContent={'space-between'} style={{ width: '100%' }}>
            <Flex
              direction="column"
              alignItems={'center'}
              style={{ width: '50%', paddingRight: '10px' }}>
              <H4Typography>Account Manager</H4Typography>
              {row.account_manager || '-'}

              <H4Typography style={{ marginTop: 10 }}>Event Name</H4Typography>
              <Flex style={{ flexWrap: 'wrap' }}>{row.event_name || '-'}</Flex>
            </Flex>
            <Flex direction="column" alignItems={'center'} style={{ width: '50%' }}>
              <H4Typography>Guests</H4Typography>
              {row.total_guest || '-'}

              <H4Typography style={{ marginTop: 10 }}>Venue</H4Typography>
              <Flex style={{ flexWrap: 'wrap' }}>{row.venue_name || '-'}</Flex>
            </Flex>
          </Flex>
        );
      }
    },
    {
      title: (
        <TableHeader
          columnTitle=""
          name="employee"
          value={filterValue.employee ?? ''}
          placeholder="Employee"
          onChange={(e) => {
            !(!filterValue.employee && !(e?.target as HTMLInputElement)?.value) && onChange(e);
          }}
          style={{ width: '400px' }}
        />
      ),
      dataIndex: 'staffs',
      width: 800,
      render: (data: any, row: any) => {
        return (
          <Flex direction="column" alignItems={'start'}>
            {row.staffs.map((staff: any) => {
              return <StaffTableItem staff={staff} />;
            })}
          </Flex>
        );
      }
    },
    {
      title: '',
      dataIndex: '',
      render: (data: any, row: any) => {
        return (
          <Button
            style={{ verticallyAlign: 'top' }}
            variant="secondary"
            text={'Add'}
            onClick={() => onPress(row)}
            className="cancel-btn"
          />
        );
      }
    }
  ];
  return columns;
};

export const addStaff = async (data: any) => {
  try {
    const res = (await services.post(`/staffs/add-position`, data)) as {
      data: any;
    };
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const addNewStaffValidations = yup.object().shape({
  end_time: yup.string().required('End Time is required'),
  position_id: yup.string().required('Position is required').nullable(),
  start_time: yup.string().required(' Start Time is required'),
  quantity: yup
    .number()
    .typeError('Qty must be a number')
    .max(255, 'Qty must be less than or equal to 255')
    .required('Quantity is required')
});

export const newStaffObject = {
  start_time: '',
  end_time: '',
  quantity: '',
  position_id: null,
  event_id: '',
  event_date: ''
};

export const getPosition = async () => {
  try {
    const res = (await services.get(`/staffs/get-positions`)) as {
      data: any;
    };
    return res;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const updateQuantity = async (id: any, qty: number) => {
  try {
    const res = await services.post(`/staffs/update-quantity/${id}/${qty}`, {});
    return res;
  } catch (error) {}
};

export const removeEmployeeFromStaffing = async (id: number) => {
  try {
    const res = await services.get(`/staffs/remove/${id}`);
  } catch (error) {}
};

export const removePosition = async (id: any) => {
  try {
    const res = await services.get(`/staffs/remove-position/${id}`);
    return res;
  } catch (error) {}
};
