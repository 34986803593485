import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { SelectInputWithCheckBox } from '@atom/Select';
import { TextAreaInput } from '@atom/TextArea';
import { H2Typography, H4Typography } from '@constant/typography/Typography';
import ModalComponent from '@organism/Modal/Modal';
import {
  getReportsToList,
  ReportsToType
} from '@pages/EmployeeManagementPage/AddEditEmployee/helper';
import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { ModalComponentWrapper } from '../style';
import { sendMessageSchema } from './helper';

interface SendMessageModalProps {
  visible: boolean;
  onOk: (values: any) => void;
  onCancel: () => void;
  staffList?: any[];
}

const SendMessageModal: React.FC<SendMessageModalProps> = ({
  visible = false,
  onOk,
  onCancel,
  staffList = []
}) => {
  const [isLoading, seIsLoading] = useState<boolean>(true);
  const formik = useFormik({
    validationSchema: sendMessageSchema,
    onSubmit: (values) => {
      onOk(values);
      resetForm();
    },
    initialValues: {
      message: '',
      subject: '',
      employee_id: staffList?.map((item: any) => item?.emp_id) || []
    }
  });
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    setFieldValue,
    touched,
    resetForm
  } = formik;

  const [reportsToList, setReportsToList] = useState<ReportsToType[]>([]);

  useEffect(() => {
    (async () => {
      seIsLoading(true);
      visible &&
        (await getReportsToList('0').then((result) => {
          if (!result.error) {
            setReportsToList(result.data || []);
          }
        }));
      seIsLoading(false);
    })();
  }, [visible]);

  const value = values?.employee_id.filter((id) => {
    return id !== undefined;
  });

  return (
    <ModalComponentWrapper>
      <ModalComponent
        visible={visible}
        onCancel={onCancel}
        modalWidth="562px !important"
        align="left !important"
      >
        <Row gutter={24} className="centerd">
          <Col md={24} xs={24}>
            <H2Typography style={{ textAlign: 'center', marginBottom: '15px' }}>
              Send Message
            </H2Typography>
            <H4Typography style={{ fontWeight: '400', textAlign: 'center', marginBottom: '30px' }}>
              This will send email to staff(s) assigned to this event.
            </H4Typography>
          </Col>
        </Row>

        {isLoading ? (
          <CenterSpiner />
        ) : (
          <>
            <Row>
              <Col md={24} xs={24}>
                <Label text="Staffs" isMandatory={true} />
                <SelectInputWithCheckBox
                  id="employee_id"
                  name="employee_id"
                  mode="multiple"
                  options={reportsToList?.map((item: any) => {
                    item.title = item.text;
                    item.value = item.id;
                    item.key = item.id;
                    return item;
                  })}
                  value={value}
                  error={touched?.employee_id && errors?.employee_id}
                  onChange={(e: any) => {
                    setFieldValue('employee_id', e);
                  }}
                />
                <span style={{ marginBottom: '20px', display: 'block' }}>
                  Additional emails can be added here. After typing valid email, press enter to add
                  it to the list.
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={24} xs={24}>
                <Label text="Subject" isMandatory={true} />
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  onChange={handleChange}
                  value={values?.subject}
                  error={errors.subject}
                />
              </Col>
            </Row>
            <Row>
              <Col md={24} xs={24}>
                <Label text="Message" isMandatory={true} />
                <TextAreaInput
                  name="message"
                  id="message"
                  onChange={handleChange}
                  value={values?.message}
                  error={errors.message}
                />
              </Col>
            </Row>

            <Flex justifyContent={'center'} gap={15} bottom={20} className="fix-mobile">
              <Button
                variant="secondary"
                text={'Cancel'}
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
              />

              <Button type="submit" text={'Send'} onClick={handleSubmit} isLoading={isSubmitting} />
            </Flex>
          </>
        )}
      </ModalComponent>
    </ModalComponentWrapper>
  );
};

export default SendMessageModal;
