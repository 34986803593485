import { IWorkOrderDataTableProps, IWorkOrderListFilterType } from '../type';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { WORK_ORDER_MANAGEMENT_TABS, getPastRecordsList, getUpcomingRecordsList } from '../helper';
import { getColumns, getRows } from './helper';

import DataTable from '@organism/DataTable/DataTable';
import moment from 'moment';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';
import { TableWrapper } from '@pages/MenuManagementPage/style';

const WorkOrderDataTable: React.FC<IWorkOrderDataTableProps> = ({ activePanel, currentPanel }) => {
  const navigate = useNavigate();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [filterData, setFilterData] = useState<IWorkOrderListFilterType>({
    event_date: '',
    event_time: '',
    venue_name: '',
    event_name: '',
    event_supervisor: '',
    type: 'all',
    page: 1
  });

  const callTableData = (filters?: IWorkOrderListFilterType) => {
    if (currentPanel === activePanel && activePanel === WORK_ORDER_MANAGEMENT_TABS.UPCOMING) {
      updateUpcomingTableData(filters || null);
    } else if (currentPanel === activePanel && activePanel === WORK_ORDER_MANAGEMENT_TABS.PAST) {
      updatePastTableData(filters || null);
    }
  };

  useEffect(() => {
    callTableData();
  }, [activePanel]);

  useEffect(() => {
    return () => {
      setTableData([]);
    };
  }, []);

  const updateUpcomingTableData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setIsTableLoading(true);
      const res = await getUpcomingRecordsList(newFilters.page, filters);
      if (!res.error) {
        setTableData(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
      setIsTableLoading(false);
    }, 100),
    []
  );

  const updatePastTableData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setIsTableLoading(true);
      const res = await getPastRecordsList(pageNo, filters);
      if (!res.error) {
        setTableData(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
      setIsTableLoading(false);
    }, 100),
    []
  );

  const onFilterChangeHandler = (e: ChangeEvent, key?: string) => {
    let updatedFilters;
    if (key === 'date') {
      //@ts-ignore
      updatedFilters = { ...filterData, event_date: e ? moment(e).format('YYYY/MM/DD') : '' };
    } else if (key === 'event_time') {
      updatedFilters = { ...filterData, event_time: e };
    } else {
      const { id, value } = e.target as HTMLInputElement;
      updatedFilters = { ...filterData, [id]: value };
    }
    setFilterData(updatedFilters);
    callTableData(updatedFilters);
  };

  const cols = getColumns(filterData, onFilterChangeHandler);

  return (
    <TableWrapper>
      <DataTable
        className="fixTD"
        loading={isTableLoading}
        columns={cols}
        showLoadMore={!!nextPageUrl}
        nextPageURL={nextPageUrl}
        dataSource={tableData?.length > 0 ? tableData : getRows()}
        pagination={{
          pageSize: pageNo,
          total: totalCount,
          current: filterData?.page,
          onChange: (page: any) => {
            const newFilters = { ...filterData, page: page };
            setFilterData(newFilters);
            callTableData(newFilters);
          }
        }}
        onFetch={() => {}}
        renderEmpty={() => <div>No Records Found.</div>}
        scrollConfig={{
          y: tableData?.length ? 'calc(100vh - 350px)' : 'auto'
        }}
        onRowClick={(e: any) => {
          if (activePanel === WORK_ORDER_MANAGEMENT_TABS.UPCOMING) {
            navigate(
              `/work-orders/upcoming/view/${e?.record?.event_id}/${e?.record?.event_staffing_id}/${e?.record?.employee_id}`
            );
          } else if (activePanel === WORK_ORDER_MANAGEMENT_TABS.PAST) {
            navigate(
              `/work-orders/past/view/${e?.record?.event_id}/${e?.record?.event_staffing_id}/${e?.record?.employee_id}`
            );
          }
        }}
      />
    </TableWrapper>
  );
};

export default WorkOrderDataTable;
