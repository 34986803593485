import { Navigate, Route, Routes } from 'react-router';

import AppLayout from '@layout/AppLayout';
import DashboardPage from '@pages/DashboardPage';
import MenuManagementPage from '@pages/MenuManagementPage';
import NotFoundPage from '@pages/NotFoundPage';
import StaffManagementPage from '@pages/StaffManagementPage';
import StaffManagementList from '@pages/StaffManagementPage/ListViewPage';
import { AppContainer } from './styles';
import ListView from '@pages/MenuManagementPage/ListViewPages';
import ProductDetails from '@pages/MenuManagementPage/DetailsViewPages/ProductDetails';
import BundleDetails from '@pages/MenuManagementPage/DetailsViewPages/BundleDetails';
import AddEditProduct from '@pages/MenuManagementPage/AddEditPages/AddEditProduct';
import AddEditBundle from '@pages/MenuManagementPage/AddEditPages/AddEditBundle';
import VenueManagementPage from '@pages/VenueManagementPage';
import VenueListView from '@pages/VenueManagementPage/ListViewPages';
import QuoteManagementPage from '@pages/QuoteManagementPage';
import QuotesList from '@pages/QuoteManagementPage/QuotesList';
import AddEditQuote from '@pages/QuoteManagementPage/AddEditQuote';
import EmployeeManagementPage from '@pages/EmployeeManagementPage';
import EmployeeList from '@pages/EmployeeManagementPage/EmployeeList';
import AddEditEmployee from '@pages/EmployeeManagementPage/AddEditEmployee';
import CustomerManagementPage from '@pages/CustomerManagementPage';
import CustomerList from '@pages/CustomerManagementPage/CustomerList';
import ViewEmployee from '@pages/EmployeeManagementPage/ViewEmployee';
import GlobalSettingsPage from '@pages/GlobalSettingsPage';
import GlobalSettingsView from '@pages/GlobalSettingsPage/GlobalSettingsView';
import VendorManagementPage from '@pages/VendorManagementPage';
import VendorListView from '@pages/VendorManagementPage/ListViewPages';
import DetailsViewPages from '@pages/VenueManagementPage/DetailsViewPages';
import AddEditPages from '@pages/VenueManagementPage/AddEditPages';
import VendorDetailsViewPages from '@pages/VendorManagementPage/DetailsViewPages';
import VendorAddEditPages from '@pages/VendorManagementPage/AddEditPages';
import WorkOrderManagementPage from '@pages/WorkOrderManagementPage';
import WorkOrderListView from '@pages/WorkOrderManagementPage/ListViewPages';
import PastRecords from '@pages/WorkOrderManagementPage/DetailsViewPages/PastRecords';
import ViewQuoteDetails from '@pages/QuoteManagementPage/ViewQuoteDetails';
import AddEditCustomerPage from '@pages/CustomerManagementPage/AddEditCustomerPage';
import ViewCustomer from '@pages/CustomerManagementPage/ViewCustomer';
import EventManagementPage from '@pages/EventManagementPage';
import EventList from '@pages/EventManagementPage/EventsList';
import AddEditEvent from '@pages/EventManagementPage/AddEditEvent';
import FindQuote from '@pages/EventManagementPage/FindQuote';
import EventDetails from '@pages/EventManagementPage/EventDetails';
import ProfilePage from '@pages/ProfilePage';
import EditProfile from '@pages/ProfilePage/EditProfile';
import MyProfile from '@pages/ProfilePage/MyProfile';
import EventWithExistingQuote from '@pages/EventManagementPage/EventWithExistingQuote';
import { useSelector } from 'react-redux';
import UnauthorizedPage from '@pages/UnauthorizedPage/unauthorizedPage';
import AuthRoute from '@atom/AuthRoute';
import GeneralDocuments from '@pages/EventManagementPage/GeneralDocuments';
import CustomerBulkImport from '@pages/CustomerManagementPage/AddEditCustomerPage/CustomerBulkImport';
import FoodPreprationPage from '@pages/FoodPrepreationPage';
import FoodPreprationList from '@pages/FoodPrepreationPage/FoodPreprationList';
import SurveyForm from '@pages/EventManagementPage/SurveyForm';
import DocusignHandler from '@pages/DocusignHandler';
import ContractView from '@pages/EventManagementPage/ContractView';

function AppRoutes() {
  const user = useSelector(
    (state: { user: { isAdmin: boolean; isManager: boolean; isEmployee: boolean } }) => state.user
  );
  const { isAdmin, isEmployee } = user;

  return (
    <AppContainer>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path="/menu" element={<MenuManagementPage />}>
            <Route index element={<ListView />} />
            {/* View List Routes */}
            <Route path="/menu/tab/:tab" element={<ListView />} />
            {/* View Detail Routes */}
            <Route path="/menu/tab/:tab/view-product/:id" element={<ProductDetails />} />
            <Route path="/menu/tab/:tab/view-bundle/:id" element={<BundleDetails />} />
            {/* CRUD Routes */}
            <Route
              path="/menu/add-product"
              element={isAdmin ? <AddEditProduct /> : <UnauthorizedPage />}
            />
            <Route
              path="/menu/:tab/edit-product/:id"
              element={isAdmin ? <AddEditProduct /> : <UnauthorizedPage />}
            />
            <Route
              path="/menu/add-bundle"
              element={isAdmin ? <AddEditBundle /> : <UnauthorizedPage />}
            />
            <Route
              path="/menu/:tab/edit-bundle/:id"
              element={isAdmin ? <AddEditBundle /> : <UnauthorizedPage />}
            />
          </Route>
          <Route
            path="/food-preparation"
            element={isAdmin ? <FoodPreprationPage /> : <UnauthorizedPage />}
          >
            <Route index element={<FoodPreprationList />} />
            <Route path="/food-preparation/:tab" element={<FoodPreprationList />} />
          </Route>
          <Route
            path="/vendors"
            element={!isEmployee ? <VendorManagementPage /> : <UnauthorizedPage />}
          >
            <Route index element={<VendorListView />} />
            {/* View Vendor List Routes */}
            <Route path="/vendors/tab/:tab" element={<VendorListView />} />
            {/* View Detail Routes */}
            <Route path="/vendors/:id/view" element={<VendorDetailsViewPages />} />
            <Route path="/vendors/all" element={<VendorListView />} />
            <Route path="/vendors/active" element={<VendorListView />} />
            <Route path="/vendors/in-active" element={<VendorListView />} />
            <Route path="/vendors/drafts" element={<VendorListView />} />
            {/* CRUD Routes */}
            <Route
              path="/vendors/create"
              element={isAdmin ? <VendorAddEditPages /> : <UnauthorizedPage />}
            />
            <Route
              path="/vendors/:id/edit"
              element={isAdmin ? <VendorAddEditPages /> : <UnauthorizedPage />}
            />
          </Route>
          <Route
            path="/venues"
            element={!isEmployee ? <VenueManagementPage /> : <UnauthorizedPage />}
          >
            <Route index element={<VenueListView />} />
            {/* View Venue List Routes */}
            <Route path="/venues/tab/:tab" element={<VenueListView />} />
            {/* View Detail Routes */}
            <Route path="/venues/:id/view" element={<DetailsViewPages />} />
            <Route path="/venues/all" element={<VenueListView />} />
            <Route path="/venues/active" element={<VenueListView />} />
            <Route path="/venues/in-active" element={<VenueListView />} />
            <Route path="/venues/drafts" element={<VenueListView />} />
            {/* CRUD Routes */}
            <Route
              path="/venues/create"
              element={isAdmin ? <AddEditPages /> : <UnauthorizedPage />}
            />
            <Route
              path="/venues/:id/edit"
              element={isAdmin ? <AddEditPages /> : <UnauthorizedPage />}
            />
          </Route>
          <Route path="/work-orders" element={<WorkOrderManagementPage />}>
            <Route index element={<WorkOrderListView />} />
            {/* View Venue List Routes */}
            <Route path="/work-orders/tab/:tab" element={<WorkOrderListView />} />
            {/* View Detail Routes */}
            <Route
              path="/work-orders/:tab/view/:event_id/:event_staffing_id/:employee_id"
              element={<PastRecords />}
            />
          </Route>
          <Route path="/staff" element={isAdmin ? <StaffManagementPage /> : <UnauthorizedPage />}>
            <Route index element={<StaffManagementList />} />
            <Route path="/staff/tab/:tab" element={<StaffManagementList />} />
          </Route>
          <Route
            path="/employees"
            element={!isEmployee ? <EmployeeManagementPage /> : <UnauthorizedPage />}
          >
            <Route index element={<EmployeeList />} />
            <Route path="all" element={<EmployeeList />} />
            <Route path="active" element={<EmployeeList />} />
            <Route path="in-active" element={<EmployeeList />} />
            <Route
              path="create"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditEmployee />} />}
              // element={isAdmin ? <AddEditEmployee /> : <UnauthorizedPage />}
            />
            <Route
              path=":id/edit"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditEmployee isEdit={true} />} />}
            />
            <Route path=":id/view" element={<ViewEmployee />} />
          </Route>
          <Route path="/quote" element={isAdmin ? <QuoteManagementPage /> : <UnauthorizedPage />}>
            <Route index element={<QuotesList />} />
            {/* View List Routes */}
            <Route path="/quote/tab/:tab" element={<QuotesList />} />
            {/* CRUD Route */}
            <Route
              path="/quote/add-quote/:form"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditQuote />} />}
            />
            <Route
              path="/quote/add-quote/:quoteId/:form"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditQuote />} />}
            />
            <Route
              path="/quote/edit-quote/:quoteId/:form"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditQuote />} />}
            />
            <Route path="/quote/view/:quoteId" element={<ViewQuoteDetails />} />
          </Route>
          <Route
            path="/customers"
            element={isAdmin ? <CustomerManagementPage /> : <UnauthorizedPage />}
          >
            <Route index element={<CustomerList />} />
            <Route
              path={'create'}
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditCustomerPage />} />}
            />
            <Route
              path=":id/edit"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditCustomerPage />} />}
            />
            <Route
              path="bulk"
              element={<AuthRoute isAdmin={isAdmin} element={<CustomerBulkImport />} />}
            />
            <Route path=":id/view" element={<ViewCustomer />} />
          </Route>
          <Route path="/events/" element={<EventManagementPage />}>
            <Route index element={<EventList />} />

            <Route path="/events/survey/:token" element={<SurveyForm />} />

            <Route path="/events/:tab" element={<EventList />} />
            {/* Without Quote */}
            <Route
              path="/events/create/:formType"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditEvent />} />}
            />
            <Route
              path="/events/create/:formType/:quoteId/:eventId"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditEvent />} />}
            />
            <Route
              path="/events/edit/:formType/:quoteId/:eventId"
              element={<AuthRoute isAdmin={isAdmin} element={<AddEditEvent />} />}
            />
            {/* With Quote */}
            <Route path="/events/find-quote" element={<FindQuote />} />
            <Route
              path="/events/find-quote/create/:quoteId/:formType"
              element={<AuthRoute isAdmin={isAdmin} element={<EventWithExistingQuote />} />}
            />
            <Route
              path="/events/find-quote/create/:quoteId/:eventId/:formType"
              element={<AuthRoute isAdmin={isAdmin} element={<EventWithExistingQuote />} />}
            />
            <Route path="/events/documents" element={<GeneralDocuments />} />
            <Route path="/events/:eventId/view" element={<EventDetails />} />
          </Route>
          <Route path="/profile" element={<ProfilePage />}>
            <Route index element={<MyProfile />} />
            <Route path="/profile/edit-profile" element={<EditProfile />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/settings" element={isAdmin ? <GlobalSettingsPage /> : <UnauthorizedPage />}>
            <Route index element={<GlobalSettingsView />} />
          </Route>
          <Route path="/contract" element={<DocusignHandler />} />
          <Route path="/contract-view/:token" element={<ContractView />} />
        </Route>
      </Routes>
    </AppContainer>
  );
}

export default AppRoutes;
