import { MinusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';

interface GuestFieldsProps {
  item: any;
  index: number;
  touched: any;
  errors: any;
  arrayHelper: any;
  isRemoved: boolean;
  setIsRemoved: (value: boolean) => void;
  setFieldValue: (key: string, value: any) => {};
  handleBlur: () => void;
}

const GuestFields = (props: GuestFieldsProps) => {
  const {
    item,
    index,
    touched,
    errors,
    arrayHelper,
    isRemoved,
    setIsRemoved,
    setFieldValue,
    handleBlur
  } = props;
  const [guestAllergiesItem, setguestAllergiesItem] = useState<any>({});

  const handleChange = (value: any, formikKey: string, key: string) => {
    setTimeout(() => {
      setFieldValue(formikKey, value);
    }, 200);
    setguestAllergiesItem({ ...guestAllergiesItem, [key]: value });
  };

  useEffect(() => {
    if (
      (!guestAllergiesItem?.id && item?.id) ||
      (!guestAllergiesItem?.tempId && item?.tempId) ||
      isRemoved
    ) {
      isRemoved && setIsRemoved(false);
      setguestAllergiesItem(item);
    }
  }, [item]);

  return (
    <>
      <Row gutter={16}>
        <Col md={4} xs={24}>
          <Label text="Title" isMandatory={false} />
          <Input
            id={`guest_allergies[${index}].seated`}
            name={`guest_allergies[${index}].seated`}
            placeholder="Seating Info"
            error={
              touched['guest_allergies']?.[index]?.seated &&
              errors['guest_allergies']?.[index]?.seated
            }
            value={guestAllergiesItem?.seated}
            onChange={(e: any) =>
              handleChange(e.target.value, `guest_allergies[${index}].seated`, 'seated')
            }
            onBlur={handleBlur}
          />
        </Col>
        <Col md={8} xs={24}>
          <Label text="Guest Name" isMandatory={false} />
          <Input
            id={`guest_allergies[${index}].guest_name`}
            name={`guest_allergies[${index}].guest_name`}
            placeholder="Type Guest Name"
            error={
              touched['guest_allergies']?.[index]?.guest_name &&
              errors['guest_allergies']?.[index]?.guest_name
            }
            value={guestAllergiesItem?.guest_name}
            onChange={(e: any) =>
              handleChange(e.target.value, `guest_allergies[${index}].guest_name`, 'guest_name')
            }
            onBlur={handleBlur}
          />
        </Col>
        <Col md={10} xs={22}>
          <Label text="Allergies" isMandatory={false} />
          <Input
            id={`guest_allergies[${index}].allergy`}
            name={`guest_allergies[${index}].allergy`}
            placeholder="Type to Generate Tag"
            error={
              touched['guest_allergies']?.[index]?.allergy &&
              errors['guest_allergies']?.[index]?.allergy
            }
            value={guestAllergiesItem?.allergy}
            onChange={(e: any) =>
              handleChange(e.target.value, `guest_allergies[${index}].allergy`, 'allergy')
            }
            onBlur={handleBlur}
          />
        </Col>
        <Col md={2} xs={2} style={{ paddingTop: '48px' }}>
          <Button
            variant="ghost"
            icon={<MinusCircleOutlined />}
            onClick={() => {
              setIsRemoved(true);
              arrayHelper.remove(index);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default GuestFields;
