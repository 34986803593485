import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { TaskInputProps } from './helper';
import RemoveIcon from '@assets/removerow.png';
import { Flex } from '@atom/Flex';

const TaskInput: FC<TaskInputProps> = ({
  index,
  formikValue,
  touched,
  errors,
  onRemoveHandler,
  handleChange,
  showRemove
}) => {
  const [taskName, setTaskName] = useState('');

  useEffect(() => {
    setTaskName(formikValue ?? '');
  }, [formikValue]);

  return (
    <Row gutter={[16, 16]} style={{ marginTop: '-8px' }}>
      <Col md={22} xs={22}>
        <Label text="Task Name" />
        <Input
          id={`event_tasks[${index}].task`}
          name={`event_tasks[${index}].task`}
          placeholder={'Event Task'}
          onChange={(e) => {
            setTaskName(e.target.value);
            setTimeout(() => {
              handleChange && handleChange(e);
            }, 200);
          }}
          error={
            touched?.event_tasks?.[index]?.task &&
            (errors?.event_tasks?.[index] as { task: string })?.task
          }
          value={taskName}
        />
      </Col>
      <Col md={1} xs={1} style={{ cursor: 'pointer', textAlign: 'center' }}>
        {showRemove && (
          <Flex alignItems={'center'} style={{ minHeight: '100%' }}>
            <img
              style={{ paddingTop: '10px' }}
              src={RemoveIcon}
              alt="remove"
              onClick={() => onRemoveHandler && onRemoveHandler(index)}
            />
          </Flex>
        )}
      </Col>
    </Row>
  );
};

export default TaskInput;
