import { CALENDAR_VIEWS } from './helper';
import { Flex } from '@atom/Flex';
import moment from 'moment';
import styled from 'styled-components';

export const EventWrapper = styled(Flex)<{ currentView: string }>`
  gap: 4px;
  border-radius: 2px;
  align-items: baseline;
  overflow: hidden;
  width: 100%;
  white-space: ${(props) =>
    props?.currentView !== CALENDAR_VIEWS.MONTH && props?.currentView !== CALENDAR_VIEWS.WEEK
      ? 'pre-wrap'
      : 'none'};
`;
export const EventTypeIndicator = styled.div<{ backgroundColor: string }>`
  min-width: 8px;
  min-height: 8px;
  border-radius: 4px;
  background-color: ${(props) => props?.backgroundColor};
`;
export const CalendarWrapper = styled.div<{ filters: any }>`
  color: black;
  .fc-toolbar-title {
    font-size: 28px;
  }
  .fc-daygrid-event {
    font-size: 14px !important;
    font-weight: 700;
  }
  .fc .fc-timegrid-axis-frame {
    justify-content: center !important;
  }
  .fc-list-event-title span {
    font-size: 16px !important;
    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }
  .fc-timegrid-slot-label-cushion {
    font-size: 16px !important;
    font-weight: 600 !important;
    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }
  .fc-timegrid-axis-cushion {
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: capitalize;
    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }
  .fc-button-group > .fc-button {
    background-color: #000;
    border-color: #000;
    padding: 6px 12px;
    font-size: 16px;
  }
  .fc-button-group > .fc-button:hover {
    background-color: rgba(228, 168, 83, 0.7);
    border-color: rgba(228, 168, 83, 0.7);
    color: #000;
  }
  .fc-button-primary:not(:disabled).fc-button-active {
    background-color: rgba(228, 168, 83, 0.7);
    border-color: rgba(228, 168, 83, 0.7);
    color: #000;
  }
  .fc-daygrid-day {
    height: 175px !important;
  }
  .fc-list-empty {
    height: 900px !important;
    @media (max-width: 768px) {
      height: 350px !important;
    }
  }

  .fc-list-event-graphic,
  .fc-list-event-time {
    display: none;
  }
  td {
    border-bottom: ${(props) =>
      props?.filters?.currentView === CALENDAR_VIEWS.DAY
        ? 'none'
        : '1px solid var(--fc-border-color, #ddd)'};
    border-top: ${(props) =>
      props?.filters?.currentView === CALENDAR_VIEWS.DAY
        ? 'none'
        : '1px solid var(--fc-border-color, #ddd)'};
  }
  a {
    color: black;
  }
  .fc .fc-list-table tbody > tr:first-child th {
    display: ${(props) => props?.filters?.currentView === CALENDAR_VIEWS.DAY && 'flex'};
    justify-content: ${(props) => props?.filters?.currentView === CALENDAR_VIEWS.DAY && 'center'};
  }
  .fc-list-day-cushion,
  .fc-cell-shaded {
    background: ${(props) =>
      props?.filters?.currentView === CALENDAR_VIEWS.DAY
        ? 'transparent'
        : 'rgba(208, 208, 208, 0.3)'};
  }
  // .fc-direction-ltr .fc-list-day-text,
  // .fc-direction-rtl .fc-list-day-side-text {
  //   float: right;
  // }
  // .fc-direction-ltr .fc-list-day-side-text,
  // .fc-direction-rtl .fc-list-day-text {
  //   float: left;
  // }
  .fc-theme-standard .fc-popover {
    max-height: 252px;
    overflow-y: scroll;
  }
  .fc-event-future {
    background-color: ${(props) =>
      props?.filters?.currentView === CALENDAR_VIEWS.DAY &&
      props?.filters?.startDate === moment().format('YYYY-MM-DD') &&
      'rgba(255,220,40,.15)'};
  }
  .fc-h-event {
    background-color: transparent !important;
  }
  .fc-event-main {
    overflow: ${(props) => props?.filters?.currentView !== CALENDAR_VIEWS.MONTH && 'hidden'};
  }
  .fc-header-toolbar {
    margin-bottom: 2.5em !important;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .fc-daygrid-day-number {
    padding-right: 22px !important;
  }
  .fc-col-header-cell-cushion {
    font-size: 16px !important;
    @media (max-width: 767px) {
      font-size: 12px !important;
    }
  }

  .fc .fc-list-sticky .fc-list-day > * {
    top: -1.68em !important;
    @media (max-width: 768px) {
      top: -0.68em !important;
    }
  }

  .fc-toolbar-title {
    @media (max-width: 767px) {
      margin: 10px 0px;
      font-size: 16px;
    }
  }
`;
