import { H2Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';
import { SectionTitleDiv } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';
import { Col, Row } from 'antd';
import React from 'react';
import { getAllergiesColumns } from '../column';
import { DataTablepaddingWrapper } from './style';

interface GuestAllergiesProps {
  data: any;
}

const GuestAllergies = (props: GuestAllergiesProps) => {
  const { data } = props;
  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'event-section'}>Guest Allergies</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <DataTablepaddingWrapper>
            <DataTableWrapper>
              <DataTable
                className="fixTD"
                renderEmpty={() => {
                  return <>No Allergies Found</>;
                }}
                dataSource={data}
                columns={getAllergiesColumns()}
              />
            </DataTableWrapper>
          </DataTablepaddingWrapper>
        </Col>
      </Row>
    </>
  );
};

export default GuestAllergies;
