import CenterSpiner from '@atom/CenterSpiner';
import DataTable from '@organism/DataTable/DataTable';
import {
  EditableCellProps,
  EditPrepListDataType,
  PrepListDataTypes,
  PrepListFiltertypes,
  PrepListTableTypes
} from '../types';
import { useState, useEffect, useCallback } from 'react';
import { showErrorToast, showToast } from '@utils/index';
import {
  getFoodListColumns,
  getMappedTableData,
  initFilterState,
  getPrepListDropdownData,
  getConfirmationModalContent,
  mapDataToSave,
  initalEditPrepListData
} from './helper';
import { EditableCellWithInput, EditableCellWithSelectInput } from './style';
import { ViewTableCell } from '../FoodPreprationListTable/style';
import ConfirmModal from '@organism/ConfimationModal';
import {
  getAssigneeList,
  getIngredientList,
  getPrepListData,
  updatePrepList
} from '@services/foodPrepServices';
import { debounce } from 'lodash';
import Checkbox from '@atom/Checkbox';
import { useParams } from 'react-router';
import { Form, Select } from 'antd';
import SpecialIngredientDetailsModal from '@pages/GlobalSettingsPage/MenuManagement/SpecialIngredients/SpecialIngredientDetailsModal';

function PrepListTable(props: PrepListTableTypes) {
  const { startDate, endDate, prepListId, callApi, setCallApi, currentPanel } = props;
  const { tab } = useParams();
  const [form] = Form.useForm();

  const searchParams = new URLSearchParams(window?.location.search);
  const [productsList, setProductsList] = useState<Array<PrepListDataTypes>>([]);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [isIngredientModal, setIngredientModal] = useState<boolean>(false);
  const [specialIngredientId, setSpecialIngredientId] = useState<number>();
  const [tableData, setTableData] = useState<Array<PrepListDataTypes>>([]);
  const [editingKey, setEditingKey] = useState<string>('');
  const [dropdownData, setDropdownData] = useState<any>({});
  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(false);
  const [assigneeListForTitleColumn, setAssigneeListForTitleColumn] = useState([]);
  const [assigneeListForTableRows, setAssigneeListForTableRows] = useState([]);
  const [initialAssigneeList, setInitialAssigneeList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [initialIngredientList, setInitialIngredientList] = useState([]);
  const [isAssigneeChanged, setIsAssigneeChanged] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  const [editPrepListData, setEditPrepListData] =
    useState<EditPrepListDataType>(initalEditPrepListData);
  const [filters, setFilters] = useState<PrepListFiltertypes>({
    ...initFilterState,
    start_date: startDate,
    end_date: endDate
  });

  const searchParamsFilter = {
    ...filters,
    ingredient_name: searchParams?.get('ingredient_name') || '',
    event_id: Number(searchParams?.get('event_code')) || '',
    start_date: searchParams?.get('start_date') || '',
    end_date: searchParams?.get('end_date') || ''
  };

  let page = 1;
  const maxPerPage = 4;
  const getAssigneOptionsData = async () => {
    try {
      const res = await getAssigneeList();
      if (res?.success) {
        setAssigneeListForTitleColumn(res?.data);
        setAssigneeListForTableRows(res?.data);
        setInitialAssigneeList(res?.data);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something Went Wrong'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: error || 'Something Went Wrong'
      });
    }
  };

  const getIngredientOptionsData = async () => {
    try {
      const res = await getIngredientList();
      if (res?.success) {
        setIngredientList(res?.data);
        setInitialIngredientList(res?.data);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something Went Wrong'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: error || 'Something Went Wrong'
      });
    }
  };

  const edit = (record: any, rowIndex: number) => {
    setEditingKey(record?.key);
    setEditPrepListData({
      event_id: record?.event_id,
      product_id: record?.product_id,
      product_subcategory_id: record?.product_subcategory_id,
      is_assign_to_all_event: record?.is_assign_to_all_event,

      ingredients: [
        {
          amount: record?.ingredient_amount,
          ingredient_id: record?.ingredient_id,
          is_completed: record?.is_done ? 1 : 0,
          assignee_id: record?.assignee_id,
          start_date: record?.startDate,
          end_date: record?.endDate
        }
      ]
    });

    form.setFieldsValue({
      ingredient_amount: tableData?.[rowIndex]?.ingredient_amount,
      assignee_name: tableData?.[rowIndex]?.assignee_id,
      is_completed: tableData?.[rowIndex]?.is_done
    });
  };

  const UpdateprepListData = async (tempData: EditPrepListDataType) => {
    const updatePrepListRes = await updatePrepList(tempData);
    if (updatePrepListRes?.success) {
      getProductsTableData(Number(prepListId), filters);
      showToast({
        message: 'Updated!',
        description: updatePrepListRes?.message || 'Products Data Updated'
      });
    } else {
      showErrorToast({
        message: 'Failed!',
        description: updatePrepListRes?.errror || 'Failed to update products data'
      });
    }
    setEditingKey('');
    setEditPrepListData(initalEditPrepListData);
    setModalVisible(false);
  };

  const save = async (record: any & { key: React.Key }) => {
    await form.validateFields();
    if (isAssigneeChanged) {
      setModalData({ ...modalData, ingredient_name: record?.ingredient_name });
      setModalVisible(true);
      setIsAssigneeChanged(false);
    } else {
      const tempData: any = editPrepListData;
      tempData.is_assign_to_all_event = 0;
      tempData.ingredients[0].amount = form.getFieldValue('ingredient_amount');
      await UpdateprepListData(mapDataToSave(tempData, 0));
    }
  };

  const editAllEvents = async () => {
    const tempData: any = editPrepListData;
    tempData.is_assign_to_all_event = 1;
    tempData.start_date = startDate;
    tempData.end_date = endDate;
    tempData.assignee_id = editPrepListData.ingredients[0].assignee_id;
    tempData.ingredients[0].amount = form.getFieldValue('ingredient_amount');
    await UpdateprepListData({
      ...mapDataToSave(tempData, 1),
      start_date: startDate,
      end_date: endDate
    });
  };

  const editCurrentEvent = async () => {
    const tempData = editPrepListData;
    tempData.is_assign_to_all_event = 0;
    tempData.ingredients[0].amount = form.getFieldValue('ingredient_amount');
    await UpdateprepListData(mapDataToSave(tempData, 0));
  };

  const isEditing = (record: any) => record.key === editingKey;

  const searchFilter = useCallback(
    debounce(async (prepListId: number, filters: any) => {
      await getProductsTableData(prepListId!, filters);
    }, 500),
    []
  );

  const getProductsTableData = async (prepListId: number, filters: any) => {
    setIsTableLoading(true);
    try {
      const res = await getPrepListData({
        ...filters,
        prep_list_id: prepListId
      });
      if (res?.success) {
        const mappedData = getMappedTableData(res?.data);
        setTableData(mappedData);
        setProductsList(res?.data);
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch products data'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch products data'
      });
    }
    setIsTableLoading(false);
  };

  const onClear = (data: any) => {
    const { id, value } = data;
    const updatedFilters = { ...filters, [id]: value };
    setFilters({ ...updatedFilters });
    getProductsTableData(prepListId!, { ...updatedFilters });
  };

  const handleClick = () => {
    setAssigneeListForTitleColumn(initialAssigneeList);
  };

  const handleSpecialIngredient = (id?: number) => {
    setSpecialIngredientId(id);
    setIngredientModal(true);
  };

  const cols = getFoodListColumns(
    filters,
    setFilters,
    dropdownData,
    getProductsTableData,
    isEditing,
    edit,
    tableData,
    prepListId!,
    save,
    onClear,
    searchFilter,
    assigneeListForTitleColumn,
    setAssigneeListForTitleColumn,
    ingredientList,
    setIngredientList,
    initialAssigneeList,
    initialIngredientList,
    handleSpecialIngredient,
    handleClick
  );

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        editPrepListData: editPrepListData,
        setEditPrepListData: setEditPrepListData,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
        assigneeList: assigneeListForTableRows,
        setIsAssigneeChanged: setIsAssigneeChanged
      })
    };
  });

  const EditableCell = ({
    editPrepListData,
    setEditPrepListData,
    editing,
    dataIndex,
    assigneeList,
    setIsAssigneeChanged,
    children
  }: EditableCellProps) => {
    const handleChange = () => {
      setEditPrepListData({
        ...editPrepListData,
        ingredients: [
          {
            ...editPrepListData.ingredients[0],
            is_completed: !editPrepListData.ingredients[0].is_completed
          }
        ]
      });
    };
    return (
      <td>
        {editing ? (
          dataIndex === 'assignee_name' ? (
            <Form.Item name={'assignee_name'} style={{ marginBottom: '0px' }}>
              <EditableCellWithSelectInput
                listHeight={150}
                placeholdertitle="Select Assignee"
                value={editPrepListData?.ingredients[0]?.assignee_id}
                onChange={(e: any) => {
                  setIsAssigneeChanged(true);
                  setModalData({
                    ...modalData,
                    assignee_name: e
                      ? assigneeList?.find((item: any) => item?.id === e)?.name
                      : null
                  });
                  setEditPrepListData({
                    ...editPrepListData,
                    ingredients: [{ ...editPrepListData.ingredients[0], assignee_id: e ?? null }]
                  });
                }}
              >
                {assigneeList?.map((opt: any, index: number) => (
                  <Select.Option key={index} value={opt?.id}>
                    {opt?.name}
                  </Select.Option>
                ))}
              </EditableCellWithSelectInput>
            </Form.Item>
          ) : dataIndex === 'ingredient_amount' ? (
            <Form.Item
              style={{ marginBottom: '0px' }}
              name="ingredient_amount"
              rules={[
                { required: true, message: 'Please enter amount!' },
                () => ({
                  validator(_, value) {
                    var validNumber = new RegExp(/^\d*\.?\d*$/);
                    if (validNumber.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Please enter valid amount!'));
                  }
                })
              ]}
            >
              <EditableCellWithInput
                placeholder="Select Amount"
                id={'ingredient_amount'}
                name={'ingredient_amount'}
              />
            </Form.Item>
          ) : (
            <Form.Item name={'is_completed'} style={{ marginBottom: '0px' }}>
              <Checkbox
                checked={editPrepListData?.ingredients[0]?.is_completed}
                onChange={() => handleChange()}
              />
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  const getFromAndToIndex = (page: any, maxLength: number) => {
    const start = maxPerPage * (page - 1);
    const end = maxPerPage * page < maxLength ? maxPerPage * page : maxLength;
    return start > maxLength || end > maxLength ? undefined : { start, end };
  };

  const handleLoadMore = async () => {
    let tempPage = page + 1;
    const range = getFromAndToIndex(tempPage, productsList?.length);
    if (range) {
      const mappedData = getMappedTableData(productsList?.slice(0, range?.end));
      setTableData(mappedData);
      setFilters((prev: PrepListFiltertypes) => ({ ...prev, page: tempPage }));
      page = tempPage;
    }
  };

  const updateDropdownData = async (filters: any) => {
    setIsDropdownLoading(true);
    try {
      const res: any = await getPrepListDropdownData(filters);
      if (res?.length > 0) {
        setDropdownData({
          eventCodesList: res?.[0]?.status === 'fulfilled' ? res?.[0]?.value?.data : [],
          measurementsList: res?.[1]?.status === 'fulfilled' ? res?.[1]?.value : []
        });
      } else {
        setDropdownData({
          eventCodesList: [{ id: 0, text: 'No Event Found' }],
          measurementsList: [{ id: 0, text: 'No Unit Found' }]
        });
      }
      setIsDropdownLoading(false);
    } catch (error) {
      setIsDropdownLoading(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch dropdown data'
      });
    }
  };

  useEffect(() => {
    if (prepListId) {
      if (window?.location?.search) {
        setFilters(searchParamsFilter);
        getProductsTableData(prepListId, searchParamsFilter);
        updateDropdownData(searchParamsFilter);
      } else {
        getProductsTableData(prepListId, filters);
        updateDropdownData({ ...filters, start_date: startDate, end_date: endDate });
        setFilters({ ...initFilterState, start_date: startDate, end_date: endDate });
      }
      setCallApi(false);
    }
  }, [callApi]);

  useEffect(() => {
    if (tab === currentPanel && prepListId) {
      if (window?.location?.search) {
        setFilters(searchParamsFilter);
        getProductsTableData(prepListId, searchParamsFilter);
        updateDropdownData(searchParamsFilter);
      } else {
        getProductsTableData(prepListId, filters);
        updateDropdownData({ ...filters, start_date: startDate, end_date: endDate });
      }
      getAssigneOptionsData();
      getIngredientOptionsData();
    }
  }, [currentPanel, tab]);

  if (isDropdownLoading) return <CenterSpiner />;

  const confirmationModalContent = getConfirmationModalContent(
    modalData?.assignee_name,
    modalData?.ingredient_name
  );

  return (
    <>
      <Form form={form} component={false}>
        <ViewTableCell>
          <DataTable
            className="fixTD"
            components={{
              body: {
                cell: EditableCell
              }
            }}
            loading={isTableLoading}
            columns={mergedColumns}
            dataSource={tableData}
            infinity={true}
            onFetch={() => handleLoadMore()}
            renderEmpty={() => <div>No Data Found.</div>}
          />
        </ViewTableCell>
      </Form>

      {isModalVisible && (
        <ConfirmModal
          visible={isModalVisible}
          onCancel={editAllEvents}
          title={confirmationModalContent?.heading}
          onOK={editCurrentEvent}
          okText={confirmationModalContent?.okText}
          cancelText={confirmationModalContent?.cancelText}
        >
          {confirmationModalContent?.message}
        </ConfirmModal>
      )}

      {isIngredientModal && (
        <SpecialIngredientDetailsModal
          isModalVisible={isIngredientModal}
          modalCloseHandler={() => {
            setIngredientModal(false);
            setSpecialIngredientId(undefined);
          }}
          specialIngredientId={specialIngredientId}
          isModalEditable={false}
        />
      )}
    </>
  );
}

export default PrepListTable;
