import { Button } from '@atom/Buttons';
import { formatCurrency } from '@utils/format';
import { updateRawData } from '@utils/index';
import { NavigateFunction } from 'react-router';
import styled from 'styled-components';

const ClickableSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const handleViewSurveyForm = ({ survey_url }: { survey_url: string }) => {
  updateRawData({ pdfUrl: survey_url });
  updateRawData({ showSurveyForm: true });
};

export const quoteTableColumns = (navigate: NavigateFunction) => {
  const cols = [
    {
      title: 'Quote Number',
      dataIndex: 'quotation_no',
      width: '25%',
      render: (data: any, row: any) => {
        return data ? (
          <ClickableSpan
            onClick={() => {
              navigate(`/quote/view/${row?.id}`);
            }}
          >
            {data}
          </ClickableSpan>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'Event Name',
      dataIndex: 'event_name',
      width: '30%',
      render: (data: any, row: any) => {
        return data ? (
          <ClickableSpan
            onClick={() => {
              navigate(`/quote/view/${row?.id}`);
            }}
          >
            {data}
          </ClickableSpan>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'Total Amount',
      dataIndex: 'grand_total',
      width: '25%',
      render: (data: any) => {
        return data ? formatCurrency(data) : '-';
      }
    }
  ];
  return cols;
};
export const eventTableColumns = (navigate: NavigateFunction, isAdmin: boolean) => {
  const cols = [
    {
      title: 'Event Name',
      dataIndex: '',
      width: '25%',
      render: (row: any) => {
        return row ? (
          <ClickableSpan
            onClick={() => {
              navigate(`/events/${row?.id}/view`);
            }}
          >
            {row?.quotation?.event_name}
          </ClickableSpan>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'Venue Name',
      dataIndex: '',
      width: '30%',
      render: (row: any) => {
        return row ? row?.quotation?.venue?.venue_name : '-';
      }
    },
    {
      title: 'Event Date',
      dataIndex: '',
      width: '25%',
      render: (row: any) => {
        return row
          ? row?.quotation?.event_dates?.date_string !== '0000-00-00'
            ? row?.quotation?.event_dates?.date_string
            : '--'
          : '-';
      }
    },
    {
      title: 'Event Survey',
      dataIndex: '',
      width: '25%',
      render: (row: any) => {
        return row ? (
          row?.survey_url ? (
            <Button
              variant="primary"
              text={'View follow up'}
              size="small"
              onClick={() => handleViewSurveyForm(row)}
            />
          ) : (
            '--'
          )
        ) : (
          '-'
        );
      },
      hidden: !isAdmin
    }
  ];
  const visibleCols = cols.filter((col) => !col.hidden);
  return visibleCols;
};
