import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { PageTitle } from '@molecules/PageTitle';
import PageLayout from '@layout/PageLayout';

import EditIcon from '@assets/edit-2.svg';
import Warning from '@assets/alert-circle-black.png';
import Send from '@assets/send.png';
import { showErrorToast, showToast } from '@utils/index';

import { changeStatus, EmployeeDetailsType, getEmployeeDetails, sendInvitation } from './helper';
import { ContentWrapper, ImageProfile, ImageWrapper, Wrapper } from './style';
import { Label } from '@atom/FormLable';
import FormDataView from '@atom/FormDataView';
import { userType } from '../constants';
import { getReportsToList, ReportsToType } from '../AddEditEmployee/helper';
import { useSelector } from 'react-redux';
import CenterSpiner from '@atom/CenterSpiner';
import ConfirmModal from '@organism/ConfimationModal';
import StatusLabel from '@atom/StatusLabel';
import NoImage from '@assets/noimage.png';
const EmployeeSummary = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetailsType | null>(null);
  const [reportsToList, setReportsToList] = useState<ReportsToType[]>([]);
  const [confirmSendInvitation, setConfirmSendInvitation] = useState(false);
  const [confirmChangeStatus, setConfirmChangeStatus] = useState(false);

  const user = useSelector((state: { user: { user_id: string; isAdmin: boolean } }) => state.user);
  const { isAdmin } = user;
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getReportsToList(user.user_id).then((result) => {
      if (!result.error) {
        setReportsToList(result.data || []);
      }
    });
    getEmployeeDetailsHandler();
  }, []);

  const getEmployeeDetailsHandler = useCallback(async () => {
    setPageLoading(true);
    const empId = params?.id || null;
    if (empId) {
      const result = await getEmployeeDetails(empId);
      setPageLoading(false);
      if (!result.error) {
        setEmployeeDetails(result.data || null);
      } else {
        showErrorToast({ message: result.error, description: '' });
      }
    }
  }, [params?.id]);

  const onSendInvitation = async () => {
    setLoading(true);
    if (!params?.id) return;
    const result = await sendInvitation(params?.id);
    setLoading(false);
    setConfirmSendInvitation(false);
    if (!result.error) {
      getEmployeeDetailsHandler();
      showToast({ message: 'Invitation sent successfully', description: '' });
    } else {
      showErrorToast({
        message: 'Error sending inviation!',
        description: result.error || 'Please try again'
      });
    }
  };

  const onChangeStatus = async () => {
    setLoading(true);
    if (!params?.id) return;
    const result = await changeStatus(params?.id);
    setLoading(false);
    setConfirmChangeStatus(false);
    if (!result.error) {
      getEmployeeDetailsHandler();
      showToast({ message: 'Employee Status Changed successfully', description: '' });
    } else {
      showErrorToast({
        message: 'Error changing status!',
        description: result.error || 'Please try again'
      });
    }
  };

  const onEdit = () => {
    navigate(`/employees/${employeeDetails?.id}/edit`);
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <PageLayout>
      <ContentWrapper>
        <Wrapper className="mobile-review">
          <PageTitle
            className="page-title"
            text="EMPLOYEE MANAGEMENT"
            bordered
            isBack
            path="/employees/all"
          >
            {isAdmin && (
              <Flex className="page-btn">
                {isAdmin && !employeeDetails?.first_login_at && (
                  <Button
                    icon={<img src={Send} alt="send" />}
                    text={`${
                      employeeDetails?.invite_sent_at ? 'RESEND INVITATION' : 'SEND INVITATION'
                    }`}
                    variant="ghost"
                    onClick={() => setConfirmSendInvitation(true)}
                    isDisable={loading}
                  />
                )}
                <Button
                  icon={<img src={Warning} alt="warning" />}
                  style={{ color: employeeDetails?.status_info?.status_name !== 'Active' && 'red' }}
                  text={
                    employeeDetails?.status_info?.status_name === 'Active'
                      ? 'DEACTIVATE'
                      : 'REACTIVATE'
                  }
                  variant="ghost"
                  onClick={() => setConfirmChangeStatus(true)}
                  isDisable={loading}
                />
                <Button
                  icon={<img src={EditIcon} alt="edit" />}
                  text="EDIT"
                  variant="ghost"
                  onClick={onEdit}
                  isDisable={loading}
                />
              </Flex>
            )}
          </PageTitle>
        </Wrapper>
        <Row gutter={24}>
          <Col md={6} xs={24} style={{ marginBottom: '10px' }} className="text-align-center">
            <ImageWrapper>
              {employeeDetails?.profile_pic ? (
                <ImageProfile src={employeeDetails?.profile_pic} />
              ) : (
                <ImageProfile src={NoImage} />
              )}
            </ImageWrapper>
          </Col>
          <Col md={18} xs={24}>
            <Row className="data-container">
              <Col md={18} xs={24}>
                <Row>
                  <Col md={12} xs={24}>
                    <FormDataView heading="NAME" value={employeeDetails?.name} />
                  </Col>
                  <Col md={12} xs={24}>
                    <FormDataView
                      heading="USER TYPE"
                      value={
                        <span style={{ textTransform: 'uppercase' }}>
                          {employeeDetails?.name
                            ? userType.find((item) => item.id === employeeDetails.user_type)?.label
                            : ''}
                        </span>
                      }
                    />
                  </Col>
                  <Col md={12} xs={24}>
                    <FormDataView heading="EMAIL" value={employeeDetails?.email} />
                  </Col>
                  <Col md={12}>
                    <FormDataView
                      heading="DEPARTMENT(S)"
                      value={employeeDetails?.employee_departments
                        ?.map((item) => item.name)
                        ?.join(', ')}
                    />
                  </Col>
                  <Col md={12} xs={24}>
                    <FormDataView
                      heading="PHONE NUMBER"
                      value={`${employeeDetails?.country_code || ''} ${
                        employeeDetails?.formated_phone_number
                      }  ${employeeDetails?.extension ? 'x' + employeeDetails?.extension : ''}`}
                    />
                  </Col>
                  <Col md={12} xs={24}>
                    <FormDataView
                      heading="POSITION(S)"
                      value={employeeDetails?.employee_positions
                        ?.map((item) => item.name)
                        ?.join(', ')}
                    />
                  </Col>
                  <Col md={12} xs={24}>
                    <FormDataView heading="ADDRESS" value={employeeDetails?.address} />
                  </Col>
                  <Col md={12} xs={24}>
                    <FormDataView
                      heading="REPORTS TO"
                      value={
                        reportsToList.find((item) => item.id === employeeDetails?.reports_to)?.text
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} xs={24}>
                <Row className="text-align" style={{ justifyContent: 'end' }}>
                  <Col>
                    <FormDataView
                      heading="STATUS"
                      value={
                        employeeDetails && (
                          <StatusLabel status={employeeDetails.status} isUppercase={true} />
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <ConfirmModal
          visible={confirmSendInvitation}
          onCancel={() => setConfirmSendInvitation(false)}
          title="Are you sure you want to Send Invitation?"
          onOK={onSendInvitation}
          isModalLoading={loading}
          okText="Send"
        >
          <span>This employee will receive an email</span>
        </ConfirmModal>
        <ConfirmModal
          visible={confirmChangeStatus}
          onCancel={() => setConfirmChangeStatus(false)}
          title={`Are you sure you want to ${
            employeeDetails?.status_info?.status_name === 'Active' ? 'Deactivate' : 'Activate'
          } this Employee?`}
          onOK={onChangeStatus}
          isModalLoading={loading}
          okText={
            employeeDetails?.status_info?.status_name === 'Active' ? 'DEACTIVATE' : 'ACTIVATE'
          }
        >
          <span>
            This employee will tagged as{' '}
            {employeeDetails?.status_info?.status_name === 'Active' ? 'INACTIVE' : 'ACTIVE'}
          </span>
        </ConfirmModal>
      </ContentWrapper>
    </PageLayout>
  );
};

export default EmployeeSummary;
