import { Col, Divider, Row } from 'antd';
import { IAddEditInfoProps, ITimeType } from '@pages/VendorManagementPage/types';

import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { Label } from '@atom/FormLable';
import { LabelWrapper } from '../style';
import React from 'react';
import TimePickerComponent from '@atom/TimePicker';
import _ from 'lodash';
import { getIn } from 'formik';
import moment, { Moment } from 'moment';

function DeliveryTimesSection({ formik }: IAddEditInfoProps) {
  const { values, setTouched, setFieldValue, touched, errors, handleChange } = formik;

  return (
    <Flex style={{ width: '100%' }} direction="column">
      <H2Typography style={{ marginTop: '15px' }}>Delivery Times</H2Typography>
      <Divider style={{ borderColor: '#e0e0e0', margin: '15px 0px' }}></Divider>
      <Row gutter={16}>
        <Col md={12} xs={0}>
          <Row gutter={16}>
            <Col xs={24} md={8} lg={8}></Col>
            <Col xs={24} md={8} lg={8}>
              <Label text="Start Time" />
            </Col>
            <Col xs={24} md={8} lg={8}>
              <Label text="End Time" />
            </Col>
          </Row>
        </Col>
      </Row>
      {!!values?.drop_times &&
        Array.isArray(values?.drop_times) &&
        values?.drop_times?.map((item: ITimeType, index: number) => (
          <LabelWrapper key={`drop_times-${item?.day}-${index}-${item?.vendor_id}`}>
            <Row gutter={16}>
              <Col md={12} xs={24}>
                <Row gutter={16}>
                  <Col xs={24} md={8} lg={8} className="time-label-style">
                    <Label text={item?.day} />
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                    <Col md={0} xs={24} style={{ padding: '0px' }}>
                      <Label text={'Start Time'} />
                    </Col>
                    <TimePickerComponent
                      id={`drop_times[${index}].start_time`}
                      name={`drop_times[${index}].start_time`}
                      use12Hours={true}
                      format="h:mm A"
                      placeholder={'HH:MM'}
                      onChange={(value: string) => {
                        const touchedVar: any = { ...touched };
                        _.set(touchedVar, `drop_times[${index}].start_time`, true);
                        setTouched({ ...touchedVar });
                        setFieldValue(`drop_times[${index}].start_time`, value);
                      }}
                      onSelect={(e: Moment) => {
                        setFieldValue(`drop_times[${index}].start_time`, e.format('hh:mm A'));
                      }}
                      value={
                        values?.drop_times?.[index]?.start_time &&
                        moment(values?.drop_times?.[index]?.start_time, 'hh:mm A').format('HH:mm')
                      }
                    />
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                    <Col md={0} xs={24} style={{ padding: '0px' }}>
                      <Label text={'End Time'} />
                    </Col>
                    <TimePickerComponent
                      id={`drop_times[${index}].end_time`}
                      name={`drop_times[${index}].end_time`}
                      use12Hours={true}
                      format="h:mm A"
                      placeholder={'HH:MM'}
                      onChange={(value: string) => {
                        const touchedVar: any = { ...touched };
                        _.set(touchedVar, `drop_times[${index}].end_time`, true);
                        setTouched({ ...touchedVar });
                        setFieldValue(`drop_times[${index}].end_time`, value);
                      }}
                      onSelect={(e: Moment) => {
                        setFieldValue(`drop_times[${index}].end_time`, e.format('hh:mm A'));
                      }}
                      value={
                        values?.drop_times?.[index]?.end_time &&
                        moment(values?.drop_times?.[index]?.end_time, 'hh:mm A').format('HH:mm')
                      }
                      error={
                        getIn(touched, `drop_times.[${index}].end_time`) &&
                        getIn(errors, `drop_times.[${index}].end_time`)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12}></Col>
            </Row>
          </LabelWrapper>
        ))}
    </Flex>
  );
}

export default DeliveryTimesSection;
