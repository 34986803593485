import { PlusCircleFilled } from '@ant-design/icons';
import { Flex } from '@atom/Flex';
import { TagComponent } from '@atom/Tag';
import ConfirmModal from '@organism/ConfimationModal';
import { addStaffToPosition } from '@pages/EventManagementPage/AddEditEvent/SharedTabs/Staffing/helper';
import UnassignedEmployeePopOver from '@pages/EventManagementPage/AddEditEvent/SharedTabs/Staffing/UnassignedEmployeePopOver';
import { updateRawData } from '@utils/index';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { removeEmployeeFromStaffing, removePosition, updateQuantity } from './helper';
import { ScrollFlex } from './style';
function StaffTableItem(props: any) {
  const { staff = {} } = props;
  const [tempEmp, setTempEmp] = useState<any>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const rawData = useSelector((state: any) => state.rawData);
  const { recallAPI = 0 } = rawData;
  const { quantity } = staff;

  const style = {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#e4a85380',
    alignItems: 'center',
    cursor: 'pointer'
  };
  const employees = useSelector(
    (state: any) => state.rawData?.[`staffEmployees_${staff.position_id}`]
  );

  const onUpdateQuantity = async (qty: number, selectedEmployee?: any) => {
    try {
      updateRawData({ showStaffingLoader: true });
      if (selectedEmployee) {
        await removeEmployeeFromStaffing(selectedEmployee?.id);
      } else {
        await updateQuantity(staff.id, qty);
      }
      updateRawData({ recallAPI: recallAPI + 1, showStaffingLoader: false });
    } catch (error) {
      updateRawData({ showStaffingLoader: false });
    }
  };

  const onRemovePosition = async () => {
    try {
      updateRawData({ showStaffingLoader: true });
      const res = await removePosition(staff.id);
      updateRawData({ recallAPI: recallAPI + 1, showStaffingLoader: false });
    } catch (error) {
      updateRawData({ showStaffingLoader: false });
    }
  };

  const handleSetEmp = async (empData?: any) => {
    try {
      updateRawData({ showStaffingLoader: true });
      const res = await addStaffToPosition(staff.id, empData?.id || tempEmp?.id);
      updateRawData({ recallAPI: recallAPI + 1, showStaffingLoader: false });
    } catch (error) {
      updateRawData({ showStaffingLoader: false });
    }
  };

  const onStaffAdded = async (employee: any) => {
    const empData = employees?.find((i: any) => i?.id === employee);
    if (empData?.status === 'UNAVAILABLE' || empData?.status === 'STAFFED') {
      setTempEmp(empData);
      setIsModalVisible(true);
    } else {
      handleSetEmp(empData);
    }
  };

  //   const {quantity}
  const assigneeArray = new Array(quantity).fill('').map((i) => ({ localId: Math.random() }));
  return (
    <Flex alignItems={'center'}>
      <Flex onClick={() => onUpdateQuantity(1)}>
        <TagComponent
          text={staff?.position?.name}
          onClose={(e: any) => {
            e.stopPropagation();
            onRemovePosition();
          }}
          prefix={<PlusCircleFilled style={{ color: 'white', fontSize: 16 }} />}
          rounded
          closable={!quantity}
          style={{ ...style }}
        />
      </Flex>

      <ScrollFlex>
        {assigneeArray.map((assignee: any, index: number) => {
          return (
            <UnassignedEmployeePopOver
              onStaffAdded={onStaffAdded}
              staff={staff}
              onUpdateQuantity={onUpdateQuantity}
              index={index}
              key={index}
            />
          );
        })}
      </ScrollFlex>
      <ConfirmModal
        visible={isModalVisible}
        onCancel={() => {
          setTempEmp(undefined);
          setIsModalVisible(false);
        }}
        title={`This staff member is not available at this time would you like to override the system and assign them anyways?`}
        onOK={() => {
          setIsModalVisible(false);
          handleSetEmp();
        }}
        okFirst={true}
        okText="Override"
      />
    </Flex>
  );
}

export default StaffTableItem;
