import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import FormDataView from '@atom/FormDataView';
import { Button } from '@atom/Buttons';
import { EditPencilIcon } from '@assets/index';
import { useNavigate } from 'react-router';
import useSWR from 'swr';
import CenterSpiner from '@atom/CenterSpiner';
import NoImage from '@assets/noimage.png';
import { getProfileDetails } from '../helper';
import moment from 'moment';
import { Flex } from '@atom/Flex';
import ChangePasswordModal from './ChangePasswordModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import { USER_TYPE_MAP } from '../constants';
import { useSelector } from 'react-redux';
import { ProfilePageWrapper } from './style';

const MyProfile = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState<boolean>(false);
  const [isRestLinkSent, setIsRestLinkSent] = useState<boolean>(false);
  const [isNewPassword, setIsNewPassword] = useState<boolean>(false);

  const navigate = useNavigate();
  const { data, isValidating } = useSWR([`/profile/view`], getProfileDetails, {
    revalidateOnFocus: false
  });
  const profileDetails = data?.profile || {};
  const { isAdmin } = useSelector((state: { user: { isAdmin: boolean } }) => state.user);

  return (
    <PageLayout>
      <ProfilePageWrapper>
        <PageTitle text="MY PROFILE" bordered>
          <Flex alignItems={'center'}>
            <span style={{ marginRight: '10px' }}>
              {isAdmin ? USER_TYPE_MAP.get(profileDetails?.user_type) : ''}
            </span>
            <Button
              variant="ghost"
              icon={<img src={EditPencilIcon} alt="edit" />}
              text="EDIT"
              onClick={() => navigate(`edit-profile`)}
            />
          </Flex>
        </PageTitle>
        {isValidating ? (
          <CenterSpiner />
        ) : (
          <Row gutter={24}>
            <Col md={6} xs={24} style={{ marginBottom: '24px' }} className="profile-image-wrapper">
              <img
                className="profile-image"
                src={profileDetails?.profile_pic || NoImage}
                style={{ maxWidth: '100%' }}
                alt="Profile Image"
              />
            </Col>
            <Col md={18} xs={24}>
              <Row className="data-container">
                <Col md={18} xs={24}>
                  <Row>
                    <Col md={12} xs={24}>
                      <FormDataView
                        heading="NAME"
                        value={profileDetails?.name || 'Not Specified'}
                      />
                    </Col>
                    <Col md={12} xs={24}>
                      <FormDataView
                        heading="DEPARTMENT(S)"
                        value={
                          profileDetails?.employee_departments
                            ?.map((d: any) => d?.name)
                            ?.join(', ') || 'Not Specified'
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={24}>
                      <FormDataView
                        heading="EMAIL"
                        value={profileDetails?.email || 'Not Specified'}
                      />
                    </Col>
                    <Col md={12} xs={24}>
                      <FormDataView
                        heading="POSITION(S)"
                        value={
                          profileDetails?.employee_positions
                            ?.map((p: any) => p?.name)
                            ?.join(', ') || 'Not Specified'
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={24}>
                      <FormDataView
                        heading="PHONE NUMBER"
                        value={
                          <span style={{ whiteSpace: 'pre-wrap' }}>
                            {profileDetails?.formated_phone_number || 'Not Specified'}
                          </span>
                        }
                      />
                    </Col>
                    <Col md={12} xs={24}>
                      <FormDataView
                        heading="REPORTS TO"
                        value={
                          <span style={{ whiteSpace: 'pre-wrap' }}>
                            {profileDetails?.reports?.name || 'Not Specified'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={24}>
                      <FormDataView
                        heading="ADDRESS"
                        value={profileDetails?.address || 'Not Specified'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} xs={24}>
                  <Row>
                    <Col className="registration-col" md={24} xs={12}>
                      <FormDataView
                        heading="REGISTRATION DATE"
                        value={
                          profileDetails?.created_at
                            ? moment(profileDetails.created_at).format('ddd, MMMM DD')
                            : 'Not Specified'
                        }
                      />
                    </Col>
                    <Col md={24} xs={12} style={{ textAlign: 'right' }}>
                      <Flex justifyContent="flex-end">
                        <Button
                          variant="ghost"
                          text="Change Password"
                          onClick={() => setIsModalVisible(true)}
                          style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            padding: '0px'
                          }}
                        />
                      </Flex>
                      <ChangePasswordModal
                        isModalVisible={isModalVisible}
                        onOk={() => {
                          setIsModalVisible(false);
                          setIsNewPassword(false);
                        }}
                        onCancel={() => {
                          setIsModalVisible(false);
                          setIsNewPassword(false);
                        }}
                        data={profileDetails}
                        onForgotPassword={() => {
                          setIsModalVisible(false);
                          setIsPasswordModalVisible(true);
                        }}
                        isNewPassword={isNewPassword}
                        setIsNewPassword={setIsNewPassword}
                      />
                      <ForgotPasswordModal
                        isModalVisible={isPasswordModalVisible}
                        onOk={() => {
                          setIsPasswordModalVisible(false);
                          setIsRestLinkSent(false);
                        }}
                        onCancel={() => {
                          setIsPasswordModalVisible(false);
                          setIsRestLinkSent(false);
                        }}
                        data={profileDetails}
                        isRestLinkSent={isRestLinkSent}
                        setIsRestLinkSent={setIsRestLinkSent}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ProfilePageWrapper>
    </PageLayout>
  );
};

export default MyProfile;
