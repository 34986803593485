import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import DataTable from '@organism/DataTable/DataTable';

import {
  getEmployeeManagementTableColumns,
  getEmployeeTableData,
  EmployeeListFilterType
} from './helper';
import { TableWrapper } from '@pages/MenuManagementPage/style';

interface EmployeeTableType {
  tab: 'all' | 'active' | 'in-active';
}

const initialFilters = {
  email: '',
  name: '',
  phone_number: '',
  position: '',
  user_type: '',
  page: 1
};
const EmployeeTable: FC<EmployeeTableType> = ({ tab }) => {
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState<EmployeeListFilterType>(initialFilters);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState<Number>(0);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [totalCount, setTotalCount] = useState<Number>(0);
  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setEmployeeTableData();
  }, [tab]);

  // const setEmployeeTableData = useCallback(
  //   debounce(async (filters = filterData, page?: number) => {
  //     setLoading(true);
  //     const result = await getEmployeeTableData(tab, page || pageNo, filters);
  //     setLoading(false);
  //     if (!result.error) {
  //       setTableData(result.data?.data || []);
  //       setTotalCount(result?.data?.total);
  //       setPageSize(result?.data?.per_page);
  //       setNextPageUrl(result?.data?.next_page_url);
  //     }
  //   }, 300),
  //   [pageNo]
  // );

  const setEmployeeTableData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setLoading(true);
      const res = await getEmployeeTableData(tab, newFilters.page, filters);
      setLoading(false);
      if (!res.error) {
        setTableData(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
    }, 300),
    []
  );

  const onFilterChangeHandler = (e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    const updatedFilters = { ...filterData, [name]: value };
    setFilterData({ ...updatedFilters, page: 1 });
    setEmployeeTableData(updatedFilters);
  };

  const columns = getEmployeeManagementTableColumns(filterData, onFilterChangeHandler);

  return (
    <TableWrapper>
      <DataTable
        loading={loading}
        columns={columns}
        nextPageURL={nextPageUrl}
        dataSource={tableData}
        pagination={{
          pageSize: pageNo,
          total: totalCount,
          current: filterData?.page,
          onChange: (page: any) => {
            const newFilters = { ...filterData, page: page };
            setFilterData(newFilters);
            setEmployeeTableData(newFilters);
          }
        }}
        // scrollConfig={{
        //   y: tableData?.length ? 'calc(100vh - 350px)' : 'auto'
        // }}
        renderEmpty={() => {
          return <p>No Employees Found</p>;
        }}
        onRowClick={(e: any) => {
          const id = e?.record?.id;
          navigate(`/employees/${id}/view`);
        }}
      />
    </TableWrapper>
  );
};

export default EmployeeTable;
