import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Col, Row } from 'antd';
import { QuoteButtonWrapper } from '../style';

interface AddEditQuoteFooterProps {
  isEvent?: boolean;
  isDraftSubmitting?: boolean;
  isSubmitting?: boolean;
  showBackButton?: boolean;
  nextButtonTitle?: string;
  isNextButtonDisabled?: boolean;
  backClickHandler?: () => void;
  cancelClickHandler: () => void;
  saveAsDraftClickHandler?: () => void;
  nextClickHandler: () => void;
  isCreateQuoteButtonVisible?: boolean;
  createQuoteHandler?: () => void;
  createQuoteTitle?: string;
  loadingCreateQuote?: boolean;
}

const AddEditQuoteFooter = (props: AddEditQuoteFooterProps) => {
  const {
    isEvent,
    isDraftSubmitting,
    isSubmitting,
    showBackButton = false,
    nextButtonTitle = 'Next',
    backClickHandler,
    cancelClickHandler,
    saveAsDraftClickHandler,
    nextClickHandler,
    isNextButtonDisabled,
    isCreateQuoteButtonVisible,
    createQuoteHandler,
    createQuoteTitle,
    loadingCreateQuote
  } = props;
  return (
    <QuoteButtonWrapper>
      <Flex
        className="footerButton responsive-css fix-mobile"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Flex className="footerButton">
          {showBackButton && (
            <Button
              variant="primary"
              text="Back"
              size="large"
              onClick={() => {
                backClickHandler && backClickHandler();
              }}
            />
          )}
        </Flex>
        <Flex className="footerButton ">
          <Button variant="secondary" text="Cancel" size="large" onClick={cancelClickHandler} />
          {saveAsDraftClickHandler && !isEvent ? (
            <Button
              variant="secondary"
              text="Save As Draft"
              isLoading={isDraftSubmitting}
              isDisable={isSubmitting}
              size="large"
              onClick={saveAsDraftClickHandler}
            />
          ) : null}
          <Button
            variant="primary"
            text={nextButtonTitle}
            isLoading={isSubmitting}
            size="large"
            isDisable={isNextButtonDisabled || isDraftSubmitting}
            onClick={nextClickHandler}
          />
          {isCreateQuoteButtonVisible ? (
            <Button
              variant="primary"
              text={createQuoteTitle}
              isLoading={loadingCreateQuote}
              size="large"
              onClick={createQuoteHandler}
            />
          ) : null}
        </Flex>
      </Flex>
    </QuoteButtonWrapper>
  );
};

export default AddEditQuoteFooter;
