import { Col, Row } from 'antd';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router';

import Logo from '@assets/logo.png';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import { H3Typography } from '@constant/typography/Typography';
import { showErrorToast, showToast } from '@utils/index';

import { resetPasswordSchema, updateResetPassword } from './helper';
import { ResetPasswordCard, ResetPasswordContainer } from './styles';

function ResetPasswordPage() {
  let { passwordVerifyString } = useParams();
  let navigate = useNavigate();

  const onSend = async (values: any) => {
    try {
      const res: any = (await updateResetPassword(
        values.newPassword,
        passwordVerifyString || '',
        values?.repeatPassword
      )) as { message: string };
      if (res?.success) {
        showToast({ message: res.message, description: '' });
        navigate('/');
      }
    } catch (error: any) {
      if (error?.error?.errors?.password_verify_string.length) {
        showErrorToast({ message: error?.error?.errors?.password_verify_string, description: '' });
        navigate('/');
        return;
      }
      if (error?.error?.errors?.new_password.length) {
        formik.setFieldError('newPassword', error?.error?.errors?.new_password);
        return;
      }

      if (error?.error?.errors?.confirm_password.length) {
        formik.setFieldError('repeatPassword', error?.error?.errors?.confirm_password);
        return;
      }
    }
  };

  const formik = useFormik({
    validationSchema: resetPasswordSchema,
    onSubmit: onSend,
    initialValues: {
      newPassword: '',
      repeatPassword: ''
    }
  });
  const { handleChange, handleSubmit, values, errors } = formik;
  return (
    <ResetPasswordContainer>
      <Row gutter={24} className="centerd">
        <Col span={24}>
          <ResetPasswordCard>
            <Row gutter={12}>
              <Flex justifyContent={'center'} flex={1}>
                <img src={Logo} alt="logo" style={{ width: 128, height: 128 }} />
              </Flex>
            </Row>
            <Row>
              <Flex justifyContent={'center'} flex={1} top={10}>
                <H3Typography>Reset Password</H3Typography>
              </Flex>
            </Row>

            <Row>
              <Flex justifyContent={'center'} flex={1}>
                <Input
                  placeholder="New Password"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  value={values.newPassword}
                  onChange={handleChange}
                  error={errors.newPassword}
                />
              </Flex>
            </Row>

            <Row>
              <Flex justifyContent={'center'} flex={1}>
                <Input
                  placeholder="Repeat Password"
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  value={values.repeatPassword}
                  onChange={handleChange}
                  error={errors.repeatPassword}
                />
              </Flex>
            </Row>

            <Row>
              <Flex flex={1} gap={8} justifyContent={'end'} top={24}>
                <Button
                  text={'Reset Password'}
                  variant="filed"
                  isLoading={formik.isSubmitting}
                  onClick={handleSubmit}
                  isDisable={!formik.isValid}
                />
              </Flex>
            </Row>
          </ResetPasswordCard>
        </Col>
      </Row>
    </ResetPasswordContainer>
  );
}

export default ResetPasswordPage;
