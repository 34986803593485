import { Col, Row, Spin } from 'antd';
import { FieldArray, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { getBundle, MENU_MANAGEMENT_TABS } from '@pages/MenuManagementPage/helper';
import { showErrorToast, showToast } from '@utils/index';

import {
  addEditBundle,
  addEditBundleValidations,
  getAddBundleDropdownData,
  getInitialBundleData,
  newProduct
} from './helper';
import ProductDataFields from './ProductDataFields';
import ScrollToFieldError from '@utils/formikFocus';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';

function AddEditBundle() {
  const { id } = useParams();
  const [isBundleLoading, setIsBundleLoading] = useState(true);
  const [bundleData, setBundledata] = useState([]);
  const [initialValues, setInitialValues] = useState<any>({});
  const [isProductDataLoading, setIsProductDataLoading] = useState<boolean>(true);
  const [dropdownData, setDropdownData] = useState<any>({});
  const [duplicateError, setDuplicateError] = useState<any>({});
  const navigate = useNavigate();
  const device = useWindowSizeChangedforGrid();

  useEffect(() => {
    updateDropdownData();
    updateInitialValues();
  }, []);

  const updateDropdownData = async () => {
    setIsProductDataLoading(true);
    try {
      const ddRes = await getAddBundleDropdownData();
      setDropdownData({
        productTypeList: ddRes?.[0]?.success
          ? ddRes?.[0]?.data?.filter((i: any) => i?.text !== 'Archived')
          : [],
        productsList: ddRes?.[1]?.success ? ddRes?.[1]?.data : []
      });
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch dropdown data'
      });
    }
    setIsProductDataLoading(false);
  };

  const updateInitialValues = async () => {
    setIsBundleLoading(true);
    let res = {} as any;
    if (id) {
      try {
        res = await getBundle(id);
        if (res?.success) {
          setBundledata(res?.data);
        } else {
          showErrorToast({
            message: 'Failed!',
            description: 'Failed to fetch bundle data'
          });
        }
      } catch (error) {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch bundle data'
        });
      }
    }
    const initVal = getInitialBundleData(id ? res?.data : null);
    setInitialValues(initVal);
    setIsBundleLoading(false);
  };

  const onSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    try {
      const res: any = await addEditBundle(values, id);
      if (res?.success) {
        actions.resetForm();
        showToast({
          message: 'Success!',
          description: 'Bundle Saved'
        });
        navigate(`/menu/tab/${MENU_MANAGEMENT_TABS.BUNDLES}`);
      } else {
        actions.setErrors(res?.error);
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {}
    actions.setSubmitting(false);
  };

  return (
    <PageLayout>
      {isProductDataLoading || isBundleLoading ? (
        <Spin />
      ) : (
        <>
          <Flex direction="column" style={{ width: '100%' }}>
            <PageTitle
              text="PRODUCT LIST"
              isBack={true}
              bordered={true}
              path={`/menu/tab/${MENU_MANAGEMENT_TABS.BUNDLES}`}
            />
          </Flex>
          <Formik
            initialValues={initialValues}
            validationSchema={addEditBundleValidations}
            onSubmit={(values, actions) => {
              onSubmit(values, actions);
            }}>
            {(props) => {
              const {
                handleSubmit,
                touched,
                handleChange,
                values,
                handleBlur,
                errors,
                setFieldValue
              } = props;
              return (
                <>
                  <ScrollToFieldError formik={props} />
                  <Row gutter={16}>
                    <Col xs={24} lg={12}>
                      <Label isMandatory={true} text="Bundle Name" />
                      <Input
                        name={`bundle_name`}
                        id={`bundle_name`}
                        error={touched?.bundle_name && errors?.bundle_name}
                        placeholder="Type Name"
                        onChange={handleChange}
                        value={values.bundle_name}
                        onBlur={handleBlur}
                      />
                    </Col>
                  </Row>
                  <div className={`${device !== 'dektop' ? 'overflow-x-auto' : ''}`}>
                    <FieldArray
                      name={'products'}
                      render={(arrayHelper: any) => {
                        return values?.products?.map((item: any, index: any) => {
                          return (
                            <ProductDataFields
                              key={index}
                              formik={props}
                              item={item}
                              index={index}
                              dropdownData={dropdownData}
                              duplicateError={duplicateError}
                              setDuplicateError={setDuplicateError}
                            />
                          );
                        });
                      }}
                    />
                  </div>
                  <Row gutter={16} style={{ marginTop: '10px' }}>
                    <Col xs={24} lg={4} className="fix-mobile">
                      <Button
                        variant="secondary"
                        icon={<PlusCircleOutlined />}
                        text="Add Product"
                        isDisable={!!Object.values(duplicateError)?.length}
                        onClick={() => {
                          setFieldValue('products', [
                            ...values?.products,
                            { ...newProduct, tempId: new Date().getTime() }
                          ]);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={0} lg={18} />
                    <Col xs={24} lg={6}>
                      <Flex className="footerButton fix-mobile">
                        <Button
                          variant="secondary"
                          text="Cancel"
                          onClick={() => navigate(`/menu/tab/${MENU_MANAGEMENT_TABS.BUNDLES}`)}
                          style={{ width: '100%' }}
                        />
                        <Button
                          variant="primary"
                          size="large"
                          text="Submit"
                          onClick={() => {
                            !Object.keys(duplicateError)?.length && handleSubmit();
                          }}
                          isLoading={props.isSubmitting}
                          style={{ width: '100%' }}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </PageLayout>
  );
}

export default AddEditBundle;
