import styled from 'styled-components';

export const ViewTableCell = styled.div`
  padding: 17px 6px !important;
`;

export const DataTableWrapper = styled.div`
  .expandableTableRow .ant-table-row {
    vertical-align: top;
  }
  .ant-table-wrapper .ant-table {
    overflow: none !important;
    min-height: 280px !important;
  }
`;
