import { useNavigate } from 'react-router';

import HorizontalTab from '@molecules/HorizontalTabs';

import { MenuManagementTabsProps } from '../types';
import { getMenuManagementTabs } from './helper';

function ManuManagementTabs(props: MenuManagementTabsProps) {
  const { activePanel } = props;
  const navigate = useNavigate();
  const menuManagementTabs = getMenuManagementTabs(activePanel);

  return (
    <HorizontalTab
      value={menuManagementTabs}
      activeKey={activePanel}
      onChange={(tab: any) => {
        let newurl = `/menu/tab/${tab}`;
        navigate(newurl);
      }}
    />
  );
}

export default ManuManagementTabs;
