import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import CenterSpiner from '@atom/CenterSpiner';

export const PdfViewer = ({
  fileUrl,
  isLoading,
  height = '750px'
}: {
  fileUrl: string;
  isLoading: boolean;
  height: string;
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  if (!fileUrl) {
    if (isLoading) return <CenterSpiner />;

    return <>No PDF available!</>;
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
      <div style={{ height }}>
        <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  );
};
