import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Col, Row } from 'antd';

interface AddEditEventFooterProps {
  isSubmitting?: boolean;
  showBackButton?: boolean;
  nextButtonTitle?: string;
  isNextButtonDisabled?: boolean;
  backClickHandler?: () => void;
  cancelClickHandler: () => void;
  nextClickHandler: () => void;
}

const AddEditEventFooter = (props: AddEditEventFooterProps) => {
  const {
    showBackButton = false,
    nextButtonTitle = 'Next',
    backClickHandler,
    cancelClickHandler,
    nextClickHandler,
    isNextButtonDisabled,
    isSubmitting
  } = props;
  return (
    <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
      <Col lg={12} xs={24} md={12}>
        <Row>
          <Col lg={6} md={6} xs={24}>
            {showBackButton && (
              <Button
                fullWidth
                variant="primary"
                text="Back"
                size="large"
                onClick={() => {
                  backClickHandler && backClickHandler();
                }}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col lg={12} md={12} xs={24}>
        <Row gutter={24}>
          <Col lg={12} xs={24}></Col>
          <Col lg={12} xs={24}>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} xs={24}>
                <Button
                  fullWidth
                  variant="secondary"
                  text="Cancel"
                  size="large"
                  onClick={cancelClickHandler}
                />
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Button
                  fullWidth
                  variant="primary"
                  text={nextButtonTitle}
                  isLoading={isSubmitting}
                  size="large"
                  isDisable={isNextButtonDisabled}
                  onClick={nextClickHandler}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AddEditEventFooter;
