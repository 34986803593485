import { Badge } from 'antd';
import styled from 'styled-components';

export const ProductBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
  .ant-badge-status-text {
    font-size: 16px;
  }
`;
