import { Flex } from '@atom/Flex';
import styled from 'styled-components';

export const GenralDocumentWrapper = styled.div`
@media (max-width: 650px) {
    .mobile-style{
        justify-content: left !important;
        margin: 5px 0px !important;
        display: flow-root !important;
        margin: 0px !important;
        min-width:300px;
    }
    .file-image-fixing{
      align-items: flex-start !important;
    }

`;

export const TaskComponentWrapper = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    color: #fff;

    border-color: #ff8a00 !important;
    background-color: #ff8a00 !important;
    border-radius: 50%;
  }
  .ant-checkbox-inner {
    border-radius: 100px;
    border-color: #232323;
    &:hover {
      border-radius: 100px !important;
      border-color: #232323 !important;
    }
    &:focus {
      border-radius: 100px !important;
      border-color: #232323 !important;
    }
  }
`;
export const DateTimeGuestWrapper = styled(Flex)`
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  label {
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
    height: 45px;
  }
`;

export const EventDetailsWrapper = styled.div`
  .ant-tabs-nav-wrap {
    border-bottom: transparent;
    // max-width: 800px;
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }
`;

export const EventHeaderWrapper = styled.div`
  margin-left: 80px;
  margin-right: 80px;
  .event-btn-wrapper {
    justify-content: end;
  }

  @media (max-width: 768px) {
    margin-left: 0px !important;
    margin-right: 0px !important;
    .event-btn-wrapper {
      justify-content: start;
      flex-wrap: wrap;
    }
    img {
      width: 16px;
    }
  }
`;

export const EventHorizontalTabWrapper = styled.div`
  margin-left: 80px;
  margin-right: 80px;
  @media (max-width: 768px) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
`;

export const HorizontalTabWrapper = styled.div`
  @media (max-width: 768px) {
    .ant-tabs-nav-wrap {
      overflow: scroll !important;
    }
  }
`;

export const ModalComponentWrapper = styled.div`
  .ant-modal-content {
    padding: 1rem 2rem 2rem 2rem !important;
  }
`;

export const DownloadContentWrapper = styled.div`
  @media (max-width: 768px) {
    .mobile-display-fix {
      display: block !important;
    }
    .btn-fixing {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }
`;
