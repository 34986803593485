import DataTable from '@organism/DataTable/DataTable';
import { localStorageUtil } from '@utils/localstorageUtil';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getEvents, getEventsColumns } from './helper';
import { EventListContainer } from './styles';

function EventListTable(props: any) {
  const { currentPanel, startDate, endDate, callApi, setCallApi } = props;
  const { tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const localStorageKeys = [
    'event_date',
    'code',
    'guests',
    'customer_name',
    'event_manager',
    'event_staffs',
    'kitchen_staff',
    'account_manager',
    'event_time',
    'event_name',
    'venue_name'
  ];

  const initialValues: Record<string, string> = {};
  localStorageKeys.forEach((key) => {
    initialValues[key] = localStorage.getItem(key) || '';
  });

  const [filterData, setFilterData] = useState({
    page: 1,
    event_start_date: startDate,
    event_end_date: endDate,
    ...initialValues
  });

  useEffect(() => {
    if (currentPanel === tab || callApi) {
      setCallApi(false);
      setFilterData(filterData);
      setEventsTableData({
        ...filterData,
        page: 1,
        tab: tab,
        event_start_date: startDate,
        event_end_date: endDate
      });
    }
  }, [tab, callApi]);

  const setEventsTableData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setIsTableLoading(true);
      const res = await getEvents(newFilters.page, filters);
      setIsTableLoading(false);
      if (!res.error) {
        setTableData(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
    }, 300),
    []
  );

  const onFilterChangeHandler = (e: ChangeEvent) => {
    const { id, value } = e.target as HTMLInputElement;
    const updatedFilters = { ...filterData, [id]: value };
    setFilterData({ ...updatedFilters, page: 1 });
    setEventsTableData({ ...updatedFilters, page: 1 });
    localStorage.setItem(id, value);
  };

  const onClear = (data: any) => {
    const { id, value } = data;
    const updatedFilters = { ...filterData, [id]: value };
    setFilterData({ ...updatedFilters, page: 1 });
    setEventsTableData({ ...updatedFilters, page: 1 });
    localStorage.removeItem('event_date');
    localStorage.removeItem('event_time');
  };

  const onRowClick = (row: any) => {
    localStorageUtil.setValue('location', {
      state: { prevPath: location?.pathname, path: location?.state?.path }
    });
    navigate(`/events/${row.record.id}/view`, {
      state: { prevPath: location?.pathname, path: location?.state?.path }
    });
  };
  const cols = getEventsColumns(filterData, onFilterChangeHandler, onClear);

  return (
    <EventListContainer>
      <DataTable
        loading={isTableLoading}
        columns={cols}
        showLoadMore={!!nextPageUrl}
        nextPageURL={nextPageUrl}
        dataSource={tableData}
        pagination={{
          pageSize: pageNo,
          total: totalCount,
          current: filterData?.page,
          onChange: (page: any) => {
            const newFilters = { ...filterData, page: page };
            setFilterData(newFilters);
            setEventsTableData(newFilters);
          }
        }}
        onFetch={() => {}}
        renderEmpty={() => <div>No Events Found.</div>}
        onRowClick={onRowClick}
      />
    </EventListContainer>
  );
}

export default EventListTable;
