export interface ResponseType {
  data: any;
  error: string | null;
  message?: string | null;
}

export const checkDuplicateError = (
  values: any,
  parent: string,
  child: string,
  parentErrorMessage: string,
  childErrorMessage: string
) => {
  let parentFieldErrors = {};
  let childFieldErrors = {};
  // Check Duplicate Parent Field
  for (let i = 1; i < values?.[parent]?.length - 1; i++) {
    let foundDuplicate = false;
    for (let j = i + 1; j < values?.[parent]?.length; j++) {
      foundDuplicate =
        values?.[parent]?.[i]?.name &&
        values?.[parent]?.[j]?.name &&
        values?.[parent]?.[i]?.name === values?.[parent]?.[j]?.name;
      if (foundDuplicate) {
        parentFieldErrors = { ...parentFieldErrors, [j]: parentErrorMessage };
      }
    }
  }
  // Check Duplicate Child Field
  values?.[parent]?.forEach((c: any, index: number) => {
    for (let i = 0; i < values?.[parent]?.[index]?.[child].length - 1; i++) {
      let foundDuplicate = false;
      for (let j = i + 1; j < values?.[parent]?.[index]?.[child].length; j++) {
        foundDuplicate =
          values?.[parent]?.[index]?.[child][i]?.name &&
          values?.[parent]?.[index]?.[child][j]?.name &&
          values?.[parent]?.[index]?.[child][i].name === values?.[parent]?.[index]?.[child][j].name;
        if (foundDuplicate) {
          childFieldErrors = {
            ...childFieldErrors,
            [index]: { [j]: childErrorMessage }
          };
        }
      }
    }
  });
  return { parentFieldErrors, childFieldErrors };
};
