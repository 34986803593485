import FormDataView, { EmptyValue } from '@atom/FormDataView';
import { H2Typography } from '@constant/typography/Typography';
import { Col, Row } from 'antd';
import { EventContentWrapper, SectionTitleDiv } from './style';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';

interface CustomerInformationProps {
  data: any;
  isEvent?: boolean;
  isQuoteTab?: boolean;
}

const CustomerInformation = (props: CustomerInformationProps) => {
  const { data, isEvent, isQuoteTab } = props;
  const device = useWindowSizeChangedforGrid();

  return (
    <>
      <Row gutter={16} className="xs-top-space" style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={isEvent ? 'event-section' : 'quote-section'}>
              Customer Information
            </H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>

      {/* <Row gutter={[16, 16]}>
          <Col lg={8} xs={isQuoteTab ? 12 : 24}>
            <FormDataView
              heading="CUSTOMER NAME"
              value={data?.customer?.customer_name || data?.name}
            />
          </Col>
          <Col lg={8} xs={isQuoteTab ? 12 : 24}>
            <FormDataView
              heading="PHONE NUMBER"
              value={
                data?.customer?.phone_number
                  ? `${data?.customer?.country_code || ''}${data?.customer?.phone_number}`
                  : data?.phone_number
                  ? `${data?.country_code || ''}${data?.phone_number}`
                  : undefined
              }
            />
          </Col>
          <Col lg={8} xs={24}>
            <FormDataView heading="EMAIL" value={data?.customer?.email || data?.email} />
          </Col>
          {!isEvent && (
            <Col lg={8} xs={24}>
              <FormDataView heading="BILLING ADDRESS" value={data?.customer?.address} />
            </Col>
          )}
        </Row> */}

      <EventContentWrapper style={isEvent ? { paddingLeft: '15px', paddingRight: '15px' } : {}}>
        {isEvent && (
          <>
            <Row gutter={[16, 16]} style={{ paddingTop: `${device == 'mobile' ? '0px' : '10px'}` }}>
              <Col lg={8} xs={12}>
                <FormDataView
                  heading="ON SITE CONTACT"
                  value={data?.event_detail?.customer_on_site_name}
                />
              </Col>
              <Col lg={8} xs={12}>
                <FormDataView
                  heading="ON SITE PHONE NUMBER"
                  value={
                    data?.event_detail?.customer_on_site_phone_number ? (
                      <a
                        href={`tel:${data?.event_detail?.customer_on_site_country_code ?? ''}${
                          data?.event_detail?.customer_on_site_phone_number
                        }`}
                        style={{ color: 'black' }}
                      >
                        {`${
                          data?.event_detail?.customer_on_site_country_code
                            ? data?.event_detail?.customer_on_site_country_code
                            : ''
                        } ${data?.event_detail?.customer_on_site_phone_number} ${
                          data?.event_detail?.customer_on_site_extension
                            ? 'x' + data?.event_detail?.customer_on_site_extension
                            : ''
                        }`}
                      </a>
                    ) : (
                      ''
                    )
                  }
                />
              </Col>
              <Col lg={8} xs={12}>
                <FormDataView
                  heading="ON SITE EMAIL"
                  value={
                    <a
                      href={`mailto:${data?.event_detail?.customer_on_site_email}`}
                      style={{ color: 'black' }}
                    >
                      {data?.event_detail?.customer_on_site_email}
                    </a>
                  }
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: '15px' }}>
              <Col lg={8} xs={12}>
                <FormDataView heading="CUSTOMER NAME" value={data?.customer_detail?.name} />
              </Col>
              <Col lg={8} xs={12}>
                <FormDataView
                  heading="CUSTOMER EMAIL"
                  value={
                    <a href={`mailto:${data?.customer_detail?.email}`} style={{ color: 'black' }}>
                      {data?.customer_detail?.email}
                    </a>
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {!isEvent && (
          <Row gutter={[16, 16]}>
            <Col lg={8} xs={isQuoteTab ? 12 : 24}>
              <FormDataView heading="CUSTOMER NAME" value={data?.customer?.customer_name} />
            </Col>
            <Col lg={8} xs={isQuoteTab ? 12 : 24}>
              <FormDataView
                heading="PHONE NUMBER"
                value={
                  data?.customer?.phone_number ? (
                    <a
                      href={`tel:${data?.customer?.country_code || ''}${
                        data?.customer?.phone_number
                      }`}
                      style={{ color: 'black' }}
                    >
                      {`${data?.customer?.country_code || ''}${data?.customer?.phone_number}`}
                    </a>
                  ) : data?.phone_number ? (
                    <a href={`tel:${data?.country_code || ''}${data?.phone_number}`}>
                      {`${data?.country_code || ''}${data?.phone_number}`}
                    </a>
                  ) : undefined
                }
              />
            </Col>
            <Col lg={8} xs={24}>
              <FormDataView
                heading="EMAIL"
                value={
                  <a href={`mailto:${data?.customer?.email}`} style={{ color: 'black' }}>
                    {data?.customer?.email ? (
                      data?.customer?.email
                    ) : (
                      <EmptyValue style={{ color: 'rgba(0, 0, 0, 0.5)' }}>Not Specified</EmptyValue>
                    )}
                  </a>
                }
              />
            </Col>
            <Col lg={8} xs={24}>
              <FormDataView heading="BILLING ADDRESS" value={data?.customer?.address} />
            </Col>
          </Row>
        )}
      </EventContentWrapper>
    </>
  );
};

export default CustomerInformation;
