import FormDataView from '@atom/FormDataView';
import { H2Typography } from '@constant/typography/Typography';
import DataTable from '@organism/DataTable/DataTable';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';
import { SectionTitleDiv } from '@pages/QuoteManagementPage/ViewQuoteDetails/style';
import { Col, Row } from 'antd';
import React from 'react';
import { getEventTimelineColumns } from '../column';
import { DataTablepaddingWrapper } from './style';

interface EventTimelineProps {
  data: any;
}

const EventTimeline = (props: EventTimelineProps) => {
  const { data } = props;
  return (
    <>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            <H2Typography className={'event-section'}>Event Timelines</H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      {/* <Row gutter={[16, 16]}>
        <Col lg={8} xs={24}>
          <FormDataView
            heading="DATE"
            value={
              (data?.event_detail?.event_date && data?.event_detail?.event_date !== '0000-00-00') ||
              '--'
            }
          />
        </Col>
      </Row> */}
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <DataTablepaddingWrapper>
            <DataTableWrapper>
              <DataTable
                className="fixTD"
                renderEmpty={() => {
                  return <>No Timelines Found</>;
                }}
                dataSource={data?.event_timeline}
                columns={getEventTimelineColumns()}
              />
            </DataTableWrapper>
          </DataTablepaddingWrapper>
        </Col>
      </Row>
    </>
  );
};

export default EventTimeline;
