import { Col, Divider, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';
import { H2Typography } from '@constant/typography/Typography';
import { IAddEditInfoProps } from '@pages/VenueManagementPage/types';
import { Label } from '@atom/FormLable';
import { TextAreaInput } from '@atom/TextArea';
import { getIn } from 'formik';
import RadioButton from '@atom/RadioButton';
import { ContentWrapper } from '../style';

function ServiceInfoSection(props: any) {
  const { coatCheckValue, addInfoError, addInfoValue, handleBlur, id, setFieldValue } = props;

  const [coatCheck, setCoatCheck] = useState<any>();
  const [addInfo, setaddInfo] = useState<any>();

  useEffect(() => {
    if (id && !!addInfoValue) {
      setaddInfo(addInfoValue);
    }
    if (id && !!coatCheckValue) {
      setCoatCheck(coatCheckValue);
    }
  }, [id]);

  const handleChange = (e: any, setState: (value: any) => void) => {
    setTimeout(() => {
      setFieldValue(e?.target?.id, e?.target?.value);
    }, 200);
    setState(e?.target?.value);
  };

  const handleRadioChange = (e: any, setState: (value: any) => void) => {
    setTimeout(() => {
      setFieldValue(e?.target?.name, e?.target?.value);
    }, 200);
    setState(e?.target?.value);
  };

  return (
    <Flex style={{ width: '100%' }} direction="column">
      <H2Typography style={{ marginTop: '15px' }}>Service Information</H2Typography>
      <Divider style={{ borderColor: '#e0e0e0', margin: '15px 0px' }}></Divider>
      <ContentWrapper>
        <Row gutter={16} justify="space-between" style={{ width: '100%', marginBottom: '24px' }}>
          <Col xs={24} lg={12}>
            <Label text="Coat Check" />
            <RadioButton
              name="venue_detail.coat_check"
              id="venue_detail.coat_check"
              value={coatCheck && Number(coatCheck)}
              onChange={(e) => handleRadioChange(e, setCoatCheck)}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </RadioButton>
          </Col>
        </Row>
        <Row gutter={16} justify="space-between" style={{ width: '100%' }}>
          <Col xs={24} lg={24}>
            <Label text="Additional Information" />
            <TextAreaInput
              id={`venue_detail.coat_check_additional_information`}
              name={`venue_detail.coat_check_additional_information`}
              placeholder="Additional Information"
              value={addInfo}
              onChange={(e: any) => handleChange(e, setaddInfo)}
              error={addInfoError}
              onBlur={handleBlur}
              allowClear={true}
            />
          </Col>
        </Row>
      </ContentWrapper>
    </Flex>
  );
}

export default ServiceInfoSection;
