import * as Yup from 'yup';

import services from '@services/index';

export const vendorQuoteItem = Yup.object().shape({
  vendor_id: Yup.string().nullable(),
  rate: Yup.number()
    .when('vendor_id', {
      is: (value: number) => value && value,
      then: Yup.number().required('The rate field is required..').typeError('Rate must be a number')
    })
    .nullable()
    .typeError('Rate must be a number'),
  total_cost: Yup.string().nullable(),
  quantity: Yup.number()
    .when('vendor_id', {
      is: (value: number) => value && value,
      then: Yup.number()
        .required('The quantity field is required..')
        .typeError('Qty must be a number')
    })
    .nullable()
    .typeError('Qty must be a number'),
  waived_off: Yup.boolean().nullable(),
  notes: Yup.string().nullable()
});

export const vendorEventItem = Yup.object().shape({
  vendor_id: Yup.string().nullable(),
  rate: Yup.number()
    .when('vendor_id', {
      is: (value: string) => value && value,
      then: Yup.number().required('The rate field is required..').typeError('Rate must be a number')
    })
    .nullable()
    .typeError('Rate must be a number'),
  total_cost: Yup.string().nullable(),
  quantity: Yup.number()
    .when('vendor_id', {
      is: (value: string) => value && value,
      then: Yup.number()
        .required('The qunatity field is required..')
        .typeError('Qty must be a number')
    })
    .nullable()
    .typeError('Qty must be a number'),
  waived_off: Yup.boolean().nullable(),
  notes: Yup.string().nullable(),
  event_start_time: Yup.string().nullable(),
  event_end_time: Yup.string().nullable()
});

export const vendorsQuoteFormSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  unique_invoice_string: Yup.string().nullable(),
  quotation_no: Yup.string().nullable(),
  event_dates: Yup.object().shape({
    id: Yup.string().nullable(),
    quotation_id: Yup.string().nullable(),
    date_string: Yup.string().nullable(),
    event_date: Yup.array().of(Yup.string().nullable()),
    vendors: Yup.array().of(vendorQuoteItem)
  })
});

export const vendorsEventFormSchema = Yup.object().shape({
  vendors: Yup.array().of(vendorEventItem)
});

export interface IVendorListItemType {
  id: number;
  vendor_name: string;
  formated_phone_number: string;
}

export interface IVendorType {
  id?: string;
  quotation_id?: string;
  quotation_event_date_id?: string;
  vendor_id: string;
  quantity: number | string;
  rate: number | string;
  total_cost: number | string;
  waived_off?: boolean;
  notes?: string;
  is_additional_vendor?: boolean;
  event_start_time?: string;
  event_end_time?: string;
}

export interface IValuesType {
  id?: string | null;
  unique_invoice_string?: string | null;
  quotation_no?: string | null;
  event_dates?: {
    id?: string | null;
    quotation_id?: string | null;
    event_date?: string[] | null;
    date_string?: string;
    vendors?: IVendorType[];
  };
  vendors?: IVendorType[];
}

export const FORM_INITIAL_VALUES: IValuesType = {
  id: '',
  unique_invoice_string: '',
  quotation_no: '',
  event_dates: {
    id: '',
    quotation_id: '',
    event_date: [new Date().toString()],
    date_string: '',
    vendors: [
      {
        vendor_id: '',
        quantity: '',
        rate: '',
        total_cost: '',
        waived_off: false,
        notes: ''
      }
    ]
  },
  vendors: [
    {
      id: '',
      quotation_id: '',
      quotation_event_date_id: '',
      vendor_id: '',
      quantity: '',
      rate: '',
      total_cost: '',
      event_start_time: '',
      event_end_time: '',
      is_additional_vendor: false
    }
  ]
};

export const vendorPositionItem = (isEventsTab: boolean) => {
  const item: IVendorType = {
    vendor_id: '',
    rate: '',
    quantity: '',
    total_cost: ''
  };
  if (isEventsTab) {
    item['event_start_time'] = '';
    item['event_end_time'] = '';
    item['is_additional_vendor'] = false;
  } else {
    item['waived_off'] = false;
  }
  return item;
};

export const getMappedData = (oldValuesObj: any, newValuesObj: any, isEventsTab: boolean) => {
  if (isEventsTab) {
    const vendors =
      !!Object.values(newValuesObj || oldValuesObj) &&
      Object.values(newValuesObj || oldValuesObj)?.length > 0
        ? Object.values(newValuesObj || oldValuesObj)
        : [vendorPositionItem(isEventsTab)];
    return { quote_id: newValuesObj?.quoteId, vendors };
  } else {
    const item = {
      ...oldValuesObj,
      id: newValuesObj?.id || oldValuesObj?.id,
      quotation_no: newValuesObj?.quotation_no || oldValuesObj?.quotation_no,
      status: newValuesObj?.status ?? oldValuesObj?.status,
      unique_invoice_string:
        newValuesObj?.unique_invoice_string || oldValuesObj?.unique_invoice_string,
      event_dates: {
        ...oldValuesObj?.event_dates,
        ...newValuesObj?.event_dates,
        event_date: [newValuesObj?.event_dates?.event_date],
        quotation_event_date_id: newValuesObj?.event_dates?.id || oldValuesObj?.event_dates?.id
      }
    };
    return item;
  }
};

export const GetVendorEventData = async (
  quote_id: string | number,
  event_date_id: string | number
) => {
  try {
    const res: any = await services.get(
      `/events/vendor?quote_id=${quote_id}&event_date_id=${event_date_id}`
    );
    if (res?.data?.success) return { data: res?.data.data, success: true };
  } catch (err: any) {
    return {
      error: err?.error?.error ? err?.error?.error : 'Something went wrong!',
      success: false
    };
  }
};

export const getVendorList = async () => {
  try {
    const res = (await services.get(`/employees/vendor-list`)) as {
      data: any;
    };
    return res?.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getVendorsList = async () => {
  try {
    const res = (await services.get(`/employees/vendor-list`)) as any;
    return res?.data?.data || {};
  } catch (error) {
    return {};
  }
};
