import { Row } from 'antd';
import styled from 'styled-components';

export const SectionTitleDiv = styled.div`
  .quote-section {
    padding-bottom: 24px;
    margin-bottom: 12px;
    border-bottom: solid 1px #e0e0e0;
  }

  .event-section {
    margin-bottom: 12px;
    background-color: #f0f0f0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    line-height: 15px;
  }
`;

export const ManagerNotesDiv = styled(Row)`
  border: 1px solid #cdcdcd;
  padding: 10px;
  border-radius: 4px;
`;

export const MealPlanRowWrapper = styled(Row)`
  @media (max-width: 767px) {
    margin-bottom: 30px;
    .content-div {
      margin-bottom: 0px !important;
    }
  }
`;

export const SubtotalAndCostDiv = styled.div`
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #191919;
  padding: 15px;
  vertical-align: top;
  padding-left: 0;
  gap: 8px;
  font-size: 16px !important;
  background: transparent;
`;

export const Divider = styled.hr`
  width: 100%;
  opacity: 0.3;
`;

export const CostLabel = styled.span<{ waived_off: boolean }>`
  color: ${(props: any) => (props?.waived_off ? '#FF0000' : '#000000')};
`;

export const VersionHistoryWrapper = styled.div`
  .ant-select-selector {
    :before {
      content: 'Last Updated: ';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      text-transform: uppercase;
      padding-right: 10px;
      color: #838383;
    }
  }
  .ant-select-selection-item {
    font-size: 16px;
    font-weight: 500;
  }
`;
export const ButtonStyleWrapper = styled.div`
  @media (max-width: 767px) {
    .fix-mobile {
      width: 100% !important;
      justify-content: normal !important;
      display: inline-grid;
    }
  }
`;

export const QuoteStatusWrapper = styled.div`
  .status {
    font-size: 30px;
    font-weight: 400;
    line-height: normal !important;
  }

  .flex-fixing {
    justify-content: end;
    flex-wrap: wrap !important;
  }
  @media (max-width: 768px) {
    .status {
      margin: 30px 0px;
    }
    .status-title {
      margin-bottom: 25px;
    }
    .flex-fixing {
      margin-top: 20px;
      flex-wrap: inherit !important;
      gap: 8px;
      .btn-fixing-reject {
        padding: 5px;
      }
    }
    .btn-fixing {
      min-width: 100px !important;
    }
  }
`;
export const ContentWrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: 20px;
  }
  .content-div {
    margin-bottom: 0px;
  }
  .content-title {
    margin-bottom: 8px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .description-margin {
    margin-bottom: 10px;
  }
  .ant-row {
    row-gap: 10px !important;
    @media (max-width: 768px) {
      row-gap: 16px !important;
    }
  }
`;

export const EventContentWrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: 10px;
  }
  .content-div {
    margin-bottom: 0px;
  }
  .content-title {
    margin-bottom: 8px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .description-margin {
    margin-bottom: 10px;
  }
  .ant-row {
    row-gap: 10px !important;
    @media (max-width: 768px) {
      row-gap: 16px !important;
    }
  }
`;

export const PointofContactWrapper = styled(Row)`
  @media (max-width: 767px) {
    flex-flow: row nowrap;
    overflow: scroll !important;
    padding-bottom: 10px !important;
    display: flex !important;
    gap: 20px !important;
    .point-of-contact-col {
      flex: none !important;
      max-width: 100% !important;
    }
  }
`;

export const ViewQuoteDetailsWrapper = styled.div`
  @media (max-width: 767px) {
    h2 {
      font-size: 30px !important;
    }
    .page-btn {
      margin-left: 20px;
    }
  }
`;
