import services from '@services/index';

export enum CUSTOMER_MANAGEMENT_TABS {
  QUOTES = 'quotes',
  EVENTS = 'events'
}

export const getCustomerDetails = async (url: string, id: any) => {
  try {
    const res: any = await services.post(url, { customer_id: id });
    return res?.success ? res?.data?.data : {};
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getQuotations = async (url: string, id: any) => {
  try {
    const res: any = await services.post(url, { customer_id: id });
    return res?.success ? res?.data?.data : {};
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getEvents = async (url: string, id: any) => {
  try {
    const res: any = await services.post(url, { customer_id: id });
    return res?.success ? res?.data?.data : {};
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};

export const getFormattedPhoneNumber = (
  country_code: string,
  phone_number: string,
  extension: string
) => {
  return country_code && phone_number
    ? `${`(${
        country_code?.includes('+') ? country_code : '+' + country_code
      }) -`} ${phone_number}  ${extension ? 'x' + extension : ''}`
    : phone_number || '-';
};
