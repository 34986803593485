import EventListTable from './EventListTable';

export enum EVENT_LIST_TABS {
  UPCOMING = 'upcoming',
  PAST = 'past',
  CANCELLED = 'cancelled'
}

export const getEventListTabs = (
  activePanel: string,
  startDate: string,
  endDate: string,
  callApi: boolean,
  setCallApi: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const tabValues = [
    {
      tab: 'UPCOMING',
      content: (
        <EventListTable
          currentPanel={EVENT_LIST_TABS.UPCOMING}
          activePanel={activePanel}
          startDate={startDate}
          endDate={endDate}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      ),
      key: EVENT_LIST_TABS.UPCOMING
    },
    {
      tab: 'PAST',
      content: (
        <EventListTable
          currentPanel={EVENT_LIST_TABS.PAST}
          activePanel={activePanel}
          startDate={startDate}
          endDate={endDate}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      ),
      key: EVENT_LIST_TABS.PAST
    },
    {
      tab: 'CANCELLED',
      content: (
        <EventListTable
          currentPanel={EVENT_LIST_TABS.CANCELLED}
          activePanel={activePanel}
          startDate={startDate}
          endDate={endDate}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      ),
      key: EVENT_LIST_TABS.CANCELLED
    }
  ];
  return tabValues;
};

export const BASE_URL_GENERATE_REPORT = `${process.env.REACT_APP_BASE_API_GENERATE_REPORT}/event/generate-report`;
export const GENERATE_REPORT_URL = {
  THIS_WEEK: `${BASE_URL_GENERATE_REPORT}/this-week`,
  NEXT_WEEK: `${BASE_URL_GENERATE_REPORT}/next-week`
};
