import styled from 'styled-components';

export const AnchorButtonWrapper = styled.a`
  .ant-btn:hover,
  .ant-btn:focus {
    background: transparent;
    border-color: #000;
    color: #000;
  }
`;

export const ButtonWrapper = styled.a`
  @media (min-width: 768px) {
    .ant-btn {
      width: fit-content !important;
    }
  }
`;

export const DataBoxDiv = styled.div`
  padding-top: 10px;
  min-height: 100px;
`;

export const DataTablepaddingWrapper = styled.a`
  .ant-table-thead > tr > th {
    padding: 15px !important;
  }
  .ant-table-tbody > tr > td {
    padding: 17px 15px !important;
  }
`;

export const DropdownButtonWrapper = styled.div`
  div {
    button {
      background: transparent;
      border-color: #000;
      font-weight: 700;
      font-size: 16px;
      border-radius: 5px;
      line-height: 19px;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      padding: 12px 8px;
      border: 1px solid #000;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 149px;
      height: auto;
      &:hover {
        background: transparent;
        border-color: #000;
        color: #000;
      }
      &:disabled {
        background: transparent !important;
        color: var(--color-system-gray-gray-15) !important;
        cursor: default;
        border-color: #000;
      }
      img {
        width: 24px;
        margin-right: 6px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        min-width: 120px;
      }
    }
    button:last-child {
      min-width: 0px !important;

      @media (max-width: 768px) {
        width: 50px !important;
      }
    }
  }
`;
