import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { checkEmptyProducts, FORM_INITIAL_VALUES, getMappedData, validationSchema } from './helper';
import EventBasicDetails from '../EventBasicDetails';
import { Row, Col, Select, Spin } from 'antd';
import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { H2Typography } from '@constant/typography/Typography';
import MealDataComponent from './MealDataComponent';
import { Button } from '@atom/Buttons';
import PlusCircleBlackOutline from '@assets/icons/plus-circle-black-outline.svg';
import { MealType } from '@pages/QuoteManagementPage/types';
import AddEditQuoteFooter from '../AddEditQuoteFooter';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getTabBaseUrl, QuoteBasicDetailsObject } from '../helper';
import CenterSpiner from '@atom/CenterSpiner';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { GetProductsList, GetTabData, PutTabData } from '@services/quoteServices';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import { getEventDate, getSelectOptions } from '@utils/helper';
import { useSelector } from 'react-redux';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';

interface MealPlanFormProps {
  tabKey: string;
  quoteBasicDetails: QuoteBasicDetailsObject;
  setQuoteBasicDetails: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  submitForm?: (tabKey: string, values: any, actions: any, method: string) => void;
  isEventsTab?: boolean;
}

const MealPlanForm = (props: MealPlanFormProps) => {
  const {
    tabKey,
    quoteBasicDetails,
    setQuoteBasicDetails,
    submitForm,
    isEventsTab = false
  } = props;

  const params = useParams();
  const { quoteId, eventId } = params;

  const location = useLocation();
  const navigate = useNavigate();

  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);
  const [isBundleProductsEditable, setIsBundleProductsEditable] = useState<boolean>(false);
  const [draftSubmitting, setDraftSubmitting] = useState(false);
  const [formikData, setFormikData] = useState(FORM_INITIAL_VALUES);

  const formik = useFormik({
    initialValues: formikData,
    // validationSchema: validationSchema,
    onSubmit: (values: any, actions: any) => {
      submitHandler(values, actions);
    }
  });
  const { touched, errors, values, setFieldValue, setValues, handleSubmit } = formik;
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [overlayLoading, setOverlayLoading] = useState<boolean>(false);

  const [duplicateError, setDuplicateError] = useState<any>({});

  const fetchAllProductsListHandler = async () => {
    setPageLoading(true);
    const res: any = await GetProductsList('', quoteId);
    if (res?.success) {
      updateRawData({ productsList: res?.data });
    }
    setPageLoading(false);
  };

  const isInvalidMealData = (meal_plans: any) => {
    let showError = false;
    for (let i = 0; i < meal_plans?.length; i++) {
      if (meal_plans?.[i]?.bundles?.length && !meal_plans?.[i]?.meal_name) {
        showError = true;
        break;
      } else if (meal_plans?.[i]?.products?.length && !meal_plans?.[i]?.meal_name) {
        showError = !!meal_plans?.[i]?.products?.filter((j: any) => j?.product_subcategory_id)
          ?.length;
        break;
      }
    }
    return showError;
  };

  const checkDuplicateMealNames = (meal_plans: any, actions: any) => {
    if (isInvalidMealData(meal_plans)) {
      const emptyNames = meal_plans?.filter((item: any) => !item?.meal_name);
      showErrorToast({
        message: 'Failed!',
        description: 'All meals should have a name.'
      });
      actions?.setSubmitting(false);
      return emptyNames;
    }
    const duplicateNames = meal_plans?.filter(
      (item: any, index: number) =>
        meal_plans?.findIndex(
          (findIndexItem: any) => findIndexItem?.meal_name === item?.meal_name
        ) !== index
    );
    if (duplicateNames?.length) {
      showErrorToast({
        message: 'Failed!',
        description: 'No two Meals should have same name.'
      });
      actions?.setSubmitting(false);
      return duplicateNames;
    }
  };

  const updateInitialData = async () => {
    setPageLoading(true);
    try {
      //@ts-ignore
      const res = await GetTabData(tabKey, quoteId, isEventsTab);
      if (res?.success) {
        setFormikData((prev) => getMappedData({ ...prev }, { ...res?.data }));
        setValues(getMappedData({ ...values }, { ...res?.data }));
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id,
          quotation_no: res?.data?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string
        }));
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch quote data.'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch quote data.'
      });
    }
    setPageLoading(false);
  };

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    if (checkDuplicateMealNames(values?.event_dates?.meal_plans, actions)?.length) {
      return;
    }

    const event_dates = {
      ...values?.event_dates,
      event_date: values?.event_dates?.event_date?.[0] ?? '',
      meal_plans: values?.event_dates?.meal_plans
        ?.filter((i: any) => i?.meal_name)
        ?.map((item: any) => ({
          ...item,
          bundles: item?.bundles?.map((bundle: any) => ({
            ...bundle,
            bundle_quantity: bundle?.bundle_quantity || 0,
            products: bundle?.products?.map((product: any) => ({
              ...product,
              quantity: product?.quantity || 0,
              rate: product?.rate || 0
            }))
          }))
        }))
    };

    //check if any bundle has empty products data
    let isAnyBundleHasEmptyProds: boolean = false;
    for (let i = 0; i < event_dates?.meal_plans?.length; i++) {
      const meal: any = event_dates?.meal_plans?.[i];
      for (let j = 0; j < meal?.bundles?.length; j++) {
        const mealBundle: any = meal?.bundles?.[i];
        if (checkEmptyProducts(mealBundle)) {
          isAnyBundleHasEmptyProds = true;
          actions?.setSubmitting(false);
          break;
        }
      }
      if (isAnyBundleHasEmptyProds) {
        break;
      }
    }

    if (isAnyBundleHasEmptyProds) {
      return;
    }
    if (!isDraft && submitForm) {
      submitForm(
        tabKey,
        {
          ...values,
          event_dates: event_dates
        },
        actions,
        'post'
      );
      return;
    }
    const res: any = await PutTabData(tabKey, {
      ...quoteBasicDetails,
      ...values,
      unique_invoice_string: random_unique_invoice_string
        ? random_unique_invoice_string
        : quoteBasicDetails.unique_invoice_string,
      event_dates: event_dates
    });
    if (res?.success) {
      actions?.setSubmitting(false);
      //   setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
      //     ...prev,
      //     id: res?.data?.id,
      //     quotation_no: res?.data?.quotation_no,
      //     unique_invoice_string: res?.data?.unique_invoice_string
      //   }));
      const tabBaseUrl = getTabBaseUrl(
        location?.pathname,
        res?.data?.id || quoteId,
        quoteBasicDetails,
        res?.data?.id
      );
      navigate(isDraft ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}` : `${tabBaseUrl}meal-order`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
      if (isDraft) {
        showToast({
          message: 'Success!',
          description: 'Quotation saved as Draft'
        });
      }
    } else {
      actions?.setSubmitting(false);
      actions?.setErrors(res?.error);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
  };

  const backHandler = () => {
    if (isEventsTab) {
      const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.VENUE}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}notes`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };

  const cancelHandler = () => {
    if (isEventsTab) {
      navigate('/events');
    } else {
      navigate('/quote');
    }
  };

  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    setFieldValue('status', STATUS.DRAFT);
    await submitHandler({ ...values, status: STATUS.DRAFT }, null, true);
    setDraftSubmitting(false);
  };
  const nextHandler = () => {
    setIsBundleProductsEditable(false);
    handleSubmit();
  };

  const addNewMealHandler = () => {
    const newMealData = {
      id: null,
      meal_id: null,
      meal_name: '',
      start_time: null,
      end_time: null,
      bundle_ids: [],
      bundles: [],
      products: []
    };
    setFieldValue('event_dates.meal_plans', [
      ...(values?.event_dates?.meal_plans || []),
      {
        ...newMealData
      }
    ]);
  };

  useEffect(() => {
    (async () => {
      if (tabKey === 'meal-plan') {
        await updateInitialData();
        await fetchAllProductsListHandler();
      }
    })();
  }, [tabKey]);

  if (pageLoading) {
    return <CenterSpiner />;
  }
  return (
    <Spin spinning={overlayLoading}>
      <EventBasicDetails isMealPlan={true} />

      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col xs={24} xl={24}>
          <H2Typography>Meal Plan Estimate</H2Typography>
          <div
            style={{
              marginTop: '18px',
              background: '#E0E0E0',
              marginBottom: '8px',
              height: '1px'
            }}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} xl={12}>
          <Label text="Date" isMandatory={true} />
          <SelectInput
            id="event_dates.event_date[0]"
            name="event_dates.event_date[0]"
            placeholdertitle="Select from the list"
            value={0}>
            {getSelectOptions(
              [
                {
                  id: 0,
                  name:
                    values?.event_dates?.date_string &&
                    values?.event_dates?.date_string !== '0000-00-00'
                      ? values?.event_dates?.date_string
                      : 'Unassigned Date'
                }
              ],
              'name'
            )}
          </SelectInput>
        </Col>
      </Row>

      {values?.event_dates?.meal_plans?.map((meal: MealType, mIndex: number) => (
        <MealDataComponent
          isBundleProductsEditable={isBundleProductsEditable}
          setIsBundleProductsEditable={setIsBundleProductsEditable}
          duplicateError={duplicateError}
          setDuplicateError={setDuplicateError}
          key={`mealData-${mIndex}-${meal?.id}`}
          formik={formik}
          mIndex={mIndex}
        />
      ))}

      <Button
        text="Add Meal"
        icon={<img src={PlusCircleBlackOutline} alt="Add product" />}
        variant="secondary"
        size="medium"
        style={{ marginTop: '16px', fontWeight: 'bold', border: '2px solid #000', padding: '10px' }}
        onClick={addNewMealHandler}
      />
      <AddEditQuoteFooter
        showBackButton={true}
        isDraftSubmitting={draftSubmitting}
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        saveAsDraftClickHandler={() => !Object.keys(duplicateError)?.length && saveAsDraftHandler()}
        nextClickHandler={() => !Object.keys(duplicateError)?.length && nextHandler()}
        isEvent={isEventsTab}
        isSubmitting={formik?.isSubmitting}
      />
    </Spin>
  );
};

export default MealPlanForm;
