import Accordion from '@atom/Accordion';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { updateRawData } from '@utils/index';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { MealOrderCardWrapper } from './style';

const MealOrderCard = (props: any) => {
  const { children, meal, mealIndex } = props;

  const { activeAccordion, mealOrders } = useSelector((state: any) => state.rawData);

  const handleChange = (e: any) => {
    updateRawData({ activeAccordion: e });
  };

  const defaultActiveKey = Array.from({ length: mealOrders?.length }, (v, k) => k);

  return (
    <MealOrderCardWrapper>
      <Accordion
        title={
          <Flex direction="row">
            <Label className="label" text="Meal Name & Date:" />
            <span className="heading">{`${meal?.name}${
              meal?.date ? ` - ${moment(meal?.date).format('ddd, MMMM D')}` : ''
            }`}</span>
          </Flex>
        }
        onChange={handleChange}
        accordianKey={mealIndex}
        defaultActiveKey={defaultActiveKey}
        activeKey={activeAccordion}
        expandIconPosition="end"
      >
        <Flex direction="row">
          <Label className="label" text="Subcategories:" />
          <span className="heading sub-heading">drag and drop to re-order subcategories</span>
        </Flex>
        {children}
      </Accordion>
    </MealOrderCardWrapper>
  );
};

export default MealOrderCard;
