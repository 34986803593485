import * as Yup from 'yup';

import { GetAccountManagers } from '@services/quoteServices';
import { VenueFormValuesTypeWithDraftStatus } from '@pages/QuoteManagementPage/types';
import services from '@services/index';

const phoneRegExp = /^[0-9]{10}$/;

export enum LANDMARKS {
  FOOD = '1',
  ALCOHOL_AND_SOFT_BAR = '2',
  LABOUR = '3',
  NET_TOTAL = '4'
}

export const newAdditionalContact = {
  localId: Math.random(),
  contact_email: '',
  contact_name: '',
  contact_phone_number: '',
  country_code: '+1',
  country_iso_code: 'CAN',
  formated_contact_phone_number: '',
  extension: ''
};

export const FORM_INITIAL_VALUES: VenueFormValuesTypeWithDraftStatus = {
  id: undefined,
  venue_id: undefined,
  venue_name: undefined,
  venue_sub_section: undefined,
  venue_address: undefined,
  latitude: undefined,
  longitude: undefined,
  start_time: undefined,
  end_time: undefined,
  capacity: undefined,
  fd_account_manager: undefined,
  status: 0,
  power_available: 1,
  running_water: 1,
  kitchen_availability: null,
  cooking_equipments: undefined,
  special_equipments: undefined,
  rental_equipments: undefined,
  alcohol_bar_venue_in_charge: 1,
  alcohol_bar_setup_requirements: undefined,
  setup_duty_requirements: undefined,
  closing_duty_requirements: undefined,
  loading_dock: null,
  parking: null,
  loading_parking_additional_information: undefined,
  coat_check: null,
  coat_check_additional_information: undefined,
  staff_additional_restriction: undefined,
  landmark_fees: undefined,
  on_net_total: false,
  food_fees: undefined,
  labour_fees: undefined,
  alcohol_soft_bar_fees: undefined,
  additional_notes: undefined,
  additional_contacts: [
    {
      contact_name: undefined,
      country_code: '+1',
      country_iso_code: 'CAN'
    }
  ],
  landmark_fee_checkboxes: [],
  is_customize_landmark_fees: false
};

export const validationSchema = Yup.object({
  venue_name: Yup.string().nullable().required('Please enter valid venue name'),
  venue_sub_section: Yup.string().nullable(),
  venue_address: Yup.string().nullable().required('Please enter valid venue address'),
  capacity: Yup.string().nullable(),
  fd_account_manager: Yup.number().nullable(),
  start_time: Yup.string().nullable(),
  end_time: Yup.string().nullable(),
  kids: Yup.number().nullable(),
  power_available: Yup.number().nullable(),
  running_water: Yup.number().nullable(),
  kitchen_availability: Yup.number().nullable(),
  cooking_equipments: Yup.string().nullable(),
  special_equipments: Yup.string().nullable(),
  rental_equipments: Yup.string().nullable(),
  alcohol_bar_venue_in_charge: Yup.number().nullable(),
  alcohol_bar_setup_requirements: Yup.string().nullable(),
  setup_duty_requirements: Yup.string().nullable(),
  closing_duty_requirements: Yup.string().nullable(),
  loading_dock: Yup.number().nullable(),
  parking: Yup.number().nullable(),
  loading_parking_additional_information: Yup.string()
    .max(2000, 'Loading Dock Addition Info cannot be more than 2000 characters.')
    .nullable(),
  parking_additional_information: Yup.string()
    .max(1200, 'Staff Entrance And Parking cannot be more than 1200 characters.')
    .nullable(),
  coat_check: Yup.number().nullable(),
  coat_check_additional_information: Yup.string().nullable(),
  staff_additional_restriction: Yup.string().nullable(),
  landmark_fees: Yup.string().nullable(),
  landmark_fee_checkboxes: Yup.array().of(Yup.number().nullable()).nullable(),
  on_net_total: Yup.boolean().nullable(),
  is_customize_landmark_fees: Yup.boolean().nullable(),
  food_fees: Yup.string().nullable(),
  alcohol_soft_bar_fees: Yup.string().nullable(),
  labour_fees: Yup.string().nullable(),
  additional_notes: Yup.string().nullable(),
  additional_contacts: Yup.array().of(
    Yup.object({
      contact_name: Yup.string().nullable().required('Please enter contact name'),
      contact_email: Yup.string().nullable(),
      contact_phone_number: Yup.string()
        .matches(phoneRegExp, 'The venue contact phone number field must be 10 digits.')
        .nullable()
    })
  )
});

export const getVenueDropdownData = () => {
  return Promise.all([getAccountManagersList()]).then((values: any) => values);
};

const getAccountManagersList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = GetAccountManagers() as any;
      resolve(res);
    } catch (err: any) {
      reject({ error: err && err?.error ? err?.error?.error : 'Something went wrong!' });
    }
  });
};

export const getPayload = (data: any) => {
  return {
    id: 1922,
    unique_invoice_string: 'a99ff967-d5cc-4a9e-a8be-b2672fc051b6',
    quotation_no: '005464',
    from_event: 0,
    status: 0,
    venue_details: data
  };
};

export const GetEventData = async (
  tab: string,
  quote_id: string | number,
  event_id: string | number,
  isEvent?: boolean
) => {
  const url = isEvent
    ? `/events/${tab}?quote_id=${quote_id}&event_id=${event_id}`
    : `/quotes/${quote_id}/${tab}`;
  try {
    const res: any = await services.get(url);
    if (res?.data?.success) return { data: res?.data.data, success: true };
  } catch (err: any) {
    return {
      error: err?.error?.error ? err?.error?.error : 'Something went wrong!',
      success: false
    };
  }
};
