import { Input } from '@atom/Input';
import { SearchBar } from '@atom/Search';
import React, { ChangeEvent, FC } from 'react';

interface TableHeaderProps {
  columnTitle: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (a: ChangeEvent) => void;
}

const TableHeader: FC<TableHeaderProps> = ({ columnTitle, name, value, placeholder, onChange }) => {
  return (
    <div style={{ width: '200px' }}>
      <p>{columnTitle}</p>
      <SearchBar
        id={name}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default TableHeader;
