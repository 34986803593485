import DataTable from '@organism/DataTable/DataTable';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getQuoteList, getQuoteListColumns } from './helper';
import { localStorageUtil } from '@utils/localstorageUtil';

function QuoteList() {
  const navigate = useNavigate();
  const [quoteList, setQuoteList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const location = localStorageUtil.getValue('location');
  const [filterData, setFilterData] = useState<any>({
    customer_name: '',
    event_name: '',
    quote_number: '',
    status: '',
    total_amount: ''
  });

  useEffect(() => {
    setQuoteListData();
  }, []);

  const setQuoteListData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setIsLoading(true);
      const res = await getQuoteList(newFilters.page, filters);
      setIsLoading(false);
      if (!res.error) {
        setQuoteList(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
    }, 300),
    []
  );
  const onAction = (row: any) => {
    localStorageUtil.setValue('location', {
      state: { prevPath: location?.pathname, path: 'select' }
    });
    navigate(`/events/find-quote/create/${row.id}/quote`, {
      state: { prevPath: location?.pathname, path: 'select' }
    });
  };

  const onFilterChangeHandler = (e: any, fieldName?: string) => {
    let name = '';
    let value = '';
    if (!fieldName) {
      name = e?.target?.name;
      value = e?.target?.value;
    } else {
      name = fieldName;
      value = fieldName === 'total_amount' ? e : e.target.value;
    }
    const updatedFilters = { ...filterData, [name]: value };
    setFilterData(updatedFilters);
    setQuoteListData(updatedFilters);
  };
  const columns = getQuoteListColumns(filterData, onFilterChangeHandler, onAction);
  return (
    <DataTable
      className="fixTD fixTD-mobile"
      loading={isLoading}
      showLoadMore={!!nextPageUrl}
      dataSource={quoteList}
      columns={columns}
      pagination={{
        pageSize: pageNo,
        total: totalCount,
        current: filterData?.page,
        onChange: (page: any) => {
          const newFilters = { ...filterData, page: page };
          setFilterData(newFilters);
          setQuoteListData(newFilters);
        }
      }}
      onFetch={() => {}}
      renderEmpty={() => <div>No Quotes Found.</div>}
      scrollConfig={{
        y: quoteList?.length ? 'calc(100vh - 350px)' : 'auto'
      }}
    />
  );
}

export default QuoteList;
