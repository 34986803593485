import { useNavigate } from 'react-router';

import HorizontalTab from '@molecules/HorizontalTabs';

import { QuoteManagementTabsProps } from '../types';
import { getQuoteManagementTabs } from './helper';
import { HorizontalTabWrapper } from '@pages/EventManagementPage/style';

function QuoteManagementTabs(props: QuoteManagementTabsProps) {
  const { activePanel, startDate, endDate, setCallApi, callApi } = props;
  const navigate = useNavigate();
  const menuManagementTabs = getQuoteManagementTabs(
    activePanel,
    startDate,
    endDate,
    callApi,
    setCallApi
  );

  return (
    <HorizontalTabWrapper>
      <HorizontalTab
        value={menuManagementTabs}
        activeKey={activePanel}
        onChange={(tab: any) => {
          let newurl = `/quote/tab/${tab}`;
          navigate(newurl);
        }}
      />
    </HorizontalTabWrapper>
  );
}

export default QuoteManagementTabs;
