import React, { useEffect, useState } from 'react';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { Col, Divider, Row } from 'antd';
import { useParams } from 'react-router';
import { getWorkOrderDetails, WORK_ORDER_MANAGEMENT_TABS } from '../helper';
import { IPastRecordsDataType } from '../type';
import { H2Typography } from '@constant/typography/Typography';
import FormDataView from '@atom/FormDataView';
import moment from 'moment';
import StaffTable from './StaffTable';
import CenterSpiner from '@atom/CenterSpiner';
import { formatPhoneNumber } from '@utils/format';
import { DataTableWrapper } from '../style';

const PastRecords = () => {
  const params = useParams();
  const { event_id, event_staffing_id, employee_id, tab } = params;
  const [pastRecordsData, setPastRecordsData] = useState<IPastRecordsDataType | null>(null);
  const [isLoadingPastRecords, setIsLoadingPastRecords] = useState(true);

  const getSupervisorContact = () => {
    if (!pastRecordsData?.supervisor?.phone_number) return null;
    const countryCode = pastRecordsData?.supervisor?.country_code?.includes('+')
      ? pastRecordsData?.supervisor?.country_code
      : pastRecordsData?.supervisor?.country_code
      ? `+${pastRecordsData?.supervisor?.country_code}`
      : '';
    const phone = pastRecordsData?.supervisor?.phone_number;
    const extension = pastRecordsData?.supervisor?.extension;
    return `${countryCode || ''} ${formatPhoneNumber(phone) || ''} ${
      extension ? `x${extension}` : ''
    }
`;
  };

  useEffect(() => {
    if (event_id && event_staffing_id && employee_id)
      getPastRecordsData(event_id, event_staffing_id, employee_id);
  }, [event_id, event_staffing_id, employee_id]);

  const getPastRecordsData = async (
    event_id: number | string,
    event_staffing_id: number | string,
    employee_id: number | string
  ) => {
    setIsLoadingPastRecords(true);
    try {
      const res = await getWorkOrderDetails(event_id, event_staffing_id, employee_id);
      if (res?.success) {
        setPastRecordsData({
          ...res?.data,
          add_staffs:
            typeof res?.data?.add_staffs === 'object'
              ? Object.keys(res?.data?.add_staffs).map(function (k) {
                  return res?.data?.add_staffs[k];
                })
              : res?.data?.add_staffs
        });
      }
    } catch (error) {}
    setIsLoadingPastRecords(false);
  };

  if (isLoadingPastRecords) return <CenterSpiner />;

  return (
    <PageLayout title={'Work Order Management'}>
      <PageTitle
        text="WORK ORDER MANAGEMENT"
        bordered={true}
        isBack={true}
        path={`/work-orders/tab/${
          tab === 'upcoming' ? WORK_ORDER_MANAGEMENT_TABS.UPCOMING : WORK_ORDER_MANAGEMENT_TABS.PAST
        }`}
      ></PageTitle>
      <H2Typography style={{ marginTop: '15px' }}>Important Information</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView
            heading={'EVENT DATE'}
            value={moment(pastRecordsData?.important_info?.event_date).format('ddd, MMMM DD')}
          />
        </Col>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView
            heading={'TIME SCHEDULED'}
            value={pastRecordsData?.important_info?.time_scheduled}
          />
        </Col>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView
            heading={'EVENT ADDRESS'}
            value={pastRecordsData?.important_info?.event_address}
          />
        </Col>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView heading={'VENUE'} value={pastRecordsData?.important_info?.venue} />
        </Col>
      </Row>
      <H2Typography style={{ marginTop: '15px' }}>
        Supervisor / Co-ordinator Information
      </H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView heading={'SUPERVISOR NAME'} value={pastRecordsData?.supervisor?.employee} />
        </Col>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView heading={'PHONE NUMBER'} value={getSupervisorContact()} />
        </Col>
        <Col span={6} md={8} sm={12} xs={24}>
          <FormDataView heading={'EMAIL'} value={pastRecordsData?.supervisor?.email} />
        </Col>
      </Row>

      <H2Typography style={{ marginTop: '15px' }}>Event Details</H2Typography>
      <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px' }} />
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={6} md={8} sm={24} xs={24}>
          <FormDataView heading={'EVENT NAME'} value={pastRecordsData?.event_details?.event_name} />
        </Col>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView
            heading={'EVENT DATE'}
            value={moment(pastRecordsData?.event_details?.event_date).format('ddd, MMMM DD')}
          />
        </Col>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView
            heading={'NUMBER OF GUESTS'}
            value={pastRecordsData?.event_details?.no_of_guest}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView heading={'START TIME'} value={pastRecordsData?.event_details?.start_time} />
        </Col>
        <Col span={6} md={8} sm={12} xs={12}>
          <FormDataView heading={'END TIME'} value={pastRecordsData?.event_details?.end_time} />
        </Col>
        <Col span={6} md={8} sm={24} xs={24}>
          <FormDataView
            heading={'CONTACT EMAIL'}
            value={pastRecordsData?.event_details?.contact_email}
          />
        </Col>
      </Row>
      <DataTableWrapper>
        <H2Typography style={{ marginTop: '15px' }}>Staff Arrival Times</H2Typography>
        <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px 30px 0px' }} />
        <StaffTable tableData={pastRecordsData?.staffs || []} />

        <H2Typography style={{ marginTop: '15px' }}>Additional Staff</H2Typography>
        <Divider style={{ borderColor: '#E0E0E0', margin: '15px 0px 30px 0px' }} />
        <StaffTable tableData={pastRecordsData?.add_staffs || []} />
      </DataTableWrapper>
    </PageLayout>
  );
};

export default PastRecords;
