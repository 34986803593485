import { FieldArray, FormikProvider, getIn } from 'formik';
import { IAddEditInfoProps, IAdditionalContacts } from '../types';
import { newAdditionalContact } from './helper';
import { showToast } from '@utils/index';
import { useNavigate, useParams } from 'react-router';

import AdditionalContacts from './AdditionalContacts';
import AdditionalNotesSection from './Sections/AdditionalNotes';
import AlcoholBarSection from './Sections/AlcoholBar';
import { Button } from '@atom/Buttons';
import ClosingDutiesSection from './Sections/ClosingDuties';
import DockParkingSection from './Sections/DockParking';
import { Flex } from '@atom/Flex';
import { H1Typography } from '@constant/typography/Typography';
import KitchenDetailsSection from './Sections/KitchenDetails';
import LandmarkFeeSection from './Sections/LandmarkFee';
import React from 'react';
import ScrollToFieldError from '@utils/formikFocus';
import ServiceInfoSection from './Sections/ServiceInfo';
import SetupDutiesSection from './Sections/SetupDuties';
import StaffDetailsSection from './Sections/StaffDetails';
import { VENUE_MANAGEMENT_TABS } from '../ListViewPages/helper';
import VenueDetailsSection from './Sections/VenueDetails';

const AddEditInfo: React.FC<IAddEditInfoProps> = ({
  formik,
  handleSaveDraft,
  isCreateLoading,
  isDraftLoading,
  setTabType,
  newVenueInQuoteOrEvent,
  onCancelInQuoteOrEvent
}) => {
  const { handleSubmit, setFieldValue, setFieldTouched, values, touched, errors } = formik;
  const navigate = useNavigate();
  const { id } = useParams();

  const removeAdditionalContactMethod = async (item: any, index: number, arrayHelper: any) => {
    if (item?.id) {
      showToast({ message: 'Contact Removed Successfully', description: '' });
    }
    arrayHelper.remove(index);
  };
  return (
    <>
      {!id && <H1Typography style={{ margin: '35px 0px' }}>ADD NEW VENUE</H1Typography>}
      <ScrollToFieldError formik={formik} />
      <VenueDetailsSection formik={formik} />
      <FormikProvider value={formik}>
        <FieldArray
          name={'additional_contacts'}
          render={(arrayHelper: any) => {
            return values?.additional_contacts?.map((item: IAdditionalContacts, index: number) => {
              return (
                <AdditionalContacts
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  arrayHelper={arrayHelper}
                  index={index}
                  item={item}
                  key={`${item?.id || index}`}
                  showRemove={(values?.additional_contacts?.length || 0) > 1}
                  showAdd={
                    !!values?.additional_contacts &&
                    index === values?.additional_contacts?.length - 1
                  }
                  onAddContact={() => {
                    setFieldValue(
                      'additional_contacts',
                      values?.additional_contacts
                        ? [...values?.additional_contacts, newAdditionalContact]
                        : [newAdditionalContact]
                    );
                  }}
                  onRemove={() => {
                    removeAdditionalContactMethod(item, index, arrayHelper);
                  }}
                />
              );
            });
          }}
        />
      </FormikProvider>
      <KitchenDetailsSection
        powerError={
          getIn(touched, `venue_kitchen_detail.power_available`) &&
          getIn(errors, `venue_kitchen_detail.power_available`)
        }
        powerValue={getIn(values, `venue_kitchen_detail.power_available`) ?? ''}
        runningWaterError={
          getIn(touched, `venue_kitchen_detail.running_water`) &&
          getIn(errors, `venue_kitchen_detail.running_water`)
        }
        runningWaterValue={getIn(values, `venue_kitchen_detail.running_water`) ?? ''}
        availabilityError={
          getIn(touched, `venue_kitchen_detail.kitchen_availability`) &&
          getIn(errors, `venue_kitchen_detail.kitchen_availability`)
        }
        availabilityValue={getIn(values, `venue_kitchen_detail.kitchen_availability`) ?? ''}
        cookingError={
          getIn(touched, `venue_kitchen_detail.cooking_equipments`) &&
          getIn(errors, `venue_kitchen_detail.cooking_equipments`)
        }
        cookingValue={getIn(values, `venue_kitchen_detail.cooking_equipments`) ?? ''}
        rentalError={
          getIn(touched, `venue_kitchen_detail.rental_equipments`) &&
          getIn(errors, `venue_kitchen_detail.rental_equipments`)
        }
        rentalValue={getIn(values, `venue_kitchen_detail.rental_equipments`) ?? ''}
        specialError={
          getIn(touched, `venue_kitchen_detail.special_equipments`) &&
          getIn(errors, `venue_kitchen_detail.special_equipments`)
        }
        specialValue={getIn(values, `venue_kitchen_detail.special_equipments`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />
      <AlcoholBarSection
        venueInChargeError={
          getIn(touched, `venue_detail.alcohol_bar_venue_in_charge`) &&
          getIn(errors, `venue_detail.alcohol_bar_venue_in_charge`)
        }
        venueInChargeValue={getIn(values, `venue_detail.alcohol_bar_venue_in_charge`) ?? ''}
        barServiceError={
          getIn(touched, `venue_detail.alcohol_bar_setup_requirements`) &&
          getIn(errors, `venue_detail.alcohol_bar_setup_requirements`)
        }
        barServiceValue={getIn(values, `venue_detail.alcohol_bar_setup_requirements`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />
      <SetupDutiesSection
        errors={
          getIn(touched, `venue_detail.setup_duty_requirements`) &&
          getIn(errors, `venue_detail.setup_duty_requirements`)
        }
        value={getIn(values, `venue_detail.setup_duty_requirements`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />
      <ClosingDutiesSection
        errors={
          getIn(touched, `venue_detail.closing_duty_requirements`) &&
          getIn(errors, `venue_detail.closing_duty_requirements`)
        }
        value={getIn(values, `venue_detail.closing_duty_requirements`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />
      <DockParkingSection
        loadingDockError={
          getIn(touched, `venue_detail.loading_dock`) && getIn(errors, `venue_detail.loading_dock`)
        }
        loadingDockValue={getIn(values, `venue_detail.loading_dock`) ?? ''}
        parkingError={
          getIn(touched, `venue_detail.parking`) && getIn(errors, `venue_detail.parking`)
        }
        parkingValue={getIn(values, `venue_detail.parking`) ?? ''}
        infoError={
          getIn(touched, `venue_detail.loading_parking_additional_information`) &&
          getIn(errors, `venue_detail.loading_parking_additional_information`)
        }
        infoValue={getIn(values, `venue_detail.loading_parking_additional_information`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />
      <ServiceInfoSection
        // coatCheckError={
        //   getIn(touched, `venue_detail.coat_check`) && getIn(errors, `venue_detail.coat_check`)
        // }
        coatCheckValue={getIn(values, `venue_detail.coat_check`) ?? ''}
        addInfoError={
          getIn(touched, `venue_detail.coat_check_additional_information`) &&
          getIn(errors, `venue_detail.coat_check_additional_information`)
        }
        addInfoValue={getIn(values, `venue_detail.coat_check_additional_information`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />
      <StaffDetailsSection
        errors={
          getIn(touched, `venue_detail.staff_additional_restriction`) &&
          getIn(errors, `venue_detail.staff_additional_restriction`)
        }
        value={getIn(values, `venue_detail.staff_additional_restriction`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />
      <LandmarkFeeSection
        newVenueInQuoteOrEvent={newVenueInQuoteOrEvent}
        foodFeesError={
          getIn(touched, `venue_detail.food_fees`) && getIn(errors, `venue_detail.food_fees`)
        }
        foodFeesValue={getIn(values, `venue_detail.food_fees`) ?? ''}
        alcoholFeesError={
          getIn(touched, `venue_detail.alcohol_soft_bar_fees`) &&
          getIn(errors, `venue_detail.alcohol_soft_bar_fees`)
        }
        alcoholFeesValue={getIn(values, `venue_detail.alcohol_soft_bar_fees`) ?? ''}
        labourFeesError={
          getIn(touched, `venue_detail.labour_fees`) && getIn(errors, `venue_detail.labour_fees`)
        }
        labourFeesValue={getIn(values, `venue_detail.labour_fees`) ?? ''}
        landmarkFeesError={
          getIn(touched, `venue_detail.landmark_fees`) &&
          getIn(errors, `venue_detail.landmark_fees`)
        }
        landmarkFeesValue={getIn(values, `venue_detail.landmark_fees`) ?? ''}
        netTotalError={
          getIn(touched, `venue_detail.on_net_total`) && getIn(errors, `venue_detail.on_net_total`)
        }
        netTotalValue={getIn(values, `venue_detail.on_net_total`) ?? ''}
        customizeFeesValue={getIn(values, `venue_detail.is_customize_landmark_fees`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />
      <AdditionalNotesSection
        errors={
          getIn(touched, `venue_detail.additional_notes`) &&
          getIn(errors, `venue_detail.additional_notes`)
        }
        value={getIn(values, `venue_detail.additional_notes`) ?? ''}
        id={values?.id}
        setFieldValue={setFieldValue}
        handleBlur={formik.handleBlur}
      />

      <Flex className="footerButton fix-mobile">
        <Button
          variant="secondary"
          text="Cancel"
          onClick={() =>
            newVenueInQuoteOrEvent
              ? onCancelInQuoteOrEvent && onCancelInQuoteOrEvent()
              : navigate(`/venues/tab/${VENUE_MANAGEMENT_TABS.ALL}`)
          }
        />

        {!newVenueInQuoteOrEvent && (
          <Button
            variant="secondary"
            text="Save as Draft"
            onClick={handleSaveDraft}
            isLoading={isDraftLoading}
          />
        )}

        <Button
          type="submit"
          variant="primary"
          size="large"
          text={newVenueInQuoteOrEvent ? 'Save' : 'Next'}
          onClick={handleSubmit}
          isLoading={isCreateLoading}
        />
      </Flex>
    </>
  );
};

export default AddEditInfo;
