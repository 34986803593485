import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Button } from '@atom/Buttons';
import { MENU_MANAGEMENT_TABS } from '../helper';
import ManuManagementTabs from './ManuManagementTabs';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { useSelector } from 'react-redux';
import { updateRawData } from '@utils/index';

function ListView() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [activePanel, setActivePanel] = useState<any>();

  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  useEffect(() => {
    return () => {
      updateRawData({
        subCategories: null,
        productTypes: null,
        categories: null
      });
    };
  }, []);

  useEffect(() => {
    const paths = window.location.pathname?.split('/') || [];
    if (paths?.length && (paths.pop() === 'menu' || paths.pop() === 'menu')) {
      navigate(`/menu/tab/${MENU_MANAGEMENT_TABS.ACTIVE}`);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setActivePanel(tab);
  }, [tab]);

  return (
    <PageLayout>
      <PageTitle text="PRODUCT LIST">
        {isAdmin && (
          <Button
            style={{ padding: '14px 15px', fontSize: '15px' }}
            text={activePanel === MENU_MANAGEMENT_TABS.BUNDLES ? 'Add Bundle' : 'Add Product'}
            onClick={() =>
              navigate(
                activePanel === MENU_MANAGEMENT_TABS.BUNDLES
                  ? '/menu/add-bundle'
                  : '/menu/add-product'
              )
            }
          />
        )}
      </PageTitle>
      <ManuManagementTabs activePanel={activePanel} />
    </PageLayout>
  );
}

export default ListView;
