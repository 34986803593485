import PageLayout from '@layout/PageLayout';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import EventDetailsHeader from './EventDetailsHeader';
import HorizontalTab from '@molecules/HorizontalTabs';
import { Row, Col } from 'antd';
import {
  EVENT_DETAILS_TABS,
  getEventCodeList,
  getEventDetails,
  IEventCodeListType
} from './helper';
import EventInfo from './EventInfo';
import EventDocument from './EventDocument';
import EventTask from './EventTask';
import CenterSpiner from '@atom/CenterSpiner';
import { EventDetailsWrapper, EventHorizontalTabWrapper } from '../style';

function EventDetails() {
  const navigate = useNavigate();
  const { quoteId, eventId } = useParams();

  const [eventData, setEventData] = useState<any>({});
  const [eventCodeList, setEventCodeList] = useState<IEventCodeListType[]>([]);
  const [currentEventCode, setCurrentEventCode] = useState<any>(null);
  const [isEventDataLoading, setIsEventDataLoading] = useState<boolean>(false);
  const [isEventCodeLoading, setIsEventCodeLoading] = useState<boolean>(false);
  const [tabType, setTabType] = useState(EVENT_DETAILS_TABS.INFO);

  const getEventDetailMethod = async (event_id?: string) => {
    if (!event_id) return;
    setIsEventDataLoading(true);
    try {
      const res = await getEventDetails(event_id);
      if (res?.success) {
        setEventData(res?.data);
        setIsEventDataLoading(false);
        setIsEventCodeLoading(false);
      }
    } catch (err) {
      setIsEventDataLoading(false);
    }
  };

  const getEventCodeListMethod = async (event_id?: string) => {
    if (!event_id) return;
    setIsEventCodeLoading(true);
    try {
      const res = await getEventCodeList(event_id);
      if (res?.success) {
        setEventCodeList(res?.data);
        setIsEventCodeLoading(false);
        if (!res?.data?.length) {
          setCurrentEventCode(null);
        }
      }
    } catch (err) {
      setIsEventCodeLoading(false);
    }
  };

  // for get next and prev  event code
  // const getNextPrevCodeMethod = async (flag: 1 | 2) => {
  //   if (!eventId) return;
  //   setIsEventCodeLoading(true);
  //   try {
  //     const res = await getNextPrev(Number(eventId), flag);
  //     if (res?.success) {
  //       navigate(`/events/${res?.data?.quote_id}/${res?.data?.event_id}/view`);
  //     }
  //   } catch (err) {
  //     setIsEventCodeLoading(false);
  //   }
  // };

  useEffect(() => {
    if (eventId) {
      getEventDetailMethod(eventId);
      getEventCodeListMethod(eventId);
      setCurrentEventCode(parseInt(eventId));
    }
  }, [eventId]);

  const eventCodeChange = (codeData: any) => {
    setCurrentEventCode(codeData?.id);
    navigate(`/events/${codeData?.id}/view`);
  };

  const getEventDetailsTabs = (eventData: any, getEventDetailMethod: (eventId: string) => void) => {
    const tabValues = [
      {
        tab: 'INFO',
        content: <EventInfo eventData={eventData} getEventDetailMethod={getEventDetailMethod} />,
        key: EVENT_DETAILS_TABS.INFO
      },
      {
        tab: 'DOCUMENTS',
        content: <EventDocument />,
        key: EVENT_DETAILS_TABS.DOCUMENTS
      },
      {
        tab: 'TASKS',
        content: <EventTask eventData={eventData} />,
        key: EVENT_DETAILS_TABS.TASKS
      }
    ];
    return tabValues;
  };

  const eventDetailsTabs = getEventDetailsTabs(eventData, getEventDetailMethod);

  if (isEventDataLoading || isEventCodeLoading) return <CenterSpiner />;

  return (
    <PageLayout>
      <EventDetailsHeader
        eventData={eventData}
        setEventData={setEventData}
        eventCodeChange={eventCodeChange}
        eventCodeList={eventCodeList}
        currentEventCode={currentEventCode}
        getEventDetailMethod={getEventDetailMethod}
      />
      <EventDetailsWrapper>
        <Row gutter={24}>
          <Col md={24}>
            <EventHorizontalTabWrapper>
              <HorizontalTab
                value={eventDetailsTabs}
                activeKey={tabType}
                onChange={(tab: any) => {
                  // let newurl = `/events/${tab}`;
                  // navigate(newurl);
                  setTabType(tab);
                }}
              />
            </EventHorizontalTabWrapper>

            {/* for view next and prev event */}
            {/* <Flex
              className="position-fix"
              justifyContent={'end'}
              gap={16}
              style={{ position: 'absolute', right: '0', top: '0' }}
            >
              <Button
                text="Prev"
                isDisable={!eventData?.prev_event}
                onClick={() => getNextPrevCodeMethod(1)}
              />
              <Button
                text="Next"
                isDisable={!eventData?.next_event}
                onClick={() => getNextPrevCodeMethod(2)}
              />
            </Flex> */}
          </Col>
        </Row>
      </EventDetailsWrapper>
    </PageLayout>
  );
}

export default EventDetails;
