import services from '@services/index';
import * as yup from 'yup';
import { ResponseType } from '../helper';

// types
export interface TaskListType {
  id: number | null;
  task: string;
}

export interface EditTaskListType {
  event_tasks: TaskListType[];
}

// api calls
export const getEventTasks = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings/tasks`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const removeTask = async (id: number): Promise<ResponseType> => {
  try {
    const res = (await services.delete(`/settings/tasks/${id}`)) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateTaskList = async (body: EditTaskListType): Promise<ResponseType> => {
  try {
    const res = (await services.put(`/settings/tasks`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

// yup validations
export const taskValidationSchema = yup.object({
  event_tasks: yup.array().of(
    yup.object({
      task: yup.string().required('Task is required.').nullable()
    })
  )
});
