import { Row } from 'antd';
import styled from 'styled-components';

export const QuoteRecordWrapper = styled.div`
  @media (max-width: 768px) {
    .ant-table {
      min-height: 100px !important;
    }
  }
`;

export const CustomerDetailWrapper = styled(Row)`
  @media (max-width: 767px) {
    flex-flow: row nowrap;
    overflow: scroll !important;
    padding-bottom: 5px !important;
    display: flex !important;
    gap: 20px !important;
    .customer-col {
      flex: none !important;
      max-width: 100% !important;
    }
  }
`;
