import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import TimePickerComponent from '@atom/TimePicker';
import { ITimeType, MyScheduleType } from '@pages/WorkOrderManagementPage/type';
import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import moment, { Moment } from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import { LabelWrapper } from '../../style';
import _ from 'lodash';

interface IMyScheduleFormProps {
  formik: FormikProps<MyScheduleType>;
  isSubmitLoading: boolean;
  onCancel: () => void;
}

const MyScheduleForm: React.FC<IMyScheduleFormProps> = ({ formik, isSubmitLoading, onCancel }) => {
  const { values, setFieldValue, setTouched, touched, errors } = formik;
  const touchedVar: any = { ...touched };
  const errorVar: any = { ...errors };

  const getErrors = (index: number, type: 'start_time' | 'end_time') => {
    return touchedVar?.schedules?.[index]?.[`${type}`] && errorVar?.schedules?.[index]?.[`${type}`];
  };

  return (
    <div style={{ paddingTop: '45px' }}>
      <Row className="hide-mobile-device" gutter={16}>
        <Col md={6} xs={8}></Col>
        <Col md={6} xs={8}>
          <Label
            style={{ fontSize: '16px', lineHeight: '21px', fontWeight: 'normal' }}
            text={'Start Time'}
          />
        </Col>
        <Col md={6} xs={8}>
          <Label
            style={{ fontSize: '16px', lineHeight: '21px', fontWeight: 'normal' }}
            text={'End Time'}
          />
        </Col>
      </Row>
      {!!values?.schedules &&
        Array.isArray(values?.schedules) &&
        values?.schedules?.map((item: ITimeType, index: number) => (
          <LabelWrapper>
            <Row className="hide-full-device" gutter={16}>
              <Col md={6} xs={8}></Col>
              <Col md={6} xs={8}>
                <Label
                  style={{ fontSize: '16px', lineHeight: '21px', fontWeight: 'normal' }}
                  text={'Start Time'}
                />
              </Col>
              <Col md={6} xs={8}>
                <Label
                  style={{ fontSize: '16px', lineHeight: '21px', fontWeight: 'normal' }}
                  text={'End Time'}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={8} md={6} lg={6} className="time-label-style">
                <Label style={{ fontWeight: 'normal' }} text={item?.day} />
              </Col>
              <Col xs={8} md={6} lg={6}>
                <TimePickerComponent
                  id={`schedules[${index}].start_time`}
                  name={`schedules[${index}].start_time`}
                  use12Hours={true}
                  format="h:mm A"
                  placeholder={'HH:MM'}
                  onChange={(value: string) => {
                    const touchedVar: any = { ...touched };
                    _.set(touchedVar, `schedules[${index}].start_time`, true);
                    setTouched({ ...touchedVar });
                    setFieldValue(`schedules[${index}].start_time`, value);
                  }}
                  onSelect={(e: Moment) => {
                    setFieldValue(`schedules[${index}].start_time`, e.format('hh:mm A'));
                  }}
                  value={
                    values?.schedules?.[index]?.start_time &&
                    moment(values?.schedules?.[index]?.start_time, 'hh:mm A').format('HH:mm')
                  }
                  error={getErrors(index, 'start_time')}
                />
              </Col>
              <Col xs={8} md={6} lg={6}>
                <TimePickerComponent
                  id={`schedules[${index}].end_time`}
                  name={`schedules[${index}].end_time`}
                  use12Hours={true}
                  format="h:mm A"
                  placeholder={'HH:MM'}
                  onChange={(value: string) => {
                    const touchedVar: any = { ...touched };
                    _.set(touchedVar, `schedules[${index}].end_time`, true);
                    setTouched({ ...touchedVar });
                    setFieldValue(`schedules[${index}].end_time`, value);
                  }}
                  onSelect={(e: Moment) => {
                    setFieldValue(`schedules[${index}].end_time`, e.format('hh:mm A'));
                  }}
                  value={
                    values?.schedules?.[index]?.end_time &&
                    moment(values?.schedules?.[index]?.end_time, 'hh:mm A').format('HH:mm')
                  }
                  error={getErrors(index, 'end_time')}
                />
              </Col>
            </Row>
          </LabelWrapper>
        ))}
      <div style={{ justifyContent: 'end', display: 'flex', margin: '20px 0px 40px 0px' }}>
        <Flex gap={16} className="fix-mobile">
          <Button variant="secondary" text="Cancel" onClick={onCancel} />
          <Button
            variant="primary"
            size="large"
            text="Save"
            onClick={formik.handleSubmit}
            isLoading={isSubmitLoading}
          />
        </Flex>
      </div>
    </div>
  );
};

export default MyScheduleForm;
