import { Col, Divider, Row, Select } from 'antd';
import {
  FORM_INITIAL_VALUES,
  IVendorListItemType,
  getMappedData,
  getVendorsList,
  vendorPositionItem,
  vendorsQuoteFormSchema,
  vendorsEventFormSchema
} from './helper';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { GetTabData, PutTabData, getTabData } from '@services/quoteServices';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AddEditQuoteFooter from '../AddEditQuoteFooter';
import { Button } from '@atom/Buttons';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import CenterSpiner from '@atom/CenterSpiner';
import DatePickerComponent from '@atom/DatePicker';
import EventBasicDetails from '../EventBasicDetails';
import { H2Typography } from '@constant/typography/Typography';
import { Label } from '@atom/FormLable';
import { getTabBaseUrl, QuoteBasicDetailsObject } from '../helper';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import SelectInput from '@atom/Select';
import VendorEstimateItem from './VendorEstmateItem';
import { showErrorToast, showToast } from '@utils/index';
import { useSelector } from 'react-redux';
import ModalComponent from '@organism/Modal/Modal';
import moment from 'moment';
import ConfirmModal from '@organism/ConfimationModal';
import { getEventDate, getSelectOptions } from '@utils/helper';
import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '@pages/EventManagementPage/EventWithExistingQuote/tabHelpers';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';

interface VendorsFormProps {
  tabKey: string;
  quoteBasicDetails?: QuoteBasicDetailsObject;
  setQuoteBasicDetails?: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  isEventsTab?: boolean;
  submitForm?: (tabKey: string, values: any, actions: any, method?: string) => void;
  withQuote?: boolean;
}

const VendorsForm = (props: VendorsFormProps) => {
  const {
    tabKey,
    quoteBasicDetails = {},
    setQuoteBasicDetails = () => {},
    isEventsTab = false,
    submitForm,
    withQuote
  } = props;
  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const params = useParams();
  const { random_unique_invoice_string } = useSelector((state: any) => state.rawData);

  const { eventId, quoteId, formType, form } = params;

  const location = useLocation();
  const navigate = useNavigate();

  const [draftSubmitting, setDraftSubmitting] = useState(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [vendorsList, setVendorsList] = useState<Array<any>>([]);
  const [formikData, setFormikData] = useState(FORM_INITIAL_VALUES);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const formik = useFormik({
    validationSchema: isEventsTab ? vendorsEventFormSchema : vendorsQuoteFormSchema,
    onSubmit: (values: any, actions: any) => {
      submitHandler({ ...values }, actions);
    },
    initialValues: formikData
  });
  const { values, touched, errors, setFieldValue, setValues, handleSubmit } = formik;

  const VendorEventDate = isEventsTab ? details?.event_date : values?.event_dates?.event_date?.[0];

  const VendorArray = isEventsTab ? values?.vendors : values?.event_dates?.vendors;
  const updateInitialData = async () => {
    setPageLoading(true);
    try {
      const vendors = await getVendorsList();
      setVendorsList(vendors);
      const res = await getTabData(
        tabKey,
        quoteId ?? '',
        isEventsTab,
        `quote_id=${quoteId}&event_date_id=${details?.event_date_id}`
      );
      if (res?.success) {
        setFormikData(res?.data);
        const valuesToSetInFormik = getMappedData({ ...values }, { ...res?.data }, isEventsTab);
        setValues(valuesToSetInFormik);
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id,
          quotation_no: res?.data?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string
        }));
        if (!valuesToSetInFormik?.event_dates?.vendors?.length) {
          formik.setFieldValue('event_dates.vendors', [
            // ...(values?.event_dates?.vendors || []),
            {
              ...vendorPositionItem(isEventsTab)
            }
          ]);
        }
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch quote data.'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch quote data.'
      });
    }
    setPageLoading(false);
  };

  const removeVendorHandler = async (item: any, index: number, arrayHelper: any) => {
    arrayHelper.remove(index);
  };

  const submitEvent = async (values: any, actions: any) => {
    setIsModalVisible(false);
    const vendors = values?.vendors
      ?.filter((vendor: any) => vendor?.vendor_id)
      ?.map((vendor: IVendorListItemType) => ({
        ...vendor,
        quotation_id: quoteId,
        quotation_event_date_id: details?.event_date_id
      }));
    submitForm &&
      submitForm(
        tabKey,
        {
          quote_id: quoteId,
          vendors: vendors
        },
        actions,
        'post'
      );
  };

  const submitHandler = async (values: any, actions: any) => {
    const payload = getMappedData({ ...values, quoteId }, { ...values }, isEventsTab);
    if (isEventsTab && submitForm) {
      const check = values?.vendors?.some(
        (item: any) =>
          !item?.event_end_time ||
          !item?.event_start_time ||
          !item?.vendor_id ||
          !item?.quantity ||
          !item?.rate
      );
      if (check) {
        setIsModalVisible(true);
        return;
      }
      submitEvent(values, actions);
      return;
    }

    const res: any = await PutTabData(tabKey, {
      ...quoteBasicDetails,
      ...payload,
      unique_invoice_string: random_unique_invoice_string
        ? random_unique_invoice_string
        : quoteBasicDetails.unique_invoice_string,
      id: quoteId,
      event_dates: {
        ...values?.event_dates,
        event_date: values?.event_dates?.event_date?.[0] ?? ''
      }
    });
    if (res?.success) {
      actions?.setSubmitting(false);
      setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
        ...prev,
        id: res?.data?.id || prev?.id,
        quotation_no: res?.data?.quotation_no || prev?.quotation_no,
        status: res?.data?.status,
        unique_invoice_string: random_unique_invoice_string
          ? random_unique_invoice_string
          : res?.data?.unique_invoice_string || prev?.unique_invoice_string
      }));
      const tabBaseUrl = location?.pathname.includes('edit-quote')
        ? `/quote/edit-quote/${res?.data?.id}/`
        : `/quote/add-quote/${
            res?.data?.id
              ? res?.data?.id + '/'
              : quoteId
              ? quoteId + '/'
              : quoteBasicDetails?.id
              ? quoteBasicDetails?.id + '/'
              : res?.data?.id
              ? res?.data?.id + '/'
              : ''
          }`;
      navigate(`${tabBaseUrl}other`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      actions?.setSubmitting(false);
      actions?.setErrors(res?.error);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
  };

  const backHandler = () => {
    if (isEventsTab) {
      const tabBaseUrl = withQuote
        ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.SERVICE}`
        : `/events/create/${CREATE_EVENT_TAB_KEYS.LABOURS}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}labour`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };

  const cancelHandler = () => {
    if (isEventsTab) {
      navigate('/events');
    } else {
      navigate('/quote');
    }
  };
  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    setFieldValue('status', STATUS.DRAFT);
    if (submitForm) {
      submitForm(
        tabKey,
        {
          ...values
        },
        null, // actions
        'post'
      );
      return;
    }
    const res: any = await PutTabData(tabKey, {
      ...values,
      status: STATUS.DRAFT,
      ...quoteBasicDetails,
      event_dates: {
        ...values?.event_dates,
        unique_invoice_string: random_unique_invoice_string
          ? random_unique_invoice_string
          : quoteBasicDetails.unique_invoice_string,
        event_date: values?.event_dates?.event_date?.[0] ?? ''
      }
    });
    if (res?.success) {
      navigate(`/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}`);
      showToast({
        message: 'Success!',
        description: 'Quotation saved as Draft'
      });
    } else {
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
    // submitHandler({ ...values, status: STATUS.DRAFT });
    setDraftSubmitting(false);
  };
  const nextHandler = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (tabKey === 'vendor') {
      if (isEventsTab) {
        if (details?.event_date_id && quoteId) {
          updateInitialData();
        }
      } else {
        updateInitialData();
      }
    }
  }, [details?.event_date_id, tabKey]);

  const addVendorMethod = () => {
    if (isEventsTab && values?.vendors) {
      let vendor = [...values?.vendors];
      vendor.push({ ...vendorPositionItem(isEventsTab) });
      formik.setFieldValue('vendors', vendor);
    } else {
      formik.setFieldValue('event_dates.vendors', [
        ...(values?.event_dates?.vendors || []),
        {
          ...vendorPositionItem(isEventsTab),
          vendor_id: Math.random()
        }
      ]);
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <>
      {/* <EventBasicDetails /> */}

      <Row style={{ marginTop: 40 }}>
        <H2Typography>Vendor Estimate</H2Typography>
        <Divider />
        <Row style={{ width: '100%' }}>
          <Col lg={8} md={12} xs={24}>
            <Label text={'Date'} />
            <SelectInput
              id={isEventsTab ? 'event_date' : 'event_dates.event_date[0]'}
              name={isEventsTab ? 'event_date' : 'event_dates.event_date[0]'}
              allowClear={false}
              placeholdertitle="Select from the list"
              value={0}
            >
              {getSelectOptions(
                [
                  {
                    id: 0,
                    name:
                      values?.event_dates?.date_string &&
                      values?.event_dates?.date_string !== '0000-00-00'
                        ? values?.event_dates?.date_string
                        : details?.event_date && details?.event_date !== '0000-00-00'
                        ? moment(details?.event_date).format('ddd, MMMM D')
                        : 'Unassigned Date'
                  }
                ],
                'name'
              )}
            </SelectInput>
          </Col>
        </Row>
        <FormikProvider value={formik}>
          <FieldArray
            name={isEventsTab ? 'vendors' : 'event_dates.vendors'}
            render={(arrayHelper) => {
              if (VendorArray?.length === 0) {
                return null;
              }
              return VendorArray?.map((item: any, index: number) => {
                return (
                  <Row
                    key={`${index}-${item.vendor_id}-vendor`}
                    style={{ marginTop: 20, width: '100%' }}
                  >
                    <VendorEstimateItem
                      vendor={item}
                      index={index}
                      formik={formik}
                      vendorsList={vendorsList}
                      onRemove={() => {
                        removeVendorHandler(item, index, arrayHelper);
                      }}
                      isEventsTab={isEventsTab}
                      key={`${index}-${item.vendor_id}-vendor`}
                    />
                  </Row>
                );
              });
            }}
          />
        </FormikProvider>
        <Row style={{ marginTop: 20 }}>
          <Button
            variant="secondary"
            onClick={addVendorMethod}
            text={'+ Add Vendors'}
            style={{ borderWidth: 2, fontWeight: 'bold' }}
          />
        </Row>
      </Row>
      <ConfirmModal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          formik?.setSubmitting(false);
        }}
        title={`You are missing information on ${
          !!details?.event_date && details?.event_date !== '0000-00-00'
            ? moment(details?.event_date).format('ddd, MMMM DD')
            : '-'
        } are you sure you want to continue?`}
        onOK={() => {
          submitEvent(values, null);
          formik?.setSubmitting(false);
        }}
        okText="Yes, Continue"
      >
        <span>you can always come back and edit later</span>
      </ConfirmModal>
      <AddEditQuoteFooter
        isEvent={isEventsTab}
        showBackButton={true}
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        saveAsDraftClickHandler={saveAsDraftHandler}
        nextClickHandler={nextHandler}
        isDraftSubmitting={draftSubmitting}
        isSubmitting={formik?.isSubmitting}
      />
    </>
  );
};

export default VendorsForm;
