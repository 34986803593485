import DataTable from '@organism/DataTable/DataTable';
import { DataTableWrapper } from '@pages/EmployeeManagementPage/ViewEmployee/style';
import React from 'react';
import { getColumns } from '../helper';
import { IInitialFiltersType } from '../types';

function VendorRecords({
  vendorRecordData,
  isLoadingVendorRecords,
  setFilters
}: {
  vendorRecordData: any;
  isLoadingVendorRecords: boolean;
  setFilters: React.Dispatch<React.SetStateAction<IInitialFiltersType>>;
}) {
  const cols = getColumns();

  const handlePaginationChange = (page: number) => {
    setFilters((prevFilters: IInitialFiltersType) => ({ ...prevFilters, page }));
  };

  return (
    <DataTableWrapper>
      <DataTable
        className="fixTD"
        loading={isLoadingVendorRecords}
        columns={cols}
        showLoadMore={!!vendorRecordData?.next_page_url}
        nextPageURL={vendorRecordData?.next_page_url}
        dataSource={vendorRecordData?.data || []}
        pagination={{
          pageSize: vendorRecordData?.per_page,
          total: vendorRecordData?.total,
          current: vendorRecordData?.current_page,
          onChange: handlePaginationChange
        }}
        onFetch={() => {}}
        renderEmpty={() => <div>No Records found.</div>}
        scrollConfig={{
          y: vendorRecordData?.data?.length ? 'calc(100vh - 350px)' : 'auto'
        }}
      />
    </DataTableWrapper>
  );
}

export default VendorRecords;
