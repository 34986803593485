import { Col, Row } from 'antd';
import {
  ContactDetailsType,
  IAddEditInfoProps,
  IVendorCatergoryType
} from '@pages/VendorManagementPage/types';
import { getAddressComps, getLocationByPlaceId } from '@utils/googlemaphealper';

import { Flex } from '@atom/Flex';
import { GoogleAutoComplete } from '@atom/GoogleAutoComplete';
import { Input } from '@atom/Input';
import InputPhone from '@atom/InputPhone';
import { Label } from '@atom/FormLable';
import QuestionMark from '@assets/question-mark.svg';
import React from 'react';
import { SelectInputWithCheckBox } from '@atom/Select';
import StatusLabel from '@atom/StatusLabel';
import TagComponent from '@atom/TagComponent';
import { TextAreaInput } from '@atom/TextArea';
import { useParams } from 'react-router';
import { ErrorWrapper } from './style';

function VendorDetailsSection({ formik, vendorCatergoriesData }: IAddEditInfoProps) {
  const { id } = useParams();
  const { values, handleBlur, setFieldValue, handleChange, touched, errors, setFieldTouched } =
    formik;

  const phoneChangeHandler = (a: ContactDetailsType) => {
    setFieldValue(`country_code`, a.code ?? ``);
    setFieldValue(`extension`, a.ext ?? ``);
    setFieldValue(`phone_number`, a.phone ?? ``);
  };

  const onPlaceSelect = async (placeId: string) => {
    if (placeId) {
      const place: any = await getLocationByPlaceId(placeId);
      if (place) {
        const locObj = getAddressComps(place);
        if (locObj.formattedAddress.length > 0) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };
          setFieldValue(
            'address',
            `${locObj?.formattedAddress}${locObj?.neighbourhood && ', ' + locObj?.neighbourhood}${
              locObj?.city && ', ' + locObj?.city
            }${locObj?.state && ', ' + locObj?.state}${
              locObj?.country && ', ' + locObj?.country
            }` || ''
          );
          setFieldValue('latitude', coordinates?.lat || '');
          setFieldValue('longitude', coordinates?.lng || '');
        }
      }
    }
  };

  const imageHelperTooltip = (
    <Flex direction="column" alignItems="end">
      <p>Add tag to describe vendor, this is what will show in the qoute</p>
    </Flex>
  );

  return (
    <Flex direction="column" style={{ width: '100%' }}>
      <div id="map" />
      <Row gutter={16} justify="space-between">
        <Col xs={24} lg={12}>
          <Label isMandatory={true} text="Vendor Type" />
          <SelectInputWithCheckBox
            id="vendor_categories"
            name="vendor_categories"
            placeholdertitle="Select Menu(s)"
            mode="multiple"
            options={vendorCatergoriesData}
            value={values?.vendor_categories?.map(
              (i: IVendorCatergoryType) => i?.vendor_category_id
            )}
            error={touched?.vendor_categories && errors?.vendor_categories}
            onChange={(e: any) => {
              const arr = vendorCatergoriesData?.filter((item) => e.includes(item?.id));
              setFieldValue('vendor_categories', arr);
            }}
          />
        </Col>
        {id && (
          <Col xs={24} lg={2} style={{ marginLeft: 'auto' }}>
            <Label text="STATUS" />
            {(!!values?.status || values?.status === 0) && <StatusLabel status={values?.status} />}
          </Col>
        )}
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label text="Vendor Name" isMandatory />
          <Input
            name="vendor_name"
            id="vendor_name"
            error={touched?.vendor_name && errors?.vendor_name}
            placeholder="Type Name"
            onChange={handleChange}
            value={values?.vendor_name ?? ''}
            onBlur={handleBlur}
          />
        </Col>

        <Col md={12} xs={24}>
          <Label isMandatory={true} text="Public Tag" />
          <ErrorWrapper>
            <TagComponent
              id={`public_tags`}
              setFieldValue={setFieldValue}
              values={values?.public_tags}
              placeholder="Add Tag to Describe Vendor"
              key="public_tags"
              error={touched?.public_tags && errors?.public_tags}
              isTooltip={true}
              toolTipText={'Add tag to describe vendor, this is what will show in the Quote'}
              toolTipIcon={QuestionMark}
              toolTipPlacement="topRight"
            />
          </ErrorWrapper>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Label isMandatory text="Contact Name " />
          <Input
            name="contact_name"
            id="contact_name"
            error={touched?.contact_name && errors?.contact_name}
            placeholder="Type Name"
            onChange={handleChange}
            value={values?.contact_name}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={12} xs={24}>
          <Label isMandatory text="Contact Email" />
          <Input
            name="email"
            id="email"
            error={touched?.email && errors?.email}
            placeholder="Type Email"
            onChange={handleChange}
            value={values?.email}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={10} xs={24}>
          <Label isMandatory text="Phone Number" />
          <InputPhone
            id="phone_number"
            name="phone_number"
            value={{
              code: String(values?.country_code ?? '+1'),
              phone: String(values?.phone_number ?? ''),
              ext: String(values?.extension ?? '')
            }}
            error={
              (touched?.phone_number && errors?.phone_number) ||
              (touched?.country_code && errors?.country_code) ||
              (touched?.extension && errors?.extension) ||
              ''
            }
            onBlur={handleBlur}
            onChange={phoneChangeHandler}
          />
        </Col>
        <Col md={2}></Col>
        <Col md={12} xs={24}>
          <Label isMandatory text="Billing Address" />
          <GoogleAutoComplete
            apiKey={process.env.REACT_APP_GOOGLEAPI_KEY}
            id="address"
            name="address"
            value={values?.address}
            placeholder="Type Address"
            onPlaceSelect={(place: any) => {
              if (place) onPlaceSelect(place.place_id);
            }}
            error={touched?.address && errors?.address}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          <Label isMandatory text="Vendor Description " />
          <TextAreaInput
            name="description"
            id="description"
            error={touched?.description && errors?.description}
            placeholder="Add Description"
            onChange={handleChange}
            value={values?.description}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </Flex>
  );
}

export default VendorDetailsSection;
