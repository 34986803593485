import { Col, Row } from 'antd';
import { ICategoryType, IVendorListFilterType } from '../types';

import { Button } from '@atom/Buttons';
import { ChangeEvent } from 'react';
import { Flex } from '@atom/Flex';
import StatusLabel from '@atom/StatusLabel';
import TableHeader from './TableHeader';
import VendorTypeHeader from './VendorTypeHeader';

export const getColumns = (
  filterValue: IVendorListFilterType,
  onChange: (a: ChangeEvent | null, key?: 'vendor_type') => void,
  vendorCategories: ICategoryType[]
) => {
  const columns = [
    {
      title: (
        <TableHeader
          columnTitle="Vendor Name"
          name="vendor_name"
          value={filterValue.vendor_name ?? ''}
          placeholder="Vendor Name"
          onChange={(e) => {
            !(!filterValue.vendor_name && !(e?.target as HTMLInputElement)?.value) && onChange(e);
          }}
        />
      ),
      dataIndex: 'vendor_name',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Row style={{ width: '240px' }}>
          <Row style={{ width: '100%' }}>
            <Col md={12} xs={12} style={{ marginBottom: '16px' }}>
              <p>Vendor Type</p>
            </Col>
            <Col md={12} xs={12}>
              {filterValue?.vendor_type ? (
                <Button
                  text="Clear"
                  variant="ghost"
                  style={{ padding: '0px', marginLeft: 'auto' }}
                  onClick={() => onChange(null, 'vendor_type')}
                />
              ) : null}
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <VendorTypeHeader
              name="vendor_type"
              value={!!filterValue.vendor_type ? filterValue.vendor_type : null}
              placeholder="SORT: Vendor Type"
              onChange={(e) => onChange(e, 'vendor_type')}
              vendorCategories={vendorCategories}
            />
          </Row>
        </Row>
      ),
      dataIndex: 'vendor_type',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Email"
          name="email"
          value={filterValue.email ?? ''}
          placeholder="Email"
          onChange={(e) => {
            !(!filterValue.email && !(e?.target as HTMLInputElement)?.value) && onChange(e);
          }}
        />
      ),
      dataIndex: 'email',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          columnTitle="Phone Number"
          name="phone_number"
          value={filterValue.phone_number ?? ''}
          placeholder="Phone Number"
          onChange={(e) => {
            !(!filterValue.phone_number && !(e?.target as HTMLInputElement)?.value) && onChange(e);
          }}
        />
      ),
      dataIndex: 'formated_phone_number',
      render: (data: any, row: any) => {
        const countryCode = row?.country_code?.includes('+')
          ? row?.country_code
          : row?.country_code && `+${row?.country_code}`;
        const phone = data;
        const extension = row?.extension;
        const phoneNo = `${countryCode || ''} ${phone || ''} ${extension ? `x${extension}` : ''}`;
        return data ? (
          <Flex style={{ width: '100%' }} justifyContent="space-between" alignItems="center">
            <span>{phoneNo}</span>
            <StatusLabel status={row?.status || 0} isUppercase={true} />
          </Flex>
        ) : (
          '-'
        );
      }
    }
  ];
  return columns;
};
