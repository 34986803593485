import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import SelectInput, { SelectInputWithCheckBox } from '@atom/Select';
import TimePickerComponent from '@atom/TimePicker';
import { AddNotesText, RemoveIcon } from '@pages/QuoteManagementPage/AddEditQuote/Labours/style';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { EMPLOYEE_STATUS, getAvailableEmployees } from './helper';
import { StaffingWrapper } from './style';
import { Select } from 'antd';
import ConfirmModal from '@organism/ConfimationModal';
import { useParams } from 'react-router';
import { showErrorToast } from '@utils/index';
import { CREATE_EVENT_TAB_KEYS } from '../../helper';
import { Moment } from 'moment';

interface StaffingFieldsProps {
  index: number;
  item: any;
  onRemove: (item: any) => void;
  formik: any;
  dropdownData: any;
  fieldType: string;
  eventDetails: any;
  deletedData?: any[];
  allPositions?: any;
}

const StaffingFields = (props: StaffingFieldsProps) => {
  const {
    index,
    item,
    onRemove,
    formik,
    dropdownData,
    fieldType,
    eventDetails,
    deletedData = [],
    allPositions = []
  } = props;
  const { handleBlur, setFieldTouched, setFieldValue, errors, touched } = formik;
  const { eventId, formType } = useParams();
  const [showNotesArea, setShowNotesArea] = useState<boolean>(false);
  const [localStaffingNotesValue, setLocalStaffingNotesValue] = useState<string>('');
  const [employees, setEmployees] = useState<Array<any>>([]);
  const [tempEmpIds, setTempEmpIds] = useState<Array<number>>([]);
  const [isloading, setLoading] = useState<boolean>(true);

  const valVarBase: any = item;
  const idBase: string = `${fieldType}[${index}]`;

  const [staffingItem, setStaffingItem] = useState<any>({});
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const isInvalidDate = !eventDetails?.event_date || eventDetails?.event_date === '0000-00-00';
  useEffect(() => {
    setLocalStaffingNotesValue(item?.notes ?? '');
  }, [item?.notes]);

  useEffect(() => {
    setStaffingItem({ ...valVarBase });
  }, [item]);

  useEffect(() => {
    if (formType === CREATE_EVENT_TAB_KEYS.STAFFING) {
      fetchAvailableEmployeesList(staffingItem);
    }
  }, [formType, staffingItem?.position_id]);

  const fetchAvailableEmployeesList = async (staffingItem: any) => {
    if (staffingItem?.position_id && !isInvalidDate) {
      setLoading(true);
      try {
        const res = await getAvailableEmployees({
          ...staffingItem,
          event_id: eventId,
          event_date: eventDetails?.event_date
        });
        setEmployees(res?.data || []);
      } catch (error: any) {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch available employees.'
        });
      }

      setLoading(false);
    }
  };

  const handleChangeValue = (value: any, formikKey: string, key: string) => {
    setStaffingItem((prev: any) => ({ ...prev, [key]: value }));
    setTimeout(() => {
      setFieldValue(formikKey, value);
    }, 200);
  };

  const handleSelectEmployee = (e: any) => {
    const isAvailable =
      !e?.length ||
      employees?.find((i: any) => i?.id === e?.[e?.length - 1])?.status === 'AVAILABLE';
    if (isAvailable) {
      handleChangeValue(e, `${idBase}.employee_ids`, 'employee_ids');
    } else {
      setTempEmpIds(e);
      setIsModalVisible(true);
    }
  };

  const handleOveride = () => {
    handleChangeValue(tempEmpIds, `${idBase}.employee_ids`, 'employee_ids');
    setTempEmpIds([]);
  };

  const getEmployeeLabel = (i: any) => {
    return (
      <Flex justifyContent="space-between">
        <>{i.name}</>
        <span
          style={{ color: i?.status_id === EMPLOYEE_STATUS.UNAVAILABLE ? '#27a03b' : '#e74a3b' }}>
          {i?.status}
        </span>
      </Flex>
    );
  };

  const showEventDateWarning = () => {
    showErrorToast({
      message: 'Warning!',
      description: 'Please set event date in customer tab. It is required to fetch employees list.'
    });
  };

  const stffingError = errors?.[fieldType] ? errors[fieldType]?.[index] || {} : {};
  const staffingTouched = touched?.[fieldType] ? touched[fieldType]?.[index] || {} : {};
  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col lg={4} xs={24}>
          <StaffingWrapper>
            <Label text={'Start Time'} />
            <TimePickerComponent
              id={`${idBase}.start_time`}
              name={`${idBase}.start_time`}
              use12Hours={true}
              format="h:mm A"
              placeholder={'HH:MM'}
              value={staffingItem?.start_time}
              onChange={(value: any) => {
                setFieldTouched(`${idBase}.start_time`, true);
                handleChangeValue(value, `${idBase}.start_time`, 'start_time');
                !isInvalidDate
                  ? fetchAvailableEmployeesList({ ...staffingItem, start_time: value })
                  : showEventDateWarning();
              }}
              onSelect={(e: Moment) => {
                handleChangeValue(e.format('hh:mm A'), `${idBase}.start_time`, 'start_time');
              }}
              error={staffingTouched?.start_time && stffingError?.start_time}
            />
          </StaffingWrapper>
        </Col>

        <Col lg={4} xs={24}>
          <Label text={'End Time'} />
          <TimePickerComponent
            className="bottom-0"
            id={`${idBase}.end_time`}
            name={`${idBase}.end_time`}
            use12Hours={true}
            format="h:mm A"
            placeholder={'HH:MM'}
            value={staffingItem?.end_time}
            onChange={(value: any) => {
              setFieldTouched(`${idBase}.end_time`, true);
              handleChangeValue(value, `${idBase}.end_time`, 'end_time');
              !isInvalidDate
                ? fetchAvailableEmployeesList({ ...staffingItem, end_time: value })
                : showEventDateWarning();
            }}
            onSelect={(e: Moment) => {
              handleChangeValue(e.format('hh:mm A'), `${idBase}.end_time`, 'end_time');
            }}
            error={staffingTouched.end_time && stffingError.end_time}
          />
        </Col>

        <Col lg={4} xs={24}>
          <Label text={'Qty'} />
          <Input
            className="bottom-0"
            name={`${idBase}.quantity`}
            id={`${idBase}.quantity`}
            placeholder={'0'}
            value={staffingItem?.quantity}
            onChange={(e: any) =>
              handleChangeValue(e?.target.value, `${idBase}.quantity`, 'quantity')
            }
            onBlur={handleBlur}
            error={staffingTouched.quantity && stffingError.quantity}
          />
        </Col>
        <Col lg={4} xs={24}>
          <Label text={'Position'} />
          <SelectInput
            className="bottom-0"
            id={`${idBase}.position_id`}
            name={`${idBase}.position_id`}
            value={staffingItem?.position_id}
            disabled={staffingItem?.quotation_labour_id}
            onChange={(value: any) => {
              handleChangeValue(value, `${idBase}.position_id`, 'position_id');
              handleChangeValue(undefined, `${idBase}.employee_ids`, 'employee_ids');
              !isInvalidDate
                ? fetchAvailableEmployeesList({ ...staffingItem, start_time: value })
                : showEventDateWarning();
            }}
            error={staffingTouched.position_id && stffingError.position_id}>
            {deletedData?.map((el: any) => el?.id)?.includes(staffingItem?.position_id)
              ? allPositions?.map((item: any) => (
                  <Select.Option key={item?.id} value={item?.id} disabled={item?.deleted_at}>
                    {item?.name}
                  </Select.Option>
                ))
              : dropdownData?.positions?.map((item: any) => (
                  <Select.Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Select.Option>
                ))}
          </SelectInput>
        </Col>

        <Col lg={6} xs={24}>
          <Label text={'Employee'} />
          <SelectInputWithCheckBox
            className="bottom-0"
            name={`${idBase}.employee_ids`}
            id={`${idBase}.employee_ids`}
            placeholdertitle="Search for Employee"
            onChange={(e: any) => handleSelectEmployee(e)}
            options={employees?.map((i: any) => {
              i.title = getEmployeeLabel(i);
              i.key = i.id;
              i.value = i.id;
              return i;
            })}
            filterTreeNode={(inputValue: string, treeNode: any) =>
              treeNode?.name?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) > -1
            }
            value={!isloading ? staffingItem?.employee_ids : []}
            error={staffingTouched.employee_ids && stffingError.employee_ids}
          />
        </Col>
        <Col
          lg={2}
          xs={24}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <RemoveIcon onClick={() => onRemove(valVarBase)} />
        </Col>
      </Row>
      {!showNotesArea && !staffingItem?.toggle_notes && !localStaffingNotesValue && (
        <AddNotesText onClick={() => setShowNotesArea(true)}>+ Add Notes</AddNotesText>
      )}
      {(showNotesArea || staffingItem?.toggle_notes || localStaffingNotesValue) && (
        <Row style={{ width: '100%' }}>
          <Col lg={12} xs={24}>
            <Label text={'Notes'} />
            <Input
              name={`${idBase}.notes`}
              id={`${idBase}.notes`}
              placeholder="Add Notes"
              onChange={(e: any) => {
                setLocalStaffingNotesValue(e?.target?.value);
                setTimeout(() => {
                  setFieldValue(`${idBase}.notes`, e?.target?.value);
                }, 200);
              }}
              value={localStaffingNotesValue}
            />
          </Col>
        </Row>
      )}
      <ConfirmModal
        visible={isModalVisible}
        onCancel={() => {
          setTempEmpIds([]);
          handleChangeValue(
            staffingItem?.employee_ids || [],
            `${idBase}.employee_ids`,
            'employee_ids'
          );
          setIsModalVisible(false);
        }}
        title={`This staff member is not available at this time would you like to override the system and assign them anyways?`}
        onOK={() => {
          setIsModalVisible(false);
          handleOveride();
        }}
        okFirst={true}
        okText="Override"
      />
    </>
  );
};

export default StaffingFields;
