import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import DragNDropWrapper from '@atom/DragNDropWrapper';
import { Col, Row } from 'antd';
import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from 'react';
import { IngredientsListType, SpecialIngredientsListType } from './helper';
import IngredientRowItem from './IngredientRowItem';
import { H4TextWrapper, IngredientListButtonsWrapper, IngredientsListContainer } from './style';

interface IngredientsVirtualListProps {
  formik: any;
  measurementsData: any[];
  prepListData: any[];
  setProductSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  ingredientsList: IngredientsListType[];
  specialIngredientsList: SpecialIngredientsListType[];
}

const IngredientsVirtualList: React.FC<IngredientsVirtualListProps> = ({
  formik,
  measurementsData,
  prepListData,
  setProductSubmit,
  isLoading,
  ingredientsList,
  specialIngredientsList
}) => {
  
  const { handleChange, setFieldValue, setValues, values, handleBlur, touched, errors } = formik;
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(values?.product_subcategory?.ingredients || [])) || [];
    setData(data);
  }, [values?.product_subcategory?.ingredients]);

  const addIngredientHandler = (isSpecialIngredient: number) => {
    setFieldValue('product_subcategory.ingredients', [
      ...(values?.product_subcategory?.ingredients || []),
      {
        is_special_ingredient: isSpecialIngredient,
        ingredient_name: '',
        ingredient_id: '',
        sort_order: values?.product_subcategory?.ingredients?.length + 1 || 1
      }
    ]);
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      let res = update(values?.product_subcategory?.ingredients, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, values?.product_subcategory?.ingredients[dragIndex]]
        ]
      });
      res = res?.map((i: any, index: number) => ({ ...i, sort_order: index + 1 }));
      setFieldValue('product_subcategory.ingredients', res);
    },
    [values?.product_subcategory?.ingredients]
  );

  if (isLoading) {
    return <CenterSpiner />;
  }
  return (
    <IngredientsListContainer className="TableFix-Td" dataLength={data.length}>
      <Row gutter={24} className="table-header-row tablerow-fix">
        <Col md={1} />
        <Col md={5}>
          <H4TextWrapper>{'Ingredient Name'}</H4TextWrapper>
        </Col>
        <Col md={3}>
          <H4TextWrapper>{'Amount'}</H4TextWrapper>
        </Col>
        <Col md={4}>
          <H4TextWrapper>{'Measurement'}</H4TextWrapper>
        </Col>
        <Col md={4}>
          <H4TextWrapper>{'Prep List'}</H4TextWrapper>
        </Col>
        <Col md={4}>
          <H4TextWrapper>{'Visibility'}</H4TextWrapper>
        </Col>
        <Col md={3}>
          <H4TextWrapper>{'Action'}</H4TextWrapper>
        </Col>
      </Row>
      {data?.map((item: any, index: number) => (
        <div className="table-body-row">
          <DragNDropWrapper key={index} index={index} moveCard={moveCard} isSide={true}>
            <IngredientRowItem
              {...{
                key: `ingredients-${index}-${values?.product_subcategory?.ingredients?.[index]?.ingredient_id}`,
                index,
                data: {
                  handleChange,
                  setFieldValue,
                  setValues,
                  touched,
                  errors,
                  values,
                  handleBlur,
                  measurementsData,
                  prepListData,
                  setProductSubmit: setProductSubmit,
                  initialIngredientsList: ingredientsList,
                  initialSpecialIngredientsList: specialIngredientsList
                }
              }}
            />
          </DragNDropWrapper>
        </div>
      ))}
      <IngredientListButtonsWrapper>
        <Button
          text="Add Ingredient"
          variant="secondary"
          icon={<img width={20} src={PlusCircle} alt="add" />}
          style={{ width: '100%', marginRight: '10px' }}
          onClick={() => addIngredientHandler(0)}
        />
        <Button
          text="Add Special Ingredient"
          variant="secondary"
          icon={
            <img
              width={20}
              src={PlusCircle}
              alt="add"
              style={{ color: '#FD7E14', borderColor: '#FD7E14' }}
            />
          }
          style={{ width: '100%', color: '#FD7E14', borderColor: '#FD7E14' }}
          onClick={() => addIngredientHandler(1)}
        />
      </IngredientListButtonsWrapper>
    </IngredientsListContainer>
  );
};

export default IngredientsVirtualList;
