import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 100%;
  // height: 355px;
  object-fit: cover;
  border-radius: 10px;
`;

export const ImageProfile = styled.img`
  object-position: left top;
  max-width: 100%;
  border-radius: 10px;
  max-height: 355px;
  object-fit: contain;
`;

export const DataTableWrapper = styled.div`
  .ant-table {
    min-height: 80px;
  }
  .special_instruction_column {
    color: #4e73df !important;
  }
`;
export const Wrapper = styled.div`
  @media (max-width: 768px) {
    .mobile-fixing {
      display: block !important;
    }
    .ant-col {
      display: flex !important;
    }
    .btn-fixing {
      justify-content: left;
    }
  }
`;

export const ContentWrapper = styled.div`
  @media (max-width: 768px) {
    .description-margin {
      margin-bottom: 0px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }

    .content-title {
      margin-bottom: 5px !important;
    }

    .page-btn {
      max-width: inital !important;
    }
  }
`;
