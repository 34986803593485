import { QUOTE_STATUS } from '@pages/QuoteManagementPage/constants';
import * as Yup from 'yup';

const positionItems = (isEvent?: boolean) =>
  Yup.object().shape({
    start_time: Yup.string().nullable(),
    end_time: Yup.string().nullable(),
    rate: Yup.number().nullable().typeError('Rate must be entered in numbers only'),
    // quantity: isEvent
    //   ? Yup.number()
    //       .when('rate', {
    //         is: (value: number) => value && value,
    //         then: Yup.number()
    //           .required('The quantity field is required.')
    //           .typeError('Qty must be entered in numbers only')
    //           .max(255, 'Qty must be less than or equal to 255'),
    //         otherwise: Yup.number().typeError('Qty must be entered in numbers only')
    //       })
    //       .nullable()
    //   : Yup.number()
    //       .nullable()
    //       .typeError('Qty must be entered in numbers only')
    //       .max(255, 'Qty must be less than or equal to 255'),
    quantity: Yup.number()
      .when('rate', {
        is: (value: number) => value !== undefined && value !== null,
        then: Yup.number()
          .required('The quantity field is required.')
          .typeError('Qty must be entered in numbers only')
          .max(255, 'Qty must be less than or equal to 255')
          .min(1, 'Qty must be greater than 0'),
        otherwise: Yup.number().typeError('Qty must be entered in numbers only')
      })
      .nullable(),
    waived_off: Yup.mixed().nullable(),
    notes: Yup.string().nullable(),
    position: Yup.mixed().required('The position field is required.'),
    master_position_id: Yup.mixed().nullable(),
    sort_order: Yup.number().nullable(),
    total_cost: Yup.string().nullable(),
    // hours: isEvent
    //   ? Yup.number()
    //       .when('rate', {
    //         is: (value: number) => value && value,
    //         then: Yup.number()
    //           .required('The hours field is required.')
    //           .typeError('Hours must be entered in numbers only'),
    //         otherwise: Yup.number().typeError('Hours must be entered in numbers only')
    //       })
    //       .nullable()
    //   : Yup.number().nullable().typeError('Hours must be entered in numbers only')
    hours: Yup.number().nullable().typeError('Hours must be entered in numbers only')
  });

export const laboursFormSchema = (isEvent?: boolean) =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    unique_invoice_string: Yup.string().nullable(),
    quotation_no: Yup.string().nullable(),
    event_dates: Yup.object().shape({
      id: Yup.string().nullable(),
      quotation_id: Yup.string().nullable(),
      event_date: Yup.array().of(Yup.string().nullable()),
      labours: Yup.array().of(positionItems(isEvent)).nullable()
    })
  });

export const FORM_INITIAL_VALUES = {
  id: '',
  unique_invoice_string: '',
  quotation_no: '',
  event_dates: {
    id: '',
    quotation_id: '',
    event_date: [new Date().toString()],
    labours: [],
    notify_staff: []
  }
};


export const labourPositionItem = {
  master_position_id: '',
  sort_order: null,
  total_cost: '',
  hours: '',
  start_time: '',
  end_time: '',
  rate: '',
  quantity: '',
  waived_off: 0,
  notes: '',
  position: null,
  do_not_override_data: 0,
};

export const getMappedData = (oldValuesObj: any, newValuesObj: any) => {
  return {
    ...oldValuesObj,
    id: newValuesObj?.id || oldValuesObj?.id,
    quotation_no: newValuesObj?.quotation_no || oldValuesObj?.quotation_no,
    status: newValuesObj?.status ?? oldValuesObj?.status,
    unique_invoice_string:
      newValuesObj?.unique_invoice_string || oldValuesObj?.unique_invoice_string,
    event_dates: {
      ...oldValuesObj?.event_dates,
      ...newValuesObj?.event_dates,
      event_date: [newValuesObj?.event_dates?.event_date],
      quotation_event_date_id: newValuesObj?.event_dates?.id || oldValuesObj?.event_dates?.id
    }
  };
};

const isValidEntry = (i: any) => {
  return (
    i?.start_time ||
    i?.end_time ||
    i?.quantity ||
    i?.position_id ||
    i?.notes ||
    i?.employee_ids?.length
  );
};

export const showOverrideCheckBox = [
  QUOTE_STATUS.CREATED_EVENTS,
  QUOTE_STATUS.CONFIRMED_EVENTS,
  QUOTE_STATUS.CANCELED_EVENTS,
  QUOTE_STATUS.EVENT_COMPLETED
];

export const getValidatedData = (data: any) => {
  return {
    ...data,
    additional_staffing: data?.additional_staffing
      ?.filter((i: any) => isValidEntry(i))
      .map((i: any, index: number) => ({ ...i, sort_order: index + 1 })),
    staffing: data?.staffing
      ?.filter((i: any) => isValidEntry(i))
      .map((i: any, index: number) => ({ ...i, sort_order: index + 1 }))
  };
};

export const labourTotalCostCalculationHandler = (laboursArray: any) => {
  let total = 0;
  if (laboursArray?.length) {
    laboursArray?.forEach(
      (labour: any) =>
        (total +=
          (parseFloat(labour?.quantity) || 0) *
          (parseFloat(labour?.rate) || 0) *
          (parseFloat(labour?.hours) || 0))
    );
  }
  return total;
};
