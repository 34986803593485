import DragNDropWrapper from '@atom/DragNDropWrapper';
import { PageTitle } from '@molecules/PageTitle';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import update from 'immutability-helper';
import { PlusCircleOutlined } from '@ant-design/icons';

import {
  EditEventTaskType,
  EventTaskType,
  eventTaskValidationSchema,
  getEventTaskList,
  initialValue,
  updateEventTaskList
} from './helper';
import TaskInput from './TaskInput';
import AddEditQuoteFooter from '@pages/QuoteManagementPage/AddEditQuote/AddEditQuoteFooter';
import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { showErrorToast, showToast } from '@utils/index';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '../../tabHelpers';

function TasksTab(props: any) {
  const { onTasksSubmit = (values: any) => {}, withQuote } = props;
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventTasks, setEventTasks] = useState<EventTaskType[]>([]);

  const { quoteId, eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (!eventId) return;
      setPageLoading(true);
      const result = await getEventTaskList(eventId);
      setPageLoading(false);
      if (!result.error) {
        setEventTasks(result.data || []);
      }
    })();
  }, []);

  const onSubmitHandler = async (values: EditEventTaskType) => {
    if (values.event_tasks.length === 0) {
      showErrorToast({ message: 'Task is required', description: '' });
      return;
    }
    setLoading(true);
    const updatedEvents = values.event_tasks.map((task, index) => ({
      ...task,
      sort_order: index + 1
    }));
    const result = await updateEventTaskList({ event_tasks: updatedEvents });
    setLoading(false);
    if (!result.error) {
      showToast({ message: 'Tasks Updated Successfully', description: '' });
      if (onTasksSubmit) {
        onTasksSubmit({ ...values, ...result });
        // return;
      }
      // const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.DOCUMENTS}/${quoteId}/${eventId}`;
      const tabBaseUrl = withQuote
        ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.DOCUMENTS}`
        : `/events/create/${CREATE_EVENT_TAB_KEYS.DOCUMENTS}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      showErrorToast({
        message: 'Error Saving Tasks',
        description: result.error || 'Please try again'
      });
    }
  };

  const formik = useFormik({
    initialValues: { event_tasks: eventTasks } as EditEventTaskType,
    enableReinitialize: true,
    validationSchema: eventTaskValidationSchema,
    onSubmit: onSubmitHandler
  });

  const { values, touched, errors, handleChange, setFieldValue, handleSubmit } = formik;

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const res = update(values.event_tasks, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, values.event_tasks[dragIndex]]
        ]
      });
      setFieldValue('event_tasks', res);
    },
    [values.event_tasks]
  );

  const handleDnD = async (obj: any) => {};

  const backHandler = () => {
    // const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.NOTES}/${quoteId}/${eventId}`;
    const tabBaseUrl = withQuote
      ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.NOTES}`
      : `/events/create/${CREATE_EVENT_TAB_KEYS.NOTES}/${quoteId}/${eventId}`;
    navigate(tabBaseUrl, {
      state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
    });
  };
  const cancelHandler = () => {
    navigate('/events');
  };
  const nextHandler = () => {
    handleSubmit();
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <div>
      <PageTitle text="Event Tasks" bordered={true} />
      <FormikProvider value={formik}>
        <FieldArray
          name="event_tasks"
          render={(arrayHelper) => {
            if (!values.event_tasks || !Array.isArray(values.event_tasks)) return null;
            return values.event_tasks.map((task, index) => (
              <DragNDropWrapper
                key={index}
                index={index}
                onDropCard={handleDnD}
                moveCard={moveCard}
                isSide={true}>
                <TaskInput
                  showRemove={values?.event_tasks.length > 1}
                  index={index}
                  formikValue={task.task}
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  onRemoveHandler={(index) => arrayHelper.remove(index)}
                />
              </DragNDropWrapper>
            ));
          }}
        />
        <Row gutter={16}>
          <Col xs={24} lg={4}>
            <Button
              style={{ fontWeight: '700' }}
              variant="secondary"
              icon={<PlusCircleOutlined />}
              text="Add Another Task"
              onClick={() => {
                setFieldValue('event_tasks', [
                  ...values?.event_tasks,
                  { ...initialValue, event_id: eventId }
                ]);
              }}
            />
          </Col>
        </Row>
      </FormikProvider>
      <AddEditQuoteFooter
        showBackButton
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        // saveAsDraftClickHandler={saveAsDraftHandler}
        nextClickHandler={nextHandler}
        isSubmitting={loading}
        // isNextButtonDisabled={!isValid}
      />
    </div>
  );
}

export default TasksTab;
