import Logo from '@assets/logo.png';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { accessibleModules, getNavItems, keysToRemove, PATH_NAME } from './helper';
import { AppSideBarContainer, Brand, NavItemContainer, MenuWrapper } from './style';
import { updateRawData } from '@utils/index';
import { localStorageUtil } from '@utils/localstorageUtil';

function AppBarSideBar() {
  const [activeState, setActiveState] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getActiveId = () => {
      if (location.pathname === '/') return PATH_NAME.DASHBOARD;
      else if (location.pathname.startsWith('/menu')) return PATH_NAME.MENU_MANAGEMENT;
      else if (location.pathname.startsWith('/food-preparation')) return PATH_NAME.FOOD_PREPARATION;
      else if (location.pathname.startsWith('/venues')) return PATH_NAME.VENUE_MANAGEMENT;
      else if (location.pathname.startsWith('/vendors')) return PATH_NAME.VENDOR_MANAGEMENT;
      else if (location.pathname.startsWith('/employees')) return PATH_NAME.EMPLOYEE_MANAGEMENT;
      else if (location.pathname.startsWith('/customers')) return PATH_NAME.CUSTOMER_MANAGEMENT;
      else if (location.pathname.startsWith('/events')) return PATH_NAME.EVENT_MANAGEMENT;
      else if (location.pathname.startsWith('/staff')) return PATH_NAME.STAFF_MANAGEMENT;
      else if (location.pathname.startsWith('/quote')) return PATH_NAME.QUOTE_MANAGEMENT;
      else if (location.pathname.startsWith('/work-orders')) return PATH_NAME.WORK_ORDER_MANAGEMENT;
      else if (location.pathname.startsWith('/profile')) return 0;
      else if (location.pathname.startsWith('/settings')) return 0;
      else return PATH_NAME.DASHBOARD;
    };
    setActiveState(getActiveId());
  }, [location.pathname]);

  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;
  const navItems = getNavItems().filter(
    (item: any) => (!isAdmin && accessibleModules(user).includes(item?.id)) || isAdmin
  );

  return (
    <AppSideBarContainer direction="column">
      <div
        className="logo"
        onClick={() => {
          updateRawData({ activeStateForMobile: 1 });
          navigate('/');
        }}>
        <img src={Logo} alt="logo" style={{ width: 50, marginBottom: '0.5rem' }} />
        <Brand>Food Dudes</Brand>
      </div>
      <MenuWrapper>
        {navItems.map((item: any, index: number) => (
          <NavItem
            key={index}
            item={item}
            className={activeState === item.id ? 'selected' : ''}
            onItemSelect={() => {
              navigate(item.to);
              keysToRemove.forEach((key) => {
                localStorageUtil.removeValue(key);
              });
            }}
          />
        ))}
      </MenuWrapper>
    </AppSideBarContainer>
  );
}

export const NavItem = (props: any) => {
  const { item, onItemSelect, isMobile, className, style } = props;
  return (
    <NavItemContainer
      className={className}
      onClick={onItemSelect}
      isMobile={isMobile}
      style={style}>
      <img src={item.icon} alt="item" />
      {item.title}
    </NavItemContainer>
  );
};

export default AppBarSideBar;
