import { Flex } from '@atom/Flex';
import React from 'react';
import EmployeeRecords from './EmployeeRecords';
import EmployeeSummary from './EmployeeSummary';

const ViewEmployee = () => {
  return (
    <Flex direction="column" gap={20}>
      <EmployeeSummary />
      <EmployeeRecords />
    </Flex>
  );
};

export default ViewEmployee;
