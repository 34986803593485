import {
  CALENDAR_VIEWS,
  MAX_EVENTS,
  getEvents,
  getInitialFilters,
  getMappedEvents,
  WEEK_START_DAY
} from './helper';
import { CalendarWrapper, EventTypeIndicator, EventWrapper } from './style';
import { useEffect, useState } from 'react';
import { Flex } from '@atom/Flex';
import FullCalendar from '@fullcalendar/react';
import PageLayout from '@layout/PageLayout';
import { Spin } from 'antd';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import list from '@fullcalendar/list';
import moment from 'moment';
import { showErrorToast } from '@utils/index';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentTimeZone from 'moment-timezone';

function DashboardPage() {
  const [eventList, setEventList] = useState<Array<any>>([]);
  const [calendarRef, setCalendarRef] = useState<any>({});
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<any>(getInitialFilters(new Date(), CALENDAR_VIEWS.MONTH));

  useEffect(() => {
    updateEventsList(filters);
  }, []);

  const updateEventsList = async (filters: any) => {
    setIsEventsLoading(true);
    try {
      const res: any = await getEvents(filters);
      if (res?.success) {
        const mappedData = getMappedEvents(res?.data, filters?.currentView);
        setEventList(mappedData);
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch Events'
        });
      }
    } catch (e: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch Events'
      });
    }
    setIsEventsLoading(false);
  };

  const handleUpdateDate = (eventContent: any) => {
    const newFilters = {
      startDate:
        eventContent?.view?.type === 'listDay'
          ? moment(
              momentTimeZone(
                eventContent?.view?.getCurrentData()?.dateProfile?.currentRange?.start
              )?.tz('Asia/Kolkata')
            )?.format('YYYY-MM-DD')
          : moment(eventContent?.view?.getCurrentData()?.dateProfile?.currentRange?.start)?.format(
              'YYYY-MM-DD'
            ),
      endDate:
        eventContent?.view?.type === 'listDay'
          ? moment(
              momentTimeZone(
                eventContent?.view?.getCurrentData()?.dateProfile?.currentRange?.end
              )?.tz('Asia/Kolkata')
            )?.format('YYYY-MM-DD')
          : moment(eventContent?.view?.getCurrentData()?.dateProfile?.currentRange?.end)
              ?.subtract(1, 'days')
              ?.format('YYYY-MM-DD'),
      currentView: eventContent?.view?.type
    };
    if (
      newFilters?.startDate !== filters?.startDate ||
      newFilters?.endDate !== filters?.endDate ||
      newFilters?.currentView !== filters?.currentView
    ) {
      setFilters(newFilters);
      updateEventsList(newFilters);
    }
  };

  const renderEventContent = (eventContent: any) => {
    // const account_manager_name =
    //   eventContent?.event?._def?.extendedProps?.account_manager_name || 'NA';
    const eventManagerNames =
      eventContent?.event?._def?.extendedProps?.event_manager_name?.map((chef: any) => chef) || [];
    const event_manager_name = eventManagerNames.length > 0 ? eventManagerNames.join(', ') : 'NA';
    const headChefsNames =
      eventContent?.event?._def?.extendedProps?.head_chef_staffs_name?.map((chef: any) => chef) ||
      [];
    const head_chef_staffs_name = headChefsNames.length > 0 ? headChefsNames.join(', ') : 'NA';

    return (
      <EventWrapper currentView={filters?.currentView}>
        {(filters?.currentView !== CALENDAR_VIEWS.WEEK || eventContent?.event?._def?.allDay) && (
          <EventTypeIndicator backgroundColor={eventContent?.backgroundColor} />
        )}
        <span
          style={{
            color: eventContent?.event?._def?.allDay && 'black',
            fontWeight: filters?.currentView !== CALENDAR_VIEWS.SCHEDULE ? '600' : '100'
          }}
        >
          {filters?.currentView === CALENDAR_VIEWS.SCHEDULE ? (
            <>
              <strong>{eventContent?.event?.title}</strong>.<br />
              {/* {account_manager_name && (
                <>
                  <strong> Account Manager : </strong>
                  {account_manager_name},
                </>
              )} */}
              {event_manager_name && (
                <>
                  <strong> Event Manager(s) : </strong>
                  {event_manager_name},
                </>
              )}
              {head_chef_staffs_name && (
                <>
                  <strong> Head Chef(s) : </strong>
                  {head_chef_staffs_name}
                </>
              )}
            </>
          ) : (
            eventContent?.event?.title
          )}
        </span>
      </EventWrapper>
    );
  };

  return (
    <PageLayout title={'Dashboard'}>
      {isEventsLoading ? (
        <Flex justifyContent={'center'}>
          <Spin />
        </Flex>
      ) : null}
      <CalendarWrapper filters={filters}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, list]}
          initialView={filters?.currentView}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,listDay,listMonth'
          }}
          buttonText={{
            month: 'Month',
            week: 'Week',
            listDay: 'Day',
            listMonth: 'Schedule'
          }}
          contentHeight="auto"
          eventContent={renderEventContent}
          dayMaxEvents={MAX_EVENTS}
          firstDay={WEEK_START_DAY.MONDAY}
          events={eventList}
          handleWindowResize={true}
          nowIndicator
          eventClick={(e) => {
            const quote_id = e?.event?._def?.extendedProps?.quote_id;
            const event_id = e?.event?._def?.publicId;
            const url = `${
              process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : '/'
            }${event_id ? `events/${event_id}/view` : `quote/view/${quote_id}`}`;
            window.open(url, '_blank');
          }}
          datesSet={(e) => handleUpdateDate(e)}
          ref={(e) => setCalendarRef(e)}
          eventOrder={['order']}
          eventDisplay="list-item"
          allDayText="All Day"
          eventOrderStrict={true}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short',
            hour12: true
          }}
          dayPopoverFormat={{
            month: 'long',
            day: 'numeric',
            weekday: 'short'
          }}
        />
      </CalendarWrapper>
    </PageLayout>
  );
}

export default DashboardPage;
