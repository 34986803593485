import * as Yup from 'yup';
import {
  BundleType,
  MealPlanFormValuesTypeWithDraftStatus,
  ProductType,
  TypeForObjectOfProductTypes
} from '@pages/QuoteManagementPage/types';
import { GetBundlesList, GetProductTypesList } from '@services/quoteServices';
import { showErrorToast, updateRawData } from '@utils/index';
import { Button } from '@atom/Buttons';

export const FORM_INITIAL_VALUES: MealPlanFormValuesTypeWithDraftStatus = {
  id: '',
  unique_invoice_string: '',
  quotation_no: '',
  event_dates: {
    id: '',
    quotation_id: '',
    date_string: '',
    event_date: [new Date().toString()],
    meal_plans: [
      {
        id: '',
        meal_id: '',
        meal_name: '',
        start_time: '',
        end_time: '',
        allsubtotal: '',
        bundle_ids: [],
        bundles: [
          {
            id: '',
            bundle_id: '',
            bundle_name: '',
            hide_price: true,
            bundle_quantity: '',
            bundle_rate: '',
            bundle_subtotal: '',
            bundle_SRP: '',
            special_instruction: '',
            products: [
              {
                id: '',
                product_id: '',
                product_subcategory_id: '',
                product_name: '',
                product_type: 4,
                special_instruction: '',
                portions: '',
                quantity: '',
                rate: '',
                total_cost: '',
                product: {
                  id: null,
                  is_one_time_product: null,
                  product_name: '',
                  product_type: 4,
                  status: 1
                }
              }
            ]
          }
        ],
        products: [
          {
            id: '',
            product_id: '',
            product_subcategory_id: '',
            product_name: 'hhhh',
            product_type: 4,
            special_instruction: '',
            portions: '',
            quantity: '',
            rate: '',
            total_cost: '',
            product: {
              id: null,
              is_one_time_product: 0,
              product_name: '*Cherry Cheesecake',
              product_type: 4,
              status: 1
            }
          }
        ]
      }
    ]
  },
  status: 0

  // event_date: '',
  // status: 0,
  // meals_data: [
  //   {
  //     id: null,
  //     meal_id: Math.floor(Math.random() * 10000000),
  //     meal_name: 'Meal-1',
  //     meal_start_time: '',
  //     meal_end_time: '',
  //     bundle_ids: [],
  //     bundles: [
  //       {
  //         id: null,
  //         bundle_id: '',
  //         bundle_name: '',
  //         hide_individual_prices_on_quotes: false,
  //         bundle_qty: '',
  //         bundle_rate: '',
  //         bundle_total_cost: '',
  //         bundle_SRP: '',
  //         bundle_products: [
  //           {
  //             id: null,
  //             product_id: '',
  //             product_name: 'hhhh',
  //             product_type: 4,
  //             product_special_instruction: '',
  //             portions: '',
  //             product_qty: '',
  //             product_rate: '',
  //             product_total: ''
  //           }
  //         ]
  //       }
  //     ],
  //     products: [
  //       {
  //         id: null,
  //         product_id: '',
  //         product_name: 'hhhh',
  //         product_type: 4,
  //         product_special_instruction: '',
  //         portions: '',
  //         product_qty: '',
  //         product_rate: '',
  //         product_total: ''
  //       }
  //     ]
  //   }
  // ]
};

export const validationSchema = Yup.object({
  event_date: Yup.string().nullable().required('Please select a valid date'),
  meal_name: Yup.string().nullable().required('Please enter valid venue address'),
  start_time: Yup.string().nullable(),
  end_time: Yup.string().nullable(),
  bundles: Yup.array().of(Yup.number().nullable()).nullable()
});

export const fetchAllAvailableBundlesList = async (
  setAvailableBundlesList: React.Dispatch<React.SetStateAction<BundleType[]>>,
  setLoader: React.Dispatch<React.SetStateAction<boolean>>,
  setAddBundle: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoader && setLoader(true);
  const res: any = await GetBundlesList();
  if (res?.success) {
    setAvailableBundlesList(
      res?.data
        ? [bundlesListButton(setAddBundle), ...res?.data]
        : [bundlesListButton(setAddBundle)]
    );
  } else {
    showErrorToast({
      message: 'Something went wrong',
      description: 'Failed to fetch Bundles List.'
    });
  }
  setLoader && setLoader(false);
};

export const fetchAllAvailableProductTypesList = async (
  setAvailableProductTypes: React.Dispatch<React.SetStateAction<TypeForObjectOfProductTypes[]>>,
  setLoader?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoader && setLoader(true);
  const res: any = await GetProductTypesList();
  if (res?.success) {
    setAvailableProductTypes(res?.data);
    updateRawData({ productTypes: res?.data?.filter((i: any) => i?.text !== 'Archived') });
  } else {
    showErrorToast({
      message: 'Something went wrong',
      description: 'Failed to fetch Product Types List.'
    });
  }
  setLoader && setLoader(false);
};

export const getMappedData = (oldValuesObj: any, newValuesObj: any) => {
  return {
    ...oldValuesObj,
    id: newValuesObj?.id || oldValuesObj?.id,
    quotation_no: newValuesObj?.quotation_no || oldValuesObj?.quotation_no,
    status: newValuesObj?.status ?? oldValuesObj?.status,
    unique_invoice_string:
      newValuesObj?.unique_invoice_string || oldValuesObj?.unique_invoice_string,
    event_dates: {
      ...oldValuesObj?.event_dates,
      ...newValuesObj?.event_dates,
      event_date: [newValuesObj?.event_dates?.event_date],
      quotation_event_date_id: newValuesObj?.event_dates?.id || oldValuesObj?.event_dates?.id,
      meal_plans: newValuesObj?.event_dates?.meal_plans?.length
        ? [...newValuesObj?.event_dates?.meal_plans] //@ts-ignore
        : FORM_INITIAL_VALUES?.event_dates?.meal_plans?.map((item: any) => ({
            ...item,
            bundles: [],
            bundle_ids: []
          }))
    }
  };
};

export const costCalculator = (
  qty: string | number = 0,
  rate: string | number = 0,
  hour: string | number = 1
) => {
  const itemQty: number = typeof qty === 'number' ? qty : Number(qty);
  const itemRate: number = typeof rate === 'number' ? rate : Number(rate);
  const itemHour: number = typeof hour === 'number' ? hour : Number(hour);
  const totalCost: number = itemQty * itemRate * itemHour;
  return totalCost;
};

export const bundleSRPCalculatorHandler = (values: any, mealIndex: number, bundleIndex: number) => {
  let net_SRP: number = 0;

  for (
    let i = 0;
    i < values?.event_dates?.meal_plans?.[mealIndex]?.bundles[bundleIndex]?.products?.length;
    i++
  ) {
    const productItem =
      values?.event_dates?.meal_plans?.[mealIndex]?.bundles[bundleIndex]?.products?.[i];
    net_SRP += costCalculator(productItem?.quantity || '0', productItem?.rate || '0');
  }
  return net_SRP;
};

export const mealSubtotalCalculatorHandler = (values: any, mIndex: number) => {
  let net_total: number = 0;
  if (values?.event_dates?.meal_plans?.[mIndex]?.bundles?.length) {
    for (let i = 0; i < values?.event_dates?.meal_plans?.[mIndex]?.bundles?.length; i++) {
      const bundleItem: any = values?.event_dates?.meal_plans?.[mIndex]?.bundles?.[i];
      const bundleItemSubtotal =
        typeof bundleItem?.bundle_subtotal === 'number'
          ? bundleItem?.bundle_subtotal
          : Number(bundleItem?.bundle_subtotal);
      net_total = net_total + bundleItemSubtotal;
    }
  }
  if (values?.event_dates?.meal_plans?.[mIndex]?.products?.length) {
    for (let i = 0; i < values?.event_dates?.meal_plans?.[mIndex]?.products?.length; i++) {
      const productItem: ProductType = values?.event_dates?.meal_plans?.[mIndex]?.products?.[i];
      net_total = net_total + costCalculator(productItem?.quantity, productItem?.rate);
    }
  }
  return net_total;
};

export const duplicateProductErrorMessage =
  'Same product name use is allowed once per meal, Please increase quantity to use same product again.';

export const bundlesListButton = (setAddBundle: React.Dispatch<React.SetStateAction<boolean>>) => {
  return {
    id: -1,
    bundle_name: (
      <Button text={'+ Create New Bundle'} variant="ghost" onClick={() => setAddBundle(true)} />
    )
  };
};

export const checkEmptyProducts = (bundleData: any) => {
  const prods = bundleData?.bundle_products || bundleData?.products;

  const emptyProducts = prods?.filter((product: any) => {
    if (!product?.product_subcategory_id || !product?.product_id) {
      return {
        ...product
      };
    }
  });

  if (emptyProducts?.length) {
    showErrorToast({
      message: 'Failed!',
      description: `Few products have missing details in bundle '${bundleData?.bundle_name}'. Please Check!`
    });
  }
  return emptyProducts?.length;
};
