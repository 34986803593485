import HorizontalTab from '@molecules/HorizontalTabs';
import { getEventDetails } from '@pages/QuoteManagementPage/AddEditQuote/helper';
import React from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { submitForm } from '../AddEditEvent/helper';
import { getEventWithExistingQuoteTabs } from './tabHelpers';
import { showErrorToast } from '@utils/index';

function EventWithExistingQuoteTabs(props: any) {
  const { activePanel } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { formType, quoteId, eventId } = params;
  const onStaffCreated = async (quoteId: string, staffValues: any) => {
    try {
      const eventDetails = await getEventDetails(quoteId);
      navigate(
        `/events/find-quote/create/${quoteId}/${eventDetails?.event_id ?? eventId}/service`,
        {
          state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
        }
      );
    } catch (error) {}
  };
  const onVendorSubmit = async (tabKey: string, values: any, actions: any, method: string) => {
    try {
      const res: any = await submitForm(tabKey, values, method, 0); // from_evnt 0 for with quote event
      navigate(`/events/find-quote/create/${quoteId}/${eventId}/notes`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } catch (error) {}
  };

  const onServiceSubmit = (values: any) => {
    navigate(`/events/find-quote/create/${quoteId}/${eventId}/vendors`, {
      state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
    });
  };

  const onTasksSubmit = (values: any) => {
    navigate(`/events/find-quote/create/${quoteId}/${eventId}/documents`, {
      state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
    });
  };

  const onDocumentSubmit = (values: any) => {
    navigate(`/events/`, {
      state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
    });
  };

  const onNotesSubmit = async (tabKey: string, values: any, actions: any, method: string) => {
    try {
      const res: any = await submitForm(tabKey, values, method, 0); // from_evnt 0 for with quote event
      if (res?.success) {
        navigate(`/events/find-quote/create/${quoteId}/${eventId}/tasks`, {
          state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
        });
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
  };
  const eventWithExistingQuoteTabs = getEventWithExistingQuoteTabs(activePanel, eventId, {
    onStaffCreated,
    onVendorSubmit,
    onServiceSubmit,
    onTasksSubmit,
    onDocumentSubmit,
    onNotesSubmit
  });
  return (
    <HorizontalTab
      value={eventWithExistingQuoteTabs}
      activeKey={formType}
      onChange={(tab: any) => {
        navigate(
          eventId
            ? `/events/find-quote/create/${params.quoteId}/${eventId}/${tab}`
            : `/events/find-quote/create/${params.quoteId}/${tab}`,
          { state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path } }
        );
      }}
    />
  );
}

export default EventWithExistingQuoteTabs;
