import * as yup from 'yup';
import services from '@services/index';
import { ResponseType } from '../helper';

// types
export interface ProductListType {
  id: number | null;
  text: string;
  is_static?: number;
  deleted_at: string | null;
}

export interface ProductTypeProps {
  isEdit: boolean;
}

export interface EditProductTypes {
  product_types: ProductListType[];
}

// api calls
export const getProductTypes = async (): Promise<ResponseType> => {
  try {
    const res = (await services.get(`/settings/product-types`)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const updateProductTypes = async (body: EditProductTypes): Promise<ResponseType> => {
  try {
    const res = (await services.put(`/settings/product-types`, body)) as any;
    return { data: res?.data?.data, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

export const removeProduct = async (id: number): Promise<ResponseType> => {
  try {
    const res = (await services.delete(`/settings/product-types/${id}`)) as any;
    return { data: res?.data?.success, error: null };
  } catch (error: any) {
    return {
      data: null,
      error: error?.error?.message || error?.message || 'Something went wrong!'
    };
  }
};

// yup validations
export const productValidationSchema = yup.object({
  product_types: yup.array().of(
    yup.object({
      text: yup.string().required('Product Type is required.').nullable()
    })
  )
});
