import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { VENUE_MANAGEMENT_TABS, getTabs } from './helper';
import { useNavigate, useParams } from 'react-router';

import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import HorizontalTab from '@molecules/HorizontalTabs';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { useSelector } from 'react-redux';
import { TabWrapper } from '../AddEditPages/style';

function VenueListView() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [tabType, setTabType] = useState<any>();

  const venueManagementTabs = getTabs(tabType);
  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  useEffect(() => {
    if (window.location.pathname === '/venues' || window.location.pathname === '/venues/') {
      navigate(`/venues/tab/${VENUE_MANAGEMENT_TABS.ALL}`);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setTabType(tab);
  }, [tab]);

  return (
    <PageLayout title={'VENUE MANAGEMENT'}>
      <Flex direction="column" flex={1}>
        <PageTitle text="VENUE MANAGEMENT" isBack={false}>
          {isAdmin && <Button text="Add Venue" onClick={() => navigate('/venues/create')} />}
        </PageTitle>
        <Row gutter={24}>
          <Col span={24}>
            <TabWrapper>
              <HorizontalTab
                value={venueManagementTabs}
                activeKey={tabType}
                onChange={(tab: any) => {
                  setTabType(tab);
                  let newurl = `/venues/tab/${tab}`;
                  navigate(newurl);
                }}
              />
            </TabWrapper>
          </Col>
        </Row>
      </Flex>
    </PageLayout>
  );
}

export default VenueListView;
