import store from '@store/index';
import { ConfigProvider } from 'antd';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import RoutesComponent from './configs/routes';
import ErrorBoundary from '@atom/ErrorBoundary';
import useCheckIsOnline from '@hooks/useCheckIsOnline';
import ModalComponent from '@organism/Modal/Modal';
import NetworkModal from '@organism/NetworkModal';
import { showErrorToast } from './utils';

// @ts-ignore
ConfigProvider.config({
  // @ts-ignore
  theme: {
    primaryColor: '#4E1C95'
  },
  prefixCls: 'ant'
});

function App() {
  const isOnline = useCheckIsOnline();
  if (!isOnline) {
    showErrorToast({
      message: `You're offline now`,
      description: 'Opps! Internet is disconnected',
      placement: 'top'
    });
  }
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
      <ConfigProvider>
        <Provider store={store}>
          <ErrorBoundary>
            <RoutesComponent />
            {/* <ModalComponent
              visible={!isOnline}
              modalWidth="auto !important"
              align="left !important">
              <NetworkModal />
            </ModalComponent> */}
          </ErrorBoundary>
        </Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
