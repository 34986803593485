import styled from 'styled-components';

export const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 25px;
    left: 0;
  }
`;
