import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import PageLayout from '@layout/PageLayout';
import HorizontalTab from '@molecules/HorizontalTabs';
import { PageTitle } from '@molecules/PageTitle';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { WORK_ORDER_MANAGEMENT_TABS } from '../helper';
import { getTabs } from './helper';

function WorkOrderListView() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [tabType, setTabType] = useState<string>(tab || WORK_ORDER_MANAGEMENT_TABS.UPCOMING);

  const workOrdersManagementTabs = getTabs(tabType);

  useEffect(() => {
    if (
      window.location.pathname === '/work-orders' ||
      window.location.pathname === '/work-orders/'
    ) {
      navigate(`/work-orders/tab/${WORK_ORDER_MANAGEMENT_TABS.UPCOMING}`);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    tab && setTabType(tab);
  }, [tab]);

  return (
    <PageLayout title={'WORK ORDER MANAGEMENT'}>
      <Flex direction="column" flex={1}>
        <PageTitle text="WORK ORDER MANAGEMENT" isBack={false} bordered={true}></PageTitle>
        <Row gutter={24}>
          <Col span={24}>
            <HorizontalTab
              value={workOrdersManagementTabs}
              activeKey={tabType}
              onChange={(tab: any) => {
                setTabType(tab);
                let newurl = `/work-orders/tab/${tab}`;
                navigate(newurl);
              }}
            />
          </Col>
        </Row>
      </Flex>
    </PageLayout>
  );
}

export default WorkOrderListView;
