import DragNDropWrapper from '@atom/DragNDropWrapper';
import { Flex } from '@atom/Flex';
import { updateRawData } from '@utils/index';
import update from 'immutability-helper';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Meal from './Meal';

const MealSubcategores = ({ mealIndex }: { mealIndex: number }) => {
  const { mealOrders } = useSelector((state: any) => state.rawData);

  const moveMealCard = useCallback(
    (dragIndex: number, hoverIndex: number, mealIndex: number) => {
        const res = update(mealOrders?.[mealIndex]?.subcategories, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, mealOrders?.[mealIndex]?.subcategories[dragIndex]]
          ]
        });
        const newData = [...mealOrders];
        newData[mealIndex].subcategories = res;
        const filteredArray = newData?.filter((item) => item !== undefined);
        updateRawData({ mealOrders: filteredArray });
    },
    [mealOrders]
  );

  return (
    <Flex direction="column" style={{ marginBottom: '30px' }}>
      {mealOrders?.[mealIndex]?.subcategories?.map((item: any, index: number) => (
        <DragNDropWrapper
          key={index}
          index={index}
          isSide={true}
          moveCard={(dragIndex: any, hoverIndex: any) =>
            moveMealCard(dragIndex, hoverIndex, mealIndex)
          }
        >
          <Meal item={item} index={index} />
        </DragNDropWrapper>
      ))}
    </Flex>
  );
};

export default MealSubcategores;
