import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import ModalComponent from '@organism/Modal/Modal';
import { showErrorToast } from '@utils/index';
import { PlayerWrapper } from './style';

interface CustomVideoPlayerProps {
  url?: string;
  directlyShowPlayer?: boolean;
  showModal?: boolean;
  handleClose?: () => void;
}

function CustomVideoPlayer({
  url,
  directlyShowPlayer = false,
  showModal = false,
  handleClose
}: CustomVideoPlayerProps) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const formattedUrl = !url?.includes('http') ? `https://${url}` : url;

  const onCancel = () => {
    setIsModalVisible(false);
    handleClose && handleClose();
  };

  const onError = () => {
    setTimeout(() => {
      setIsModalVisible(false);
      showErrorToast({
        message: 'This seems to be an non-video link',
        description: 'Please copy link and open in new tab'
      });
    }, 1000);
  };

  const CustomReactPlayer = () => {
    return (
      <PlayerWrapper>
        <ReactPlayer
          url={formattedUrl}
          controls
          onError={() => onError()}
          className="react-player"
          width="100%"
          height="100%"
        />
      </PlayerWrapper>
    );
  };

  return (
    <>
      {url && directlyShowPlayer ? (
        <CustomReactPlayer />
      ) : (
        <>
          <a onClick={() => setIsModalVisible(true)}>{!showModal ? formattedUrl : null}</a>
          {(isModalVisible || showModal) && (
            <ModalComponent
              visible={isModalVisible || showModal}
              onCancel={onCancel}
              closable={true}>
              <CustomReactPlayer />
            </ModalComponent>
          )}
        </>
      )}
    </>
  );
}

export default CustomVideoPlayer;
