import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { Col, Row, Select } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { VersionHistoryWrapper } from './style';

interface VersionHistoryProps {
  data: any;
  onVersionUpdate: (id: any) => void;
}
const { Option } = Select;
const VersionHistory = (props: VersionHistoryProps) => {
  const { data, onVersionUpdate } = props;

  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin } = user;

  return (
    <Row gutter={16}>
      <Col xs={24} lg={14}>
        <Label text="Version History" />
        <VersionHistoryWrapper>
          <SelectInput
            id="version_history"
            name="version_history"
            placeholdertitle="Select One"
            value={data?.id}
            onChange={(id: any) => onVersionUpdate(id)}
          >
            <Option
              style={{
                color: '#999',
                fontSize: '16px',
                padding: '6px 6px 6px 12px',
                fontWeight: '400'
              }}
              value="disabled"
              disabled
            >
              <>Select One</>
            </Option>
            {data?.versions?.map((i: any) => (
              <Select.Option key={i?.id} value={i?.id}>
                {moment(i?.created_at).format('ddd, MMMM DD hh:mm A')}
              </Select.Option>
            ))}
          </SelectInput>
        </VersionHistoryWrapper>
      </Col>
    </Row>
  );
};
export default VersionHistory;
