import { Col, Row, Select } from 'antd';
import { useSelector } from 'react-redux';

import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import SelectInput from '@atom/Select';
import {
  checkDuplicate,
  duplicateProductErrorMessage,
  PRODUCT_TYPE_COLORS
} from '@pages/MenuManagementPage/helper';
import { BundleProductFieldsProps } from '@pages/MenuManagementPage/types';
import { useEffect } from 'react';
import { TextWrapper } from './style';
import { getSelectOptions } from '@utils/helper';

function BundleProductFields(props: BundleProductFieldsProps) {
  const { index, handleUpdate, dropdownData, duplicateError, setDuplicateError, formik } = props;
  const { values, touched, errors, handleBlur, setFieldValue } = formik;
  const { productTypes } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    checkDuplicate(values, setDuplicateError);
  }, [values]);

  const updateProduct = (e: any) => {
    handleUpdate(values, setFieldValue, index, e, 'product_id');
    const curProduct =
      e && dropdownData?.products?.find((i: any) => e === i?.product_subcategory_id);
    handleUpdate(values, setFieldValue, index, e, 'product_subcategory_id');
    handleUpdate(
      values,
      setFieldValue,
      index,
      e ? curProduct?.product_id : undefined,
      'product_id'
    );
    handleUpdate(values, setFieldValue, index, e ? 1 : undefined, 'quantity_per_product');
    handleUpdate(
      values,
      setFieldValue,
      index,
      e ? Number(curProduct?.price) : undefined,
      'product_cost'
    );
  };

  const updateProductType = (e: any) => {
    handleUpdate(values, setFieldValue, index, e, 'product_type');
    handleUpdate(values, setFieldValue, index, undefined, 'product_subcategory_id');
    handleUpdate(values, setFieldValue, index, undefined, 'quantity_per_product');
    handleUpdate(values, setFieldValue, index, undefined, 'product_cost');
  };

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} lg={24}>
          <Label isMandatory={true} text="Product Type" />
          <SelectInput
            id={`products?.[${index}]?.product_type`}
            name={`products?.[${index}]?.product_type`}
            placeholdertitle="Select Product Type"
            value={values?.products?.[index]?.product_type}
            error={
              touched?.products?.[index]?.product_type &&
              //@ts-ignore
              errors?.products?.[index]?.product_type
            }
            onChange={updateProductType}
            onBlur={handleBlur}
          >
            {getSelectOptions(productTypes, 'text')}
          </SelectInput>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={24}>
          <TextWrapper>
            <Label isMandatory={true} text="Product Name" />
            <SelectInput
              id={`products?.[${index}]?.product_id`}
              name={`products?.[${index}]?.product_id`}
              placeholdertitle="Type to search for products"
              value={values?.products?.[index]?.product_subcategory_id}
              error={
                (touched['products']?.[index]?.product_id &&
                  errors['products']?.[index]?.product_id) ||
                errors?.[`products.${index}.product_id`]?.[0] ||
                duplicateError?.[index]
              }
              onChange={updateProduct}
              filterOption={(key: any, e: any) =>
                e?.name?.toLowerCase()?.includes(key?.toLowerCase())
              }
              onBlur={handleBlur}
              allowClear={true}
            >
              {dropdownData?.products
                ?.filter((i: any) => i?.product_type === values?.products[index]?.product_type)
                ?.map((item: any) => {
                  return (
                    <Select.Option
                      value={item?.product_subcategory_id}
                      key={item?.product_subcategory_id}
                      name={item?.text || item?.product_name}
                    >
                      <>
                        <span
                          className="text-style"
                          style={{
                            color:
                              PRODUCT_TYPE_COLORS?.[values?.products?.[index]?.product_type] ||
                              'black'
                          }}
                        >
                          {item?.text || item?.product_name}
                        </span>{' '}
                        <span className="text-style" style={{ color: 'red' }}>
                          {' '}
                          - {item?.master_category}/{item?.subcategory}
                        </span>
                      </>
                    </Select.Option>
                  );
                })}
            </SelectInput>
          </TextWrapper>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Label isMandatory={true} text="Quantity" />
          <Input
            name={`products?.[${index}]?.quantity_per_product`}
            id={`products?.[${index}]?.quantity_per_product`}
            error={
              touched?.products?.[index]?.quantity_per_product &&
              //@ts-ignore
              errors?.products?.[index]?.quantity_per_product
            }
            placeholder="0"
            onChange={(e: any) =>
              handleUpdate(values, setFieldValue, index, e.target.value, 'quantity_per_product')
            }
            value={values?.products?.[index]?.quantity_per_product}
            onBlur={handleBlur}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Label isMandatory={true} text="Product Cost" />
          <Input
            name={`products?.[${index}]?.product_cost`}
            id={`products?.[${index}]?.product_cost`}
            error={
              touched?.products?.[index]?.product_cost &&
              //@ts-ignore
              errors?.products?.[index]?.product_cost
            }
            placeholder="$ 0."
            onChange={(e: any) =>
              handleUpdate(values, setFieldValue, index, e.target.value, 'product_cost')
            }
            value={values?.products?.[index]?.product_cost}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Label isMandatory={true} text="Total Product Cost" />
          {values?.products?.[index]?.quantity_per_product &&
          values?.products?.[index]?.product_cost
            ? values?.products?.[index]?.quantity_per_product *
              values?.products?.[index]?.product_cost
            : 0}
        </Col>
      </Row>
    </>
  );
}

export default BundleProductFields;
