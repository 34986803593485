import DropdownButton from '@atom/DropdownButton';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import DataTable from '@organism/DataTable/DataTable';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '@atom/Buttons';
import { TableWrapper } from '@pages/MenuManagementPage/style';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getColumns, getCustomerList, handleDownloadCustomerSheet } from './helper';

function CustomerList() {
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const navigate = useNavigate();

  const { state } = useLocation();
  const goToPageFromLocation = state?.goToPage;

  const user = useSelector((state: { user: { isAdmin: boolean} }) => state.user);
  const { isAdmin } = user;

  const [filterData, setFilterData] = useState<any>({
    name: '',
    company_name: '',
    email: '',
    location: '',
    phone_number: ''
  });

  useEffect(() => {
    setCustomerListData({
      ...filterData,
      page: goToPageFromLocation ? goToPageFromLocation : filterData?.page
    });
    navigate('/customers', { replace: true });
  }, []);

  const setCustomerListData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setIsLoading(true);
      const res = await getCustomerList(newFilters.page, filters);
      setIsLoading(false);
      if (!res.error) {
        setCustomerList(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
    }, 300),
    []
  );

  const onFilterChangeHandler = (e: any) => {
    const { name, value } = e.target as HTMLInputElement;
    const updatedFilters = { ...filterData, [name]: value };
    setFilterData({ ...updatedFilters, page: 1 });
    setCustomerListData(updatedFilters);
  };

  const cols = getColumns(filterData, onFilterChangeHandler);

  return (
    <PageLayout title={'Customer Management'}>
      <PageTitle text={'CUSTOMER MANAGEMENT'}>
        {isAdmin && (
          <Button
            text="Export Customers"
            onClick={() => {
              handleDownloadCustomerSheet();
            }}
          />)}
        <DropdownButton
          text={'Add Customer'}
          options={[
            { key: 'single', label: 'Single Customer' },
            { key: 'bulk', label: 'Bulk Import' }
          ]}
          onSelect={(item) => {
            if (item.key === 'single') {
              navigate('/customers/create');
            } else {
              navigate('/customers/bulk');
            }
          }}
        />
      </PageTitle>
      <TableWrapper>
        <DataTable
          className="fixTD fixTD-mobile"
          columns={cols}
          dataSource={customerList}
          loading={isLoading}
          showLoadMore={!!nextPageUrl}
          pagination={{
            pageSize: pageNo,
            total: totalCount,
            current: filterData?.page,
            onChange: (page: any) => {
              const newFilters = { ...filterData, page: page };
              setFilterData(newFilters);
              setCustomerListData(newFilters);
            }
          }}
          onFetch={() => { }}
          renderEmpty={() => <div>No Customer Found.</div>}
          scrollConfig={{
            y: customerList?.length ? 'calc(100vh - 350px)' : 'auto'
          }}
          onRowClick={(e: any) =>
            navigate(`/customers/${e?.record?.id}/view`, {
              state: { comingFromPage: filterData?.page }
            })
          }
        />
      </TableWrapper>
    </PageLayout>
  );
}

export default CustomerList;
