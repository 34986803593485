import styled from 'styled-components';

export const MealOrderCardWrapper = styled.div`
  .label {
    font-size: 18px;
  }
  .heading {
    margin-left: 5px;
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 14px;
  }
  .sub-heading {
    color: rgb(133, 135, 150);
    font-size: 14px;
  }
`;

export const MealOrderNoteWrapper = styled.div`
  width: 100%;
  .ant-alert-info {
    background-color: white !important;
    border: 1px solid black !important;
  }
`;
