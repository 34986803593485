import { Flex } from '@atom/Flex';
import { ContractViewContainer } from '@pages/LoginPage/styles';
import { showErrorToast } from '@utils/index';
import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getContractViewDetails } from '../SurveyForm/helper';
import NotFoundPage from '@pages/NotFoundPage';

const ContractView = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const { token } = params as { token: string };

  const viewContractDetailMethod = async (token?: string) => {
    try {
      const res = await getContractViewDetails(token!);
      if (res?.success) {
        window.location.replace(res?.data?.url);
        // setLoading(false);
      } else {
        setLoading(false);
        showErrorToast({ message: 'Error!', description: res.error.token });
      }
    } catch (err) {
      setLoading(false);
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
    }
  };

  useEffect(() => {
    viewContractDetailMethod(token);
  }, [token]);

  return (
    <ContractViewContainer>
      {loading ? (
        <Flex justifyContent={'center'}>
          <Spin />
        </Flex>
      ) : (
        <NotFoundPage />
      )}
    </ContractViewContainer>
  );
};

export default ContractView;
