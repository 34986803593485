import { Flex } from '@atom/Flex';
import TableHeader from '@atom/TableHeader';
import { H4Typography } from '@constant/typography/Typography';
import services from '@services/index';

export const AMOUNT_SORT_OPTIONS = [
  {
    title: 'Low - High',
    id: '1'
  },
  {
    title: 'High - Low',
    id: '2'
  },
  {
    title: 'Under $10,000',
    id: '3'
  },
  {
    title: 'Under $50,000',
    id: '4'
  },
  {
    title: 'Over $50,000',
    id: '5'
  }
];
export const getDummyQuotes = () => {
  return Array(10)
    .fill('')
    .map((i: any, index) => {
      //@ts-ignore
      const key = index + 1;
      return {
        quotation_no: key,
        customer_name: 'Customer Name ' + key,
        event_name: 'Event ' + key,
        grand_total: Math.random()
      };
    });
};
export const getQuoteListColumns = (
  filterValue: any,
  onChange: (a: any) => void,
  onAction: (row: any) => void
) => {
  const columns = [
    {
      title: (
        <TableHeader
          columnTitle="Quote Number"
          name="quote_number"
          value={filterValue.quote_number ?? ''}
          placeholder="Quote Number"
          onChange={onChange}
        />
      ),

      dataIndex: 'quotation_no',
      width: '10%'
    },
    {
      title: (
        <TableHeader
          columnTitle="Customer Name"
          name="customer_name"
          value={filterValue.customer_name ?? ''}
          placeholder="Customr Name"
          onChange={onChange}
        />
      ),
      dataIndex: 'customer',
      render: (data: any) => {
        return data?.customer_name;
      },
      width: '10%'
    },
    {
      title: (
        <TableHeader
          columnTitle="Event Name"
          name="event_name"
          value={filterValue.event_name ?? ''}
          placeholder="Event Name"
          onChange={onChange}
        />
      ),
      dataIndex: 'event_name',
      width: '10%'
    },
    {
      title: (
        <TableHeader
          columnTitle="Amount"
          name="total_amount"
          value={filterValue?.total_amount || undefined}
          placeholder="Amount"
          onChange={onChange}
          isSelect
          options={AMOUNT_SORT_OPTIONS}
        />
      ),
      dataIndex: 'grand_total',
      width: '10%'
    },
    {
      title: '',
      dataIndex: '',
      width: '4%',
      render: (row: any) => (
        <Flex
          onClick={() => onAction(row)}
          justifyContent={'center'}
          style={{
            cursor: 'pointer',
            width: '100%'
          }}
        >
          <H4Typography style={{ fontWeight: 400, borderBottom: '1px solid #000' }}>
            SELECT
          </H4Typography>
        </Flex>
      )
    }
  ];
  return columns;
};

export const getQuoteList = async (page: number, filters: any) => {
  try {
    const res = (await services.post(`/events/quotation-list?page=${page || 1}`, {
      search_data: { ...filters }
    })) as any;
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
