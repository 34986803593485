import { Col, Row, Select } from 'antd';
import { useSelector } from 'react-redux';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import SelectInput from '@atom/Select';
import {
  checkDuplicate,
  duplicateProductErrorMessage,
  PRODUCT_TYPE_COLORS
} from '@pages/MenuManagementPage/helper';
import { ExistingProductFieldsProps } from '@pages/MenuManagementPage/types';
import { useEffect } from 'react';
import { ExistingProductFieldsWrapper, TextWrapper } from './style';
import { getSelectOptions } from '@utils/helper';

function ExistingProductFields(props: ExistingProductFieldsProps) {
  const { formik, dropdownData, data, onRemove, index, duplicateError, setDuplicateError } = props;
  const { touched, errors, values, setFieldValue, handleBlur, handleSubmit, handleChange } = formik;
  const { productTypes } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    checkDuplicate(values, setDuplicateError);
  }, [values]);

  const handleUpdate = (data: any, key: any) => {
    const tempData = values?.products ? [...values.products] : [];
    tempData[index][key] = data;
    setFieldValue('products', tempData);
  };

  const updateProduct = (e: any) => {
    handleUpdate(e, 'product_id');
    const curProduct =
      e && dropdownData?.products?.find((i: any) => e === i?.product_subcategory_id);
    handleUpdate(e, 'product_subcategory_id');
    handleUpdate(e ? curProduct?.product_id : undefined, 'product_id');
    handleUpdate(e ? 1 : undefined, 'quantity_per_product');
    handleUpdate(e ? Number(curProduct?.price) : undefined, 'product_cost');
  };

  const updateProductType = (e: any) => {
    handleUpdate(e, 'product_type');
    handleUpdate(undefined, 'product_subcategory_id');
    handleUpdate(undefined, 'quantity_per_product');
    handleUpdate(undefined, 'product_cost');
  };

  return (
    <ExistingProductFieldsWrapper>
      <Row gutter={16}>
        <Col xs={24} lg={20}>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Label isMandatory={true} text="Product Type" />
              <SelectInput
                id={`products?.[${index}]?.product_type`}
                name={`products?.[${index}]?.product_type`}
                placeholdertitle="Select Product Type"
                value={data?.product_type}
                error={
                  touched['products']?.[index]?.product_type &&
                  errors['products']?.[index]?.product_type
                }
                onChange={updateProductType}
                onBlur={handleBlur}
              >
                {getSelectOptions(productTypes, 'text')}
              </SelectInput>
            </Col>
            <Col xs={24} lg={12}>
              <TextWrapper>
                <Label isMandatory={true} text="Product " />
                <SelectInput
                  id={`products?.[${index}]?.product_id`}
                  name={`products?.[${index}]?.product_id`}
                  placeholdertitle="Type to search for products"
                  value={data?.product_subcategory_id}
                  error={
                    (touched['products']?.[index]?.product_id &&
                      errors['products']?.[index]?.product_id) ||
                    errors?.[`products.${index}.product_id`]?.[0] ||
                    duplicateError?.[index]
                  }
                  onChange={updateProduct}
                  filterOption={(key: any, e: any) =>
                    e?.name?.toLowerCase()?.includes(key?.toLowerCase())
                  }
                  onBlur={handleBlur}
                  allowClear={true}
                >
                  {dropdownData?.products
                    ?.filter((i: any) => i?.product_type === data?.product_type)
                    ?.map((item: any) => {
                      return (
                        <Select.Option
                          value={item?.product_subcategory_id}
                          key={item?.product_subcategory_id}
                          name={item?.text || item?.product_name}
                        >
                          <>
                            <span
                              className="text-style"
                              style={{
                                color: PRODUCT_TYPE_COLORS?.[data?.product_type] || 'black'
                              }}
                            >
                              {item?.text || item?.product_name}
                            </span>{' '}
                            <span className="text-style" style={{ color: 'red' }}>
                              {' '}
                              - {item?.master_category}/{item?.subcategory}
                            </span>
                          </>
                        </Select.Option>
                      );
                    })}
                </SelectInput>
              </TextWrapper>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Label isMandatory={true} text="Quantity" />
              <Input
                name={`products?.[${index}]?.quantity_per_product`}
                id={`products?.[${index}]?.quantity_per_product`}
                error={
                  touched['products']?.[index]?.quantity_per_product &&
                  errors['products']?.[index]?.quantity_per_product
                }
                placeholder="0"
                onChange={(e: any) => handleUpdate(e.target.value, 'quantity_per_product')}
                value={data.quantity_per_product}
                onBlur={handleBlur}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Label isMandatory={true} text="Product Cost" />
              <Input
                name={`products?.[${index}]?.product_cost`}
                id={`products?.[${index}]?.product_cost`}
                error={
                  touched['products']?.[index]?.product_cost &&
                  errors['products']?.[index]?.product_cost
                }
                placeholder="$ 0."
                onChange={(e: any) => handleUpdate(e.target.value, 'product_cost')}
                value={data.product_cost}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={4} className="remove-btn">
          <Button
            icon={<MinusCircleOutlined />}
            text=""
            variant="ghost"
            onClick={() => onRemove(data?.id)}
          />
        </Col>
      </Row>
    </ExistingProductFieldsWrapper>
  );
}
export default ExistingProductFields;
