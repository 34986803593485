import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import { forgotPassword } from '@services/userServices';
import { Row } from 'antd';
import * as Yup from 'yup';

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email').required('The email field is required.')
});

export const sendforgotPasswordLink = async (email: string) => {
  try {
    const res = await forgotPassword(email);
    return res;
  } catch (error) {
    throw error;
  }
};

export enum FORGET_PASSWORD_CONTENT_TYPES {
  EMAIL_NOT_SENT = 'email_not_sent',
  EMAIL_SENT = 'email_sent'
}

export const getForgetPasswordContent = (data: {
  email: string | undefined;
  formik: any;
  navigate: any;
  setContentType: any;
}) => {
  const { email, formik, navigate, setContentType } = data;
  const { values, errors, handleSubmit, handleChange } = formik;
  return {
    [FORGET_PASSWORD_CONTENT_TYPES.EMAIL_NOT_SENT]: {
      title: 'Forgot Password?',
      content: (
        <>
          <Flex justifyContent={'center'}>
            <p>We’ll send a link to your registered email ID to recover your password</p>
          </Flex>
          <Row>
            <Flex justifyContent={'center'} flex={1}>
              <Input
                placeholder="Email"
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
              />
            </Flex>
          </Row>

          <Row>
            <Flex flex={1} gap={8} justifyContent={'end'} top={24} className="loginBtn">
              <Button
                text={'Cancel'}
                onClick={() => {
                  formik.resetForm();
                  navigate('/');
                }}
              />
              <Button
                text={'Send'}
                variant="secondary"
                isLoading={formik.isSubmitting}
                onClick={handleSubmit}
              />
            </Flex>
          </Row>
        </>
      )
    },
    [FORGET_PASSWORD_CONTENT_TYPES.EMAIL_SENT]: {
      title: 'A Reset Link Has Been Sent',
      content: (
        <>
          <Flex justifyContent={'center'}>
            <p>
              A link has been sent to:
              <span style={{ fontWeight: 'bold' }}>{` ${email}`}</span>
            </p>
          </Flex>
          <Flex direction="column" alignItems="center">
            Didn’t get an email?
            <Button
              text={<span style={{ textDecoration: 'underline' }}>Try Again</span>}
              variant="ghost"
              onClick={() => setContentType(FORGET_PASSWORD_CONTENT_TYPES.EMAIL_NOT_SENT)}
            />
          </Flex>
          <Row>
            <Flex flex={1} gap={8} justifyContent={'end'} top={50}>
              <Button text={'Sign In'} variant="primary" onClick={() => navigate('/')} />
            </Flex>
          </Row>
        </>
      )
    }
  } as any;
};
