import { Col, Row } from 'antd';
import { Label } from '@atom/FormLable';
import { Value } from '@atom/FormDataView';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getEventDetails } from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { Flex } from '@atom/Flex';
import { updateRawData } from '@utils/index';
import { useSelector } from 'react-redux';
import { EventManagementVenueWrapper } from '../style';
import moment from 'moment';

interface EventBasicDetailsProps {
  eventName?: string;
  eventDate?: string;
  venueName?: string;
  customerName?: string;
  isMealPlan?: boolean;
}

const EventBasicDetails = (props: EventBasicDetailsProps) => {
  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const [showLoading, setShowLoading] = useState(true);
  const { quoteId, formType, form } = useParams();
  const { isMealPlan } = props;

  useEffect(() => {
    if (quoteId) {
      (async () => {
        const res = (await getEventDetails(quoteId)) as any;
        if (res) {
          updateRawData({ eventDetails: { ...res } });
        }
        setShowLoading(false);
      })();
    } else {
      setShowLoading(false);
    }
  }, [quoteId, formType, form]);

  const getEventDate = (date: string) => {
    if (!date || date === '0000-00-00') {
      return '--';
    } else {
      return moment(date).format('ddd, MMMM DD');
    }
  };

  return (
    <EventManagementVenueWrapper>
      <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
        {showLoading ? (
          <Flex justifyContent={'center'} style={{ width: '100%' }}>
            <CenterSpiner />
          </Flex>
        ) : (
          <>
            <Col md={8} xs={24}>
              <Label text="EVENT NAME" />
              <Value>{details?.event_name || '-'}</Value>
            </Col>
            <Col md={8} xs={isMealPlan ? 24 : 12}>
              <Label text="EVENT DATE" />
              <Value>{getEventDate(details?.event_date)}</Value>
            </Col>
            <Col md={4} xs={isMealPlan ? 24 : 12}>
              <Label text="START TIME" />
              <Value>
                {(details?.start_time &&
                  moment(details?.start_time, 'HH:mm:ss').format('hh:mm A')) ||
                  '-'}
              </Value>
            </Col>
            <Col md={4} xs={isMealPlan ? 24 : 12}>
              <Label text="END TIME" />
              <Value>
                {(details?.end_time && moment(details?.end_time, 'HH:mm:ss').format('hh:mm A')) ||
                  '-'}
              </Value>
            </Col>
            <Col md={8} xs={isMealPlan ? 24 : 12}>
              <Label text="VENUE NAME" />
              <Value>{details?.venue_name || '-'}</Value>
            </Col>
            <Col md={8} xs={24}>
              <Label text="CUSTOMER NAME" />
              <Value>{details?.customer_name || '-'}</Value>
            </Col>
          </>
        )}
      </Row>
    </EventManagementVenueWrapper>
  );
};

export default EventBasicDetails;
