import React, { useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4Typography } from '@constant/typography/Typography';
import {
  EditIngredientType,
  getMenuDetails,
  IngredientListType,
  ingredientsValidationSchema,
  updateMenuNames
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';

import { Formik, FormikProps } from 'formik';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';
import MenuManagementEditableVirtualList from './MenuManagementEditableVirtualList';
import ScrollToFieldError from '@utils/formikFocus';

const Ingredients = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [ingredientsList, setIngredientsList] = useState<IngredientListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<IngredientListType[] | null>(null);
  const [scrollToBottomOfList, setScrollToBottomOfList] = useState<boolean>(false);

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getIngredientsHandler();
  }, []);

  const getIngredientsHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getMenuDetails('ingredients');
    if (!result.error) {
      setIngredientsList(result.data || []);
    }
    setPageLoading(false);
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getIngredientsHandler();
    onModalCancelHandler();
  };

  const onIngredientDelete = (
    index: number,
    ingredientsArray: any,
    setFieldValue: any,
    id?: number | null,
    is_associated?: boolean
  ) => {
    if (is_associated) {
      showErrorToast({
        message: 'Ingredient is used with product so it will not deleted',
        description: ''
      });
      return false;
    } else {
      if (id) {
        showToast({ message: 'Ingredient Removed Successfully', description: '' });
      }
      const tempArr = [...JSON.parse(JSON.stringify(ingredientsArray))];
      tempArr?.splice(index, 1);
      setFieldValue('ingredients', tempArr);
      return true;
    }
  };

  const onSubmitHandler = (values: EditIngredientType) => {
    setSubmitValue(values?.ingredients || []);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateMIngredientsHandler = async () => {
    if (!submitValue) return;
    setLoading(true);
    const deletedIngs: IngredientListType[] = [];
    let updatedIngredientsToSubmit: IngredientListType[] = [];

    ingredientsList?.forEach((ing: IngredientListType) => {
      const indexOfItem = submitValue?.findIndex((val: IngredientListType) => val?.id === ing?.id);
      if (indexOfItem >= 0) {
        if (ing?.name !== submitValue?.[indexOfItem]?.name) {
          updatedIngredientsToSubmit?.push(submitValue?.[indexOfItem]);
        }
      } else {
        deletedIngs.push(ing);
      }
    });
    updatedIngredientsToSubmit = [
      ...updatedIngredientsToSubmit,
      ...submitValue?.filter?.((item: IngredientListType) => !item?.id)
    ];

    // const result = await updateMenuNames({ ingredients: submitValue }, 'ingredients');
    const result = await updateMenuNames(
      { save_ingredients: updatedIngredientsToSubmit, delete_ingredients: deletedIngs },
      'ingredients'
    );
    setLoading(false);
    if (!result.error) {
      showToast({ message: 'Ingredients Updated Successfully', description: '' });
      onCancelHandler();
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Ingredients',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Flex bottom={26}>
          <H4Typography>MANAGE INGREDIENTS</H4Typography>
        </Flex>
        <Formik
          initialValues={{ ingredients: ingredientsList }}
          validationSchema={ingredientsValidationSchema}
          onSubmit={onSubmitHandler}
        >
          {(formikProps: FormikProps<any>) => {
            const { values, setFieldValue, handleSubmit } = formikProps;
            return (
              <Flex direction="column">
                <ScrollToFieldError formik={formikProps} />
                <MenuManagementEditableVirtualList
                  formik={formikProps}
                  onItemDelete={onIngredientDelete}
                  fieldArrayName="ingredients"
                  inputFieldPlaceHolder="Ingredient Name"
                  scrollToBottomOfList={scrollToBottomOfList}
                  setScrollToBottomOfList={setScrollToBottomOfList}
                />
                <Flex bottom={24}>
                  <Button
                    text="Add Ingredient"
                    variant="secondary"
                    icon={<img width={20} src={PlusCircle} alt="add" />}
                    onClick={() => {
                      setFieldValue('ingredients', [...values.ingredients, { name: '', id: '' }]);
                      setScrollToBottomOfList(true);
                    }}
                  />
                </Flex>
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8} className="fix-mobile">
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button text="Submit" onClick={handleSubmit} isLoading={loading} />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Saving will impact existing entries."
          onOK={updateMIngredientsHandler}
          isModalLoading={loading}
          okText="Okay"
        >
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <div>
      <Flex bottom={26}>
        <H4Typography>MANAGE INGREDIENTS</H4Typography>
      </Flex>
      <Flex style={{ flexFlow: 'wrap', gap: '16px 40px' }}>
        {ingredientsList.map((ing) => (
          <Flex key={ing.id} style={{ display: 'contents', whiteSpace: 'pre-wrap' }}>
            <p
              style={{
                backgroundColor: '#F4F4F4',
                borderRadius: '5px',
                padding: '5px 10px',
                margin: 0
              }}
            >
              {ing.name}
            </p>
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

export default Ingredients;
