export const SpecialIngredientModalInitialValues = {
  id: null,
  name: '',
  yields: '',
  measurement: null,
  measurement_name: '',
  ingredients: [
    {
      ingredient: null,
      ingredient_id: null,
      ingredient_name: '',
      amount: null,
      measurement_id: null,
      measurement: '',
      prep_list_id: null,
      prep_list: '',
      is_special_ingredient: 0
    }
  ]
};

export const getSpecialIngredientMappedValues = (data: any) => {
  return {
    id: data?.id ?? null,
    name: data?.name ?? '',
    yields: data?.yields ?? '',
    measurement: data?.measurement ?? null,
    measurement_name: data?.measurement_detail?.name ?? '',
    ingredients: data?.ingredient_mappings?.length
      ? data?.ingredient_mappings?.map((ing: any) => ({
          ingredient: ing?.master_ingredient,
          ingredient_id: ing?.ingredient_id,
          ingredient_name: ing?.master_ingredient?.name,
          amount: ing?.amount,
          measurement_id: ing?.measurement_id,
          measurement: ing?.special_ingredient_measurement_detail?.name,
          prep_list_id: ing?.prep_list_id,
          prep_list: ing?.prep_detail.name,
          is_special_ingredient: ing?.master_ingredient?.is_special_ingredient
        }))
      : []
  };
};
