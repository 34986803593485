import { CloseOutlined } from '@ant-design/icons';
import { PdfViewer } from '@atom/PdfViewer';
import { updateRawData } from '@utils/index';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';

function SurveyFormPdfViewer() {
  const { showSurveyForm } = useSelector((state: any) => state.rawData);

  const { pdfUrl } = useSelector((state: any) => state.rawData);

  const onClose = () => {
    updateRawData({ showSurveyForm: false });
  };

  return (
    <Drawer
      closeIcon={<CloseOutlined style={{ stroke: 'black', strokeWidth: '90px' }} />}
      placement={'right'}
      closable={true}
      onClose={onClose}
      open={showSurveyForm}
      key={'right'}
      width={'70%'}
      bodyStyle={{ padding: '0px' }}
    >
      <PdfViewer height="100%" fileUrl={pdfUrl} isLoading={false} />
    </Drawer>
  );
}

export default SurveyFormPdfViewer;
