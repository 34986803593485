import Error from '@atom/Error';
import { Flex } from '@atom/Flex';
import { Label } from '@atom/FormLable';
import RadioButton from '@atom/RadioButton';
import { TextAreaInput } from '@atom/TextArea';
import { Radio } from 'antd';
import { INPUT_TYPE } from './helper';
import { RadioButtonWrapper, TextAreaInputWrapper } from './style';

function QuestionItem(props: QuestionItemProps) {
  const { question, type, id, formik } = props;

  const { values, handleChange, errors, handleBlur, touched } = formik;

  return (
    <Flex style={{ height: '100%' }} direction="column" justifyContent="space-between">
      <Label
        style={{ fontSize: '16px', fontWeight: '800', textTransform: 'uppercase' }}
        text={question}
        isMandatory={type === INPUT_TYPE.RADIO_INPUT}
      />
      {type === INPUT_TYPE.RADIO_INPUT && (
        <div>
          <RadioButtonWrapper>
            <RadioButton
              name={`${id}_response`}
              value={values[`${id}_response`]}
              id={`${id}_response`}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </RadioButton>
            <span className="errormsg" style={{ color: 'red' }}>
              <Error error={touched[`${id}_response`] && errors[`${id}_response`]} />
            </span>
          </RadioButtonWrapper>
          <Label
            style={{ fontSize: '16px', fontWeight: '800', textTransform: 'uppercase' }}
            text={`If any answers were listed "no" above, please explain?`}
          />
        </div>
      )}
      <TextAreaInputWrapper>
        <TextAreaInput
          className="textAreaInput"
          id={`${id}_text`}
          value={values[`${id}_text`]}
          name={`${id}_text`}
          onChange={handleChange}
          maxLength={1200}
          onBlur={handleBlur}
        />
      </TextAreaInputWrapper>
    </Flex>
  );
}

export default QuestionItem;
