import { Col, Row, Select } from 'antd';
import { useEffect } from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import SelectInput from '@atom/Select';
import {
  checkDuplicate,
  duplicateProductErrorMessage,
  PRODUCT_TYPE_COLORS
} from '@pages/MenuManagementPage/helper';
import { ProductDataFieldsProps } from '@pages/MenuManagementPage/types';

import { deleteBundleProduct } from './helper';
import { ArrowComponentWrapper } from '../AddEditProduct/style';
import { CloseButtonWrapper } from '@pages/MenuManagementPage/style';
import { getSelectOptions } from '@utils/helper';
import { Flex } from '@atom/Flex';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';
import { DEVICE } from '@constants/index';

function ProductDataFields(props: ProductDataFieldsProps) {
  const { item, index, formik, dropdownData, duplicateError, setDuplicateError } = props;
  const { values, handleBlur, setFieldValue, setTouched, setErrors, touched, errors } = formik;

  const device = useWindowSizeChangedforGrid();

  useEffect(() => {
    checkDuplicate(values, setDuplicateError);
  }, [values]);

  const handleUpdate = (data: any, key: any) => {
    const tempData = values?.products ? [...values.products] : [];
    tempData[index][key] = data;
    setFieldValue('products', tempData);
  };

  const handleRemove = () => {
    setFieldValue(
      'products',
      values?.products?.filter((i: any) =>
        item?.id ? i?.id != item?.id : i?.tempId != item?.tempId
      )
    );
    setTouched({});
    // if (item?.id) {
    //   deleteBundleProduct(item?.id);
    // }
  };

  const updateProductType = (e: any) => {
    handleUpdate(e, 'product_type');
    handleUpdate(undefined, 'product_subcategory_id');
    handleUpdate(undefined, 'quantity_per_product');
    handleUpdate(undefined, 'product_cost');
  };

  // const handleUpdateError = (key: string, message: string) => {
  //   const tempError = errors ? { ...errors } : {}
  //   tempError.products =
  //     tempError?.products || Array.from({ length: index + 1 }, () => ({}));
  //   tempError.products =
  //     index < tempError?.products?.length
  //       ? tempError?.products
  //       : [
  //         ...tempError?.products,
  //         ...Array.from({ length: index - (length - 1) }, () => ({}))
  //       ];

  //   tempError.products[index][key] = message;
  //   setErrors({...tempError})
  // }

  const handleUpdateBlur = (key: any) => {
    const temptouched: any = touched ? { ...touched } : {};
    temptouched.products = temptouched?.products || Array.from({ length: index + 1 }, () => ({}));
    temptouched.products =
      index < temptouched?.products?.length
        ? temptouched?.products
        : [...temptouched?.products, ...Array.from({ length: index - (length - 1) }, () => ({}))];
    temptouched.products[index][key] = true;
    setTouched(temptouched);
  };

  const updateProduct = (e: any) => {
    const curProduct =
      e && dropdownData?.productsList?.find((i: any) => e === i?.product_subcategory_id);

    handleUpdate(e, 'product_subcategory_id');
    handleUpdate(e ? curProduct?.product_id : undefined, 'product_id');
    handleUpdate(e ? 1 : undefined, 'quantity_per_product');
    handleUpdate(e ? Number(curProduct?.price) : undefined, 'product_cost');
  };

  useEffect(() => {
    const tempData = values?.products ? [...values.products] : [];
    tempData[index].total_cost =
      Number(values?.products?.[index]?.quantity_per_product || 0) *
      Number(values?.products?.[index]?.product_cost || 0);
    setFieldValue('products', tempData);
  }, [values?.products?.[index]?.quantity_per_product, values?.products?.[index]?.product_cost]);

  const ProductDataFieldsForDesktop = () => {
    const { index } = props;
    return (
      <Row gutter={16}>
        <Col xs={6} md={6}>
          {!index && <Label isMandatory={true} text="PRODUCT TYPES" />}
          <ArrowComponentWrapper>
            <SelectInput
              id={`products[${index}]?.product_type`}
              name={`products[${index}]?.product_type`}
              placeholdertitle="Product Type"
              value={item?.product_type}
              error={
                (touched['products']?.[index]?.product_type &&
                  errors['products']?.[index]?.product_type) ||
                errors?.[`products.${index}.product_type`]?.[0]
              }
              onChange={updateProductType}
              onBlur={() => handleUpdateBlur('product_type')}>
              {getSelectOptions(dropdownData?.productTypeList, 'text')}
            </SelectInput>
          </ArrowComponentWrapper>
        </Col>
        <Col xs={6} md={6}>
          {!index && <Label isMandatory={true} text="PRODUCTS" />}
          <ArrowComponentWrapper>
            <SelectInput
              id={`products[${index}]?.product_id`}
              name={`products[${index}]?.product_id`}
              placeholdertitle="Type to Search Products"
              value={item?.product_subcategory_id}
              error={
                (touched['products']?.[index]?.product_id &&
                  errors['products']?.[index]?.product_id) ||
                errors?.[`products.${index}.product_id`]?.[0] ||
                duplicateError?.[index]
              }
              filterOption={(key: any, e: any) =>
                e?.name?.toLowerCase()?.includes(key?.toLowerCase())
              }
              onChange={updateProduct}
              onBlur={() => handleUpdateBlur('product_id')}
              allowClear={true}>
              {dropdownData?.productsList
                ?.filter((i: any) => i?.product_type === item?.product_type)
                ?.map((item: any) => {
                  return (
                    <Select.Option
                      value={item?.product_subcategory_id}
                      key={item?.product_subcategory_id}
                      name={item?.text}>
                      <>
                        <span
                          className="text-style"
                          style={{ color: PRODUCT_TYPE_COLORS?.[item?.product_type] || 'black' }}>
                          {item?.text}
                        </span>{' '}
                        <span className="text-style" style={{ color: 'red' }}>
                          {' '}
                          - {item?.master_category}/{item?.subcategory}
                        </span>
                      </>
                    </Select.Option>
                  );
                })}
            </SelectInput>
          </ArrowComponentWrapper>
        </Col>

        <Col xs={4} md={4} />
        <Col xs={2} md={2}>
          {!index && <Label isMandatory={true} text="QTY" />}
          <Input
            name={`products[${index}]?.quantity_per_product`}
            id={`products[${index}]?.quantity_per_product`}
            error={
              touched['products']?.[index]?.quantity_per_product &&
              errors['products']?.[index]?.quantity_per_product
            }
            placeholder="0"
            onChange={(e: any) => handleUpdate(e?.target.value, 'quantity_per_product')}
            value={item.quantity_per_product}
            onBlur={() => handleUpdateBlur('quantity_per_product')}
          />
        </Col>
        <Col xs={2} md={2}>
          {!index && <Label isMandatory={true} text="RATE" />}
          <Input
            name={`products[${index}]?.product_cost`}
            id={`products[${index}]?.product_cost`}
            error={
              touched['products']?.[index]?.product_cost &&
              errors['products']?.[index]?.product_cost
            }
            placeholder="0"
            onChange={(e: any) => handleUpdate(e?.target.value, 'product_cost')}
            value={item.product_cost}
            onBlur={() => handleUpdateBlur('product_cost')}
          />
        </Col>
        <Col xs={2} md={2}>
          {!index && <Label isMandatory={false} text="TOTAL" />}
          <div style={{ height: '48px', alignItems: 'center', display: 'flex' }}>
            {`$ ${item?.total_cost || 0}`}{' '}
          </div>
        </Col>
        <CloseButtonWrapper>
          <Col
            className="ghost-btn-style"
            xs={2}
            md={2}
            style={{ paddingTop: !index ? '54px' : '10px' }}>
            {values?.products?.length > 1 ? (
              <Button
                variant="ghost"
                icon={<MinusCircleOutlined />}
                onClick={() => handleRemove()}
              />
            ) : null}
          </Col>
        </CloseButtonWrapper>
      </Row>
    );
  };

  const ProductDataFieldsForMobile = () => {
    return (
      <Flex className="productdata-mobilefixing" style={{ columnGap: '20px', display: 'flex' }}>
        <Flex direction="column" style={{ width: '150px' }}>
          {!index && <Label isMandatory={true} text="PRODUCT TYPES" />}
          <ArrowComponentWrapper>
            <SelectInput
              id={`products[${index}]?.product_type`}
              name={`products[${index}]?.product_type`}
              placeholdertitle="Product Type"
              value={item?.product_type}
              error={
                (touched['products']?.[index]?.product_type &&
                  errors['products']?.[index]?.product_type) ||
                errors?.[`products.${index}.product_type`]?.[0]
              }
              onChange={updateProductType}
              onBlur={() => handleUpdateBlur('product_type')}>
              {getSelectOptions(dropdownData?.productTypeList, 'text')}
            </SelectInput>
          </ArrowComponentWrapper>
        </Flex>
        <Flex direction="column" style={{ width: '150px' }}>
          {!index && <Label isMandatory={true} text="PRODUCTS" />}
          <ArrowComponentWrapper>
            <SelectInput
              id={`products[${index}]?.product_id`}
              name={`products[${index}]?.product_id`}
              placeholdertitle="Type to Search Products"
              value={item?.product_subcategory_id}
              error={
                (touched['products']?.[index]?.product_id &&
                  errors['products']?.[index]?.product_id) ||
                errors?.[`products.${index}.product_id`]?.[0] ||
                duplicateError?.[index]
              }
              filterOption={(key: any, e: any) =>
                e?.name?.toLowerCase()?.includes(key?.toLowerCase())
              }
              onChange={updateProduct}
              onBlur={() => handleUpdateBlur('product_id')}
              allowClear={true}>
              {dropdownData?.productsList
                ?.filter((i: any) => i?.product_type === item?.product_type)
                ?.map((item: any) => {
                  return (
                    <Select.Option
                      value={item?.product_subcategory_id}
                      key={item?.product_subcategory_id}
                      name={item?.text}>
                      <>
                        <span
                          className="text-style"
                          style={{ color: PRODUCT_TYPE_COLORS?.[item?.product_type] || 'black' }}>
                          {item?.text}
                        </span>{' '}
                        <span className="text-style" style={{ color: 'red' }}>
                          {' '}
                          - {item?.master_category}/{item?.subcategory}
                        </span>
                      </>
                    </Select.Option>
                  );
                })}
            </SelectInput>
          </ArrowComponentWrapper>
        </Flex>

        <Flex direction="column" style={{ width: '100px' }} />
        <Flex direction="column" style={{ width: '90px' }}>
          {!index && <Label isMandatory={true} text="QTY" />}
          <Input
            name={`products[${index}]?.quantity_per_product`}
            id={`products[${index}]?.quantity_per_product`}
            error={
              touched['products']?.[index]?.quantity_per_product &&
              errors['products']?.[index]?.quantity_per_product
            }
            placeholder="0"
            onChange={(e: any) => handleUpdate(e?.target.value, 'quantity_per_product')}
            value={item.quantity_per_product}
            onBlur={() => handleUpdateBlur('quantity_per_product')}
          />
        </Flex>
        <Flex direction="column" style={{ width: '90px' }}>
          {!index && <Label isMandatory={true} text="RATE" />}
          <Input
            name={`products[${index}]?.product_cost`}
            id={`products[${index}]?.product_cost`}
            error={
              touched['products']?.[index]?.product_cost &&
              errors['products']?.[index]?.product_cost
            }
            placeholder="0"
            onChange={(e: any) => handleUpdate(e?.target.value, 'product_cost')}
            value={item.product_cost}
            onBlur={() => handleUpdateBlur('product_cost')}
          />
        </Flex>
        <Flex direction="column" style={{ width: '50px' }}>
          {!index && <Label isMandatory={false} text="TOTAL" />}
          <div style={{ height: '48px', alignItems: 'center', display: 'flex', minWidth: '50px' }}>
            {`$ ${item?.total_cost || 0}`}{' '}
          </div>
        </Flex>
        <CloseButtonWrapper>
          {!index && <Label style={{ marginTop: '45px' }} text="" />}
          <Flex
            direction="column"
            className="ghost-btn-style"
            style={{
              paddingTop: !index ? '54px' : '10px',
              width: '50px'
            }}>
            {values?.products?.length > 1 ? (
              <Button
                variant="ghost"
                icon={<MinusCircleOutlined />}
                onClick={() => handleRemove()}
              />
            ) : null}
          </Flex>
        </CloseButtonWrapper>
      </Flex>
    );
  };

  if (device === 'desktop') {
    return ProductDataFieldsForDesktop();
  } else {
    return ProductDataFieldsForMobile();
  }
}

export default ProductDataFields;
