import { Spin } from 'antd';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { H1Typography } from '@constant/typography/Typography';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import {
  getProduct,
  INGREDIENT_FORM_STATUS,
  MENU_MANAGEMENT_TABS,
  PRODUCT_STATUS
} from '@pages/MenuManagementPage/helper';
import { showErrorToast, showToast, updateRawData } from '@utils/index';

import { addEditProduct, addEditProductValidations, getInitValues } from './helper';
import IngredientDietAndVideoDetailsSec from './IngredientDietAndVideoDetailsSec';
import MenuAndProductDetailsSec from './MenuAndProductDetailsSec';
import PriceImagesAndBundleDetailsSec from './PriceImagesAndBundleDetailsSec';
import ScrollToFieldError from '@utils/formikFocus';
import ConfirmModal from '@organism/ConfimationModal';
import { ButtonWrapper } from './style';

function AddEditProduct() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [isProductloading, setIsProductLoading] = useState(true);
  const [productData, setProductdata] = useState<any>({});
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const { ingredients } = useSelector((state: any) => state.rawData);
  const [initialValues, setInitialValues] = useState<any>({});
  const [isProductSubmit, setProductSubmit] = useState<boolean>(false);
  const [isIngredientWarningModalActive, setIsIngredientWarningModalActive] = useState(false);

  const { ingredientsFormState } = useSelector((state: any) => state?.rawData);

  const ingredientRef = useRef<any>(null);
  const submitIngredients = () => {
    !!ingredientRef && ingredientRef?.current?.handleSubmit();
  };

  useEffect(() => {
    updateInitialValues();
  }, []);

  useEffect(() => {
    return () => {
      updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
    };
  }, []);

  const updateInitialValues = async () => {
    setIsProductLoading(true);
    let res = {} as any;
    if (id) {
      try {
        res = await getProduct(id);
        if (res?.success) {
          setProductdata(res?.data);
        }
      } catch (error) {
        showErrorToast({
          message: 'Failed!',
          description: 'Filed to fetch Product details'
        });
      }
    }
    const initVal = getInitValues(id && res?.success ? res?.data : null);
    setInitialValues(initVal);
    setIsProductLoading(false);
  };

  const onSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    try {
      const res: any = await addEditProduct(
        ingredients,
        {
          ...values,
          status:
            productData?.status == PRODUCT_STATUS.ACTIVE ||
            productData?.status == PRODUCT_STATUS.INACTIVE
              ? productData?.status
              : PRODUCT_STATUS.ACTIVE
        },
        id
      );
      if (res?.success) {
        actions.resetForm();
        navigate(`/menu/tab/${tab || MENU_MANAGEMENT_TABS.ACTIVE}`);
        showToast({
          message: 'Success!',
          description: 'Product Added.'
        });
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    actions.setSubmitting(false);
  };

  const validateForm = (values: any, actions: any) => {
    const { menus, product_name, category, sub_categories } = values;
    const errorKeys = Object.keys(actions?.errors || {});
    const isMandatoryFieldsMissing = !menus || !product_name || !category || !sub_categories;
    const mandatoryKeys = ['menus', 'product_name', 'category', 'sub_categories'];
    let tempTouched = { ...actions?.touched };
    if (errorKeys?.length) {
      errorKeys?.forEach((i: any) => {
        if (i === 'price_descriptions') {
          tempTouched[i] = actions?.errors?.price_descriptions?.map((i: any) => ({
            sub_category_id: true,
            name: true,
            price: true
          }));
        } else if (i === 'bundles') {
          tempTouched[i] = actions?.errors?.bundles?.map((i: any) => ({
            product_cost: true,
            quantity_per_product: true
          }));
        } else {
          tempTouched[i] = true;
        }
      });
    } else if (isMandatoryFieldsMissing) {
      Object.keys(values)?.forEach((i: any) => {
        if (!values?.[i] && mandatoryKeys?.includes(i)) {
          tempTouched[i] = true;
        }
      });
    }
    actions.setTouched(tempTouched);
    return !!errorKeys?.length || isMandatoryFieldsMissing;
  };

  const handleSaveDraft = async (values: any, actions: any) => {
    const isErrors = validateForm(values, actions);
    if (!isErrors) {
      setIsSubmittingDraft(true);
      try {
        const res: any = await addEditProduct(
          ingredients,
          { ...values, status: PRODUCT_STATUS.DRAFT },
          id
        );
        if (res?.success) {
          actions.resetForm();
          navigate(`/menu/tab/${tab || MENU_MANAGEMENT_TABS.ACTIVE}`);
          showToast({
            message: 'Success!',
            description: 'Product Saved as Draft.'
          });
        } else {
          showErrorToast({
            message: 'Failed!',
            description: 'Something went wrong'
          });
        }
      } catch (error) {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
      setIsSubmittingDraft(false);
    } else {
      showErrorToast({
        message: 'Failed!',
        description: 'Please resolve form error'
      });
    }
  };

  const onWarningModalCancel = (handleSubmit: () => void) => {
    updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
    setIsIngredientWarningModalActive(false);
    handleSubmit && handleSubmit();
  };

  const savedProgress = () => {
    submitIngredients && submitIngredients();
    setIsIngredientWarningModalActive(false);
  };

  const submitHandler = (handleSubmit: () => void) => {
    if (ingredientsFormState && ingredientsFormState === INGREDIENT_FORM_STATUS.MODIFIED) {
      setIsIngredientWarningModalActive(true);
    } else {
      updateRawData({ ingredientsFormState: INGREDIENT_FORM_STATUS.UNCHANGED });
      handleSubmit && handleSubmit();
    }
  };

  return (
    <PageLayout>
      {isProductloading ? (
        <Spin />
      ) : (
        <>
          <Flex direction="column" style={{ width: '100%' }}>
            <PageTitle
              text="PRODUCT LIST"
              bordered={true}
              isBack={true}
              path={`/menu/tab/${MENU_MANAGEMENT_TABS.ACTIVE}`}
            />

            <Formik
              initialValues={initialValues}
              validationSchema={addEditProductValidations()}
              onSubmit={(values, actions) => {
                onSubmit(values, actions);
              }}>
              {(props) => {
                const { values, errors, handleSubmit } = props;
                return (
                  <>
                    <ButtonWrapper>
                      <Flex justifyContent={'space-between'}>
                        <H1Typography>{`${id ? 'EDIT' : 'ADD'} PRODUCT`}</H1Typography>
                        <div className="hide-mobile-device">
                          <Flex className="footerButton fix-mobile">
                            <Button
                              variant="secondary"
                              text="Cancel"
                              onClick={() => navigate(`/menu/tab/${MENU_MANAGEMENT_TABS.ALL}`)}
                              isDisable={isSubmittingDraft || props.isSubmitting}
                            />
                            <Button
                              variant="secondary"
                              text="Save as Draft"
                              onClick={() => handleSaveDraft(values, props)}
                              isLoading={isSubmittingDraft}
                              isDisable={isSubmittingDraft || props.isSubmitting}
                            />
                            <Button
                              variant="primary"
                              size="large"
                              text="Submit"
                              onClick={() => !errors.products && submitHandler(handleSubmit)}
                              isLoading={props.isSubmitting}
                              isDisable={isSubmittingDraft || props.isSubmitting || !isProductSubmit}
                            />
                          </Flex>
                        </div>
                      </Flex>
                    </ButtonWrapper>
                    <Flex direction="column" top={30}>
                      <ScrollToFieldError formik={props} />
                      <MenuAndProductDetailsSec
                      setProductSubmit={setProductSubmit}
                        formik={props}
                        ingredientRef={ingredientRef}
                        submitIngredients={submitIngredients}
                      />
                      <IngredientDietAndVideoDetailsSec formik={props} />
                      <PriceImagesAndBundleDetailsSec formik={props} />
                      <Flex className="footerButton fix-mobile hide-full-device">
                        <Button
                          variant="secondary"
                          text="Cancel"
                          onClick={() => navigate(`/menu/tab/${MENU_MANAGEMENT_TABS.ALL}`)}
                          isDisable={isSubmittingDraft || props.isSubmitting}
                        />
                        <Button
                          variant="secondary"
                          text="Save as Draft"
                          onClick={() => handleSaveDraft(values, props)}
                          isLoading={isSubmittingDraft}
                          isDisable={isSubmittingDraft || props.isSubmitting}
                        />
                        <Button
                          variant="primary"
                          size="large"
                          text="Submit"
                          onClick={() => !errors.products && submitHandler(handleSubmit)}
                          isLoading={props.isSubmitting}
                          isDisable={isSubmittingDraft || props.isSubmitting}
                        />
                      </Flex>
                      {!!isIngredientWarningModalActive && (
                        <ConfirmModal
                          visible={isIngredientWarningModalActive}
                          onCancel={() => onWarningModalCancel(handleSubmit)}
                          title="You are about to leave the Ingredient form"
                          onOK={savedProgress}
                          // isModalLoading={loading}
                          cancelText={'Leave'}
                          okText="Save">
                          <span>
                            It looks like you have not saved the ingredients of the sub category.
                            Make sure you save your progress before submitting the menu.
                          </span>
                        </ConfirmModal>
                      )}
                    </Flex>
                  </>
                );
              }}
            </Formik>
          </Flex>
        </>
      )}
    </PageLayout>
  );
}

export default AddEditProduct;
