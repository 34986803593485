import { ICategoryType, IVendorDataTableProps, IVendorListFilterType } from '../types';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  VendorCategoriesDummyData,
  getTableStatus,
  getVendorCategories,
  getVendorList,
  initialFilters
} from '../helper';

import DataTable from '@organism/DataTable/DataTable';
import { Flex } from '@atom/Flex';
import { Spin } from 'antd';
import { debounce } from 'lodash';
import { getColumns } from './helper';
import { useNavigate } from 'react-router';
import { TableWrapper } from '@pages/MenuManagementPage/style';
import { ListWrapper } from './styles';

const VendorDataTable: React.FC<IVendorDataTableProps> = ({ activePanel, currentPanel }) => {
  const navigate = useNavigate();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isVendorCategoryLoading, setIsVendorCategoryLoading] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [filterData, setFilterData] = useState<IVendorListFilterType>({
    vendor_name: '',
    vendor_type: '',
    email: '',
    phone_number: '',
    type: 'all',
    page: 1
  });

  const [vendorCategories, setVendorCategories] = useState<ICategoryType[]>([]);

  useEffect(() => {
    if (currentPanel === activePanel) {
      setFilterData(initialFilters);
      updateTableData({ ...initialFilters, type: activePanel });
      getVendorCatergoriesMethod();
    }
  }, [activePanel]);

  const updateTableData = useCallback(
    debounce(async (filters = filterData) => {
      const newFilters = { ...filters };
      setFilterData(newFilters);
      setIsTableLoading(true);
      const res = await getVendorList(pageNo, getTableStatus(activePanel), filters);
      setIsTableLoading(false);
      if (!res.error) {
        setTableData(res.data?.data);
        setNextPageUrl(res?.data?.next_page_url);
        if (totalCount !== res?.data?.total) {
          setTotalCount(res?.data?.total);
        }
        if (pageNo !== res?.data?.per_page) {
          setPageNo(res?.data?.per_page);
        }
      }
    }, 300),
    []
  );

  const onFilterChangeHandler = (e: ChangeEvent | null, key?: 'vendor_type') => {
    let updatedFilters;
    if (key === 'vendor_type') {
      updatedFilters = { ...filterData, vendor_type: e };
    } else {
      const { id, value } = !!e ? (e.target as HTMLInputElement) : { id: '', value: '' };
      updatedFilters = { ...filterData, [id]: value };
    }
    updateTableData(updatedFilters);
    setFilterData({ ...updatedFilters, page: 1 });
  };

  const getVendorCatergoriesMethod = async () => {
    setIsVendorCategoryLoading(true);
    const res = await getVendorCategories();
    if (!res?.error) {
      setVendorCategories(res?.data ?? []);
    }
    setIsVendorCategoryLoading(false);
  };
  const cols = getColumns(filterData, onFilterChangeHandler, vendorCategories);

  if (isVendorCategoryLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spin />
      </Flex>
    );
  }

  return (
    <ListWrapper>
      <TableWrapper>
        <DataTable
          className="fixTD"
          loading={isTableLoading}
          columns={cols}
          showLoadMore={!!nextPageUrl}
          nextPageURL={nextPageUrl}
          dataSource={tableData}
          pagination={{
            pageSize: pageNo,
            total: totalCount,
            current: filterData?.page,
            onChange: (page: any) => {
              const newFilters = { ...filterData, page: page };
              setFilterData(newFilters);
              updateTableData(newFilters);
            }
          }}
          onFetch={() => {}}
          renderEmpty={() => <div>No Vendors Found.</div>}
          onRowClick={(e: any) => {
            navigate(`/vendors/${e?.record?.id}/view`, { state: activePanel });
          }}
        />
      </TableWrapper>
    </ListWrapper>
  );
};

export default VendorDataTable;
