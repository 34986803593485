import { Spin } from 'antd';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Flex } from '@atom/Flex';
import { showErrorToast } from '@utils/index';

import DataTable from '../../../shared/organisms/DataTable/DataTable';
import { getProductListDropdownData } from '../AddEditPages/AddEditProduct/helper';
import { MENU_MANAGEMENT_TABS } from '../helper';
import { TableWrapper } from '../style';
import { MenuManagementTablesProps } from '../types';
import { getList, getMenuManagementTableColumns, initialFilters, mapTableData } from './helper';
import { useSelector } from 'react-redux';

function MenuManagementTable(props: MenuManagementTablesProps) {
  const { activePanel, currentPanel } = props;
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(false);
  const [dropdownData, setDropdownData] = useState<any>({});
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [filters, setFilters] = useState<any>(initialFilters);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [totalCount, setTotalCount] = useState<Number>(0);
  const [pageSize, setPageSize] = useState<Number>(0);
  const { subCategories, productTypes, categories } = useSelector((state: any) => {
    return state.rawData;
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPanel === activePanel) {
      updateDropdownData();
      setFilters(initialFilters);
      updateTableData({ ...initialFilters, type: activePanel });
    }
  }, [activePanel]);

  const updateDropdownData = async () => {
    setIsDropdownLoading(true);
    try {
      if (!subCategories || !categories || !productTypes) {
        const ddRes = await getProductListDropdownData();
        setDropdownData({
          productTypeList: ddRes?.[0]?.success
            ? ddRes?.[0]?.data?.filter((i: any) => i?.text !== 'Archived')
            : [],
          catgoryList: ddRes?.[1]?.success ? ddRes?.[1]?.data : [],
          subCatgoryList: ddRes?.[2]?.success ? ddRes?.[2]?.data : []
        });
      } else {
        setDropdownData({
          productTypeList: productTypes || [],
          catgoryList: categories || [],
          subCatgoryList: subCategories || []
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch dropdown data'
      });
    }
    setIsDropdownLoading(false);
  };

  const updateTableData = async (filters: any) => {
    setIsTableLoading(true);
    try {
      const newFilters = { ...filters };
      setFilters(newFilters);
      const res = await getList(newFilters);
      if (res?.success) {
        setTotalCount(res?.data?.total);
        setPageSize(res?.data?.per_page);
        const mappedData = mapTableData(res?.data?.data, activePanel);
        setTableData(mappedData);
        setNextPageUrl(res?.data?.next_page_url);
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch list data'
      });
    }
    setIsTableLoading(false);
  };

  const searchFilter = useCallback(
    debounce(async (filters: any) => {
      await updateTableData(filters);
    }, 500),
    []
  );

  if (isDropdownLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spin />
      </Flex>
    );
  }

  const columns = getMenuManagementTableColumns(
    activePanel,
    filters,
    setFilters,
    dropdownData,
    updateTableData,
    searchFilter
  );

  return (
    <TableWrapper>
      <DataTable
        loading={isTableLoading}
        columns={columns}
        nextPageURL={nextPageUrl}
        dataSource={tableData}
        pagination={{
          pageSize: pageSize,
          total: totalCount,
          current: filters?.page,
          onChange: (page: any) => {
            const newFilters = { ...filters, page: page };
            setFilters(newFilters);
            updateTableData(newFilters);
          }
        }}
        renderEmpty={() => {
          return (
            <>{`No ${
              activePanel !== MENU_MANAGEMENT_TABS.BUNDLES ? 'Products' : 'Bundles'
            } Found.`}</>
          );
        }}
        onRowClick={(e: any) =>
          navigate(
            `/menu/tab/${activePanel}/${
              activePanel !== MENU_MANAGEMENT_TABS.BUNDLES ? 'view-product' : 'view-bundle'
            }/${e?.record?.id}`
          )
        }
      />
    </TableWrapper>
  );
}

export default MenuManagementTable;
