import CustomerMangementIcon from '@assets/menu-icon/customer-management.png';
import DashboardIcon from '@assets/menu-icon/dashboard.png';
import EmployeeMangementIcon from '@assets/menu-icon/user.png';
import EventMangementIcon from '@assets/menu-icon/event-calender.png';
import { MENU_MANAGEMENT_TABS } from '@pages/MenuManagementPage/helper';
import MenuMangementIcon from '@assets/menu-icon/menumanagement.png';
import QuoteMangementIcon from '@assets/menu-icon/qoute-management.png';
import StaffMangementIcon from '@assets/menu-icon/workorder-management.png';
import { VENDOR_MANAGEMENT_TABS } from '@pages/VendorManagementPage/helper';
import { VENUE_MANAGEMENT_TABS } from '@pages/VenueManagementPage/ListViewPages/helper';
import VendorMangementIcon from '@assets/menu-icon/vendor-management.png';
import VenueMangementIcon from '@assets/menu-icon/venue-management.png';
import WorkOrderManagement from '@assets/menu-icon/workorder-management.png';
import FoodPreparationIcon from '@assets/menu-icon/food-preparation.png';

export enum PATH_NAME {
  DASHBOARD = 1,
  MENU_MANAGEMENT = 2,
  FOOD_PREPARATION = 3,
  VENUE_MANAGEMENT = 4,
  VENDOR_MANAGEMENT = 5,
  EMPLOYEE_MANAGEMENT = 6,
  CUSTOMER_MANAGEMENT = 7,
  EVENT_MANAGEMENT = 8,
  STAFF_MANAGEMENT = 9,
  QUOTE_MANAGEMENT = 10,
  WORK_ORDER_MANAGEMENT = 11
}

export const keysToRemove = [
  'event_date',
  'code',
  'customer_name',
  'event_name',
  'guests',
  'venue_name',
  'event_time',
  'account_manager',
  'kitchen_staff',
  'event_staffs',
  'event_manager',
  'quote_quotation_no',
  'quote_event_date',
  'quote_customer_name',
  'quote_event_name',
  'quote_guests',
  'quote_venue_name',
  'quote_event_time',
  'quote_account_manager',
  'quote_kitchen_staff',
  'quote_event_staffs',
  'quote_event_manager'
];

export const getNavItems = () => {
  const navItems = [
    {
      title: 'Dashboard',
      icon: DashboardIcon,
      to: '/',
      id: PATH_NAME.DASHBOARD
    },
    {
      title: 'Menu Management',
      icon: MenuMangementIcon,
      to: `/menu/tab/${MENU_MANAGEMENT_TABS.ACTIVE}`,
      id: PATH_NAME.MENU_MANAGEMENT
    },
    {
      title: 'Food Preparation',
      icon: FoodPreparationIcon,
      to: `/food-preparation`,
      id: PATH_NAME.FOOD_PREPARATION
    },
    {
      title: 'Venue Management',
      icon: VenueMangementIcon,
      to: `/venues/tab/${VENUE_MANAGEMENT_TABS.ALL}`,
      id: PATH_NAME.VENUE_MANAGEMENT
    },
    {
      title: 'Vendor Management',
      icon: VendorMangementIcon,
      to: `/vendors/tab/${VENDOR_MANAGEMENT_TABS.ALL}`,
      id: PATH_NAME.VENDOR_MANAGEMENT
    },
    {
      title: 'Employee Management',
      to: '/employees/all',
      icon: EmployeeMangementIcon,
      id: PATH_NAME.EMPLOYEE_MANAGEMENT
    },
    {
      title: 'Customer Management',
      icon: CustomerMangementIcon,
      to: '/customers',
      id: PATH_NAME.CUSTOMER_MANAGEMENT
    },
    {
      title: 'Event Management',
      icon: EventMangementIcon,
      to: '/events',
      id: PATH_NAME.EVENT_MANAGEMENT
    },
    {
      title: 'Staff Management',
      icon: StaffMangementIcon,
      to: '/staff',
      id: PATH_NAME.STAFF_MANAGEMENT
    },
    {
      title: 'Quote Management',
      icon: QuoteMangementIcon,
      to: '/quote',
      id: PATH_NAME.QUOTE_MANAGEMENT
    },
    {
      title: 'Work Order Management',
      icon: WorkOrderManagement,
      to: '/work-orders',
      id: PATH_NAME.WORK_ORDER_MANAGEMENT
    }
  ];
  return navItems;
};

export const accessibleModules = (user: any) => {
  const { isManager, isEmployee } = user;
  const navItemIds = isManager
    ? [
        PATH_NAME.DASHBOARD,
        PATH_NAME.MENU_MANAGEMENT,
        PATH_NAME.VENUE_MANAGEMENT,
        PATH_NAME.VENDOR_MANAGEMENT,
        PATH_NAME.EMPLOYEE_MANAGEMENT,
        PATH_NAME.EVENT_MANAGEMENT,
        PATH_NAME.WORK_ORDER_MANAGEMENT
      ]
    : isEmployee
    ? [
        PATH_NAME.DASHBOARD,
        PATH_NAME.MENU_MANAGEMENT,
        PATH_NAME.EVENT_MANAGEMENT,
        PATH_NAME.WORK_ORDER_MANAGEMENT
      ]
    : [];
  return navItemIds;
};
