import React, { FC, useCallback, useEffect, useState } from 'react';

import { Flex } from '@atom/Flex';

import { H4Typography } from '@constant/typography/Typography';
import {
  CategoryListType,
  categoryValidationSchema,
  duplicateCategoryErrorMessage,
  duplicateSubCategoryErrorMessage,
  EditCategoriesType,
  getMenuDetails,
  updateMenuNames
} from './helper';
import CenterSpiner from '@atom/CenterSpiner';
import { useSelector } from 'react-redux';
import { FieldArray, Formik } from 'formik';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Col, Row } from 'antd';
import { Input } from '@atom/Input';
import RemoveIcon from '@assets/removerow.png';
import PlusCircle from '@assets/plus-circle.svg';
import { Button } from '@atom/Buttons';
import ConfirmModal from '@organism/ConfimationModal';
import { checkDuplicateError } from '../helper';

const Categories = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [categoryList, setCategoryList] = useState<CategoryListType[]>([]);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [submitValue, setSubmitValue] = useState<CategoryListType[] | null>(null);
  const [duplicateCategoryError, setDuplicateCategoryError] = useState<any>({});
  const [duplicateSubCategoryError, setDuplicateSubCategoryError] = useState<any>({});

  const { isGlobalSettingsEdit } = useSelector((state: any) => state.rawData);

  useEffect(() => {
    getCategoriesHandler();
  }, []);

  const getCategoriesHandler = useCallback(async () => {
    setPageLoading(true);
    const result = await getMenuDetails('categories');
    setPageLoading(false);
    if (!result.error) {
      setCategoryList(result.data || []);
    }
  }, []);

  const onCancelHandler = () => {
    updateRawData({ isGlobalSettingsEdit: false });
    getCategoriesHandler();
    onModalCancelHandler();
  };

  const onCategoryDelete = async (
    index: number,
    arrayHelper: any,
    id?: number | null,
    isSubCategory = false
  ) => {
    if (id) {
      showToast({
        message: `${isSubCategory ? 'Sub Category' : 'Master Category'} Removed Successfully`,
        description: ''
      });
    }
    arrayHelper.remove(index);
  };

  const onSubmitHandler = (values: EditCategoriesType) => {
    setSubmitValue(values.categories);
    setShowSaveConfirmModal(true);
  };

  const onModalCancelHandler = () => {
    setSubmitValue(null);
    setShowSaveConfirmModal(false);
  };

  const updateCategoriesHandler = async () => {
    if (!submitValue) return;
    setLoading(true);
    const result = await updateMenuNames({ categories: submitValue }, 'categories');
    setLoading(false);
    if (!result.error) {
      showToast({
        message: 'Categories Updated Successfully',
        description: result?.data?.message.length > 2 ? result?.data?.message : ''
      });
      onCancelHandler();
    } else {
      onModalCancelHandler();
      showErrorToast({
        message: 'Error Saving Categories',
        description: result.error || 'Please try again'
      });
    }
  };

  if (pageLoading) {
    return <CenterSpiner />;
  }

  if (isGlobalSettingsEdit) {
    return (
      <div>
        <Row className="hide-mobile-device">
          <Col md={12}>
            <H4Typography>Master Categories</H4Typography>
          </Col>
          <Col md={12}>
            <H4Typography>Sub Categories</H4Typography>
          </Col>
        </Row>
        <Formik
          initialValues={{ categories: categoryList }}
          validationSchema={categoryValidationSchema}
          onSubmit={onSubmitHandler}>
          {({ values, handleChange, setFieldValue, handleSubmit, handleBlur, touched, errors }) => {
            useEffect(() => {
              setDuplicateCategoryError({});
              setDuplicateSubCategoryError({});
              const { parentFieldErrors, childFieldErrors } = checkDuplicateError(
                values,
                'categories',
                'sub_categories',
                duplicateCategoryErrorMessage,
                duplicateSubCategoryErrorMessage
              );
              setDuplicateCategoryError(parentFieldErrors);
              setDuplicateSubCategoryError(childFieldErrors);
            }, [values]);

            const onSubCategorAdd = (index: number) => {
              const updatedCategories = [...values.categories];
              updatedCategories[index].sub_categories = [
                ...updatedCategories[index].sub_categories,
                { name: '', id: null, parent_id: updatedCategories[index].id }
              ];
              setFieldValue('categories', updatedCategories);
            };

            return (
              <Flex direction="column">
                <FieldArray
                  name={'categories'}
                  render={(arrayHelper: any) => {
                    return values?.categories?.map((category, index) => (
                      <Row gutter={24}>
                        <Col lg={12} md={12} xs={24}>
                          <Row gutter={[16, 16]}>
                            <Col md={22} xs={20}>
                              <H4Typography className="hide-full-device">
                                Master Categories
                              </H4Typography>
                              <Input
                                id={`categories[${index}].name`}
                                name={`categories[${index}].name`}
                                value={category.name}
                                placeholder={`Master Category`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  (touched?.categories?.[index]?.name &&
                                    (errors?.categories as { name: string }[])?.[index]?.name) ||
                                  duplicateCategoryError?.[index]
                                }
                              />
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              className="centerPosition"
                              style={{ textAlign: 'center' }}>
                              <img
                                style={{ paddingTop: '10px' }}
                                src={RemoveIcon}
                                alt="remove"
                                onClick={() => onCategoryDelete(index, arrayHelper, category?.id)}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={12} md={12} xs={24}>
                          <FieldArray
                            name={`categories[${index}].sub_categories`}
                            render={(subArrayHelper: any) => {
                              return category.sub_categories.map((subCategory, subIndex) => (
                                <Row gutter={[16, 16]}>
                                  <Col md={22} xs={20}>
                                    <H4Typography
                                      className={`hide-full-device  ${
                                        subIndex !== 0 ? 'hide-mobile-device' : ''
                                      }`}>
                                      Sub Categories
                                    </H4Typography>
                                    <Input
                                      id={`categories[${index}].sub_categories[${subIndex}].name`}
                                      name={`categories[${index}].sub_categories[${subIndex}].name`}
                                      value={subCategory.name}
                                      placeholder={`Sub Category`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        (touched?.categories?.[index]?.sub_categories?.[subIndex]
                                          ?.name &&
                                          (
                                            errors?.categories as {
                                              sub_categories: { name: string }[];
                                            }[]
                                          )?.[index]?.sub_categories?.[subIndex]?.name) ||
                                        duplicateSubCategoryError?.[index]?.[subIndex]
                                      }
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    xs={4}
                                    className="centerPosition"
                                    style={{
                                      opacity: 1,
                                      textAlign: 'center'
                                    }}>
                                    <img
                                      style={{ paddingTop: '10px' }}
                                      src={RemoveIcon}
                                      alt="remove"
                                      onClick={() =>
                                        onCategoryDelete(
                                          subIndex,
                                          subArrayHelper,
                                          subCategory?.id,
                                          true
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              ));
                            }}
                          />
                          <Flex bottom={24} style={{ justifyContent: 'end' }}>
                            <Button
                              text="Add Sub Category"
                              variant="secondary"
                              icon={<img width={20} src={PlusCircle} alt="add" />}
                              style={{ fontWeight: '700' }}
                              onClick={() => onSubCategorAdd(index)}
                            />
                          </Flex>
                        </Col>
                      </Row>
                    ));
                  }}
                />
                <Col md={12} style={{ justifyContent: 'end', display: 'flex' }}>
                  <Button
                    text="Add Master Category"
                    variant="secondary"
                    icon={<img width={20} src={PlusCircle} alt="add" />}
                    style={{ fontWeight: '700' }}
                    onClick={() =>
                      setFieldValue('categories', [
                        ...values.categories,
                        { name: '', id: '', sub_categories: [{ name: '', id: '' }] }
                      ])
                    }
                  />
                </Col>
                <Flex className="footerButton" justifyContent={'space-between'}>
                  <Flex alignItems={'center'}></Flex>
                  <Flex gap={8} className="fix-mobile">
                    <Button
                      text="Cancel"
                      variant="secondary"
                      onClick={onCancelHandler}
                      isDisable={loading}
                    />
                    <Button
                      text="Submit"
                      onClick={() => {
                        !Object.keys(duplicateCategoryError)?.length &&
                          !Object.keys(duplicateSubCategoryError)?.length &&
                          handleSubmit();
                      }}
                      isLoading={loading}
                    />
                  </Flex>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
        <ConfirmModal
          visible={showSaveConfirmModal}
          onCancel={onModalCancelHandler}
          title="Saving will impact existing entries."
          onOK={updateCategoriesHandler}
          isModalLoading={loading}
          okText="Okay">
          <span>
            Saving this new global setting could impact existing entries. Do you wish to continiue?
          </span>
        </ConfirmModal>
      </div>
    );
  }

  return (
    <div>
      <Flex bottom={26}>
        <H4Typography>MASTER CATEGORIES</H4Typography>
      </Flex>
      <Flex style={{ flexFlow: 'wrap', gap: '40px' }}>
        {categoryList.map((category) => (
          <Flex key={category.id} direction="column">
            <p>{category.name}</p>
            <p>SUB CATEGORIES</p>
            {category.sub_categories.map((subCat) => (
              <p key={subCat.id}>{subCat.name}</p>
            ))}
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

export default Categories;
