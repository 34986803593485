import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@atom/Buttons';
import { debounce } from 'lodash';
import { Flex } from '@atom/Flex';
import EditIcon from '@assets/edit-2.svg';
import { Row, Col, Divider } from 'antd';
import { ITimeType, MyScheduleType } from '../../type';
import {
  getMyScheduleData,
  MyScheduleDummyData,
  submitMyScheduleData,
  WORK_ORDER_MANAGEMENT_TABS
} from '../../helper';
import { H3Typography } from '@constant/typography/Typography';
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import FormDataView from '@atom/FormDataView';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { useSelector } from 'react-redux';
import MyScheduleForm from './MyScheduleForm';
import CenterSpiner from '@atom/CenterSpiner';
import { myScheduleValidations } from './helper';

interface MySchedulePageProps {
  activePanel: string;
}

const MySchedulePage: React.FC<MySchedulePageProps> = ({ activePanel }) => {
  const [ismyScheduleLoading, setIsMyScheduleLoading] = useState(false);
  const [isScheduleEdit, setScheduleEdit] = useState(false);
  const [myScheduleData, setMyScheduleData] = useState<MyScheduleType>(MyScheduleDummyData);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { isMyScheduleEdit } = useSelector((state: any) => state.rawData);

  const onEdit = () => {
    setScheduleEdit(true);
    updateRawData({ isMyScheduleEdit: true });
  };

  const onCancel = () => {
    setScheduleEdit(false);
    updateRawData({ isMyScheduleEdit: false });
  };

  const user = useSelector((state: { user: any }) => state.user);

  useEffect(() => {
    if (activePanel === WORK_ORDER_MANAGEMENT_TABS.MY_SCHEDULE) {
      updatemyScheduleData();
    }
  }, [activePanel]);

  const updatemyScheduleData = useCallback(
    debounce(async () => {
      setIsMyScheduleLoading(true);
      const res = await getMyScheduleData();
      setIsMyScheduleLoading(false);
      if (!res.error) {
        setMyScheduleData({
          schedules: !!res?.data?.length
            ? res?.data?.map((item: any) => ({
                ...item,
                user_id: user?.user_id
              }))
            : MyScheduleDummyData?.schedules?.map((item) => ({
                ...item,
                user_id: user?.user_id
              }))
        });
      }
    }, 100),
    []
  );

  const submitForm = async (values: MyScheduleType) => {
    setIsSubmitLoading(true);
    const res = await submitMyScheduleData(values);
    if (res?.success) {
      setMyScheduleData({ schedules: res?.data });
      setScheduleEdit(false);
      showToast({
        message: 'Success!',
        description: 'Schedule Saved.'
      });
    } else {
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
    setIsSubmitLoading(false);
  };

  const formik = useFormik({
    initialValues: myScheduleData,
    enableReinitialize: true,
    validate: (value) => {
      try {
        validateYupSchema(value, myScheduleValidations, true, value);
      } catch (error) {
        return yupToFormErrors(error);
      }
      return {};
    },
    validationSchema: null,
    validateOnMount: true,
    onSubmit: submitForm
  });

  if (ismyScheduleLoading) {
    return <CenterSpiner />;
  }

  const getTime = (start_time: string, end_time: string) => {
    if (!start_time || !end_time) return null;
    return `${start_time} - ${end_time}`;
  };

  return (
    <Flex direction="column" flex={1} style={{ width: '100%' }}>
      <Flex justifyContent={'space-between'} style={{ width: '100%' }}>
        <Row style={{ width: '100%', marginTop: '45px' }}>
          <Col md={12} xs={24}>
            <H3Typography style={{ marginBottom: '0px', fontWeight: '400' }}>
              Availablity
            </H3Typography>
          </Col>
          <Col md={12} xs={24} style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              style={{ paddingBottom: '0px', paddingTop: '0px' }}
              variant="ghost"
              text="EDIT"
              icon={<img src={EditIcon} alt="Edit" />}
              onClick={onEdit}
            />
          </Col>
        </Row>
      </Flex>
      <Divider style={{ borderColor: '#e0e0e0', margin: '15px 0px' }}></Divider>

      {isMyScheduleEdit && isScheduleEdit ? (
        <MyScheduleForm formik={formik} isSubmitLoading={isSubmitLoading} onCancel={onCancel} />
      ) : (
        <Row gutter={8} style={{ width: '100%' }}>
          {myScheduleData?.schedules?.map((item: ITimeType, index: number) => (
            <Col span={6} md={8} sm={6} xs={12} key={index}>
              <FormDataView
                heading={item?.day ?? ''}
                value={getTime(item.start_time ?? '', item.end_time ?? '')}
              />
            </Col>
          ))}
        </Row>
      )}
    </Flex>
  );
};

export default MySchedulePage;
