import { Col, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import DeactivateIcon from '@assets/alert-circle.svg';
import EditIcon from '@assets/edit-2.svg';
import NoImage from '@assets/noimage.png';
import { Button } from '@atom/Buttons';
import FormDataView from '@atom/FormDataView';
import StatusLabel from '@atom/StatusLabel';
import { H2Typography } from '@constant/typography/Typography';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import ConfirmModal from '@organism/ConfimationModal';
import { showErrorToast, showToast } from '@utils/index';
import PlayIcon from '@assets/icons/PlayIcon.svg';

import { PRODUCT_STATUS } from '../helper';
import { getProduct, updateProductStatus } from './helper';
import { PageWrapper } from '../style';
import { useSelector } from 'react-redux';
import { Flex } from '@atom/Flex';
import CustomVideoPlayer from '@molecules/CustomVideoPlayer';
import { formatCurrency } from '@utils/format';
import SpecialIngredientDetailsModal from '@pages/GlobalSettingsPage/MenuManagement/SpecialIngredients/SpecialIngredientDetailsModal';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';

interface productDetailsProps {
  productId?: number;
  isEditable?: boolean;
  showProductDetail?: boolean;
}

function ProductDetails(props: productDetailsProps) {
  const { productId, isEditable = true, showProductDetail } = props;
  const device = useWindowSizeChangedforGrid();

  const navigate = useNavigate();
  const { tab, id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [productData, setProductData] = useState<any>({});
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [isIngredientModal, setIngredientModal] = useState<boolean>(false);
  const [specialIngredientId, setSpecialIngredientId] = useState<number>();
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);

  const user = useSelector((state: { user: any }) => state.user);
  const { isAdmin, isEmployee } = user;

  useEffect(() => {
    getProductData();
  }, [id]);

  const getProductData = async () => {
    setIsLoadingProduct(true);
    try {
      const res = await getProduct(isEditable ? id : productId);
      if (res?.success) {
        setProductData(res?.data);
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch product data'
      });
    }
    setIsLoadingProduct(false);
  };

  const handleActivateDeactivate = async () => {
    const reqObj: any = {
      product_id: id
    };
    if (productData?.status === PRODUCT_STATUS.ACTIVE) {
      reqObj.status = PRODUCT_STATUS.INACTIVE;
    } else if (productData?.status === PRODUCT_STATUS.INACTIVE) {
      reqObj.status = PRODUCT_STATUS.ACTIVE;
    }
    try {
      const res = await updateProductStatus(reqObj);
      if (res?.success) {
        setIsModalVisible(false);
        navigate(`/menu/tab/${tab}`);
        showToast({
          message: 'Success!',
          description: 'Product status updated'
        });
        getProductData();
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to update product status'
        });
      }
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to update product status'
      });
    }
  };

  const modalOpenCloseHandler = (openModal: boolean, id?: number) => {
    if (openModal && id) {
      setIngredientModal(openModal);
      setSpecialIngredientId(id);
    } else {
      setIngredientModal(false);
      setSpecialIngredientId(undefined);
    }
  };

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
  };

  return (
    <PageWrapper>
      <PageLayout>
        {isLoadingProduct ? (
          <Spin />
        ) : (
          <>
            {isEditable && (
              <PageTitle
                bordered={true}
                isBottom={true}
                text="PRODUCT LIST"
                isBack={true}
                path={`/menu/tab/${tab}`}>
                {isAdmin && isEditable && (
                  <Flex>
                    {productData?.status !== PRODUCT_STATUS.DRAFT ? (
                      <Button
                        style={{ marginRight: '20px' }}
                        variant="ghost"
                        text={
                          productData?.status === PRODUCT_STATUS.INACTIVE
                            ? 'REACTIVATE'
                            : 'DEACTIVATE'
                        }
                        icon={<img src={DeactivateIcon} alt="Deactivate Button" />}
                        onClick={() => setIsModalVisible(true)}
                      />
                    ) : null}
                    <Button
                      variant="ghost"
                      text="EDIT"
                      icon={<img src={EditIcon} alt="Edit Button" />}
                      onClick={() => navigate(`/menu/${tab}/edit-product/${id}`)}
                    />
                  </Flex>
                )}
              </PageTitle>
            )}

            {showProductDetail ? (
              <>
                <Row
                  className="hide-full-device"
                  style={{
                    marginBottom: '10px',
                    marginTop: '30px',
                    marginRight: '40px'
                  }}
                  align="middle">
                  <Col md={12} xs={12}>
                    <H2Typography>{productData?.product_name}</H2Typography>
                  </Col>
                  <Col md={12} xs={8} offset={4}>
                    <Button
                      text="Watch Me "
                      icon={<img src={PlayIcon} alt=" Watch Me" style={{ paddingRight: '10px' }} />}
                      variant={'primary'}
                      onClick={() => {
                        productData?.tutorial_link
                          ? setShowVideoModal(true)
                          : showToast({
                              message: 'Not Specified',
                              description: 'Tutorial link is not provided.'
                            });
                      }}
                    />
                    {showVideoModal && (
                      <CustomVideoPlayer
                        url={productData?.tutorial_link}
                        showModal={showVideoModal}
                        handleClose={handleVideoModalClose}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="hide-mobile-device">
                  <Col md={12} xs={24} style={{ alignItems: 'center', display: 'flex' }}>
                    <H2Typography
                      style={{
                        margin: '15px 0px 15px 0px',
                        paddingLeft: '15px',
                        fontSize: '30px',
                        lineHeight: '36px'
                      }}>
                      {productData?.product_name}
                    </H2Typography>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col
                    md={12}
                    xs={24}
                    style={{
                      marginBottom: '24px',
                      display: 'flex',
                      justifyContent: `${device == 'mobile' ? 'center ' : 'initial'}`
                    }}>
                    {/* <Col md={12}> */}
                    <img
                      src={productData?.images?.[0]?.image || NoImage}
                      style={{ maxWidth: '100%' }}
                      alt="Product Image"
                    />
                    {/* </Col> */}
                  </Col>

                  <Col md={12} xs={24}>
                    <Row>
                      {!isEmployee && (
                        <FormDataView
                          heading="INGREDIENTS"
                          value={
                            <>
                              <Row style={{ color: 'red' }}>
                                {!productData?.is_subcategory_ingredient_exist &&
                                  'No Ingredients Set'}
                              </Row>
                              {productData?.ingredient_list?.map((i: any, index: number) => (
                                <>
                                  <span
                                    key={i.id}
                                    className="underline-cursor"
                                    onClick={() => {
                                      i?.is_special_ingredient &&
                                        modalOpenCloseHandler(true, i?.id);
                                    }}
                                    style={{
                                      textDecoration: i?.is_special_ingredient ? 'underline' : '',
                                      cursor: i?.is_special_ingredient ? 'pointer' : 'default'
                                    }}>{`${i?.name}`}</span>
                                  {index + 1 !== productData?.ingredient_list?.length && (
                                    <>,&nbsp;</>
                                  )}
                                </>
                              ))}
                            </>
                          }
                        />
                      )}
                    </Row>

                    <Row>
                      <FormDataView
                        heading="DIETARY RESTRICTIONS"
                        value={
                          productData?.dietary_restrictions
                            ?.map((i: any) => i?.dietary_restriction?.name)
                            ?.join(', ') || 'Not Specified'
                        }
                      />
                    </Row>

                    <Row className="hide-mobile-device">
                      <Button
                        text=" Watch Me"
                        icon={
                          <img src={PlayIcon} alt=" Watch Me" style={{ paddingRight: '10px' }} />
                        }
                        variant={'primary'}
                        onClick={() => {
                          productData?.tutorial_link
                            ? setShowVideoModal(true)
                            : showToast({
                                message: 'Not Specified',
                                description: 'Tutorial link is not provided.'
                              });
                        }}></Button>
                      {showVideoModal && (
                        <CustomVideoPlayer
                          url={productData?.tutorial_link}
                          showModal={showVideoModal}
                          handleClose={handleVideoModalClose}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md={12} xs={24} style={{ alignItems: 'center', display: 'flex' }}>
                    <H2Typography
                      style={{ margin: '10px 0px 15px 0px', fontSize: '30px', lineHeight: '36px' }}>
                      {productData?.product_name}
                    </H2Typography>
                  </Col>
                  <Col
                    md={12}
                    xs={24}
                    className="text-xs-left"
                    style={{ textAlign: 'end', textTransform: 'uppercase' }}>
                    <FormDataView
                      heading="STATUS"
                      value={<StatusLabel status={productData?.status} isUppercase={true} />}
                    />
                  </Col>
                </Row>
                <Row gutter={48}>
                  <Col md={6} xs={24} style={{ marginBottom: '24px' }}>
                    <img
                      src={productData?.images?.[0]?.image || NoImage}
                      style={{ maxWidth: '100%' }}
                      alt="Product Image"
                    />
                  </Col>

                  <Col md={18} xs={24}>
                    <Row>
                      {isAdmin && (
                        <Col md={12} xs={24}>
                          <FormDataView
                            heading="MENU(S)"
                            value={
                              productData?.menus
                                ?.map((i: any) => i?.master_menu?.name)
                                ?.join(', ') || 'Not Specified'
                            }
                          />
                        </Col>
                      )}
                      <Col
                        md={12}
                        xs={24}
                        className="text-xs-left"
                        style={isAdmin ? { textAlign: 'right' } : { textAlign: 'left' }}>
                        <FormDataView
                          heading="DIETARY RESTRICTIONS"
                          value={
                            productData?.dietary_restrictions
                              ?.map((i: any) => i?.dietary_restriction?.name)
                              ?.join(', ') || 'Not Specified'
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      {!isEmployee && (
                        <Col md={12} xs={24}>
                          <FormDataView
                            heading="INGREDIENTS"
                            value={
                              <>
                                <Row style={{ color: 'red' }}>
                                  {!productData?.is_subcategory_ingredient_exist &&
                                    'No Ingredients Set'}
                                </Row>
                                {productData?.ingredient_list?.map((i: any, index: number) => (
                                  <>
                                    <span
                                      key={i.id}
                                      className="underline-cursor"
                                      onClick={() => {
                                        i?.is_special_ingredient &&
                                          modalOpenCloseHandler(true, i?.id);
                                      }}
                                      style={{
                                        textDecoration: i?.is_special_ingredient ? 'underline' : '',
                                        cursor: i?.is_special_ingredient ? 'pointer' : 'default'
                                      }}>{`${i?.name}`}</span>
                                    {index + 1 !== productData?.ingredient_list?.length && (
                                      <>,&nbsp;</>
                                    )}
                                  </>
                                ))}
                              </>
                            }
                          />
                        </Col>
                      )}
                      {!isEmployee && (
                        <Col
                          md={12}
                          xs={24}
                          className="text-xs-left"
                          style={{ textAlign: 'right' }}>
                          <FormDataView
                            heading="PRODUCT TYPE"
                            value={
                              productData?.product_type_details?.product_type || 'Not Specified'
                            }
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {isAdmin && (
                        <Col md={12} xs={24}>
                          <FormDataView
                            heading="SUB - CATEGORY"
                            value={
                              <span style={{ whiteSpace: 'pre-wrap' }}>
                                {productData?.sub_categories
                                  ?.map(
                                    (i: any) =>
                                      `${i?.category?.name} ${
                                        id ? `- ${formatCurrency(i?.price)}` : ''
                                      }`
                                  )
                                  ?.join('\n') || 'Not Specified'}
                              </span>
                            }
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {isAdmin && id && (
                        <Col md={12} xs={24}>
                          <FormDataView
                            heading="BUNDLES"
                            value={
                              productData?.bundles
                                ?.map((i: any) => i?.bundle?.bundle_name)
                                ?.join(', ') || 'Not Specified'
                            }
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col md={12} xs={24}>
                        <FormDataView
                          heading="VIDEO TUTORIAL"
                          value={
                            productData?.tutorial_link ? (
                              <CustomVideoPlayer url={productData?.tutorial_link} />
                            ) : (
                              'Not Specified'
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <ConfirmModal
              title={`Are you sure you want to ${
                productData?.status === PRODUCT_STATUS.ACTIVE ? 'Deactivate' : 'Reactivate'
              } this Item?`}
              okText={productData?.status === PRODUCT_STATUS.ACTIVE ? 'Deactivate' : 'Reactivate'}
              cancelText="Cancel"
              visible={isModalVisible}
              onOK={() => handleActivateDeactivate()}
              onCancel={() => setIsModalVisible(false)}>
              {productData?.status === PRODUCT_STATUS.ACTIVE
                ? 'This item will not be forever deleted the account It will just be deactivated, but will remain the database'
                : `This item will be reactivated, it will go back into the "Active" product list.`}
            </ConfirmModal>

            {isIngredientModal && (
              <SpecialIngredientDetailsModal
                isModalVisible={isIngredientModal}
                modalCloseHandler={() => {
                  modalOpenCloseHandler(false);
                }}
                specialIngredientId={specialIngredientId}
                isModalEditable={false}
              />
            )}
          </>
        )}
      </PageLayout>
    </PageWrapper>
  );
}

export default ProductDetails;
